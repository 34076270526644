import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
// import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import StepsComponent from "../components/StepsComponent";

const AuthenticateAadhaar: React.FC = () => {
    const location = useLocation();
    const prevData: any = location.state;
    const navigate = useNavigate();
    const { setShowPreloader } = useAppStateAPI();
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();

    const authenticateaadhar = () => {
        setShowPreloader(true);
        ServerAPI.AuthenticateAadhar('web').then((response: any) => {
            if (response != undefined) {
                var left = (window.screen.width - 500) / 2;
                var top = (window.screen.height - 500) / 2;
                window.open(response['link'], 'popup', 'resizable=yes, width=500,height=500 + , top=' + top + ', left=' + left);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: any) => {
            setUserDetails(res)
        }).finally(() => {
            setShowPreloader(false);
        })

    }, [])

    return (
        <div className="main-index-wrapper">
            <main>
                {/* Profile-details-edit-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                            <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                        </div>
                                    </div>
                                    <StepsComponent step={1} />
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow page-main-heading" data-wow-delay="0.1s">Personal Details <span className="page-main-heading-steps">STEPS - 1 of 2</span></h6>
                                                            <hr></hr>
                                                            <form className="quote-form mb-10" action="#">
                                                                <div className="col-xl-12 pl-0 float-left">
                                                                    <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">Hey {userDetail?.pan_name}, Let’s Authenticate Your Aadhaar</h6>
                                                                </div>
                                                                <div className="col-xl-12 p-0 float-left mt-4">
                                                                    <button onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        authenticateaadhar();
                                                                    }} className="theme_btn primary_btn_one sub-btn wow w-auto" data-wow-delay="0.5s">Authenticate Aadhaar</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Profile-details-edit-area end */}
            </main>
        </div>
    )
}

export default AuthenticateAadhaar;
