import React, { Fragment, useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DialogTitle } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageLinks } from "../../common/Constants";

import {
    WhatsappIcon,
} from "react-share";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
}


const SetAlert: React.FC<FormParameters> = ({ showModal, closeModal }) => {
    const [otpModel, setOtpModel] = useState(false);

    const sendopt = () => {
        setOtpModel(true);
    }
    const verify = () => {
        setOtpModel(false);
        closeModal(true);
    }
    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog
                    open={showModal}
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    maxWidth="md"
                >
                    <DialogTitle className="header">{otpModel !== true ?
                        <p className="mt-2">Set alerts for the upcoming issues of Gold Bond</p> : ""}
                        <button onClick={(e: any) => {
                            e.preventDefault();
                            closeModal();
                        }} className="theme_btn profile_close_btn sub-btn wow" data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</button>
                    </DialogTitle>
                    {otpModel !== true ?
                        <div className="border-top-alert"> </div> : ""}
                    <DialogContent className="cancelled-cheque-bg" style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description" className="">

                            {otpModel == true ? (
                                <form className="quote-form row mt-2">



                                    <div className="row mx-1">

                                        <h3 className="header-text mb-4">Mobile Verification</h3>
                                        <p className="text-center p1-header mt-3">Enter the 4 digits, One Time Password sent on your number</p>
                                        <p className="no_text text-center">+91 9820098200 <svg xmlns="http://www.w3.org/2000/svg" width="20px" className="mb-2" height="20px" viewBox="0 0 89.227 79.313">
                                            <path id="Path_23211" data-name="Path 23211" d="M146.269,45.775a.924.924,0,0,1,1.58.651V71.877a7.438,7.438,0,0,1-7.435,7.435H85.888a7.438,7.438,0,0,1-7.437-7.435V17.349a7.438,7.438,0,0,1,7.437-7.435h45.278a.925.925,0,0,1,.652,1.58l-3.1,3.1a.959.959,0,0,1-.652.279H85.888a2.486,2.486,0,0,0-2.48,2.478V71.877a2.486,2.486,0,0,0,2.48,2.478h54.526a2.485,2.485,0,0,0,2.478-2.478V49.523a.875.875,0,0,1,.279-.651ZM117.364,67.664,101.89,69.382a3.25,3.25,0,0,1-3.595-3.593l1.719-15.476L148.423,1.905a6.508,6.508,0,0,1,9.2,0l8.147,8.15a6.5,6.5,0,0,1,0,9.2ZM140.942,16.42,104.755,52.59,103.47,64.225,115.1,62.938l36.17-36.187ZM154.124,5.406a1.541,1.541,0,0,0-2.184,0l-7.5,7.5,10.331,10.333,7.5-7.5a1.544,1.544,0,0,0,0-2.186Z" transform="translate(-78.451 0)" fill="#FF405A" />
                                        </svg></p>
                                        <div className="email-input_bond_set input_field input-pt pl-0 col-lg-6 m-auto">
                                            <input type="text"
                                                name="mobile_number"
                                                maxLength={10}
                                                size={10}
                                                className="email_input_no" />
                                            <div className="d-flex justify-content-between">
                                                <p className="text_didnt">Didn’t get OTP?</p>
                                                <button className="btn_resend">Resend OTP</button>
                                            </div>

                                        </div>

                                        <div className="login_bottom mt-4 pt-240">
                                            <div className="email-input d-flex justify-content-center mobile-input input_field pl-0">
                                                <button type="submit" onClick={(e: any) => {
                                                    e.preventDefault();
                                                    verify();
                                                }} className="theme_btn btn-change-alert primary_btn_1 sub-btn wow w-50" data-wow-delay="0.5s">Verify</button>
                                            </div>
                                        </div>
                                    </div>


                                </form>) : (<form className="quote-form row mt-2">

                                    <div className="col-lg-12">

                                        <div className="row mx-1">
                                            <div className="email-input_bond_set input_field input-pt pl-0 col-lg-6">
                                                <label className="input-title">First Name11</label>
                                                <input type="text"
                                                    name="price"
                                                    id="price" placeholder="Type here" />
                                            </div>

                                            <div className="email-input_bond_set input_field input-pt pl-0 col-lg-6">
                                                <label className="input-title">Last Name</label>
                                                <input type="text"
                                                    name="yield" id="yield" placeholder="Type here" />
                                            </div>

                                            <div className="email-input_bond_set mobile-input input_field input-pt pl-0 col-lg-6 mt-2">
                                                <label className="input-title">Mobile Number *</label>
                                                <input type="text" placeholder="Enter Mobile Number"

                                                    name="mobile_number"
                                                    maxLength={10}
                                                    size={10}
                                                    className="email_input_no" />
                                                <img src="assets/img/whatsapp.png" alt="" className="img_whatsapp" />
                                                <div className="country_box_no"><span className="input-title-mobile-no"> +91</span></div>
                                            </div>
                                            <div className="email-input_bond_set input_field input-pt pl-0 col-lg-6 mt-2">
                                                <label className="input-title">Email ID</label>
                                                <input type="text"
                                                    name="yield" id="yield" placeholder="Type here" />
                                            </div>
                                            <div className="col-xl-12 p-0 float-left">
                                                <div className="choice-list">
                                                    <span className="input-title">Choose Months To Issue Alerts</span>
                                                    <span className="input-title-filled gender_col" role="group">
                                                        <div className="gender_btn"><input type="radio" className="d-none" name="gender" id="gender_option_3" value="3" /><label htmlFor="gender_option_3" className="gender_btn-label">May</label></div>
                                                        <div className="gender_btn"><input type="radio" className="d-none" name="gender" id="gender_option_3" value="3" /><label htmlFor="gender_option_3" className="gender_btn-label">June</label></div>
                                                        <div className="gender_btn"><input type="radio" className="d-none" name="gender" id="gender_option_3" value="3" /><label htmlFor="gender_option_3" className="gender_btn-label">July</label></div>
                                                        <div className="gender_btn"><input type="radio" className="d-none" name="gender" id="gender_option_3" value="3" /><label htmlFor="gender_option_3" className="gender_btn-label">August</label></div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-xl-8 p-0 float-left">
                                                <p className="review-text text-left mt-5"><input type="checkbox" id="signup" name="check_box_share_data_with_company" /> Allow Trust Money to send me promotional mails and WhatsApp messages</p>
                                                <p className="review-text text-left pb-0"><input type="checkbox" id="signup" name="check_box_share_data_with_govt" /> I agree to <Link to={PageLinks.TERMS_AND_CONDITIONS}>Terms & Conditions</Link> and <Link to={PageLinks.PRIVACY_POLICY}>Privacy Policy</Link></p>
                                            </div>
                                            <div className="login_bottom mt-4">
                                                <div className="email-input d-flex justify-content-center mobile-input input_field pl-0">
                                                    <button type="submit" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        sendopt();
                                                    }} className="theme_btn btn-change-alert primary_btn_1 sub-btn wow w-50" data-wow-delay="0.5s">Set Alert</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>)}
                        </DialogContentText>
                    </DialogContent>

                </Dialog>
            </main>
        </div>
    )
}

export default SetAlert;