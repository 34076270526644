import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { kycStatus, Mail_mobile_verify, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { kycStatusUpdate } from "../common/Utilities";
import HeaderLogoContent from "../components/HeaderLogoContent";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";
import StepsComponent from "../components/StepsComponent";

const PersonalDetailsEditEmailVerified: React.FC = () => {

    const location = useLocation();
    const preData: any = location.state;
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const navigate = useNavigate();
    const [KycExit, setKycExit] = useState(false);
    const { setShowPreloader } = useAppStateAPI();

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res) => {
            setUserDetails(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }, [])


    return (
        <div className="main-index-wrapper">
            <main>
                {/* Profile-details-edit-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <HeaderLogoContent />
                                    <StepsComponent step={1} />
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow " data-wow-delay="0.1s">Personal Details <span className="step_count">STEPS - 1 of 2</span></h6>

                                                            <hr></hr>
                                                            <form className="quote-form mb-10" action="#">
                                                                <div className="col-xl-8 pl-0 float-left">
                                                                    <h6 className="pt-20 mb--10 " data-wow-delay="0.1s">Hey {userDetail?.fname} {' '} {userDetail?.lname}, Congratulations! Your Email is Verified.</h6>
                                                                    <div className="col-xl-6 pl-0 float-left mt-20">
                                                                        <div className="email-input input_field pl-0">
                                                                            <label className="input-title">Email ID<span className="error-area">*</span></label>
                                                                            <input type="text" className="email-verifed-bg" value={userDetail?.email_id} readOnly />
                                                                            {userDetail?.is_email_verified == Mail_mobile_verify.Verified ? <span className="verified"><i className="far fa-check-circle"></i></span> : <span className="not-verified"> <i className="far fa-times-circle"></i></span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 p-0 float-left">

                                                                </div>
                                                                <div className="col-xl-12 p-0 float-left text-center mt-200">
                                                                    <div onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        navigate(PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VALIDATE_PAN);
                                                                    }} className="theme_btn primary_btn_one sub-btn wow mb-3" data-wow-delay="0.5s">Continue</div>
                                                                    <div onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        setKycExit(true);
                                                                    }} className="save_continue_btn text-center cursor"><u>Save & Complete Later</u></div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ConfirmationKYCModel showModal={KycExit} closeModal={() => { setKycExit(false) }} confirmtype={'email'} />
            </main>
        </div>
    )
}

export default PersonalDetailsEditEmailVerified;
