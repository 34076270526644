import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props: any) => {
  const location = useLocation();

  useEffect(() => {
    //window.scrollTo(0, 0);
    // console.log('Scroll To Top');
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000)
  }, [location]);

  return <>{props.children}</>
};

export default ScrollToTop;
