import { Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { Annual_income_status, Gender, kycStatus, PageLinks, Selected_marital_status, TradingExperience } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { kycStatusUpdate } from "../common/Utilities";
import { InitialData } from "../components/Form/InitialData";
import AppologiesPopupModel from "../components/Modals/AppologiesPopupModel";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";
import StepsComponent from "../components/StepsComponent";

const AccountOpeningpennyDrop: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
    const [gender, setGender] = useState(Gender.NotSelected);
    const [maritalStatus, setMaritalStatus] = useState(Selected_marital_status.NotSelected);
    const [annualIncome, setAnnualIncome] = useState(Annual_income_status.NotSelected);
    const [tradingExperience, setTradingExperience] = useState(TradingExperience.NotSelected);
    const [professions, setProfessions] = useState<APIData.ProfessionDropDown[]>([]);
    const [KycExit, setKycExit] = useState(false);
    const { setShowPreloader } = useAppStateAPI();
    const [apologyPopup, setApologyPopup] = useState(false);


    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: APIData.userDetail) => {
            if (res.gender != null) {
                setGender(res.gender);
            }
            if (res.married_status != null) {
                setMaritalStatus(res.married_status);
            }
            if (res.annual_income != null) {
                setAnnualIncome(res.annual_income);
            }
            if (res.trading_experience != null) {
                setTradingExperience(res.trading_experience);
            }
            setUserDetails(res);
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
        ServerAPI.ProfessionDropDown().then((res: any) => {
            setProfessions(res?.message)
        }).finally(() => {
            setShowPreloader(false);
        })
    }, []);



    return (
        <div className="main-index-wrapper">
            <main>
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                            <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                        </div>
                                    </div>
                                    <StepsComponent step={1} />
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow page-main-heading" data-wow-delay="0.1s">Personal Details <span className="page-main-heading-steps">STEPS - 1 of 2</span></h6>

                                                            <hr></hr>
                                                            <Formik initialValues={userDetail} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                                if (values.occupation == null) {
                                                                    toast.error("Please Select Your Occupation Type Before Proceeding");
                                                                } else {
                                                                    setShowPreloader(true);
                                                                    ServerAPI.add_profile_details(values).then((res) => {
                                                                        if (res != undefined) {
                                                                            toast.success(res["message"]);
                                                                            if (userDetail.in_person_verification != null && userDetail.in_person_verification != undefined) {
                                                                                if (userDetail.in_person_verification == 0) {
                                                                                    navigate(PageLinks.VERIFY_YOUR_IDENTITY);
                                                                                } else if (userDetail.in_person_verification == 1) {
                                                                                    navigate(PageLinks.BANK_DETAILS);
                                                                                } else {
                                                                                    navigate(PageLinks.VERIFY_YOUR_IDENTITY);
                                                                                }
                                                                            } else {
                                                                                navigate(PageLinks.VERIFY_YOUR_IDENTITY);
                                                                            }
                                                                        }
                                                                    }).finally(() => {
                                                                        setShowPreloader(false);
                                                                    })
                                                                }
                                                            }}>
                                                                {({
                                                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                                }) => (

                                                                    <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                                                        <div className="col-xl-12 p-0 float-left">
                                                                             <h6 className="pt-20 mb-10 " data-wow-delay="0.1s">Hey {userDetail?.fname} {' '} {userDetail?.lname}, Please Verify, We Fetched This Information From PAN And KRA Records, As Provided By You.</h6>
                                                                            <div className="choice-list">
                                                                                <span className="input-title">What is your gender?</span>
                                                                                <span className="input-title-filled " role={"group"}>
                                                                                    <div className={gender == Gender.Male ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setGender(Gender.Male);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={1} checked={gender == Gender.Male ? true : false} className="d-none" name="gender" id="gender_option_1" />
                                                                                        <label
                                                                                            htmlFor="gender_option_1"
                                                                                            className={gender == Gender.Male ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            Male
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={gender == Gender.Female ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setGender(Gender.Female);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={2} checked={gender == Gender.Female ? true : false} className="d-none" name="gender" id="gender_option_2" />
                                                                                        <label
                                                                                            htmlFor="gender_option_2"
                                                                                            className={gender == Gender.Female ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            Female
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={gender == Gender.Trans ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setGender(Gender.Trans);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={3} checked={gender == Gender.Trans ? true : false} className="d-none" name="gender" id="gender_option_3" />
                                                                                        <label
                                                                                            htmlFor="gender_option_3"
                                                                                            className={gender == Gender.Trans ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            Trans
                                                                                        </label>
                                                                                    </div>
                                                                                </span>
                                                                            </div>
                                                                            <div className="choice-list">
                                                                                <span className="input-title">What is your marital status?</span>
                                                                                <span className="input-title-filled " role={"group"}>
                                                                                    <div className={maritalStatus == Selected_marital_status.Single ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setMaritalStatus(Selected_marital_status.Single);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={1} checked={maritalStatus == Selected_marital_status.Single ? true : false} className="d-none" name="married_status" id="marital_status_option_1" />
                                                                                        <label
                                                                                            htmlFor="marital_status_option_1"
                                                                                            className={maritalStatus == Selected_marital_status.Single ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            Single
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={maritalStatus == Selected_marital_status.Married ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setMaritalStatus(Selected_marital_status.Married);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={2} checked={maritalStatus == Selected_marital_status.Married ? true : false} className="d-none" name="married_status" id="marital_status_option_2" />
                                                                                        <label
                                                                                            htmlFor="marital_status_option_2"
                                                                                            className={maritalStatus == Selected_marital_status.Married ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            Married
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={maritalStatus == Selected_marital_status.Others ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setMaritalStatus(Selected_marital_status.Others);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={3} checked={maritalStatus == Selected_marital_status.Others ? true : false} className="d-none" name="married_status" id="marital_status_option_3" />
                                                                                        <label
                                                                                            htmlFor="marital_status_option_3"
                                                                                            className={maritalStatus == Selected_marital_status.Others ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            Other
                                                                                        </label>
                                                                                    </div>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 p-0 float-left">
                                                                            <div className="col-xl-4 pl-0 float-left ">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">What is your Occupation *</label>
                                                                                    <Field as="select" className="" name="occupation" onChange={handleChange} onBlur={handleBlur}>
                                                                                        <option>select</option>
                                                                                        {professions != undefined && professions.length > 0 && professions.map((profession: APIData.ProfessionDropDown, index) => {
                                                                                            return (
                                                                                                <option key={index} value={profession.occupation_id} >{profession.occupation}</option>
                                                                                            )
                                                                                        })

                                                                                        }
                                                                                    </Field>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 p-0 float-left">
                                                                            <div className="choice-list">
                                                                                <span className="input-title">What is your annual income? *</span>
                                                                                <span className="input-title-filled " role={"group"}>
                                                                                    <div className={annualIncome == Annual_income_status.below_one ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setAnnualIncome(Annual_income_status.below_one);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={1} checked={annualIncome == Annual_income_status.below_one ? true : false} className="d-none" name="annual_income" id="income_option_1" />
                                                                                        <label
                                                                                            htmlFor="income_option_1"
                                                                                            className={annualIncome == Annual_income_status.below_one ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            Below 1 Lakh
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={annualIncome == Annual_income_status.one_to_five ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setAnnualIncome(Annual_income_status.one_to_five);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={2} checked={annualIncome == Annual_income_status.one_to_five ? true : false} className="d-none" name="annual_income" id="income_option_2" />
                                                                                        <label
                                                                                            htmlFor="income_option_2"
                                                                                            className={annualIncome == Annual_income_status.one_to_five ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            1 - 5 Lakhs
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={annualIncome == Annual_income_status.five_to_ten ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setAnnualIncome(Annual_income_status.five_to_ten);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={3} checked={annualIncome == Annual_income_status.five_to_ten ? true : false} className="d-none" name="annual_income" id="income_option_3" />
                                                                                        <label
                                                                                            htmlFor="income_option_3"
                                                                                            className={annualIncome == Annual_income_status.five_to_ten ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            5 - 10 Lakhs
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={annualIncome == Annual_income_status.ten_to_twentyfive ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setAnnualIncome(Annual_income_status.ten_to_twentyfive);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={4} checked={annualIncome == Annual_income_status.ten_to_twentyfive ? true : false} className="d-none" name="annual_income" id="income_option_4" />
                                                                                        <label
                                                                                            htmlFor="income_option_4"
                                                                                            className={annualIncome == Annual_income_status.ten_to_twentyfive ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            10 - 25 Lakhs
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={annualIncome == Annual_income_status.above_twntyfive ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setAnnualIncome(Annual_income_status.above_twntyfive);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={5} checked={annualIncome == Annual_income_status.above_twntyfive ? true : false} className="d-none" name="annual_income" id="income_option_5" />
                                                                                        <label
                                                                                            htmlFor="income_option_5"
                                                                                            className={annualIncome == Annual_income_status.above_twntyfive ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            Above 25 Lakhs
                                                                                        </label>
                                                                                    </div>
                                                                                </span>
                                                                            </div>
                                                                            <div className="choice-list">
                                                                                <span className="input-title">What is your trading experience? *</span>
                                                                                <span className="input-title-filled " role={"group"}>
                                                                                    <div className={tradingExperience == TradingExperience.less_than_one ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setTradingExperience(TradingExperience.less_than_one);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={1} checked={tradingExperience == TradingExperience.less_than_one ? true : false} className="d-none" name="trading_experience" id="trading_option_1" />
                                                                                        <label
                                                                                            htmlFor="trading_option_1"
                                                                                            className={tradingExperience == TradingExperience.less_than_one ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            Less than 1 year
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={tradingExperience == TradingExperience.one_to_two ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setTradingExperience(TradingExperience.one_to_two);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={2} checked={tradingExperience == TradingExperience.one_to_two ? true : false} className="d-none" name="trading_experience" id="trading_option_2" />
                                                                                        <label
                                                                                            htmlFor="trading_option_2"
                                                                                            className={tradingExperience == TradingExperience.one_to_two ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            1 - 2 Years
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={tradingExperience == TradingExperience.two_to_five ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setTradingExperience(TradingExperience.two_to_five);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={3} checked={tradingExperience == TradingExperience.two_to_five ? true : false} className="d-none" name="trading_experience" id="trading_option_3" />
                                                                                        <label
                                                                                            htmlFor="trading_option_3"
                                                                                            className={tradingExperience == TradingExperience.two_to_five ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            2 - 5 Years
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={tradingExperience == TradingExperience.five_to_ten ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setTradingExperience(Annual_income_status.ten_to_twentyfive);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={4} checked={tradingExperience == TradingExperience.five_to_ten ? true : false} className="d-none" name="trading_experience" id="trading_option_4" />
                                                                                        <label
                                                                                            htmlFor="trading_option_4"
                                                                                            className={tradingExperience == TradingExperience.five_to_ten ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            5 - 10 Years
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={tradingExperience == TradingExperience.ten_to_twenty ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setTradingExperience(TradingExperience.ten_to_twenty);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={5} checked={tradingExperience == TradingExperience.ten_to_twenty ? true : false} className="d-none" name="trading_experience" id="trading_option_5" />
                                                                                        <label
                                                                                            htmlFor="trading_option_5"
                                                                                            className={tradingExperience == TradingExperience.ten_to_twenty ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            10 - 20 Years
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={tradingExperience == TradingExperience.twenty_to_twenty_five ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setTradingExperience(TradingExperience.twenty_to_twenty_five);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={6} checked={tradingExperience == TradingExperience.twenty_to_twenty_five ? true : false} className="d-none" name="trading_experience" id="trading_option_6" />
                                                                                        <label
                                                                                            htmlFor="trading_option_6"
                                                                                            className={tradingExperience == TradingExperience.twenty_to_twenty_five ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            20 - 25 Years     
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={tradingExperience == TradingExperience.above_twenty_five ? "gender_btn selected" : "gender_btn"}>
                                                                                        <input onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setTradingExperience(TradingExperience.above_twenty_five);
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} onBlur={handleBlur} type="radio" value={7} checked={tradingExperience == TradingExperience.above_twenty_five ? true : false} className="d-none" name="trading_experience" id="trading_option_7" />
                                                                                        <label
                                                                                            htmlFor="trading_option_7"
                                                                                            className={tradingExperience == TradingExperience.above_twenty_five ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                        >
                                                                                            Above 25 Years
                                                                                        </label>
                                                                                    </div>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row col-xl-12 mb-2 mt-3 p-0 float-left">
                                                                            <div className="col-xl-5">
                                                                                <span className="input-title">Are You A Tax Resident Of Any Other Country Other Than India?</span>
                                                                            </div>
                                                                            <div className="col-lg-2">
                                                                                <div className="my-account-input-title-filled-switch">
                                                                                    <div className="custom-switch custom-switch-label-yesno pl-0">
                                                                                        <input
                                                                                            className="custom-switch-input"
                                                                                            id="is_politically_exposed"
                                                                                            type="checkbox"
                                                                                            checked={values.citizen_usa == 1 ? true : false}
                                                                                            name="is_politically_exposed"
                                                                                            onChange={(e: any) => {
                                                                                                if (e.target.checked) {
                                                                                                    values.itr_records = 1;
                                                                                                    setApologyPopup(true);
                                                                                                } else {
                                                                                                    values.itr_records = 0;
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        <label className="custom-switch-btn d-flex justify-content-start" htmlFor="is_politically_exposed"></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="row col-xl-12 p-0 float-left">
                                                                            <div className="col-xl-5">
                                                                                <span className="input-title">Have You Filed ITR For Last 2 Years</span>
                                                                            </div>
                                                                            <div className="col-lg-2">
                                                                                <div className="my-account-input-title-filled-switch">
                                                                                    <div className="custom-switch custom-switch-label-yesno pl-0">
                                                                                        <input className="custom-switch-input" id="filled_itr_last_2years" type="checkbox" name="filled_itr_last_2years" onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                values.filled_itr_last_2years = 1;
                                                                                            } else {
                                                                                                values.filled_itr_last_2years = 0;
                                                                                            }
                                                                                        }} />
                                                                                        <label className="custom-switch-btn" htmlFor="filled_itr_last_2years"></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="col-xl-12 p-0 float-left">
                                                                            <div className="choice-list political_switch">
                                                                                <span className="input-title">Have You Filed ITR For Last 2 Years</span>
                                                                                <div className="input-title-filled-it">
                                                                                    <div className="custom-switch custom-switch-label-yesno pl-0">
                                                                                        <input className="custom-switch-input" id="filled_itr_last_2years" type="checkbox" name="filled_itr_last_2years" onChange={(e: any) => {
                                                                                           
                                                                                        }} />
                                                                                        <label className="custom-switch-btn" htmlFor=""></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="col-xl-12 p-0 float-left">
                                                                            <div className="col-xl-4 pl-0 float-left ">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">Mother’s Maiden Name</label>
                                                                                    <Field as="input" type="text" name="mothers_maiden_name" onChange={handleChange} onBlur={handleBlur} placeholder="Enter Mother's Maiden Name" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-12 continue_btn_whole mb-3">
                                                                            <button onClick={(e: any) => {
                                                                                kycStatusUpdate(kycStatus.IPV);
                                                                            }} type="submit" className={(gender != Gender.NotSelected && values.occupation != '' && values.mothers_maiden_name != '' && annualIncome != Annual_income_status.NotSelected && tradingExperience != TradingExperience.NotSelected) ? "theme_btn primary_btn_one sub-btn wow w-auto" : "theme_btn primary_btn sub-btn wow"} data-wow-delay="0.5s">Continue</button>
                                                                        </div>
                                                                        <div onClick={(e: any) => {
                                                                            e.preventDefault();
                                                                            setKycExit(true);
                                                                        }} className="save_continue_btn text-center cursor"><u>Save & Complete Later</u></div>
                                                                    </form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AppologiesPopupModel
                        showModal={apologyPopup}
                        closeModal={() => {
                            setApologyPopup(false);
                        }}
                    />
                    <ConfirmationKYCModel showModal={KycExit} closeModal={() => { setKycExit(false) }} confirmtype={'krarecords'} />
                </section>
            </main>
        </div>
    )
}

export default AccountOpeningpennyDrop;
