import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ChequeUpload from "./ChequeUpload";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageLinks } from "../../common/Constants";
import { DialogTitle } from "@mui/material";
import { Formik } from "formik";
import { APIData } from "../../common/DataTypes";
import { InitialData } from "../Form/InitialData";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { ServerAPI } from "../../common/ServerAPI";
import { toast } from "react-hot-toast";

interface FormParameters {
  showModal: boolean;
  closeModal: any;
  emailerror: any;
}

const EmailOTPVerifyexists: React.FC<FormParameters> = ({ showModal, closeModal, emailerror}) => {

  const navigate = useNavigate();
  const location = useLocation();

  return (
   <>
    <div className="main-index-wrapper">
      <main>
        <Dialog
          className="dialog-background"
          open={showModal}
          keepMounted
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              closeModal();
            }
          }}
          aria-describedby="alert-dialog-slide-description"
          scroll="body"
          PaperProps={{
            style: {
              background: "none",
              boxShadow: "none",
            },
          }}
          maxWidth="sm"
        >
          <div className="do-box do-box-2 mb-30 email_verify_bg max_width_fix">
            <DialogContent style={{ overflow: "hidden" }} className="p-2">
              <DialogContentText id="alert-dialog-slide-description">
                <div className="img m-auto text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76">
                  <g id="Group_14532" data-name="Group 14532" transform="translate(0.184 0.184)">
                    <circle id="Ellipse_3" data-name="Ellipse 3" cx="38" cy="38" r="38" transform="translate(-0.184 -0.184)" fill="#fff"/>
                    <g id="Group_14526" data-name="Group 14526" transform="translate(15.775 33.73)">
                      <g id="Group_14524" data-name="Group 14524" transform="translate(0 -0.001)">
                        <path id="Path_25291" data-name="Path 25291" d="M98.663,243.471a1.935,1.935,0,0,1-1.256-3.407l15.185-12.948a1.935,1.935,0,1,1,2.511,2.944L99.918,243.008A1.928,1.928,0,0,1,98.663,243.471Z" transform="translate(-96.728 -226.654)" fill="#ff405a"/>
                      </g>
                      <g id="Group_14525" data-name="Group 14525" transform="translate(26.689 -0.001)">
                        <path id="Path_25292" data-name="Path 25292" d="M306.978,243.471a1.926,1.926,0,0,1-1.254-.463l-15.185-12.948a1.935,1.935,0,0,1,2.51-2.944l15.185,12.948a1.935,1.935,0,0,1-1.256,3.407Z" transform="translate(-289.859 -226.654)" fill="#ff405a"/>
                      </g>
                    </g>
                    <g id="Group_14527" data-name="Group 14527" transform="translate(10.917 30.719)">
                      <path id="Path_25293" data-name="Path 25293" d="M70.868,208.737H63.509a1.935,1.935,0,0,1,0-3.869h7.359a1.935,1.935,0,0,1,0,3.869Z" transform="translate(-61.574 -204.868)" fill="#ff405a"/>
                    </g>
                    <g id="Group_14528" data-name="Group 14528" transform="translate(7.289 36.472)">
                      <path id="Path_25294" data-name="Path 25294" d="M48.244,250.364H37.258a1.935,1.935,0,0,1,0-3.869H48.244a1.935,1.935,0,0,1,0,3.869Z" transform="translate(-35.323 -246.496)" fill="#ff405a"/>
                    </g>
                    <g id="Group_14529" data-name="Group 14529" transform="translate(15.788 20.065)">
                      <path id="Path_25295" data-name="Path 25295" d="M119.676,149.395a1.93,1.93,0,0,1-1.252-.46L97.5,131.181a1.935,1.935,0,0,1,2.5-2.95l19.669,16.692,19.669-16.692a1.935,1.935,0,0,1,2.5,2.95l-20.92,17.754A1.93,1.93,0,0,1,119.676,149.395Z" transform="translate(-96.821 -127.771)" fill="#00c6d8"/>
                    </g>
                    <g id="Group_14530" data-name="Group 14530" transform="translate(14.873 19.371)">
                      <path id="Path_25296" data-name="Path 25296" d="M133.077,154.633H94.863A4.669,4.669,0,0,1,90.2,149.97V146.83a1.935,1.935,0,0,1,3.869,0v3.139a.8.8,0,0,0,.794.794h38.214a.8.8,0,0,0,.794-.794V127.413a.8.8,0,0,0-.794-.794H94.863a.8.8,0,0,0-.794.794v3.276a1.935,1.935,0,0,1-3.869,0v-3.276a4.669,4.669,0,0,1,4.663-4.663h38.214a4.669,4.669,0,0,1,4.663,4.663V149.97A4.669,4.669,0,0,1,133.077,154.633Z" transform="translate(-90.2 -122.75)" fill="#00c6d8"/>
                    </g>
                    <g id="Group_176" data-name="Group 176" transform="translate(-688.184 -309.771)">
                      <circle id="Ellipse_4" data-name="Ellipse 4" cx="9" cy="9" r="9" transform="translate(738 347.588)" fill="#ff405a"/>
                      <g id="Group_160" data-name="Group 160" transform="translate(-0.5 -0.5)">
                        <line id="Line_14" data-name="Line 14" y1="8" x2="8" transform="translate(743.5 353.088)" fill="none" stroke="#fff" stroke-width="1"/>
                        <line id="Line_15" data-name="Line 15" x1="8" y1="8" transform="translate(743.5 353.088)" fill="none" stroke="#fff" stroke-width="1"/>
                      </g>
                    </g>
                  </g>
                </svg>
                </div>
               <p className="text-fonts-20">{emailerror}</p>
               <div className="text-center">

               <button className="close_page mt-3"  onClick={(e: any) => {
                                e.preventDefault();
                                closeModal();
                              }}>close</button>
               </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
      </main>
    </div>
   </>
  );
};

export default EmailOTPVerifyexists;
