import { createContext, useContext, useReducer } from 'react';

const initialState = {
    showPreloader: false,
    kycStatus:0,
    SGBBond: { sgb_order_quantity:0, sgb_order_amount: 0, bond_id: 0 ,sgb_order_demant_account_id:0,quantity_type:''},
    IPOBond: { ipo_order_quantity:0, ipo_order_amount: 0, bond_id: 0 ,ipo_order_demant_account_id:0},
    IPOSeries:[{bond_ncd_series_id:0}]
};
const AppStateContext = createContext(initialState);

export const StateActionTypes = {
    SET_SHOW_PRELOADER: "SET_SHOW_PRELOADER",
    SET_KYC_STATUS: "SET_KYC_STATUS",
    SET_SGB_BOND: "SET_SGB_BOND",
    SET_IPO_BOND: "SET_IPO_BOND",
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case StateActionTypes.SET_SHOW_PRELOADER:
            return {
                ...state, showPreloader: action.showPreloader
            }
        case StateActionTypes.SET_KYC_STATUS:
            return {
                ...state, kycStatus: action.kycStatus
            }
            case StateActionTypes.SET_SGB_BOND:
                return {
                    ...state, SGBBond: action.SGBBond
                }
            case StateActionTypes.SET_IPO_BOND:
                return {
                    ...state, IPOBond: action.IPOBond
                }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

export const AppStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppStateContext.Provider value={{ state, dispatch }}>
            {children}
        </AppStateContext.Provider>
    )
}

export const useAppState = () => useContext(AppStateContext);