import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useAppStateAPI } from "../common/AppStateAPI";
import { interestPaymentFrequency, NatureofInstruments, bondPurchaseTypes, IssuerType, listing, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { numberWithCommas } from "../common/Utilities";
import Copy from "../components/Svg/Copy";
import moment from "moment";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Alert from "../components/Modals/Alert";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import copy from 'copy-to-clipboard';


const ApplyBondNonGold = () => {
    const location = useLocation();
    const [isin, setIsin] = useState('');
    const [checkedone, setCheckedOne] = useState(0);
    const [qty, setQty] = useState(1);
    const [total, setTotal] = useState(0);
    const [OrderID, setOrderID] = useState(0);
    const [OrderNo, setOrderNO] = useState('');
    const [List, setList] = useState('');
    const [alertPopup, setAlertPopup] = useState(false);
    const [modify, setModify] = useState(0);
    const [bondsList, setBondsList] = useState<APIData.BondlistData[]>([]);
    const [bondsDetail, setBondDetail] = useState<any>();
    const [investmentValues, setInvestmentValues] = useState<APIData.InvestmentCalculator>();
    const [bondsDetailCashflow, setBondDetailCashflow] = useState<APIData.BondCashFlow[]>([]);
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("isin")) {
                const id = query.get("isin");
                setIsin((id!));
            } if (query.has("qty")) {
                const qty = query.get("qty");
                setQty(parseInt(qty!))
            } if (query.has("modify")) {
                const modify = query.get("modify");
                setModify(parseInt(modify!))
            }
            if (query.has("orderid")) {
                const orderID = query.get("orderid");
                setOrderID(parseInt(orderID!))
            } if (query.has("orderno")) {
                const orderNo = query.get("orderno");
                setOrderNO(orderNo!)
            }
        }
    }, [location]);

    // useEffect(() => {
    //      let newTotal = 0;
    //     newTotal += bondsDetail?.bond_face_value * qty
    //     setTotal(newTotal);
    // }, []) 

    const goToBuy = (isin: string) => {
        let items: any[] = [];
        let params: any = {};
        params.isin = isin;
        params.bond_id = bondsDetail?.bond_id;
        params.bond_type = bondPurchaseTypes.BONDS;
        params.qty = qty;
        params.modify = modify;
        params.Order_No = OrderNo;
        params.Order_ID = OrderID;
        params.Order_SELL_ID = bondsDetail?.buy_sell_id
        params.List = List
        items.push(params);
        navigate(PageLinks.PAYMENTBOND,
            { state: { data: items } });
        //   return   navigate({
        //         pathname: PageLinks.PAYMENTBOND,
        //         search: "?modify=" + 1,
        //     });
    }


    // useEffect(() => {
    //     if (isin != '') {
    //         setShowPreloader(true);
    //         ServerAPI.BondDetail(isin).then((response: any) => {
    //             if (response['message']['bond_details'] != undefined) {
    //                 setBondDetail(response['message']['bond_details']);
    //             }
    //             if (response['message']['bond_cashflow_details'] != undefined) {
    //                 setBondDetailCashflow(response['message']['bond_cashflow_details']);
    //             }
    //         }).finally(() => {
    //             setShowPreloader(false);
    //         })
    //     }
    // }, [isin])
    useEffect(() => {
        if (isin != '') {
            setShowPreloader(true);
            ServerAPI.BondDetail(isin).then((response: any) => {
                if (response != undefined) {
                    setBondDetail(response);
                    setList(response?.bond_listing)
                }
            }).finally(() => {
                setShowPreloader(false);
            })

            setShowPreloader(true);
            ServerAPI.Cashflow(isin).then((response: any) => {
                if (response != undefined) {
                    setBondDetailCashflow(response);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [isin])
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // useEffect(() => {
    //     setShowPreloader(true);
    //     ServerAPI.BondInvestmentCalculator(isin, qty).then((response: any) => {
    //         if (response != undefined) {
    //             setInvestmentValues(response);
    //         }
    //     }).finally(() => {
    //         setShowPreloader(false);
    //     })
    // }, [isin, qty])
    return (
        <div className="main-index-wrapper">
            <Header />
            <div className="body-overlay"></div>
            <main>
                <section className="sovereign_gold_bonds pt-100 pt-md-100 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid ">
                        <div className="row pt-3">
                            <div className="col-lg-12">
                                <div className="row align-items-left">
                                    <div className="col-lg-12 breadcums pt-4 ">
                                        <nav>
                                            <ul>
                                                <li>Home</li>
                                                <li>Bonds</li>
                                                <li>Explore Bonds</li>
                                                <li>{bondsDetail?.bond_name}</li>
                                                <li className="active">Buy</li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pb-3 mx-4 px-4 p-sm-none  detail-view-calculation">
                            <div className="col-lg-6 col-md-12 col-sm-12 pb-20 p-sm-none">
                                <div className="card-box-shadow-1 p-3 p-sm-0 p-sm-none">
                                    <div className="d-flex main-head-bond px-sm-10">
                                        <div className="image-logo-ipo mt-2 mr-2">
                                            <img src={bondsDetail?.bond_logo} alt="sovereign" className="bond-title-img" />
                                        </div>
                                        <div className="title-bond-detail ml-2 mt-10 pt-2">
                                            <p className="bond-title">{bondsDetail?.bond_name}</p>

                                            <div className="d-flex my-2">
                                                <a className="background-green title-background-style p-md-2  py-1 mr-1 px-2 ">
                                                    <p className="fs-12 fs-sm-8 p-0 text-white">RETURNS Based On Market</p>
                                                </a>
                                                {bondsDetail?.bond_isin_number != '' &&
                                                    <button className="isin-btn m-0 fs-15"><span className="fs-15 p-0">ISIN:&nbsp;</span>{bondsDetail?.bond_isin_number}&nbsp;
                                                        <span className="cursor" onClick={(e: any) => {
                                                            e.preventDefault();
                                                            copy(bondsDetail?.bond_isin_number!, {
                                                                debug: true,
                                                            });
                                                            toast.success('ISIN Number Copied To Clipboard');
                                                        }}>
                                                            <Copy />
                                                        </span>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row px-sm-10" data-wow-delay="0.1s">

                                        <div className="mt-1 mb-1 bond-t">
                                            <p className="title"> Key Points To Consider</p>
                                            <span className="text-light sourceSansPro-regular">Face Value (In INR) and Issue Size (In INR Crores)</span>
                                        </div>
                                    </div>

                                    <div className="section-table table-font-p">
                                        <div className="col">
                                            <div className="row row-first">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    <p>Rate of Int (Returns)</p>
                                                    <h5>{bondsDetail?.bond_coupon_rate != null && bondsDetail?.bond_coupon_rate != '' ? bondsDetail?.bond_coupon_rate + "% p.a" : "N/A"}</h5>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    <p>Yield</p>
                                                    <h5>{bondsDetail?.bonds_yeild != null && bondsDetail?.bonds_yeild != 0 ? bondsDetail?.bonds_yeild + "%" : "N/A"}</h5>
                                                </div>
                                            </div>
                                            <div className="row row-sec pb-1">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    <p>Min. Investment</p>
                                                    <h5>{bondsDetail?.bond_minimum_application != null && bondsDetail?.bond_minimum_application != '' ? "₹" + numberWithCommas(bondsDetail.bond_minimum_application) : "N/A"}</h5>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    <p>Face Value</p>
                                                    <h5>{bondsDetail?.bond_face_value != null && bondsDetail?.bond_face_value != 0 ? "₹" + numberWithCommas(bondsDetail?.bond_face_value) : "N/A"}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="row row-first">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    <p>Interest Payment Frequency</p>
                                                    <h5>{parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_ANNUAL &&
                                                        <h5>Annual</h5>
                                                    }
                                                        {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                            <h5>Monthly</h5>
                                                        }
                                                        {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                            <h5>Quarterly</h5>
                                                        }
                                                        {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                            <h5>Semi-Annual</h5>
                                                        }
                                                        {parseInt(bondsDetail?.bond_interest_frequency!) == 0 &&
                                                            <h5>N/A</h5>
                                                        }
                                                        {bondsDetail?.bond_interest_frequency == null || bondsDetail?.bond_interest_frequency == '' &&
                                                            <h5>N/A</h5>
                                                        }</h5>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    <p>Maturity Date</p>
                                                    <h5>{bondsDetail?.bond_maturity_date != null && bondsDetail?.bond_maturity_date != '' ? moment(bondsDetail?.bond_maturity_date).format("MMM Do YYYY") : "N/A"}</h5>
                                                </div>
                                            </div>
                                            <div className="row row-sec pb-1">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    <p>Instrument Type</p>
                                                    <h5>{bondsDetail?.bond_nature_of_instrument == NatureofInstruments.TAXABLE ? "Taxable" : "Tax Free"}</h5>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    <p>Date Of Issue</p>
                                                    <h5>{bondsDetail?.bond_issue_date != null && bondsDetail?.bond_issue_date != '' ? moment(bondsDetail?.bond_issue_date).format("MMM Do YYYY") : "N/A"}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="row row-first">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    <p>Call Option</p>
                                                    {bondsDetail != undefined && bondsDetail.bond_call_details != undefined && bondsDetail.bond_call_details.length > 0 ?
                                                        <Fragment>
                                                            <h5>Yes
                                                                {/* <span className="call-option" id="call" onClick={handleClick} >{moment(bondsDetail?.bond_call_details[0].bond_call_detail_datetime).format("MMM Do YYYY")} <img src="assets/img/call-option-info.svg" /></span> */}
                                                            </h5>
                                                        </Fragment>
                                                        :
                                                        <h5>No</h5>
                                                    }
                                                </div>
                                                {/* <Popover
                                                    id="call"
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <Typography sx={{ p: 2 }}>
                                                        <div className="call-option-content">
                                                            <h5>Dates for Call Option</h5>
                                                            <h6>02 Oct 2022, 10.00am to 11.00am</h6>
                                                            <h6>02 Oct 2022, 10.00am to 11.00am</h6>
                                                            <h6 className="selected">02 Oct 2022, 10.00am to 11.00am</h6>
                                                            <div className="center-btn">
                                                                <button className="alert-button" onClick={(e: any) => {
                                                                    e.preventDefault();
                                                                    setAlertPopup(true);
                                                                    handleClose();
                                                                }}>Set Alert<span><i className="fa fa-arrow-right" aria-hidden="true"></i></span></button>
                                                            </div>
                                                        </div>
                                                    </Typography>
                                                </Popover> */}
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    <p>Put Option</p>
                                                    {bondsDetail != undefined && bondsDetail.bond_put_details != undefined && bondsDetail.bond_put_details.length > 0 ?
                                                        <Fragment>
                                                            <h5>Yes
                                                                {/* <span className="call-option" id="call" onClick={handleClick} >{moment(bondsDetail?.bond_put_details[0].bond_put_detail_datetime).format("MMM Do YYYY")} <img src="assets/img/call-option-info.svg" /></span> */}
                                                            </h5>
                                                        </Fragment>
                                                        :
                                                        <h5>No</h5>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row row-sec pb-1">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    <p>Mode Of Issue</p>
                                                    {bondsDetail?.bond_issuer_details[0]?.bond_type_of_issuer == IssuerType.CENTRAL_GOVERNMENT &&
                                                        <h6>Central Government</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0]?.bond_type_of_issuer == IssuerType.PRIVATE_BANK &&
                                                        <h6>Private Bank</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0]?.bond_type_of_issuer == IssuerType.PRIVATE_SECTOR_CORPORATE &&
                                                        <h6>Private Sector Corporate</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0]?.bond_type_of_issuer == IssuerType.PUBLIC_SECTOR_BANK_STATE_GOVERNMENT &&
                                                        <h6>Public Sector Bank State Government</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0]?.bond_type_of_issuer == IssuerType.PUBLIC_SECTOR_UNDERTAKING &&
                                                        <h6>Public Sector Undertaking</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0]?.bond_type_of_issuer == null &&
                                                        <h6>N/A</h6>
                                                    }
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    <p>Listed</p>
                                                    <h5>{bondsDetail?.bond_listing != null && bondsDetail?.bond_listing != undefined ? (bondsDetail?.bond_listing == listing.BSE ? 'BSE' : bondsDetail?.bond_listing == listing.NSE ? 'NSE': bondsDetail?.bond_listing == listing.BSE_NSE ? 'BSE & NSE' : "") : 'N/A'}</h5>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <table className="w-100 bond-table">
                                        <tr>
                                            <td className="w-50">
                                                <p className="table-text-light sourceSansPro-semibold">Rate of Int (Returns)</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bond_coupon_rate}%</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold">Yield</p>
                                                <p className="sourceSansPro-bold">{investmentValues?.yield_value}%</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold">Allotment Date</p>
                                                <p className="sourceSansPro-bold">Mar 08, 2022</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold">Issue Opening - Closing Dates</p>
                                                <p className="sourceSansPro-bold">Feb 28-Mar 04, 2022</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold">Next Interest Payment Date</p>
                                                <p className="sourceSansPro-bold">Sep 07, 2022</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold mt-2">Interest Payment Frequency</p>
                                                <div >
                                                    <p className="sourceSansPro-bold mb-0"> Semi-Annually
                                                        <span className="table-text-light sourceSansPro-semibold d-block margin-top--10" > Every 6 Months</span></p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold mt-2">Maturity after</p>
                                                <div >
                                                    <p className="sourceSansPro-bold mb-0"> Mar 07, 2027
                                                        <span className="table-text-light sourceSansPro-semibold d-block margin-top--10" > (1st Exit Date)</span></p>
                                                </div>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold mt-2">Purchase Limit</p>
                                                <div >
                                                    <p className="sourceSansPro-bold mb-0"> 4 Kg/4000 units
                                                        <span className="table-text-light sourceSansPro-semibold d-block margin-top--10" >  During each financial year</span></p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold">Issuer</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bond_issuer_name}</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold mt-2">RBI Notice</p>
                                                <a href="#" className="sourceSansPro-bold mb-0"> https://www.rbi.org.in</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold">Next Interest Payment Date</p>
                                                <p className="sourceSansPro-bold">Sep 07, 2022</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-semibold mt-2">Exit Option Available</p>
                                                <div >
                                                    <p className="sourceSansPro-bold mb-0">Yes
                                                        <span className="table-text-light sourceSansPro-semibold d-block margin-top--10" >After the 5th year. Investors can exercise it on the next interest payment dates.</span></p>
                                                </div>
                                            </td>
                                        </tr>
                                    </table> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 pt-md-20 pt-sm-20 p-sm-none">
                                <div className="ml-lg-3 h-100">
                                    <div className="section section-sm-bg p-sm-none h-100">
                                        <div className="row d-flex section-head cal p-2 align-items-center">
                                            <div className="col-lg-3 col-md-3 col-sm-3 col-3 p-0">
                                                <p className="title text-quick font-weight-600 fs-16 p-0">Bond Value Details</p>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-3 mt-lg-3 col-3 p-sm-none">
                                                <p className="sub-head">Face Value (A)</p>
                                                <h5 className="sub-value">₹ {numberWithCommas(bondsDetail?.bond_face_value)}</h5>
                                            </div>
                                            <div className="col-lg-3 col-md-3  col-sm-3 mt-lg-3 col-3 p-sm-none">
                                                <p className="sub-head">Yield (YTM)</p>
                                                <h5 className="sub-value">{bondsDetail?.bonds_yeild}%</h5>
                                            </div>
                                            <div className="col-lg-3 col-md-3  col-sm-3 mt-lg-3 col-3 p-sm-none">
                                                <p className="sub-head">Min. Investment (B)</p>
                                                <h5 className="sub-value">₹ {numberWithCommas(bondsDetail?.bond_minimum_application + "")}</h5>
                                            </div>
                                        </div>
                                        <div className="section-form form-sm-p pt-60">
                                            <div className="row row-form-res">
                                                <div className="col-lg-7 col-md-6 col-sm-6 col-6 pt-10  pb-35 w-xs-100">
                                                    <div>
                                                        <h6>Number of bonds</h6>
                                                        <p> No. of Bonds you want to Purchase (B)</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-6 col-sm-6 col-6 pt-10 w-xs-100 pb-35 pb-sm-20 ">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <div className="">
                                                            <button onClick={(e: any) => {
                                                                e.preventDefault();
                                                                if (qty != 1) {
                                                                    setQty(qty - 1);
                                                                }
                                                            }} className="detail-view-calculator-button">-</button>
                                                        </div>
                                                        <div className="">
                                                            <input value={qty}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    e.preventDefault();
                                                                    const inputValue = e.target.value;
                                                                    const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove leading zeros
                                                                    const parsedValue = parseInt(sanitizedValue, 10);
                                                                    if (isNaN(parsedValue)) {
                                                                        setQty(0);
                                                                    } else {
                                                                        setQty(parsedValue);
                                                                    }
                                                                }}
                                                                className="input-value1 box-shadow sgb-calculator-input-1" />
                                                        </div>
                                                        <div className="">
                                                            <button onClick={(e: any) => {
                                                                e.preventDefault();
                                                                setQty(qty + 1);
                                                            }} className="detail-view-calculator-button">+</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                                <div className="col-lg-7 col-md-6 col-sm-6 col-6 pt-10 pb-35 w-xs-100">
                                                    <div>
                                                        <h6>Principal Amount (E)</h6>
                                                        <p>Market value (B) x no. of bonds (C)</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-6 col-sm-6 col-6 pt-10 pb-35 w-xs-100 ">
                                                    
                                                    <input disabled={true}
                                                        value={' ₹ ' + numberWithCommas(bondsDetail?.bond_face_value * qty)}
                                                     
                                                        className="input-valu2" />
                                                    
                                                </div>
                                            </div> */}
                                            {/* <div className="row">
                                                <div className="col-lg-7 col-md-6 col-sm-6 col-6 pt-10 pb-35 w-xs-100">
                                                    <div>
                                                        <h6>Accrued Interest</h6>
                                                        <p>(Interest earned on the bond this year) (D)</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-6 col-sm-6 col-6 pt-10 pb-35 w-xs-100">
                                                    <div className="">
                                                        <input disabled={true}
                                                            value={' ₹ ' + numberWithCommas(bondsDetail?.bond_face_value * qty * 5 / 100)}
                                                           
                                                            className="input-valu2" />
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-lg-7 col-md-6 col-sm-6 col-6 pt-10 pb-35 w-xs-100">
                                                    <div>
                                                        <h6 className="font-big fs-20 p-0"> {bondsDetail?.buy_sell_id == 1 && 'Total Investment'}{bondsDetail?.buy_sell_id == 2 && 'Total Return'}</h6>
                                                        <p>Face Value (A) x no. of bonds (B)</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-6 col-sm-6 col-6 pt-10 pb-35 w-xs-100">
                                                    <div className="">
                                                        <input disabled={true}
                                                            value={' ₹ ' + numberWithCommas(bondsDetail?.bond_minimum_application * qty)}
                                                            //  value={' ₹ ' + numberWithCommas(investmentValues?.total_investment + "")}
                                                            className="input-valu2" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="review-text text-left">
                                            <label htmlFor="signup" className="review-text mx-1 mb-0 p-0">
                                                <p className="review-text text-left pb-0 d-inline-flex">
                                                    <input type="checkbox" id="signup" className="signup" value={checkedone} onChange={(e: any) => {
                                                        if (e.target.checked) {
                                                            setCheckedOne(1);
                                                        } else {
                                                            setCheckedOne(0);
                                                        }
                                                    }} name="check_box_share_data_with_govt" /><p className="fs-12"> I have read and agreed to the <u className="signup-terms_conditions">disclaimer</u> and <u className="signup-terms_conditions">terms & conditions</u></p></p>
                                            </label>
                                        </p>

                                        <div className="text-center pb-30">
                                            {bondsDetail?.buy_sell_id == 1 &&
                                                <button className="apply-gold-btn  text-white" onClick={(e: any) => {
                                                    e.preventDefault();
                                                    if (checkedone == 0) {
                                                        toast.error("Please accept Terms & Condition and Disclaimer to proceed");
                                                    } else if (qty == 0) {
                                                        toast.error("Please enter a valid number of bond");

                                                    } else {
                                                        goToBuy(bondsDetail?.bond_isin_number!);
                                                    }
                                                }}>Continue to Buy</button>}
                                            {bondsDetail?.buy_sell_id == 2 &&
                                                <button className="apply-gold-btn sell_btn_bond text-white" onClick={(e: any) => {
                                                    e.preventDefault();
                                                    if (checkedone == 0) {
                                                        toast.error("Please accept Terms & Condition and Disclaimer to proceed");
                                                    } else if (qty == 0) {
                                                        toast.error("Please enter a valid number of bond");

                                                    } else {
                                                        goToBuy(bondsDetail?.bond_isin_number!);
                                                    }
                                                }}>Continue to Sell</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Alert showModal={alertPopup} closeModal={() => { setAlertPopup(false) }} />
            </main>
            <Footer />
        </div>
    );
}

export default ApplyBondNonGold



