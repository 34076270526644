import { Field, Formik } from "formik";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { numberWithCommas } from "../../common/Utilities";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { APIData } from "../../common/DataTypes";
import { ServerAPI } from "../../common/ServerAPI";
import { toast } from "react-hot-toast";
import Copy from "../../components/Svg/Copy";
import copy from 'copy-to-clipboard';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DialogTitle } from "@mui/material";
import { InitialData } from "../../components/Form/InitialData";
import { PageLinks } from "../../common/Constants";


interface FormParameters {
    showModal: boolean,
    closeModal: any,
    setFormData: any
    bondID: number,
    bondsDetail: any,
    isin: any,
    setCalcudata: any
}


const Calculater: React.FC<FormParameters> = ({ showModal, closeModal, setFormData, bondID, bondsDetail, isin, setCalcudata }) => {
    const [id, setId] = useState(0);
    const [nomineeAdd, setNomineeAdd] = useState(false);
    const [calculater, setCalculater] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [settlementID, setSettlementID] = useState(0);
    const [YieldID, setYieldID] = useState(0);
    const [aidccID, setAidccID] = useState(0);
    const [price, setPrice] = useState<any>(0);
    const [YieldVal, setYieldVal] = useState<any>();
    const [aidcc, setAidcc] = useState<APIData.aidcc[]>([]);
    const [Yield, setYield] = useState<APIData.Yield[]>([]);
    const [settlement, setSettlement] = useState<APIData.settlement[]>([]);
    const [caldata, setCaldata] = useState<any>();
    const [buyid, setBuyid] = useState(0);
    const [calculaterDetail, setCalculaterDetail] = useState<APIData.calculater>(InitialData.calculater);
    // const [bondsDetail, setBondDetail] = useState<APIData.IPODetail>();
    const { setShowPreloader } = useAppStateAPI();
    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.settlements().then((res: any) => {
            setSettlement(res);
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
        ServerAPI.Yields().then((res: any) => {
            setYield(res);
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
        ServerAPI.aidcc().then((res: any) => {
            setAidcc(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }, [])
    const BondEnquiryCal = () => {

        setShowPreloader(true);
        ServerAPI.price_calculator(isin, settlementID, YieldID, price, YieldVal, aidccID).then((response) => {
            if (response != undefined) {
                setCaldata(response)
                setCalculater(true);
                if(response.price_value){
                    setPrice(response.price_value?.toFixed(2))
                }else if(response.yield_value){
                    setYieldVal(response.yield_value?.toFixed(2))
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const Accept = () => {

        let params: any = {};
        params.price = price;
        params.yield_value = caldata?.yield_value;
        params.YieldVal = YieldVal;

        //  items.push(params);
        closeModal({state: { data: params } });
        setCalcudata({ data: params })
    }
    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog
                    open={showModal}
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    maxWidth="md"

                >
                    <DialogTitle className="bg-popup-res"> <p className="header-p">Yield / Price Calculator</p>
                        <button onClick={(e: any) => {
                            e.preventDefault();
                            closeModal();
                        }} className="theme_btn profile_close_btn sub-btn wow ds-btn-none" data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</button>
                        <button onClick={(e: any) => {
                            e.preventDefault();
                            closeModal();
                        }} className="btn-close-sm wow filter_close ds-btn-show" data-wow-delay="0.5s"><i className="fas fa-times"></i></button>
                    </DialogTitle>
                    <DialogContent className="cancelled-cheque-bg bg-popup-res" style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div className="row py-3 mx-4 px-4 detail-view-calculation mt-4 mt-sm-0">

                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="row align-items-center pb-3 res-text-none">
                                        <div className="col-lg-2 col-md-3 col-3 col-3 p-0">
                                            <img src={bondsDetail?.bond_logo != '' ? bondsDetail?.bond_logo : "assets/img/img_placeholder.png"} alt="ipo" className="bond-title-img" />
                                        </div>
                                        <div className="col-lg-10 col-md-9 col-sm-9 col-9 detail-view-description p-0 m-0">
                                            <div className="d-flex mb-2">
                                                <p className="title fs-20 text-quick font-weight-500">{bondsDetail?.bond_issuer_name}</p>
                                            </div>
                                            {bondsDetail?.bond_isin_number != '' &&
                                                <button className="isin-btn d-flex align-items-center fs-15 p-2 m-0"><span className="fs-15 p-0">ISIN:&nbsp;</span>{bondsDetail?.bond_isin_number}&nbsp;
                                                    <span className="cursor" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        copy(bondsDetail?.bond_isin_number!, {
                                                            debug: true,
                                                        });
                                                        toast.success('ISIN Number Copied To Clipboard');
                                                    }}>
                                                        <Copy />
                                                    </span>
                                                </button>
                                            }
                                        </div>
                                    </div>

                                    <form className="quote-form res-text-light row mt-2">
                                        <div className="row">
                                            <div className="col-xl-4 col-md-5 p-0 float-left">
                                                <div className="choice-list political_switch mt-1  input_field p-0">
                                                    <span className="input-title1">Mode Of Calculation</span>
                                                    <div className="input-title-filled nominee-toggle-switch">
                                                        <div className="custom-switch custom-switch-label-yesno pl-0 ">
                                                            <input className="custom-switch-input" id="citizen" type="checkbox" checked={nomineeAdd} onChange={(e: any) => {
                                                                // if (e.target.checked == true) {
                                                                setNomineeAdd(e.target.checked);
                                                                // } else {
                                                                //     setNomineeAdd(false);
                                                                // }

                                                            }} />
                                                            <label className="custom-switch-btn Switch-text" htmlFor="citizen" data-on="Yes1111" data-off="No" ></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="email-input input_field input-pt pl-0 col-lg-6 mt-3">
                                            <label className="input-title1">Settlement Date</label>
                                            <select name="settlement_date_id" onChange={(e: any) => {
                                                setSettlementID(e.target.value);
                                            }}>
                                                <option value="0">select</option>
                                                {settlement != undefined &&
                                                    settlement.length > 0 &&
                                                    settlement.map((title: APIData.settlement) => {
                                                        return <option value={title.settlement_date_id}>{title.settlement_date_name}</option>;
                                                    })}
                                            </select>

                                        </div>
                                        {nomineeAdd === false ?
                                            <div className="email-input input_field input-pt pl-0 col-lg-6 mt-3">
                                                <label className="input-title1">Enter Yield(%) to Maturity </label>
                                                <input type="text" placeholder="Type here"
                                                    onChange={(e: any) => {
                                                        setYieldVal(e.target.value);
                                                    }}
                                                    name="yield"
                                                    id="yield"

                                                />
                                            </div>
                                            :
                                            <div className="email-input input_field input-pt pl-0 col-lg-6 mt-3">
                                                <label className="input-title1">Enter Price</label>
                                                <input type="text"
                                                    onChange={(e: any) => {
                                                        setPrice(e.target.value);
                                                    }}
                                                    name="price"
                                                    id="price" placeholder="Type here" />
                                            </div>
                                        }
                                        <div className="email-input input_field input-pt pl-0 col-lg-6">
                                            <label className="input-title1">Yield Type</label>
                                            <select name="yield_type_name" onChange={(e: any) => {
                                                setYieldID(e.target.value);
                                            }}>
                                                <option value="0">select</option>
                                                {Yield != undefined &&
                                                    Yield.length > 0 &&
                                                    Yield.map((yields: APIData.Yield) => {
                                                        return <option value={yields.yield_type_id}>{yields.yield_type_name}</option>;
                                                    })}
                                            </select>

                                        </div>
                                        <div className="email-input input_field input-pt pl-0 col-lg-6">
                                            <label className="input-title1">aiDcc</label>
                                            <select name="ai_dcc_name" onChange={(e: any) => {
                                                setAidccID(e.target.value);
                                            }}>
                                                <option value="0">select</option>
                                                {aidcc != undefined &&
                                                    aidcc.length > 0 &&
                                                    aidcc.map((aidccs: APIData.aidcc) => {
                                                        return <option value={aidccs.ai_dcc_id}>{aidccs.ai_dcc_name}</option>;
                                                    })}
                                            </select>

                                        </div>

                                        <div className="col-lg-12 mb-2 mt-3">
                                            <div onClick={(e: any) => {
                                                e.preventDefault();
                                                BondEnquiryCal();
                                            }} className="d-flex mb-4 btn-box justify-content-center">
                                                <button className="spotlight_readmore  btn-1 mx-2 mt-1 w-30" style={{ padding: "3px 20px" }}>Calculate</button>
                                            </div>
                                        </div>

                                        {calculater == true ?
                                            <div className="row box-shadow1 mt-4">
                                                <div className="col-lg-12 p-4 row">
                                                    <div className="col-lg-3">
                                                        <p className="bg-1">My New Offer</p>
                                                    </div>
                                                    <div className="col-lg-6 mt-1">
                                                        <div className="row row-sec mr-1 pb-1">
                                                            <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                                                <p className="price">Price</p>
                                                                <h5 className="text-left">{price}</h5>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 col-sm-4 col-3">
                                                                <p className="price">Yield(%)</p>
                                                                <h5 className="text-left">{YieldVal}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 mt-3 mt-sm-0">
                                                        <div className="d-flex mb-4 btn-box justify-content-end">
                                                            <button onClick={(e: any) => {
                                                                e.preventDefault();
                                                                Accept();

                                                            }} className="btn-green mx-2" style={{ padding: "3px 20px" }}>Accept</button>
                                                            <button className="btn-red btn-1 mx-2 mt-1" style={{ padding: "3px 20px" }}>Reject</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div> : null}
                                    </form>

                                </div>



                            </div>

                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </main>
        </div >
    )
}

export default Calculater;