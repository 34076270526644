import { useCallback, useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { gapi } from 'gapi-script';
import { ServerAPI } from "../common/ServerAPI";
import { APIData } from "../common/DataTypes";
import StepsComponent from "../components/StepsComponent";

const clientId = "90863909653-7vahl3orei20fngu27kaehmiqqs7cb1n.apps.googleusercontent.com";

const PersonalDetailsEditVerifyEmail: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const prevData: any = location.state;
    const [email_id, setEmail] = useState<any>();
    const [loginStatus, setLoginStatus] = useState(false);
    const { setShowPreloader } = useAppStateAPI();
    const [active, setActive] = useState(false)

    useEffect(() => {
        upload_email()
    }, [email_id])

    const upload_email = () => {
        console.log(email_id);

        let passData: APIData.userDetail;

        if (email_id !== undefined) {
            localStorage.setItem('animation', 'email');
            setActive(true)
            setShowPreloader(true);
            ServerAPI.OauthEmailVerify(email_id).then((res) => {
                passData = { ...prevData, email_id };
                if (res.errors) {
                    navigate(PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VERIFY_EMAIL);
                }
            }).catch((err: any) => {
                navigate(PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VERIFY_EMAIL);
            }).finally(() => {
                setTimeout(() => {
                    setShowPreloader(false);
                    localStorage.setItem('animation', 'default');
                    navigate(PageLinks.EMAIL_VERIFED_SUCCESS);
                }, 10000);

            })

        }
    }

    const onSuccess = (res: any) => {
        let passData: APIData.userDetail;
        setEmail(res.profileObj['email']);
    }

    const onFailure = (err: any) => {
        console.log('failed', err);
    };

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    });

    const [first_name, setFirstName] = useState();

    useEffect(() => {
        let fname: any = localStorage.getItem('customerFirstName');
        setFirstName(fname);


    })

    return (
        <div className="main-index-wrapper">
            <main>
                {/* Profile-details-edit-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                            <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                        </div>
                                    </div>
                                    <StepsComponent step={1} />
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow " data-wow-delay="0.1s">Personal Details <span className="step_count">STEPS - 1 of 2</span></h6>
                                                            <hr></hr>
                                                            <form className="quote-form mb-10" action="#">
                                                                <div className="col-xl-8 pl-0 float-left">
                                                                    <h6 className="pt-20 mb--10 " data-wow-delay="0.1s">Hey {localStorage.getItem('customerFirstName')} {' '} {localStorage.getItem('customerMiddleName')} {' '} {localStorage.getItem('customerLastName')}, Let’s Verify Your Email ID</h6>
                                                                    <div className="col-xl-12 pl-0 pt-20 float-left">
                                                                        <div className="email-input input_field pl-0">
                                                                            <label className="input-title">Email ID<span className="error-area">*</span></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-5 pl-0 pt-20 float-left">
                                                                        <a className=" pt-1" onClick={() => upload_email()}>
                                                                            <GoogleLogin
                                                                                className="sign_in_with_google_btn"
                                                                                clientId={clientId}
                                                                                buttonText="Sign in with Google"
                                                                                onSuccess={onSuccess}
                                                                                onFailure={onFailure}
                                                                                isSignedIn={false}
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-xl-1 pl-0 pt-20 float-left">
                                                                        <span className="sign_in_with_google_btn_choise">Or</span>
                                                                    </div>
                                                                    <div className="col-xl-6 pl-0 pt-20 float-left">
                                                                        <div className="email-input input_field pl-0">
                                                                            <Link to={PageLinks.EMAIL_VERIFICATION} state={prevData} className="diff_email_btn">I would like to use a different E-mail Address</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 p-0 float-left">

                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Profile-details-edit-area end */}
            </main>
        </div>
    )
}

export default PersonalDetailsEditVerifyEmail;
