import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import InvestSteps from "../components/InvestSteps";
import { useAppStateAPI } from "../common/AppStateAPI";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import Footer from "../components/template/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { bondTypes, OrderStatus, bondPurchaseTypes, interestPaymentFrequency, PageLinks } from "../common/Constants";
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
import { Fragment, useEffect, useState } from "react";
import OrderStepsComponent from "../components/OrderStepsComponent";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import moment from "moment";
import { styled } from '@mui/system';
import { numberWithCommas } from "../common/Utilities";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ReactPaginate from "react-paginate";
import SortBy from "../components/template/SortBy";
import SortByOrder from "../components/template/SortByOrder";
import { InitialData } from "../components/Form/InitialData";
import Copy from "../components/Svg/Copy";
import { toast } from "react-hot-toast";
import copy from 'copy-to-clipboard';
import GoldBond1 from "../components/GoldBond1";
import DownloadIssueDocuments from "../components/DownloadIssueDocuments";

const GoldBond: React.FC = () => {
    const location = useLocation();
    const [orderOpenIndex, setorderOpenIndex] = useState('');
    const [ordersList, setOrdersList] = useState<APIData.order[]>([]);
    const [isin, setIsin] = useState('');
    const [qty, setQty] = useState(1);
    // const [bondsDetail, setBondDetail] = useState<APIData.SGBDetail>();
    const [bondsDetail, setBondDetail] = useState<APIData.BondlistData>();
    const [bondsDetailCashflow, setBondDetailCashflow] = useState<APIData.BondCashFlow[]>([]);
    const navigate = useNavigate();
    const [validationFailed, setValidationFailed] = useState(false);
    const [calculatorType, setCalculatortype] = useState(false);
    const { setShowPreloader } = useAppStateAPI();
    const [pageno, setPageno] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [bondType, setBondType] = useState(0);
    const [Amount, setAmount] = useState('');
    const [TotalAmount, setTotalAmount] = useState('0');
    const [selectedFilters, setSelectedFilters] = useState<APIData.OrderFilters>({ ...InitialData.OrderFilterData });

    const bondAmount = () => {
        if (Amount != '') {
            setTotalAmount(parseInt(Amount) * qty + "");
        }
    }
    useEffect(() => {
        if (validationFailed != true) {
            bondAmount();
        }
    }, [validationFailed])

    const orderslist = (pageno: number, selectedFilters: any) => {
        ServerAPI.GetOrders(pageno, selectedFilters).then((response: any) => {
            if (response != undefined) {
                setOrdersList(response['data']);
                setTotalCount(response['count']);
            }
        })
    }

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("isin")) {
                const id = query.get("isin");
                setIsin((id!));
            }
        }
    }, [location]);

    const goToBuy = (isin: string) =>
        navigate({
            pathname: PageLinks.PAYMENTBOND,
            search: "?isin=" + isin + "&bond=" + bondPurchaseTypes.BONDS,
        });
    const Root = styled('span')(
        ({ theme }) => `
            font-size: 0;
            position: relative;
            display: inline-block;
            width: 40px;
            height: 24px;
            margin: 10px auto;
            cursor: pointer;
          
            &.${switchUnstyledClasses.disabled} {
              opacity: 0.4;
              cursor: not-allowed;
            }
          
            & .${switchUnstyledClasses.track} {
              background: #02AD41;
              border-radius: 20px;
              display: block;
              height: 40px;
              width: 62px;
              position: absolute;
            }
          
            & .${switchUnstyledClasses.thumb} {
              display: block;
              width: 34px;
              height: 34px;
              top: 4px;
              left: 4px;
              border-radius: 20px;
              background-color: #fff;
              position: relative;
              
              transition-property: all;
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              transition-duration: 120ms;
            }
          
            &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
              background-color:#CCCCCC;
              box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
            }
          
            &.${switchUnstyledClasses.checked} {
              .${switchUnstyledClasses.thumb} {
                left: 25px;
                top: 3px;
                background-color:#fff;
              }
          
              .${switchUnstyledClasses.track} {
                background: #02AD41;
                border:1px solid #02AD41;
              }
            }
          
            & .${switchUnstyledClasses.input} {
              cursor: inherit;
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              opacity: 0;
              z-index: 1;
              margin: 0;
            }
            `,
    );

    useEffect(() => {
        if (isin != '') {
            setShowPreloader(true);
            ServerAPI.BondDetail(isin).then((response: any) => {
                if (response['message']['bond_details'] != undefined) {
                    setBondDetail(response['message']['bond_details']);
                }
                if (response['message']['bond_cashflow_details'] != undefined) {
                    setBondDetailCashflow(response['message']['bond_cashflow_details']);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [isin])

    const [openDropDown, setOpenDropDown] = useState(true);
    const toggleDropdown = () => setOpenDropDown(!openDropDown);
    const [openEarnDropDown, setOpenEarnDropDown] = useState(true);
    const toggleEarnDropdown = () => setOpenEarnDropDown(!openEarnDropDown);

    useEffect(() => {
        console.log(pageno);
        console.log(selectedFilters);
        orderslist(pageno, selectedFilters);
    }, [pageno, selectedFilters])

    return (
        <div className="main-index-wrapper">
            <Header />

            <div className="body-overlay"></div>
            <main>
                <section className="page-title-area listing-search fix">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="widget-search-content faq-que-search">
                                    <form className="subscribe-form">
                                        <input type="text" placeholder="Bond name, issuer or bond type" />
                                        <button className="search-icon" onClick={(e: any) => {
                                            e.preventDefault();
                                        }}>Search</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="order_listing pt-30 pb-50 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-12 breadcums">
                                    <nav>
                                        <ul>
                                            <li className="cursor" onClick={(e: any) => {
                                                e.preventDefault();
                                                navigate(PageLinks.PROFILE);
                                            }}>Home</li>
                                            <li>Bonds</li>
                                            <li>Explore Bonds</li>
                                            <li className="active"> Sovereign Gold Bonds…</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="bond-gold pt-100 pb-20 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40 mt-1">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6 pt-100">
                                    <div className="section-title section-title-3 text-left text-md-left mb-30 pr-80 pr-xs-0">
                                        <h2>Invest In <span className="text-red">Sovereign Gold Bond</span></h2>
                                        <span className="small_text">Apply Online Through Your Demat Account And Save ₹50 Per Gram</span>
                                        <div className="text-bond pt-20">
                                            <div className="d-flex my-2">
                                                <span className="box-yellow  d-flex pr-3">
                                                    <p className="fs-15"> <span>Listed on BSE / NSE &nbsp;</span> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 103 103" className="text-right svg-gold">
                                                        <g id="Group_91" data-name="Group 91" transform="translate(0 0.004)">
                                                            <circle id="Ellipse_4" data-name="Ellipse 4" cx="51.5" cy="51.5" r="51.5" transform="translate(0 -0.004)" fill="#9F0704" />
                                                            <g id="Group_85" data-name="Group 85" transform="translate(20.425 17.118)">
                                                                <rect id="Rectangle_460" data-name="Rectangle 460" width="63.374" height="63.374" transform="translate(0)" fill="none" />
                                                                <path id="Checkbox" d="M25.131,43.706,0,18.575l5.1-5.1L25.131,33.144,58.275,0l5.1,5.1Z" transform="translate(0 11.883)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                    </svg> &nbsp;
                                                    </p>
                                                </span>
                                            </div>
                                            <div className="d-flex my-2">
                                                <span className="box-yellow  d-flex pr-3">
                                                    <p className="fs-15"> <span>+ 2.5% Interest Extra &nbsp;</span> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 103 103" className="text-right svg-gold">
                                                        <g id="Group_91" data-name="Group 91" transform="translate(0 0.004)">
                                                            <circle id="Ellipse_4" data-name="Ellipse 4" cx="51.5" cy="51.5" r="51.5" transform="translate(0 -0.004)" fill="#9F0704" />
                                                            <g id="Group_85" data-name="Group 85" transform="translate(20.425 17.118)">
                                                                <rect id="Rectangle_460" data-name="Rectangle 460" width="63.374" height="63.374" transform="translate(0)" fill="none" />
                                                                <path id="Checkbox" d="M25.131,43.706,0,18.575l5.1-5.1L25.131,33.144,58.275,0l5.1,5.1Z" transform="translate(0 11.883)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                    </svg> &nbsp;
                                                    </p>
                                                </span>
                                            </div>
                                            <div className="d-flex my-2">
                                                <span className="box-yellow  d-flex pr-3">
                                                    <p className="fs-15"> <span>+ Capital Gains Tax Benefit&nbsp;</span> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 103 103" className="text-right svg-gold">
                                                        <g id="Group_91" data-name="Group 91" transform="translate(0 0.004)">
                                                            <circle id="Ellipse_4" data-name="Ellipse 4" cx="51.5" cy="51.5" r="51.5" transform="translate(0 -0.004)" fill="#9F0704" />
                                                            <g id="Group_85" data-name="Group 85" transform="translate(20.425 17.118)">
                                                                <rect id="Rectangle_460" data-name="Rectangle 460" width="63.374" height="63.374" transform="translate(0)" fill="none" />
                                                                <path id="Checkbox" d="M25.131,43.706,0,18.575l5.1-5.1L25.131,33.144,58.275,0l5.1,5.1Z" transform="translate(0 11.883)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                    </svg> &nbsp;
                                                    </p>
                                                </span>
                                            </div>
                                            <div className="d-flex my-2">
                                                <span className="box-yellow  d-flex pr-3">
                                                    <p className="fs-15"> <span>36,741.27 Crore already Invested!&nbsp;</span> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 103 103" className="text-right svg-gold">
                                                        <g id="Group_91" data-name="Group 91" transform="translate(0 0.004)">
                                                            <circle id="Ellipse_4" data-name="Ellipse 4" cx="51.5" cy="51.5" r="51.5" transform="translate(0 -0.004)" fill="#9F0704" />
                                                            <g id="Group_85" data-name="Group 85" transform="translate(20.425 17.118)">
                                                                <rect id="Rectangle_460" data-name="Rectangle 460" width="63.374" height="63.374" transform="translate(0)" fill="none" />
                                                                <path id="Checkbox" d="M25.131,43.706,0,18.575l5.1-5.1L25.131,33.144,58.275,0l5.1,5.1Z" transform="translate(0 11.883)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                    </svg> &nbsp;
                                                    </p>
                                                </span>
                                            </div>
                                            <div className="d-flex my-2">
                                                <span className="box-yellow  d-flex pr-3">
                                                    <p className="fs-15"> <span>Issued by the Government of India &nbsp;</span> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 103 103" className="text-right svg-gold">
                                                        <g id="Group_91" data-name="Group 91" transform="translate(0 0.004)">
                                                            <circle id="Ellipse_4" data-name="Ellipse 4" cx="51.5" cy="51.5" r="51.5" transform="translate(0 -0.004)" fill="#9F0704" />
                                                            <g id="Group_85" data-name="Group 85" transform="translate(20.425 17.118)">
                                                                <rect id="Rectangle_460" data-name="Rectangle 460" width="63.374" height="63.374" transform="translate(0)" fill="none" />
                                                                <path id="Checkbox" d="M25.131,43.706,0,18.575l5.1-5.1L25.131,33.144,58.275,0l5.1,5.1Z" transform="translate(0 11.883)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                    </svg> &nbsp;
                                                    </p>
                                                </span>
                                            </div>

                                        </div>
                                        <button onClick={(e: any) => {
                                            e.preventDefault();
                                            navigate(PageLinks.APPLYBONDPURCHASE);
                                        }} className="spotlight_readmore bonds-detail-btn1 mt-3" style={{ padding: "3px 20px", fontSize: "20px" }}> Buy this BOND now!</button>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="img">
                                        <img src="../assets/img/SGB Bond banner image.png" alt="" className="w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="invest-text pt-100 pb-20 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40 mt-1">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="section-title section-title-3 text-left text-md-left mb-30 pr-80 pr-xs-0 gold-p">
                                        <h6>Investing In Sovereign Gold Bonds (Sgbs)</h6>
                                        <p>Issued by Reserve Bank Of India on behalf of Government of India, Sovereign Gold Bonds (SGBs) are the perfect alternative to investment in physical gold. With these bonds, you can enjoy capital appreciation and also earn interest every year. These bonds, issued by the Government of India, also eliminate several risks associated with physical gold.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="section-title section-title-3 text-left text-md-left mb-30 pr-80 pr-xs-0">
                                        <h6>Sovereign Gold Bond Benefits</h6>
                                    </div>
                                    <div className="box">
                                        <div className="bg-gray mt-2">
                                            <div className="row">
                                                <div className="col-lg-1 mx-2">
                                                    <img src="assets/img/icon/hassle_free.png" alt="" />
                                                </div>
                                                <div className="col-lg-10 mt-3">
                                                    <h5>Hassle-Free</h5>
                                                    <p>Ownership of gold without any physical possession (No risks and no cost of storage)</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-light-1 mt-2">
                                            <div className="row">
                                                <div className="col-lg-1 mx-2">
                                                    <img src="assets/img/icon/tax_treatments.png" alt="" />
                                                </div>
                                                <div className="col-lg-10 mt-2">
                                                    <h5>Tax Treatment</h5>
                                                    <p>The capital gains tax arising on redemption of SGB to an individual has been exempted. The indexation benefits will be provided to long term capital gains arising to any person on transfer of bond.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-light-2 mt-2">
                                            <div className="row">
                                                <div className="col-lg-1 mx-2">
                                                    <img src="assets/img/icon/tradability.png" alt="" />
                                                </div>
                                                <div className="col-lg-10 mt-3">
                                                    <h5>Tradability</h5>
                                                    <p>Bonds will be tradable on stock exchanges within a fortnight of the issuance on a date as notified by the RBI.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-light-3 mt-2">
                                            <div className="row">
                                                <div className="col-lg-1 mx-2">
                                                    <img src="assets/img/icon/transferability.png" alt="" />
                                                </div>
                                                <div className="col-lg-10 mt-3">
                                                    <h5>Transferability</h5>
                                                    <p>Bonds shall be transferable by execution of an Instrument of transfer in accordance with the provisions of the Government Securities Act.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card-box-shadow-1 p-3">
                            <div className="row  pb-3">
                                <div className="col-lg-1 col-md-3 col-sm-6">
                                    <img src="assets/img/img_placeholder.png" alt="sovereign" className="bond-title-img" />
                                </div>
                                <div className="col-lg-10 col-md-9 col-sm-6 ">
                                    <p>{bondsDetail?.bond_issuer_name}</p>

                                    <div className="d-flex my-2 ml-3">
                                        <a className="background-green title-background-style">
                                            <p className="fs-15 text-white">RETURNS Based On Market</p>
                                        </a>
                                        {bondsDetail?.bond_isin_number != '' &&
                                            <a className="background-grey title-background-style mx-2"><p className="fs-15-bond"> <span>ISIN:&nbsp;</span>{bondsDetail?.bond_isin_number}&nbsp;
                                                <span className="cursor" onClick={(e: any) => {
                                                    e.preventDefault();
                                                    copy(bondsDetail?.bond_isin_number!, {
                                                        debug: true,
                                                    });
                                                    toast.success('ISIN Number Copied To Clipboard');
                                                }}>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <Copy />
                                                </span></p>
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 " data-wow-delay="0.1s">
                                <div className="d-flex">
                                    <a className="background-lightblack text-white sourceSansPro-regular px-2 fs-12" >
                                        Eligible Investors*
                                    </a> <span className="triangle-right"></span>

                                    <a className="background-grey px-2 mx-2 fs-12">
                                        Individual
                                    </a>
                                    <a className="background-grey px-2 mx-2 fs-12">
                                        HUF
                                    </a>
                                    <a className="background-grey px-2 mx-2 fs-12">
                                        Corporate
                                    </a>
                                    <a className="background-grey px-2 mx-2 fs-12">
                                        Trust
                                    </a>
                                    <a className="background-grey px-2 mx-2 fs-12">
                                        Minor
                                    </a>
                                </div>

                                <div className=" mt-4 mb-4">
                                    <p className="title">Key Points To Consider</p>
                                    <span className="text-light sourceSansPro-regular">Face Value (In INR) and Issue Size (In INR Crores)</span>
                                </div>
                            </div>

                            <div className="row section-table mt-3 ml-0">
                                <div className="col-lg-4 col-md-4">
                                    <div className="row row-first mr-1">
                                        <div className="col-lg-6">
                                            <p>Rate of Int (Returns)</p>
                                            <h5>{bondsDetail?.bond_coupon_on != null && bondsDetail?.bond_coupon_on != '' ? bondsDetail?.bond_coupon_on + "% p.a" : "N/A"}</h5>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>Yield</p>
                                            <h5>{bondsDetail?.bonds_yeild != null && bondsDetail?.bonds_yeild != '' ? bondsDetail?.bonds_yeild + "%" : "N/A"}</h5>
                                        </div>
                                    </div>
                                    <div className="row row-sec mr-1 pb-1">
                                        <div className="col-lg-6">
                                            <p>Face Value</p>
                                            <h5>{bondsDetail?.bond_face_value != null && bondsDetail?.bond_face_value != '' ? "₹" + numberWithCommas(bondsDetail?.bond_face_value) : "N/A"}</h5>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>Issue Size (in Crores)</p>
                                            <h5>{bondsDetail?.bond_issue_size != null && bondsDetail?.bond_issue_size != '' ? "₹" + numberWithCommas(bondsDetail?.bond_issue_size) : "N/A"}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="row row-first  mr-1 pb-3 pt-2">
                                        <div className="col-lg-6">
                                            <p>Next Interest Payment Date</p>
                                            <h5>{moment(bondsDetail?.bonds_next_interest_payment_date).format("MMM Do YYYY")}</h5>
                                        </div>
                                        <div className="col-lg-6">

                                            <p>Interest Payment Frequency</p>
                                            {parseInt(bondsDetail?.bond_interest_type!) == interestPaymentFrequency.FOR_ANNUAL &&
                                                <p className="sourceSansPro-bold">Annual</p>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_type!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                <p className="sourceSansPro-bold">Monthly</p>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_type!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                <p className="sourceSansPro-bold">Quarterly</p>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_type!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                <p className="sourceSansPro-bold">Semi-Annual</p>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_type!) == null || parseInt(bondsDetail?.bond_interest_type!) == undefined || bondsDetail?.bond_interest_type! == "" &&
                                                <p className="sourceSansPro-bold">N/A</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="row row-sec mr-1 pb-2" style={{ minHeight: "72px" }}>
                                        <div className="col-lg-6">
                                            <p>Maturity after</p>
                                            <h5>{moment(bondsDetail?.bond_maturity_date).format("MMM Do YYYY")} (1st Exit Date)</h5>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>Purchase Limit</p>
                                            <h5>{bondsDetail?.bond_purchase_limit_metric} Kg/{bondsDetail?.bond_purchase_limit} units During each financial year</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="row row-first mr-1  pb-3 pt-2">
                                        <div className="col-lg-6">
                                            <p>Issuer</p>
                                            <h5>{bondsDetail?.bond_issuer_name}</h5>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>RBI Notice</p>
                                            <h5><u className="signup-terms_conditions">{bondsDetail?.bonds_banner_rcb_notice_link}</u></h5>
                                        </div>
                                    </div>
                                    <div className="row row-sec mr-1 pb-2" style={{ minHeight: "72px" }}>
                                        <div className="col-lg-6">
                                            <p>Govt. Guarantee</p>
                                            {bondsDetail?.bond_govt_guranatee == 1 ?
                                                <h5>Yes</h5>
                                                :
                                                <h5>No</h5>
                                            }
                                        </div>
                                        <div className="col-lg-6">
                                            <p>Exit Option Available</p>
                                            {bondsDetail?.exit_option_available == 1 ?
                                                <h5>Yes After the 5th year. Investors can exercise it on the next date.</h5>
                                                :
                                                <h5>No.</h5>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center pt-20 pb-10">
                                <div className="sovereign_gold_buynow_btn">
                                    <button className="buy_this_bond_btn" onClick={(e: any) => {
                                        e.preventDefault();
                                        navigate(PageLinks.APPLYBONDPURCHASE);
                                    }} >Buy this BOND now!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="sovereign_gold_bonds_invest_calculation pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                        <div className="container-fluid">
                            <div className="row detail-view-calculation">
                                <div className="col-lg-7 pl-30">
                                    <h3 style={{ marginTop: "30px" }}>Your Investment Calculator</h3>
                                    <div className="center">
                                        <img className="detail-one-investment-cal-img" src="assets/img/calculator.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-5 p-0 section">
                                    <div className="row d-flex section-head">
                                        <div className="col-lg-12">
                                            <p className="title">The maximum limit of subscription shall be 4 kg for individuals, 4 kg for Hindu Undivided Family (HUF) and 20 kg for trusts and similar entities notified by the government from time to time</p>
                                        </div>
                                    </div>
                                    <div className="row section-form align-items-center">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div>
                                                    <h6>Mode Of Investment</h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 pt-10 pb-10 row">
                                                <div className="col-lg-4 mt-3">
                                                    <p className={calculatorType == false ? "sgb-calculator-active sourceSansPro-bold" : "sgb-calculator-inactive sourceSansPro-regular"}>Gold Qty</p>
                                                </div>
                                                <div className="col-lg-4">
                                                    <SwitchUnstyled component={Root} onChange={(e: any) => {
                                                        if (e.target.checked == true) {
                                                            setCalculatortype(true);
                                                        } else {
                                                            setCalculatortype(false);
                                                        }
                                                    }} />
                                                </div>
                                                <div className="col-lg-4 mt-3">
                                                    <p className={calculatorType == true ? "sgb-calculator-active sourceSansPro-bold" : "sgb-calculator-inactive sourceSansPro-regular"}>Amount</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 pt-10 pb-10">
                                                {calculatorType == true ?
                                                    <div>
                                                        <h6>Enter Amount (A) </h6>
                                                        <p>(Range : {"₹" + (numberWithCommas(bondsDetail?.bonds_price_per_gram!) + "") + "-" + "₹" + numberWithCommas((bondsDetail?.bonds_price_per_gram!) * 4000) + ""})</p>
                                                    </div>
                                                    :
                                                    <div>
                                                        <h6>Enter gold quantity (A)</h6>
                                                        <p>(Range: 1 gm - 4000 gm)</p>
                                                    </div>
                                                }
                                            </div>
                                            {calculatorType == true ?
                                                <div className="col-lg-6 pt-10 pb-10">
                                                    <div className="row">
                                                        <input className="input-valu2" onBlur={(e: any) => {
                                                            e.preventDefault();
                                                            if ((e.target.value < bondsDetail?.bonds_price_per_gram!) || (e.target.value > ((bondsDetail?.bonds_price_per_gram!) * 4000))) {
                                                                toast.error("Please Enter Amount Within the Range");
                                                                setValidationFailed(true);
                                                            } else {
                                                                setTotalAmount(e.target.value);
                                                                if (bondsDetail?.bonds_price_per_gram != undefined) {
                                                                    setQty(parseInt(e.target.value) / (bondsDetail?.bonds_price_per_gram));
                                                                }
                                                                setValidationFailed(false);
                                                            }
                                                        }} />

                                                    </div>
                                                </div>
                                                :
                                                <div className="col-lg-6 pt-10 pb-10 pl-0 pr-0">
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <button onClick={(e: any) => {
                                                                e.preventDefault();
                                                                if (qty != 1) {
                                                                    setQty(qty - 1);
                                                                }
                                                            }} className="detail-view-calculator-button">-</button>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <input value={qty} onChange={(e: any) => {
                                                                e.preventDefault();
                                                                if (e.target.value > 4000 && e.target.value < 1) {
                                                                    toast.error("Please Enter the Quantity Within the Range");
                                                                } else {
                                                                    setQty(e.target.value);
                                                                }
                                                            }} className="input-value1 box-shadow sgb-calculator-input-1 w-100" />
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <button onClick={(e: any) => {
                                                                e.preventDefault();
                                                                setQty(qty + 1);
                                                            }} className="detail-view-calculator-button">+</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 pt-10 pb-10">
                                                <div>
                                                    <h6>Issue Price (B)</h6>
                                                    <p>(Price of 1 gram of gold)</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 pt-10 pb-10">
                                                <div className="row">
                                                    <input value={"₹" + numberWithCommas(Amount)} className="input-valu2" readOnly={true} />
                                                </div>
                                            </div>
                                        </div>
                                        {calculatorType == false ?
                                            <div className="row">
                                                <div className="col-lg-6 pt-10 pb-10">
                                                    <div>
                                                        <h6>Total Investment</h6>
                                                        <p>Amount (A) x Issue Price (B)</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 pt-10 pb-10">
                                                    <div className="row">
                                                        <input value={"₹" + numberWithCommas(TotalAmount)} className="input-valu2" readOnly={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="row">
                                                <div className="col-lg-6 pt-10 pb-10">
                                                    <div>
                                                        <h6>Total Investment</h6>
                                                        <p>Investment Grams</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 pt-10 pb-10">
                                                    <div className="row">
                                                        <input value={(parseInt(TotalAmount) / bondsDetail?.bonds_price_per_gram!).toFixed(2)} onChange={(e: any) => {
                                                            console.log(e.target.value);
                                                        }} className="input-valu2" readOnly={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="row align-items-center pt-20 pb-10">
                                        <div className="sovereign_gold_buynow_btn">
                                            <button className="buy_this_bond_btn" onClick={(e: any) => {

                                            }} >Buy SGB!</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {bondsDetail != undefined && bondsDetail.bond_cashflow_details.length > 0 &&
                                <div className="row">
                                    <p className="detail-view-calculation-dec-below" onClick={() => { toggleEarnDropdown() }}>WHAT WILL I EARN <i className="fa fa-angle-down" aria-hidden="true"></i></p>
                                </div>
                            }
                            {bondsDetail != undefined && bondsDetail.bond_cashflow_details.length > 0 &&
                                openEarnDropDown === true ?
                                <Fragment>
                                    <div className="row align-items-center sub_content">
                                        <div className="col-lg-7 left-content">
                                            {
                                                bondsDetail.bond_cashflow_details.map((cashflow: APIData.bondCashflowDetails) => {
                                                    return (
                                                        <Fragment>
                                                            <div className="row align-items-center p-1">
                                                                <div className="col-lg-4 white-bg sgb-calculator">
                                                                    <p>{bondsDetail.bond_name}</p>
                                                                </div>
                                                                <div className="col-lg-2 yellow-bg sgb-calculator">
                                                                    <p>Gold Price</p>
                                                                    <p>₹{numberWithCommas(bondsDetail.bonds_price_per_gram)}</p>
                                                                </div>
                                                                <div className="col-lg-2 light-green-bg sgb-calculator">
                                                                    <p>Interest*</p>
                                                                    <p>{cashflow.bond_cashflow_days}</p>
                                                                </div>
                                                                <div className="col-lg-4 blue-bg sgb-calculator">
                                                                    <p>Gold Bond Returns**</p>
                                                                    <p>₹{numberWithCommas(cashflow.bond_cashflow_amount)}</p>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    )

                                                })
                                            }
                                            <div className="desc">
                                                <p>* Interest = [{(2.5 % +"interest") + "*" + ("nominal Value")}X(8 years)]</p>
                                                <p>** Gold Bond Returns = (((Price at Maturity + Interest) - Price at Investment) / Price at Investment) * 100)</p>
                                                <p>Subject to applicable tax only to income & calculated on simple interest basis and it is the responsibility of the bond holder to comply with the tax laws.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 right-content">
                                            <div className="center">
                                                <img src="assets/img/soveregin_invest_img.svg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                                : ""
                            }
                        </div>
                    </section>
                    {bondsDetail?.bond_issuer_details[0]?.bond_issuer_documents != '' && bondsDetail?.bond_issuer_details[0]?.bond_issuer_documents != null &&
                        <DownloadIssueDocuments document={bondsDetail?.bond_issuer_documents} />
                    }
                    {bondsDetail != undefined && bondsDetail.bond_upcoming_issues.length > 0 &&
                        <section className="sovereign_gold_bonds_upload_issue pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                            <div className="container-fluid">
                                <div className="row align-items-center sub_content">
                                    <div className="col-lg-7 left-content">
                                        <h3>Upcoming issues</h3>
                                        <p>As declared by RBI, Gold bonds are issued every month. You can also easily plan your gold investments.</p>
                                        <div className="row align-items-center p-10">
                                            <div className="table-responsive">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">S.no</th>
                                                            <th scope="col">Issue Name*</th>
                                                            <th scope="col">Subscription Dates</th>
                                                            <th scope="col">Allotment Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {bondsDetail != undefined && bondsDetail.bond_upcoming_issues.length > 0 && bondsDetail.bond_upcoming_issues.map((upcoming: APIData.BondUpcomingIssues, index) => {
                                                            return (
                                                                <tr>
                                                                    <th>{index + 1}</th>
                                                                    <td>{upcoming.bond_upcoming_issues_name}</td>
                                                                    <td>{moment(upcoming?.bond_upcoming_issues_subscription_start_date).format("MMM Do YYYY")}&nbsp;- {moment(upcoming?.bond_upcoming_issues_subscription_end_date).format("MMM Do YYYY")}</td>
                                                                    <td>{moment(upcoming.bond_upcoming_issues_allotment_date).format("MMM Do YYYY")}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 right-content">
                                        <div className="center">
                                            <img style={{ width: "100%", height: "auto" }} src="assets/img/T09.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    <InvestSteps />
                    <Subscribe />
                    <NeedHelp />
                    < ReactPaginate
                        onPageChange={(e: any) => {
                            setPageno(e.selected + 1);
                        }}
                        breakLabel="..."
                        nextLabel=">"
                        pageRangeDisplayed={20}
                        pageCount={totalCount / 20}
                        previousLabel="<"
                        containerClassName="pagination"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        activeClassName="active"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                    />


                </section>
            </main>
            <Footer />
        </div>
    )
}

export default GoldBond;
