import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { ServerAPI } from "../common/ServerAPI";

const Subscribe: React.FC = () => {
    const [useEmail, setUserEmail] = useState('');
    const { setShowPreloader } = useAppStateAPI();

    const subscribe = () => {
        const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        if (useEmail != '' && !emailPattern.test(useEmail)) {
            toast.error("Please enter a Valid Email");
        } else {
            setShowPreloader(true);
            ServerAPI.SubscribeBonds(useEmail).then((res: any) => {
                if (res != undefined) {
                    toast.success(res['message']);
                    setUserEmail('');
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }
    return (
        <section className="subscribe-area pt-70 pb-70 float-left">
            <div className="container-fluid sub-section">
                <div className="col-12 subscribe_full">
                    <div className="col-lg-7 col-md-7 col-12">
                        <div className="your_story_head">
                            <span className="fs-24">Subscribe to</span>
                            <h3><span className="colored_text">Bond Updates</span></h3>
                            <span className="your_story_subhead">Subscribe to get regular updates on the latest on Bonds from Trust Money.</span>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-12">
                        <div className="subscribe-form quote-form mb-20">
                            <div className="email-input input_field pl-0">
                                <input type="text" value={useEmail} onChange={(e: any) => {
                                    setUserEmail(e.target.value);
                                }} placeholder="Enter your email here" />
                            </div>
                        </div>
                        <div className="view_all float-right">
                            <a style={{color:"#fff"}} onClick={(e: any) => {
                                e.preventDefault();
                                subscribe();
                            }} className="explore_btn">Subscribe <i className="fas fa-arrow-right mx-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Subscribe;
