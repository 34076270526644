import { useEffect } from "react";
import { getFormattedLocalDateTime } from "../common/Utilities";



const HeaderLogoContent: React.FC = () => {

    useEffect(()=>{
        console.log(localStorage.getItem('last_login'));
    },[])
    let lastlogin = localStorage.getItem('last_login');
    return (
        <div className="subscribe-wrapper">
            <div className="section-title section-title-3 text-center">
                <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
            </div>
            {lastlogin != undefined && lastlogin != null && lastlogin!='' &&
                <div className="welcomeback_div">
                    <p className="welcomeback_msg">Welcome Back !</p>
                    <p className="welcomeback_name">{localStorage.getItem('customerFirstName')} {localStorage.getItem('customerLastName')}</p>
                    <p className="welcomeback_time">Last login:{lastlogin}</p>
                </div>
            }
        </div>
    )
}

export default HeaderLogoContent;
