import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";

const EmailVerificationOTP: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate()
    const [useDetail, setUserDetails] = useState<APIData.userDetail>()
    const prevData: any = location.state;
    const [email_otp, setEmailOtp] = useState<APIData.otp_verify>(InitialData.verify_otp);
    const { setShowPreloader } = useAppStateAPI();

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res) => {
            setUserDetails(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }, [])

    // const resent_otp = () => {
    //     setShowPreloader(true);
    //     ServerAPI.ResendEmailOtp(prevData).then((res) => {
    //         toast.success(res['message'])
    //     }).finally(() => {
    //         setShowPreloader(false);
    //     })
    // }

    const validate = (values: APIData.otp_verify) => {
        let errors: any = {};
        if (values.otp == "") {
            errors.otp = "Otp is required";
        }
        return errors;
    }

    return (
        <div className="main-index-wrapper">
            <main>
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg pr-150 pl-150 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-11">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                            <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                            <h4 className="sub-title wow">Mobile Verification</h4>
                                            <p className="review-text text-center">Enter the 6 digits, One Time Password sent on your number <br></br>
                                                <a href="#" className="mobile_number">+91 9820098200 <i className="far fa-edit"></i></a>
                                            </p>
                                            <form className="login-form quote-form mb-20" action="#">
                                                <div className="email-input input_field">
                                                    <input type="password" placeholder="Enter OTP" />
                                                    <label className="input-title"><a href="#">Didn’t get OTP?</a></label>
                                                </div>
                                                <a href="contact.html" className="theme_btn resend_otp_btn sub-btn wow">Resend OTP</a>
                                            </form>
                                            <div className="divider_150"></div>
                                            <a href="contact.html" className="theme_btn primary_btn sub-btn wow">Verify</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="otp_popup">
                    <div className="blur_back"></div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg pt-150 pb-100 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 col-md-6 text-left" data-wow-delay="0.3s">
                                    <div className="do-box do-box-2 mb-30 email_verify_bg">
                                        <h6 className="mb--10 email_verify_head" data-wow-delay="0.1s">Email ID Verification</h6>
                                        <Formik initialValues={email_otp} validate={validate} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                            let passData: any;
                                            if (values.otp != "") {
                                                localStorage.setItem('animation', 'email');
                                                setShowPreloader(true);
                                                ServerAPI.EmailVerify(useDetail, values.otp).then((res) => {
                                                    toast.success(res['message']);
                                                    let is_email_verified = res.is_email_verified
                                                    passData = { ...useDetail, is_email_verified };
                                                }).finally(() => {
                                                    setTimeout(() => {
                                                        setShowPreloader(false);
                                                        localStorage.setItem('animation', 'default');
                                                        if (localStorage.getItem('update') != undefined && localStorage.getItem('update') == 'true') {
                                                            localStorage.setItem('update', '');
                                                            navigate(PageLinks.MY_ACCOUNT_PROFILE);
                                                        } else {
                                                            navigate(PageLinks.EMAIL_VERIFED_SUCCESS);
                                                        }
                                                    }, 10000);
                                                })
                                            }
                                        }}>
                                            {({
                                                values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                            }) => (

                                                <form className="email_verify_form quote-form mb-20" onSubmit={handleSubmit}>
                                                    <div className="email-input input_field pl-0 pr-0">
                                                        <p className="otp_text">Enter the 6 digits, One Time Password sent on Email ID : <Link to={"/email-verification"} >{useDetail?.email_id} <i className="fa fa-edit"></i></Link></p>
                                                        <input type="text" placeholder="Enter OTP"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="otp"
                                                            value={values.otp}
                                                            className={errors.otp && touched.otp ? "input-error" : ""} />
                                                        {errors.otp && touched.otp && (
                                                            <span className="error">{errors.otp}</span>
                                                        )}

                                                    </div>
                                                    <label className="input-title"><a href="#">Didn’t get OTP?</a></label>
                                                    {/* <a className="theme_btn resend_otp_btn_mobile sub-btn " data-wow-delay="0.5s" onClick={() => resent_otp()}>Resend OTP</a> */}
                                                    <div className="divider_15"></div>
                                                    <button type="submit" className="theme_btn email_verify_btn primary_btn_1 sub-btn" data-wow-delay="0.5s">Submit</button>
                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default EmailVerificationOTP;
