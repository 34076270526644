import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import { PageLinks, bondTypes } from "../../common/Constants";
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface FormParameters {
    review?: boolean;
    rating?: any;
    bondType?: any;
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiBackdrop-root': {
        backdropFilter: 'none'
    },
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            paddingBottom: '5px !important',
            paddingTop: '9px !important',
            backgroundColor: "#22263D",
            borderBottom: "1px solid #fff",


            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            // '&:active': {
            //     backgroundColor: alpha(
            //         theme.palette.primary.main,
            //         theme.palette.action.selectedOpacity,
            //     ),
            // },
            // '&:hover': {
            //     backgroundColor: '#833db2 !important',
            //     color: '#FFF !important'
            // }
        },
    },
}));

const SortBy: React.FC<FormParameters> = ({ review, rating, bondType }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedSort, setSelectedSort] = useState(0);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        console.log(bondType)
    }, [bondType])


    return (
        <Fragment>
           
            {
                bondType != bondTypes.IPO ?
                    <button onClick={(e: any) => { e.preventDefault(); navigate(PageLinks.DID_NOT); }} className="did-not-btn">
                        Did not find the BOND?</button> : ""
            }
            <Button style={{ backgroundColor: "fbf3f8", textTransform: 'lowercase', background: "none", color: "#22263D" }}
                // id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
            >

                <button className="sort_by">
                    Sort By &nbsp;&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 89.638 70.664">
                        <g id="Group_3307" data-name="Group 3307" transform="translate(2362.892 -1281.7)">
                            <path id="Path_22287" data-name="Path 22287" d="M-2279.312,1342.149c4.954-3.968,9.2-7.344,13.413-10.754,1.806-1.459,3.749-2.669,5.664-.5,2.147,2.425.354,4.131-1.537,5.652-5.837,4.693-11.728,9.328-17.494,14.107-2.332,1.932-4.273,2.132-6.706.1-5.845-4.883-11.84-9.586-17.786-14.349-1.833-1.468-3.32-3.163-1.453-5.407,1.829-2.2,3.8-1.2,5.649.3,4.228,3.438,8.489,6.835,13.533,10.888V1337.6q0-24.865,0-49.73c0-.886-.331-1.985.074-2.612a28.455,28.455,0,0,1,3.18-3.56c1.143,1.112,2.794,2.038,3.3,3.386.58,1.561.161,3.495.162,5.267C-2279.312,1307.3-2279.312,1324.248-2279.312,1342.149Z" transform="translate(-13.996 0)" />
                            <path id="Path_22288" data-name="Path 22288" d="M-2342.812,1291.881c-5.069,4.054-9.235,7.363-13.375,10.708-1.941,1.568-4.016,2.715-5.93.269-1.841-2.351-.046-3.945,1.716-5.358,5.85-4.686,11.763-9.3,17.523-14.083,2.355-1.96,4.313-2.125,6.728-.129,5.87,4.857,11.85,9.584,17.81,14.335,1.881,1.5,3.212,3.271,1.37,5.431s-3.841,1.077-5.672-.407c-4.23-3.424-8.492-6.808-13.518-10.832v4.642q0,24.874,0,49.749c0,.887.327,1.985-.08,2.615a28.873,28.873,0,0,1-3.176,3.583,23.76,23.76,0,0,1-3.281-3.532c-.461-.734-.118-1.975-.118-2.987q0-24.494,0-48.989Z" transform="translate(0 -0.039)" />
                        </g>
                    </svg></button>
                &nbsp;
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="popup-center"
            >
                <Fragment >
                    <MenuItem id='4' disableRipple>
                        <p className='fs-14 text-quick font-weight-600' style={{ color: "#fff" }}>Invesment</p>
                    </MenuItem>
                    <MenuItem id='4' className='border-top' disableRipple>
                        <div className="">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_1" id="account_type_1_option_1" value="1" />
                            <label htmlFor="account_type_1_option_1" className="gender_btn_sort_label">High Investment</label>
                        </div>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_1" id="account_type_1_option_2" value="1" />
                            <label htmlFor="account_type_1_option_2" className="gender_btn_sort_label">Low Investment</label>
                        </div>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <p className='fs-14 text-quick font-weight-600' style={{ color: "#fff" }}>Safety (Credit Rating)</p>
                    </MenuItem>
                    <MenuItem id='4' className='border-top' disableRipple>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_2" id="account_type_1_option_3" value="1" />
                            <label htmlFor="account_type_1_option_3" className="gender_btn_sort_label ">High Safety</label>
                        </div>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_2" id="account_type_1_option_4" value="1" />
                            <label htmlFor="account_type_1_option_4" className="gender_btn_sort_label ">Low Safety</label>
                        </div>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <p className='fs-14 text-quick font-weight-600' style={{ color: "#fff" }}>Yield</p>
                    </MenuItem>
                    <MenuItem id='4' className='border-top' disableRipple>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_3" id="account_type_1_option_5" value="1" />
                            <label htmlFor="account_type_1_option_5" className="gender_btn_sort_label ">High Yield</label>
                        </div>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_3" id="account_type_1_option_6" value="1" />
                            <label htmlFor="account_type_1_option_6" className="gender_btn_sort_label ">Low Yield</label>
                        </div>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <p className='fs-14 text-quick font-weight-600' style={{ color: "#fff" }}>Tenure</p>
                    </MenuItem>
                    <MenuItem id='4' className='border-top' disableRipple>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_4" id="account_type_1_option_7" value="1" />
                            <label htmlFor="account_type_1_option_7" className="gender_btn_sort_label ">High Tenure</label>
                        </div>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_4" id="account_type_1_option_8" value="1" />
                            <label htmlFor="account_type_1_option_8" className="gender_btn_sort_label ">Low Tenure</label>
                        </div>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <div className="spotlight_readmore_box mt-3">
                            <a className="trustclearbtn1 mx-3" onClick={(e: any) => {
                                e.preventDefault();
                                handleClose();
                            }}>Clear All</a>
                            <a className="Apply-btn mx-2" style={{ color: "#fff" }} onClick={(e: any) => {
                                e.preventDefault();
                                handleClose();
                            }}>Apply Filters</a>
                        </div>
                    </MenuItem>
                </Fragment>
            </StyledMenu>
        </Fragment>
    );
}
export default SortBy;
