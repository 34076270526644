import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { kycStatus, PageLinks, bondTypes } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";

const PayBondsuccess: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [bondtype, setBondType] = useState(0);
    const [orderID, setOrderID] = useState('0');
    var databond: any = {};
    useEffect(() => {
        databond = location.state;
        if (location.search !== "") {
            const query = new URLSearchParams(location.search);
            if (query.has("id")) {
                const id = query.get("id");
                setOrderID(id!);
            }
            if (query.has("bond")) {
                const bondType = query.get("bond");
                setBondType(parseInt(bondType!));
            }
        }
    }, [location]);

    const Pagenavigate = () => {
        if (bondtype == bondTypes.SGB) {

            navigate({
                pathname: PageLinks.BOND_CONFIRMED_ORDER,
                search: "?id=" + orderID + "&bond=" + bondtype,
            });
        } else if(databond.data.bond_type == bondTypes.ADDEDBOND ||databond.data.bond_type == bondTypes.EXISTINGBOND){
            navigate(PageLinks.BOND_CONFIRMED_ORDER, { state: { data: databond } });
        }
    }

    setTimeout(Pagenavigate, 3000);

    return (
        <section className="subscribe-letter-area animation-div">
            <div className="line-shape d-none d-lg-inline-block success-popup-bg-img">
                <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
            </div>
            <div className="container custom-container-subs">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12  text-left mt-150 m-res-0" data-wow-delay="0.3s">
                        <div className="do-box do-box-pan-status mb-30 email_verify_bg text-center cursor">
                            <video className="video-style svg-img-ani" autoPlay controls={false} loop width="428" height="428"  >

                                <source src="assets/animations/default_loader.mp4" type="video/mp4" />

                            </video>
                            <h3 className="text-process font-weight-600" data-wow-delay="0.1s">Processing...</h3>
                            <p className="text-play text-popup-p" data-wow-delay="0.1s">Please wait while we process your payment. <br></br> Do not refresh the page or click the "Back" or "Close" button of your browser.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PayBondsuccess;