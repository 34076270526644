import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SlideBar from "../../components/template/SlideBar";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { kycStatus, PageLinks, bondTypes } from "../../common/Constants";
import { APIData } from "../../common/DataTypes";
import { ServerAPI } from "../../common/ServerAPI";
import { ClearLocalStorageData } from "../../common/Utilities";
import { InitialData } from "../Form/InitialData";

const Header: React.FC = (props) => {

    const [customerFirstName, setCustomerFirstName] = useState('');
    const [customerLastName, setCustomerLastName] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [bondsDetail, setBondDetail] = useState<APIData.BondlistData>();
    const [sortName, setSortName] = useState('');
    const [isin1, setIsin1] = useState('');
    const [headerClass, setHeaderClass] = useState('main-header-area main-head-two');
    const [progressStatus, setProgressStatus] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
    const { setShowPreloader } = useAppStateAPI();
    const [scrollTop, setScrollTop] = useState(0);
    const [scrolling, setScrolling] = useState(false);
    const [headerListClass, setHeaderListClass] = useState('non-scroll-header');
    const [headerListIconClass, setHeaderListIconClass] = useState('');
    const [headerListLineClass, setHeaderListLineClass] = useState('');
    const [slidebar, setSlidebar] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<APIData.Filters>({ ...InitialData.FilterData });

    const [headerLogo, setHeaderLogo] = useState('assets/img/logo/header_logo_dark.svg');
const bondType = '4'
    const bondlist = () => {
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        ServerAPI.BondsList(1, '',selectedFilters , bondType).then((response: any) => {
            if (response != undefined) {
                setBondDetail(response['data']);
                setIsin1(response['data'][0]?.bond_isin_number)
                localStorage.setItem('isin1', response['data'][0]?.bond_isin_number)
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const bondlistHome = () => {
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        ServerAPI.BondsListHome(1, '',selectedFilters , bondType).then((response: any) => {
            if (response != undefined) {
                setBondDetail(response['data']);
                setIsin1(response['data'][0]?.bond_isin_number)
                localStorage.setItem('isin1', response['data'][0]?.bond_isin_number)
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    useEffect(() => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            bondlist();
        } else {
            bondlistHome();
        }

    }, [])
    // useEffect(() => {
       
    //     // const storedTimestamp = localStorage.getItem('loginTime');
    
    //     // if (storedTimestamp) {
    //     //     const storedDate = new Date(parseInt(storedTimestamp)).getTime();
    //     //   const currentTime = new Date().getTime();
    //     //   const sevenDaysInMillis = 60000; // 7 days in milliseconds
    
    //     //   if (currentTime - storedDate >= sevenDaysInMillis) {
    //     //     localStorage.clear();
    //     //   }
    //     // }
    //   }, []);
    // useEffect(() => {
    //     ServerAPI.RefreshToken().then((response: any) => {

    //     }).catch((error: any) => {
    //         localStorage.clear();
    //       });
    //   })
    useEffect(() => {
        let login = localStorage.getItem('login');
        let firstName: any = localStorage.getItem("customerFirstName");
        let lastName: any = localStorage.getItem("customerLastName");
        let customer_id: any = localStorage.getItem("customerid");
        setCustomerFirstName(firstName);
        setCustomerLastName(lastName);
        setCustomerId(customer_id);
        if (firstName && lastName != null && firstName && lastName != undefined) {
            let fullname = firstName.split("")[0] + lastName.split("")[0];
            let nameFirstLetter = fullname.toUpperCase();
            setSortName(nameFirstLetter);

            setShowPreloader(true);
            ServerAPI.ProgressBarStatusGet().then((response) => {
                setProgressStatus(response['message']);
            }).finally(() => {
                setShowPreloader(false);
            })
            if (login != undefined && login != null && login != '0') {
                ServerAPI.userDetailData().then((res: any) => {
                    if (res != undefined) {
                        setUserDetails(res);
                    }
                })
            }
        }



    }, []);

    useEffect(() => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
        ServerAPI.userDetailData().then((res: any) => {
            if (res != undefined) {
                setUserDetails(res);
            }
        }).catch((err) => {
           localStorage.clear();
        })
    }
        if (location.pathname == PageLinks.PROFILE) {
            setHeaderClass('main-header-area main-head-two');
            setHeaderLogo('assets/img/Trust_header_logo_2_Black.svg');
            setHeaderListClass('non-scroll-header');
            setHeaderListIconClass('header-search-icon');
            setHeaderListLineClass('vl');
        } else {
            setHeaderClass('main-header-area main-head-two header-bg');
            setHeaderLogo('assets/img/Trust_header_logo_2.svg');
            setHeaderListClass('scroll-header');
            setHeaderListIconClass('header-search-icon-white');
            setHeaderListLineClass('vl-white');
        }

    }, [])
    const goToIPO = () =>
        navigate({
            pathname: PageLinks.PROFILE_LISTING,
            search: "bond_type_ipo=" + bondTypes.IPO,
        });
    const goToBOND = () =>
        navigate({
            pathname: PageLinks.PROFILE_LISTING,
            search: "?bond_type_bond=" + bondTypes.ADDEDBOND + "&bond_type_bond1=" + bondTypes.EXISTINGBOND,
        });
    const goToSGB = (isin: string) =>
        navigate({
            pathname: PageLinks.SOVEREIGN_BONDS_LANDING_PAGE,
            search: "isin=" + localStorage.getItem('isin1'),
        });
    // useEffect(() => {
    //     if (isin1 != '') {
    //         setShowPreloader(true);
    //         ServerAPI.BondDetail(isin1).then((response: any) => {
    //             if (response != undefined) {
    //                 setBondDetail(response);
    //             }
    //         }).finally(() => {
    //             setShowPreloader(false);
    //         })
    //     }
    // }, [isin1])
    useEffect(() => {
        const onScroll = (e: any) => {
            if (window.scrollY == 0 && location.pathname == PageLinks.PROFILE) {
                setHeaderClass('main-header-area main-head-two');
                setHeaderLogo('assets/img/Trust_header_logo_2_Black.svg');
                setHeaderListClass('non-scroll-header');
                setHeaderListIconClass('header-search-icon');
                setHeaderListLineClass('vl');
            } else {
                setHeaderClass('main-header-area main-head-two header-bg');
                setHeaderLogo('assets/img/Trust_header_logo_2.svg');
                setHeaderListClass('scroll-header');
                setHeaderListIconClass('header-search-icon-white');
                setHeaderListLineClass('vl-white');
            }
            setScrollTop(e.target.documentElement.scrollTop);
            setScrolling(e.target.documentElement.scrollTop > scrollTop);
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);


    return (
        <header id="top-menu" className="transparent-head sticky-header">
            {/* For Header with BG call > header-bg  */}
            <div id="headerdiv" className={location.pathname == PageLinks.PROFILE ? headerClass : 'main-header-area main-head-two header-bg'}>
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-2 col-lg-2 col-md-7 col-8 responsive-menu align-items-center">
                            <div className="hamburger-menu d-lg-none d-block">
                                <a >
                                    <i style={{ color: "#ffff" }} className="far fa-bars" onClick={(e: any) => {
                                        e.preventDefault();
                                        setSlidebar(true);
                                    }}></i>
                                </a>
                            </div>
                            <div className="logo text-logo">
                                <a className="logo-img logo-w" onClick={(e: any) => {
                                    e.preventDefault();
                                    navigate(PageLinks.PROFILE);
                                }} >
                                    <img className="logo-1a" src={headerLogo} alt="" />
                                    <img className="logo-2" src={headerLogo} alt="" />
                                    <img className="logo-3" src={headerLogo} alt="" />
                                </a>
                            </div>
                        </div>

                        <div className="col-xl-10 col-lg-10 col-md-5 col-4 text-lg-right text-lg-center text-xl-right">
                            <div className="main-nav d-md-flex align-items-center justify-content-md-end">
                                <div className="main-menu d-none d-lg-block">
                                    <nav>
                                        <ul>
                                            <li><a onClick={(e: any) => {
                                                e.preventDefault();
                                                navigate(PageLinks.PROFILE);
                                            }} className={headerListClass}>Home</a>
                                            </li>
                                            <li><a onClick={(e: any) => {
                                                e.preventDefault();
                                                goToBOND()
                                            }} className={headerListClass}>Explore Bonds</a>
                                            </li>
                                             <li><a onClick={(e: any) => {
                                                e.preventDefault();
                                                goToIPO()
                                            }} className={headerListClass}>Bond IPOs</a>
                                            </li>
                                            <li><a onClick={(e: any) => {
                                                e.preventDefault();
                                                goToSGB(isin1)
                                            }} className={headerListClass}>Sovereign Gold Bond</a>
                                            </li>
                                            {/* <li><a className={headerListClass}>Bonds  <i className="far fa-chevron-down"></i></a> */}
                                                {/* <ul className="submenu">
                                                    <li><a onClick={(e: any) => {
                                                        e.preventDefault();
                                                        goToIPO()
                                                    }}>Bond IPOs</a>
                                                    </li>
                                                    <li><a onClick={(e: any) => {
                                                        e.preventDefault();
                                                        goToSGB(bondsDetail?.bond_isin_number!)
                                                    }}>Sovereign Gold Bond</a>
                                                    </li>
                                                    <li><a onClick={(e: any) => {
                                                        e.preventDefault();
                                                        goToBOND()
                                                    }}>Explore Bonds</a>
                                                    </li> */}

                                                    {/* <li><a onClick={(e: any) => {
                                                        e.preventDefault();
                                                        navigate(PageLinks.COLLECTION_OF_BONDS)
                                                    }}>Collection of Bonds</a></li> */}
                                                    {/* <li><a>How does it work?</a></li> */}
                                                    {/* <li onClick={(e: any) => {
                                                        e.preventDefault();
                                                        navigate(PageLinks.FAQ);
                                                    }}><a>Frequently Asked Questions</a></li> */}
                                                    {/* <li><a onClick={(e: any) => {
                                                        e.preventDefault();
                                                        navigate(PageLinks.LEARN_FROM_BOND)
                                                    }}>Learn, from Bond, Trust Bond</a></li>
                                                    <li onClick={(e: any) => {
                                                        e.preventDefault();
                                                        navigate(PageLinks.NEWSANDINSIGHTS);
                                                    }}><a>Bond ki Khaberin</a></li> */}
                                                {/* </ul>
                                            </li> */}
                                            {/* <li><a className={headerListClass}>Mutual Funds  <i className="far fa-chevron-down"></i></a></li>
                                            <li><a className={headerListClass}>Equity Trading  <i className="far fa-chevron-down"></i></a></li>
                                            <li><a className={headerListClass}>F&amp;O Trading  <i className="far fa-chevron-down"></i></a></li>
                                            <li><a className={headerListClass}>More  <i className="far fa-chevron-down"></i></a>
                                                <ul className="submenu">
                                                    <li className="hasdropdown"><a>Wealth Management <span className="dropdownarrow"><i className="far fa-angle-right"></i></span></a></li>
                                                    <li className="hasdropdown"><a>Insurance <span className="dropdownarrow"><i className="far fa-angle-right"></i></span></a>
                                                        <ul className="submenu_level2">
                                                            <li><a>Life Insurance</a></li>
                                                            <li><a>Investment Plans</a></li>
                                                            <li><a>Health Insurance</a></li>
                                                            <li><a>Car Insurance</a></li>
                                                            <li><a>Other Insurance</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li> */}
                                            {/* <li><a onClick={(e: any) => {
                                                e.preventDefault();
                                                navigate(PageLinks.CONTACT_US);
                                            }} className={headerListClass}>Contact Us</a></li> */}
                                            <li className={headerListLineClass}><a></a></li>
                                            <li><i className={headerListIconClass + " fa fa-search fw-400"} aria-hidden="true"></i></li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="right-nav d-flex align-items-center justify-content-end">


                                    <div className="hp-main-layout">
                                        <header>
                                            {customerFirstName == undefined || customerFirstName == null || customerLastName == undefined || customerLastName == null || customerId == undefined || customerId == null ?
                                                <Fragment>
                                                    <div className="quote-btn d-sm-block" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        navigate(PageLinks.LOGIN);
                                                    }}>
                                                        <p className="profile-signup trustWhitebtn">Sign Up / Sign In</p>
                                                    </div>
                                                </Fragment> :
                                                <div className="hover-dropdown-fade w-auto px-0 ms-6 position-relative hp-cursor-pointer">
                                                    <div className="avatar-item d-flex align-items-center justify-content-center rounded-circle">
                                                        {sortName}
                                                    </div>
                                                    <div className="quote-btn d-none d-sm-block">
                                                        <a className="theme_btn profile_top_btn user_login">{customerFirstName} {customerLastName} <i className="far fa-chevron-down"></i></a>
                                                    </div>
                                                    <div className="hp-header-profile-menu dropdown-fade position-absolute pt-18">
                                                        <div className="border_bottom_radius hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80">
                                                            <div className="profile_top_section p-12">
                                                                <div className="avatar-item avatar-res d-flex align-items-center justify-content-center rounded-circle">
                                                                    {sortName}
                                                                </div>
                                                                <div className="">
                                                                    <span className="d-block hp-text-color-black-100 hp-text-color-dark-0 mb-6 avatar-item_text user_login">{customerFirstName} {customerLastName} </span>
                                                                    <span className="d-block hp-text-color-black-100 hp-text-color-dark-0 mb-6 avatar-item_id">Customer ID: {customerId}</span>
                                                                </div>
                                                                {userDetail.ekyc_application_status == '0' || userDetail.ekyc_application_status == '' || userDetail.ekyc_application_status == null ?
                                                                    <div className="theme_btn profile_btn sub-btn cursor" onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        navigate(PageLinks.INSTRUCTION_SCREEN);
                                                                    }}>Profile <span className="status_highlight">(KYC Pending)</span></div>
                                                                    :
                                                                    <div className="theme_btn profile_btn sub-btn cursor" onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        navigate(PageLinks.MY_ACCOUNT_PROFILE);
                                                                    }}>Profile <span className="status_complete">(KYC Complete)</span></div>
                                                                }
                                                            </div>
                                                            <div className="row p-24">
                                                                {/* <div className="col-12">
                                                                    <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="ml-8 profile-menu-list">Watchlist</span>
                                                                    </a>
                                                                </div>
                                                                <div className="col-12">
                                                                    <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="hp-ml-8 profile-menu-list">Portfolio</span>
                                                                    </a>
                                                                </div> */}
                                                                <div className="col-12">
                                                                    <Link to={PageLinks.ORDER_LISTING} className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="hp-ml-8 profile-menu-list">Orders</span>
                                                                    </Link>
                                                                </div>
                                                                {/* <div className="col-12">
                                                                    <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="hp-ml-8 profile-menu-list">Add Funds</span>
                                                                    </a>
                                                                </div>
                                                                <div className="col-12">
                                                                    <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="hp-ml-8 profile-menu-list">Transaction Report</span>
                                                                    </a>
                                                                </div>
                                                                <div className="col-12">
                                                                    <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="hp-ml-8 profile-menu-list">Settings</span>
                                                                    </a>
                                                                </div> */}
                                                                <div className="col-12 cursor" onClick={(e: any) => {
                                                                    e.preventDefault();
                                                                    ClearLocalStorageData();
                                                                    navigate(PageLinks.LOGIN);
                                                                }}>
                                                                    <a className="d-flex align-items-center hp-p1-body py-4 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded" style={{ marginLeft: "-10px", marginRight: "-10px" }}>
                                                                        <span className="hp-ml-8 profile-menu-list">Logout</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </header>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>

            <aside className={slidebar === false ? 'slide-bar-menu ' : ' slide-bar-menu show'}>
                <div className="close-mobile-menu" onClick={(e: any) => {
                    e.preventDefault();
                    setSlidebar(false);
                }}>
                    <a href="javascript:void(0);"><i className="fas fa-times"></i></a>
                </div>

                {/* side-mobile-menu start */}
                <nav className="side-mobile-menu menu-ul">
                    <div className="accordion-wrapper border-bottom">

                        <div
                            className={`accordion-title ${isOpen ? "open" : ""}`}
                            onClick={() => setOpen(!isOpen)}
                        >
                            BONDS
                        </div>
                        <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
                            <div className="accordion-content p-0">

                                <ul className="submenu">
                                    <li><a onClick={(e: any) => {
                                        e.preventDefault();
                                        goToIPO()
                                    }}>Bond IPO</a>
                                    </li>
                                    <li><a onClick={(e: any) => {
                                        e.preventDefault();
                                        goToSGB(isin1)
                                    }}>Sovereign Gold Bond</a>
                                    </li>
                                    <li><a onClick={(e: any) => {
                                        e.preventDefault();
                                        goToBOND()
                                    }}>Explore Bonds</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <ul id="mobile-menu-active">
                    <li className="has-dropdown">
                        <a href="index.html">Home</a>
                        <ul className="sub-menu">
                            <li><a href="index.html">Home Style 1</a></li>
                            <li><a href="index-2.html">Home Style 2</a></li>
                            <li><a href="index-3.html">Home Style 3</a></li>
                            <li><a href="index-dark.html">Home Dark 1 </a></li>
                            <li><a href="index-dark-2.html">Home Dark 2</a></li>
                            <li><a href="index-dark-3.html">Home Dark 3</a></li>
                        </ul>
                    </li>
                    <li><a href="#">Insurance</a></li>
                    <li className="has-dropdown">
                        <a href="#">Pages</a>
                        <ul className="sub-menu">
                            <li><a href="about.html">About us</a></li>
                            <li><a href="case-one.html">Case Study</a></li>
                            <li><a href="price.html">Price</a></li>
                            <li><a href="testimonial.html">Testimonial</a></li>
                            <li><a href="team.html">Team</a></li>
                            <li><a href="faq.html">FAQ</a></li>
                            <li><a href="error.html">Error</a></li>
                        </ul>
                    </li>
                    <li className="has-dropdown"><a href="#">Blogs</a>
                        <ul className="sub-menu">
                            <li><a href="blog-grid.html">Blog Grid</a></li>
                            <li><a href="blog-standard.html">Blog Standard</a></li>
                            <li><a href="blog-details.html">Blog Details</a></li>
                        </ul>
                    </li>
                    <li><a href="#">Contacts Us</a></li>
                </ul> */}
                </nav>
                {/* side-mobile-menu end */}
            </aside>
        </header>
    )
}

export default Header;
