import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const InvestSteps: React.FC = () => {
    return (
        <section className="simple_steps mb-5 p-20">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-8 simple_steps_arrow">
                        <div className="simple_steps_head">Invest in 3 Simple <br></br> Steps</div>
                        <div className="simple_steps_box_one">
                            <div className="simple_steps_box_head">Register & Complete KYC</div>
                            <div className="simple_steps_box_subhead">Upload your documents online</div>
                        </div>
                        <div className="simple_steps_box_two">
                            <div className="simple_steps_box_head">Choose Bonds</div>
                            <div className="simple_steps_box_subhead">Select bonds that match your investment goal</div>
                        </div>
                        <div className="simple_steps_box_three">
                            <div className="simple_steps_box_head">Make Investment</div>
                            <div className="simple_steps_box_subhead">Pay online and receive bond units in your Demat account</div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    </div>
                </div>
            </div>
            <img src="assets/img/mobile_bg.png" alt="" className="image_responsive d-lg-none d-md-none"/>
        </section>
        
        )
}

export default InvestSteps;
