import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { kycStatus, PageLinks, bondTypes } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";


const EsignCancel: React.FC = () => {
    const location = useLocation();
    const { setShowPreloader } = useAppStateAPI();
    const [esignClicked, setEsignClicked] = useState(false);
    var wind: any;
    const navigate = useNavigate();

    const goToBOND = () =>
    navigate({
        pathname: PageLinks.PROFILE_LISTING,
        search:  "?bond_type_bond=" + bondTypes.ADDEDBOND + "?bond_type_bond1=" + bondTypes.EXISTINGBOND ,
    });

    return (
        <section className="subscribe-letter-area pt-200 pb-200">
            <div className="line-shape d-none d-lg-inline-block">
                <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
            </div>
            <div className="container custom-container-subs">
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-6  text-left" data-wow-delay="0.3s">
                        <div className="do-box do-box-pan-status mb-30 email_verify_bg text-center">

                            <img src="assets/img/icon/zero_credit.svg" className="user_icon w-25 pb-0" />
                            <p className="mb-10  text-dark-p" data-wow-delay="0.1s">Thanks for your Request!</p>
                            <p className="mb-10  text-dark-des " data-wow-delay="0.1s">Our Trustmony Team member will get in touch with you shortly.</p>
                            <div className="mt-4 col-md-12 m-auto col-xl-10 row col-12">
                                <div className="col-md-6 col-xl-6 col-12">
                                    <button onClick={(e: any) => {
                                        e.preventDefault();
                                         goToBOND()
                                    }} type="submit" className=" order-confirm-page-home-btn_enq w-100" data-wow-delay="0.5s">Explore Other Bonds</button>

                                </div>
                                <div className="col-md-6 col-xl-6 col-12">
                                    <button onClick={() => {
                                         navigate(PageLinks.PROFILE);
                                    }} type="submit" className="spotlight_readmore bonds-detail-btn btn-1 mx-2 mt-1 w-100" data-wow-delay="0.5s">Go to home</button>
                                </div>
                            </div>
                           

                        </div>
                    </div>
                </div>
            </div>

        </section>

    );
};

export default EsignCancel;
