
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DialogTitle } from "@mui/material";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
}


const Category: React.FC<FormParameters> = ({ showModal, closeModal }) => {

    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog
                    open={showModal}
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    maxWidth="md"
                >
                    <DialogTitle className="head"> <p>Investor Category</p>
                        <button onClick={(e: any) => {
                            e.preventDefault();
                            closeModal();
                        }} className="theme_btn profile_close_btn sub-btn wow " data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</button>
                    </DialogTitle>
                    <DialogContent className="cancelled-cheque-bg" style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description">
                        <h3>Category I</h3>
                           <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                           <h3>Category II</h3>
                           <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                           <h3>Category III</h3>
                           <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </main>
        </div>
    )
}

export default Category;