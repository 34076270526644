import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import { interestPaymentFrequency, bondTypes, NatureofInstruments, bondPurchaseTypes, PageLinks } from "../../common/Constants";
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { APIData } from "../../common/DataTypes";
import { ServerAPI } from "../../common/ServerAPI";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { InitialData } from "../../components/Form/InitialData";

interface FormParameters {
    review?: boolean;
    rating?: any;
    bondType?: any;
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiBackdrop-root': {
        backdropFilter: 'none'
    },
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            paddingBottom: '5px !important',
            paddingTop: '9px !important',
            backgroundColor: "#22263D",
            borderBottom: "1px solid #fff",


            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            // '&:active': {
            //     backgroundColor: alpha(
            //         theme.palette.primary.main,
            //         theme.palette.action.selectedOpacity,
            //     ),
            // },
            // '&:hover': {
            //     backgroundColor: '#833db2 !important',
            //     color: '#FFF !important'
            // }
        },
    },
}));

const SortByRes: React.FC<FormParameters> = ({ review, rating, bondType }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [BondName, setBondName] = useState('');
    const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
    const [selectedSort, setSelectedSort] = useState(0);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const open1 = Boolean(anchorEl1);
    const { setShowPreloader } = useAppStateAPI();
    const [selectedFilters, setSelectedFilters] = useState<APIData.Filters>({ ...InitialData.FilterData });
    const [bondsFilters, setBondsFilters] = useState<APIData.BondFilters>();
    const [show, setShow] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick1 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        console.log(bondType)
    }, [bondType])

    const bondFilters = () => {
        setShowPreloader(true);
        ServerAPI.GetBondFilter().then((response: any) => {
            if (response != undefined) {
                setBondsFilters(response);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    useEffect(() => {
        bondFilters();
    }, [])

    const handleSelectedFilter = (type: string, updateValue: number, checked: boolean) => {
        if (checked === true) {
            if (type === 'brand_tag') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, brand_tag_id: [...selectedFilters.brand_tag_id, updateValue + ""] }))
            } else if (type === 'yield_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_from: updateValue }))
            } else if (type === 'yield_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_to: updateValue }))
            } else if (type === 'tenure_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_from: updateValue }))
            } else if (type === 'tenure_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_to: updateValue }))
            } else if (type === 'rating') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, bond_rating_list_id: [...selFilters.bond_rating_list_id, updateValue + ""] }))
            } else if (type === 'frequency') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, payment_frequency: [...selFilters.payment_frequency, updateValue + ""] }))
            }
        } else {
            if (type === 'brand_tag') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, brand_tag_id: selFilters.brand_tag_id.filter((val: any) => val != updateValue) }));
            } else if (type === 'yield_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_from: 0 }))
            } else if (type === 'yield_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_to: 0 }))
            } else if (type === 'tenure_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_from: 0 }))
            } else if (type === 'tenure_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_to: 0 }))
            } else if (type === 'frequency') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, payment_frequency: selFilters.payment_frequency.filter((val: any) => val != updateValue) }));
            } else if (type === 'rating') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, bond_rating_list_id: selFilters.bond_rating_list_id.filter((val: any) => val != updateValue) }));
            }
        }
    }


    return (
        <Fragment>
            {/* <Button style={{ backgroundColor: "fbf3f8", textTransform: 'lowercase', background: "none", color: "#22263D" }}
                // id="demo-customized-button1"
                aria-controls={open1 ? 'demo-customized-filter' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick1}
            >
                <button className="did_not">
                    Filter <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 82.325 70.665">
                        <g id="Group_3305" data-name="Group 3305" transform="translate(1590.298 -1424.861)">
                            <path id="Path_22284" data-name="Path 22284" d="M-1508.005,1666.622h-1.705q-16.43-.01-32.859-.039a1.5,1.5,0,0,0-1.678,1.235,11.1,11.1,0,0,1-10.667,7.763,11.063,11.063,0,0,1-10.416-7.8,1.491,1.491,0,0,0-1.7-1.2c-7.29.039-14.58.032-21.87.037-1.367,0-1.366,0-1.368-1.477,0-1.12.047-2.243-.025-3.358-.06-.925.224-1.237,1.116-1.224,3.239.047,6.48.016,9.72.019,4.192,0,8.382-.008,12.573.034a1.379,1.379,0,0,0,1.547-1.111,11.146,11.146,0,0,1,10.7-7.879,11.315,11.315,0,0,1,10.4,7.894,1.332,1.332,0,0,0,1.539,1.076q16.324-.031,32.647-.012a4.83,4.83,0,0,1,1.365.052,1.071,1.071,0,0,1,.65.731C-1507.977,1663.039-1508.005,1664.716-1508.005,1666.622Zm-46.778,2.906a5.678,5.678,0,0,0,5.578-5.866,5.813,5.813,0,0,0-5.614-6,5.915,5.915,0,0,0-5.624,5.928A5.786,5.786,0,0,0-1554.783,1669.528Z" transform="translate(0 -180.055)" />
                            <path id="Path_22285" data-name="Path 22285" d="M-1563.394,1547.438c8.418,0,16.836-.016,25.252.021a1.765,1.765,0,0,0,2.009-1.4,10.868,10.868,0,0,1,10.556-7.558,10.975,10.975,0,0,1,10.4,7.647c.354,1.031.828,1.375,1.824,1.311,1.439-.092,2.887-.025,4.331-.055.722-.014,1.108.2,1.066,1.079-.062,1.3-.06,2.614,0,3.917.039.872-.328,1.1-1.059,1.089-1.585-.03-3.171,0-4.755-.041-.707-.021-1.048.257-1.3.975-1.769,4.956-5.907,8.03-10.658,7.989s-8.743-3.042-10.533-8.007a1.285,1.285,0,0,0-1.429-.979q-25.576.027-51.15.033c-1.389,0-1.389,0-1.389-1.449,0-1.157.057-2.318-.018-3.47-.058-.9.257-1.152,1.072-1.146,4.191.032,8.384.014,12.576.014h13.21Zm37.9-2.924a5.763,5.763,0,0,0-5.767,5.691,5.778,5.778,0,0,0,5.431,6.154,5.792,5.792,0,0,0,5.786-5.805A5.818,5.818,0,0,0-1525.5,1544.514Z" transform="translate(-0.031 -90.232)" />
                            <path id="Path_22286" data-name="Path 22286" d="M-1589.995,1439.708v-5.786c.285-.019.585-.056.885-.057,2.854,0,5.706-.028,8.559.014a1.438,1.438,0,0,0,1.62-1.169,11.04,11.04,0,0,1,10.813-7.85,11.083,11.083,0,0,1,10.255,7.728,1.656,1.656,0,0,0,1.872,1.3c15.639-.031,31.277-.008,46.917-.053,1.114,0,1.4.343,1.325,1.441-.081,1.225-.039,2.462-.011,3.693.014.632-.239.882-.807.869-.669-.015-1.338-.02-2.007-.02q-22.93,0-45.86-.019a1.152,1.152,0,0,0-1.3.937,11.4,11.4,0,0,1-10.715,8.08,11.333,11.333,0,0,1-10.527-8.027,1.246,1.246,0,0,0-1.389-1.017c-2.782.04-5.564.038-8.347.039C-1589.125,1439.811-1589.535,1439.746-1589.995,1439.708Zm21.577-8.833a5.787,5.787,0,0,0-5.546,6.037,5.861,5.861,0,0,0,5.6,5.907,5.917,5.917,0,0,0,5.617-6.021A5.821,5.821,0,0,0-1568.418,1430.875Z" transform="translate(-0.235)" />
                        </g>
                    </svg></button></Button>
            <StyledMenu
                id="demo-customized-filter"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button1',
                }}
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose1}
                className="popup-center"
            >
                <Fragment >
                    {bondsFilters != undefined && <>
                        <MenuItem id='4' disableRipple>
                            <p className='fs-14 text-quick font-weight-600' style={{ color: "#fff" }}>Investment Purpose</p>
                        </MenuItem>
                        <MenuItem id='4' className='border-top' disableRipple>
                            <span className="input-title-filled gender_col purpose_popup" role="group">
                                {bondsFilters?.brand_tags != undefined && bondsFilters.brand_tags.length > 0 && bondsFilters.brand_tags.map((tags: APIData.FilterBrandTags, index: number) => {
                                    return (
                                        <div className={selectedFilters.brand_tag_id.includes(tags.brand_tag_id + "") ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                            <input type="checkbox" className="d-none"
                                                onClick={(e: any) => {
                                                    handleSelectedFilter('brand_tag', tags.brand_tag_id, e.target.checked);
                                                }}
                                                name={tags.brand_tag_name} id={tags.brand_tag_id + ""} value={tags.brand_tag_id} />
                                            <label htmlFor={tags.brand_tag_id + ""} className="gender_btn-label-filter">{tags.brand_tag_name}</label>
                                        </div>
                                    )
                                })}
                            </span>
                        </MenuItem>
                        <MenuItem id='4' disableRipple>
                            <p className='fs-14 text-quick font-weight-600' style={{ color: "#fff" }}>Safety (Credit Rating)</p>
                        </MenuItem>
                        <MenuItem id='4' className='border-top' disableRipple>
                            <span className="input-title-filled gender_col purpose_popup" role="group">
                                {bondsFilters.bond_ratings != undefined && bondsFilters.bond_ratings.length > 0 && bondsFilters.bond_ratings.map((rating: APIData.FilterBondRatings) => {
                                    return (
                                        <div className={selectedFilters.bond_rating_list_id.includes(rating.bond_rating_list_id + "") ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                            <input type="checkbox" onClick={(e: any) => { handleSelectedFilter('rating', rating.bond_rating_list_id, e.target.checked) }} className="d-none" name={rating.bond_rating_name} id={"rating" + rating.bond_rating_list_id + ""} value={rating.bond_rating_list_id} />
                                            <label htmlFor={"rating" + rating.bond_rating_list_id + ""} className="gender_btn-label-filter">{rating.bond_rating_name}</label>
                                        </div>
                                    )

                                })}
                            </span>
                        </MenuItem>
                        <MenuItem id='4' disableRipple>
                            <p className='fs-14 text-quick font-weight-600' style={{ color: "#fff" }}> Yield</p>
                        </MenuItem>
                        <MenuItem id='4' className='border-top' disableRipple>
                            <span className="input-title-filled gender_col purpose_popup" role="group">
                                <div className={selectedFilters.yield_to == 8 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                    <input type="checkbox" className="d-none" name="yield_1" id="yield_1" value="1" onClick={(e: any) => { handleSelectedFilter('yield_from', 1, e.target.checked); handleSelectedFilter('yield_to', 8, e.target.checked) }} />
                                    <label htmlFor="yield_1" className="gender_btn-label-filter">Upto 8%</label>
                                </div>
                                <div className={selectedFilters.yield_to == 11 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                    <input type="checkbox" className="d-none" name="yield_2" id="yield_2" value="2" onClick={(e: any) => { handleSelectedFilter('yield_from', 8, e.target.checked); handleSelectedFilter('yield_to', 11, e.target.checked) }} />
                                    <label htmlFor="yield_2" className="gender_btn-label-filter">8 - 11%</label>
                                </div>
                                <div className={selectedFilters.yield_to == 100 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                    <input type="checkbox" className="d-none" name="yield_3" id="yield_3" value="3" onClick={(e: any) => { handleSelectedFilter('yield_from', 11, e.target.checked); handleSelectedFilter('yield_to', 100, e.target.checked) }} />
                                    <label htmlFor="yield_3" className="gender_btn-label-filter">11% +</label>
                                </div>
                            </span>
                        </MenuItem>
                        <MenuItem id='4' disableRipple>
                            <p className='fs-14 text-quick font-weight-600' style={{ color: "#fff" }}>  Payment Frequency</p>
                        </MenuItem>
                        <MenuItem id='4' className='border-top' disableRipple>
                            <span className="input-title-filled gender_col purpose_popup" role="group">
                                {bondsFilters.payment_frequency != undefined && bondsFilters.payment_frequency.length > 0 && bondsFilters.payment_frequency.map((frequency: APIData.FilterPaymentFrequency) => {
                                    return (
                                        <div className={selectedFilters.payment_frequency.includes(frequency.payment_frequency_type + "") ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                            {frequency.payment_frequency_type !== 0 && <input type="checkbox" className="d-none" name={"frequency" + frequency.payment_frequency_type + ""} id={"frequency" + frequency.payment_frequency_type + ""} value={frequency.payment_frequency_type} onClick={(e: any) => { handleSelectedFilter('frequency', frequency.payment_frequency_type, e.target.checked) }} />}
                                            {frequency.payment_frequency_type == interestPaymentFrequency.FOR_ANNUAL &&
                                                <label htmlFor={"frequency" + frequency.payment_frequency_type + ""} className="gender_btn-label-filter">Annual</label>
                                            }
                                            {frequency.payment_frequency_type == interestPaymentFrequency.FOR_MONTHLY &&
                                                <label htmlFor={"frequency" + frequency.payment_frequency_type + ""} className="gender_btn-label-filter">Monthly</label>
                                            }
                                            {frequency.payment_frequency_type == interestPaymentFrequency.FOR_QUARTERLY &&
                                                <label htmlFor={"frequency" + frequency.payment_frequency_type + ""} className="gender_btn-label-filter">Quarterly</label>
                                            }
                                            {frequency.payment_frequency_type == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                <label htmlFor={"frequency" + frequency.payment_frequency_type + ""} className="gender_btn-label-filter">Semi-Annual</label>
                                            }
                                        </div>
                                    )
                                })
                                }
                            </span>
                        </MenuItem>
                        <MenuItem id='4' disableRipple>
                            <p className='fs-14 text-quick font-weight-600' style={{ color: "#fff" }}>  Tenure Remaining</p>
                        </MenuItem>
                        <MenuItem id='4' className='border-top' disableRipple>
                            <span className="input-title-filled gender_col purpose_popup" role="group">
                                <div className={selectedFilters.tenure_to == 1 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                    <input type="checkbox" className="d-none" name="tenure_1" id="tenure_1" value="1" onClick={(e: any) => { handleSelectedFilter('tenure_from', 0, e.target.checked); handleSelectedFilter('tenure_to', 1, e.target.checked) }} />
                                    <label htmlFor="tenure_1" className="gender_btn-label-filter">Less than 1 year</label>
                                </div>
                                <div className={selectedFilters.tenure_to == 5 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                    <input type="checkbox" className="d-none" name="tenure_2" id="tenure_2" value="2" onClick={(e: any) => { handleSelectedFilter('tenure_from', 1, e.target.checked); handleSelectedFilter('tenure_to', 5, e.target.checked) }} />
                                    <label htmlFor="tenure_2" className="gender_btn-label-filter">1 to 5 Years</label>
                                </div>
                                <div className={selectedFilters.tenure_to == 10 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                    <input type="checkbox" className="d-none" name="tenure_3" id="tenure_3" value="3" onClick={(e: any) => { handleSelectedFilter('tenure_from', 5, e.target.checked); handleSelectedFilter('tenure_to', 10, e.target.checked) }} />
                                    <label htmlFor="tenure_3" className="gender_btn-label-filter">5 to 10 Years</label>
                                </div>
                                <div className={selectedFilters.tenure_to == 100 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                    <input type="checkbox" className="d-none" name="tenure_4" id="tenure_4" value="4" onClick={(e: any) => { handleSelectedFilter('tenure_from', 10, e.target.checked); handleSelectedFilter('tenure_to', 100, e.target.checked) }} />
                                    <label htmlFor="tenure_4" className="gender_btn-label-filter">More than 10 years</label>
                                </div>
                            </span>
                        </MenuItem>
                        <MenuItem id='4' disableRipple>
                            <div className="filter_box_buttons">
                                <div className="spotlight_readmore_box mt-4">
                                    <button className="trustclearbtn mx-2" onClick={(e: any) => {
                                        e.preventDefault();
                                        setSelectedFilters({ ...InitialData.FilterData });
                                    }}>Clear All</button>
                                    <button className="trustallbtn mx-2" style={{ color: "#fff" }} onClick={(e: any) => {
                                        e.preventDefault();
                                    }}>Apply Filters</button>
                                </div>
                            </div>
                        </MenuItem>
                    </>}

                </Fragment>
            </StyledMenu> */}
            <Button style={{ backgroundColor: "fbf3f8", textTransform: 'lowercase', background: "none", color: "#22263D" }}
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu1' : undefined}
                aria-haspopup="false"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
            >

                <button className="sort_by_res">
                    Sort By &nbsp;&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 89.638 70.664">
                        <g id="Group_3307" data-name="Group 3307" transform="translate(2362.892 -1281.7)">
                            <path id="Path_22287" data-name="Path 22287" d="M-2279.312,1342.149c4.954-3.968,9.2-7.344,13.413-10.754,1.806-1.459,3.749-2.669,5.664-.5,2.147,2.425.354,4.131-1.537,5.652-5.837,4.693-11.728,9.328-17.494,14.107-2.332,1.932-4.273,2.132-6.706.1-5.845-4.883-11.84-9.586-17.786-14.349-1.833-1.468-3.32-3.163-1.453-5.407,1.829-2.2,3.8-1.2,5.649.3,4.228,3.438,8.489,6.835,13.533,10.888V1337.6q0-24.865,0-49.73c0-.886-.331-1.985.074-2.612a28.455,28.455,0,0,1,3.18-3.56c1.143,1.112,2.794,2.038,3.3,3.386.58,1.561.161,3.495.162,5.267C-2279.312,1307.3-2279.312,1324.248-2279.312,1342.149Z" transform="translate(-13.996 0)" />
                            <path id="Path_22288" data-name="Path 22288" d="M-2342.812,1291.881c-5.069,4.054-9.235,7.363-13.375,10.708-1.941,1.568-4.016,2.715-5.93.269-1.841-2.351-.046-3.945,1.716-5.358,5.85-4.686,11.763-9.3,17.523-14.083,2.355-1.96,4.313-2.125,6.728-.129,5.87,4.857,11.85,9.584,17.81,14.335,1.881,1.5,3.212,3.271,1.37,5.431s-3.841,1.077-5.672-.407c-4.23-3.424-8.492-6.808-13.518-10.832v4.642q0,24.874,0,49.749c0,.887.327,1.985-.08,2.615a28.873,28.873,0,0,1-3.176,3.583,23.76,23.76,0,0,1-3.281-3.532c-.461-.734-.118-1.975-.118-2.987q0-24.494,0-48.989Z" transform="translate(0 -0.039)" />
                        </g>
                    </svg></button>
                &nbsp;
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="popup-center"
            >
                <Fragment >
                <MenuItem id='4' disableRipple className='justify-content-between'>
                        <p className='mb-0 font-weight-600 fs-24 text-white'>Sort by</p>
                        <a href="javascript:void(0)" className='text-white'  ><i className="fas fa-times" onClick={handleClose}></i></a>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <p className='fs-14 text-quick font-weight-600' style={{ color: "#fff" }}>Invesment</p>
                    </MenuItem>
                    <MenuItem id='4' className='border-top' disableRipple>
                        <div className="">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_1" id="account_type_1_option_1" value="1" />
                            <label htmlFor="account_type_1_option_1" className="gender_btn_sort_label">High Investment</label>
                        </div>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_1" id="account_type_1_option_2" value="1" />
                            <label htmlFor="account_type_1_option_2" className="gender_btn_sort_label">Low Investment</label>
                        </div>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <p className='fs-14 text-quick font-weight-600' style={{ color: "#fff" }}>Safety (Credit Rating)</p>
                    </MenuItem>
                    <MenuItem id='4' className='border-top' disableRipple>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_2" id="account_type_1_option_3" value="1" />
                            <label htmlFor="account_type_1_option_3" className="gender_btn_sort_label ">High Safety</label>
                        </div>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_2" id="account_type_1_option_4" value="1" />
                            <label htmlFor="account_type_1_option_4" className="gender_btn_sort_label ">Low Safety</label>
                        </div>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <p className='fs-14 text-quick font-weight-600' style={{ color: "#fff" }}>Yield</p>
                    </MenuItem>
                    <MenuItem id='4' className='border-top' disableRipple>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_3" id="account_type_1_option_5" value="1" />
                            <label htmlFor="account_type_1_option_5" className="gender_btn_sort_label ">High Yield</label>
                        </div>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_3" id="account_type_1_option_6" value="1" />
                            <label htmlFor="account_type_1_option_6" className="gender_btn_sort_label ">Low Yield</label>
                        </div>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <p className='fs-14 text-quick font-weight-600' style={{ color: "#fff" }}>Tenure</p>
                    </MenuItem>
                    <MenuItem id='4' className='border-top' disableRipple>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_4" id="account_type_1_option_7" value="1" />
                            <label htmlFor="account_type_1_option_7" className="gender_btn_sort_label ">High Tenure</label>
                        </div>
                        <div className=" ">
                            <input type="radio" className="d-none gender_btn_sort" name="account_type_4" id="account_type_1_option_8" value="1" />
                            <label htmlFor="account_type_1_option_8" className="gender_btn_sort_label ">Low Tenure</label>
                        </div>
                    </MenuItem>
                    <MenuItem id='4' disableRipple>
                        <div className="spotlight_readmore_box mt-3">
                            <a className="trustclearbtn1 mx-3" onClick={(e: any) => {
                                e.preventDefault();
                                handleClose();
                            }}>Clear All</a>
                            <a className="Apply-btn mx-2" style={{ color: "#fff" }} onClick={(e: any) => {
                                e.preventDefault();
                                handleClose();
                            }}>Apply Filters</a>
                        </div>
                    </MenuItem>
                </Fragment>
            </StyledMenu>
        </Fragment>
    );
}
export default SortByRes;
