
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DialogTitle } from "@mui/material";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
}


const MoreInfo: React.FC<FormParameters> = ({ showModal, closeModal }) => {

    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog
                    open={showModal}
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    maxWidth="lg"
                >
                    <DialogTitle> <p>Bond Details</p>
                        <button onClick={(e: any) => {
                            e.preventDefault();
                            closeModal();
                        }} className="theme_btn profile_close_btn sub-btn wow " data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</button>
                    </DialogTitle>
                    <DialogContent className="cancelled-cheque-bg" style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description">
                        <h3>Key Highlights</h3>
                            <div className="col-lg-12">
                               
                            <ul className="ipo-bond-list list1">
                                <li className="mb-3">This bond can be considered for medium term Investment purpose with annual returns. Coupon is 13.75% i.e you will receive Rs. 137,500.0 annual interest for every 10 lac Investment. It has call option on 24-Jan-2025.</li>
                                <li className="mb-3">The bond issuer is of type Private Sector Bank. The Issuer raised total Rs. 500.0 Cr for this bond.</li>
                                <li className="mb-3">Use of Proceeds: Augmentation of tier-i capital to strengthen its capital adequacy and to enhance long-term resources.</li>
                                <li className="mb-3">It is rated A- by IND with STABLE outlook.</li>
                            </ul>
                            </div>
                          <h3>About this Bond</h3>
                          <p>STATE DEVELOPMENT LOAN is a multi-billion dollar, transnational conglomerate. The Group’s activities span three core areas: Investment Banking, International Trading and Global Investments. It also supports charitable and philanthropic activities across the world through the Mahindra & Mahindra Foundation.</p>
                                    <div className="mt-3">
                                        <div className="row detail-view-financial">
                                            <div className="d-flex">
                                                <h6 className="ipo-flow-issuer-title">Issuer</h6>
                                                <span className="triangle-right-ipo"></span>
                                            </div>
                                            <div className="col-lg-6 section-left">
                                                <h3>STATE DEVELOPMENT LOAN</h3>
                                                <p className="underline2"></p>
                                                
                                                    <h6 className="mt-2">Issuer's Type: NBFC</h6>
                                                <p className="font-bold">
                                                STATE DEVELOPMENT LOAN is a multi-billion dollar, transnational conglomerate. The Group’s activities span three core areas: Investment Banking, International Trading and Global Investments. It also supports charitable and philanthropic activities across the world through the Mahindra & Mahindra Foundation.
                                                </p>
                                            </div>
                                            <div className="col-lg-6">
                                                <p className="section-right-border-length box-data"></p>
                                                <div className="section-right1 p-2">
                                                    <div className="row">
                                                        <div className="col-lg-4 pt-10 pb-10">
                                                            <div>
                                                                <p>Total Annual Revenue</p>
                                                                <h6>4,731 Cr (FY 21)</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 pt-10 pb-10">
                                                            <div>
                                                                <p>Year of Inception</p>
                                                                <h6>2007</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 pt-10 pb-10">
                                                            <div>
                                                                <p>Industry</p>
                                                                <h6>NBFC Retail</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 pt-10 pb-10">
                                                            <div>
                                                                <p>Head Office</p>
                                                                <h6>Mumbai</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 pt-10 pb-10">
                                                            <div>
                                                                <p>Type of Issuer</p>
                                                                <h6>Corporate</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 pt-10 pb-10">
                                                            <div>
                                                                <p>Current MD/CEO</p>
                                                                <h6>Rajashree Nambiar</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row">
                                                        
                                                            <div className="col-lg-4 pt-10 pb-10 cursor">
                                                                <a><u>Issuers Profile</u> <i className="fa fa-external-link" aria-hidden="true"></i></a>
                                                            </div>
                                                       
                                                            <div className="col-lg-7 d-flex pt-10 pb-10 cursor" 
                                                            >
                                                                <a><u>Issuers Finance Details</u> <i className="fa fa-external-link" aria-hidden="true"></i></a>
                                                            </div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </main>
        </div>
    )
}

export default MoreInfo;