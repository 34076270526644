import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Footer from "../components/template/Footer";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import InvestSteps from "../components/InvestSteps";
import YourStory from "../components/YourStory";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import ReactSimplyCarousel from 'react-simply-carousel';
import { useState } from "react";

const LearnFromBond: React.FC = () => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    return (
        <div className="main-index-wrapper">
            <Header />

            <div className="body-overlay"></div>
            <main>
                <section className="learn_from_bond pt-110 pb-50 pt-md-45 pb-md-40 pt-xs-70 pb-xs-40">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li className="active">Learn, from Bond, Trust Bond</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="learn_from_bond_box">
                            <div className="col-lg-8 learn_from_bond_box_left">
                                <div className="section-title section-title-3 prot_md text-left text-md-left mb-30 pr-80 pr-xs-0">
                                    <h3 className="mb-30">Seekho toh sirf <br></br><span className="colored_text"> Bond, Trust Bond</span> se</h3>
                                    <h4 className="sub-title mb-10">Come learn from the best in the business about the benefits and associated risks of investing in the fixed-income market, investment options and a lot more.</h4>
                                </div>
                                <div className="learn_from_bond_box_btn">
                                    <a href="#" className="theme_btn learn_from_bond_readmore">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 p-sm-none learn_from_bond_editor_top_picks">
                            <div className="view_all">
                                <div className="learn_from_bond_editor_top_picks_head">Editors Top Picks</div>
                                <a href="#" className="explore_btn w-auto view_btn_none">View All <i className="fas fa-arrow-right"></i></a>
                            </div>
                            <div className="editor_top_picks_box">
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="triangle-left"></div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="view_all view_responsive">
                                    <a href="#" className="explore_btn w-auto view_btn_block">View All <i className="fas fa-arrow-right"></i></a>
                                </div>
                            </div>

                        </div>

                        <section className="about_what_we_offer company_history pt-30 pb-0">
                            <div className="custom-container-team">
                                <div className="col-lg-12  p-sm-none  invest_in_india">
                                    <div className="view_all">
                                        <div className="learn_from_bond_editor_top_picks_head">Invest In India</div>
                                        <a href="#" className="theme_btn theme_btn2 explore_btn w-auto view_btn_none">View All <i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                                <div className="row justify-content-center mb-30 mt-5">
                                    <div className="invest_in_india_box">
                                        <ReactSimplyCarousel
                                            infinite={true}
                                            activeSlideIndex={activeSlideIndex}
                                            onRequestChange={setActiveSlideIndex}
                                            itemsToShow={3}
                                            itemsToScroll={1}
                                            containerProps={{
                                                style: {
                                                    width: "100%",
                                                }
                                            }}
                                            forwardBtnProps={{
                                                //here you can also pass className, or any other button element attributes
                                                className: "btn_scroll",
                                                style: {
                                                    alignSelf: 'center',
                                                    background: '#00C6D8',
                                                    border: 'none',
                                                    borderRadius: '0px 25px 25px 0px',
                                                    color: 'white',
                                                    cursor: 'pointer',
                                                    fontSize: '20px',
                                                    height: 49,
                                                    lineHeight: 1,
                                                    textAlign: 'center',
                                                    width: 40,
                                                    boxShadow: "0px 3px 30px #00000029",
                                                },
                                                children: <span>{`>`}</span>,
                                            }}
                                            backwardBtnProps={{
                                                //here you can also pass className, or any other button element attributes
                                                className: "btn_scroll",
                                                style: {
                                                    alignSelf: 'center',
                                                    background: '#00C6D8',
                                                    border: 'none',
                                                    borderRadius: '25px 0px 0px 25px',
                                                    color: 'white',
                                                    cursor: 'pointer',
                                                    fontSize: '20px',
                                                    height: 49,
                                                    lineHeight: 1,
                                                    textAlign: 'center',
                                                    width: 40,
                                                    boxShadow: "0px 3px 30px #00000029",
                                                    marginRight: "30px"
                                                },
                                                children: <span>{`<`}</span>,
                                            }}
                                            responsiveProps={[
                                                {
                                                    itemsToShow: 3,
                                                    itemsToScroll: 1,
                                                    minWidth: 768,
                                                },
                                            ]}
                                            speed={400}
                                            easing="linear"
                                        >
                                            {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

                                            <div className="story_box story-box-without-shadow">
                                                <div className="story_box_left">
                                                    WHAT ARE THE ADVANTAGES OF INVESTING IN BOND IPO
                                                    <div className="story_box_right_date">Jan 24, 2022</div>
                                                    <div className="triangle-left"></div>
                                                    <div className="story_box_right_mins">5 min read</div>
                                                </div>
                                            </div>
                                            <div className="story_box story-box-without-shadow">
                                                <div className="story_box_left">
                                                    WHAT ARE THE ADVANTAGES OF INVESTING IN BOND IPO
                                                    <div className="story_box_right_date">Jan 24, 2022</div>
                                                    <div className="triangle-left"></div>
                                                    <div className="story_box_right_mins">5 min read</div>
                                                </div>
                                            </div>
                                            <div className="story_box story-box-without-shadow">
                                                <div className="story_box_left">
                                                    WHAT ARE THE ADVANTAGES OF INVESTING IN BOND IPO
                                                    <div className="story_box_right_date">Jan 24, 2022</div>
                                                    <div className="triangle-left"></div>
                                                    <div className="story_box_right_mins">5 min read</div>
                                                </div>
                                            </div>
                                            <div className="story_box story-box-without-shadow">
                                                <div className="story_box_left">
                                                    WHAT ARE THE ADVANTAGES OF INVESTING IN BOND IPO
                                                    <div className="story_box_right_date">Jan 24, 2022</div>
                                                    <div className="triangle-left"></div>
                                                    <div className="story_box_right_mins">5 min read</div>
                                                </div>
                                            </div>
                                            <div className="story_box story-box-without-shadow">
                                                <div className="story_box_left">
                                                    WHAT ARE THE ADVANTAGES OF INVESTING IN BOND IPO
                                                    <div className="story_box_right_date">Jan 24, 2022</div>
                                                    <div className="triangle-left"></div>
                                                    <div className="story_box_right_mins">5 min read</div>
                                                </div>
                                            </div>
                                            <div className="story_box story-box-without-shadow">
                                                <div className="story_box_left">
                                                    WHAT ARE THE ADVANTAGES OF INVESTING IN BOND IPO
                                                    <div className="story_box_right_date">Jan 24, 2022</div>
                                                    <div className="triangle-left"></div>
                                                    <div className="story_box_right_mins">5 min read</div>
                                                </div>
                                            </div>
                                        </ReactSimplyCarousel>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="about_what_we_offer company_history pt-30 pt-sm-0 pb-0">
                            <div className="custom-container-team">
                                <div className="col-lg-12 p-sm-none invest_in_india">
                                    <div className="view_all">
                                        <div className="learn_from_bond_editor_top_picks_head">Bonds And Debt</div>
                                        <a href="#" className="theme_btn theme_btn2 explore_btn w-auto view_btn_none">View All <i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                                <div className="row justify-content-center mb-30 mt-5">
                                    <div className="invest_in_india_box">
                                        <ReactSimplyCarousel
                                            infinite={true}
                                            activeSlideIndex={activeSlideIndex}
                                            onRequestChange={setActiveSlideIndex}
                                            itemsToShow={3}
                                            itemsToScroll={1}
                                            containerProps={{
                                                style: {
                                                    width: "100%",
                                                }
                                            }}
                                            forwardBtnProps={{
                                                //here you can also pass className, or any other button element attributes
                                                className: "btn_scroll",
                                                style: {
                                                    alignSelf: 'center',
                                                    background: '#00C6D8',
                                                    border: 'none',
                                                    borderRadius: '0px 25px 25px 0px',
                                                    color: 'white',
                                                    cursor: 'pointer',
                                                    fontSize: '20px',
                                                    height: 49,
                                                    lineHeight: 1,
                                                    textAlign: 'center',
                                                    width: 40,
                                                    boxShadow: "0px 3px 30px #00000029",
                                                },
                                                children: <span>{`>`}</span>,
                                            }}
                                            backwardBtnProps={{
                                                //here you can also pass className, or any other button element attributes
                                                className: "btn_scroll",
                                                style: {
                                                    alignSelf: 'center',
                                                    background: '#00C6D8',
                                                    border: 'none',
                                                    borderRadius: '25px 0px 0px 25px',
                                                    color: 'white',
                                                    cursor: 'pointer',
                                                    fontSize: '20px',
                                                    height: 49,
                                                    lineHeight: 1,
                                                    textAlign: 'center',
                                                    width: 40,
                                                    boxShadow: "0px 3px 30px #00000029",
                                                    marginRight: "30px"
                                                },
                                                children: <span>{`<`}</span>,
                                            }}
                                            responsiveProps={[
                                                {
                                                    itemsToShow: 3,
                                                    itemsToScroll: 1,
                                                    minWidth: 768,
                                                },
                                            ]}
                                            speed={400}
                                            easing="linear"
                                        >
                                            {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

                                            <div className="story_box story-box-without-shadow">
                                                <div className="story_box_left">
                                                    IS BONDS GOOD INVESTMENT COMPARED WITH MUTUAL FUNDS?
                                                    <div className="story_box_right_date">Jan 24, 2022</div>
                                                    <div className="triangle-left"></div>
                                                    <div className="story_box_right_mins">5 min read</div>
                                                </div>
                                            </div>
                                            <div className="story_box story-box-without-shadow">
                                                <div className="story_box_left">
                                                    HOW NCD IPO IS BETTER THAN STOCK IPO
                                                    <div className="story_box_right_date">Jan 24, 2022</div>
                                                    <div className="triangle-left"></div>
                                                    <div className="story_box_right_mins">5 min read</div>
                                                </div>
                                            </div>
                                            <div className="story_box story-box-without-shadow">
                                                <div className="story_box_left">
                                                    IS BONDS GOOD INVESTMENT COMPARED WITH MUTUAL FUNDS?
                                                    <div className="story_box_right_date">Jan 24, 2022</div>
                                                    <div className="triangle-left"></div>
                                                    <div className="story_box_right_mins">5 min read</div>
                                                </div>
                                            </div>
                                            <div className="story_box story-box-without-shadow">
                                                <div className="story_box_left">
                                                    HOW NCD IPO IS BETTER THAN STOCK IPO
                                                    <div className="story_box_right_date">Jan 24, 2022</div>
                                                    <div className="triangle-left"></div>
                                                    <div className="story_box_right_mins">5 min read</div>
                                                </div>
                                            </div>
                                            <div className="story_box story-box-without-shadow">
                                                <div className="story_box_left">
                                                    IS BONDS GOOD INVESTMENT COMPARED WITH MUTUAL FUNDS?
                                                    <div className="story_box_right_date">Jan 24, 2022</div>
                                                    <div className="triangle-left"></div>
                                                    <div className="story_box_right_mins">5 min read</div>
                                                </div>
                                            </div>
                                            <div className="story_box story-box-without-shadow">
                                                <div className="story_box_left">
                                                    HOW NCD IPO IS BETTER THAN STOCK IPO
                                                    <div className="story_box_right_date">Jan 24, 2022</div>
                                                    <div className="triangle-left"></div>
                                                    <div className="story_box_right_mins">5 min read</div>
                                                </div>
                                            </div>
                                        </ReactSimplyCarousel>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="col-lg-12 row tax_savings p-sm-none">
                            <div className="col-lg-5 col-md-6 col-sm-12 col-12 p-sm-none">
                                <div className="your_story_head">
                                    <h3>TAX Savings</h3>
                                </div>
                                <div className="story_box story_box1">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">Bank FD’s vs Nothing Safer</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box story_box1">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box story_box1">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box story_box1">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="view_all ">
                                    <a href="#" className="explore_btn w-auto">View All <i className="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div className="offset-lg-1 col-lg-5 col-md-6 col-sm-12 col-12 p-sm-none">
                                <div className="your_story_head">
                                    <h3>Personal Finance</h3>
                                </div>
                                <div className="story_box story_box2">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">DEBT OR EQUITY? OR A COMBINATION OF BOTH!</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box story_box2">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box story_box2">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="story_box story_box2">
                                    <div className="story_box_left"><img src="assets/img/icon/story_left_img.png" /></div>
                                    <div className="story_box_right">
                                        <div className="story_box_right_head">What are 54EC Bonds or Capital Gain Bonds</div>
                                        <div className="story_box_right_date">Jan 24, 2022</div>
                                        <div className="story_box_right_mins">5 min read</div>
                                    </div>
                                </div>
                                <div className="view_all">
                                    <a href="#" className="explore_btn w-auto">View All <i className="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                <Subscribe />
                <NeedHelp />
            </main >
            <Footer />
        </div >
    )
}

export default LearnFromBond;