import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Footer from "../components/template/Footer";

const RefundPolicy: React.FC = () => {

    return (
        <div className="main-index-wrapper">
            <Header />
            
            <div className="body-overlay"></div>
            <main>
                <section className="what_are_advantages pt-110 pb-50 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li className="active">Refund Policy</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 what_are_advantages_full">
                            <div className="col-lg-12 what_are_advantages_full_left privacy_para_text">
                                <div className="what_are_advantages_full_left_text">
                                    <p>
                                        All Subscription made through this website are settled through BSE & NSE Subsidiaries and hence, subscription made cannot be refunded.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default RefundPolicy;
