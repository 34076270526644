import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import ChequeUpload from "./ChequeUpload";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "../../common/Constants";
import { DialogTitle } from "@mui/material";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
}

const PhotoExample: React.FC<FormParameters> = ({ showModal, closeModal }) => {
    const navigate = useNavigate();

    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog className="dialog-background"
                    open={showModal}
                    keepMounted
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            closeModal();
                        }
                    }}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    PaperProps={{
                        style: {
                            background: 'none',
                            boxShadow: 'none',
                        },
                    }}
                    maxWidth="sm"
                >
                    <DialogContent style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div className="mb-30 email_verify_bg text-center photo-sample-body">
                                <p className="photo-sample-head">Code Position Example</p>
                                <img src="assets/img/icon/photosample.png" className="user_icon_photo_sample" />
                                <div className="mt-3 col-md-12 col-xl-12 row col-12">
                                    <div className="col-md-12 col-xl-12 col-12">
                                        <button onClick={(e: any) => {
                                            e.preventDefault();
                                            closeModal();
                                        }} type="submit" className="theme_btn email_verify_btn primary_btn_1 sub-btn " data-wow-delay="0.5s">Close</button>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

            </main>
        </div>
    )
}

export default PhotoExample;