import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { kycStatus, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";
import PoliticallyExposed from "../components/Modals/PoliticallyExposed";
import StepsComponent from "../components/StepsComponent";
import EmailOTPVerify from "../components/Modals/EmailOTPVerify";
import { useAppStateAPI } from "../common/AppStateAPI";
import HeaderLogoContent from "../components/HeaderLogoContent";
import { kycStatusUpdate } from "../common/Utilities";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";
import EmailVerification from "./EmailVerification";
import AppologiesPopup from "./AppologiesPopup";
import AppologiesPopupModel from "../components/Modals/AppologiesPopupModel";
import EmailOTPVerifyexists from "../components/Modals/EmailOTPVerifyexists";
const maxDate = moment(new Date()).format('YYYY-MM-DD');
const PersonalDetailsEdit: React.FC = () => {
    const navigate = useNavigate();
    const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
    const [politicallyExposed, setPoliticallyExposed] = useState(false);
    const [emailID, setEmailID] = useState("");
    const [emailerror, setEmailerror] = useState("");
    const [agree1, setAgree1] = useState(false);
    const [apologyPopup, setApologyPopup] = useState(false);
    const [ageValidationFailed, setValidationFailed] = useState(false);
    const [agree2, setAgree2] = useState(false);
    const [KycExit, setKycExit] = useState(false);
    const [emailVerifyModal, setEmailVerifyModal] = useState(false);
    const [emailVerifyModalExists, setEmailVerifyModalExists] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const { setShowPreloader } = useAppStateAPI();
    const [startDate, setStartDate] = useState<Date>();
    const [emailtrue, setEmailtrue] = useState(0);
    const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;


    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: APIData.userDetail) => {
            setUserDetails(res);
            setEmailtrue(res?.is_email_verified)
        }).finally(() => {
            setShowPreloader(false);
        })
    }, []);

    useEffect(() => {
        if (moment().diff(moment(startDate), "years") < 18 && startDate || startDate == null) {
            setValidationFailed(true);
        } else {
            setValidationFailed(false);
        }
    }, [startDate]);

    const validate = (values: APIData.userDetail) => {
        let errors: any = {};
        if (values.firstname == '' || values.firstname == null) {
            errors.firstname = "First Name is Required";
        }
        if (values.lastname == '' || values.lastname == null) {
            errors.lastname = "Last Name is Required";
        }
        if (values.dob == '') {
            errors.dob = "Please Enter Valid Date of Birth";
        }
        let agelimitvalidate = agelimit(new Date(values.dob));
        if (agelimitvalidate == false) {
            errors.dob = "To open an account the user should be at-least 18 years of age";
        }
       
        return errors;
    }

    const agelimit = (dob: Date) => {
        const date18YrsAgo = new Date();
        date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
        return dob <= date18YrsAgo;
    }

    return (
        <div className="main-index-wrapper">
            <main>
                {/* Profile-details-edit-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <HeaderLogoContent />
                                    <StepsComponent step={1} />
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow page-main-heading" data-wow-delay="0.1s">Personal Details <span className="page-main-heading-steps">STEPS - 1 of 2</span></h6>

                                                            <hr></hr>
                                                            <Formik initialValues={userDetail} validate={validate} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                                console.log("🚀 ~ emailtrue:", emailtrue)
                                                                console.log("🚀 ~ emailVerified:", emailVerified)
                                                                var test = false;
                                                                    if (startDate == null) {
                                                                        toast.error("please Enter a valid date");
                                                                        test = true;
                                                                    }
                                                                    if (agree1 == false || agree2 == false) {
                                                                        toast.error("Please accept all the terms and conditions to proceed.");
                                                                        test = true;
                                                                    }
                                                                    if(!test && (emailtrue || emailVerified)){
                                                                    setShowPreloader(true);
                                                                    let profiledetails = { ...values };
                                                                    profiledetails.is_politically_exposed = 0;
                                                                    ServerAPI.add_profile_details(profiledetails).then((res: any) => {
                                                                        if (res != undefined) {
                                                                            toast.success(res["message"]);

                                                                            navigate(PageLinks.PROFILE_PERSONAL_DETAILS_EDIT_VALIDATE_PAN);
                                                                        }
                                                                    }).finally(() => {
                                                                        setShowPreloader(false);
                                                                    })
                                                                }else{
                                                                     toast.error("please verify your mobile number and email address");
                                                                }
                                                                
                                                            }}>
                                                                {({
                                                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                                }) => (

                                                                    <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                                                        <div className="col-xl-8 pl-0 float-left">
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className={errors.firstname && touched.firstname ? "error" : "input-title"}>First Name *</label>
                                                                                    <input type="text" placeholder="Enter First Name"
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        name="firstname"
                                                                                        value={values.firstname}
                                                                                        className={errors.firstname && touched.firstname ? "input-error" : ""} />
                                                                                    {errors.firstname && touched.firstname && (
                                                                                        <span className="error">{errors.firstname}</span>
                                                                                    )}
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className={errors.lastname && touched.lastname ? "error" : "input-title"}>Last Name <span className="error-area">* &nbsp;</span>
                                                                                        {errors.lastname && touched.lastname && (
                                                                                            <span className="error">({errors.lastname})</span>
                                                                                        )}</label>
                                                                                    <input type="text" className={errors.lastname && touched.lastname ? "input-error" : ""} placeholder="Enter Last Name" name="lastname" value={values.lastname} onChange={handleChange}
                                                                                        onBlur={handleBlur} />

                                                                                    {errors.lastname && touched.lastname && (
                                                                                        <span className="error">{errors.lastname}</span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input mobile-input input_field pl-0">
                                                                                    <label className="input-title">Mobile Number <span className="error-area">*</span></label>
                                                                                    <input type="text" className="email_input mobile_number pe-4" placeholder="Enter Mobile Number" name="mobile_number" value={values.mobile_number} readOnly />
                                                                                    {values.is_mobile_verified && values.mobile_number.length == 10 ?
                                                                                        <span className="verified"><i className="far fa-check-circle"></i></span>
                                                                                        :
                                                                                        <span></span>
                                                                                    }
                                                                                    <div className={errors.mobile_number && touched.mobile_number ? "country_box_with_error" : "country_box"}><img src="assets/img/icon/flag.png" /><span className="input-title-mobile"> +91</span>&nbsp;&nbsp;&nbsp;</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className={errors.email_id && touched.email_id ? "error" : "input-title"}>Email ID *</label>
                                                                                    <input type="text" placeholder="Enter Email ID" readOnly={!emailVerified ? false : true} onChange={handleChange} onBlur={handleBlur} name="email_id" value={values.email_id} className={errors.email_id && touched.email_id ? "input-error" : ""} />
                                                                                    {errors.email_id && touched.email_id && <span className="error">{errors.email_id}</span>}
                                                                                    {emailtrue !== 0 || emailVerified && emailPattern.test(values.email_id) ? (
                                                                                        <span className="verified">
                                                                                            <i className="far fa-check-circle"></i>
                                                                                        </span>
                                                                                    ) : (
                                                                                        <button
                                                                                            onClick={(e: any) => {
                                                                                                e.preventDefault();
                                                                                                if (values.email_id != "") {
                                                                                                    setShowPreloader(true);
                                                                                                    ServerAPI.EmailSendOtp(userDetail, values.email_id)
                                                                                                        .then((res: any) => {        
                                                                                                            if (res?.data !== undefined && res?.status === 400) {
                                                                                                                setEmailerror(res?.data?.errors)
                                                                                                                setEmailVerifyModalExists(true);

                                                                                                            } else {
                                                                                                                toast.success(res['message']);
                                                                                                                setEmailID(res.email_id);
                                                                                                                setEmailVerifyModal(true)
                                                                                                            }
                                                                                                        })
                                                                                                        .finally(() => {
                                                                                                            setShowPreloader(false);
                                                                                                        });
                                                                                                }

                                                                                            }}
                                                                                            className={!emailPattern.test(values.email_id) ? "pan_verify_btn disabled_btn" : "pan_verify_btn"}
                                                                                        >
                                                                                            Verify
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className=" input_field pl-0">
                                                                                    <label className={ageValidationFailed ? "error" : "input-title"}>
                                                                                        Date of Birth - Should be as per PAN <span className="error-area">*</span>
                                                                                    </label>
                                                                                    <DatePicker
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        placeholderText="DD-MM-YYYY"
                                                                                        maxDate={new Date(maxDate)}
                                                                                        minDate={new Date("1900-01-01")}
                                                                                        className="date-picker-input profile-calendar mobile_number"
                                                                                        name="personal_data_dob"
                                                                                        selected={startDate}
                                                                                        onChange={(date: any) => {
                                                                                            setStartDate(date);
                                                                                            values.dob = moment(date).format("YYYY-MM-DD");
                                                                                        }}
                                                                                    />
                                                                                    {ageValidationFailed && <span className="error">Please Enter a Valid Date of Birth</span>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-4 p-0 float-left">
                                                                        </div>
                                                                        <div className="col-xl-8 p-0 float-left mb-5 political_switch-div">
                                                                            {/* <div className="choice-list political_switch">
                                                                                <span className="input-title">Are You Politically Exposed</span>
                                                                                <div className="input-title-filled">
                                                                                    <div className="custom-switch custom-switch-label-yesno pl-0">
                                                                                        <input className="custom-switch-input" id="is_politically_exposed" type="checkbox" checked={values.is_politically_exposed == 1 ? true : false} name="is_politically_exposed" onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                setPoliticallyExposed(true);
                                                                                                handleChange(e);
                                                                                            }
                                                                                        }} />
                                                                                        <label className="custom-switch-btn" htmlFor="is_politically_exposed"></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="choice-list future_switch mt-2">
                                                                                <span className="input-title future_switch-span">Would Like To Activate Your Futures & Options, Currency & Commodity Segment As Well?</span>
                                                                                <div className="input-title-filled">
                                                                                    <div className="custom-switch custom-switch-label-yesno pl-0">
                                                                                        <input className="custom-switch-input" name="would_you_like_to_activate" id="would_you_like_to_activate" value={values.would_you_like_to_activate} type="checkbox" onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                values.would_you_like_to_activate = 1;
                                                                                            } else {
                                                                                                values.would_you_like_to_activate = 0;
                                                                                            }
                                                                                        }} />
                                                                                        <label className="custom-switch-btn" htmlFor="would_you_like_to_activate"></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                            <div className="col-xl-12 pl-3 row political_switch-div">
                                                                                <div className="choice-list political_switch">
                                                                                    <p className="input-title" style={{ textTransform: "capitalize" }}>
                                                                                        Are You A U.S Citizen?
                                                                                    </p>
                                                                                    <div className="input-title-filled">
                                                                                        <div className="custom-switch custom-switch-label-yesno pl-0">
                                                                                            <input
                                                                                                className="custom-switch-input"
                                                                                                id="is_politically_exposed"
                                                                                                type="checkbox"
                                                                                                checked={values.citizen_usa == 1 ? true : false}
                                                                                                name="is_politically_exposed"
                                                                                                onChange={(e: any) => {
                                                                                                    if (e.target.checked) {
                                                                                                        setApologyPopup(true);
                                                                                                    }
                                                                                                }}

                                                                                            />
                                                                                            <label className="custom-switch-btn" htmlFor="is_politically_exposed"></label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="col-xl-4 choice-list text-start">
                                                                                    <label htmlFor="terms" className="review-text mx-1 mb-0 p-0 d-inline-flex">
                                                                                        <p className="review-text text-left mt-1">
                                                                                            <input
                                                                                                className="signup"
                                                                                                type="checkbox"
                                                                                                id="terms"
                                                                                                name="auth_interfychange"
                                                                                                onChange={(e: any) => {
                                                                                                    if (e.target.checked == true) {
                                                                                                        setAgree1(true);
                                                                                                        values.auth_interfychange = 1;
                                                                                                    } else {
                                                                                                        setAgree1(false);
                                                                                                        values.auth_interfychange = 0;
                                                                                                    }
                                                                                                }}
                                                                                            />{" "}
                                                                                        </p>
                                                                                        <p className="m-1 text-left">Authentication for InterFychange </p>
                                                                                    </label>
                                                                                    {errors.auth_interfychange && touched.auth_interfychange && <p className="error_text">{errors.auth_interfychange}</p>}
                                                                                </div>
                                                                                <div className="col-xl-4 choice-list text-center">
                                                                                    <label htmlFor="terms1" className="review-text mx-1 mb-0 p-0 d-inline-flex">
                                                                                        <p className="review-text text-left mt-1">
                                                                                            <input
                                                                                                className="signup"
                                                                                                type="checkbox"
                                                                                                id="terms1"
                                                                                                name="e_confirmation"
                                                                                                onChange={(e: any) => {
                                                                                                    if (e.target.checked == true) {
                                                                                                        setAgree1(true);
                                                                                                        values.e_confirmation = 1;
                                                                                                    } else {
                                                                                                        setAgree1(false);
                                                                                                        values.e_confirmation = 0;
                                                                                                    }
                                                                                                }}
                                                                                            />{" "}
                                                                                        </p>
                                                                                        <p className="m-1 text-left">E-Confirmation</p>
                                                                                    </label>
                                                                                    {errors.e_confirmation && touched.e_confirmation && <p className="error_text">{errors.e_confirmation}</p>}
                                                                                </div>
                                                                                <div className="col-xl-4 choice-list text-center">
                                                                                    <label htmlFor="terms2" className="review-text mx-1 mb-0 p-0 d-inline-flex">
                                                                                        <p className="review-text text-left mt-1">
                                                                                            <input
                                                                                                className="signup"
                                                                                                type="checkbox"
                                                                                                id="terms2"
                                                                                                name="auth_fund_securities"
                                                                                                onChange={(e: any) => {
                                                                                                    if (e.target.checked == true) {
                                                                                                        setAgree1(true);
                                                                                                        values.auth_fund_securities = 1;
                                                                                                    } else {
                                                                                                        setAgree1(false);
                                                                                                        values.auth_fund_securities = 0;
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </p>
                                                                                        <p className="m-1 text-left">Authentication for Fund Securities</p>
                                                                                    </label>
                                                                                    {errors.auth_fund_securities && touched.auth_fund_securities && <p className="error_text">{errors.auth_fund_securities}</p>}
                                                                                </div> */}

                                                                                <label htmlFor="terms3" className="review-text mx-1 mb-0 p-0 d-inline-flex">
                                                                                    <p className="review-text text-left mt-1">
                                                                                        <input
                                                                                            className="signup"
                                                                                            type="checkbox"
                                                                                            id="terms3"
                                                                                            name="auth_fetch_kyc_details"
                                                                                            onChange={(e: any) => {
                                                                                                if (e.target.checked == true) {
                                                                                                    setAgree1(true);
                                                                                                    values.auth_fetch_kyc_details = 1;
                                                                                                } else {
                                                                                                    setAgree1(false);
                                                                                                    values.auth_fetch_kyc_details = 0;
                                                                                                }
                                                                                            }}
                                                                                        />{" "}
                                                                                    </p>
                                                                                    <p className="m-1 mx-2 text-left"> I/We authorise TFCS to fetch my/our KYC details available with KRA. I/We also request you to please consider the details fetched for account opening purposes.</p>
                                                                                </label>
                                                                                {errors.auth_fetch_kyc_details && touched.auth_fetch_kyc_details && <p className="error_text">{errors.auth_fetch_kyc_details}</p>}
                                                                                <label htmlFor="terms4" className="review-text mx-1 mb-0 p-0 d-inline-flex">
                                                                                    <p className="review-text text-left pb-0">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="terms4"
                                                                                            className="signup"
                                                                                            name="e_confirmation"
                                                                                            onChange={(e: any) => {
                                                                                                if (e.target.checked == true) {
                                                                                                    setAgree2(true);
                                                                                                    values.e_confirmation = 1;
                                                                                                } else {
                                                                                                    setAgree2(false);
                                                                                                    values.e_confirmation = 0;
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </p>
                                                                                    <p className="mx-2 text-left"> I hereby give my consent tо use my Aadhar / Virtual ID details (as applicable) for the purpose of e- Signing my account opening form.</p>
                                                                                </label>
                                                                                {errors.e_confirmation && touched.e_confirmation && <p className="error_text">{errors.e_confirmation}</p>}
                                                                                <div className="col-xl-12 mt-2">
                                                                                    <ul className="ul-style">
                                                                                        <li className="mt-2">
                                                                                            <p className="sourceSansPro-regular text-light-grey">I authorise TRUST GROUP to fetch my data from KYC Registration Agency by submitting my PAN.</p>
                                                                                        </li>
                                                                                        <li className="mt-2">
                                                                                            <p className="sourceSansPro-regular text-light-grey">Currently, this process is applicable only for opening an individual trading account and single-holder Demat accounts for resident individuals soon we shall allow for NRIs and multiple holders in Demat account.</p>
                                                                                        </li>

                                                                                        <li className="mt-2">
                                                                                            <p className="sourceSansPro-regular text-light-grey">Please note that submitting the above-mentioned details allows us to Call/SMS you even though you may be registered under DNC. We shall Call/SMS you for a period of 12 months.</p>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-xl-4 p-0 float-left">

                                                                        </div>
                                                                        <div className="col-xl-12 continue_btn_whole mb-3">
                                                                            <button onClick={(e: any) => {
                                                                                kycStatusUpdate(kycStatus.PAN);
                                                                            }} className={(values.firstname != "" && values.lastname != '' && values.dob != "" && agree1 == true && agree2 == true) ? "theme_btn btn-change primary_btn_1 sub-btn wow" : "theme_btn primary_btn sub-btn wow"} data-wow-delay="0.5s" type="submit">Continue</button>
                                                                        </div>
                                                                        <div onClick={(e: any) => {
                                                                            e.preventDefault();
                                                                            setKycExit(true);
                                                                        }} className="save_continue_btn text-center cursor"><u>Save & Complete Later</u></div>
                                                                    </form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <ConfirmationKYCModel showModal={KycExit} closeModal={() => { setKycExit(false) }} confirmtype={'personaldetails'} />
                <PoliticallyExposed showModal={politicallyExposed} closeModal={() => { setPoliticallyExposed(false) }} />
                <EmailOTPVerify
                    showModal={emailVerifyModal}
                    closeModal={() => {
                        setEmailVerifyModal(false);
                    }}
                    emailID={emailID}
                    userDetail={userDetail}
                    setEmailVerified={() => {
                        setEmailVerified(true);
                    }}
                />
                <EmailOTPVerifyexists
                    showModal={emailVerifyModalExists}
                    closeModal={() => {
                        setEmailVerifyModalExists(false);
                    }} 
                    emailerror ={emailerror}/>
                <AppologiesPopupModel
                    showModal={apologyPopup}
                    closeModal={() => {
                        setApologyPopup(false);
                    }}
                />
            </main>
        </div >
    )
}

export default PersonalDetailsEdit;
