import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { PageLinks } from "../../common/Constants";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ServerAPI } from "../../common/ServerAPI";
import toast from "react-hot-toast";
import { InitialData } from "../Form/InitialData";
import PreviewCheque from "./PreviewCheque";
import { useAppStateAPI } from "../../common/AppStateAPI";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
    setShowModel?:any,
    setChequeUrl:any
}

const ChequeUpload: React.FC<FormParameters> = ({ showModal, closeModal,setShowModel,setChequeUrl }) => {
    const [cheque, setCheque] = useState<string>('');
    const { setShowPreloader } = useAppStateAPI();
    const [openChequePreviewPopup, setOpenChequePreviewPopup] = useState(false);


    useEffect(() => {
        if(showModal==true){
            setCheque('');
        }
    }, [showModal])


    const loadUploadedfile = (fileInput: any) => {
        let filetype = fileInput.files[0].type;
        filetype = filetype.split("/")[0];
        if (filetype == 'image') {
            setShowPreloader(true);
            ServerAPI.UploadCancelledCheque(fileInput.files[0]).then(response => {
                if (response.message != undefined && response.message != "") {
                    setCheque(response.message);
                    toast.success('Image Uploaded Successfully');
                } else {
                    toast.error('unable to upload the image please try again!');
                }
            }).finally(()=>{
                setShowPreloader(false);
            });
        } else {
            toast.error("Uploaded file is not an Image!");
        }
    }

    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog
                    open={showModal}
                    keepMounted
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    maxWidth="md"
                >
                    <DialogTitle>Upload Cancelled Cheque
                        <button onClick={(e: any) => {
                            e.preventDefault();
                            closeModal();
                        }} className="theme_btn profile_close_btn sub-btn wow " data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</button>
                    </DialogTitle>
                    <DialogContent className="cancelled-cheque-bg" style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div className="col-xl-12">
                                <div className="subscribe-wrapper">
                                    <div className="section-title section-title-3">
                                        <hr />
                                        <div className="cancelled_cheque_box">
                                            <div className="signature_preview_box_head">Upload Preview</div>
                                            <div className="signature_preview_box_content mt-80">
                                                <div className="upload_img cursor">
                                                    <label htmlFor="upload">
                                                        <img className="cursor" 
                                                        onClick={(e:any)=>{
                                                            if(cheque != ""){
                                                                e.preventDefault();
                                                                closeModal();
                                                                setOpenChequePreviewPopup(true);
                                                            }
                                                        }} 
                                                        src={cheque != undefined && cheque != undefined && cheque != "" ? cheque : "assets/img/icon/Upload.png"} />
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="upload"
                                                        hidden
                                                        name="cancelled_cheque_image"
                                                        onChange={(e: any) => {
                                                            loadUploadedfile(e.target);
                                                        }}
                                                    />
                                                </div>

                                                <p className="upload_img_text">Upload Cancelled Cheque</p>
                                                {cheque != "" &&
                                                    <div className="signature_preview_box_content_replace_btn" >
                                                        <label htmlFor="upload">Replace</label></div>
                                                }
                                                <p className="signature_preview_box_content_text_one"><a href="/upload-cancelled-cheque">JPG</a>, <a href="/upload-cancelled-cheque">JPEG</a> or PNG in less than <a href="/upload-cancelled-cheque">10 MB</a></p>
                                            </div>
                                        </div>
                                    </div><p className="personal_sub_text grayed_out">*Note: If you do not have your Cheque book with you for the cancelled cheque, you can upload a copy of your Bank Statement or Bank Passbook</p>
                                    <div className="col-xl-12 continue_btn_whole" onClick={(e:any)=>{
                                        e.preventDefault();
                                        setChequeUrl(cheque);
                                        closeModal();
                                    }}><a className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s" href="/authenticate-aadhaar-detail">Submit</a>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            <PreviewCheque showModal={openChequePreviewPopup} closeModal={()=>{setOpenChequePreviewPopup(false)}} url={cheque} setShowUploadModel={setShowModel}/>
            </main>
        </div>
    )
}

export default ChequeUpload;