import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Footer from "../components/template/Footer";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import InvestSteps from "../components/InvestSteps";
import YourStory from "../components/YourStory";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import { Fragment, useEffect, useState } from "react";
import DownloadIssueDocuments from "../components/DownloadIssueDocuments";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { bondPurchaseTypes, interestPaymentFrequency, PageLinks } from "../common/Constants";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { numberWithCommas } from "../common/Utilities";
import moment from "moment";
import { toast } from "react-hot-toast";
import { styled } from '@mui/system';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
import Copy from "../components/Svg/Copy";
import copy from 'copy-to-clipboard';
import SetAlert from "../components/Modals/SetAlert";

const SovereignBondsLandingPage: React.FC = () => {
    const { setShowPreloader } = useAppStateAPI();
    const [isin, setIsin] = useState('');
    const [qty, setQty] = useState(1);
    const [Alert, setAlert] = useState(false);
    const [bondsDetail, setBondDetail] = useState<APIData.SGBDetail>();
    const [bondsDetailCashflow, setBondDetailCashflow] = useState<APIData.BondCashFlow[]>([]);
    const [calculatorType, setCalculatortype] = useState(false);
    const [validationFailed, setValidationFailed] = useState(false);
    const [quantityType, setQuantityType] = useState(1);
    const [Amount, setAmount] = useState('');
    const [TotalAmount, setTotalAmount] = useState('0');
    const colorCodes = ["#9BA9AD", "#AC979F", "#BC8691", "#CD7584", "#DE6376", "#EE5268", "#FF405A"];

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("isin")) {
                const id = query.get("isin");
                setIsin((id!));
            }
        }
    }, [location]);

    useEffect(() => {
        if (isin != '') {
            setShowPreloader(true);
            ServerAPI.SGBBondDetail(isin).then((response: any) => {
                if (response != undefined) {
                    setBondDetail(response);
                    setAmount(response['bonds_price_per_gram'] + "");
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [isin])

    useEffect(() => {
        setTotalAmount('0');
    }, [calculatorType])


    const goToItemPurchase = (isin: string) => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            navigate({
                pathname: PageLinks.APPLYBONDPURCHASE,
                search: "?isin=" + isin + "&bond=" + bondPurchaseTypes.SGB + "&bond_id=" + bondsDetail?.bond_id + "&qty=" + qty,
            });
        } else {
            localStorage.setItem('redirection', 'SGB');
            localStorage.setItem('isin', bondsDetail?.bond_isin_number!);
            navigate(PageLinks.LOGIN);
        }
    }

    const [openEarnDropDown, setOpenEarnDropDown] = useState(true);
    const toggleEarnDropdown = () => setOpenEarnDropDown(!openEarnDropDown);

    const bondAmount = () => {
        if (Amount != '') {
            setTotalAmount(parseInt(Amount) * qty + "");
        }
    }

    useEffect(() => {
        if (validationFailed != true) {
            bondAmount();
        }
    }, [qty, quantityType, validationFailed])

    const Root = styled('span')(
        ({ theme }) => `
        font-size: 0;
        position: relative;
        display: inline-block;
        width: 40px;
        height: 24px;
        margin: 10px auto;
        cursor: pointer;
      
        &.${switchUnstyledClasses.disabled} {
          opacity: 0.4;
          cursor: not-allowed;
        }
      
        & .${switchUnstyledClasses.track} {
          background: #02AD41;
          border-radius: 20px;
          display: block;
          height: 40px;
          width: 62px;
          position: absolute;
        }
      
        & .${switchUnstyledClasses.thumb} {
          display: block;
          width: 34px;
          height: 34px;
          top: 4px;
          left: 4px;
          border-radius: 20px;
          background-color: #fff;
          position: relative;
          
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 120ms;
        }
      
        &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
          background-color:#CCCCCC;
          box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
        }
      
        &.${switchUnstyledClasses.checked} {
          .${switchUnstyledClasses.thumb} {
            left: 25px;
            top: 3px;
            background-color:#fff;
          }
      
          .${switchUnstyledClasses.track} {
            background: #02AD41;
            border:1px solid #02AD41;
          }
        }
      
        & .${switchUnstyledClasses.input} {
          cursor: inherit;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          z-index: 1;
          margin: 0;
        }
        `,
    );

    return (
        <div className="main-index-wrapper">
            <Header />

            <div className="body-overlay"></div>
            <main>
                <section className="sovereign_gold_bonds pt-110 pb-0 pb-md-20 pt-sm-70 pt-xs-70 pb-xs-10">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row align-items-left">
                                    <div className="col-lg-8 breadcums">
                                        <nav>
                                            <ul>
                                                <li className="cursor" onClick={(e: any) => {
                                                    e.preventDefault();
                                                    navigate(PageLinks.PROFILE);
                                                }}>Home</li>
                                                <li>Bonds</li>
                                                <li className="cursor" onClick={(e: any) => {
                                                    e.preventDefault();
                                                    navigate(PageLinks.PROFILE_LISTING)
                                                }}>Explore Bonds</li>
                                                <li className="active">{bondsDetail?.bond_name}</li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row bond-gold bond-main invest_sovereign_gold_bond pt-100 pb-20 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40 mt-1 align-items-center">

                            <div className="col-lg-6 col-md-12 col-xs-12 col-12 invest_sovereign_gold_bond_left">
                                <div className="section-title section-title-3 text-left text-md-left mb-30 pt-20">
                                    <h3 className="mb-30">Invest In <span className="text-red">{bondsDetail?.bonds_banner_content_title}</span></h3>
                                    <h4 className="sub-title mb-10">{bondsDetail?.bonds_banner_content_sub_title}</h4>
                                </div>
                                {bondsDetail != undefined && bondsDetail.bond_highlights.length > 0 && bondsDetail.bond_highlights.map((brand_tag: APIData.BondHighlights) => {
                                    return (
                                        <>
                                            <div className="text-bond mt-4">
                                                <div className="d-flex my-2">
                                                    <span className="box-yellow  d-flex">
                                                        <p className="fs-18"> <span>{brand_tag.bond_highlights_title}  &nbsp;</span> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 103 103" className="text-right svg-gold">
                                                            <g id="Group_91" data-name="Group 91" transform="translate(0 0.004)">
                                                                <circle id="Ellipse_4" data-name="Ellipse 4" cx="51.5" cy="51.5" r="51.5" transform="translate(0 -0.004)" fill="#9F0704" />
                                                                <g id="Group_85" data-name="Group 85" transform="translate(20.425 17.118)">
                                                                    <rect id="Rectangle_460" data-name="Rectangle 460" width="63.374" height="63.374" transform="translate(0)" fill="none" />
                                                                    <path id="Checkbox" d="M25.131,43.706,0,18.575l5.1-5.1L25.131,33.144,58.275,0l5.1,5.1Z" transform="translate(0 11.883)" fill="#fff" />
                                                                </g>
                                                            </g>
                                                        </svg> &nbsp;
                                                        </p>
                                                    </span>
                                                </div>
                                            </div></>
                                    )
                                })
                                }
                                <div className="sovereign_gold_buynow_btn d-flex">
                                    <button className="buy_this_bond_btn float-left mt-3" onClick={(e: any) => {
                                        goToItemPurchase(isin);
                                    }} >Buy this BOND now!</button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-xs-12 col-12">
                                <div className="img">
                                    <img src={bondsDetail?.bonds_banner_content_image} className="w-100" />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-12 sovereign_gold_bond_benefits">
                                <div className="col-lg-6 col-md-6 col-xs-12 col-12 sovereign_gold_bond_benefits_left">
                                    <div className="section-title section-title-3 text-left text-md-left mb-30 pr-80 pr-xs-0">
                                        <h3 className="mb-10">{bondsDetail?.bonds_banner_content_title}</h3>
                                        {/* <h4 className="sub-title mb-10">{bondsDetail?.bonds_banner_content_sub_title}</h4> */}
                                        <h4 className="new-sub mb-10">Issued by Reserve Bank Of India on behalf of Government of India, Sovereign Gold Bonds (SGBs) are the perfect alternative to investment in physical gold. With these bonds, you can enjoy capital appreciation and also earn interest every year. These bonds, issued by the Government of India, also eliminate several risks associated with physical gold.</h4>
                                    </div>
                                </div>
                                {bondsDetail != undefined && bondsDetail.bond_benefits.length > 0 &&
                                    <div className="col-lg-6 col-md-6 col-xs-12 col-12 sovereign_gold_bond_benefits_right">
                                        <div className="section-title section-title-3 text-left text-md-left mb-30 pr-2">
                                            <h4 className="sub-title mb-10">{bondsDetail?.bond_name} Benefits</h4>
                                        </div>
                                        {bondsDetail != undefined && bondsDetail.bond_benefits.length > 0 && bondsDetail.bond_benefits.map((benefits: APIData.BondBenefits, index) => {
                                            return (
                                                <>
                                                    {benefits.bond_benefits_icon != "" && benefits.bond_benefits_description != "" &&
                                                        benefits.bond_benefits_title != "" &&
                                                        <div className="hassle_free_box" style={{ background: colorCodes[index] != undefined && colorCodes[index] != null ? colorCodes[index] : "#9BA9AD" }}>
                                                            <div className="hassle_free_box_icon"><img className="sgb-benefits-img" src={benefits.bond_benefits_icon} /></div>
                                                            <div className="hassle_free_box_text">
                                                                <b>{benefits.bond_benefits_title}</b> <br></br>
                                                                {benefits.bond_benefits_description}
                                                            </div>
                                                        </div>}
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sovereign_gold_bonds_detail_desc sub_content1 pb-md-20 pt-md-60 pt-xs-10 pb-xs-20 ">
                    <div className="container-fluid">
                        <div className="row align-items-center detail-view-description">
                            <div>
                                <div className="row align-items-center">
                                    <div className="col-lg-1 col-md-1 col-xs-1 col-3">

                                        <img className="detail-one-view-logo" src={bondsDetail?.bond_logo} alt="" />

                                    </div>
                                    <div className="col-lg-11 col-md-11 col-xs-11 col-9">
                                        <h4 className="mb-2 ml-sm-10">{bondsDetail?.bond_name}</h4>
                                        <div className="d-flex row-isin-res">
                                            <button className="return-btn">Returns Based On Market</button>
                                            {bondsDetail?.bond_isin_number != '' &&
                                                <button className="isin-btn"><span>ISIN:</span> {bondsDetail?.bond_isin_number}&nbsp;
                                                    <span className="cursor" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        copy(bondsDetail?.bond_isin_number!, {
                                                            debug: true,
                                                        });
                                                        toast.success('ISIN Number Copied To Clipboard');
                                                    }}>
                                                        <Copy />
                                                    </span></button>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12 " data-wow-delay="0.1s">
                                {bondsDetail != undefined && bondsDetail.bond_eligible_investors.length > 0 &&
                                    <div className="d-flex flex-row bd-highlight mb-2 mt-4 d-inline-flex bond_wrap">
                                        <div className="bd-highlight eligible-head ">Eligible Investors*</div>
                                        <div className="triangle-right"></div>
                                        {bondsDetail.bond_eligible_investors.map((brand: APIData.BondEligibleInvestors) => {
                                            return (
                                                <>
                                                {brand?.eligible_investors_available == 1 &&
                                                <div className="bd-highlight eligible-sub-head">{brand.eligible_investor_name}</div>}
                                                </>
                                            )

                                        })}
                                    </div>
                                }

                            </div>
                            <div className="row section-table p-xs-mobile mt-3 ml-0 table-bond-p ">
                                <div className="col-lg-4 col-md-4 pr-0">
                                    <div className="row row-first mr-1 pb-3 pt-2">
                                        <div className="col-lg-6 col-6">
                                            <p className="bond-detail-p">Rate of Int (Returns)</p>
                                            <h5 className="bond-detail-p">{bondsDetail?.bond_coupon_rate}%</h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <p className="bond-detail-p">Yield</p>
                                            <h5 className="bond-detail-p">{bondsDetail?.bonds_yeild}%</h5>
                                        </div>
                                    </div>
                                    <div className="row row-sec mr-1 pb-2">
                                        <div className="col-lg-6 col-6">
                                            <p className="bond-detail-p">Allotment Date</p>
                                            <h5 className="bond-detail-p">{moment(bondsDetail?.bond_allotment_date).format("MMM DD, YYYY")}</h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <p className="bond-detail-p">Issue Opening - Closing Dates</p>
                                            <h5 className="bond-detail-p">{moment(bondsDetail?.bond_opening_date).format("MMM DD")}&nbsp;- {moment(bondsDetail?.bond_closing_date).format("MMM DD, YYYY")}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 pr-0">
                                    <div className="row row-first  mr-1 pb-3 pt-2">
                                        <div className="col-lg-6 col-6">
                                            <p className="bond-detail-p">Next Interest Payment Date</p>
                                            <h5 className="bond-detail-p">{moment(bondsDetail?.bonds_next_interest_payment_date).format("MMM DD, YYYY")}</h5>
                                        </div>
                                        <div className="col-lg-6 col-6">

                                            <p className="bond-detail-p">Interest Payment Frequency</p>
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_ANNUAL &&
                                                <h5 className="bond-detail-p">Annual</h5>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                <h5 className=" bond-detail-p">Monthly</h5>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                <h5 className="bond-detail-p">Quarterly</h5>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                <h5 className="bond-detail-p">Semi-Annual</h5>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == null || parseInt(bondsDetail?.bond_interest_frequency!) == undefined || bondsDetail?.bond_interest_type! == "" &&
                                                <h5 className="bond-detail-p">N/A</h5>
                                            }
                                        </div>
                                    </div>
                                    <div className="row row-sec mr-1 pb-2">
                                        <div className="col-lg-6 col-6">
                                            <p className="bond-detail-p">Maturity after</p>
                                            <h5 className="bond-detail-p">{moment(bondsDetail?.bond_maturity_date).format("MMM DD, YYYY")} <p>(1st Exit Date)</p></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <p className="bond-detail-p">Purchase Limit</p>
                                            <h5 className="bond-detail-p">{bondsDetail?.bond_purchase_limit_metric} Kg/{bondsDetail?.bond_purchase_limit} <p>During each financial year</p></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 pr-0">
                                    <div className="row row-first mr-1  pb-3 pt-2">
                                        <div className="col-lg-6 col-6">
                                            <p className="bond-detail-p">Issuer</p>
                                            <h5 className="bond-detail-p">{bondsDetail?.bond_issuer_name}</h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <p className="bond-detail-p">RBI Notice</p>
                                            <h5 className="bond-detail-p"><u className="signup-terms_conditions">{bondsDetail?.bonds_banner_rcb_notice_link}</u></h5>
                                        </div>
                                    </div>
                                    <div className="row row-sec mr-1 pb-2">
                                        <div className="col-lg-6 col-6">
                                            <p className="bond-detail-p">Govt. Guarantee</p>
                                            {bondsDetail?.bond_govt_guranatee == 1 ?
                                                <h5 className="bond-detail-p">Yes</h5>
                                                :
                                                <h5 className="bond-detail-p">No</h5>
                                            }
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <p className="bond-detail-p">Exit Option Available</p>
                                            {bondsDetail?.exit_option_available == 1 ?
                                                <>
                                                    <h5 className="bond-detail-p">Yes</h5>
                                                    <p className="lh-10">After the 5th year. Investors can exercise it on the next date.</p>
                                                </>
                                                :
                                                <h5 className="bond-detail-p">No.</h5>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center pt-20 pb-10">
                                <div className="sovereign_gold_buynow_btn">
                                    <button className="buy_this_bond_btn" onClick={(e: any) => {
                                        goToItemPurchase(isin);
                                    }} >Buy this BOND now!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sovereign_gold_bonds_invest_calculation pb-md-20 pt-md-60 pt-sm-0 pt-xs-0 pb-xs-0">
                    <div className="container-fluid">
                        <div className="row detail-view-calculation ">
                            <div className="col-lg-7 pl-30 mrd-none">
                                <h3 style={{ marginTop: "30px" }}>Your Investment Calculator</h3>
                                <div className="center">
                                    <img className="detail-one-investment-cal-img w-100" src="assets/img/calculator.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-5 section bg-light1">
                                <div className="row d-flex section-head">
                                    <div className="col-lg-12">
                                        <p className="title">The maximum limit of subscription shall be 4 kg for individuals, 4 kg for Hindu Undivided Family (HUF) and 20 kg for trusts and similar entities notified by the government from time to time</p>
                                    </div>
                                </div>
                                <div className="section-form align-items-center mt-sm-10">
                                    <div className="row align-items-center row-form-res">
                                        <div className="col-lg-7 col-6 w-xs-100 text-res-center">
                                            <div>
                                                <h6>Mode Of Investment</h6>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-6 w-xs-80 pt-10 pb-20 px-0">
                                            <div className="row align-items-center m-sm-auto">
                                                <div className="col-lg-3 col-3 mt-3 p-0 text-res-center">
                                                    <p className={calculatorType == false ? "sgb-calculator-active sourceSansPro-bold text-p1-bond" : "sgb-calculator-inactive sourceSansPro-regular"} style={{ width: '61px' }}>Gold Qty</p>
                                                </div>
                                                <div className="col-lg-4 col-4">
                                                    <SwitchUnstyled component={Root} onChange={(e: any) => {
                                                        if (e.target.checked == true) {
                                                            setCalculatortype(true);
                                                        } else {
                                                            setCalculatortype(false);
                                                        }
                                                    }} />
                                                </div>
                                                <div className="col-lg-4 col-4 mt-3">
                                                    <p className={calculatorType == true ? "sgb-calculator-active sourceSansPro-bold text-p1-bond" : "sgb-calculator-inactive sourceSansPro-regular"} style={{ width: '53px' }}>Amount</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-7 col-6 pt-10 pb-10 w-xs-100">
                                            {calculatorType == true ?
                                                <div>
                                                    <h6>Enter Amount (A) </h6>
                                                    <p>(Range : {"₹" + (numberWithCommas(bondsDetail?.bonds_price_per_gram!) + "") + "-" + "₹" + numberWithCommas((bondsDetail?.bonds_price_per_gram!) * 4000) + ""})</p>
                                                </div>
                                                :
                                                <div>
                                                    <h6>Enter gold quantity (A)</h6>
                                                    <p>(Range: 1 gm - 4000 gm) <br /> *Quantity in Grams</p>
                                                </div>
                                            }
                                        </div>
                                        {calculatorType == true ?
                                            <div className="col-lg-5 col-6 pt-10 pb-10 w-xs-100">
                                                <div className="row">
                                                    <input className="input-valu2" onBlur={(e: any) => {
                                                        e.preventDefault();
                                                        if ((e.target.value < bondsDetail?.bonds_price_per_gram!) || (e.target.value > ((bondsDetail?.bonds_price_per_gram!) * 4000))) {
                                                            toast.error("Please Enter Amount Within the Range");
                                                            setValidationFailed(true);
                                                        } else {
                                                            // /setTotalAmount(e.target.value);
                                                            if (bondsDetail?.bonds_price_per_gram != undefined) {
                                                                setQty(parseInt(e.target.value) / (bondsDetail?.bonds_price_per_gram));
                                                            }
                                                            setValidationFailed(false);
                                                        }
                                                    }} />

                                                </div>
                                            </div>
                                            :
                                            <div className="col-lg-5 col-6 pl-0 w-xs-100">
                                                <div className="d-flex justify-content-between jcb-mobile flex-row">
                                                    <div className="">
                                                        <button onClick={(e: any) => {
                                                            e.preventDefault();
                                                            if (qty != 1) {
                                                                setQty(qty - 1);
                                                            }
                                                        }} className="detail-view-calculator-button">-</button>
                                                    </div>
                                                    <div className="position-relative">
                                                        <input value={qty} onChange={(e: any) => {
                                                            e.preventDefault();
                                                            if (e.target.value > 4000 && e.target.value < 1) {
                                                                toast.error("Please Enter the Quantity Within the Range");
                                                            } else {
                                                                const inputValue = e.target.value;
                                                                const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove leading zeros
                                                                const parsedValue = parseInt(sanitizedValue, 10);
                                                                if (isNaN(parsedValue)) {
                                                                  setQty(0);
                                                                } else {
                                                                  setQty(parsedValue);
                                                                }
                                                            }
                                                        }} className="input-value1 box-shadow sgb-calculator-input-1 mr-1" />
                                                        <p className="kg-text">{qty / 1000} kg</p>
                                                    </div>
                                                    <div className="">
                                                        <button onClick={(e: any) => {
                                                            e.preventDefault();
                                                            setQty(qty + 1);
                                                        }} className="detail-view-calculator-button">+</button>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="row ">
                                        <div className="col-lg-7 col-6 pt-10 pb-10 w-xs-100 issue">
                                            <div>
                                                <h6>Issue Price (B)</h6>
                                                <p>(Price of 1 gram of gold)</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-6 pt-10 pb-10 w-xs-100">
                                            <div className="row">
                                                <input value={"₹" + numberWithCommas(Amount)} className="input-valu2" readOnly={true} />
                                            </div>
                                        </div>
                                    </div>
                                    {calculatorType == true ?
                                        <div className="row row-form-res">
                                            <div className="col-lg-7 col-6 pt-10 pb-10 w-xs-100">
                                                <div className="mt-2">
                                                    <h6 className="fs-20">Gold Quantity in Grams</h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-6 pt-10 pb-10 pl-0 w-xs-100">
                                                <div className="row">
                                                    <input value={qty} onChange={(e: any) => {
                                                        e.preventDefault();
                                                        if (e.target.value > 4000 && e.target.value < 1) {
                                                            toast.error("Please Enter the Quantity Within the Range");
                                                        } else {
                                                            setQty(e.target.value);
                                                        }
                                                    }} className="input-valu2" />

                                                </div>
                                            </div>
                                        </div> : ""}
                                    {calculatorType == false ?
                                        <div className="row">
                                            <div className="col-lg-7 col-6 pt-10 pb-10 w-xs-100">
                                                <div>
                                                    <h6>Total Investment</h6>
                                                    <p>Amount (A) x Issue Price (B)</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-6 pt-10 pb-10 w-xs-100">
                                                <div className="row">
                                                    <input value={"₹" + numberWithCommas(parseInt(Amount) * qty)} className="input-valu2" readOnly={true} />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="row row-form-res">
                                            <div className="col-lg-7 col-6 pt-10 pb-10 w-xs-100">
                                                <div>
                                                    <h6>Total Investment</h6>
                                                    <p>Investment Grams</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-6 pt-10 pb-10 w-xs-100">
                                                <div className="row">
                                                    <input value={(parseInt(TotalAmount) / bondsDetail?.bonds_price_per_gram!).toFixed(2)} onChange={(e: any) => {
                                                        console.log(e.target.value);
                                                    }} className="input-valu2" readOnly={true} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="row align-items-center pt-20 pb-10">
                                    <div className="sovereign_gold_buynow_btn">
                                        <button className="buy_this_bond_btn" onClick={(e: any) => {
                                            goToItemPurchase(isin);
                                        }} >Buy this BOND now!</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {bondsDetail != undefined && bondsDetail.bond_cashflow_details.length > 0 &&
                            <div className="row">
                                <p className="detail-view-calculation-dec-below" onClick={() => { toggleEarnDropdown() }}>WHAT WILL I EARN <i className="fa fa-angle-down" aria-hidden="true"></i></p>
                            </div>
                        }
                        {bondsDetail != undefined && bondsDetail.bond_cashflow_details.length > 0 &&
                            openEarnDropDown === true ?
                            <Fragment>
                                <div className="row align-items-center sub_content">
                                    <div className="col-lg-6 left-content offset-lg-1">
                                        {
                                            bondsDetail.bond_cashflow_details.map((cashflow: APIData.bondCashflowDetails) => {
                                                return (
                                                    <Fragment>
                                                        <div className="row align-items-center p-1">
                                                            <div className="col-lg-3 col-md-3 col-12 white-bg sgb-calculator d-flex">
                                                                <p className="ml-2 px-2 text-left">{bondsDetail.bond_name}</p>
                                                            </div>
                                                            <div className="col-lg-2 col-md-2 col-4 yellow-bg sgb-calculator d-flex">
                                                                <div className="m-auto">
                                                                    <p>Gold Price</p>
                                                                    <p className="price">₹{numberWithCommas(bondsDetail.bonds_price_per_gram)}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-2 col-4 light-green-bg sgb-calculator d-flex">
                                                                <div className="m-auto">
                                                                    <p>Interest*</p>
                                                                    <p className="price">{cashflow.bond_cashflow_days}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-3 col-4 blue-bg sgb-calculator d-flex">
                                                                <div className="m-auto">
                                                                    <p>Gold Bond Returns**</p>
                                                                    <p className="price-1">₹{numberWithCommas(cashflow.bond_cashflow_amount)} (+40%)</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center p-1">
                                                            <div className="col-lg-3 col-md-3 col-12 white-bg sgb-calculator d-flex">
                                                                <p className="ml-2 px-2 text-left br-none">Only <br></br> Gold</p>
                                                            </div>
                                                            <div className="col-lg-2 col-md-2 col-4 yellow-dark-bg sgb-calculator d-flex">
                                                                <div className="m-auto">
                                                                    <p>Gold Price</p>
                                                                    <p className="price">₹{numberWithCommas(bondsDetail.bonds_price_per_gram)}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-2 col-4 light-dark-bg sgb-calculator d-flex">
                                                                <div className="m-auto">
                                                                    <p>Interest*</p>
                                                                    <p className="price">{cashflow.bond_cashflow_days}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-3 col-4 blue-dark-bg sgb-calculator d-flex">
                                                                <div className="m-auto">
                                                                    <p>Gold Returns</p>
                                                                    <p className="price-1">₹{numberWithCommas(cashflow.bond_cashflow_amount)} (+20%)</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )

                                            })
                                        }
                                        <div className="desc">
                                            <p>* Interest = [{(2.5 % +"interest") + "*" + ("nominal Value")}X(8 years)]</p>
                                            <p>** Gold Bond Returns = (((Price at Maturity + Interest) - Price at Investment) / Price at Investment) * 100)</p>
                                            <p>Subject to applicable tax only to income & calculated on simple interest basis and it is the responsibility of the bond holder to comply with the tax laws.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 right-content">
                                        <div className="center">
                                            <img src="assets/img/soveregin_invest_img.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                            : ""
                        }
                    </div>
                </section>
                {bondsDetail?.bond_issue_document != '' && bondsDetail?.bond_issue_document != null &&
                    <DownloadIssueDocuments document={bondsDetail?.bond_issue_document} />
                }
                {bondsDetail != undefined && bondsDetail.bond_upcoming_issues.length > 0 &&
                    <section className="sovereign_gold_bonds_upload_issue pb-md-20 pt-md-60 pt-sm-0 pt-xs-0 pb-xs-10">
                        <div className="container-fluid">
                            <div className="row align-items-center sub_content">
                                <div className="col-lg-7 left-content">
                                    <h3 className="font-24 font-weight-600">Upcoming issues</h3>
                                    <p className="mb-3">As declared by RBI, Gold bonds are issued every month. You can also easily plan your gold investments.</p>
                                    <div className="row align-items-center">
                                        <div className="table-responsive px-0">
                                            <table className="table table-bordered shadow-table table-issue">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">S.no</th>
                                                        <th scope="col">Issue Name*</th>
                                                        <th scope="col">Subscription Dates</th>
                                                        <th scope="col">Allotment Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bondsDetail != undefined && bondsDetail.bond_upcoming_issues.length > 0 && bondsDetail.bond_upcoming_issues.map((upcoming: APIData.BondUpcomingIssues, index) => {
                                                        return (
                                                            <tr>
                                                                <th>{index + 1}</th>
                                                                <td>{upcoming.bond_upcoming_issues_name}</td>
                                                                <td>{moment(upcoming?.bond_upcoming_issues_subscription_start_date).format("MMM Do YYYY")}&nbsp;- {moment(upcoming?.bond_upcoming_issues_subscription_end_date).format("MMM Do YYYY")}</td>
                                                                <td>{moment(upcoming.bond_upcoming_issues_allotment_date).format("MMM Do YYYY")}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <p>*As per RBI data</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 right-content mrd-none">
                                    <div className="center position-relative">
                                        <img style={{ width: "100%", height: "auto" }} src="assets/img/T09.png" alt="" />
                                        <button onClick={(e: any) => {
                                            e.preventDefault();
                                            setAlert(true);
                                        }} className="btn-alert">Set Alerts &nbsp; &nbsp;<i className="fas fa-arrow-right mx-1"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <InvestSteps />
                <Subscribe />
                <NeedHelp />

            </main>
            <Footer />
            <SetAlert
                showModal={Alert}
                closeModal={() => {
                    setAlert(false);
                }}
            />
        </div>
    )
}

export default SovereignBondsLandingPage;