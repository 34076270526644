import React from 'react'
import { useNavigate } from 'react-router-dom';

const ProfileSettings: React.FC = () => {
    const navigate = useNavigate();
    return (
        <section className="otp_popup">
            <div className="blur_back"></div>
            {/* <div className="container custom-container-subs">
                <div className="sub-bg pt-85 pb-75 pr-100 pl-100 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0"> */}
            <div className='profile_settings'>
                <div className="step_box_content">
                    <div className="sub-bg">
                        <div className="row justify-content-center">
                            <div className="col-xl-12">
                                <div className="section-title section-title-3">
                                    <div className='d-flex'>
                                        <div className='col-lg-10'>
                                            <h6 className="wow " data-wow-delay="0.1s">Settings</h6>
                                        </div>
                                        <div className='col-lg-2'>
                                            <div className='close-btn' onClick={()=>{navigate(-1)}}>
                                                <i className="far fa-times-circle"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div className="section-title section-title-3 p-10">
                                    <h6 className="wow " data-wow-delay="0.1s">General Settings</h6>
                                    <div className='session_val'>
                                        <p>Session Validation</p>
                                        <h6>60 MINS</h6>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='col-lg-6'>
                                            <h6 className="wow " data-wow-delay="0.1s">Push Notification</h6>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="on-off-settings-toggle">
                                                <input className="on-off-settings-toggle__input" type="checkbox" id="bopis" />
                                                <label htmlFor="bopis" className="on-off-settings-toggle__slider"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div className="section-title section-title-3 p-10">
                                    <h6 className="wow " data-wow-delay="0.1s">Security Settings</h6>
                                    <div className='row d-flex p-10'>
                                        <div className='col-lg-6'>
                                            <h6 className="wow " data-wow-delay="0.1s">Enable Finger Print</h6>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="on-off-settings-toggle">
                                                <input className="on-off-settings-toggle__input" type="checkbox" id="bopis" />
                                                <label htmlFor="bopis" className="on-off-settings-toggle__slider"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row d-flex p-10'>
                                        <div className='col-lg-6'>
                                            <h6 className="wow " data-wow-delay="0.1s">Enable MPIN</h6>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="on-off-settings-toggle">
                                                <input className="on-off-settings-toggle__input" type="checkbox" id="bopis" />
                                                <label htmlFor="bopis" className="on-off-settings-toggle__slider"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div>
            </div> */}
        </section>
    )
}

export default ProfileSettings