import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ChequeUpload from "./ChequeUpload";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageLinks } from "../../common/Constants";
import { DialogTitle } from "@mui/material";
import { Formik } from "formik";
import { APIData } from "../../common/DataTypes";
import { InitialData } from "../Form/InitialData";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { ServerAPI } from "../../common/ServerAPI";
import { toast } from "react-hot-toast";

interface FormParameters {
  showModal: boolean;
  closeModal: any;
  emailID: string;
  setEmailVerified: any;
  userDetail: any
}

const EmailOTPVerify: React.FC<FormParameters> = ({ showModal, closeModal, emailID, setEmailVerified, userDetail }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [useDetail, setUserDetails] = useState<APIData.userDetail>();
  const prevData = location.state;
  const [Email_otp, setEmail_Otp] = useState('');
  const [email_otp, setEmailOtp] = useState<APIData.otp_verify>(InitialData.verify_otp);
  const { setShowPreloader } = useAppStateAPI();

  const validate = (values: APIData.otp_verify) => {
    let errors: any = {};
    if (values.otp == "") {
      errors.otp = "Otp is required";
    }
    return errors;
  };

  const resent_otp = () => {
    setShowPreloader(true);
    ServerAPI.ResendEmailOtp(userDetail, emailID)
      .then((res) => {
        toast.success(res["message"]);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  useEffect(() => {
    setEmailOtp(InitialData.verify_otp);
  }, [emailID]);

  return (
    <div className="main-index-wrapper">
      <main>
        <Dialog
          className="dialog-background"
          open={showModal}
          keepMounted
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              closeModal();
            }
          }}
          aria-describedby="alert-dialog-slide-description"
          scroll="body"
          PaperProps={{
            style: {
              background: "none",
              boxShadow: "none",
            },
          }}
          maxWidth="sm"
        >
          <div className="do-box do-box-2 mb-30 email_verify_bg">
            <DialogTitle className="otp_text p-2 mb-3 otp-text">Email ID Verification</DialogTitle>
            <DialogContent style={{ overflow: "hidden" }} className="p-2">
              <DialogContentText id="alert-dialog-slide-description">
                <Formik
                  initialValues={email_otp}
                  validate={validate}
                  enableReinitialize
                  onSubmit={(values, { setSubmitting }) => {
                    let passData: any;
                    if (values.otp != "") {
                      setShowPreloader(true);
                      ServerAPI.EmailVerifyNew(emailID, values.otp, useDetail)
                        .then((res) => {
                          toast.success(res["message"]);
                          let is_email_verified = res.is_email_verified
                          passData = { ...useDetail, is_email_verified };
                          setEmailVerified(true);
                          closeModal();
                        })
                        .finally(() => {
                          setShowPreloader(false);
                        });
                    }
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form className="email_verify_form quote-form mb-20" onSubmit={handleSubmit}>
                      <div className="email-input input_field pl-0 pr-0">
                        <p className="otp_text txt-wight">
                          Enter the 6 digits, One Time Password sent on your Email Id :{" "}
                          <span style={{ color: "#FF405A" }}>
                            {emailID}{" "}
                            <i
                              onClick={(e: any) => {
                                e.preventDefault();
                                setEmail_Otp('');
                                closeModal();
                              }}
                              className="fa fa-edit"
                            ></i>
                          </span>
                        </p>
                        <input type="password" id='OTP' placeholder="Enter OTP" onChange={(e: any) => {
                          handleChange(e);
                          setEmail_Otp(e.target.value);
                        }} onBlur={handleBlur} name="otp" maxLength={6} value={Email_otp} className={errors.otp && touched.otp ? "input-error" : "password-acc"} />
                        {errors.otp && touched.otp && <span className="error">{errors.otp}</span>}
                      </div>
                      <label className="input-title">
                        <a href="#">Didn’t get OTP?</a>
                      </label>
                      <a className="theme_btn resend_otp_btn_mobile sub-btn " data-wow-delay="0.5s" onClick={() => resent_otp()}>
                        Resend OTP
                      </a>
                      <div className="divider_15"></div>
                      <button type="submit" className="theme_btn email_verify_btn primary_btn_1 sub-btn" data-wow-delay="0.5s">
                        Verify
                      </button>
                    </form>
                  )}
                </Formik>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
      </main>
    </div>
  );
};

export default EmailOTPVerify;
