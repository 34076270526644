import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PageLinks } from "../../common/Constants";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from "react";

const Footer: React.FC = () => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const year = new Date().getFullYear();
    const [page, setPage] = useState('');
    const location = useLocation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <Fragment>
            <footer className="footer-area fix">
                <div className="container-fluid">
                    <div className="row hr-border pt-50">
                        <div className="col-xl-3 col-lg-4 col-md-3  " data-wow-delay='.1s'>
                            <div className="footer__widget text-center text-md-left mb-30">
                                <div className="footer-log mb-10">
                                    <a href="#" className="logo">
                                        <img src="assets/img/logo/header_logo_white.png" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-4 " data-wow-delay='.3s'>
                            <div className="footer__widget text-left text-md-left mb-30 pl-40">
                                <h4 className="widget-title mb-20">Bonds</h4>
                                <ul className="fot-list">
                                    <li><Link to={PageLinks.PROFILE_LISTING}>Explore Bonds</Link></li>
                                    {/* <li><Link to={PageLinks.COLLECTION_OF_BONDS}>Collection of Bonds</Link></li>
                                    <li><Link to={PageLinks.FAQ}>Frequently Asked Questions</Link></li>
                                    <li><Link to={PageLinks.LEARN_FROM_BOND}>Learn, from Bond, Trust Bond</Link></li>
                                    <li><Link to={PageLinks.CHAT}>Talk to Bond, Trust Bond</Link></li> */}
                                    {/* <li><Link to={PageLinks.NEWSANDINSIGHTS}>Bond ki Khaberin</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-4  " data-wow-delay='.5s'>
                            <div className="footer__widget text-left text-md-left mb-25 pl-85">
                                <h4 className="widget-title mb-20">Quick Link</h4>
                                <ul className="fot-list">
                                    {/* <li><Link to={PageLinks.ABOUTUS}>About Us</Link></li>
                            <li><Link to={PageLinks.NEWSANDINSIGHTS}>News and Insights</Link></li> */}
                                    <li><Link to={PageLinks.TERMS_AND_CONDITIONS}>Terms of Use</Link></li>
                                    <li><Link to={PageLinks.PRIVACY_POLICY}>Privacy Policy</Link></li>
                                    <li><Link to={PageLinks.REFUND_POLICY}>Refund Policy</Link></li>
                                    <li><Link to={PageLinks.CONTACT_US}>Contact Us</Link></li>
                                    {/* <li><Link to={PageLinks.DISCLAIMER}>Disclaimer</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6  " data-wow-delay='.7s'>
                            <div className="footer__widget text-left text-md-left mb-30 pl-30 pl-lg-0 pl-md-0 pl-xs-0">
                                {/* <h4 className="widget-title mb-20">Talk to expert</h4> */}
                                {/* <ul className="fot-list">
                                    <li><Link to={PageLinks.CONTACT_US}>Write to Us</Link></li>
                                    <li><a href="#">Login To Chat</a></li>
                                </ul> */}
                                <div className="footer-social mt-20 mb-40">
                                    <a href="#" onClick={(e: any) => {
                                        e.preventDefault();
                                    }}><i className="fab fa-facebook-f"></i></a>
                                    <a  href="#" onClick={(e: any) => {
                                        e.preventDefault();
                                    }}><i className="fab fa-twitter"></i></a>
                                    <a  href="#" onClick={(e: any) => {
                                        e.preventDefault();
                                    }}><i className="fab fa-linkedin-in"></i></a>
                                    <a  href="#" onClick={(e: any) => {
                                        e.preventDefault();
                                    }}><i className="fab fa-instagram"></i></a>
                                    <a  href="#" onClick={(e: any) => {
                                        e.preventDefault();
                                    }}><i className="fab fa-medium-m"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copy-right-area pt-10">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="copyright mb-40 text-center">
                                    <p>© {year} Trust Capital Pvt Ltd. | CIN: U65929MH2016PTC287266</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="home_chat_icon">
                <span id="home_chat" onClick={handleClick} >
                    <img src="assets/img/icon/home_chat.svg" />
                </span>
            </div> */}
                <Popover
                    id="home_chat"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Typography sx={{ p: 2 }}>
                        <div className="call-option-content home_chat_cont">
                            <h5>Need help to find bonds</h5>
                            <h6>Learn how to pick quality bonds <br></br> and start investing...  </h6>
                            <Link to={PageLinks.CHAT}><button className="alert-button"><span><i className="fa fa-arrow-right" aria-hidden="true"></i></span></button></Link>
                        </div>

                    </Typography>
                </Popover>
            </footer>
            <div className="footer_fixed_menu">
                <ul>
                    <li>
                        <Link to={PageLinks.PROFILE} className={(location.pathname == PageLinks.PROFILE || page == 'home') ? "header-active" : "header-notactive"} ><i className="fa fa-home" aria-hidden="true"></i><br />Home</Link>
                    </li>
                    <li>
                        <Link to={PageLinks.LEARN_FROM_BOND} className={(location.pathname == PageLinks.LEARN_FROM_BOND || page == 'Service') ? "header-active" : "header-notactive"} ><i className='far fa-clipboard-list'></i> <br />Portfolio</Link>
                    </li>
                    <li>
                        <Link to={PageLinks.ORDER_LISTING} className={(location.pathname == PageLinks.ORDER_LISTING || page == 'Scheme') ? "header-active" : "header-notactive"}>
                            <i className="far fa-shopping-cart" aria-hidden="true"></i><br />orders</Link>
                    </li>
                    <li>
                        <Link to={PageLinks.CHAT} className={(location.pathname == PageLinks.CHAT || page == 'Product') ? "header-active" : "header-notactive"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 80.129 91.578">
  <path id="Path_26276" data-name="Path 26276" d="M-1063.666,1075.547a5.725,5.725,0,0,1-5.723,5.724h-68.682a5.725,5.725,0,0,1-5.724-5.724,22.9,22.9,0,0,1,22.894-22.891h.848a19.767,19.767,0,0,1,6.334,1.212,31.051,31.051,0,0,0,9.991,1.64,30.963,30.963,0,0,0,9.977-1.64,19.857,19.857,0,0,1,6.35-1.215h.84A22.9,22.9,0,0,1-1063.666,1075.547Zm-74.406-48.65v-2.862a34.378,34.378,0,0,1,34.341-34.343,34.378,34.378,0,0,1,34.341,34.343v2.862a20.067,20.067,0,0,1-20.032,20.032h-17.17a5.727,5.727,0,0,1-5.724-5.724,5.726,5.726,0,0,1,5.724-5.724h5.724a5.725,5.725,0,0,1,5.723,5.724h5.723a14.333,14.333,0,0,0,14.309-14.309v-2.862a28.651,28.651,0,0,0-28.617-28.619,28.651,28.651,0,0,0-28.618,28.619v2.862a2.863,2.863,0,0,1-2.862,2.862A2.862,2.862,0,0,1-1138.072,1026.9Zm68.682,48.647a17.2,17.2,0,0,0-17.171-17.171h-.84a14.329,14.329,0,0,0-4.515.914,36.805,36.805,0,0,1-11.827,1.938,36.7,36.7,0,0,1-11.811-1.938,14.253,14.253,0,0,0-4.5-.911h-.848a17.2,17.2,0,0,0-17.171,17.171Zm-34.422-68.674a17.105,17.105,0,0,0-17.1,17.1,17.03,17.03,0,0,0,4.464,11.516,11.3,11.3,0,0,0-1.6,5.724c0,.214.051.412.063.62a22.754,22.754,0,0,1-8.648-17.791,22.906,22.906,0,0,1,22.894-22.9,22.906,22.906,0,0,1,22.894,22.9,22.835,22.835,0,0,1-1.333,7.407,8.542,8.542,0,0,1-7.253,4.04h-1.535c-.009-.017-.033-.028-.033-.042a17.055,17.055,0,0,0,4.432-11.481,17.1,17.1,0,0,0-17.094-17.094h-.078C-1103.759,1006.865-1103.783,1006.87-1103.812,1006.87Z" transform="translate(1143.795 -989.693)" fill="#fff"/>
</svg><br />chat</Link>
                    </li>
                </ul>
            </div>
        </Fragment>

    )
}

export default Footer;
