import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { TransitionProps } from '@mui/material/transitions';
import { DialogTitle } from "@mui/material";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
}

const AlertSuccess: React.FC<FormParameters> = ({ showModal, closeModal }) => {

    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog className="dialog-background"
                    open={showModal}
                    keepMounted
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{
                    }}
                    maxWidth="lg"
                >
                    <div className="alert-div-borderline"></div>
                    <DialogContent className="cancelled-cheque-bg" style={{ overflow: "hidden" }}>
                        <DialogContentText style={{ overflow: "hidden" }} id="alert-dialog-slide-description">
                            <div className="container custom-container-subs">
                                <form className="quote-form">
                                    <div className='alert_image_popup align-items-center'>
                                        <img src='assets/img/AlertsSet.png'></img>
                                        <h3>Alert Set!</h3>
                                    </div>

                                </form>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

            </main>
        </div>
    )
}

export default AlertSuccess;