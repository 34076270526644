import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { PageLinks } from "../../common/Constants";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ServerAPI } from "../../common/ServerAPI";
import toast from "react-hot-toast";
import AddressProofPreview from "./AddressProofPreview";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { APIData } from "../../common/DataTypes";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
    dematDetailsId?: any,
    dematType: any

}

const DematAccounts: React.FC<FormParameters> = ({ showModal, closeModal, dematDetailsId, dematType }) => {
    const { setShowPreloader } = useAppStateAPI();
    const [exisitingDematDetails, setExisitingDematBankDetails] = useState<APIData.ExisitingDematAccountDetails[]>([]);
    const [newDematDetails, setNewDematDetails] = useState<APIData.NewDematDetails[]>([]);

    // const getExisitingDematAccounts = () => {
    //     setShowPreloader(true);
    //     ServerAPI.ExisitingDematAccountList().then((response: any) => {
    //         setExisitingDematBankDetails(response.message);
    //     }).finally(() => {
    //         setShowPreloader(false);
    //     })
    // }
    const getNewDematAccounts = () => {
        setShowPreloader(true);
        ServerAPI.AllOrderDematList().then((response: any) => {
            setNewDematDetails(response.demat_list);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    useEffect(() => {
        // getExisitingDematAccounts();
        getNewDematAccounts();
    }, [])




    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog
                    open={showModal}
                    keepMounted
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            closeModal();
                        }
                    }}
                    fullWidth={true}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    maxWidth="lg"
                >
                    <DialogTitle>Select Demat Account
                        <button onClick={(e: any) => {
                            e.preventDefault();
                            closeModal();
                        }} className="theme_btn profile_close_btn sub-btn wow "><i className="far fa-times-circle"></i> Close</button>
                    </DialogTitle>
                    <DialogContent className="cancelled-cheque-bg p-sm-none" style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            {/* <div className="col-xl-12 p-0 d-lg-flex">
                                <div className="col-xl-12 col-12 p-0 row">
                                    {exisitingDematDetails != undefined && exisitingDematDetails.length > 0 && exisitingDematDetails.map((exisitingDemat: APIData.ExisitingDematAccountDetails) => {
                                        return (
                                            <div className="col-xl-6 row">
                                                <div className="col-xl-1">
                                                    <input type="radio" style={{ position: "initial" }} name="demat_account" onChange={(e: any) => {
                                                        dematType(2);
                                                        dematDetailsId(exisitingDemat.demat_id);
                                                    }} />
                                                </div>
                                                <div className="col-xl-10">
                                                    <div className="exisiting_demat_card_full mb-3 w-100">
                                                        <div className={exisitingDemat.exist_demat_account_details_status_key == 1 ? "exisiting_demat_card_left w-100" : "demat_card_left bank-disabled w-100"}>
                                                            <div className="exisiting_demat_card_icon">
                                                                <i className="fal fa-exchange"></i>
                                                            </div>

                                                            <div className="exisiting_demat_card_text">
                                                                <p className="demat_saving_btn">{exisitingDemat.depository}</p>
                                                                <br />
                                                                <div className="exisiting_demat_card_text_head_one">{exisitingDemat.dp_name}</div>
                                                                <div className="exisiting_demat_card_text_num">{exisitingDemat.dp_id}</div>
                                                            </div>
                                                        </div>
                                                        <div className={exisitingDemat.exist_demat_account_primary_account == 1 ? "exisiting_demat_primarybtn cursor" : ""}>{exisitingDemat.exist_demat_account_primary_account == 1 ? "PRIMARY" : ""}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div> */}
                            <div className="p-0 d-lg-flex">
                               
                                    {newDematDetails != undefined && newDematDetails.length > 0 && newDematDetails.map((newDemat: APIData.NewDematDetails) => {
                                        return (
                                            <div className="col-xl-6 row  p-sm-none">
                                                <div className="col-xl-1">
                                                    <input type="radio" style={{ position: "initial" }} name="demat_account" onChange={(e: any) => {
                                                        dematType(1);
                                                        dematDetailsId(newDemat.demat_id);
                                                      
                                                    }} />
                                                </div>
                                                <div className="col-xl-10">
                                                    <div className="demat_card_full mb-3 w-100">
                                                        <div className={newDemat.demat_account_status == 1 ? "demat_card_left w-100" : "demat_card_left bank-disabled w-100"}>
                                                            <div className="demat_card_icon">
                                                                <i className="fal fa-exchange"></i>
                                                            </div>

                                                            <div className="demat_card_text">
                                                                <p className="demat_saving_btn">{newDemat.depository}</p>
                                                                <br />
                                                                <div className="demat_card_text_head_one">{newDemat.firstname} {newDemat.lastname}</div>
                                                                <div className="demat_card_text_num">{newDemat.dp_id}{newDemat.client_id}</div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={newDemat.demat_is_primary == 1 ? "demat_primarybtn" : "bank-disabled-primary bank_primarybtn cursor"}>{newDemat.demat_is_primary == 1 ? "PRIMARY" : "Set as Primary"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                               
                            </div>
                            <div className="col-xl-12 continue_btn_whole" onClick={(e: any) => {
                                e.preventDefault();
                                closeModal();
                            }}>
                                <a className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s" href="/authenticate-aadhaar-detail">Submit</a>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </main>
        </div>
    )
}

export default DematAccounts;