export enum PageLinks {
    PROFILE = "/home",
    LOGIN = "/login",
    SIGNUP = "/signup",
    VERIFY_OTP = "/verify-otp",
    VERIFY_OTP_LOGIN = "/verify-otp-login",
    PROFILE_PERSONAL_DETAILS = "/profile-personal-details",
    PROFILE_PERSONAL_DETAILS_EDIT = "/profile-personal-details-edit",
    PROFILE_PERSONAL_DETAILS_EDIT_VERIFY_EMAIL = "/profile-personal-details-edit-verify-email",
    EMAIL_VERIFICATION = "/email-verification",
    EMAIL_VERIFICATION_OTP = "/email-verification-otp",
    PROFILE_PERSONAL_DETAILS_EDIT_EMAIL_VERIFIED = "/profile-personal-details-edit-email-verified",
    PROFILE_PERSONAL_DETAILS_EDIT_VALIDATE_PAN = "/profile-personal-details-edit-validate-pan",
    PAN_VERIFICATION = "/pan-verification",
    PROFILE_PERSONAL_DETAILS_EDIT_PAN_DATA = "/profile-personal-details-edit-pan-data",
    ACCOUNT_OPENING_PENNY_DROP = "/verify-your-details",
    VERIFY_YOUR_IDENTITY = "/verify-your-indentity",
    VERIFY_YOUR_IDENTITY_PHOTO = "/verify-your-indentity-photo",
    VERIFY_YOUR_IDENTITY_SUCCESS = "/verify-your-indentity-success",
    BANK_DETAILS = "/bank-details",
    BANK_DETAILS_ADDED_SUCCESS = "/bank-details-added-success",
    DEMAT_DETAILS = "/demat-details",
    DEMAT_DETAILS_ADDED_SUCCESS = "/demat-details-added-success",
    VERIFY_DEMAT_DETAILS_SUCCESS = "/verify-demat-details-success",
    KYC_SUCCESS = "/kyc-success",
    MY_ACCOUNT_PROFILE = "/my-account-profile",
    ACCOUNT_OPENING_PENNY_DROP_POPUP = "/account-opening-example",
    VERIFY_YOUR_IDENTITY_PHOTO_POPUP = "/verify-your-indentity-photo-popup",
    ARE_YOU_SURE_ALERT = "/are-you-sure-alert-popup",
    OPEN_DEMAT_ACCOUNT = "/open-demat-account",
    APPOLOGIES_POPUP = "/appoligies-popup",
    APPOLOGIES_POPUP1 = "/appoligies-popup1",
    DEMAT_SIGNATURE_PREVIEW = "/demat-signature-preview",
    SIGNATURE_SUCCESS = "/signature-success",
    ADD_NOMINEE = "/add-nominee",
    ESIGN_AADHAAR = "/esign-aadhaar",
    FORM_SUCCESS = "/form-success",
    AUTHENTICATE_AADHAAR = "/authenticate-aadhaar",
    AUTHENTICATE_AADHAAR_DETAIL = "/authenticate-aadhaar-detail",
    UPLOAD_PASSPORT_SCANS = "/upload-passport-scans",
    PASSPORT_PREVIEW = "/passport-preview",
    PAN_NOT_MATCH_POPUP = "/pan-not-match-popup",
    UPLOAD_CANCELLED_CHEQUE = "/upload-cancelled-cheque",
    CANCELLED_CHEQUE_PREVIEW = "/cancelled-cheque-preview",
    PAN_STATUS_POPUP = "/pan-status-popup",
    DIJI_SUCCESS_POPUP = "/diji-success-popup",
    PAN_INVALID = "/pan-invalid",
    EMAIL_UPDATE = "/email-update",
    ESIGN_SUCCESS_POPUP = "/esign-success-popup",
    PROFILE_LISTING = "/bonds-listing",
    PROFILE_LISTING_ROW = "/bonds-listing-row",
    COLLECTION_OF_BONDS = "/collection-of-bonds",
    SOVEREIGN_BONDS_LANDING_PAGE = "/sovereign-bonds-landing-page",
    DETAIL_VIEW_ONE = "/detail-view-one",
    APPLYBONDPURCHASE = "/apply-bond-purchase",
    APPLYBONDNONGOLD = "/apply-bond-non-gold",
    LEARN_FROM_BOND = "/learn-from-bond",
    WHAT_ARE_ADVANTAGES = "/what-are-advantages",
    ALERT_FORM_POPUP = "/alert_form_popup",
    ALERT_IMAGE_POPUP = "/alert_image_popup",
    ORDER_LISTING = "/order-listing",
    PROFILE_SETTINGS = "/profile-settings",
    ALERT_LISTS = "/alert-lits",
    CHAT = "/chat",
    ABOUTUS = "/aboutus",
    NEWSANDINSIGHTS = "/news-and-insights",
    PRIVACY_POLICY = "/privacy-policy",
    TERMS_AND_CONDITIONS = "/terms-and-conditions",
    CONTACT_US = "/contact-us",
    DISCLAIMER = "/disclaimer",
    PAYMENTBOND = "/payment-bond",
    PAYMENTBOND1 = "/payment-bond1",
    PAYMENTBOND_SUCCESS = "/payment-success",
    BOND_CONFIRMED_ORDER = "/bond-confirmed-order",
    EDIT_NOMINEE = "/nominee-detail",
    SET_ALERT = "/set-alert",
    FAQ = "/faq",
    INSTRUCTION_SCREEN = "/instruction-screen",
    EMAIL_VERIFED_SUCCESS = "/email-verfied-success",
    WITHDROW_SUCCESS = "/withdrawn-success",
    BOND_IPO_FLOW = "/bond-ipo-detail",
    BOND_IPO_PURCHASE = "/bond-ipo-purchase",
    MODIFY_IPO_BOND = "/modify-ipo-bond",
    DOWNLOAD_APPLICATION = "/download-application",
    REFUND_POLICY = "/refund-policy",
    ENQUIRY_ADDED_SUCCESS = "/enquiry-added-success",
    GOLD_BOND = "/gold-bond",
    VERIFY_ADD_NOMINEE_SUCCESS = "/verify-add-nominee-success",
    DID_NOT = "/did-not",
    BOND_CALCULATION = "/bond-calculation",
    ENQUIRE_POPUP = "/Enquire-popup",
    SEARCH_BOND = "/bond-search",
    ESIGN_CANCEL_POPUP = "/cancel-popup",



}

export enum Endpoints {
    register = "send_otp_mobile",
    resend_otp = "send_otp_mobile",
    verify_otp = "verify_otp_mobile",
    userDetails = "personal_details",
    email_verify = "send_otp_email",
    email_otp_verify = "verify_otp_email",
    bank_details = "bank_details",
    login = "login_mobile_otp_send",
    login_resend_otp = "login_mobile_otp_send",
    login_otp_verify = "login_mobile_otp_verify",
    email_send_otp = "send_otp_email",
    email_resend_otp = "send_otp_email",
    exisiting_demat_account = "existing_demat_account_details",
    all_demat_account = "all_demat_account",
    verification_code = "in_person_video_verification_code",
    upload_video = "in_person_video_verification_video",
    authenticate_aadhar = "authentication_digilocker",
    aadhar_details = "digilocker_details",
    address_proof = "master/address-proof",
    personal_details = "personal_details",
    oauth_email_verify = "oauth2_verify_email",
    depository = "master/depository",
    proof_image_upload = "proof_image_upload",
    new_demat_account_details_post = "new_demat_account_details",
    new_demat_account_details = "all_demat_account",
    razorpay_bank_details = "razorpay_bank_details",
    cheque_cancelled_image = "cheque_cancelled_image",
    select_state = "master/state",
    select_city = "city",
    pan_verify = "pan_verify",
    profession_dropdown = "master/occupation",
    ifsc_code = "ifsc_code",
    primary_source_of_wealth = "master/primary-source",
    brokerage_scheme = "master/brokerage-scheme",
    where_you_born = "master/born-in",
    relationship_with_application = "master/relationship-applicant",
    settlement_date = "master/settlement_date",
    Yields ="master/yield_type",
    aidcc = "master/ai_dcc",
    nominee_proof_dropdown = "master/nominee-identification",
    nominee_details = "nominee_details",
    esign_pdf = "esign_pdf",
    profile_listing = "profile_listing",
    profile_listing_row = "profile_listing_row",
    collection_of_bonds = "collection_of_bonds",
    bank_details_status = "bank_details",
    sovereign_bonds_landing_page = "sovereign_bonds_landing_page",
    detail_view_one = "detail_view_one",
    apply_bond_non_gold = "apply_bond_non_gold",
    pincode = "master/pincode",
    signature_image_upload = 'signature_image_upload',
    learn_from_bond = "learn_from_bond",
    what_are_advantages = "what_are_advantages",
    aboutus = "aboutus",
    news_and_insights = "news_and_insights",
    privacy_policy = "privacy_policy",
    terms_and_conditions = "terms_and_conditions",
    contact_us = "contact_us",
    disclaimer = "disclaimer",
    set_alert = "set_alert",
    kyc_progressbar_status = 'kyc_progressbar_status',
    new_demat_status_update = 'new_demat_status_update',
    existing_demat_status_update = 'existing_demat_status_update',
    faq = 'faq',
    instruction_screen = 'instrction_screen',
    bondsList = 'customer/bonds-list',
    bondsListHome = 'home/bonds-list',
    specific_bonds_list = 'specific_bond_details',
    e_sign_pdf_download = 'e_sign_pdf_download',
    investment_calculator = 'investment_calculator',
    nominee_details_specific = 'nominee_details_specific',
    specific_sgb_details = 'specific_sgb_details',
    set_primary_bank_account = "set_primary_bank_account",
    set_primary_demat_account = 'set_primary_demat_account',
    sgb_orders = "sgb-orders",
    ipo_detail = "ipo",
    investment_rate = "investment_rate",
    ipo_orders = 'ipo-orders',
    // ipo_orders1 = 'customers/bond-bse-order',
    ipo_orders1 = 'customer/bond-order',
    documents_collected = "documents_collected",
    orders_list = "orders-list",
    asba_list = "asba_city_list",
    sgb_payment_mode = 'payment-modes/sgb',
    buy_sell ="master/buy-sell",
    bond_payment_mode = 'payment-modes/bond',
    bond_filters = "bond-filters",
    // all_demat_account_get = "all_demat_account_get",
    all_demat_account_get = "all_demat_account",
    sgb_payment_verify = "sgb-orders/payment-verify",
    bank_list_order = 'bank_list_order',
    demat_list_order = "demat_list_orders",
    subscribe_bond = 'subscribe-bond',
    investor_categories = 'investor-categories',
    investor_types = 'investor-types',
    various_segment = "master/various-segment",
    token_refresh = 'token_refresh',
    name_title = "master/name-title",
    Calculater= "customer/bond-enquiry",
    all_dematAccount = "all_demat_account",
    // bond_order_cancle= 'customers/bond-bse-order',
    bond_order_cancle= 'customer/bond-order',
    SGB_order_cancel = 'sgb-orders/mod-cancel',
    IPO_order_cancel = 'ipo-orders',
    enquire_bond_list = 'customer/enquire-bond-list',
    yield_price_calculator = 'master/yield-price-calculator',


}

export const APIResult = {
    SUCCESS: true,
    FAILURE: false
}

export const Mail_mobile_verify = {
    Verified: 1,
    NotVerified: 0
}
export const Selected_gender = {
    Male: 1,
    Female: 2,
    Trans: 3
}

export const Selected_marital_status = {
    NotSelected: 0,
    Single: 1,
    Married: 2,
    Others: 3
}

export const Annual_income_status = {
    NotSelected: 0,
    below_one: 1,
    one_to_five: 2,
    five_to_ten: 3,
    ten_to_twentyfive: 4,
    above_twntyfive: 5

}

export const Checked_value = {
    Yes: 1,
    No: 2
}

export const ProofImageSides = {
    Not_Selected: 0,
    Front: 1,
    Rear: 2
}

export const Experience = {
    below_one: 1,
    one_to_two: 2,
    two_to_five: 3,
    five_to_ten: 4,
    ten_to_twenty: 5,
    twenty_to_twentyfive: 6,
    above_twentyfive: 7
}

export const AccountTypeValues1 = {
    NotSelected: 0,
    savings: 1,
    current: 2
}

export const Gender = {
    NotSelected: 0,
    Male: 1,
    Female: 2,
    Trans: 3
}
export const exchange = {
    NotSelected: 0,
    BSC: 1,
    NSC: 2,
    Both: 3,
}
export const TradingExperience = {
    NotSelected: 0,
    less_than_one: 1,
    one_to_two: 2,
    two_to_five: 3,
    five_to_ten: 4,
    ten_to_twenty: 5,
    twenty_to_twenty_five: 6,
    above_twenty_five: 7
}

export const AccountTypeValues2 = {
    NotSelected: 0,
    single: 1,
    joint: 2
}

export const true_false_value = {
    True: 1,
    False: 0
}

export const depositories = {
    CSDL: 1,
    NSDL: 2
}

export const bondTypes = {
    IPO: 1,
    ADDEDBOND: 2,
    EXISTINGBOND: 3,
    SGB: 4,
    BOND: 0,
    NOT_IPO: 5
}
export const bondListTypes = {
    IPO: 1,
    ADDEDBOND: 2,
    EXISTINGBOND: 2,
    SGB: 4
}
export const bondPurchaseTypes = {
    IPO: 1,
    BONDS: 2,
    SGB: 3,
}

export const kycStatus = {
    NOT_STARTED: 0,
    PERSONAL_DETAILS: 1,
    EMAIL: 2,
    PAN: 3,
    AADHAR: 4,
    KRA_RECORDS: 5,
    IPV: 6,
    BANK: 7,
    DEMAT: 8,
    ESIGN: 9,
    KYC_COMPLETE: 10,
    APPLICATION_DOWNLOAD: 11,
    ADD_NOMINEE: 12,

}

export const interestPaymentFrequency = {
    FOR_MONTHLY: 1,
    FOR_QUARTERLY: 2,
    FOR_SEMI_ANNUAL: 3,
    FOR_ANNUAL: 4
}

export const NatureofInstruments = {
    TAXABLE: 1,
    TAX_FREE: 2
}

export const listing = {
    BSE: 1,
    NSE: 2,
    BSE_NSE: 3
}


export const IssuerType = {
    PRIVATE_SECTOR_CORPORATE: 1,
    PUBLIC_SECTOR_UNDERTAKING: 2,
    PRIVATE_BANK: 3,
    PUBLIC_SECTOR_BANK_STATE_GOVERNMENT: 4,
    CENTRAL_GOVERNMENT: 5
}

export const OrderStatus = {
    PAYMENT_PENDING: 10,
    SUCCESS: 20
}
export const OrderStatus1 = {
    PLACED: 1,
    MODIFIED: 2,
    CANCLE: 3,
    ACCEPTED: 4,
    REJECTED: 5,
}
export const OrderCancle = {
    CANCLE: 1,
    SUCCESS: 2
}
export const PaymentStatus = {
    PENDING: 10,
    SUCCESS: 20,
    FAILURE: 30
}
export const PaymentStatus1 = {
    PENDING: 1,
    SUCCESS: 2,
    FAILURE: 3,
    REFUNDED: 4,
    
}
export const SGBPaymentStatus = {
    PENDING: 1,
    SUCCESS: 2,
    FAILURE: 3,
    REFUNDED: 4
}

export const BankStatus = {
    Active: 1,
    Disabled: 0
}

export const RazorPayKey = "rzp_test_LFEvdhuQczrl3M";

export const API = {
    /*Live*/
    // BaseUrl: "https://api.trustmony.com/api/v1/",
    BaseUrl: "https://trust-api.trustmony.com/api/v1/",
    EndPoint: Endpoints,
    Result: APIResult

}

