import React from 'react'

const AlertImagePopup: React.FC = () => {
    return (
        <section className="otp_popup">
            <div className="blur_back"></div>
            <div className="container custom-container-subs">
                <div className="sub-bg pt-85 pb-75 pr-100 pl-100 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 wow" data-wow-delay="0.3s">
                            <div className='alert_image_popup align-items-center'>
                                <img src='assets/img/AlertsSet.png'></img>
                                <h3>Alert Set!</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default AlertImagePopup