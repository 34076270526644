import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { kycStatus, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";

const VerifyAddNominee: React.FC = () => {

    const location = useLocation();
    const preData: any = location.state;
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: any) => {
            setUserDetails(res)
        }).finally(() => {
            setShowPreloader(false);
        })

    }, []);

    const Pagenavigate = () => {
        navigate(PageLinks.DOWNLOAD_APPLICATION);
    }

    setTimeout(Pagenavigate, 3000);

    return (
        <section className="subscribe-letter-area animation-div pt-100">
            <div className="line-shape d-none d-lg-inline-block success-popup-bg-img pt-100">
                <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
            </div>
            <div className="container custom-container-subs">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6  text-left mt-200" data-wow-delay="0.3s">
                        <div className="do-box do-box-pan-status mb-30 email_verify_bg text-center cursor" onClick={(e: any) => {
                            e.preventDefault();
                            navigate(PageLinks.DOWNLOAD_APPLICATION);
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="20" viewBox="0 0 202.455 98.274" className="user_icon p-0 w-30">
                                <g id="Group_17293" data-name="Group 17293" transform="translate(-569.32 -134.839)">
                                    <g id="Group_17287" data-name="Group 17287" transform="translate(569.32 135.421)" opacity="0.15">
                                        <g id="Group_103" data-name="Group 103" transform="translate(46.965 0)">
                                            <g id="Group_102" data-name="Group 102" transform="translate(18.046)">
                                                <g id="Ellipse_1" data-name="Ellipse 1">
                                                    <path id="Path_27529" data-name="Path 27529" d="M751.241,190.83a27.3,27.3,0,1,1,27.3-27.3A27.335,27.335,0,0,1,751.241,190.83Zm0-47.377a20.074,20.074,0,1,0,20.074,20.074A20.1,20.1,0,0,0,751.241,143.453Z" transform="translate(-723.938 -136.224)" fill="#ec515f" />
                                                </g>
                                            </g>
                                            <g id="Path_15" data-name="Path 15" transform="translate(0 47.38)">
                                                <path id="Path_27530" data-name="Path 27530" d="M768.106,292.23a3.614,3.614,0,0,1-3.615-3.614,32.516,32.516,0,0,0-32.479-32.479H720.726a32.515,32.515,0,0,0-32.479,32.479,3.615,3.615,0,0,1-7.229,0,39.753,39.753,0,0,1,39.708-39.708h11.286a39.753,39.753,0,0,1,39.708,39.708A3.614,3.614,0,0,1,768.106,292.23Z" transform="translate(-681.018 -248.908)" fill="#ec515f" />
                                            </g>
                                        </g>
                                        <g id="Group_17286" data-name="Group 17286" transform="translate(0 25.478)">
                                            <g id="Group_17284" data-name="Group 17284" transform="translate(126.663)">
                                                <g id="Group_102-2" data-name="Group 102" transform="translate(3.776)">
                                                    <g id="Ellipse_1-2" data-name="Ellipse 1">
                                                        <path id="Path_27531" data-name="Path 27531" d="M898.468,234.669a18.925,18.925,0,1,1,18.925-18.925A18.947,18.947,0,0,1,898.468,234.669Zm0-30.621a11.7,11.7,0,1,0,11.7,11.7A11.709,11.709,0,0,0,898.468,204.048Z" transform="translate(-879.543 -196.819)" fill="#ec515f" />
                                                    </g>
                                                </g>
                                                <g id="Path_15-2" data-name="Path 15" transform="translate(0 30.621)">
                                                    <path id="Path_27532" data-name="Path 27532" d="M924.918,304.249a3.614,3.614,0,0,1-3.615-3.614,23.786,23.786,0,0,0-23.759-23.759h-8.559a23.668,23.668,0,0,0-12.849,3.77,3.615,3.615,0,0,1-3.915-6.077,30.886,30.886,0,0,1,16.765-4.922h8.559a31.024,31.024,0,0,1,30.989,30.988A3.614,3.614,0,0,1,924.918,304.249Z" transform="translate(-870.563 -269.646)" fill="#ec515f" />
                                                </g>
                                            </g>
                                            <g id="Group_17285" data-name="Group 17285">
                                                <g id="Group_102-3" data-name="Group 102" transform="translate(16.344)">
                                                    <g id="Ellipse_1-3" data-name="Ellipse 1">
                                                        <path id="Path_27533" data-name="Path 27533" d="M627.116,234.669a18.925,18.925,0,1,1,18.925-18.925A18.947,18.947,0,0,1,627.116,234.669Zm0-30.621a11.7,11.7,0,1,0,11.7,11.7A11.709,11.709,0,0,0,627.116,204.048Z" transform="translate(-608.191 -196.819)" fill="#ec515f" />
                                                    </g>
                                                </g>
                                                <g id="Path_15-3" data-name="Path 15" transform="translate(0 30.621)">
                                                    <path id="Path_27534" data-name="Path 27534" d="M572.935,304.249a3.614,3.614,0,0,1-3.615-3.614,31.024,31.024,0,0,1,30.989-30.988h8.559a30.887,30.887,0,0,1,16.765,4.922,3.615,3.615,0,0,1-3.916,6.077,23.668,23.668,0,0,0-12.849-3.77h-8.559a23.786,23.786,0,0,0-23.759,23.759A3.614,3.614,0,0,1,572.935,304.249Z" transform="translate(-569.32 -269.646)" fill="#ec515f" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_17291" data-name="Group 17291" transform="translate(571.631 134.839)">
                                        <g id="Group_103-2" data-name="Group 103" transform="translate(46.965 0)">
                                            <g id="Group_102-4" data-name="Group 102" transform="translate(18.047)">
                                                <g id="Ellipse_1-4" data-name="Ellipse 1">
                                                    <path id="Path_27535" data-name="Path 27535" d="M756.738,189.445a27.3,27.3,0,1,1,27.3-27.3A27.334,27.334,0,0,1,756.738,189.445Zm0-47.377a20.074,20.074,0,1,0,20.074,20.074A20.1,20.1,0,0,0,756.738,142.068Z" transform="translate(-729.435 -134.839)" fill="#ff405a" />
                                                </g>
                                            </g>
                                            <g id="Path_15-4" data-name="Path 15" transform="translate(0 47.379)">
                                                <path id="Path_27536" data-name="Path 27536" d="M773.6,290.845a3.615,3.615,0,0,1-3.615-3.615,32.516,32.516,0,0,0-32.479-32.479H726.222a32.515,32.515,0,0,0-32.479,32.479,3.615,3.615,0,1,1-7.229,0,39.753,39.753,0,0,1,39.708-39.708h11.286a39.753,39.753,0,0,1,39.708,39.708A3.614,3.614,0,0,1,773.6,290.845Z" transform="translate(-686.514 -247.522)" fill="#00c6d8" />
                                            </g>
                                        </g>
                                        <g id="Group_17290" data-name="Group 17290" transform="translate(0 25.478)">
                                            <g id="Group_17288" data-name="Group 17288" transform="translate(126.662)">
                                                <g id="Group_102-5" data-name="Group 102" transform="translate(3.776)">
                                                    <g id="Ellipse_1-5" data-name="Ellipse 1">
                                                        <path id="Path_27537" data-name="Path 27537" d="M903.964,233.284a18.925,18.925,0,1,1,18.925-18.925A18.946,18.946,0,0,1,903.964,233.284Zm0-30.621a11.7,11.7,0,1,0,11.7,11.7A11.71,11.71,0,0,0,903.964,202.663Z" transform="translate(-885.039 -195.434)" fill="#ff405a" />
                                                    </g>
                                                </g>
                                                <g id="Path_15-5" data-name="Path 15" transform="translate(0 30.621)">
                                                    <path id="Path_27538" data-name="Path 27538" d="M930.414,302.863a3.615,3.615,0,0,1-3.615-3.615,23.786,23.786,0,0,0-23.759-23.759h-8.559a23.672,23.672,0,0,0-12.849,3.77,3.614,3.614,0,0,1-3.915-6.076,30.882,30.882,0,0,1,16.765-4.923h8.559a31.023,31.023,0,0,1,30.988,30.988A3.615,3.615,0,0,1,930.414,302.863Z" transform="translate(-876.059 -268.26)" fill="#00c6d8" />
                                                </g>
                                            </g>
                                            <g id="Group_17289" data-name="Group 17289">
                                                <g id="Group_102-6" data-name="Group 102" transform="translate(16.344)">
                                                    <g id="Ellipse_1-6" data-name="Ellipse 1">
                                                        <path id="Path_27539" data-name="Path 27539" d="M632.612,233.284a18.925,18.925,0,1,1,18.925-18.925A18.947,18.947,0,0,1,632.612,233.284Zm0-30.621a11.7,11.7,0,1,0,11.7,11.7A11.709,11.709,0,0,0,632.612,202.663Z" transform="translate(-613.687 -195.434)" fill="#ff405a" />
                                                    </g>
                                                </g>
                                                <g id="Path_15-6" data-name="Path 15" transform="translate(0 30.621)">
                                                    <path id="Path_27540" data-name="Path 27540" d="M578.432,302.863a3.615,3.615,0,0,1-3.615-3.615,31.024,31.024,0,0,1,30.989-30.988h8.559a30.882,30.882,0,0,1,16.765,4.923,3.614,3.614,0,0,1-3.915,6.076,23.672,23.672,0,0,0-12.849-3.77h-8.559a23.786,23.786,0,0,0-23.759,23.759A3.615,3.615,0,0,1,578.432,302.863Z" transform="translate(-574.817 -268.26)" fill="#00c6d8" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group_17292" data-name="Group 17292" transform="translate(733.522 194.86)">
                                        <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(0 0)">
                                            <circle id="Ellipse_1046" data-name="Ellipse 1046" cx="19.126" cy="19.126" r="19.126" fill="#07b907" />
                                        </g>
                                        <g id="Checkbox" transform="translate(7.917 11.567)">
                                            <path id="Path_27541" data-name="Path 27541" d="M986.246,319.57l-7.179-7.179a1.344,1.344,0,0,1,0-1.9h0a1.344,1.344,0,0,1,1.892-.009l6.489,6.37,11.359-11.359a1.342,1.342,0,0,1,1.9,0h0a1.342,1.342,0,0,1,0,1.893l-12.064,12.18A1.693,1.693,0,0,1,986.246,319.57Z" transform="translate(-978.673 -305.098)" fill="#fff" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            {/* <img src="assets/animations/icon_animations/user.gif" className="user_icon" /> */}
                            <p className="success-popup-text1" data-wow-delay="0.1s">Congratulations!</p>
                            <p className="success-popup-text1" data-wow-delay="0.1s">Nominees Added Successfully</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VerifyAddNominee;