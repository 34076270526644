import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PageLinks } from "../common/Constants";

const NeedHelp: React.FC = () => {
    return (
        <section className="need_help pt-50 pb-0 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40 float-left">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 need_help_left">
                        <div className="need_help_head">
                            Need Help?
                        </div>
                        <div className="pt-20">
                        <Link to={PageLinks.CHAT}>
                            <div className="need_help_box trustBlackbtn">
                                <span className="need_help_box_text">Speak to us</span> <i className="fas fa-arrow-right"></i>
                            </div>
                        </Link>
                        <div className="need_help_box trustBlackbtn">
                            <span className="need_help_box_text">Assisted Buying / Selling of Bonds</span> <i className="fas fa-arrow-right"></i>
                        </div>
                        <div className="need_help_box trustBlackbtn">
                            <span className="need_help_box_text">Frequently Asked Questions</span> <i className="fas fa-arrow-right"></i>
                        </div>
                        <Link to={PageLinks.LEARN_FROM_BOND}>
                            <div className="need_help_box trustBlackbtn">
                                <span className="need_help_box_text">Learn from, Bond, Trust Bond</span> <i className="fas fa-arrow-right"></i>
                            </div>
                        </Link>
                        </div>
                       
                    </div>
                    <div className="col-lg-4 offset-lg-2 mrd-none">
                        <div className="need_help_img">
                            <img src="assets/img/icon/need_help.png" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
}

export default NeedHelp;
