import { url } from "inspector";
import { Field, Formik } from "formik";
import { Fragment, useEffect, useRef, useState } from "react";
import DownloadIssueDocuments from "../components/DownloadIssueDocuments";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { interestPaymentFrequency, IssuerType, NatureofInstruments, PageLinks, bondTypes } from "../common/Constants";
import Footer from "../components/template/Footer";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import InvestSteps from "../components/InvestSteps";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import moment from "moment";
import { numberWithCommas } from "../common/Utilities";
import { InitialData } from "../components/Form/InitialData";
import Copy from "../components/Svg/Copy";
import Alert from "../components/Modals/Alert";
import Download from "../components/Svg/Download";
import copy from 'copy-to-clipboard';
import { toast } from "react-hot-toast";
import Calculater from "../components/Modals/Calculater";


const BondCalculator: React.FC = () => {

    const [openEarnDropDown, setOpenEarnDropDown] = useState(true);
    const [BondName, setBondName] = useState('');
    const [bonds, setBonds] = useState(0);
    const toggleEarnDropdown = () => setOpenEarnDropDown(!openEarnDropDown);
    const [subTable, setSubTable] = useState(false);
    const toggleEarnSubTableDropdown = () => setSubTable(!subTable);
    const [isin, setIsin] = useState('');
    const [bondID, setBondID] = useState(0);
    const [qty, setQty] = useState<any>(1);
    const [Yield, setYield] = useState<any>();
    const [calcudata, setCalcudata] = useState<any>([]);
    const location = useLocation();
    const [formdata, setFormData] = useState('');
    const [Buysell, setBuysell] = useState<APIData.Bond[]>([]);
    const [bondsDetail, setBondDetail] = useState<APIData.BondlistData>();
    const [investmentValues, setInvestmentValues] = useState<APIData.InvestmentCalculator>();
    const [bondsDetailCashflow, setBondDetailCashflow] = useState<APIData.BondCashFlow[]>([]);
    const [calculaterDetail, setCalculaterDetail] = useState<APIData.calculater>(InitialData.calculater);
    const [investmentToday, setInvestmentToday] = useState('');
    const [alertPopup, setAlertPopup] = useState(false);
    const [calculater, setCalculater] = useState(false);
    const [price, setPrice] = useState<any>(0);
    const [highlighterClass, setHighlighterClass] = useState('');
    let InterstEarned = 0;
    let principalAmount = 0;
    const scollToRef = useRef();
    let nextprincipleAmount = 0;
    let totalreturnAmount = 0;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const goToBuy = (isin: string) =>
        navigate({
            search: "?isin=" + isin,
        });
    const goToBOND = () =>
        navigate({
            pathname: PageLinks.PROFILE_LISTING,
            search: "?bond_type_bond=" + bondTypes.ADDEDBOND + "?bond_type_bond1=" + bondTypes.EXISTINGBOND,
        });
    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("isin")) {
                const id = query.get("isin");
                setIsin((id!));
            }
        }
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("bond_id")) {
                const bond_id = query.get("bond_id");
                setBondID(parseInt(bond_id!));
            }
        }
    }, [location]);

    useEffect(() => {
        principalAmount = parseInt(investmentToday);
    }, [investmentToday])

    useEffect(() => {
        if (isin != '') {
            setShowPreloader(true);
            ServerAPI.BondDetail(isin).then((response: any) => {
                if (response != undefined) {
                    setBondDetail(response);
                }
            }).finally(() => {
                setShowPreloader(false);
            })

            setShowPreloader(true);
            ServerAPI.Cashflow(isin).then((response: any) => {
                if (response != undefined) {
                    setBondDetailCashflow(response);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [isin])
    // useEffect(() => {
    //     if (isin != '') {
    //         setShowPreloader(true);
    //         ServerAPI.BondInvestmentCalculator(isin, qty).then((response: any) => {
    //             if (response != undefined) {
    //                 setInvestmentValues(response);
    //                 setInvestmentToday(response['total_investment']);
    //             }
    //         }).finally(() => {
    //             setShowPreloader(false);
    //         })
    //     }
    // }, [isin, qty])

    const BuySell = () => {
        setShowPreloader(true);
        ServerAPI.BuySell().then((response: any) => {
            if (response != undefined) {
                setBuysell(response['message'])
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    useEffect(() => {
        BuySell();
    }, [])

    useEffect(() => {
        setYield(calcudata?.data?.YieldVal);
        setPrice(calcudata?.data?.price)
    }, [calcudata])
    
    const BondEnquiry = () => {
        setShowPreloader(true);
        ServerAPI.Calculater(bondsDetail?.bond_id, Yield,qty,price, bonds).then((response) => {
            toast.success(response["message"]);
            navigate(PageLinks.ENQUIRE_POPUP);

        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const gotoHighlight = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        }
    };
    useEffect(() => {
        if (BondName == 'BUY') {
            setBonds(1);
        } else if (BondName == 'SELL')
            setBonds(2);
    }, [BondName])

    return (
        <div className="main-index-wrapper">
            <Header />
            
            <main>
                <section className="mt-0 pt-110 pb-10 pb-md-130 pt-md-130 pt-xs-90 pb-xs-10">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li>Bonds</li>
                                            <li>Results</li>
                                            <li className="active">{bondsDetail?.bond_name}</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section className="sovereign_gold_bonds_detail_desc profile_listing m-0 mt-0 pt-0 pb-0 pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                    <div className="container-fluid pl-0">
                        <div className="align-items-center p-0">
                            <div className="row">
                                <div className="d-flex align-items-center">
                                    <div className="image-logo-ipo mr-3 ">

                                        <img className="bond-title-img" src={bondsDetail?.bond_logo} alt="" />

                                    </div>
                                    <div className="w-auto ml-2 title-bond-detail mt-3">
                                        <h4>{bondsDetail?.bond_name}</h4>
                                        <div>
                                            <button className=" fs-14 return-btn mt-1">RETURNS Based On Market</button>
                                            {bondsDetail?.bond_isin_number != '' &&
                                                <button className="isin-btn m-0 fs-15"><span className="fs-15 p-0">ISIN:&nbsp;</span>{bondsDetail?.bond_isin_number}&nbsp;
                                                    <span className="cursor" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        copy(bondsDetail?.bond_isin_number!, {
                                                            debug: true,
                                                        });
                                                        toast.success('ISIN Number Copied To Clipboard');
                                                    }}>
                                                        <Copy />
                                                    </span>
                                                </button>
                                            }
                                        </div>
                                    </div>

                                </div>


                            </div>


                            <div className="row section-table mt-3 ml-0">
                                <div className="col-lg-3 col-md-6 col-sm-6 p-0">
                                    <div className="spotlight_box_one1 ">
                                        <div className="spotlight_box_inner">
                                            <div className="spotlight_box_inner_text1">Coupon</div>
                                            <div className="spotlight_box_inner_text2 fs-14">{bondsDetail?.bond_coupon_rate != null && bondsDetail?.bond_coupon_rate != '' ? bondsDetail?.bond_coupon_rate + "% p.a" : "N/A"}</div>
                                        </div>
                                        {bondsDetail?.bond_type !== 1 &&
                                        <div className="spotlight_box_inner">
                                            <div className="spotlight_box_inner_text1">Yield</div>
                                            <div className="spotlight_box_inner_text2 fs-14">{bondsDetail?.bonds_yeild != null && bondsDetail?.bonds_yeild != '' ? bondsDetail?.bonds_yeild + "%" : "N/A"}</div>
                                        </div>}
                                    </div>
                                    <div className="spotlight_box_two2 ">
                                        <div className="spotlight_box_inner">
                                            <div className="spotlight_box_inner_text1">Interest Payment</div>
                                            <div className="spotlight_box_inner_text2 fs-14">
                                               </div>
                                        </div>
                                        <div className="spotlight_box_inner">
                                            <div className="spotlight_box_inner_text1">Min. Investment</div>
                                            <div className="spotlight_box_inner_text2 fs-14">{bondsDetail?.bond_minimum_application != null && bondsDetail?.bond_minimum_application != '' ? "₹" + numberWithCommas(bondsDetail.bond_minimum_application) : "N/A"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 p-0">
                                    <div className="spotlight_box_one1 mx-lg-10 ml-md-10">
                                        <div className="spotlight_box_inner">
                                            <div className="spotlight_box_inner_text1">Interest Payment Freq.</div>
                                            <div className="spotlight_box_inner_text2 fs-14">
                                                {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_ANNUAL &&
                                                    "Annual"
                                                }
                                                {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                    "Monthly"
                                                }
                                                {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                    "Quarterly"
                                                }
                                                {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                    "Semi-Annual"
                                                }
                                                {parseInt(bondsDetail?.bond_interest_frequency!) == 0 &&
                                                    "N/A"
                                                }
                                                {bondsDetail?.bond_interest_frequency == null || bondsDetail?.bond_interest_frequency == '' &&
                                                    "N/A"
                                                }</div>
                                        </div>
                                        <div className="spotlight_box_inner">
                                            <div className="spotlight_box_inner_text1">Maturity Date</div>
                                            <div className="spotlight_box_inner_text2 fs-14">{bondsDetail?.bond_maturity_date != null && bondsDetail?.bond_maturity_date != '' ? moment(bondsDetail?.bond_maturity_date).format("MMM Do YYYY") : "N/A"}</div>
                                        </div>
                                    </div>
                                    <div className="spotlight_box_two2 mx-lg-10 ml-md-10">
                                        <div className="spotlight_box_inner">
                                            <div className="spotlight_box_inner_text1">Instrument Type</div>
                                            <div className="spotlight_box_inner_text2 fs-14">
                                                {bondsDetail?.bond_nature_of_instrument == NatureofInstruments.TAXABLE ? "Taxable" : "Tax Free"}</div>
                                        </div>
                                        <div className="spotlight_box_inner">
                                            <div className="spotlight_box_inner_text1">Date of Issue</div>
                                            <div className="spotlight_box_inner_text2 fs-14">{"N/A"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-md-10  p-0">
                                    <div className="spotlight_box_one1 ">
                                        <div className="spotlight_box_inner">
                                            <div className="spotlight_box_inner_text1">Call Option</div>
                                            <div className="spotlight_box_inner_text2 fs-14">{bondsDetail != undefined && bondsDetail.bond_call_details != undefined && bondsDetail.bond_call_details.length > 0 ?
                                                <Fragment>
                                                    Yes<span className="call-option" id="call" onClick={handleClick} >{moment(bondsDetail?.bond_call_details[0].bond_call_detail_datetime).format("MMM Do YYYY")} <img src="assets/img/call-option-info.svg" /></span>
                                                </Fragment>
                                                :
                                                <div className="spotlight_box_inner_text2 fs-14">No</div>
                                            }</div>
                                            <Popover
                                                id="call"
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <Typography sx={{ p: 2 }}>
                                                    <div className="call-option-content">
                                                        <h5>Dates for Call Option</h5>
                                                        <h6>02 Oct 2022, 10.00am to 11.00am</h6>
                                                        <h6>02 Oct 2022, 10.00am to 11.00am</h6>
                                                        <h6 className="selected">02 Oct 2022, 10.00am to 11.00am</h6>
                                                        <div className="center-btn">
                                                            <button className="alert-button" onClick={(e: any) => {
                                                                e.preventDefault();
                                                                setAlertPopup(true);
                                                                handleClose();
                                                            }}>Set Alert<span><i className="fa fa-arrow-right" aria-hidden="true"></i></span></button>
                                                        </div>
                                                    </div>
                                                </Typography>
                                            </Popover>
                                        </div>
                                        <div className="spotlight_box_inner">
                                            <div className="spotlight_box_inner_text1">Put Option</div>
                                            <div className="spotlight_box_inner_text2 fs-14">  {bondsDetail != undefined && bondsDetail.bond_put_details != undefined && bondsDetail.bond_put_details.length > 0 ?
                                                <Fragment>
                                                    Yes<span className="call-option" id="call" onClick={handleClick} >{moment(bondsDetail?.bond_put_details[0].bond_put_detail_datetime).format("MMM Do YYYY")} <img src="assets/img/call-option-info.svg" /></span>
                                                </Fragment>
                                                :
                                                <div className="spotlight_box_inner_text2 fs-14">No</div>
                                            }</div>
                                        </div>
                                    </div>
                                    <div className="spotlight_box_two2 ">
                                        <div className="spotlight_box_inner">
                                            <div className="spotlight_box_inner_text1">Mode of Issue</div>
                                            <div className="spotlight_box_inner_text2 fs-14">
                                                {'N/A'}</div>
                                        </div>
                                        <div className="spotlight_box_inner">
                                            <div className="spotlight_box_inner_text1">Listed</div>
                                            <div className="spotlight_box_inner_text2 fs-14">{"N/A"}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 bond-cal">
                                    {bondsDetail != undefined && bondsDetail.bond_rating_details.length > 0 &&
                                        <h4>BOND’S Rating</h4>
                                    }
                                    <div className="detail-view-meter" style={{ marginTop: "20px" }}>
                                        <div className="mb-2 row">

                                            {bondsDetail != undefined && bondsDetail.bond_rating_details.length > 0 && bondsDetail.bond_rating_details.map((rating: APIData.BondRatingDetails) => {
                                                return (
                                                    <Fragment>
                                                        {
                                                            rating.bond_rating_image != null && rating.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=" &&
                                                            <>
                                                              
                                                                    <div className="m-auto res-image-cal w-75">
                                                                        <img className="w-100" src={rating.bond_rating_image} alt="" />
                                                                        <div>
                                                                            <table className="ipo-bond-download-table mt-3 w-100">
                                                                                <tr>
                                                                                    <td className="sourceSansPro-bold">{rating.bond_rating_agency}</td>
                                                                                    <td className="sourceSansPro-bold">{rating.bond_rating}</td>
                                                                                    {rating.bond_rating_certificate != "" &&
                                                                                        <td className="sourceSansPro-bold cursor" onClick={(e: any) => {
                                                                                            e.preventDefault();
                                                                                            window.open(rating.bond_rating_certificate);
                                                                                        }}><Download /></td>
                                                                                    }
                                                                                </tr>
                                                                            </table>
                                                                        
                                                                    </div>
                                                                </div>

                                                            </>
                                                        }
                                                    </Fragment>
                                                )
                                            })
                                            }

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h4 className="sell-bond-h4 mt-2">Do you wish to Buy or sell this bond?</h4>
                                


                                <form className="quote-form row mt-2">
                                    <div className="col-lg-12">
                                        <span className="input-title-filled gender_col m-0 mb-2" role={"group"}>
                                            {Buysell != undefined && Buysell.length > 0 && Buysell.map((mode: APIData.Bond) => {
                                                return (
                                                    <div className={BondName == mode.buy_sell_name ? "sell_btn selected" : "sell_btn"}>
                                                        <input type="radio" value={mode.buy_sell_id} onClick={(e: any) => {
                                                            setBondName(mode.buy_sell_name);
                                                        }} onChange={(e: any) => {
                                                            if (e.target.checked) {
                                                                if (BondName == 'BUY') {
                                                                    setBonds(1);
                                                                } else if (BondName == 'SELL') {
                                                                    setBonds(2);
                                                                } else {
                                                                    setBonds(0);
                                                                }
                                                            }
                                                        }} className="d-none" name={mode.buy_sell_name} id={mode.buy_sell_id + ""} />
                                                        <label
                                                            htmlFor={mode.buy_sell_id + ""}
                                                            className={BondName == mode.buy_sell_name ? "sell-btn-label-selected" : "sell_btn-label"}
                                                        >
                                                            {mode.buy_sell_name}
                                                        </label>
                                                    </div>
                                                )
                                            })}


                                        </span>
                                    </div>


                                    
                                        <div className="col-lg-12">
                                            <form className="quote-form pt-20">
                                                <div className="row mx-1">
                                                    <div className="email-input_bond input_field input-pt pl-0 col-lg-3 col-md-6">
                                                    {BondName == 'SELL' ?
                                                        <label className="input-title1">Sell Price</label>:
                                                       <label className="input-title1">Buy Price</label> }
                                                        <input type="text"
                                                            onChange={(e: any) => {
                                                                setPrice(e.target.value);
                                                            }}
                                                            value={calcudata?.data?.price}
                                                            name="price"
                                                            id="price" placeholder="Type here" />
                                                    </div>

                                                    <div className="email-input_bond input_field input-pt pl-0 col-lg-3 col-md-6">
                                                    {BondName == 'SELL' ?
                                                        <label className="input-title1">Sell Yield</label>:
                                                        <label className="input-title1">Buy Yield</label>}
                                                        <input type="text"
                                                            onChange={(e: any) => {
                                                                setYield(e.target.value);
                                                            }}
                                                            value={calcudata?.data?.YieldVal}
                                                            name="yield" id="yield" placeholder="Type here" />
                                                    </div>

                                                    <div className="email-input_bond input_field input-pt pl-0 col-lg-3 col-md-6">
                                                        <label className="input-title1">Quantity</label>
                                                        <input type="text"
                                                            onChange={(e: any) => {
                                                                setQty(e.target.value);
                                                            }}
                                                            value={qty}
                                                            name="quantity" id="quantity" placeholder="Type here" />
                                                    </div>
                                                    <div className="email-input_bond input_field input-pt pl-0 col-lg-3 col-md-6">
                                                        <label className="input-title1">Total Amount</label>
                                                        <input type="text"
                                                             value={price && qty ? price*qty : 0} name="Amount" id="Amount" placeholder="Type here" />
                                                    </div>

                                                </div>
                                            </form>
                                        </div> 
                                    <div className="row mt-4">
                                        <div className="col-lg-6">
                                            <button onClick={(e: any) => {
                                                e.preventDefault();
                                                setCalculater(true);
                                            }} className="calculate-btn mx-2 bonds-detail-btn"><span><svg xmlns="http://www.w3.org/2000/svg" className="mb-1 mx-1" width="15" height="15" viewBox="0 0 69.398 79.312">
                                                <path id="Path_23208" data-name="Path 23208" d="M377.526,17.349V71.877a7.438,7.438,0,0,1-7.435,7.435H315.565a7.438,7.438,0,0,1-7.437-7.435V17.349a7.438,7.438,0,0,1,7.437-7.435H323V1.859A1.864,1.864,0,0,1,324.859,0H326.1a1.864,1.864,0,0,1,1.859,1.859V9.914H357.7V1.859A1.864,1.864,0,0,1,359.557,0H360.8a1.864,1.864,0,0,1,1.859,1.859V9.914h7.435a7.438,7.438,0,0,1,7.435,7.435m-64.441,0v7.437h59.484V17.349a2.486,2.486,0,0,0-2.478-2.478H315.565a2.487,2.487,0,0,0-2.48,2.478m59.484,54.528V29.743H313.085V71.877a2.486,2.486,0,0,0,2.48,2.478h54.526a2.485,2.485,0,0,0,2.478-2.478m-47.71-22.306A1.864,1.864,0,0,1,323,47.712v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871A1.864,1.864,0,0,1,323,62.583v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM339.73,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM354.6,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Z" transform="translate(-308.128)" fill="#fff" />
                                            </svg></span>&nbsp;Yield / Price Calculator</button>
                                        </div>
                                        <div className="col-lg-6 d-flex justify-content-end">
                                            <button onClick={(e: any) => {
                                                e.preventDefault();
                                                goToBOND()
                                            }} className="order-confirm-page-home-btn1 mx-3">Cancel</button>
                                            <button onClick={(e: any) => {
                                                e.preventDefault();
                                                if (bonds == 0) {
                                                    toast.error("Please selected BUY or SELL");
                                                }else if(Yield == ""){  
                                                    toast.error("Please enter a Yield");
                                                }else if(qty == ""){
                                                    toast.error("Please enter a qty");
                                                } else {
                                                    BondEnquiry();
                                                }

                                            }} className="sell_readmore bonds-detail-btn btn-1 mx-2 mt-1" style={{ padding: "3px 20px" }}>
                                                Enquire Now</button>
                                        </div>
                                    </div>
                                </form>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>
                <Calculater showModal={calculater} closeModal={() => { setCalculater(false) }} setFormData={setFormData} bondID={bondID} bondsDetail={bondsDetail} isin={isin} setCalcudata={setCalcudata} />
                <Alert showModal={alertPopup} closeModal={() => { setAlertPopup(false) }} />
            </main>
            <Footer />
        </div >
    )
}

export default BondCalculator;
