import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { kycStatus, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { kycStatusUpdate } from "../common/Utilities";
import HeaderLogoContent from "../components/HeaderLogoContent";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";
import StepsComponent from "../components/StepsComponent";

const PersonalDetailsEditVerifyEmail: React.FC = () => {
    const location = useLocation();
    const [pan, setPan] = useState('');
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const navigate = useNavigate();
    const { setShowPreloader } = useAppStateAPI();
    const [panVerified, setPanVerified] = useState(false);
    const [KycExit, setKycExit] = useState(false);

    const panCaps = (value: string) => {
        let panValue = value.toLocaleUpperCase();
        setPan(panValue);
    }

    const panVerify = (value: string) => {
        localStorage.setItem('animation', 'pan');
        let panregex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
        if (!panregex.test(value)) {
            toast.error("PAN number belongs to the Corporate Only the PAN of Individuals is allowed to open the account");
        } else {
            setShowPreloader(true);
            let data: any = { ...userDetail };
            ServerAPI.PanVerify(value).then((response: any) => {
                
                if (response['pan_fname'] != undefined && response['pan_fname'] != null) {
                    if (response['pan_status'] == 'E' && response['aadhar_verify_status'] == 0 && response['person_video_verification_status'] == 1) {
                        localStorage.setItem('aadhar', 'true');
                        localStorage.setItem('verification', 'false');
                        navigate(PageLinks.PAN_VERIFICATION);
                    } else if (response['pan_status'] == 'E' && response['aadhar_verify_status'] == 1 && response['person_video_verification_status'] == 0) {
                        localStorage.setItem('aadhar', 'false');
                        localStorage.setItem('verification', 'true');
                        navigate(PageLinks.PAN_VERIFICATION);
                    } else if (response['pan_status'] == 'E' && response['aadhar_verify_status'] == 1 && response['person_video_verification_status'] == 1) {
                        localStorage.setItem('aadhar', 'false');
                        localStorage.setItem('verification', 'false');
                        navigate(PageLinks.PAN_VERIFICATION, { state: { name: 'bank' } });
                    } else if (response['pan_status'] == 'E' && response['aadhar_verify_status'] == 0 && response['person_video_verification_status'] == 0) {
                        localStorage.setItem('aadhar', 'true');
                        localStorage.setItem('verification', 'true');
                        navigate(PageLinks.PAN_VERIFICATION);
                    }
                    else if (response['pan_status'] == 'N' || response['pan_status'] == 'F' || response['pan_status'] == 'ED' || response['pan_status'] == 'D') {
                        navigate(PageLinks.PAN_INVALID, { state: { name: 'delete' } });
                    } else if (response['pan_status'] == 'X') {
                        navigate(PageLinks.PAN_INVALID, { state: { name: 'deactivated' } });
                    } else if (response['pan_status'] == 'I') {
                        navigate(PageLinks.PAN_INVALID, { state: { name: 'inoperative' } });
                    } else {
                        navigate(PageLinks.PAN_INVALID, { state: { name: 'delete' } });
                    }
                    setPanVerified(true);
                }else if(response?.data?.errors[0] !== "" && response?.data?.errors[0] === "Incorrect Date of Birth. Please note Date of Birth should be as per your PAN Card."){
                    
                    navigate(PageLinks.PROFILE_PERSONAL_DETAILS_EDIT);
                    toast.error(response?.data?.errors[0])
                }else{
                    toast.error(response?.data?.errors[0])
                }
            }).catch((error) => {
                setShowPreloader(false);
                if (error.status == 'Match') {
                    toast.error("the PAN You Have Entered is Debarred Please Enter Valid PAN Number");
                }
            }).finally(() => {
                setShowPreloader(false);
                localStorage.setItem('animation', 'default');
            });
        }

    }
    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: any) => {
            setUserDetails(res)
        }).finally(() => {
            setShowPreloader(false);
        })
    }, [])


    return (
        <div className="main-index-wrapper">
            <main>
                {/* Profile-details-edit-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <HeaderLogoContent />
                                    <StepsComponent step={1} />
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow " data-wow-delay="0.1s">Personal Details <span className="step_count">STEPS - 1 of 2</span></h6>
                                                            <hr></hr>
                                                            <form className="quote-form mb-10" action="#">
                                                                <div className="col-xl-8 pl-0 float-left">
                                                                    <h6 className="pt-20 mb--10 " data-wow-delay="0.1s">Hey {userDetail?.fname} {' '} {userDetail?.lname}, Let’s Validate Your PAN</h6>
                                                                    <div className="col-xl-6 pl-0 float-left mt-3">
                                                                        <div className="email-input input_field pl-0">
                                                                            <label className="input-title">PAN Number<span className="error-area">*</span></label>
                                                                            <input type="text" className={pan.length != 10 ? "disabled-field" : ""} placeholder="10 Digit PAN Number" maxLength={10} value={pan} onChange={(e: any) => {
                                                                                panCaps(e.target.value);
                                                                            }} />
                                                                            <button onClick={(e: any) => {
                                                                                e.preventDefault();
                                                                                if (pan.length == 10) {
                                                                                    panVerify(pan);
                                                                                }
                                                                            }} className={pan.length != 10 ? "pan_verify_btn disabled_btn" : "pan_verify_btn"}>Verify</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 p-0 float-left">

                                                                </div>
                                                                <div className="col-xl-12 p-0 float-left text-center mt-200">
                                                                    <button onClick={(e:any)=>{
                                                                        e.preventDefault();
                                                                        if(panVerified==false){
                                                                            toast.error('First verify your PAN number');
                                                                        }
                                                                      
                                                                    }} className={pan.length == 10 &&panVerified==true ? "theme_btn primary_btn_one sub-btn wow mb-3" : "theme_btn primary_btn sub-btn wow mb-3"}>Continue</button>
                                                                    <div onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        setKycExit(true);
                                                                    }} className="save_continue_btn text-center cursor"><u>Save & Complete Later</u></div>
                                                                </div>

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ConfirmationKYCModel showModal = {KycExit} closeModal = {()=>{setKycExit(false)}} confirmtype={'pan'}/>
                {/* Profile-details-edit-area end */}
            </main>
        </div>
    )
}

export default PersonalDetailsEditVerifyEmail;