import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { EmailShareButton, FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton, EmailIcon, FacebookIcon, TelegramIcon, TwitterIcon, WhatsappIcon } from "react-share";

interface FormParameters {
    url: string,
}

const SocialMediaShare: React.FC<FormParameters> = ({ url }) => {
    const [openShareModal, setOpenShareModal] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <a className="icon-box-share n-ppost cursor"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                <i className="far fa-share-alt"></i>
            </a>
            <Menu
            
            className="horiz-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem>
                    <FacebookShareButton url={url}>
                        <FacebookIcon size={32} round={true} />&nbsp;&nbsp;
                    </FacebookShareButton>
                </MenuItem>
                <MenuItem>
                    <TwitterShareButton url={url}>
                        <TwitterIcon size={32} round={true} />&nbsp;&nbsp;
                    </TwitterShareButton>
                </MenuItem>
                <MenuItem>
                    <WhatsappShareButton url={url}>
                        <WhatsappIcon size={32} round={true} />&nbsp;&nbsp;
                    </WhatsappShareButton>
                </MenuItem>
                <MenuItem>
                    <TelegramShareButton url={url}>
                        <TelegramIcon size={32} round={true} />&nbsp;&nbsp;
                    </TelegramShareButton>
                </MenuItem>
                <MenuItem>
                    <EmailShareButton url={url}>
                        <EmailIcon size={32} round={true} />&nbsp;&nbsp;
                    </EmailShareButton>
                </MenuItem>
            </Menu>
        </div>
    );
}

export default SocialMediaShare;