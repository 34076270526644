
export namespace UIDataTypes {

}


export namespace FormDataTypes {
    export interface SelectOption {
        value: string,
        label: string
    }
}

export namespace FormDefaultConstants {
    export const putAndCallTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Put'
        },
        {
            value: '2',
            label: 'Call'
        },
        {
            value: '3',
            label: 'Both'
        }
    ]
    export const frequnceyInterestOfPaymentTypeOption: FormDataTypes.SelectOption[] = [
        {
            value: '1',
            label: 'Monthly'
        },
        {
            value: '2',
            label: 'Cumulative'
        },
        {
            value: '3',
            label: 'Yearly'
        },
    ]
}

export namespace APIData {

    export interface register {
        firstName: string,
        lastName: string,
        mobile_number: string,
    }


    export interface userDetail {
        address_proof_id: number;
        firstname: string,
        lastname: string,
        fname: string,
        lname: string,
        pan_number: string,
        pan_name: string,
        aadhar_number: string,
        aadhar_name: string,
        dob: string,
        country_code: string,
        smart_card_required: number,
        smart_card_number: string,
        smart_card_PIN: string,
        gender: number,
        married_status: number,
        mothers_maiden_name: string,
        mobile_number: string,
        is_mobile_verified: number,
        annual_income: number,
        trading_experience: number,
        occupation: string,
        lifestyle: string,
        geogriphical_code: string,
        education_degree: string,
        address_line_1: string,
        address_line_2: string,
        address_line_3: string,
        address_zip: string,
        address_state_code: string,
        email_id: string,
        is_email_verified: number,
        address_state: string,
        address_city: string,
        city_sequence_no: string,
        family_account: string,
        mental_disability: string,
        is_pan_verified: number,
        is_aadhar_verified: number,
        signature: string,
        signature_file_flag: string,
        profile_image: string,
        verification_video: string,
        in_person_verification: number,
        proof_type: string,
        proof_front_image: string,
        proof_back_image: string,
        manager_id: number,
        is_politically_exposed: number,
        would_you_like_to_activate: number,
        mediaBlob: any,
        filled_itr_last_2years: number,
        ekyc_application_status: string,
        existing_demat_account_count: number,
        new_demat_account_count: number,
        activation_date: string,
        auth_interfychange: number,
        e_confirmation: number,
        auth_fund_securities: number,
        auth_fetch_kyc_details: number,
        auth_use_aadhar: number,
        citizen_usa: number,
        itr_records: number,
        tax_resident: number,
        is_nominee_added: number,
    }

    export interface otp_verify {
        otp: string
    }

    export interface email_Verify {
        email_id: string
    }
    export interface signup {
        firstName: string,
        lastName: string,
        mobile_number: string,
        share_details_with_partner: number,
        contact_me_for_details: number,
        terms_and_conditions: number,
    }
    export interface calculater {
        bond_id: number,
        quantity: string,
        price: string,
        yield: string,
        settlement_date_id: number,
        buy_sell_id: number,
    }
    export interface bankDetails {
        number_of_account: string,
        ifsc_code: string,
        account_number: string,
        account_type_1: number,
        account_type_2: number,
        is_primary: number,
        cancel_cheque_image: string,
        dividend_bank_account_type: string,
        dividend_bank_account_code: string,
        dividend_bank_account_number: string,
        dividend_bank_CCY: number,
        dividend_bank_IFSC_code: string
        dividend_currency: number,
        RBI_reference_no: string,
        RBI_approval_date: string,
        account_number_confirm?: string
    }


    export interface ExistingDematAccount {
        depository_id: number,
        auth_fund_securities: number,
        auth_receive_info_stock_broker: number,
        auth_transfer_fund_securities: number,
        auth_accept_collateral_segments: number,
        dp_id: string,
        client_id?: string,
        dpidconfirm?: string,
        reenterclientid? : string,
        born_place_id: number,
        brokerage_scheme_id: number,
        primary_source_id: number,
        dp_resident_usa: number
        dp_resident_india: number,
        various_segment: VariousSegment[]
    }
     export interface VariousSegment{
        various_segment_id: number,
        various_segment_selected_status: number
     }
     export interface Segments {
        various_segment: string;
        various_segment_created_at: string;
        various_segment_id: number;
        various_segment_status: number;
        various_segment_updated_at: string;
      }
    export interface AadharDetails {
        aadhar_number: string,
        care_of: string,
        country: string,
        date_of_birth: string,
        district: string,
        gender: string,
        house_no: string,
        image: string,
        landmark: string,
        location: string,
        name: string,
        pincode: string,
        state: string,
        street: string,
        village_town_city: string
    }

    export interface AddressProof {
        address_proof: string;
    address_proof_created_at: string;
    address_proof_id: number;
    address_proof_status: number;
    address_proof_updated_at: string;
    }

    export interface StateList {
        state_id: any,
        state_name: string,
    }

    export interface cityList {
        city_id: number,
        city_name: string,
        state_id: number
    }

    export interface updateAadharDetails {
        address_proof: string,
        front_image: string,
        back_image: string,
        Address_line_1: string,
        Address_line_2: string,
        pincode: string,
        state: string,
        city: string,
        personal_data_address_proof_number: string;
    }

    export interface Depositories {
        depository: string
        depository_id: number,
    }
    export interface BrokerageScheme {
        brokerage_scheme: string;
        brokerage_scheme_created_at: string;
        brokerage_scheme_id: number;
        brokerage_scheme_status: number;
        brokerage_scheme_updated_at: string;
      }

    export interface BankAccountDetails {
        account_number: number,
        account_type_1: number,
        account_type_2: number,
        bank_details_created_at: string,
        bank_details_updated_at: string,
        cancel_cheque_image: string,
        column_1: string,
        column_2: string,
        fname: string,
        bank_details_id: number,
        bank_id: number,
        ifsc_code: number,
        is_primary: number,
        is_asba: number,
        lname: string,
        user_id: number,
        bank_name: string,
        bank_status: number,

    }

    export interface ExisitingDematAccountDetails {
        application_number: string,
        beneficiary_id: number,
        country_code: string,
        depository: string,
        dp_id: string,
        dp_name: string,
        ekyc_application_status: string,
        email_id: string,
        exist_demat_account_details_created_at: string,
        exist_demat_account_details_updated_at: string,
        father_or_husband_name: string,
        fname: string,
        exist_demat_id: number,
        is_email_verified: number,
        is_mobile_verified: number,
        lname: string,
        mobile_number: string,
        suffix: string,
        title: string,
        user_id: number,
        exist_demat_account_details_status_key: number,
        exist_demat_account_primary_account: number,
        demat_id: number,
        demat_account_type: number

    }

    export interface ProfessionDropDown {
        occupation: string;
       occupation_created_at: string;
       occupation_id: number;
       occupation_status: number;
       occupation_updated_at: string;
    }

    export interface IfscDetails {
        ADDRESS: string,
        BANK: string,
        BANKCODE: string,
        BRANCH: string,
        CENTRE: string,
        CITY: string,
        CONTACT: string,
        DISTRICT: string,
        IFSC: string,
        IMPS: boolean,
        ISO3166: string,
        MICR: string,
        NEFT: boolean,
        RTGS: boolean,
        STATE: string,
        SWIFT: string,
        UPI: boolean
    }

    export interface WealthSource {
        created_at: string;
    primary_source_of_wealth_id: number;
    primary_source_of_wealth_name: string;
    primary_source_of_wealth_status: number;
    updated_at: string
    }

    export interface WhereYouBorn {
        country_name: string,
        created_at: number,
        updated_at: number,
        where_you_born_id: number,
        where_you_born_status: number
    }

    export interface NewDematAccount {
        auth_receive_info_stock_broker: number,
        auth_transfer_fund_securities: number
        auth_fund_securities:  number,
        auth_accept_collateral_segments: number,
        born_place_id: number,
        primary_source_id: number,
        dp_resident_india: number,
        dp_resident_usa: number,
        demat_signature_image: string,
        account_statement_in_electronics: number,
        primary_source: number,
        born_place: number,
        demat_terms_and_conditions: number,
    }
    export interface VariousSegment{
        various_segment_id: number
        various_segment_selected_status: number
    }
    export interface NomineeDetails {
        allocation: number,
        title: string,
        fname: string,
        mname: string,
        lname: string,
        relationship: string,
        identification: number,
        mobile_number: string,
        dob: string,
        address_line_1: string,
        address_line_2: string,
        address_line_3: string,
        address_zip: string,
        address_state_code: string,
        address_state: string,
        address_city: string,
        city_sequence_no: string,
        current_address_line_1: string,
        current_address_line_2: string,
        current_address_line_3: string,
        current_address_zip: string,
        current_address_state_codecurrent: string,
        current_address_state: string,
        current_address_city: string,
        current_city_sequence_no: string,
        identification_number: string
    }

    export interface NomineeDataUpdate {
     
            nominee_percentage: string;
            nominee_fullname: string;
            name_title_id: number;
            nominee_id: number;
            relationship_with_applicant_id: number;
            nominee_identification_id: number;
            nominee_guardian_id?: number;
            nominee_identification_number: string;
            nominee_mobile_number: string;
            nominee_dob: string;
            nominee_address_line_1: string;
            nominee_address_line_2: string;
            nominee_address_pincode: string;
            nominee_address_state: string;
            nominee_address_city: string;
            nominee_address_as_user: number;
            nominee_is_minor: number;
            nominee_guardian_records: NomineeGuardian;
            nominee_guardian_identification_number: string;
    }


  export interface NomineeData {
    nominee_percentage: string;
    nominee_fullname: string;
    name_title_id: number;
    nominee_id: number;
    relationship_with_applicant_id: number;
    nominee_identification_id: number;
    nominee_guardian_id?: number;
    nominee_identification_number: string;
    nominee_mobile_number: string;
    nominee_dob: string;
    nominee_address_line_1: string;
    nominee_address_line_2: string;
    nominee_address_pincode: string;
    nominee_address_state: string;
    nominee_address_city: string;
    nominee_address_as_user: number;
    nominee_is_minor: number;
    nominee_guardian_records: NomineeGuardian;
    nominee_guardian_identification_number: string;
    nominee_guardian_mobile_number?: string;
    nominee_guardian_fullname?: string;
    nominee_relationship_with_applicant_id? : number;
    nominee_name_title_id? : number;

  }

  export interface NomineeGuardian {
    nominee_fullname?: string;
    nominee_name_title_id?: number;
    name_title: string;
    relationship_with_applicant: string;
    nominee_guardian_fullname: string;
    nominee_relationship_with_applicant_id?: number;
    nominee_identification_id?: number;
    nominee_guardian_identification_number: string;
    nominee_guardian_mobile_number: string;
  }


    export interface Relationship {
        relationship_with_applicant: string;
        relationship_with_applicant_created_at: string;
        relationship_with_applicant_id: number;
        relationship_with_applicant_status: number;
        relationship_with_applicant_updated_at: string;
    }
    export interface Titles {
        name_title: string;
        name_title_created_at: string;
        name_title_id: number;
        name_title_status: number;
        name_title_updated_at: string;
      }
     
    export interface NomineeProof {
        address_proof: string,
        nominee_identification_id: number
        nominee_identification: string;
        nominee_identification_created_at: string;
        nominee_identification_status: number;
        nominee_identification_updated_at: string;
    }
    export interface settlement {
        settlement_date_created_at: string,
        settlement_date_id: number
        settlement_date_name: string;
        settlement_date_updated_at: string;
    }
    export interface Yield {
        yield_type_created_at: string,
        yield_type_id: number
        yield_type_name: string;
        yield_type_updated_at: string;
    }
    export interface aidcc {
        ai_dcc_created_at: string,
        ai_dcc_id: number
        ai_dcc_name: string;
        ai_dcc_updated_at: string;
    }
    export interface NewDematDetails {
        born_place: number,
        check_box_account_statement_electronic: number,
        check_box_terms_selected: number,
        column_1: string,
        column_2: string,
        country_code: string,
        demat_account_details_created_at: string,
        demat_account_details_updated_at: string,
        demat_signature_image: string,
        dp_resident_india: number,
        dp_resident_usa: number,
        ekyc_application_status: number,
        email_id: string,
        father_or_husband_name: string,
        firstname: string,
        new_demat_id: number,
        is_email_verified: number,
        is_mobile_verified: number,
        lastname: string,
        mobile_number: string,
        primary_source: string,
        suffix: string,
        title: string,
        user_id: number,
        dp_account_number: string,
        demat_account_details_status: number,
        depository: string,
        demat_account_primary_account: number,
        demat_id: number,
        demat_account_type: number,
        demat_account_status: number,
        demat_is_primary: number,
        dp_id: string,
        client_id: string,
        where_you_born: string
    }

    export interface NomineeDetailsData {
        column_1: string,
        column_2: string,
        id: number,
        nominee_percentage: string;
        nominee_fullname: string;
        name_title: string;
        name_title_id: number;
        nominee_id: number;
        relationship_with_applicant_id: number;
        nominee_identification_id: number;
        nominee_guardian_id?: number;
        nominee_identification_number: string;
        nominee_mobile_number: string;
        nominee_dob: string;
        nominee_address_line_1: string;
        nominee_address_line_2: string;
        nominee_address_pincode: string;
        nominee_address_state: string;
        nominee_address_city: string;
        nominee_address_as_user: number;
        nominee_is_minor: number;
        relationship_with_applicant:string;
        nominee_guardian_records: NomineeGuardian;
        nominee_guardian_identification_number: string;
        user_id: number
    }



    export interface BondlistData {
        bond_about_this_ipo: string,
        bond_allotment_date: string,
        bond_app_no_series: string,
        bond_arranger_name: string,
        bond_asba_app_no_series_1: string,
        bond_asba_app_no_series_2: string,
        bond_asba_details: string,
        bond_brand_tags: BondBrandTags[],
        bond_rating_details: BondRatingDetails[],
        bond_rating_list: BondRatingDetails[],
        bond_brokerage_details: BondBrokerageDetail[],
        bond_brokerage_details_status: string,
        bond_brokerage_structure: string,
        bond_calc_int_on_holidays: string,
        bond_call_details: BondCallDetails[],
        bond_category_hni: string,
        bond_category_institutional: string,
        bond_category_instructions: string,
        bond_category_non_institutional: string,
        bond_category_retail: string,
        bond_closer_date: string,
        bond_closing_date: string,
        bond_coupon_amount: string,
        bond_coupon_date: string,
        bond_coupon_on: string,
        bond_coupon_rate: string,
        bond_created_at: string,
        bond_depository: string,
        bond_disclaimer: string,
        bond_dmat_book_closer_date: string,
        bond_e_form_incentive: string,
        bond_early_bird_incentive: string,
        bond_effort_basis: string,
        bond_eligible_investors: BondEligibleInvestors[],
        bond_cashflow_details: bondCashflowDetails[],
        bond_upcoming_issues: BondUpcomingIssues[],
        bond_exchange: string,
        bond_face_value: string,
        bond_final_issue_amount: string,
        bond_form_pdf_progress: string,
        bond_govt_guranatee: number,
        bond_green_shoe: string,
        bond_green_shoe_size: string,
        bond_gst: string,
        bond_guaranted_by: string,
        bond_id: number,
        bond_int_on_app_money: string,
        bond_int_on_maturity: string,
        bond_int_on_refund_money: string,
        bond_interest_days: string,
        bond_interest_frequency: string,
        bond_interest_type: string,
        bond_ip_date: string,
        bond_ipo_sell_windows_days: string,
        bond_isin_number: string,
        bond_issue_date: string,
        bond_issuer_documents: string,
        bond_issue_price: string,
        bond_issue_size: string,
        bond_issue_status: string,
        bond_issuer_details: issuerDetails[],
        bond_issuer_name: string,
        bond_key_highlights: string,
        bond_listing: number,
        bond_listing_circle: string,
        bond_logo: string,
        bond_maturity_amount: string
        bond_maturity_date: string,
        bond_minimum_application: string,
        bond_name: string,
        bond_nature_of_instrument: number,
        bond_ncd_available: [],
        bond_ncd_series: APIData.BondNcdSeries[],
        bond_ncd_status: number,
        bond_opening_date: string,
        bond_other_incentive: string,
        bond_our_status: string,
        bond_price_per_bond: string,
        bond_procurement_amount: string,
        bond_product_note: string,
        bond_purchase_limit: string,
        bond_purchase_limit_metric: string,
        bond_put_details: BondPutDetails[],
        bond_rbi_loan_code: string,
        bond_registrar: string,
        bond_script_id: string,
        bond_security_code: string,
        bond_security_type: number,
        bond_security_type_code: string,
        bond_series_instructions: string,
        bond_st: string,
        bond_sub_category_code: string,
        bond_term_condition_link: string,
        bond_trustee: string,
        bond_type: number,
        bond_updated_at: string,
        bond_upi_app_no_series_1: string,
        bond_upi_app_no_series_2: string,
        bonds_banner_content_image: string,
        bonds_banner_content_sub_title: string,
        bonds_banner_content_title: string,
        bonds_banner_rcb_notice_link: string,
        bonds_next_interest_payment_date: string,
        bonds_price_per_gram: number,
        bonds_yeild: string,
        exit_option_available: number,
        bond_highlights: BondHighlights[],
        bond_rating_image: string,
        bond_issuer_frequency_of_interest: string,
        share_link: string,
        bond_issuer_effective_yield: string,
        call_option: string,
        frequency_of_interest_name: string,
        interest_payment: string,
        listing_name: string,
        mode_of_issuer_name: string,
        nature_of_instrument_name: string,
        put_option: string,
        buy_sell_id: number,                                                            


    }
    export interface BondCashFlow {
        bse_rfq_id: number,
        order_number: number,
        quantity: number,
        action_flag: number,
    }

    export interface BondCashFlow {
        bond_cashflow_amount: string,
        bond_cashflow_created_at: string,
        bond_cashflow_date: string,
        bond_cashflow_days: string,
        bond_cashflow_id: number,
        bond_cashflow_type: string,
        bond_cashflow_updated_at: string,
        bond_id: number
    }

    export interface BondBenefits {
        bond_benefits_title: string,
        bond_benefits_icon: string,
        bond_benefits_description: string
    }

    export interface BondHighlights {
        bond_highlights_title: string
    }

    export interface BondUpcomingIssues {
        bond_upcoming_issues_name: string,
        bond_upcoming_issues_subscription_start_date: string,
        bond_upcoming_issues_subscription_end_date: string,
        bond_upcoming_issues_allotment_date: string
    }

    export interface InvestmentCalculator {
        accrued_interest: number,
        face_value: string,
        market_value: string,
        principal_amount: number,
        total_investment: number,
        yield_value: number,
    }

    export interface bondCashflowDetails {
        bond_cashflow_date: string,
        bond_cashflow_amount: number
        bond_cashflow_days: string,
        bond_cashflow_type: number

    }

    export interface BondBrandTags {
        brand_tag_id: number,
        brand_tag_name: string,
        bond_brand_tag_available: number,
        bond_highlights_title: string
    }

    export interface BondEligibleInvestors {
        eligible_investor_id: number,
        eligible_investor_name: string,
        eligible_investors_available: number
    }

    export interface SGBDetail {
        bond_issuer_name: string,
        bond_name: string,
        bond_nature_of_instrument: number,
        bond_security_type: number
        bond_st: number,
        bond_security_code: string,
        bond_security_type_code: string,
        bond_govt_guranatee: number,
        bond_isin_number: string,
        bond_face_value: number,
        bond_issue_price: number,
        bonds_price_per_gram: number,
        bond_purchase_limit: number,
        bond_purchase_limit_metric: number,
        bond_coupon_rate: string,
        bonds_yeild: number,
        bond_interest_type: string,
        bond_interest_frequency: string,
        bond_allotment_date: string,
        bond_opening_date: string,
        bond_closing_date: string,
        bonds_next_interest_payment_date: string,
        bond_maturity_date: string,
        bond_dmat_book_closer_date: string,
        exit_option_available: number,
        bond_calc_int_on_holidays: number,
        bond_int_on_maturity: number,
        bond_exchange: string,
        bond_listing: number,
        bonds_banner_rcb_notice_link: number,
        bond_logo: string,
        bond_about_this_ipo: string,
        bond_category_institutional: number,
        bond_category_non_institutional: number,
        bond_category_hni: number,
        bond_category_retail: number,
        bond_coupon_date: string,
        bond_coupon_amount: number,
        bonds_banner_content_title: string,
        bonds_banner_content_sub_title: string,
        bonds_banner_content_image: string,
        bond_issue_document: string,
        bond_disclaimer: string,
        bond_term_condition_link: string,
        bond_benefits: BondBenefits[],
        bond_highlights: BondHighlights[],
        bond_upcoming_issues_status: number,
        bond_upcoming_issues: BondUpcomingIssues[],
        bond_cashflow_details: bondCashflowDetails[],
        bond_brand_tags: BondBrandTags[],
        bond_eligible_investors: BondEligibleInvestors[],
        bond_id: number
        bond_coupon_on: string,
        bond_call_details: BondCallDetails[],
        bond_put_details: BondPutDetails[],
        bond_minimum_application: string,
    }

    export interface SGBOrderPayments {
        bond_id: number,
        payment_mode_id: number,
        sgb_order_id: number,
        sgb_order_payment_amount: string,
        sgb_order_payment_created_at: string,
        sgb_order_payment_datetime: string,
        sgb_order_payment_id: number,
        sgb_order_payment_ref: string,
        sgb_order_payment_status: number,
        sgb_order_payment_updated_at: string
    }

    export interface SGBOrderStatus {
        sgb_order_id: number,
        sgb_order_status: number,
        sgb_order_status_created_at: string,
        sgb_order_status_id: number
    }

    export interface SGBOrderDetail {
        bond_id: number,
        bond_isin_number: string,
        order_id: number,
        order_type: number,
        sgb_order_amount: string,
        sgb_order_created_at: string,
        sgb_order_current_payment_id: number,
        sgb_order_current_status_id: number,
        sgb_order_customer_id: number,
        sgb_order_datetime: string,
        sgb_order_demant_account_id: number,
        sgb_order_demant_account_type: number,
        sgb_order_id: number,
        sgb_order_payments: number,
        sgb_order_quantity: number,
        sgb_order_status: number,
        sgb_order_updated_at: string,
        sgb_order_total_amount:string,
        order_number: string,

    }

    export interface BondBrokerageDetail {
        bond_breakage_series_number: string,
        bond_brokerage_category_id: number,
        bond_brokerage_detail_created_at: string,
        bond_brokerage_detail_id: number,
        bond_brokerage_detail_updated_at: string,
        bond_brokerage_value: string,
        bond_id: number,
        bond_ncd_id: number
    }

    export interface BondCallDetails {
        bond_call_detail_amount: string,
        bond_call_detail_created_at: string,
        bond_call_detail_datetime: string,
        bond_call_detail_id: number,
        bond_call_detail_updated_at: string,
        bond_id: number
    }

    export interface issuerDetails {
        bond_id: number,
        bond_issuer_about: string,
        bond_issuer_coupon_type: number,
        bond_issuer_created_at: string,
        bond_issuer_documents: string,
        bond_issuer_effective_yield: string,
        bond_issuer_finance_details: string,
        bond_issuer_first_ip_date: string,
        bond_issuer_frequency_of_interest: number,
        bond_issuer_head_office: string,
        bond_issuer_id: number,
        bond_issuer_industry: string,
        bond_issuer_ip_dates: string,
        bond_issuer_isin_number: string,
        bond_issuer_profile_link: string,
        bond_issuer_series: string,
        bond_issuer_tenor: number,
        bond_issuer_total_annual_revenue: string,
        bond_issuer_type: number,
        bond_issuer_updated_at: string,
        bond_issuer_year_of_inception: number,
        bond_type_of_issuer: number
    }

    export interface BondNcd {
        bond_id: number,
        bond_ncd_coupon: number,
        bond_ncd_created_at: string,
        bond_ncd_effective_yield: number,
        bond_ncd_frequency_of_interest_payment: number,
        bond_ncd_id: number,
        bond_ncd_in_multiple_terms_of_thereafter: number,
        bond_ncd_issue_price_of_ncd: number,
        bond_ncd_minimum_application: number,
        bond_ncd_mode_of_interest_payment: number,
        bond_ncd_put_and_option: number,
        bond_ncd_redemption_amount: string,
        bond_ncd_tenor: number
    }

    export interface BondNcdSeries {
        bond_id: number,
        bond_ncd_coupon: string,
        bond_ncd_effective_yield: string,
        bond_ncd_frequency_of_interest_payment: number,
        bond_ncd_in_multiple_terms_of_thereafter: number,
        bond_ncd_issue_price_of_ncd: string,
        bond_ncd_minimum_application: number,
        bond_ncd_mode_of_interest_payment: string,
        bond_ncd_put_and_option: string,
        bond_ncd_redemption_amount: string,
        bond_ncd_series_created_at: string,
        bond_ncd_series_id: number,
        bond_ncd_series_number: number,
        bond_ncd_tenor: number

    }

    export interface BondPutDetails {
        bond_id: number,
        bond_put_detail_amount: string,
        bond_put_detail_created_at: string,
        bond_put_detail_datetime: string,
        bond_put_detail_id: number,
        bond_put_detail_updated_at: string
    }

    export interface BondRatingDetails {
        bond_rating: number,
        bond_rating_agency: string,
        bond_rating_certificate: string,
        bond_rating_image: string,
        bond_rating_name:string,
    }

    export interface IPODetail {
        bond_about_this_ipo: string,
        bond_allotment_date: string,
        bond_app_no_series: string,
        bond_arranger_name: number,
        bond_asba_app_no_series_1: string,
        bond_asba_app_no_series_2: string,
        bond_asba_details: string,
        bond_brand_tags: BondBrandTags[],
        bond_brokerage_details: BondBrokerageDetail[],
        bond_brokerage_details_status: number,
        bond_brokerage_structure: string,
        bond_calc_int_on_holidays: number,
        bond_call_details: BondCallDetails[],
        bond_category_hni: string,
        bond_category_institutional: string,
        bond_category_instructions: string,
        bond_category_non_institutional: string,
        bond_category_retail: string,
        bond_closer_date: string,
        bond_closing_date: string,
        bond_coupon_amount: string,
        bond_coupon_date: string,
        bond_coupon_on: string,
        bond_coupon_rate: string,
        bond_created_at: string,
        bond_depository: string,
        bond_disclaimer: string,
        bond_dmat_book_closer_date: string,
        bond_e_form_incentive: string,
        bond_early_bird_incentive: string,
        bond_effort_basis: number,
        bond_eligible_investors: BondEligibleInvestors[],
        bond_exchange: string,
        bond_face_value: string,
        bond_final_issue_amount: string,
        bond_govt_guranatee: string,
        bond_green_shoe: number,
        bond_green_shoe_size: string,
        bond_gst: number,
        bond_guaranted_by: string,
        bond_id: number,
        bond_int_on_app_money: string,
        bond_int_on_maturity: number,
        bond_int_on_refund_money: string,
        bond_interest_days: string,
        bond_interest_frequency: string,
        bond_interest_type: string,
        bond_ip_date: string,
        bond_ipo_sell_windows_days: number,
        bond_isin_number: string,
        bond_issue_date: string,
        bond_issue_document: string,
        bond_issue_price: string,
        bond_issue_size: string,
        bond_issue_status: number,
        bond_issuer_details: issuerDetails[],
        bond_issuer_name: string,
        bond_key_highlights: string,
        bond_listing: number,
        bond_listing_circle: string,
        bond_logo: string,
        bond_maturity_amount: string,
        bond_maturity_date: string,
        bond_minimum_application: string,
        bond_name: string,
        bond_nature_of_instrument: number,
        bond_ncd_available: BondNcd[],
        bond_ncd_series: BondNcdSeries[],
        bond_ncd_status: number,
        bond_opening_date: string,
        bond_other_incentive: string,
        bond_our_status: number,
        bond_price_per_bond: string,
        bond_procurement_amount: string,
        bond_product_note: string,
        bond_purchase_limit: string,
        bond_purchase_limit_metric: string,
        bond_put_details: BondPutDetails[],
        bond_rating_details: BondRatingDetails[],
        bond_rbi_loan_code: string,
        bond_registrar: string,
        bond_script_id: string,
        bond_security_code: string,
        bond_security_type: number,
        bond_security_type_code: string,
        bond_series_instructions: string,
        bond_st: string,
        bond_sub_category_code: string,
        bond_term_condition_link: string,
        bond_trustee: string,
        bond_type: number,
        bond_updated_at: string,
        bond_upi_app_no_series_1: string,
        bond_upi_app_no_series_2: string,
        bonds_banner_content_image: string,
        bonds_banner_content_sub_title: string,
        bonds_banner_content_title: string,
        bonds_banner_rcb_notice_link: string,
        bonds_next_interest_payment_date: string,
        bonds_price_per_gram: string,
        bonds_yeild: string,
        exit_option_available: string,
    }

    export interface DocumentsCompleted {
        aadhar_card: string,
        address_proof: string,
        pan_card: string,
        user_id: number
    }

    export interface IPOOrderPayments {
        bond_id: number,
        ipo_order_id: number,
        ipo_order_payment_amount: string,
        ipo_order_payment_created_at: string,
        ipo_order_payment_datetime: string,
        ipo_order_payment_id: number,
        ipo_order_payment_ref: string,
        ipo_order_payment_status: number,
        ipo_order_payment_updated_at: string,
        payment_mode_id: number
    }

    export interface IPOorderStatus {
        ipo_order_id: number,
        ipo_order_status: number,
        ipo_order_status_created_at: string,
        ipo_order_status_id: number
    }

    export interface IPOorderNCDSeries {
        ipo_order_id: number,
        ipo_order_ncd_series_amount: string,
        ipo_order_ncd_series_id: number,
        ipo_order_ncd_series_number: number,
        ipo_order_ncd_series_quantity: number,
        ipo_order_ncd_series_total_amount: string,
        bond_id: number,
        bond_ncd_coupon: string,
        bond_ncd_effective_yield: string,
        bond_ncd_frequency_of_interest_payment: number,
        bond_ncd_in_multiple_terms_of_thereafter: number,
        bond_ncd_issue_price_of_ncd: string,
        bond_ncd_minimum_application: number,
        bond_ncd_mode_of_interest_payment: string,
        bond_ncd_put_and_option: string,
        bond_ncd_redemption_amount: string,
        bond_ncd_series_created_at: string,
        bond_ncd_series_id: number,
        bond_ncd_series_number: number,
        bond_ncd_tenor: number
    }

    export interface IPOOrderDetail {
        rfq_order_number: number,
        rfq_deal_datetime: number,
        rfq_qoute_status: number,
        bse_rfq_order_number: number,
        bse_rfq_quote_status: number,
        bse_rfq_deal_datetime: string,
        bond_id: number,
        bond_name: string,
        bond_isin_number: string,
        bond_serial_number_id: number,
        ipo_ncd_series: IPOorderNCDSeries[],
        ipo_order_amount: string,
        ipo_order_created_at: string,
        ipo_order_current_payment_id: number,
        ipo_order_current_payment_status: number,
        ipo_order_current_status: number,
        ipo_order_current_status_id: number,
        ipo_order_customer_id: number,
        ipo_order_datetime: string,
        ipo_order_demant_account_id: number,
        ipo_order_demant_account_type: number,
        ipo_order_form_number: number,
        ipo_order_id: number,
        ipo_order_investor_category_id: number,
        ipo_order_pdf: string,
        ipo_order_quantity: number,
        ipo_order_second_appl_name: string,
        ipo_order_second_pan: string,
        ipo_order_sub_category_code: string,
        ipo_order_third_appl_name: string,
        ipo_order_third_pan: string,
        ipo_order_total_amount: string,
        ipo_order_updated_at: string,
        ipo_order_payment_ref_city_id: number,
        ipo_order_payment_upi: number,
        ipo_order_payment_ref_bank_id: number,
        payment_mode_id: number,
        order_id: number,
        order_type: number,
        previous_orders: [],
        bond_logo: string,
        ipo_order_investor_type_id: number
        bse_rfq_isin_number: string,
    }


    export interface Orderslist {
        count: number,
        total_count: order[]

    }


    export interface order {
        bond_id: number,
        bond_isin_number: string,
        bond_logo: string,
        bond_name: string,
        order_amount: string,
        order_datetime: string,
        order_id: number,
        order_order_id: number,
        order_pdf: string,
        order_quantity: number,
        order_status: number,
        order_type: number,
        bond_type: number
        order_number: number,
        order_payment_status: number,
        bond_price: number,
        bond_yeild: number,
        is_modifiable: number,
        is_cancellable: number,
        bse_rfq_id: number,
        buy_sell_id: number,
        listing_name: any,
        
    }

    export interface NCDSeriesOrder {
        bond_ncd_series_id: number
    }

    export interface ASBADetails {
        asba_bank_code: string,
        asba_bank_location_created_at: string,
        asba_bank_location_fullname: string,
        asba_bank_location_id: number,
        asba_bank_location_shortname: string,
        asba_bank_location_status: number,
        asba_bank_location_updated_at: string,
        asba_bank_name: string,
        bond_id: number
    }

    export interface PaymentModes {
        payment_mode_created_at: string,
        payment_mode_id: number,
        payment_mode_name: string,
        payment_mode_status: number
    }
    export interface Bond {
        buy_sell_created_at: string,
        buy_sell_id: number,
        buy_sell_name: string,
        buy_sell_status: number
        buy_sell_updated_at: number
    }

    export interface FilterBondRatings {
        bond_rating_list_id: number,
        bond_rating_name: string
    }

    export interface FilterBrandTags {
        brand_tag_id: number,
        brand_tag_name: string
    }

    export interface FilterPaymentFrequency {
        payment_frequency_type: number,
    }

    export interface BondFilters {
        bond_ratings: FilterBondRatings[],
        brand_tags: FilterBrandTags[],
        payment_frequency: FilterPaymentFrequency[]
    }

    export interface Filters {
        brand_tag_id: string[],
        bond_rating_list_id: string[],
        payment_frequency: string[],
        tenure_from: number,
        tenure_to: number,
        yield_from: number,
        yield_to: number,
        search_text: string,
    }

    export interface OrderFilters {
        bond_type: number,
        order_from_date: string,
        order_to_date: string,
    }

    export interface IPONCDOrderSeires {
        bond_ncd_series_id: number,
        ipo_order_ncd_series_quantity: number,
        ipo_order_ncd_series_amount: string,
        ipo_order_ncd_series_total_amount: string
    }

    export interface InvestorCategory {
        investor_category_id: number,
        investor_category_name: string,
        investor_category_sub_code: string
    }

    export interface InvestorTypes {
        investor_type_id: number,
        investor_type_name: string
    }
}