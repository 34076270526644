import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import { API, Endpoints, PageLinks } from "./Constants";
import { APIData } from "./DataTypes";
import { getAuthString } from "./Utilities";

export namespace ServerAPI {

    export enum APIMethod {
        GET = "GET",
        POST = "POST",
        PUT = "PUT",
        DELETE = "DELETE"
    }

    export const Register = async (values: any) => {
        let data: { [k: string]: any } = {};
        data["mobile_number"] = values['mobile_number'];
        data["fname"] = values['firstName'];
        data["lname"] = values['lastName'];
        data["resend_otp"] = false;
        data["share_details_with_partner"] = values['share_details_with_partner'];
        data["contact_me_for_details"] = values['contact_me_for_details'];
        data["terms_and_conditions"] = values['terms_and_conditions'];
        return executeAPI(API.EndPoint.register, APIMethod.POST, false, data);
    }
    export const Calculater = async (bondID:any,  Yield: number, qty: number, price: number, bonds: number) => {
        let data: { [k: string]: any } = {};
        data["bond_id"] = bondID;
        data["quantity"] = qty
        data["price"] = price;
        data["yield"] = Yield;
        data["settlement_date_id"] = 1;
        data["buy_sell_id"] = bonds;
        return executeAPI(API.EndPoint.Calculater, APIMethod.POST, true, data);
    }
    export const price_calculator = async (isin: number, settlementID: number, YieldID: number, price: number, YieldVal: number,aidccID: number) => {
        let data: { [k: string]: any } = {};
        data["isin_number"] = isin;
        data["settlement_date"] = settlementID;
        data["yield_type"] = YieldID;
         data["price_value"] = price;
         data["yield_value"] = YieldVal;
         data["ai_dcc"] = aidccID;
        return executeAPI(API.EndPoint.yield_price_calculator, APIMethod.POST, true, data);
    }
    export const Login = async (values: any) => {
        let data: { [k: string]: any } = {};
        data["mobile_number"] = values;
        data["resend_otp"] = false;
        return executeAPI(API.EndPoint.login, APIMethod.POST, false, data);

    }
    export const Login_resend_otp = async () => {
        let data: { [k: string]: any } = {};
        data["mobile_number"] = localStorage.getItem('mobile');
        data["resend_otp"] = true;
        return executeAPI(API.EndPoint.login_resend_otp, APIMethod.POST, false, data);

    }
    export const resend_otp = async () => {
        let data: { [k: string]: any } = {};
        data["mobile_number"] = localStorage.getItem('mobile');
        data["fname"] = localStorage.getItem('customerFirstName');
        data["lname"] = localStorage.getItem('customerLastName');
        data["resend_otp"] = true;
        data["share_details_with_partner"] = 1;
        data["contact_me_for_details"] = 1
        data["terms_and_conditions"] = 1;
        return executeAPI(API.EndPoint.resend_otp, APIMethod.POST, false, data);
    }

    export const verifyOtp = async (otp: any) => {
        let data: { [k: string]: any } = {};
        data["otp"] = otp;
        data["mobile_number"] = localStorage.getItem('mobile');
        data["hash_key"] = localStorage.getItem('hashkey');
        return executeAPI(API.EndPoint.verify_otp, APIMethod.POST, false, data);

    }

    export const verify_Login_Otp = async (otp: any) => {
        let data: { [k: string]: any } = {};
        data["otp"] = otp;
        data["mobile_number"] = localStorage.getItem('mobile');
        data["hash_key"] = localStorage.getItem('hashkey');
        return executeAPI(API.EndPoint.login_otp_verify, APIMethod.POST, false, data);

    }

    export const Titles = async () => {
        return executeAPI(API.EndPoint.name_title, APIMethod.GET, true);
      };
    
    export const userDetailData = async () => {
        return executeAPI(API.EndPoint.userDetails, APIMethod.GET, true)
    }
    export const bankDetailData = async (ifsc_code: string, account_number: string, account_type_1: string, account_type_2: string, cheque_image: string) => {
        let data: { [k: string]: any } = {};
        data["ifsc_code"] = ifsc_code;
        data["account_number"] = account_number;
        data["account_type_1"] = parseInt(account_type_1);
        data["account_type_2"] = parseInt(account_type_2);
        data["cancel_cheque_image"] = cheque_image;

        return executeAPI(API.EndPoint.bank_details, APIMethod.POST, true, data)
    }

    export const EmailSendOtp = async (values: any, email_id: any) => {
        let data: { [k: string]: any } = {};
        data["mobile_number"] = values['mobile_number'];
        data["email_id"] = email_id;
        data["resend_otp"] = false;
        return executeAPI(API.EndPoint.email_send_otp, APIMethod.POST, true, data);
    }
    export const ResendEmailOtp = async (values: any, email_id: string) => {
        let data: { [k: string]: any } = {};
        data["mobile_number"] = values['mobile_number'];
        data["email_id"] = email_id;
        data["resend_otp"] = true;
        return executeAPI(API.EndPoint.email_resend_otp, APIMethod.POST, true, data);
    }

    export const EmailVerify = async (values: any, otp: any) => {
        let data: { [k: string]: any } = {};
        data["email_id"] = values['email_id'];
        data["otp"] = otp;
        // data["resend_otp"] = true;
        return executeAPI(API.EndPoint.email_otp_verify, APIMethod.POST, true, data);

    }
    export const EmailVerifyNew = async (email: string, otp: any, values: any) => {
        let data: { [k: string]: any } = {};
        data["email_id"] = email;
        data["otp"] = otp;
        // data["resend_otp"] = true;
        return executeAPI(API.EndPoint.email_otp_verify, APIMethod.POST, true, data);
      };

    export const OauthEmailVerify = async (email_id: any) => {
        let data: { [k: string]: any } = {};
        data["email_id"] = email_id;
        data["is_verified"] = true;
        // data["resend_otp"] = true;
        return executeAPI(API.EndPoint.oauth_email_verify, APIMethod.POST, true, data);

    }

    export const RazorPaybankDetailData = async (ifsc_code: string, account_number: string, account_type_1: string, account_type_2: string) => {
        let data: { [k: string]: any } = {};
        data["ifsc_code"] = ifsc_code;
        data["account_number"] = account_number;
        data["account_type_1"] = parseInt(account_type_1);
        data["account_type_2"] = parseInt(account_type_2);
        data["is_primary"] = 1;
        return executeAPI(API.EndPoint.razorpay_bank_details, APIMethod.POST, true, data, null, false, true)
    }
    export const exisitingDematAccount = async (values: APIData.ExistingDematAccount) => {
        let data: { [k: string]: any } = {};
        data["auth_fund_securities"] = values.auth_fund_securities;
        data["auth_receive_info_stock_broker"] = values.auth_receive_info_stock_broker;
        data["auth_transfer_fund_securities"] = values.auth_transfer_fund_securities;
        data["auth_accept_collateral_segments"] = values.auth_accept_collateral_segments;
        data["client_id"] = values.client_id;
        data["dp_id"] = values.dp_id;
        data["depository_id"] = Number(values.depository_id);
        data["brokerage_scheme_id"] = Number(values.brokerage_scheme_id);
        data["various_segment"] = values.various_segment;
        return executeAPI(API.EndPoint.exisiting_demat_account, APIMethod.POST, true, data)
    }
    // export const exisitingDematAccount = async (depository: number, dp_id: string, dp_name: string) => {
    //     let data: { [k: string]: any } = {};
    //     data["depository"] = depository;
    //     data["dp_id"] = dp_id;
    //     data["dp_name"] = dp_name;
    //     return executeAPI(API.EndPoint.exisiting_demat_account, APIMethod.POST, true, data)
    // }
    export const Verify_code = async () => {
        return executeAPI(API.EndPoint.verification_code, APIMethod.GET, true)
    }

    export const AuthenticateAadhar = async (platform: any) => {
        let params: { [k: string]: any } = {};
        params["platform"] = platform;
        // return executeAPI(API.EndPoint.select_city,APIMethod.GET, true,null,params)
        return executeAPI(API.EndPoint.authenticate_aadhar, APIMethod.GET, true, null, params)
    }

    export const AadharDetails = async () => {
        return executeAPI(API.EndPoint.aadhar_details, APIMethod.GET, true)
    }

    export const AddressProof = async () => {
        return executeAPI(API.EndPoint.address_proof, APIMethod.GET, true)
    }

    export const City = async (state_id: number) => {
        let params: { [k: string]: any } = {};
        params["state_id"] = Number(state_id);
        return executeAPI(API.EndPoint.select_city, APIMethod.GET, true, null, params)
    }

    export const State = async () => {
        return executeAPI(API.EndPoint.select_state, APIMethod.GET, true)
    }

    export const BankDetails = async () => {
        return executeAPI(API.EndPoint.bank_details, APIMethod.GET, true)
    }

    export const ExisitingDematAccountList = async () => {
        return executeAPI(API.EndPoint.all_demat_account, APIMethod.GET, true)
    }

    export const NewDematAccountList = async () => {
        return executeAPI(API.EndPoint.all_demat_account, APIMethod.GET, true)
    }

    export const upload_vidio = async (file: any) => {
        let data: { [k: string]: any } = {};
        data["video"] = file;
        const config: any = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': localStorage.getItem('token') } };
        let fd = new FormData();
        fd.append('video', file);

        try {
            const response = await axios.post(API.BaseUrl + API.EndPoint.upload_video, fd, config);
            return response.data;
        } catch (error: any) {
            return { success: false, message: "Error Occurred in the Request!" };
        }
        // return executeAPI(API.EndPoint.upload_image,APIMethod.POST,true)

    }
   
    export const add_profile_details = async (details: any) => {
        let data: { [k: string]: any } = {};
        data["firstname"] = details.firstname;
        data["lastname"] = details.lastname;
        data["dob"] = moment(details.dob).format("YYYY-MM-DD"); 
        data["smart_card_required"] = details.smart_card_required;
        data["smart_card_number"] = details.smart_card_number;
        data["smart_card_PIN"] = details.smart_card_PIN;
        data["gender"] = details.gender;
        data["married_status"] = details.married_status;
        data["mothers_maiden_name"] = details.mothers_maiden_name;
        data["annual_income"] = details.annual_income;
        data["trading_experience"] = details.trading_experience;
        data["occupation"] = details.occupation;
        data["lifestyle"] = details.lifestyle;
        data["geogriphical_code"] = details.geogriphical_code;
        data["education_degree"] = details.education_degree;
        data["address_line_3"] = details.address_line_3;
        data["address_zip"] = details.address_zip;
        data["address_state_code"] = details.address_state_code;
        data["address_state"] = details.address_state;
        data["address_line_1"] = details.address_line_1;
        data["address_line_2"] = details.address_line_2;
        data["address_city"] = details.address_city;
        data["city_sequence_no"] = details.city_sequence_no;
        data["family_account"] = details.family_account;
        data["mental_disability"] = details.mental_disability;
        // data["is_pan_verified"] = details.is_pan_verified;
        data["profile_image"] = details.profile_image;
        data["verification_video"] = details.verification_video;
        // data["in_person_verification"] = details.in_person_verification;
        data["proof_type"] = details.proof_type;
        data["proof_front_image"] = details.front_image;
        data["proof_back_image"] = details.back_image;
        data["manager_id"] = details.manager_id;
        data["is_politically_exposed"] = details.is_politically_exposed;
        data["would_you_like_to_activate"] = details.would_you_like_to_activate;
        data["filled_itr_last_2years"] = details.filled_itr_last_2years;
        if (details.address_proof_id != 0) {
            data["address_proof_id"] = details.address_proof_id;
        }
        // data["auth_interfychange"] = details.auth_interfychange;
        data["auth_interfychange"] = 1;
        // data["e_confirmation"] = details.e_confirmation;
        data["e_confirmation"] = 1;
        // data["auth_fund_securities"] = details.auth_fund_securities;
        data["auth_fund_securities"] = 1;

        data["auth_fetch_kyc_details"] = details.auth_fetch_kyc_details;
        data["auth_use_aadhar"] = details.auth_use_aadhar;
        data["citizen_usa"] = details.citizen_usa;
        data["itr_records"] = details.itr_records;
        data["tax_resident"] = details.tax_resident;
        data["is_nominee_added"] = details.is_nominee_added;

        return executeAPI(API.EndPoint.userDetails, APIMethod.PUT, true, data);
    }

    export const Depository = async () => {
        return executeAPI(API.EndPoint.depository, APIMethod.GET, true)
    }
    export const Relationship = async () => {
        return executeAPI(API.EndPoint.relationship_with_application, APIMethod.GET, true)
    }
    export const settlements = async () => {
        return executeAPI(API.EndPoint.settlement_date, APIMethod.GET, true)
    }
      export const Yields = async () => {
        return executeAPI(API.EndPoint.Yields, APIMethod.GET, true)
    }
    export const aidcc = async () => {
        return executeAPI(API.EndPoint.aidcc, APIMethod.GET, true)
    }
    export const NomineeProof = async () => {
        return executeAPI(API.EndPoint.nominee_proof_dropdown, APIMethod.GET, true)
    }
    

    export const ProfessionDropDown = async () => {
        return executeAPI(API.EndPoint.profession_dropdown, APIMethod.GET, true)
    }

    export const proofImageUpload = async (proof_type: number, front_image?: any, back_image?: any) => {
        const config = { headers: { "Content-Type": "multipart/form-data", 'Authorization': localStorage.getItem("token")! } };
        let fd = new FormData();
        if (front_image) {
            fd.append("front_image", front_image);

        }
        if (back_image) {
            fd.append("back_image", back_image);

        }
        try {
            const response = await axios.post(API.BaseUrl + API.EndPoint.proof_image_upload + "?address_proof_id=" + proof_type, fd, config);
            return response.data;
        } catch (error: any) {
            return { success: false, message: "Error Occurred in the Request!" };
        }
    };

    export const removeBankAccount = async (ID: number) => {
        let params: { [k: string]: any } = {};
        params["id"] = ID;
        return executeAPI(API.EndPoint.bank_details, APIMethod.DELETE, true, null, params);
    }
    export const removeNewDematAccount = async (ID: number) => {
        let params: { [k: string]: any } = {};
        params["demat_id"] = ID;
        return executeAPI(API.EndPoint.new_demat_account_details, APIMethod.DELETE, true, null, params);
    }

    export const removeExisitingDematAccount = async (ID: number) => {
        let params: { [k: string]: any } = {};
        params["exist_demat_id"] = ID;
        return executeAPI(API.EndPoint.exisiting_demat_account, APIMethod.DELETE, true, null, params);
    }

    export const UploadCancelledCheque = async (file: any) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': localStorage.getItem('token')! } };
        let fd = new FormData();
        fd.append('image', file);

        try {
            const response = await axios.post(API.BaseUrl + API.EndPoint.cheque_cancelled_image, fd, config);
            return response.data;
        } catch (error: any) {
            return { success: false, message: "Error Occurred in the Request!" };
        }
    }

    export const PanVerify = async (pan_no: string) => {
        let params: { [k: string]: any } = {};
        params["pan_no"] = pan_no;
        return executeAPI(API.EndPoint.pan_verify, APIMethod.GET, true, null, params)
    }
    export const IfscCode = async (ifsc: string) => {
        let params: { [k: string]: any } = {};
        params["ifsc_code"] = ifsc;
        return executeAPI(API.EndPoint.ifsc_code, APIMethod.GET, true, null, params)
    }
    export const BrokerageScheme = async () => {
        return executeAPI(API.EndPoint.brokerage_scheme, APIMethod.GET, true);
      };
      export const Segments = async () => {
        return executeAPI(API.EndPoint.various_segment, APIMethod.GET, true);
      };
    
    export const WealthSource = async () => {
        return executeAPI(API.EndPoint.primary_source_of_wealth, APIMethod.GET, true)
    }
    export const BornCity = async () => {
        return executeAPI(API.EndPoint.where_you_born, APIMethod.GET, true)
    }

    export const newDematAdd = async (values: APIData.NewDematAccount ) => {
        let data: { [k: string]: any } = {};
        data["auth_fund_securities"] = values.auth_fund_securities;
        data["auth_receive_info_stock_broker"] = values.auth_receive_info_stock_broker;
        data["auth_transfer_fund_securities"] = values.auth_transfer_fund_securities;
        data["auth_accept_collateral_segments"] = values.auth_accept_collateral_segments;
        data["born_place_id"] = Number(values.born_place);
        data["dp_resident_india"] = values.dp_resident_india;
        data["dp_resident_usa"] = values.dp_resident_usa;
        data["demat_signature_image"] = values.demat_signature_image;
        data["account_statement_in_electronics"] = values.account_statement_in_electronics;
        data["demat_terms_and_conditions"] = values.demat_terms_and_conditions;
        data["primary_source_id"] = Number(values.primary_source);
        return executeAPI(API.EndPoint.new_demat_account_details_post, APIMethod.POST, true, data)
    }
    export const eSign = async (platform: any) => {
        let params: { [k: string]: any } = {};
        params["platform"] = platform;
        return executeAPI(API.EndPoint.esign_pdf, APIMethod.GET, true, null, params)
    }

    export const AddNominee = async (values: APIData.NomineeData[]) => {
        // values.map((data:any)=>{
        //     if(data.nominee_guardian_records && !Array.isArray(data.nominee_guardian_records)){
        //         data.nominee_guardian_records = [data.nominee_guardian_records];
        //     }
        // })
        return executeAPI(API.EndPoint.nominee_details, APIMethod.POST, true, values);
      };

    export const BankStatus = async (id: number, status: number) => {
        let params: { [k: string]: any } = {};
        params["bank_id"] = id;
        params["status"] = status;
        console.log(params)
        return executeAPI(API.EndPoint.bank_details_status, APIMethod.PUT, true, null,params)
    }

    export const pincodeSearch = async (pincode: any) => {
        let params: { [k: string]: any } = {};
        params["pincode"] = pincode;
        return executeAPI(API.EndPoint.pincode, APIMethod.GET, true, null, params)
    }

    export const UploadSignature = async (file: any) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': localStorage.getItem('token')! } };
        let fd = new FormData();
        fd.append('image', file);

        try {
            const response = await axios.post(API.BaseUrl + API.EndPoint.signature_image_upload, fd, config);
            return response.data;
        } catch (error: any) {
            return { success: false, message: "Error Occurred in the Request!" };
        }
    }
    export const NomineeDetails = async () => {
        return executeAPI(API.EndPoint.nominee_details, APIMethod.GET, true, null)
    }

    export const ProgressBarStatusGet = async () => {
        return executeAPI(API.EndPoint.kyc_progressbar_status, APIMethod.GET, true, null)
    }

    export const ProgressBarStatus = async (status: number) => {
        let data: { [k: string]: any } = {};
        data["kyc_progressbar_status_id"] = status;
        return executeAPI(API.EndPoint.kyc_progressbar_status, APIMethod.PUT, true, data)
    }

    export const DematStatus = async (id: number, status: number) => {
        let params: { [k: string]: any } = {};
        params["demat_id"] = id;
        params["status"] = status;
        console.log(params)
        return executeAPI(API.EndPoint.all_dematAccount, APIMethod.PUT, true, null,params)
    }

    export const ExisitingDematStatus = async (id: number, status: number) => {
        let data: { [k: string]: any } = {};
        data["id"] = id;
        data["status_key"] = status;
        return executeAPI(API.EndPoint.existing_demat_status_update, APIMethod.PUT, true, data)
    }

    export const SubscribeBonds = async (email: string) => {
        let data: { [k: string]: any } = {};
        data["bond_subcriber_email"] = email;
        return executeAPI(API.EndPoint.subscribe_bond, APIMethod.POST, true, data)
    }

    export const BondsList = async (pageno: number, searchText?: string, filters?: APIData.Filters, bondType?: string, limit?: number,) => {
        let params: { [k: string]: any } = {};
        params["page_number"] = pageno;
        if (bondType == '4') {
            params["bond_type"] = 4;
        } else if (bondType == '1') {
            params["bond_type"] = 1;
        } else if (bondType == '2') {
            params["bond_type"] = 2;
        } else {
            params["bond_type"] = '';
        }
        params['limit'] = limit;
        params['search_text'] = searchText;
        if (filters != undefined) {
            if (filters.brand_tag_id.length > 0 && filters.brand_tag_id != undefined) {
                params['brand_tag_id'] = filters.brand_tag_id.join(",");
            }
            if (filters.bond_rating_list_id.length > 0 && filters.bond_rating_list_id != undefined) {
                params['bond_rating_list_id'] = filters.bond_rating_list_id.join(",");
            }
            if (filters.payment_frequency.length > 0 && filters.payment_frequency != undefined) {
                params['payment_frequency'] = filters.payment_frequency.join(",");
            }
            if (filters.yield_from != 0 && filters.yield_from != undefined) {
                params['yield_from'] = filters.yield_from;
            }
            if (filters.yield_to != 0 && filters.yield_to != undefined) {
                params['yield_to'] = filters.yield_to;
            }
            if (((filters.tenure_from == 0 && filters.tenure_to != 0) || (filters.tenure_from != 0)) && filters.tenure_from != undefined) {
                params['tenure_from'] = filters.tenure_from;
            }
            if (filters.tenure_to != 0 && filters.tenure_to != undefined) {
                params['tenure_to'] = filters.tenure_to;
            }
        }
        return executeAPI(API.EndPoint.bondsList, APIMethod.GET, true, null, params)
    }
    export const BondsListHome = async (pageno: number, searchText?: string, filters?: APIData.Filters, bondType?: string, limit?: number,) => {
        let params: { [k: string]: any } = {};
        params["page_number"] = pageno;
        if (bondType == '4') {
            params["bond_type"] = 4;
        } else if (bondType == '1') {
            params["bond_type"] = 1;
        } else if (bondType == '2') {
            params["bond_type"] = 2;
        } else {
            params["bond_type"] = '';
        }
        params['limit'] = limit;
        params['search_text'] = searchText;
        if (filters != undefined) {
            if (filters.brand_tag_id.length > 0 && filters.brand_tag_id != undefined) {
                params['brand_tag_id'] = filters.brand_tag_id.join(",");
            }
            if (filters.bond_rating_list_id.length > 0 && filters.bond_rating_list_id != undefined) {
                params['bond_rating_list_id'] = filters.bond_rating_list_id.join(",");
            }
            if (filters.payment_frequency.length > 0 && filters.payment_frequency != undefined) {
                params['payment_frequency'] = filters.payment_frequency.join(",");
            }
            if (filters.yield_from != 0 && filters.yield_from != undefined) {
                params['yield_from'] = filters.yield_from;
            }
            if (filters.yield_to != 0 && filters.yield_to != undefined) {
                params['yield_to'] = filters.yield_to;
            }
            if (((filters.tenure_from == 0 && filters.tenure_to != 0) || (filters.tenure_from != 0)) && filters.tenure_from != undefined) {
                params['tenure_from'] = filters.tenure_from;
            }
            if (filters.tenure_to != 0 && filters.tenure_to != undefined) {
                params['tenure_to'] = filters.tenure_to;
            }
        }
        return executeAPI(API.EndPoint.bondsListHome, APIMethod.GET, false, null, params)
    }
    export const EnquireBondsListe = async (pageno: number, searchText?: string, filters?: APIData.Filters, bondType?: string, limit?: number,) => {
        let params: { [k: string]: any } = {};
        params["page_number"] = pageno;
        // if (bondType == 'SGB') {
        //     params["filter_by"] = 4;
        // } else if (bondType == 'IPO') {
        //     params["filter_by"] = 1;
        // } else if (bondType == 'BONDS') {
        //     params["filter_by"] = 2;
        // } else {
        //     params["filter_by"] = '';
        // }
        params['limit'] = limit;
        params['search_text'] = searchText;
        if (filters != undefined) {
            if (filters.brand_tag_id.length > 0 && filters.brand_tag_id != undefined) {
                params['brand_tag_id'] = filters.brand_tag_id.join(",");
            }
            if (filters.bond_rating_list_id.length > 0 && filters.bond_rating_list_id != undefined) {
                params['bond_rating_list_id'] = filters.bond_rating_list_id.join(",");
            }
            if (filters.payment_frequency.length > 0 && filters.payment_frequency != undefined) {
                params['payment_frequency'] = filters.payment_frequency.join(",");
            }
            if (filters.yield_from != 0 && filters.yield_from != undefined) {
                params['yield_from'] = filters.yield_from;
            }
            if (filters.yield_to != 0 && filters.yield_to != undefined) {
                params['yield_to'] = filters.yield_to;
            }
            if (((filters.tenure_from == 0 && filters.tenure_to != 0) || (filters.tenure_from != 0)) && filters.tenure_from != undefined) {
                params['tenure_from'] = filters.tenure_from;
            }
            if (filters.tenure_to != 0 && filters.tenure_to != undefined) {
                params['tenure_to'] = filters.tenure_to;
            }
        }
        return executeAPI(API.EndPoint.enquire_bond_list, APIMethod.GET, false, null, params)
    }


    export const BondDetail = async (isin: string) => {
        let params: { [k: string]: any } = {};
        params["bond_isin_number"] = isin;
        return executeAPI(API.EndPoint.specific_bonds_list, APIMethod.GET, true, null, params)
    }
    export const Cancle = async (id: string, orderQty: number, actionflag: number) => {
        let data: { [k: string]: any } = {};
        data["order_id"] = id;
        data["quantity"] = orderQty;
        data["action_flag"] = actionflag;
        return executeAPI(API.EndPoint.bond_order_cancle, APIMethod.PUT, true, data);
    }

    export const CancleIPO = async (dematAccountID: number,  accountType: number,IPOQty: number,Action: number, amount: number, bondID: number,OID: string, paymentupi: string, cityID: number,ipo_order_investor_category_id: number, investor_type: number, BankID: number, PaymentMode: number,  ipo_order_second_appl_name: string, ipo_order_second_pan: string, ipo_order_third_appl_name: string, ipo_order_third_pan: string, NCDSeries: APIData.NCDSeriesOrder[]) => {
        let data: { [k: string]: any } = {};
        data["ipo_order_demant_account_id"] = dematAccountID;
        data["ipo_order_demant_account_type"] = accountType;
        data["ipo_order_quantity"] = IPOQty;
        data["action_flag"] = Action;
        data["ipo_order_amount"] = amount;
        data["bond_id"] = bondID;
        data["order_id"] = OID;
        data["ipo_order_payment_upi"] = paymentupi || null;
        data["ipo_order_payment_ref_city_id"] = Number(cityID);
        data["ipo_order_investor_category_id"] = ipo_order_investor_category_id;
        data["ipo_order_investor_type_id"] = investor_type;
        data["ipo_order_payment_ref_bank_id"] = BankID;
        data["payment_mode_id"] = PaymentMode;
        data["ipo_order_second_appl_name"] = ipo_order_second_appl_name;
        data["ipo_order_second_pan"] = ipo_order_second_pan;
        data["ipo_order_third_appl_name"] = ipo_order_third_appl_name;
        data["ipo_order_third_pan"] = ipo_order_third_pan;
        data["bond_ncd_series"] = NCDSeries;

        return executeAPI(API.EndPoint.IPO_order_cancel, APIMethod.PUT, true, data);
    }

    export const SGBCancle = async (id: number, actionflag: number) => {
        let params: { [k: string]: any } = {};
        params["order_id"] = id;
        params["action_flag"] = actionflag;
        return executeAPI(API.EndPoint.SGB_order_cancel, APIMethod.PUT, true, null,params);
    }
   
    export const IPOCancle = async (id: number, actionflag: number) => {
        let data: { [k: string]: any } = {};
        data["order_id"] = id;
        data["action_flag"] = actionflag;
        return executeAPI(API.EndPoint.IPO_order_cancel, APIMethod.PUT, true, data);
    }
   
    export const SGBBondDetail = async (isin: string) => {
        let params: { [k: string]: any } = {};
        params["bond_isin_number"] = isin;
        return executeAPI(API.EndPoint.specific_sgb_details, APIMethod.GET, true, null, params)
    }

    export const BondInvestmentCalculator = async (isin: string, qty: number) => {
        let params: { [k: string]: any } = {};
        params["isin"] = isin;
        params["number_of_bonds"] = qty;
        return executeAPI(API.EndPoint.investment_calculator, APIMethod.GET, true, null, params)
    }

    export const GetNomineeDetails = async (id: number) => {
        let params: { [k: string]: any } = {};
        params["nominee_id"] = id;
        return executeAPI(API.EndPoint.nominee_details_specific, APIMethod.GET, true, null, params)
    }

    export const EsignDownload = async () => {
        return executeAPI(API.EndPoint.e_sign_pdf_download, APIMethod.GET, true, null)
    }

    export const deleteNomineeDetails = async (ID: number) => {
        let params: { [k: string]: any } = {};
        params["nominee_id"] = ID;
        return executeAPI(API.EndPoint.nominee_details, APIMethod.DELETE, true, null, params);
    }

    export const setasPrimaryDemat = async (ID: number) => {
        let params: { [k: string]: any } = {};
        params["demat_id"] = ID;
        return executeAPI(API.EndPoint.set_primary_demat_account, APIMethod.PUT, true, null, params);
    }

    export const setasPrimaryBankAccount = async (ID: number) => {
        let params: { [k: string]: any } = {};
        params["bank_id"] = ID;
        return executeAPI(API.EndPoint.set_primary_bank_account, APIMethod.PUT, true, null, params);
    }

    export const OrderSGBBond = async (dematAccountID: number, accountType: number, quantity: number, amount: number, bondID: number, pricepergram: string) => {
        let data: { [k: string]: any } = {};
        data["sgb_order_demant_account_id"] = dematAccountID;
        data["sgb_order_demant_account_type"] = accountType;
        data["sgb_order_quantity"] = quantity;
        data["sgb_order_amount"] = pricepergram;
        data["sgb_order_total_amount"] = amount;
        data["bond_id"] = bondID;
        console.log(data)
        return executeAPI(API.EndPoint.sgb_orders, APIMethod.POST, true, data);
    }
    export const OrderIPOBond = async (dematAccountID: number, quantity: number, amount: number, bondID: number, accountType: number, paymentupi: string, cityID: number, BankID: number, PaymentMode: number, NCDSeries: APIData.NCDSeriesOrder[], ipo_order_investor_category_id: number, ipo_order_sub_category_code: string, ipo_order_second_appl_name: string, ipo_order_second_pan: string, ipo_order_third_appl_name: string, ipo_order_third_pan: string, investor_type: number) => {
        let data: { [k: string]: any } = {};
        data["ipo_order_demant_account_id"] = dematAccountID;
        data["ipo_order_demant_account_type"] = accountType;
        data["ipo_order_quantity"] = quantity;
        data["ipo_order_amount"] = amount;
        data["bond_id"] = bondID;
        data["ipo_order_payment_upi"] = paymentupi;
        data["ipo_order_payment_ref_city_id"] = Number(cityID);
        data["ipo_order_payment_ref_bank_id"] = BankID;
        data["payment_mode_id"] = PaymentMode;
        data["ipo_order_investor_category_id"] = ipo_order_investor_category_id;
        data["ipo_order_sub_category_code"] = ipo_order_sub_category_code;
        data["bond_ncd_series"] = NCDSeries;
        data["ipo_order_second_appl_name"] = ipo_order_second_appl_name;
        data["ipo_order_second_pan"] = ipo_order_second_pan;
        data["ipo_order_third_appl_name"] = ipo_order_third_appl_name;
        data["ipo_order_third_pan"] = ipo_order_third_pan;
        data["ipo_order_investor_type_id"] = investor_type;
        return executeAPI(API.EndPoint.ipo_orders, APIMethod.POST, true, data);
    }
    export const OrderIPOBond1 = async ( bondID: number,dematID: number,BankID: number, quantity: number, sell_buy: number, Exchange: any) => {
        let data: { [k: string]: any } = {};
        data["bond_id"] = bondID;
        data["buy_sell"] = sell_buy;
        data["bank_id"] = BankID;
        data["demat_id"] = dematID;
        data["quantity"] = quantity;
       data['order_placed_on'] = Exchange
        return executeAPI(API.EndPoint.ipo_orders1, APIMethod.POST, true, data);
    }
    export const GetOrderBond = async (id: string) => {
        let params: { [k: string]: any } = {};
        params["order_id"] = id;
        return executeAPI(API.EndPoint.ipo_orders1, APIMethod.GET, true, null, params);
    }

    export const GetOrderSGBBond = async (id: number) => {
        let params: { [k: string]: any } = {};
        params["order_id"] = id;
        return executeAPI(API.EndPoint.sgb_orders, APIMethod.GET, true, null, params);
    }

    export const GetDocumentsCollected = async () => {
        return executeAPI(API.EndPoint.documents_collected, APIMethod.GET, true);
    }


    export const GetOrderIPOBond = async (id: number) => {
        let params: { [k: string]: any } = {};
        params["order_id"] = id;
        return executeAPI(API.EndPoint.ipo_orders, APIMethod.GET, true, null, params);
    }

    export const UpdateNomineeDetails = async (id: number, values: APIData.NomineeDataUpdate) => {
        let data: { [k: string]: any } = {};
        let params: { [k: string]: any } = {};

        data["nominee_percentage"] = values.nominee_percentage;
        data["nominee_fullname"] = values.nominee_fullname;
        data["name_title_id"] = values.name_title_id;
        data["nominee_guardian_id"] = values.nominee_guardian_id;
        data["nominee_id"] = values.nominee_id;
        data["relationship_with_applicant_id"] = values.relationship_with_applicant_id;
        data["nominee_identification_id"] = values.nominee_identification_id;
        data["nominee_identification_number"] = values.nominee_identification_number;
        data["nominee_mobile_number"] = values.nominee_mobile_number;
        data["nominee_dob"] = values.nominee_dob;
        data["nominee_address_line_1"] = values.nominee_address_line_1;
        data["nominee_address_pincode"] = values.nominee_address_pincode;
        data["nominee_address_line_2"] = values.nominee_address_line_2;
        data["nominee_address_state"] = values.nominee_address_state;
        data["nominee_address_city"] = values.nominee_address_city;
        data["nominee_is_minor"] = values.nominee_is_minor;
        data["nominee_guardian_records"] = values.nominee_guardian_records;
        return executeAPI(API.EndPoint.nominee_details, APIMethod.PUT, true, data, params);
    }

    export const GetIPODetail = async (id: number) => {
        let params: { [k: string]: any } = {};
        params["id"] = id;
        return executeAPI(API.EndPoint.ipo_detail, APIMethod.GET, true, null, params);
    }

    export const Cashflow = async (isin: string) => {
        let params: { [k: string]: any } = {};
        params["isin"] = isin;
        return executeAPI(API.EndPoint.investment_rate, APIMethod.GET, true, null, params)
    }

    export const GetOrders = async (pagenumber: number, filters: APIData.OrderFilters) => {
        let params: { [k: string]: any } = {};
        params["page_number"] = pagenumber;
        params["limit"] = 20;
        if (filters.bond_type != 0) {
            params["bond_type"] = filters.bond_type;
        }
        if (filters.order_from_date != "") {
            params["from_date"] = filters.order_from_date;
        }

        if (filters.order_to_date != "") {
            params["to_date"] = filters.order_to_date;
        }
        return executeAPI(API.EndPoint.orders_list, APIMethod.GET, true, null, params);
    }
    export const ASBALists = async (bondID: number, bankID: number) => {
        let params: { [k: string]: any } = {};
        params["bond_id"] = bondID;
        params["bank_details_id"] = bankID;
        return executeAPI(API.EndPoint.asba_list, APIMethod.GET, true, null, params);
    }

    export const GetSGBPaymentModes = async () => {
        return executeAPI(API.EndPoint.sgb_payment_mode, APIMethod.GET, true);
    }
      export const BuySell = async () => {
        return executeAPI(API.EndPoint.buy_sell, APIMethod.GET, true);
    }
    export const GetBondPaymentModes = async () => {
        return executeAPI(API.EndPoint.bond_payment_mode, APIMethod.GET, true);
    }

    export const GetBondFilter = async () => {
        return executeAPI(API.EndPoint.bond_filters, APIMethod.GET, true);
    }
    export const GetAllDematAccounts = async () => {
        return executeAPI(API.EndPoint.all_demat_account_get, APIMethod.GET, true);
    }

    export const SGBPaymentVerify = async (payment_status: number, sgb_order_payment_id: number, razorpay_order_id: number, razorpay_signature?: string, razorpay_payment_id?: number) => {
        let data: { [k: string]: any } = {};
        data["razorpay_order_id"] = razorpay_order_id;
        data["razorpay_payment_id"] = razorpay_payment_id;
        data["razorpay_signature"] = razorpay_signature;
        data["sgb_order_payment_status"] = payment_status;
        data["sgb_order_payment_id"] = sgb_order_payment_id;
        return executeAPI(API.EndPoint.sgb_payment_verify, APIMethod.PUT, true, data);
    }

    export const OrderBankList = async (bondID: number) => {
        let params: { [k: string]: any } = {};
        params["bond_id"] = bondID;
        return executeAPI(API.EndPoint.bank_list_order, APIMethod.GET, true, null, params);
    }
    export const OrderDematList = async (bondID: number, bankID: number) => {
        return executeAPI(API.EndPoint.demat_list_order, APIMethod.GET, true);
    }

    export const AllOrderDematList = async () => {
        return executeAPI(API.EndPoint.demat_list_order, APIMethod.GET, true);
    }

    export const GetInvestorCategories = async () => {
        return executeAPI(API.EndPoint.investor_categories, APIMethod.GET, true);
    }

    export const GetInvestorTypes = async () => {
        return executeAPI(API.EndPoint.investor_types, APIMethod.GET, true);
    }

    export const RefreshToken = async () => {
        const response = await executeAPI(API.EndPoint.token_refresh, APIMethod.POST, true);
        localStorage.setItem('token', response['token']);
        localStorage.setItem('refresh_token', response['refresh_token']);
        return true;
    }

    export const executeAPI = async (
        endpoint: string,
        method: APIMethod,
        authRequired?: boolean,
        data?: any,
        params?: any,
        suppressError?: boolean,
        returnError?: boolean
    ) => {
        let headers: { [k: string]: any } = {};
        if (endpoint == Endpoints.token_refresh) {
            var refreshToken;
            refreshToken = localStorage.getItem('refresh_token');
            headers["Authorization"] = refreshToken;
        } else {
            if (authRequired) {
                const persistAuthString = await getAuthString();
                if (persistAuthString) {
                    headers["Authorization"] = persistAuthString;
                }
            }
        }
        if (method === APIMethod.POST || method === APIMethod.PUT) {
            headers["Content-Type"] = "application/json";
        }

        try {
            const response = await axios({
                method: method,
                url: API.BaseUrl + endpoint,
                data: JSON.stringify(data),
                headers: headers,
                params: params,
            });
            return response.data;

        } catch (error) {
            if (axios.isAxiosError(error) && error.response && !suppressError && !returnError) {
                
                const errorData: any = error.response.data;
                if(error?.message){
                    if(errorData.message){
                        toast.error(errorData.message);
                    }else if(errorData?.errors){
                       
                        if(endpoint == API.EndPoint.email_send_otp || endpoint == API.EndPoint.pan_verify){
                            return (error.response);
                        }else{
                            toast.error(errorData?.errors);
                        }
                    }
                }
                else if (errorData.errors || errorData.errors.length > 0) {
                    if( error.response.status === 401){
                        localStorage.clear();
                    }
                    if (errorData.errors) {
                        toast.error(errorData.errors);
                    } else {
                        toast.error(errorData.errors);
                    }
                   
                } else {
                    toast.error("Error Occurred in the Request!");
                }

            }
            if (axios.isAxiosError(error) && error.response && returnError) {
                const errorData: any = error.response.data;
                if (errorData.errors && errorData.errors.length > 0) {
                    if (errorData.errors) {
                        return (errorData.errors);
                    }
                } else {
                    
                    toast.error("Error Occurred in the Request!");
                }

            }
        }


    }
}
