import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import InvestSteps from "../components/InvestSteps";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import Footer from "../components/template/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { interestPaymentFrequency, bondTypes, NatureofInstruments, bondPurchaseTypes, PageLinks } from "../common/Constants";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React, { Fragment, useEffect, useRef, useState } from "react";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import ReactPaginate from 'react-paginate';
import { Button, Menu } from "@mui/material";
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Avatar, ListItemDecorator } from "@mui/joy";
import { List } from "@mui/material";
import SortBy from "../components/template/SortBy";
import { numberWithCommas } from "../common/Utilities";
import { ControlledMenu } from "@szhsin/react-menu";
import ShareOptions from "../components/ShareOptions";
import { InitialData } from "../components/Form/InitialData";
import MetaDecorator from "../components/MetaDecorator";
import SocialMediaShare from "../components/SocialMediaShare";
import Listing from "../components/Svg/Listing";
import Download from "../components/Svg/Download";
import MoreInfo from "../components/Modals/MoreInfo";
import Copy from "../components/Svg/Copy";
import copy from 'copy-to-clipboard';
import { toast } from "react-hot-toast";
import Alert from "../components/Modals/Alert";
import AliceCarousel from 'react-alice-carousel';
import moment from "moment";
import SortByRes from "../components/template/SortByRes";

type LocationState = { data: any };

const SearchBond: React.FC = () => {
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [bondsList, setBondsList] = useState<APIData.BondlistData[]>([]);
    const [bondsFilters, setBondsFilters] = useState<APIData.BondFilters>();
    const [pageno, setPageno] = useState(1);
    const [bondtype, setBondType] = useState(0);
    const [All, setAll] = useState(0);
    const [isin, setIsin] = useState('');
    const [filterdata, setFilterdata] = useState<any>();
    const [isinNumber, setisinNumber] = useState('');
    const [moreinfo, setMoreInfo] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [searchText, setSeatchText] = useState('');
    const [alertPopup, setAlertPopup] = useState(false);
    const [show, setShow] = useState(false);
    const [searchBarText, setSeatchBarText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [slidebar, setSlidebar] = useState(false);
    const navigate = useNavigate();
    const socialShare = useRef(null);
    const [Amount, setAmount] = useState('');
    const [socialLinkState, setSocialLinkState] = useState<any>("closed");
    const [selectedFilters, setSelectedFilters] = useState<APIData.Filters>({ ...InitialData.FilterData });
    const sites = ['facebook', 'twitter', 'whatsapp', 'telegram', 'linkedin', 'mail'];
    var databond: any = {};
    const location = useLocation();
    const bondType = '2'

    // const { data } = location.state as LocationState;

    // useEffect(() => {
    //     if (location.state) {
    //         databond = location.state;
    //         setisinNumber(databond?.data?.isinNumber)
    //         setFilterdata(databond?.data?.data)
    //     }
    // }, [location.state])


    useEffect(() => {
        console.log(bondtype);
    }, [bondtype])



    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("isinNumber")) {
                const isinNumber = query.get("isinNumber");
                setSeatchText((isinNumber!));
            }

        }
        if (location.search) {
            setSeatchBarText("")
            const query = new URLSearchParams(location.search);
            if (query.has("isin")) {
                const id = query.get("isin");
                setIsin((id!));
            }

        }
        if (location.search) {
            setSeatchBarText("")
            const query = new URLSearchParams(location.search);
            if (query.has("bond_type_ipo")) {
                const IPO = query.get("bond_type_ipo");
                setBondType(parseInt(IPO!));
            }

        }
        if (location.search) {
            setSeatchBarText("")
            const query = new URLSearchParams(location.search);
            if (query.has("bond_type_bond")) {
                const ADDEDBOND = query.get("bond_type_bond");
                setBondType(parseInt(ADDEDBOND!));
            }

        }
        if (location.search) {
            setSeatchBarText("")
            const query = new URLSearchParams(location.search);
            if (query.has("bond_All")) {
                const BOND = query.get("bond_All");
                setBondType(parseInt(BOND!));
            }

        }
    }, [location]);

    console.log('set', isin);

    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
    };
    const handleSelectedFilter = (type: string, updateValue: number, checked: boolean) => {
        if (checked === true) {
            if (type === 'brand_tag') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, brand_tag_id: [...selectedFilters.brand_tag_id, updateValue + ""] }))
            } else if (type === 'yield_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_from: updateValue }))
            } else if (type === 'yield_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_to: updateValue }))
            } else if (type === 'tenure_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_from: updateValue }))
            } else if (type === 'tenure_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_to: updateValue }))
            } else if (type === 'rating') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, bond_rating_list_id: [...selFilters.bond_rating_list_id, updateValue + ""] }))
            } else if (type === 'frequency') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, payment_frequency: [...selFilters.payment_frequency, updateValue + ""] }))
            }
        } else {
            if (type === 'brand_tag') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, brand_tag_id: selFilters.brand_tag_id.filter((val: any) => val != updateValue) }));
            } else if (type === 'yield_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_from: 0 }))
            } else if (type === 'yield_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_to: 0 }))
            } else if (type === 'tenure_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_from: 0 }))
            } else if (type === 'tenure_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_to: 0 }))
            } else if (type === 'frequency') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, payment_frequency: selFilters.payment_frequency.filter((val: any) => val != updateValue) }));
            } else if (type === 'rating') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, bond_rating_list_id: selFilters.bond_rating_list_id.filter((val: any) => val != updateValue) }));
            }
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        console.log('clicked');
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // useEffect(() => {
    //     if (isin != '') {
    //         setShowPreloader(true);
    //         ServerAPI.SGBBondDetail(isin).then((response: any) => {
    //             if (response != undefined) {
    //                 setBondsList(response);
    //                 setAmount(response['bonds_price_per_gram'] + "");
    //             }
    //         }).finally(() => {
    //             setShowPreloader(false);
    //         })
    //     }
    // }, [isin])
    const goToItemPurchase = (bond_type: number, id: number, isin: string) => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            if (bond_type == bondTypes.SGB) {
                navigate({
                    pathname: PageLinks.APPLYBONDPURCHASE,
                    search: "?isin=" + isin,
                });
            } else if (bond_type == bondTypes.IPO) {
                navigate({
                    pathname: PageLinks.BOND_IPO_PURCHASE,
                    // pathname: PageLinks.BOND_IPO_FLOW1,
                    search: "?id=" + id,
                });
            } else if (bond_type == bondTypes.ADDEDBOND || bond_type == bondTypes.EXISTINGBOND) {
                navigate({
                    pathname: PageLinks.APPLYBONDNONGOLD,
                    search: "?isin=" + isin,
                });
            }
        } else {
            localStorage.setItem('redirection', 'SGB');
            localStorage.setItem('isin', bondsList[0]?.bond_isin_number!);
            navigate(PageLinks.LOGIN);
        }
    }
    const goToItemDetail = (bond_type: number, id: number, isin: string) => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            if (bond_type == bondTypes.ADDEDBOND || bond_type == bondTypes.EXISTINGBOND) {
                navigate({
                    pathname: PageLinks.DETAIL_VIEW_ONE,
                    search: "?isin=" + isin,
                });
            } else if (bond_type == bondTypes.IPO) {
                navigate({
                    pathname: PageLinks.BOND_IPO_FLOW,
                    // pathname: PageLinks.BOND_IPO_FLOW1,
                    search: "?id=" + id,
                });
            } else if (bond_type == bondTypes.SGB) {
                navigate({
                    pathname: PageLinks.SOVEREIGN_BONDS_LANDING_PAGE,
                    search: "?isin=" + isin,
                });
            }
        } else {
            localStorage.setItem('redirection', 'SGB');
            localStorage.setItem('isin', bondsList[0]?.bond_isin_number!);
            navigate(PageLinks.LOGIN);
        }

    }


    const bondlist = (selectedFilters: APIData.Filters, searchText: any) => {
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        ServerAPI.BondsList(pageno, searchText, selectedFilters, bondType).then((response: any) => {
            if (response != undefined) {
                setFilterdata(response['data']);
                setTotalCount(response['total'])
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const bondlistWithSearch = (searchText: string) => {
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            ServerAPI.BondsList(pageno, searchText, selectedFilters, bondType).then((response: any) => {
                if (response != undefined) {
                    setFilterdata(response['data']);
                    setTotalCount(response['total'])
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        } else {
            ServerAPI.BondsListHome(pageno, searchText, selectedFilters, bondType).then((response: any) => {
                if (response != undefined) {
                    setFilterdata(response['data']);
                    setTotalCount(response['total'])
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }
    let filter = true;
    useEffect(() => {
        if ((searchText !== "" || searchText !== null) && filter == true) {
            filter = false
            localStorage.setItem('animation', 'default');
            setShowPreloader(true);
            if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
                ServerAPI.BondsList(pageno, searchText, selectedFilters, bondType).then((response: any) => {
                    if (response != undefined) {
                        setFilterdata(response['data']);
                        setTotalCount(response['total'])
                    }
                }).finally(() => {
                    setShowPreloader(false);
                })
            } else {
                ServerAPI.BondsListHome(pageno, searchText, selectedFilters, bondType).then((response: any) => {
                    if (response != undefined) {
                        setFilterdata(response['data']);
                        setTotalCount(response['total'])
                    }
                }).finally(() => {
                    setShowPreloader(false);
                })
            }
        }
    }, [searchText])
    const bondFilters = () => {
        setShowPreloader(true);
        ServerAPI.GetBondFilter().then((response: any) => {
            if (response != undefined) {
                setBondsFilters(response);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const bondlistHome = (selectedFilters: APIData.Filters, searchText: any) => {
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        ServerAPI.BondsListHome(pageno, searchText, selectedFilters, bondType).then((response: any) => {
            if (response != undefined) {
                setFilterdata(response['data']);
                setTotalCount(response['total'])
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    useEffect(() => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            // bondlist(selectedFilters);
            bondFilters();
        } else {
            // bondlistHome(selectedFilters);
            bondFilters();
        }
    }, [selectedFilters])

    // useEffect(() => {
    //     if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
    //         bondlist(selectedFilters);

    //     } else {
    //         bondlistHome(selectedFilters);

    //     }
    // }, [pageno])

    // useEffect(() => {
    //     if (searchBarText == '') {
    //         bondlistWithSearch(searchBarText);
    //     }
    // }, [searchBarText])

    const handleClearAll = () => {
        // Clear your filters logic here
        setFiltersApplied(false);
    };
    const filterApply = () => {
        setFiltersApplied(true);
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            bondlist(selectedFilters, searchText);

        } else {
            bondlistHome(selectedFilters, searchText);
        }

    }

    return (
        <div className="main-index-wrapper">
            <Header />

            <div className="body-overlay"></div>
            <main>
                <section className="page-title-area listing-search fix pb-20 pt-150">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-6 breadcums reverse-block">
                                <nav>
                                    <ul>
                                        <li>Home</li>
                                        <li>Bonds</li>
                                        <li className="active">{bondtype == bondTypes.IPO ? "IPO Bonds" : "Explore Bonds"}</li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="col-lg-12">
                                <div className="widget-search-content faq-que-search">
                                    <form className="subscribe-form input-fs-res" action="">
                                        <i className="far fa-search"></i>
                                        <input type="text" placeholder="Bond name, issuer or bond type" className="input-box-bond-slider " onChange={(e: any) => {
                                            setSeatchBarText(e.target.value);
                                        }} defaultValue={searchText == "" ? "" : searchText} />
                                        <button className="trustBlackbtn" onClick={(e: any) => {
                                            e.preventDefault();
                                            bondlistWithSearch(searchText);
                                        }}>Search</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="profile_listing pt-30 pb-110 pt-md-45 pb-md-40 pt-xs-20 pb-xs-40">
                    <div className="container-fluid">
                        <div className="row">
                            {bondsFilters != undefined &&
                                <div className="col-lg-3">
                                    <div className={slidebar === false ? 'filter_modal ' : ' filter_modal show'}>



                                        <div className="modal_content">
                                            <div className="mb-5 pb-1 d-flex justify-content-between">
                                                <p className="mb-0 filter_name text-white">Filter</p>
                                                <a href="javascript:void(0);" className="filter_close" onClick={(e: any) => {
                                                    e.preventDefault();
                                                    setSlidebar(false);
                                                }}><i className="fas fa-times"></i></a>
                                            </div>
                                            <div className="filter_box position-relative">
                                                {bondsFilters.brand_tags != undefined && bondsFilters.brand_tags.length > 0 &&
                                                    <div className="filter_head">
                                                        Investment Purpose
                                                    </div>
                                                }
                                                {filtersApplied ? (
                                                    <button className="clear_btn mx-2" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
                                                            bondlist({ ...InitialData.FilterData }, searchText);

                                                        } else {
                                                            bondlistHome({ ...InitialData.FilterData }, searchText);
                                                        }
                                                        setSelectedFilters({ ...InitialData.FilterData });
                                                        handleClearAll()
                                                    }}>Clear All</button>
                                                ) : (
                                                    <button className="applay_btn mx-2" style={{ color: "#fff" }} onClick={(e: any) => {
                                                        e.preventDefault();
                                                        filterApply();
                                                    }}>Apply Filters</button>
                                                )}
                                                <span className="input-title-filled gender_col" role="group">
                                                    {bondsFilters.brand_tags != undefined && bondsFilters.brand_tags.length > 0 && bondsFilters.brand_tags.map((tags: APIData.FilterBrandTags, index: number) => {
                                                        return (
                                                            <div className={selectedFilters.brand_tag_id.includes(tags.brand_tag_id + "") ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                                                <input type="checkbox" className="d-none"
                                                                    onClick={(e: any) => {
                                                                        handleSelectedFilter('brand_tag', tags.brand_tag_id, e.target.checked);
                                                                    }}
                                                                    name={tags.brand_tag_name} id={tags.brand_tag_id + ""} value={tags.brand_tag_id} />
                                                                <label htmlFor={tags.brand_tag_id + ""} className="gender_btn-label-filter">{tags.brand_tag_name}</label>
                                                            </div>
                                                        )
                                                    })}
                                                </span>
                                            </div>
                                            <div className="filter_box">
                                                {bondsFilters.bond_ratings != undefined && bondsFilters.bond_ratings.length > 0 &&
                                                    <div className="filter_head">
                                                        Safety (Credit Rating)
                                                    </div>
                                                }
                                                <span className="input-title-filled gender_col" role="group">
                                                    {bondsFilters.bond_ratings != undefined && bondsFilters.bond_ratings.length > 0 && bondsFilters.bond_ratings.map((rating: APIData.FilterBondRatings) => {
                                                        return (
                                                            <div className={selectedFilters.bond_rating_list_id.includes(rating.bond_rating_list_id + "") ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                                                <input type="checkbox" onClick={(e: any) => { handleSelectedFilter('rating', rating.bond_rating_list_id, e.target.checked) }} className="d-none" name={rating.bond_rating_name} id={"rating" + rating.bond_rating_list_id + ""} value={rating.bond_rating_list_id} />
                                                                <label htmlFor={"rating" + rating.bond_rating_list_id + ""} className="gender_btn-label-filter">{rating.bond_rating_name}</label>
                                                            </div>
                                                        )

                                                    })}
                                                </span>
                                            </div>
                                            <div className="filter_box">
                                                <div className="filter_head">
                                                    Yield
                                                </div>
                                                <span className="input-title-filled gender_col" role="group">
                                                    <div className={selectedFilters.yield_to == 8 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                                        <input type="checkbox" className="d-none" name="yield_1" id="yield_1" value="1" onClick={(e: any) => { handleSelectedFilter('yield_from', 1, e.target.checked); handleSelectedFilter('yield_to', 8, e.target.checked) }} />
                                                        <label htmlFor="yield_1" className="gender_btn-label-filter">Upto 8%</label>
                                                    </div>
                                                    <div className={selectedFilters.yield_to == 11 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                                        <input type="checkbox" className="d-none" name="yield_2" id="yield_2" value="2" onClick={(e: any) => { handleSelectedFilter('yield_from', 8, e.target.checked); handleSelectedFilter('yield_to', 11, e.target.checked) }} />
                                                        <label htmlFor="yield_2" className="gender_btn-label-filter">8 - 11%</label>
                                                    </div>
                                                    <div className={selectedFilters.yield_to == 100 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                                        <input type="checkbox" className="d-none" name="yield_3" id="yield_3" value="3" onClick={(e: any) => { handleSelectedFilter('yield_from', 11, e.target.checked); handleSelectedFilter('yield_to', 100, e.target.checked) }} />
                                                        <label htmlFor="yield_3" className="gender_btn-label-filter">11% +</label>
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="filter_box">
                                                {bondsFilters.payment_frequency != undefined && bondsFilters.payment_frequency.length > 0 &&
                                                    <div className="filter_head">
                                                        Payment Frequency
                                                    </div>
                                                }
                                                <span className="input-title-filled gender_col" role="group">
                                                    {bondsFilters.payment_frequency != undefined && bondsFilters.payment_frequency.length > 0 && bondsFilters.payment_frequency.map((frequency: APIData.FilterPaymentFrequency) => {
                                                        return (
                                                            <div className={selectedFilters.payment_frequency.includes(frequency.payment_frequency_type + "") ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                                                {frequency.payment_frequency_type !== 0 && <input type="checkbox" className="d-none" name={"frequency" + frequency.payment_frequency_type + ""} id={"frequency" + frequency.payment_frequency_type + ""} value={frequency.payment_frequency_type} onClick={(e: any) => { handleSelectedFilter('frequency', frequency.payment_frequency_type, e.target.checked) }} />}
                                                                {frequency.payment_frequency_type == interestPaymentFrequency.FOR_ANNUAL &&
                                                                    <label htmlFor={"frequency" + frequency.payment_frequency_type + ""} className="gender_btn-label-filter">Annual</label>
                                                                }
                                                                {frequency.payment_frequency_type == interestPaymentFrequency.FOR_MONTHLY &&
                                                                    <label htmlFor={"frequency" + frequency.payment_frequency_type + ""} className="gender_btn-label-filter">Monthly</label>
                                                                }
                                                                {frequency.payment_frequency_type == interestPaymentFrequency.FOR_QUARTERLY &&
                                                                    <label htmlFor={"frequency" + frequency.payment_frequency_type + ""} className="gender_btn-label-filter">Quarterly</label>
                                                                }
                                                                {frequency.payment_frequency_type == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                                    <label htmlFor={"frequency" + frequency.payment_frequency_type + ""} className="gender_btn-label-filter">Semi-Annual</label>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </span>
                                            </div>
                                            <div className="filter_box">
                                                <div className="filter_head">
                                                    Tenure Remaining
                                                </div>
                                                <span className="input-title-filled gender_col" role="group">
                                                    <div className={selectedFilters.tenure_to == 1 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                                        <input type="checkbox" className="d-none" name="tenure_1" id="tenure_1" value="1" onClick={(e: any) => { handleSelectedFilter('tenure_from', 0, e.target.checked); handleSelectedFilter('tenure_to', 1, e.target.checked) }} />
                                                        <label htmlFor="tenure_1" className="gender_btn-label-filter">Less than 1 year</label>
                                                    </div>
                                                    <div className={selectedFilters.tenure_to == 5 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                                        <input type="checkbox" className="d-none" name="tenure_2" id="tenure_2" value="2" onClick={(e: any) => { handleSelectedFilter('tenure_from', 1, e.target.checked); handleSelectedFilter('tenure_to', 5, e.target.checked) }} />
                                                        <label htmlFor="tenure_2" className="gender_btn-label-filter">1 to 5 Years</label>
                                                    </div>
                                                    <div className={selectedFilters.tenure_to == 10 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                                        <input type="checkbox" className="d-none" name="tenure_3" id="tenure_3" value="3" onClick={(e: any) => { handleSelectedFilter('tenure_from', 5, e.target.checked); handleSelectedFilter('tenure_to', 10, e.target.checked) }} />
                                                        <label htmlFor="tenure_3" className="gender_btn-label-filter">5 to 10 Years</label>
                                                    </div>
                                                    <div className={selectedFilters.tenure_to == 100 ? "gender_btn_filter selected" : "gender_btn_filter"}>
                                                        <input type="checkbox" className="d-none" name="tenure_4" id="tenure_4" value="4" onClick={(e: any) => { handleSelectedFilter('tenure_from', 10, e.target.checked); handleSelectedFilter('tenure_to', 100, e.target.checked) }} />
                                                        <label htmlFor="tenure_4" className="gender_btn-label-filter">More than 10 years</label>
                                                    </div>
                                                </span>
                                            </div>
                                            {/* <div className="filter_box_buttons">
                                                <div className="spotlight_readmore_box mt-4">
                                                    <button className="trustclearbtn mx-2" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
                                                            bondlist({ ...InitialData.FilterData }, searchText);

                                                        } else {
                                                            bondlistHome({ ...InitialData.FilterData }, searchText);
                                                        }
                                                        setSelectedFilters({ ...InitialData.FilterData });
                                                    }}>Clear All</button>
                                                    <button className="trustallbtn mx-2" style={{ color: "#fff" }} onClick={(e: any) => {
                                                        e.preventDefault();
                                                        filterApply();
                                                    }}>Apply Filters</button>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>

                                </div>
                            }
                            <div className="col-lg-9">
                                <div className="row align-items-left">
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12 breadcums reverse-none">
                                        <nav>
                                            <ul>
                                                <li>Home</li>
                                                <li>Bonds</li>
                                                <li className="active">{bondtype == bondTypes.IPO ? "IPO Bonds" : "Explore Bonds"}</li>
                                            </ul>
                                        </nav>
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-res-block">
                                        <div className="d-flex w-100 justify-content-between">
                                            <div className="sort_responsive">


                                                <Fragment>
                                                    <button className="did_not" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        setSlidebar(true);
                                                    }}>
                                                        Filter <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 82.325 70.665">
                                                            <g id="Group_3305" data-name="Group 3305" transform="translate(1590.298 -1424.861)">
                                                                <path id="Path_22284" data-name="Path 22284" d="M-1508.005,1666.622h-1.705q-16.43-.01-32.859-.039a1.5,1.5,0,0,0-1.678,1.235,11.1,11.1,0,0,1-10.667,7.763,11.063,11.063,0,0,1-10.416-7.8,1.491,1.491,0,0,0-1.7-1.2c-7.29.039-14.58.032-21.87.037-1.367,0-1.366,0-1.368-1.477,0-1.12.047-2.243-.025-3.358-.06-.925.224-1.237,1.116-1.224,3.239.047,6.48.016,9.72.019,4.192,0,8.382-.008,12.573.034a1.379,1.379,0,0,0,1.547-1.111,11.146,11.146,0,0,1,10.7-7.879,11.315,11.315,0,0,1,10.4,7.894,1.332,1.332,0,0,0,1.539,1.076q16.324-.031,32.647-.012a4.83,4.83,0,0,1,1.365.052,1.071,1.071,0,0,1,.65.731C-1507.977,1663.039-1508.005,1664.716-1508.005,1666.622Zm-46.778,2.906a5.678,5.678,0,0,0,5.578-5.866,5.813,5.813,0,0,0-5.614-6,5.915,5.915,0,0,0-5.624,5.928A5.786,5.786,0,0,0-1554.783,1669.528Z" transform="translate(0 -180.055)" />
                                                                <path id="Path_22285" data-name="Path 22285" d="M-1563.394,1547.438c8.418,0,16.836-.016,25.252.021a1.765,1.765,0,0,0,2.009-1.4,10.868,10.868,0,0,1,10.556-7.558,10.975,10.975,0,0,1,10.4,7.647c.354,1.031.828,1.375,1.824,1.311,1.439-.092,2.887-.025,4.331-.055.722-.014,1.108.2,1.066,1.079-.062,1.3-.06,2.614,0,3.917.039.872-.328,1.1-1.059,1.089-1.585-.03-3.171,0-4.755-.041-.707-.021-1.048.257-1.3.975-1.769,4.956-5.907,8.03-10.658,7.989s-8.743-3.042-10.533-8.007a1.285,1.285,0,0,0-1.429-.979q-25.576.027-51.15.033c-1.389,0-1.389,0-1.389-1.449,0-1.157.057-2.318-.018-3.47-.058-.9.257-1.152,1.072-1.146,4.191.032,8.384.014,12.576.014h13.21Zm37.9-2.924a5.763,5.763,0,0,0-5.767,5.691,5.778,5.778,0,0,0,5.431,6.154,5.792,5.792,0,0,0,5.786-5.805A5.818,5.818,0,0,0-1525.5,1544.514Z" transform="translate(-0.031 -90.232)" />
                                                                <path id="Path_22286" data-name="Path 22286" d="M-1589.995,1439.708v-5.786c.285-.019.585-.056.885-.057,2.854,0,5.706-.028,8.559.014a1.438,1.438,0,0,0,1.62-1.169,11.04,11.04,0,0,1,10.813-7.85,11.083,11.083,0,0,1,10.255,7.728,1.656,1.656,0,0,0,1.872,1.3c15.639-.031,31.277-.008,46.917-.053,1.114,0,1.4.343,1.325,1.441-.081,1.225-.039,2.462-.011,3.693.014.632-.239.882-.807.869-.669-.015-1.338-.02-2.007-.02q-22.93,0-45.86-.019a1.152,1.152,0,0,0-1.3.937,11.4,11.4,0,0,1-10.715,8.08,11.333,11.333,0,0,1-10.527-8.027,1.246,1.246,0,0,0-1.389-1.017c-2.782.04-5.564.038-8.347.039C-1589.125,1439.811-1589.535,1439.746-1589.995,1439.708Zm21.577-8.833a5.787,5.787,0,0,0-5.546,6.037,5.861,5.861,0,0,0,5.6,5.907,5.917,5.917,0,0,0,5.617-6.021A5.821,5.821,0,0,0-1568.418,1430.875Z" transform="translate(-0.235)" />
                                                            </g>
                                                        </svg></button>
                                                    <SortByRes bondType={bondtype} />

                                                    {/* <div className="sort_by">Sort By <i id="simple-popover" onClick={handleClick} className="fal fa-sort-alt"></i></div> */}
                                                </Fragment>

                                            </div>
                                            {
                                                bondtype != bondTypes.IPO ?
                                                    <button onClick={(e: any) => { e.preventDefault(); navigate(PageLinks.DID_NOT); }} className="did-not-btn">
                                                        Did not find the BOND?</button> : ""
                                            }
                                        </div>

                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-web-block">

                                        <div className="sort">

                                            {bondsList != undefined && bondsList.length > 0 &&
                                                <Fragment>

                                                    <SortBy bondType={bondtype} />

                                                    {/* <div className="sort_by">Sort By <i id="simple-popover" onClick={handleClick} className="fal fa-sort-alt"></i></div> */}
                                                </Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {filterdata !== undefined && filterdata !== null && filterdata.length > 0 ?
                                    <div className="col-lg-12 col-md-12" data-wow-delay="0.1s">
                                        {filterdata?.map((filterdata: any) => {
                                            return (
                                                <div className="do-box do-box-2 mb-30">
                                                    <div className="icon-box d-flex">
                                                        <div className="icon-img1 icon-res-img">
                                                            <img src={filterdata.bond_logo ? filterdata.bond_logo : "assets/img/img_placeholder.png"} alt="" />
                                                        </div>
                                                        <div className="icon-head mt-1">

                                                            <div className="col-lg-12 p-0 bond-details1">
                                                                <p className="p1">{filterdata.bond_name}</p>
                                                                <div className="d-flex my-2">
                                                                    <a className="background-green title-background-style ">
                                                                        <p className="fs-15-bond text-white padding-style-fs-12">RETURNS Based On Market</p>
                                                                    </a>
                                                                    {filterdata?.bond_isin_number != '' &&
                                                                        <a className="background-grey title-background-style mx-2">
                                                                            <p className="fs-15-bond padding-style-fs-12"> <span>ISIN:&nbsp;</span>{filterdata?.bond_isin_number}
                                                                                <span className="cursor" onClick={(e: any) => {
                                                                                    e.preventDefault();
                                                                                    copy(filterdata?.bond_isin_number!, {
                                                                                        debug: true,
                                                                                    });
                                                                                    toast.success('ISIN Number Copied To Clipboard');
                                                                                }}>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                    <Copy />
                                                                                </span>
                                                                            </p>
                                                                        </a>}
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <SocialMediaShare url={filterdata.share_link} />
                                                        {/* <ControlledMenu state={socialLinkState} anchorRef={socialShare} onMouseEnter={() => setSocialLinkState("open")} onMouseLeave={() => setSocialLinkState("closed")} onClose={() => setSocialLinkState("closed")} className="pro_details_share" direction="bottom">
                          <ShareOptions baseURL={"test"} id={bond.bond_id} name={bond.bond_name} />
                      </ControlledMenu> */}
                                                    </div>
                                                    <div className="col-lg-12 p-0 d-flex box-reverse main-boxbond">
                                                        {(filterdata?.bond_type == 2 || filterdata?.bond_type == 3) && <div className="col-lg-3 p-0 box-bond-max">
                                                            <div className="spotlight_box_one1">
                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Rate of Int (Returns)</div>
                                                                    <div className="spotlight_box_inner_text2">{filterdata.bond_coupon_rate ? filterdata.bond_coupon_rate + " % p.a" : "N/A"}</div>
                                                                </div>

                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Yield %</div>
                                                                    <div className="spotlight_box_inner_text2">{filterdata.bonds_yeild != null && filterdata.bonds_yeild != '' ? filterdata.bonds_yeild + "%" : "N/A"}</div>
                                                                </div>
                                                            </div>
                                                            <div className="spotlight_box_two2 bg-gray-table">
                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Min. Investment</div>
                                                                    <div className="spotlight_box_inner_text2">{filterdata?.bond_minimum_application ? "₹" + numberWithCommas(filterdata?.bond_minimum_application) : 'N/A'}
                                                                    </div>
                                                                </div>
                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Face Value</div>
                                                                    <div className="spotlight_box_inner_text2">{filterdata.bond_face_value != null && filterdata.bond_face_value != '' ? "₹" + numberWithCommas(filterdata.bond_face_value) : "N/A"}</div>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        {(filterdata?.bond_type == 1 || filterdata?.bond_type == 4) && <div className="col-lg-3 p-0 box-bond-max">
                                                            <div className="spotlight_box_one1">
                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Issue Opens on</div>
                                                                    <div className="spotlight_box_inner_text2">{filterdata.bond_issue_date != null ? moment(filterdata.bond_issue_date).format("DD, MMM YYYY") : "N/A"}</div>
                                                                </div>
                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Issue Closes on</div>
                                                                    <div className="spotlight_box_inner_text2">{filterdata.bond_closing_date != null ? moment(filterdata.bond_closing_date).format("DD, MMM YYYY") : "N/A"}</div>
                                                                </div>
                                                            </div>
                                                            <div className="spotlight_box_two2 bg-gray-table">
                                                                <div className="spotlight_box_inner w-100">
                                                                    <div className="spotlight_box_inner_text1">Rate of Int (Returns)</div>
                                                                    <div className="spotlight_box_inner_text2">{filterdata.bond_coupon_rate ? filterdata.bond_coupon_rate + " % p.a" : "N/A"}
                                                                    </div>
                                                                </div>
                                                                {/* <div className="spotlight_box_inner">
                                                            <div className="spotlight_box_inner_text1">Yield %</div>
                                                            <div className="spotlight_box_inner_text2">{filterdata.bonds_yeild != null && filterdata.bonds_yeild != '' ? filterdata.bonds_yeild + "%" : "N/A"}</div>
                                                        </div> */}
                                                            </div>
                                                        </div>}
                                                        <div className="col-lg-3 mx-2 p-0 box-bond-max box-bond-2 d-box-none">
                                                            <div className="spotlight_box_one1">
                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Interest Payment Freq.</div>
                                                                    <div className="spotlight_box_inner_text2">
                                                                        {parseInt(filterdata.bond_interest_frequency!) == interestPaymentFrequency.FOR_ANNUAL &&
                                                                            'Annual'
                                                                        }
                                                                        {parseInt(filterdata.bond_interest_frequency!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                                            'Monthly'
                                                                        }
                                                                        {parseInt(filterdata.bond_interest_frequency!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                                            'Quarterly'
                                                                        }
                                                                        {parseInt(filterdata.bond_interest_frequency!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                                            'Semi - Annual'
                                                                        }
                                                                        {parseInt(filterdata.bond_interest_frequency!) == 0 &&
                                                                            'N / A'
                                                                        }
                                                                        {filterdata.bond_interest_frequency == null || filterdata.bond_interest_frequency == '' &&
                                                                            ' N / A'
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Maturity Date</div>
                                                                    <div className="spotlight_box_inner_text2">{filterdata.bond_maturity_date != null && filterdata.bond_maturity_date != '' ? moment(filterdata.bond_maturity_date).format("MMM Do YYYY") : "N/A"}</div>
                                                                </div>
                                                            </div>
                                                            <div className="spotlight_box_two2 bg-gray-table">
                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Instrument Type</div>
                                                                    <div className="spotlight_box_inner_text2">
                                                                        {filterdata.nature_of_instrument_name !== null && filterdata.nature_of_instrument_name !== '' ? filterdata.nature_of_instrument_name! : 'N/A'}
                                                                    </div>
                                                                </div>
                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Date of Issue</div>
                                                                    <div className="spotlight_box_inner_text2 ">{filterdata?.bond_issue_date != null && filterdata?.bond_issue_date != '' ? moment(filterdata?.bond_issue_date).format("MMM Do YYYY") : "N/A"}</div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 p-0 box-bond-max box-bond-3 d-box-none">
                                                            <div className="spotlight_box_one1">
                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Call Option</div>
                                                                    {filterdata?.call_option == 'YES' ?
                                                                        <div className="spotlight_box_inner_text2">{filterdata?.call_option}
                                                                            {/* <span className="call-option" id="call" onClick={handleClick} ><img src="assets/img/call-option-info.svg" /></span> */}
                                                                        </div>
                                                                        : <div className="spotlight_box_inner_text2 "> {filterdata?.call_option} </div>
                                                                    }
                                                                </div>
                                                                {/* <Popover
                                                      id="call"
                                                      open={open}
                                                      anchorEl={anchorEl}
                                                      onClose={handleClose}
                                                      anchorOrigin={{
                                                          vertical: 'top',
                                                          horizontal: 'right',
                                                      }}
                                                      transformOrigin={{
                                                          vertical: 'top',
                                                          horizontal: 'left',
                                                      }}
                                                  >
                                                      <Typography sx={{ p: 2 }}>
                                                          <div className="call-option-content">
                                                              <h5>Dates for Call Option</h5>
                                                              <h6>02 Oct 2022, 10.00am to 11.00am</h6>
                                                              <h6>02 Oct 2022, 10.00am to 11.00am</h6>
                                                              <h6 className="selected">02 Oct 2022, 10.00am to 11.00am</h6>
                                                              <div className="center-btn">
                                                                  <button className="alert-button" onClick={(e: any) => {
                                                                      e.preventDefault();
                                                                      setAlertPopup(true);
                                                                      handleClose();
                                                                  }}>Set Alert<span><i className="fa fa-arrow-right" aria-hidden="true"></i></span></button>
                                                              </div>
                                                          </div>
                                                      </Typography>
                                                  </Popover> */}
                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Put Option</div>
                                                                    {filterdata?.put_option == 'YES' ?
                                                                        <div className="spotlight_box_inner_text2">{filterdata?.put_option}
                                                                            {/* <span className="call-option" id="call" onClick={handleClick} ><img src="assets/img/call-option-info.svg" /></span> */}
                                                                        </div>
                                                                        : <div className="spotlight_box_inner_text2">{filterdata?.put_option}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="spotlight_box_two2 bg-gray-table">
                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Mode of Issue</div>
                                                                    <div className="spotlight_box_inner_text2">
                                                                        {filterdata?.mode_of_issuer_name ? filterdata?.mode_of_issuer_name : 'N/A'}</div>
                                                                </div>
                                                                <div className="spotlight_box_inner">
                                                                    <div className="spotlight_box_inner_text1">Listed</div>
                                                                    <div className="spotlight_box_inner_text2">{filterdata?.listing_name ? filterdata?.listing_name : 'N/A'}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 box-img1 box-bond-image p-0 d-flex">
                                                            <p className="pra-1 d-box-none">Bond’s Rating</p>
                                                            {filterdata != undefined && filterdata?.bond_rating_list.length > 0 ?

                                                                <AliceCarousel responsive={responsive}>
                                                                    {filterdata?.bond_rating_list?.map((rating: any) => (

                                                                        <div className="m-auto speed-img-bond ">
                                                                            <img src={rating?.bond_rating_image != null && rating?.bond_rating_image != "https://trust-api.trustmony.com/api/v1/image_download?name=None" && rating?.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=None" ? rating.bond_rating_image : "N/A"} alt="" className="w-100" />
                                                                            <table className="ipo-bond-download-table mt-1 w-100 d-box-none">
                                                                                <tr>
                                                                                    <td className="sourceSansPro-bold p-1 ipo-bond-responsive">{rating?.bond_rating_agency}</td>
                                                                                    <td className="sourceSansPro-bold">{rating.bond_rating_name}</td>
                                                                                    <td className="sourceSansPro-bold cursor p-1 ipo-bond-responsive" onClick={(e: any) => {
                                                                                        e.preventDefault();
                                                                                        window.open(rating.bond_rating_certificate);
                                                                                    }}><Download /></td>
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                    ))}
                                                                </AliceCarousel>
                                                                : "N/A"
                                                            }
                                                            {/* <AliceCarousel responsive={responsive}>
                          <div className="speed-img2 mx-2">
                              <img src={bond.bond_rating_image != null && bond.bond_rating_image != "https://trust-api.trustmony.com/api/v1/image_download?name=None" && bond.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=None" ? bond.bond_rating_image : "../assets/img/no_rating.png"} alt="" className="w-100" />
                              <table className="ipo-bond-download-table mt-1 w-100">
                                  <tr>
                                      <td className="sourceSansPro-bold p-1">aa</td>
                                      <td className="sourceSansPro-bold p-1">bb</td>
                                      <td className="sourceSansPro-bold cursor p-1"><Download /></td>
                                  </tr>
                              </table>
                          </div>
                          <div className="speed-img2">
                              <img src={bond.bond_rating_image != null && bond.bond_rating_image != "https://trust-api.trustmony.com/api/v1/image_download?name=None" && bond.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=None" ? bond.bond_rating_image : "../assets/img/no_rating.png"} alt="" className="w-100" />
                              <table className="ipo-bond-download-table mt-1 w-100">
                                  <tr>
                                      <td className="sourceSansPro-bold p-1">aa</td>
                                      <td className="sourceSansPro-bold p-1">bb</td>
                                      <td className="sourceSansPro-bold cursor p-1"><Download /></td>
                                  </tr>
                              </table>
                          </div>
                      </AliceCarousel> */}
                                                        </div>

                                                    </div>
                                                    <div className="row mt-0">
                                                        <div className="col-lg-12 mb-2">

                                                            <div className="d-flex mb-4 btn-box justify-content-end">
                                                                {filterdata.bond_type == bondTypes.IPO &&
                                                                    <div className="ipo_text">

                                                                        IPO

                                                                    </div>}
                                                                <button onClick={(e: any) => {
                                                                    e.preventDefault();
                                                                    goToItemDetail(filterdata.bond_type, filterdata.bond_type != bondTypes.IPO ? 0 : filterdata.bond_id, filterdata.bond_type == bondTypes.IPO ? '' : filterdata.bond_isin_number)
                                                                }} className="readmore-btn mx-2 bonds-detail-btn btn-1 mx-2 mt-1"><span><svg xmlns="http://www.w3.org/2000/svg" className="mb-1" width="14" height="14" viewBox="0 0 88.716 88.716">
                                                                    <path id="Path_26371" data-name="Path 26371" d="M-710.221,975.631a44.355,44.355,0,0,1-44.357,44.357,44.356,44.356,0,0,1-44.359-44.357,44.365,44.365,0,0,1,44.359-44.359A44.364,44.364,0,0,1-710.221,975.631Zm-34.341,16.455a2.146,2.146,0,0,0-2.146-2.146h-2.146V972.054A2.146,2.146,0,0,0-751,969.907h-11.447a2.146,2.146,0,0,0-2.146,2.146v4.293a2.146,2.146,0,0,0,2.146,2.146h2.146v11.447h-2.146a2.146,2.146,0,0,0-2.146,2.146v4.293a2.146,2.146,0,0,0,2.146,2.146h15.74a2.146,2.146,0,0,0,2.146-2.146ZM-762.09,958.46a7.511,7.511,0,0,0,7.512,7.51,7.511,7.511,0,0,0,7.512-7.51,7.512,7.512,0,0,0-7.512-7.512A7.512,7.512,0,0,0-762.09,958.46Z" transform="translate(798.937 -931.272)" fill="#fff" />
                                                                </svg></span> Read More</button>
                                                                {/* {(bond.bond_type == bondTypes.ADDEDBOND || bond.bond_type == bondTypes.EXISTINGBOND) &&
                                          <button onClick={(e: any) => {
                                              goToItemPurchase(bond.bond_type, bond.bond_type != bondTypes.IPO ? 0 : bond.bond_id, bond.bond_type == bondTypes.IPO ? '' : bond.bond_isin_number);
                                          }} className="sell-btn bonds-detail-btn btn-1 mt-1" style={{ padding: "3px 20px" }}>
                                              Sell this BOND now</button>} */}
                                                                {(filterdata.bond_type !== bondTypes.IPO && filterdata.buy_sell_id == 1) &&
                                                                    <button onClick={(e: any) => {
                                                                        goToItemPurchase(filterdata.bond_type, filterdata.bond_type != bondTypes.IPO ? 0 : filterdata.bond_id, filterdata.bond_type == bondTypes.IPO ? '' : filterdata.bond_isin_number);
                                                                    }} className="bond-btn bonds-detail-btn btn-1 mx-2 mt-1" style={{ padding: "3px 20px" }}>
                                                                        Buy this BOND now</button>}
                                                                {(filterdata.bond_type !== bondTypes.IPO && filterdata.buy_sell_id == 2) &&
                                                                    <button onClick={(e: any) => {
                                                                        goToItemPurchase(filterdata.bond_type, filterdata.bond_type != bondTypes.IPO ? 0 : filterdata.bond_id, filterdata.bond_type == bondTypes.IPO ? '' : filterdata.bond_isin_number);
                                                                    }} className="bond-btn bonds-detail-btn  sell_btn_bond btn-1 mx-2 mt-1" style={{ padding: "3px 20px" }}>
                                                                        Sell this BOND now</button>}
                                                                {filterdata.bond_type == bondTypes.IPO &&
                                                                    <button onClick={(e: any) => {
                                                                        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
                                                                            goToItemPurchase(filterdata.bond_type, filterdata.bond_type != bondTypes.IPO ? 0 : filterdata.bond_id, filterdata.bond_type == bondTypes.IPO ? '' : filterdata.bond_isin_number);
                                                                        } else {
                                                                            navigate(PageLinks.LOGIN);
                                                                        }
                                                                    }} className="bond-btn bonds-detail-btn btn-1 mx-2 mt-1" style={{ padding: "3px 20px" }}>
                                                                        Subscribe This IPO now</button>}
                                                                {/* <button onClick={(e: any) => {
                                  e.preventDefault();
                                  goToItemDetail(bond.bond_type, bond.bond_type != bondTypes.IPO ? 0 : bond.bond_id, bond.bond_type == bondTypes.IPO ? '' : bond.bond_isin_number)
                              }} className="spotlight_readmore bonds-detail-btn btn-1 mx-2 mt-1" style={{ padding: "3px 20px" }}>Buy this BOND now</button> */}
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            );
                                        }

                                        )}

                                    </div>
                                    :
                                    <>


                                        <div className="text-center mt-5">
                                            <img src="assets/img/no_result.png" />
                                            <p className="source sourceSansPro-bold no-result-main">No Results Found</p>
                                            <p className="source sourceSansPro-regular no-result-sub">Try changing the filters or search for a different keyword.</p>
                                        </div>
                                    </>}
                            </div>
                        </div>
                    </div>
                </section>
                <InvestSteps />
                <Subscribe />
                <NeedHelp />
                {/* <NeedHelp /> */}
            </main>
            <Footer />
            <MoreInfo showModal={moreinfo} closeModal={() => { setMoreInfo(false) }} />
            <Alert showModal={alertPopup} closeModal={() => { setAlertPopup(false) }} />
        </div >
    )
}

export default SearchBond;
