import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { kycStatus, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { kycStatusUpdate } from "../common/Utilities";



const KYCSuccess: React.FC = () => {
    const navigate = useNavigate();
    const [documentCollected, setDocumentCollected] = useState<APIData.DocumentsCompleted>();

    useEffect(() => {
        ServerAPI.GetDocumentsCollected().then((response: any) => {
            if (response['message'] != undefined) {
                setDocumentCollected(response['message']);

            }
        })
    }, [])
    return (
        <div className="main-index-wrapper">
            <main>
                {/* Profile-details-edit-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 instruct_logo">
                                            <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                        </div>
                                    </div>
                                    <div className="step_box_content">
                                        <div className="instruction_screen_full">
                                            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12 instruction_screen_full_left">
                                                <div className="instruction_screen_full_left_text">
                                                    Congratulation For Successfully Completing Your KYC Process.
                                                </div>
                                                <div className="mobile_show text-center"><img src="assets/img/icon/instruction.svg" /></div>
                                                <p pt-15>List of Documents Collected</p>
                                                <div className="instruction_bonds_box">
                                                    {documentCollected?.pan_card != null &&
                                                        <div className="collection_bonds_box">
                                                            <div className="collection_bonds_box_left">
                                                                <img src="assets/img/icon/pan.svg" />
                                                            </div>
                                                            <div className="collection_bonds_box_right mt-2">PAN Card</div>
                                                            <img src="assets/img/checkicon.png" className="icon-check" />
                                                        </div>
                                                    }
                                                    {documentCollected?.aadhar_card != null &&
                                                        <div className="collection_bonds_box">
                                                            <div className="collection_bonds_box_left">
                                                                <img src="assets/img/icon/aadhaar.svg" />
                                                            </div>
                                                            <div className="collection_bonds_box_right mt-2">Aadhaar Card</div>
                                                            <img src="assets/img/checkicon.png" className="icon-check" />
                                                        </div>
                                                    }
                                                    {documentCollected?.address_proof != null &&
                                                        <div className="collection_bonds_box">
                                                            <div className="collection_bonds_box_left">
                                                                <img src="assets/img/icon/cancelled_cheque.png" />
                                                            </div>
                                                            <div className="collection_bonds_box_right mt-2">Address Proof</div>
                                                            <img src="assets/img/checkicon.png" className="icon-check" />
                                                        </div>
                                                    }
                                                    {/* <div className="collection_bonds_box">
                                                        <div className="collection_bonds_box_left">
                                                            <img src="assets/img/icon/demat.svg" />
                                                        </div>
                                                        <div className="collection_bonds_box_right mt-2">Signature</div>
                                                    </div> */}
                                                </div>
                                                <div className="instruct_btns">
                                                    <button className="goto_home_btn" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        kycStatusUpdate(kycStatus.APPLICATION_DOWNLOAD);
                                                        ServerAPI.EsignDownload().then((response: any) => {
                                                            window.location.replace(response['message']);
                                                        })
                                                    }}>Download Your E-Signed Application Form</button>
                                                    <button onClick={(e: any) => {
                                                        kycStatusUpdate(kycStatus.KYC_COMPLETE);
                                                        navigate(PageLinks.MY_ACCOUNT_PROFILE);
                                                    }} className="continue_kyc_btn">Continue to Profile Section</button>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 mobile_hide">
                                                <div className="instruction_screen_full_right_text">
                                                    <img src="assets/img/icon/instruction.svg" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="inner_footer">
                    © 2021-2022 Trust Capital Pvt Ltd.
                </div>
            </main>
        </div>
    )
}

export default KYCSuccess;
