import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Footer from "../components/template/Footer";

const PrivacyPolicy: React.FC = () => {

    return (
        <div className="main-index-wrapper">
            <Header />
            
            <div className="body-overlay"></div>
            <main>
            <section className="what_are_advantages pt-110 pb-50 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li className="active">Privacy Policy</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 what_are_advantages_full">
                            <div className="col-lg-12 what_are_advantages_full_left privacy_para_text">
                                <div className="what_are_advantages_full_left_text">
                                    <p>
                                    Trust Securities Services Private Limited and its group companies (we/us/our/Trust Group) is committed to protecting and respecting your privacy of personal information that you provide to us when using our website (www.trustsecurities.in) or any of our services thereat. This Privacy Policy (“Policy”) sets out the basis on which any personal data we collect from you or that you provide to us will be processed by us and the purpose for our collection of it. 
                                    </p>
                                    <p>
                                    We need to collect, use and disclose personal information, in order to perform our business functions and activities, and render various services to you (our customer). We are firmly committed to protecting the privacy and confidentiality of personal information with various physical, electronic and procedural safeguards. 
                                    </p>
                                    <p>
                                    By visiting this website, you are accepting and consenting to the practices described in this Policy. However, if you do not provide us with your personal information, or if you withdraw consent that you have given under this Policy, this may affect our ability to provide services to you or negatively impact the services we can provide to you. If you reached our site for availing products or services, we may become privy to the personal information of users, including information that is of a confidential and sensitive in nature. 
                                    </p>
                                    <p>
                                    While information is the keystone of our ability to deliver superior service, our most essential asset is our clients’ trust. Keeping client information secure and using it only as our client would want us to, is a top priority for all of us at Trust Group.
                                    </p>
                                    <p className="text_light_privacy">
                                        This Policy explains how we may collect and process personal data about: 
                                        <ul className="privacy_policy_list">
                                            <li>Clients and prospective clients;</li>
                                            <li>Visitors to our website and subscribers to our online services; and </li>
                                            <li>Any other user whose personal data we may hold.</li>
                                        </ul>
                                    </p>
                                    <p>Depending on our relationship with you, this Policy may be supplemented with additional information, policies or guidelines relating to our use of your personal data.</p>
                                    <p className="text_bold">Information We Collect</p>
                                    <p className="text_light_privacy">
                                        When you register, and at other times, we may collect personally identifiable information from you that may include but not be limited to your name, address, telephone number, e-mail address, and facts about your computer.
                                    </p>
                                    <p className="text_light_privacy">
                                        Generally, the type of personal information we collect about you is the information that is required to assist provision of services and/or products as per your needs and requirements.
                                    </p>
                                    <p className="text_light_privacy">
                                        Following are the types of personal information being processed by us:
                                        <ul className="privacy_policy_list">
                                            <li>Identification Information: Name, gender, residential / correspondence address, telephone number, date of birth, marital status, email address or other contact information;</li>
                                            <li>PAN, KYC Status, Signature and Photograph;</li>
                                            <li>Bank account or other payment instrument details;</li>
                                            <li>Any other detail for providing services</li>
                                        </ul>
                                    </p>
                                    <p className="text_bold">Information we may collect from your use of our services</p>
                                    <p className="text_light_privacy">
                                        <ul className="privacy_policy_list">
                                            <li>Storage Information: We may facilitate user to download and display information such as scheme commission details which a user may refer to, or to upload relevant documents as per various processes during user account management or transaction order placement. </li>
                                            <li>Media Information: We facilitate users to capture / upload relevant documents as may be required to be uploaded during user account management or transaction order placement</li>
                                            <li>Device Information: We collect specific information about your device when you access our Services, including your storage, hardware model, operating system and version, unique device identifier, mobile network information, location, contacts and information about the device’s interaction with our services</li>
                                        </ul>
                                    </p>
                                    <p className="text_bold">Website Visitorship Data is data about the way a website is used that is not associated with an individual’s identity. We collect this information to analyse how our website is used and to improve it.</p>
                                    <p className="text_light_privacy">
                                        The kind of information we collect includes:
                                        <ul className="privacy_policy_list">
                                            <li>the pages visited on our website</li>
                                            <li>the features used</li>
                                            <li>how long a visitor stays</li>
                                            <li>the domain name of the website from which visitors connect to our site</li>
                                        </ul>
                                    </p>
                                    <p>Trust Group or its authorized agents shall not retain or store the information for longer than is required for the purposes except when the information may lawfully be used or is otherwise required under any other law for the time being in force.</p>
                                    <p>By agreeing to avail the services offered by Trust Group, you have agreed to the collection and use of your sensitive personal data or information by Trust Group. You always have the right to refuse or withdraw your consent to share / dissemination of your sensitive personal data or information by contacting the customer care. However, in such an event, you would no longer be able to avail the services from Trust Group</p>
                                    <p className="text_bold">Link to other websites:</p>
                                    <p className="text_light_privacy">
                                        This Policy applies only to your use of our website. It does not extend to any websites that are linked to or from our website (whether we provide those links or whether they are shared by other users). We have no control over how your data is collected, stored or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them.
                                    </p>
                                    <p className="text_bold">Visiting our Website</p>
                                    <p className="text_light_privacy">
                                        All of the information that is collected by us is used only in the aggregate; that is, it is entered into our database, where we can use it to generate overall reports on our visitors, but not reports about individual visitors. When you use a device to access our website, we may access, collect, monitor, store on your device, and/or remotely store one or more "device identifiers." Device identifiers are small data files or similar data structures stored on or associated with your device, which uniquely identify your device. A device identifier may be data stored in connection with the device hardware, the device's operating system or other software, or data sent to the device by us and a device identifier may deliver information to us or to a third party partner about how you browse and use the service and may help us or others provide reports or personalized content and ads. Some features of the Website may not function properly if use or availability of device identifiers is impaired or disabled.
                                    </p>
                                    <p className="text_light_privacy">
                                        Like most websites, we may place a small file known as a "cookie" on your computer's hard drive. A cookie may contain information that allows us to track your path through our website and to determine whether you have visited us before. However, unless you register with us, it contains no personally identifiable information that would allow us to identify you. Cookies cannot be used to read data of your hard drive, and cannot retrieve information from any other cookies created by other websites. We use cookies in this manner to help us understand how visitors use our website, and to help us to improve our website. You may refuse to accept a cookie from us by following the procedures specific to your web browser. Although you may do so, you may find that your browser reacts strangely when visiting not only our website, but other websites as well. Since cookies don't provide us with any information from which we can identify you, we suggest you allow us to place one on your computer. If you are visiting a website where you will be accessing your confidential account information, you will be required to accept cookies as it is essential for site administration and security.
                                    </p>
                                    <p className="text_light_privacy">
                                        If you visit our website by "clicking-through" from a website operated by one of our partners, and you have registered with that partner, then that information provided by you to that partner may be transmitted to us. You should review the privacy policy of that website from which you reached our site in order to determine what information was collected and how you agreed that our partner could use that information. Regardless of the information that was transmitted to us, we don't store it unless you register with us.
                                    </p>
                                    <p className="text_bold">How we use the personal information we collect:</p>
                                    <p>
                                        We use personal information collected on this website in several ways:
                                        <ul className="privacy_policy_list">
                                            <li>to respond to your request for a call, referral, or quote</li>
                                            <li>to process transactions you request</li>
                                            <li>to tell you about products or services we offer</li>
                                            <li>to authenticate registered customers</li>
                                        </ul>
                                    </p>
                                    <p>
                                        We use Website Visitorship Data to develop, manage, and improve the website. Sometimes we add Website visitorship to personal information from registered customers. We may use that information to: 
                                        <ul className="privacy_policy_list">
                                            <li>manage the website</li>
                                            <li>help resolve problems</li>
                                            <li>analyse use of the website</li>
                                            <li>tell you about products or services we offer</li>
                                        </ul>
                                    </p>
                                    <p>
                                        If you have provided an email address, we may use your personal information to send you notifications about services offered to cater to your requirements. You may opt-out of any or all such marketing communications by informing us through the available contacts.
                                    </p>
                                    <p>
                                        By accessing our website or by enquiring on any of the products or services offered by us, it is deemed that you have expressly authorized Trust Group to contact you via call, text message or chatbots or otherwise for solicitation of the products and services provided by us. 
                                    </p>
                                    <p className="text_bold">
                                        Disclosure of your personal information:
                                    </p>
                                    <p>
                                        Trust Group may aggregate personal information from all of its users and provide that information in the aggregate to other parties, including advertisers, for marketing and promotional purposes. However, if we do so, that information will not be in a form that will allow any third party to identify you personally.
                                    </p>
                                    <p>
                                        This notice / policy is not intended to and do not create any contractual or other legal rights in favour of any user or viewer of www.trustsecurites.in or on behalf of any other party. However, users and viewers are informed that by using this website, they are deemed to have consented to the collection and use of information by Trust Group as stated above.
                                    </p>
                                    <p>
                                        We may share some of your personally identifiable information with our subsidiaries and sister companies that are committed to providing you with a comprehensive array of services. We do not place restrictions on the use of personal information by our subsidiaries, or affiliates but they will treat it at least as protectively as they treat information they obtain from their other users. They will also comply with applicable laws governing the transmission of promotional communications, and give you an opportunity in any such email that they send to choose not to receive such promotional e-mail messages in the future. The information so collected may be shared with SEBI/ NSE/ BSE/ MCX / KYC Registration Agencies (KRAs), asset management companies of mutual funds / registrar and transfer agents / collecting banks / other statutory and/ or regulatory authorities etc. We also may share your information in connection with a corporate transaction, such as a divestiture, merger, consolidation, or asset sale, and in the unlikely event of bankruptcy.
                                    </p>
                                    <p className="text_bold">
                                        Steps we take to protect your personal information
                                    </p>
                                    <p>
                                        Trust Group has implemented security procedures to help protect the personal information stored in our systems. For example, we limit access to personal information about you to employees who we believe reasonably need to come into contact with that information. We also employ required processes to protect against unauthorized access to your personal information.
                                    </p>
                                    <p className="text_bold">
                                        Notification Procedures
                                    </p>
                                    <p>
                                        Trust Group provides notifications, whether such notifications are required by law or are for marketing or other business related purposes, to you via email notice, written or hard copy notice, or through conspicuous posting of such notice on our website page, as determined by Trust Group in its sole discretion. Trust Group reserves the right to determine the form and manner of providing notifications to you, provided that you may opt out of certain manner of notification as described in this Privacy Policy.
                                    </p>
                                    <p className="text_bold">
                                        Minors
                                    </p>
                                    <p>
                                        Minors (as defined under the laws of their jurisdiction or residence) are not eligible to register for, use, or purchase the products or services available on our website. We do not knowingly collect personal Information from any minor, and will not use this information if we discover that it has been provided by a minor.                                     
                                    </p>
                                    <p className="text_bold">
                                        Retention and storage
                                    </p>
                                    <p>
                                        We will retain your personal information in our databases in accordance with the applicable laws.
                                    </p>
                                    <p className="text_bold">
                                        Opting-Out
                                    </p>
                                    <p>
                                        As part of the registration process, we give you the ability to receive via e-mail or direct messaging information about our products and services, updates to our website and customized advertisements that are targeted to your specific interest. We send this information directly ourselves, or via third party service providers. If you do not opt-out from receiving these communications about our website, we will send them to you.
                                    </p>
                                    <p className="text_bold">
                                        Contact Us
                                    </p>
                                    <p>
                                        If you have any questions about our website or this Policy, please contact us by email at info@trustgroup.in. Please ensure that your query is clear, particularly if it is a request for information about the data we hold about you.
                                    </p>
                                    <p className="text_bold">
                                        Changes to this Privacy Policy
                                    </p>
                                    <p>
                                        We reserve the right to change this Policy should we deem it advisable to do so. The changes may be due to changes in legislation or enhancements on the website. We may make changes to privacy policy and would reflect those changes in this Policy statement. You are requested to go through the privacy policy statement on a regular basis.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy;
