import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";

const AccountOpeningpennyDropPopup: React.FC = () => {

    return (
        <div className="main-index-wrapper">
            <main>
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg pr-150 pl-150 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-11">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                           <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                            <h4 className="sub-title wow " data-wow-delay="0.3s">Mobile Verification</h4>
                                            <p className="review-text text-center">Enter the 6 digits, One Time Password sent on your number <br></br>
                                                <a href="#" className="mobile_number">+91 9820098200 <i className="far fa-edit"></i></a>
                                            </p>
                                            <form className="login-form quote-form mb-20" action="#">
                                                <div className="email-input input_field">
                                                    <input type="password" placeholder="Enter OTP" />
                                                    <label className="input-title"><a href="#">Didn’t get OTP?</a></label>
                                                </div>
                                                <a href="contact.html" className="theme_btn resend_otp_btn sub-btn wow " data-wow-delay="0.5s">Resend OTP</a>
                                            </form>
                                            <div className="divider_150"></div>
                                            <a href="contact.html" className="theme_btn primary_btn sub-btn wow " data-wow-delay="0.5s">Verify</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="otp_popup">
                    <div className="blur_back"></div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg pt-150 pb-100 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 col-md-6  text-left" data-wow-delay="0.3s">
                                    <div className="do-box do-box-2 mb-30 email_verify_bg text-center">
                                        <h6 className="mb--10  pan_popup_head" data-wow-delay="0.1s">Code Position Example</h6>
                                        <img src="assets/img/icon/code_example.png" className="pan_popup_icon" />
                                        <Link to={PageLinks.ACCOUNT_OPENING_PENNY_DROP} className="theme_btn email_verify_btn sub-btn " data-wow-delay="0.5s">Close</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
        )
}

export default AccountOpeningpennyDropPopup;
