import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { kycStatus, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";

const VerifyYourEmailSuccessContact: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const Pagenavigate = () => {
        navigate(PageLinks.PROFILE);
    }

    setTimeout(Pagenavigate, 3000);

    return (
        <section className="subscribe-letter-area animation-div">
            <div className="line-shape d-none d-lg-inline-block success-popup-bg-img">
                <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
            </div>
            <div className="container custom-container-subs">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6  text-left mt-200" data-wow-delay="0.3s">
                        <div className="do-box do-box-pan-status mb-30 email_verify_bg text-center cursor" onClick={(e: any) => {
                            e.preventDefault();
                            navigate(PageLinks.PROFILE);
                        }}>
                            <img src="assets/img/email_suceess.png" className="user_icon" />
                            <p className="success-popup-text" data-wow-delay="0.1s">Thank You!</p>
                            <p className="success-popup-text" data-wow-delay="0.1s">Your Mail Sent Successfully Our team Member Will contact you soon.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VerifyYourEmailSuccessContact;