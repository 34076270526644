import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { bondTypes, OrderStatus1, OrderCancle, PageLinks, PaymentStatus1, SGBPaymentStatus, OrderStatus } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { numberWithCommas } from "../common/Utilities";
import { InitialData } from "../components/Form/InitialData";
import ConfirmationWithdrow from "../components/Modals/ConfirmationWithdrow";
import OrderStepsComponent from "../components/OrderStepsComponent";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import Copy from "../components/Svg/Copy";
import copy from 'copy-to-clipboard';
import { toast } from "react-hot-toast";
import ModifyPpup from "../components/Modals/ModifyPopup";
import ModifyPopup from "../components/Modals/ModifyPopup";
import CanclePopup from "../components/Modals/CanclePopup";
import Calculater from "../components/Modals/Calculater";


const BondConfirmedOrder = () => {

    const [bondsDetail, setBondDetail] = useState<APIData.SGBDetail>();
    const [bondsOrderDetail, setBondOrderDetail] = useState<APIData.SGBOrderDetail>();
    const [bondsOrderDetailIPO, setBondOrderDetailIPO] = useState<any>();
    const [bdDetail, setBdDetail] = useState<any>();
    const [Bonddetail, setBondOdetail] = useState<any>();
    const [confirmationModel, setConfirmationModel] = useState(false);
    const [modify, setModifyModel] = useState(false);
    const [cancel, setCancel] = useState(false);
    const navigate = useNavigate();
    const [isin, setIsin] = useState('');
    const [orderID, setOrderID] = useState('0');
    const [orderNO, setOrderNO] = useState('0');
    const [orderQty, setOrderQty] = useState('0');
    const [actionflag, setAtionflag] = useState(0);
    const { setShowPreloader, SGBBond } = useAppStateAPI();
    const location = useLocation();
    const [bondType, setBondType] = useState('');
    const [cancledata, setCancledata] = useState<any>();
    const [sucessStatusMode, setSucessStatusMode] = useState()

    var databond: any = {};

    useEffect(() => {
        databond = location.state;
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("id")) {
                const id = query.get("id");
                setOrderID(id!);
            }
            if (query.has("bond")) {
                const bondtype = query.get("bond");
                setBondType((bondtype!));
            }
            if (query.has("isin")) {
                const isin = query.get("isin");
                setIsin((isin!));
            }
        } else {
            setBondOdetail(databond)
            setBondType((databond.data.data.bond_type!));
            setOrderID(databond.data.data.order_id)
        }
    }, [location]);

    useEffect(() => {
        if (cancledata == bondTypes.IPO) {
            IPOorderDetail();
        } else if (cancledata == bondTypes.SGB) {
            SGBorderDetail();
        } else if (cancledata == bondTypes.ADDEDBOND) {
            BondorderDetail();
        }
    }, [cancledata])


    useEffect(() => {
        if (orderID != '0') {
            if (parseInt(bondType) == bondTypes.SGB) {
                SGBorderDetail();
            } else if (parseInt(bondType) == bondTypes.IPO) {
                IPOorderDetail();
            } else {
                BondorderDetail();
            }
        }
    }, [orderID])
    const goToBOND = () =>
        navigate({
            pathname: PageLinks.PROFILE_LISTING,
            search: "?bond_type_bond=" + bondTypes.ADDEDBOND + "?bond_type_bond1=" + bondTypes.EXISTINGBOND,
        });
    const goToBONDLIST = () =>
        navigate({
            pathname: PageLinks.ORDER_LISTING,
            search: "&isin=" + isin + "&bond=" + bondType + "&id=" + orderID,
        });
    const SGBorderDetail = () => {
        setShowPreloader(true);
        if (parseInt(bondType) == bondTypes.SGB) {
        ServerAPI.GetOrderSGBBond(parseInt(orderID)).then((response: any) => {
            if (response != undefined) {
                setBondOrderDetail(response);
                setShowPreloader(true);
                setSucessStatusMode(response.sgb_order_status);
               
                ServerAPI.SGBBondDetail(response['bond_isin_number']).then((response: any) => {
                    if (response != undefined) {
                        setBondDetail(response);
                    }
                }).finally(() => {
                    setShowPreloader(false);
                })
            
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    }

    const IPOorderDetail = () => {
        setShowPreloader(true);
        ServerAPI.GetOrderIPOBond(parseInt(orderID)).then((response: any) => {
            if (response != undefined) {
                setBondOrderDetailIPO(response);
                setShowPreloader(true);
                ServerAPI.GetIPODetail(response?.bond_id!).then((response: any) => {
                    if (response != undefined) {
                        setBondDetail(response);
                    }
                }).finally(() => {
                    setShowPreloader(false);
                })
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const BondorderDetail = () => {
        setShowPreloader(true);
        ServerAPI.GetOrderBond(orderID).then((response: any) => {
            if (response != undefined) {
                setBdDetail(response);
                setIsin(response?.order_details?.bond_isin_number)
                setShowPreloader(true);
                if(isin !== undefined || isin !== null || isin !== '' ){
                    ServerAPI.BondDetail(response?.order_details?.bond_isin_number).then((response: any) => {
                        if (response != undefined) {
                            setBondDetail(response);
                        }
                    }).finally(() => {
                        setShowPreloader(false);
                    })
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }


    useEffect(() => {
        if (isin != '' && parseInt(bondType) == bondTypes.SGB) {
            setShowPreloader(true);

        }
    }, [isin])

    const goToModify = (series: any) => {
        if (bondsOrderDetailIPO != undefined) {
            let items: any[] = [];
            let params: any = {};
            params.bond_ncd_series = series;
            items.push(params);
            navigate(PageLinks.BOND_IPO_PURCHASE + "?id=" + bondsOrderDetailIPO.bond_id, { state: { data: items } });
        }
    }

    return (
        <div className="main-index-wrapper">
            <Header />
            <div className="body-overlay"></div>
            <main>
                <section className="sovereign_gold_bonds pt-xl-70 pt-lg-70  pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid ">
                        <div className="row padding-20">
                            <div className="col-lg-8 col-md-8 col-sm-12  py-3 pt-sm-0">
                                <div className="mt-4 pt-3 confirm-res d-lg-block d-none">
                                    <p className="quicksand-midium font-weight-600 mb-1 fs-28">
                                        <b>Confirmation</b>
                                    </p>
                                    <p className="text-light-grey quicksand-midium fs-20 fw-500 p-0">We've got your request.</p>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 confirm-res">
                                <div className="confirm-image-style text-center my-3 justify-content-center pb-10">
                                    {(bdDetail?.order_details?.bond_order_status?.bond_order_status === OrderStatus1.CANCLE) || (bondsOrderDetail?.sgb_order_status == OrderStatus1.CANCLE) || (bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.CANCLE) ?

                                        <>
                                            <img src="/assets/img/Group 17380.svg" alt="bond_confirmed" className="confirm-img" />
                                            <p className="text-dark-red pt-2 quicksand-midium  fw-bold"> Order Cancelled Successfully!</p>
                                        </> :
                                        <>
                                            <img src="/assets/img/bond_confirmed.png" alt="bond_confirmed" className="confirm-img" />
                                            {(parseInt(bondType) == bondTypes.SGB) &&
                                                <p className="text-dark-red pt-2 quicksand-midium fs-xl-19  fw-bold">Order Placed Successfully!</p>
                                            }
                                            {(parseInt(bondType) == bondTypes.IPO) &&
                                                <p className="text-dark-red pt-2 quicksand-midium  fw-bold">Bid Received Successfully!</p>
                                            }
                                            {(parseInt(bondType) == bondTypes.ADDEDBOND || parseInt(bondType) == bondTypes.EXISTINGBOND) &&
                                                <p className="text-dark-red pt-2 quicksand-midium  fw-bold">Order Placed Successfully!</p>
                                            }
                                        </>
                                    }
                                </div>
                                <div className="text-center container-fluid card-box-2 pt-20">
                                    <div className="d-flex justify-content-between align-items-end row-responsive ">
                                        <div className=" mb-3 icon-logo ml-2 w_62">
                                        {(parseInt(bondType) == bondTypes.ADDEDBOND || parseInt(bondType) == bondTypes.EXISTINGBOND) ?
                                                <img src={bdDetail?.bond_logo} alt="" className="img-small img-70" />:
                                            <img src={bondsDetail?.bond_logo} alt="" className="img-small img-70 1" /> }
                                            {/* {/* {(parseInt(bondType) == bondTypes.ADDEDBOND || parseInt(bondType) == bondTypes.EXISTINGBOND) &&
                                                <img src={bondsOrderDetailIPO?.bond_logo} alt="" className="img-small img-70" />
                                            {(parseInt(bondType) == bondTypes.IPO) &&
                                                <img src={bondsDetail?.bond_logo} alt="" className="img-small img-70 1" />}
                                            {(parseInt(bondType) == bondTypes.SGB) &&
                                                <img src={bondsDetail?.bond_logo} alt="" className="img-small img-70 1" />} */}
                                            {/* {(parseInt(bondType) == bondTypes.SGB) &&
                                                <img src={bondsOrderDetailIPO?.order.bond_logo} alt="" className="img-small img-70" />} */}

                                            <div className="d-flex flex-column align-items-start ml-3">
                                                {(parseInt(bondType) == bondTypes.ADDEDBOND || parseInt(bondType) == bondTypes.EXISTINGBOND) &&
                                                    <p className="text-start quicksand-bold sgborder-bond-name fs-18 p-0 fw-500 p-0">{bdDetail?.bond_name} </p>}
                                                {(parseInt(bondType) == bondTypes.IPO) &&
                                                    <p className="text-start quicksand-bold sgborder-bond-name fs-18 p-0 fw-500 p-0">{bondsDetail?.bond_name} </p>}
                                                {(parseInt(bondType) == bondTypes.SGB) &&
                                                    <p className="text-start quicksand-bold sgborder-bond-name fs-18 p-0 fw-500 p-0">{bondsDetail?.bond_name} </p>}
                                                <div className="">
                                                    <div className="my-1">
                                                        <span className="background-grey title-background-style d-flex">
                                                            {(parseInt(bondType) == bondTypes.ADDEDBOND || parseInt(bondType) == bondTypes.EXISTINGBOND) &&
                                                                <p className="fs-15"> <span>ISIN: &nbsp;</span>{bdDetail?.order_details?.bond_isin_number} &nbsp;
                                                                    <span className="cursor" onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        copy(bdDetail?.order_details?.bond_isin_number!, {
                                                                            debug: true,
                                                                        });
                                                                        toast.success('ISIN Number Copied To Clipboard');
                                                                    }}>
                                                                        <Copy />
                                                                    </span>
                                                                </p>}
                                                            {(parseInt(bondType) == bondTypes.IPO) &&
                                                                <p className="fs-15"> <span>ISIN: &nbsp;</span>{bondsDetail?.bond_isin_number} &nbsp;
                                                                    <span className="cursor" onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        copy(bondsDetail?.bond_isin_number!, {
                                                                            debug: true,
                                                                        });
                                                                        toast.success('ISIN Number Copied To Clipboard');
                                                                    }}>
                                                                        <Copy />
                                                                    </span>
                                                                </p>}
                                                            {(parseInt(bondType) == bondTypes.SGB) &&
                                                                <p className="fs-15"> <span>ISIN: &nbsp;</span>{bondsDetail?.bond_isin_number} &nbsp;
                                                                    <span className="cursor" onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        copy(bondsDetail?.bond_isin_number!, {
                                                                            debug: true,
                                                                        });
                                                                        toast.success('ISIN Number Copied To Clipboard');
                                                                    }}>
                                                                        <Copy />
                                                                    </span>
                                                                </p>}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn-box d-lg-box">
                                            {((bondsOrderDetailIPO?.ipo_order_current_status !== OrderStatus1.CANCLE) || (bdDetail?.order_details?.bond_order_status?.bond_order_status === OrderStatus1.CANCLE)) && parseInt(bondType) == bondTypes.IPO ?
                                                <button onClick={(e: any) => {
                                                    e.preventDefault();
                                                    window.location.replace(bondsOrderDetailIPO?.ipo_order_pdf);
                                                }} className="btn-edit btn-1 m-1 font-bold-600"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 83.283 83.283" className="mr-1">
                                                        <path id="Path_22591" data-name="Path 22591" d="M-1692.082,782.512a3.9,3.9,0,0,1-3.9,3.9h-75.476a3.9,3.9,0,0,1-3.9-3.9V764.294a3.894,3.894,0,0,1,3.9-3.9h23.863l7.971,7.97a8.333,8.333,0,0,0,11.808,0l7.971-7.97h23.862a3.894,3.894,0,0,1,3.9,3.9Zm-35.135-79.379a3.894,3.894,0,0,1,3.9,3.9v27.327h14.266a3.247,3.247,0,0,1,2.293,5.546l-24.74,24.758a3.151,3.151,0,0,1-4.441,0l-24.774-24.758a3.246,3.246,0,0,1,2.293-5.546h14.283V707.037a3.894,3.894,0,0,1,3.9-3.9Zm11.712,72.222a3.262,3.262,0,0,0-3.253,3.253,3.263,3.263,0,0,0,3.253,3.253,3.263,3.263,0,0,0,3.253-3.253A3.262,3.262,0,0,0-1715.5,775.355Zm10.41,0a3.262,3.262,0,0,0-3.253,3.253,3.263,3.263,0,0,0,3.253,3.253,3.263,3.263,0,0,0,3.253-3.253A3.262,3.262,0,0,0-1705.095,775.355Z" transform="translate(1775.365 -703.133)" />
                                                    </svg> &nbsp;Download Application</button> : ""
                                            }
                                            {((bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.CANCLE) || (bdDetail?.order_details?.bond_order_status?.bond_order_status === OrderStatus1.CANCLE) || parseInt(bondType) == bondTypes.SGB) ?
                                                "" : <button onClick={(e: any) => {
                                                    e.preventDefault();
                                                    setModifyModel(true);
                                                }} className="btn-modify font-bold-600 btn-1 m-1"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 93.694 83.266" className="mr-1 mb-1">
                                                        <path id="Path_22587" data-name="Path 22587" d="M-1436.527,909.412l6.506-6.506a1.955,1.955,0,0,1,3.334,1.384v25.668a7.81,7.81,0,0,1-7.808,7.808h-57.258a7.81,7.81,0,0,1-7.808-7.808V872.7a7.811,7.811,0,0,1,7.808-7.809h46.49a1.952,1.952,0,0,1,1.382,3.336l-6.506,6.507a2,2,0,0,1-1.382.57h-37.379v52.051h52.051V910.8A2,2,0,0,1-1436.527,909.412Zm17.128-26.725a1.591,1.591,0,0,1,0,2.245l-35.525,35.526-15.1,1.673a3.163,3.163,0,0,1-3.5-3.5l1.676-15.094,35.525-35.526a1.591,1.591,0,0,1,2.245,0Zm11.679-9.419-5.758,5.759a1.59,1.59,0,0,1-2.244,0l-14.674-14.672a1.592,1.592,0,0,1,0-2.246l5.759-5.758a6.362,6.362,0,0,1,8.978,0l7.938,7.938A6.36,6.36,0,0,1-1407.72,873.268Z" transform="translate(1499.559 -854.498)" />
                                                    </svg>&nbsp;Modify Order</button>
                                            }
                                            {
                                                (bdDetail?.order_details?.bond_order_status?.bond_order_status === OrderStatus1.CANCLE) || (bondsOrderDetail?.sgb_order_status == OrderStatus1.CANCLE) || (bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.CANCLE) ?
                                                    "" : <button onClick={(e: any) => {
                                                        e.preventDefault();
                                                        setCancel(true);
                                                    }} className="btn-withdrow font-bold-600 btn-1 m-1"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 103 103" className="bg-light mr-1">
                                                            <g id="Group_177" data-name="Group 177" transform="translate(0.219 0.004)">
                                                                <circle id="Ellipse_4" data-name="Ellipse 4" transform="translate(-0.219 -0.004)" fill="#fff" />
                                                                <g id="Group_160" data-name="Group 160" transform="translate(25.941 25.94)">
                                                                    <line id="Line_14" data-name="Line 14" y1="51.132" x2="51.132" fill="none" stroke="#FF405A" stroke-width="7" />
                                                                    <line id="Line_15" data-name="Line 15" x1="51.132" y1="51.132" fill="none" stroke="#FF405A" stroke-width="7" />
                                                                </g>
                                                            </g>
                                                        </svg>Cancel</button>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-lg-12 bg-2 mt-2 p-10 mt-10">
                                        <div className="row m-auto justify-space-between">
                                            <div className="res-width p-sm-none mb-3">
                                                <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Order No</p>
                                                {(parseInt(bondType) == bondTypes.ADDEDBOND || parseInt(bondType) == bondTypes.EXISTINGBOND) &&
                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">{bdDetail?.order_number}</p>}

                                                {(parseInt(bondType) == bondTypes.SGB) &&
                                                    <p className="text-small1 text-start sourceSansPro-regular fs-18 p-0 font-bold-600">{bondsOrderDetail?.order_number}</p>}
                                                {(parseInt(bondType) == bondTypes.IPO) &&
                                                    <p className="text-small1 text-start sourceSansPro-regular fs-18 p-0 font-bold-600">{bondsOrderDetailIPO?.order_detail?.order_number}</p>}
                                            </div>
                                            <div className="res-width p-sm-none mb-3">
                                                <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Date</p>
                                                {(parseInt(bondType) == bondTypes.ADDEDBOND || parseInt(bondType) == bondTypes.EXISTINGBOND) &&
                                                    <p className="text-small1 text-start sourceSansPro-regular fs-18 p-0 font-bold-600">{moment(bdDetail?.order_details?.bond_order_datetime).format("MMM Do YYYY")}</p>}
                                                {(parseInt(bondType) == bondTypes.SGB) &&
                                                    <p className="text-small1 text-start sourceSansPro-regular fs-18 p-0 font-bold-600">{moment(bondsOrderDetail?.sgb_order_datetime).format("MMM Do YYYY")}</p>}
                                                {(parseInt(bondType) == bondTypes.IPO) &&
                                                    <p className="text-small1 text-start sourceSansPro-regular fs-18 p-0 font-bold-600">{moment(bondsOrderDetailIPO?.ipo_order_datetime).format("MMM Do YYYY")}</p>}

                                            </div>
                                            <div className="res-width p-sm-none mb-3">
                                                <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Order Status</p>
                                                {/* <p className="text-small1 text-start sourceSansPro-regular font-bold-600 text-yellow">Under Processing</p> */}
                                                {(parseInt(bondType) == bondTypes.ADDEDBOND || parseInt(bondType) == bondTypes.EXISTINGBOND) &&
                                                    <div className="col-lg-12 p-0">
                                                        {bdDetail?.order_details?.bond_order_status?.bond_order_status == OrderStatus1.PLACED &&
                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-yellow">Under Processing</p>
                                                        }
                                                        {bdDetail?.order_details?.bond_order_status?.bond_order_status == OrderStatus1.MODIFIED &&
                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-yellow">Modified</p>
                                                        }
                                                        {bdDetail?.order_details?.bond_order_status?.bond_order_status === OrderStatus1.CANCLE &&

                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-red-cancle">Cancelled</p>

                                                        }
                                                        {bdDetail?.order_details?.bond_order_status?.bond_order_status == OrderStatus1.ACCEPTED &&

                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-yellow">Accepted</p>

                                                        }
                                                        {bdDetail?.order_details?.bond_order_status?.bond_order_status == OrderStatus1.REJECTED &&

                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-yellow">Rejected</p>

                                                        }
                                                    </div>
                                                }
                                                {(parseInt(bondType) == bondTypes.IPO) &&
                                                    <div className="col-lg-12 p-0">
                                                        {bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.PLACED &&
                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-yellow">Under Processing</p>
                                                        }
                                                        {bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.MODIFIED &&
                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-yellow">Modified</p>
                                                        }
                                                        {bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.CANCLE &&

                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-red-cancle">Cancelled</p>

                                                        }
                                                        {bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.ACCEPTED &&

                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-yellow">Accepted</p>

                                                        }
                                                        {bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.REJECTED &&

                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-yellow">Rejected</p>

                                                        }

                                                    </div>
                                                }
                                                {(parseInt(bondType) == bondTypes.SGB) &&
                                                    <div className="col-lg-12 p-0">
                                                        {bondsOrderDetail?.sgb_order_status == OrderStatus1.PLACED &&
                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600  fs-18 p-0 text-yellow">Under Processing</p>
                                                        }
                                                        {bondsOrderDetail?.sgb_order_status == OrderStatus1.MODIFIED &&
                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0  text-green">Success</p>
                                                        }
                                                        {bondsOrderDetail?.sgb_order_status == OrderStatus1.CANCLE &&

                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-red-cancle">Cancelled</p>

                                                        }
                                                        {bondsOrderDetail?.sgb_order_status == OrderStatus1.ACCEPTED &&

                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-yellow">Accepted</p>

                                                        }
                                                        {bondsOrderDetail?.sgb_order_status == OrderStatus1.REJECTED &&

                                                            <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-yellow">Rejected</p>

                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <div className="res-width p-sm-none mb-3">
                                                <p className="text-small1 text-start sourceSansPro-regular text-light-grey text-blue">Payment Status</p>
                                                {(parseInt(bondType) == bondTypes.IPO) &&

                                                    <div className="col-lg-4 p-0">
                                                        {bondsOrderDetailIPO?.ipo_order_current_payment_status == PaymentStatus1.PENDING &&
                                                            <p className="text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-blue">Processing</p>
                                                        }
                                                        {bondsOrderDetailIPO?.ipo_order_current_payment_status == PaymentStatus1.SUCCESS &&
                                                            <p className="text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-green" >Successful</p>
                                                        }
                                                        {bondsOrderDetailIPO?.ipo_order_current_payment_status == PaymentStatus1.FAILURE &&
                                                            <p className="text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-red-cancle">Failed</p>
                                                        }
                                                        {bondsOrderDetailIPO?.ipo_order_current_payment_status == PaymentStatus1.REFUNDED &&
                                                            <p className="text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-red-cancle">Refunded</p>
                                                        }
                                                    </div>
                                                }
                                                {(parseInt(bondType) == bondTypes.ADDEDBOND || parseInt(bondType) == bondTypes.EXISTINGBOND) &&
                                                    <div className="col-lg-4 p-0">
                                                        {bdDetail?.order_details?.bond_order_payments?.bond_order_payment_status == PaymentStatus1.PENDING &&
                                                            <p className="text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-blue">Processing</p>
                                                        }
                                                        {bdDetail?.order_details?.bond_order_payments?.bond_order_payment_status == PaymentStatus1.SUCCESS &&
                                                            <p className="text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-green">Successful</p>
                                                        }
                                                        {bdDetail?.order_details?.bond_order_payments?.bond_order_payment_status == PaymentStatus1.FAILURE &&
                                                            <p className="text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-red-cancle">Failed</p>
                                                        }
                                                        {bdDetail?.order_details?.bond_order_payments?.bond_order_payment_status == PaymentStatus1.REFUNDED &&
                                                            <p className="text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-red-cancle">Refunded</p>
                                                        }
                                                    </div>
                                                }
                                                {(parseInt(bondType) == bondTypes.SGB) &&
                                                    <div className="col-lg-4 p-0">
                                                        {bondsOrderDetail?.sgb_order_payments == SGBPaymentStatus.PENDING &&
                                                            <p className="text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-blue">Processing</p>
                                                        }
                                                        {bondsOrderDetail?.sgb_order_payments == SGBPaymentStatus.SUCCESS &&
                                                            <p className="text-start sourceSansPro-regular font-bold-600 fs-18 p-0  text-green">Successful</p>
                                                        }
                                                        {bondsOrderDetail?.sgb_order_payments == SGBPaymentStatus.FAILURE &&
                                                            <p className="text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-red-cancle" style={{ color: "#FF0023" }}>Failed</p>
                                                        }
                                                        {bondsOrderDetail?.sgb_order_payments == SGBPaymentStatus.REFUNDED &&
                                                            <p className="text-start quicksand-bold text-red-cancle fs-18 p-0">Refunded</p>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            {(parseInt(bondType) == bondTypes.ADDEDBOND || parseInt(bondType) == bondTypes.EXISTINGBOND) &&
                                                <div className="res-width  bg-res mb-3">
                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Price</p>

                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">₹ {numberWithCommas(bdDetail?.order_details?.bond_order_amount)}</p>

                                                </div>}

                                            {(parseInt(bondType) == bondTypes.ADDEDBOND || parseInt(bondType) == bondTypes.EXISTINGBOND) &&
                                                <>
                                                    <div className="res-width  bg-res mb-3">
                                                        <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Yield</p>

                                                        <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">{numberWithCommas(bdDetail?.bonds_yeild)}</p>

                                                    </div>
                                                    <div className="res-width  bg-res mb-3">
                                                        <p className="text-small1 text-start sourceSansPro-regular text-light-grey">No. of Quantity</p>

                                                        <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">{bdDetail?.order_details?.bond_order_quantity}</p>

                                                    </div>
                                                    <div className="res-width  bg-res mb-3">
                                                        <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Total Amount</p>

                                                        <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">{numberWithCommas(bdDetail?.order_details?.bond_order_total_amount)}</p>

                                                    </div>
                                                    <div className="res-width  bg-res mb-3">
                                                        <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Order Type</p>

                                                        <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">{bdDetail?.order_details?.buy_sell_id == 2 ? "SELL" : "BUY"}</p>

                                                    </div>
                                                    <div className="res-width  bg-res mb-3">
                                                        <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Exchange</p>
                                                        <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">{bdDetail?.listing_name}</p>

                                                    </div>
                                                </>}
                                        </div>
                                        {(parseInt(bondType) == bondTypes.IPO) &&
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h5 className="text-left text-title mb-2">Selected Series Of Ncds</h5>
                                                    <div className="bg-wight">
                                                        <table className="w-100 justify-space-between">
                                                            <thead>
                                                                <tr>
                                                                    <th className="th-header w-20">Series</th>
                                                                    <th className="th-header w-20">Price</th>
                                                                    {/* <th className="th-header w-20">Yield</th> */}
                                                                    <th className="th-header w-20">No. of Quantity</th>
                                                                    <th className="th-header w-20">Total Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    bondsOrderDetailIPO?.ipo_ncd_series.map((ncdsOrderDetail: any, index: number) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className="td-body">{ncdsOrderDetail?.ipo_order_ncd_series_number}</td>
                                                                                <td className="td-body">₹{ncdsOrderDetail?.ipo_order_ncd_series_amount}</td>
                                                                                {/* <td className="td-body">{ncdsOrderDetail?.ipo_order_ncd_series_number}</td> */}
                                                                                <td className="td-body">{ncdsOrderDetail?.ipo_order_ncd_series_quantity}</td>
                                                                                <td className="td-body">₹ {ncdsOrderDetail?.ipo_order_ncd_series_total_amount}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                                {/* <tr>
                                                                        <td className="td-body">{bondsOrderDetailIPO?.ipo_ncd_series[0]?.ipo_order_ncd_series_number}</td>
                                                                        <td className="td-body">₹{bondsOrderDetailIPO?.ipo_ncd_series[0]?.ipo_order_ncd_series_amount}</td>
                                                                        <td className="td-body">{bondsOrderDetailIPO?.ipo_ncd_series[0]?.ipo_order_ncd_series_number}</td>
                                                                        <td className="td-body">{bondsOrderDetailIPO?.ipo_ncd_series[0]?.ipo_order_ncd_series_quantity}</td>
                                                                        <td className="td-body">₹ {bondsOrderDetailIPO?.ipo_ncd_series[0]?.ipo_order_ncd_series_total_amount}</td>
                                                                    </tr> */}
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                </div>
                                            </div>}
                                        {(parseInt(bondType) == bondTypes.SGB) &&
                                            <div className="row m-auto justify-space-between">
                                                <div className="res-width  bg-res mb-3">
                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Price of 1 gram</p>
                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600">₹ {numberWithCommas(bondsDetail?.bonds_price_per_gram)}</p>
                                                </div>
                                                <div className="res-width  bg-res mb-3">
                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Yield</p>


                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600">{numberWithCommas(bondsDetail?.bonds_yeild)}</p>
                                                </div>
                                                <div className="res-width  bg-res mb-3">
                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">No. of Quantity</p>

                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600">{bondsOrderDetail?.sgb_order_quantity}</p>
                                                </div>
                                                <div className="res-width  bg-res mb-3">
                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Total Amount</p>

                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600 ">₹ {numberWithCommas(bondsOrderDetail?.sgb_order_total_amount)}</p>

                                                </div>
                                            </div>}


                                    </div>

                                    <div className="btn-box d-sm-box">
                                        {((bondsOrderDetailIPO?.ipo_order_current_status !== OrderStatus1.CANCLE) || (bdDetail?.order_details?.bond_order_status?.bond_order_status === OrderStatus1.CANCLE)) && parseInt(bondType) == bondTypes.IPO ?
                                            <button onClick={(e: any) => {
                                                e.preventDefault();
                                            }} className="btn-edit btn-1 m-1 font-bold-600"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 83.283 83.283" className="mr-1">
                                                    <path id="Path_22591" data-name="Path 22591" d="M-1692.082,782.512a3.9,3.9,0,0,1-3.9,3.9h-75.476a3.9,3.9,0,0,1-3.9-3.9V764.294a3.894,3.894,0,0,1,3.9-3.9h23.863l7.971,7.97a8.333,8.333,0,0,0,11.808,0l7.971-7.97h23.862a3.894,3.894,0,0,1,3.9,3.9Zm-35.135-79.379a3.894,3.894,0,0,1,3.9,3.9v27.327h14.266a3.247,3.247,0,0,1,2.293,5.546l-24.74,24.758a3.151,3.151,0,0,1-4.441,0l-24.774-24.758a3.246,3.246,0,0,1,2.293-5.546h14.283V707.037a3.894,3.894,0,0,1,3.9-3.9Zm11.712,72.222a3.262,3.262,0,0,0-3.253,3.253,3.263,3.263,0,0,0,3.253,3.253,3.263,3.263,0,0,0,3.253-3.253A3.262,3.262,0,0,0-1715.5,775.355Zm10.41,0a3.262,3.262,0,0,0-3.253,3.253,3.263,3.263,0,0,0,3.253,3.253,3.263,3.263,0,0,0,3.253-3.253A3.262,3.262,0,0,0-1705.095,775.355Z" transform="translate(1775.365 -703.133)" />
                                                </svg> &nbsp;Download Application</button> : ""
                                        }
                                        {((bondsOrderDetailIPO?.ipo_order_current_status !== OrderStatus1.CANCLE) || (bdDetail?.order_details?.bond_order_status?.bond_order_status === OrderStatus1.CANCLE)) && parseInt(bondType) !== bondTypes.SGB ?
                                            <button onClick={(e: any) => {
                                                e.preventDefault();
                                                setModifyModel(true);
                                            }} className="btn-modify font-bold-600 btn-1 m-1"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 93.694 83.266" className="mr-1 mb-1">
                                                    <path id="Path_22587" data-name="Path 22587" d="M-1436.527,909.412l6.506-6.506a1.955,1.955,0,0,1,3.334,1.384v25.668a7.81,7.81,0,0,1-7.808,7.808h-57.258a7.81,7.81,0,0,1-7.808-7.808V872.7a7.811,7.811,0,0,1,7.808-7.809h46.49a1.952,1.952,0,0,1,1.382,3.336l-6.506,6.507a2,2,0,0,1-1.382.57h-37.379v52.051h52.051V910.8A2,2,0,0,1-1436.527,909.412Zm17.128-26.725a1.591,1.591,0,0,1,0,2.245l-35.525,35.526-15.1,1.673a3.163,3.163,0,0,1-3.5-3.5l1.676-15.094,35.525-35.526a1.591,1.591,0,0,1,2.245,0Zm11.679-9.419-5.758,5.759a1.59,1.59,0,0,1-2.244,0l-14.674-14.672a1.592,1.592,0,0,1,0-2.246l5.759-5.758a6.362,6.362,0,0,1,8.978,0l7.938,7.938A6.36,6.36,0,0,1-1407.72,873.268Z" transform="translate(1499.559 -854.498)" />
                                                </svg>&nbsp;Modify Order</button> : ""
                                        }
                                        {
                                            (bdDetail?.order_details?.bond_order_status?.bond_order_status === OrderStatus1.CANCLE) || (bondsOrderDetail?.sgb_order_status == OrderStatus1.CANCLE) || (bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.CANCLE) ?
                                                "" : <button onClick={(e: any) => {
                                                    e.preventDefault();
                                                    setCancel(true);
                                                }} className="btn-withdrow font-bold-600 btn-1 m-1"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 103 103" className="bg-light mr-1">
                                                        <g id="Group_177" data-name="Group 177" transform="translate(0.219 0.004)">
                                                            <circle id="Ellipse_4" data-name="Ellipse 4" transform="translate(-0.219 -0.004)" fill="#fff" />
                                                            <g id="Group_160" data-name="Group 160" transform="translate(25.941 25.94)">
                                                                <line id="Line_14" data-name="Line 14" y1="51.132" x2="51.132" fill="none" stroke="#FF405A" stroke-width="7" />
                                                                <line id="Line_15" data-name="Line 15" x1="51.132" y1="51.132" fill="none" stroke="#FF405A" stroke-width="7" />
                                                            </g>
                                                        </g>
                                                    </svg>Cancel</button>
                                        }

                                    </div>
                                    <Fragment>
                                        <div className="row">
                                            <div className="col-lg-12 p-0">
                                                {/* {
                                                    (bondsOrderDetailIPO?.bse_rfq_order_status == OrderStatus1.CANCLE) || (bondsOrderDetail?.sgb_order_status == OrderStatus1.CANCLE ) || (bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.CANCLE)?
                                                    <OrderStepsComponent step={4} />
                                                    : bondsOrderDetailIPO?.bse_rfq_order_status == OrderStatus1.PLACED || bondsOrderDetail?.sgb_order_status == OrderStatus1.PLACED || bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.PLACED ?
                                                    <OrderStepsComponent step={2.5} />
                                                    : bondsOrderDetailIPO?.bse_rfq_order_status == OrderStatus1.ACCEPTED || bondsOrderDetail?.sgb_order_status == OrderStatus1.ACCEPTED || bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.ACCEPTED ?
                                                      <OrderStepsComponent step={4} /> : <OrderStepsComponent step={2.5} />
                                            } */}
                                                {((bondsOrderDetail?.sgb_order_status == OrderStatus1.CANCLE) || (bondsOrderDetailIPO?.ipo_order_current_status === OrderStatus1.CANCLE) || (bdDetail?.order_details?.bond_order_status?.bond_order_status == OrderStatus1.CANCLE)) &&
                                                    <OrderStepsComponent step={4} />
                                                }

                                                {(bdDetail?.order_details?.bond_order_status?.bond_order_status == OrderStatus1.MODIFIED) || (bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.MODIFIED) &&
                                                    <OrderStepsComponent step={2.5} />
                                                }
                                                {((bdDetail?.order_details?.bond_order_status?.bond_order_status == OrderStatus1.PLACED) || (bondsOrderDetail?.sgb_order_status == OrderStatus1.PLACED) || (bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.PLACED)) &&
                                                    <OrderStepsComponent step={2.5} />
                                                }

                                                {((bdDetail?.order_details?.bond_order_status?.bond_order_status == OrderStatus1.ACCEPTED) || (bondsOrderDetail?.sgb_order_status == OrderStatus1.ACCEPTED) || (bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.ACCEPTED)) &&
                                                    <OrderStepsComponent step={3} />
                                                }
                                                {((bdDetail?.order_details?.bond_order_status?.bond_order_status == OrderStatus1.REJECTED) || (bondsOrderDetail?.sgb_order_status == OrderStatus1.REJECTED) || (bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus1.REJECTED)) &&
                                                    <OrderStepsComponent step={4} />
                                                }
                                            </div>
                                        </div>
                                    </Fragment>
                                    {/* {(parseInt(bondType) == bondTypes.SGB) &&
                                        <Fragment>
                                            <div className="col-lg-12 row text-start">
                                                <div className="col-lg-2"></div>
                                                <div className="background-light-grey d-flex justify-content-between border-radius mt-2 p-3 col-lg-10 row">
                                                    <div className="col-lg-2">
                                                        <span className="text-small1 sourceSansPro-regular text-light-grey">Price of 1 gm</span>
                                                        <p className="quicksand-bold">{"₹" + numberWithCommas(bondsOrderDetail?.sgb_order_amount)}</p>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <span className="text-small1 sourceSansPro-regular text-light-grey">Yield %</span>
                                                        {(parseInt(bondType) == bondTypes.SGB) &&
                                                            <p className="quicksand-bold">{bondsDetail?.bonds_yeild}</p>
                                                        }
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <span className="text-small1 sourceSansPro-regular text-light-grey">Gold Quantity in Grams</span>
                                                        {(parseInt(bondType) == bondTypes.SGB) &&
                                                            <p className="quicksand-bold">{bondsOrderDetail?.sgb_order_quantity}</p>
                                                        }
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <span className="text-small1 sourceSansPro-regular text-light-grey">Amount Invested</span>
                                                        <p className="quicksand-bold">{"₹" + numberWithCommas(bondsOrderDetail?.sgb_order_total_amount)}</p>
                                                    </div>
                                                    <div className="col-lg-1"></div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 row">
                                                <div className="col-lg-1"></div>
                                                <div className="col-lg-9">
                                                    <OrderStepsComponent step={2.5} />
                                                </div>
                                                <div className="col-lg-2 mt-4 text-start">
                                                    <span className="text-small1 sourceSansPro-regular text-light-grey">Order Status</span>
                                                    <p className="fs-16 fw-500 lh-sm text-wrap text-orange sourceSansPro-regular">{bondsOrderDetail?.sgb_order_status == OrderStatus.SUCCESS ? "Success" : "Under Processing"}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 row mt-3">
                                                <div className="text-center">
                                                    <button className="order-confirm-page-cancel-btn w-15"><span className="order-cancel-btn-symbol"><b>X</b></span>&nbsp;&nbsp;Cancel</button>
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                    {(parseInt(bondType) == bondTypes.IPO) && bondsOrderDetailIPO != undefined &&
                                        <Fragment>
                                            <div className="col-lg-12 row text-start">
                                                <div className="col-lg-2"></div>
                                                <div className="background-light-grey d-flex justify-content-between border-radius mt-2 p-3 col-lg-10 row">
                                                    <div className="col-lg-2">
                                                        <span className="text-start text-small1 sourceSansPro-regular text-light-grey">Series</span>
                                                        {bondsOrderDetailIPO.ipo_ncd_series != undefined && bondsOrderDetailIPO.ipo_ncd_series.length > 0 && bondsOrderDetailIPO.ipo_ncd_series.map((ncd_series: APIData.IPOorderNCDSeries) => {
                                                            return (
                                                                <p className="quicksand-bold">{ncd_series.ipo_order_ncd_series_number}</p>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <span className="text-small1 sourceSansPro-regular text-light-grey">Price</span>
                                                        {bondsOrderDetailIPO.ipo_ncd_series != undefined && bondsOrderDetailIPO.ipo_ncd_series.length > 0 && bondsOrderDetailIPO.ipo_ncd_series.map((ncd_series: APIData.IPOorderNCDSeries) => {
                                                            return (
                                                                <p className="quicksand-bold">{"₹" + numberWithCommas(ncd_series.ipo_order_ncd_series_number)}</p>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <span className="text-small1 sourceSansPro-regular text-light-grey">Yield%</span>
                                                        {bondsOrderDetailIPO.ipo_ncd_series != undefined && bondsOrderDetailIPO.ipo_ncd_series.length > 0 && bondsOrderDetailIPO.ipo_ncd_series.map((ncd_series: APIData.IPOorderNCDSeries) => {
                                                            return (
                                                                <p className="quicksand-bold">{"₹" + numberWithCommas(ncd_series.ipo_order_ncd_series_number)}</p>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <span className="text-small1 sourceSansPro-regular text-light-grey">No. of Quantity</span>
                                                        {bondsOrderDetailIPO.ipo_ncd_series != undefined && bondsOrderDetailIPO.ipo_ncd_series.length > 0 && bondsOrderDetailIPO.ipo_ncd_series.map((ncd_series: APIData.IPOorderNCDSeries) => {
                                                            return (
                                                                <p className="quicksand-bold">{"₹" + numberWithCommas(ncd_series.ipo_order_ncd_series_quantity)}</p>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <span className="text-small1 sourceSansPro-regular text-light-grey">Total Amount</span>
                                                        {bondsOrderDetailIPO.ipo_ncd_series != undefined && bondsOrderDetailIPO.ipo_ncd_series.length > 0 && bondsOrderDetailIPO.ipo_ncd_series.map((ncd_series: APIData.IPOorderNCDSeries) => {
                                                            return (
                                                                <p className="quicksand-bold">{"₹" + numberWithCommas(ncd_series.ipo_order_ncd_series_amount)}</p>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 row">
                                                <div className="col-lg-1"></div>
                                                <div className="col-lg-9">
                                                    <OrderStepsComponent step={2.5} />
                                                </div>
                                                <div className="col-lg-2 mt-4 text-start">
                                                    <span className="text-small1 sourceSansPro-regular text-light-grey">Order Status</span>
                                                    <p className="fs-16 fw-500 lh-sm text-wrap text-orange sourceSansPro-regular">{bondsOrderDetailIPO?.ipo_order_current_status == OrderStatus.SUCCESS ? "Success" : "Under Processing"}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 row mt-3">
                                                <div className="col-lg-2"></div>
                                                <div className="col-lg-3">
                                                    <button onClick={(e: any) => {
                                                        e.preventDefault();
                                                        window.open(bondsOrderDetailIPO.ipo_order_pdf);
                                                    }} className="order-confirm-page-download-btn"><span><b><i className="fa fa-download" aria-hidden="true"></i></b></span>&nbsp;&nbsp;Download Application</button>
                                                </div>
                                                <div className="col-lg-1">
                                                </div>
                                                <div className="col-lg-5 row">
                                                    <div className="col-lg-6">
                                                        <button className="order-confirm-page-modify-btn" onClick={(e: any) => {
                                                            e.preventDefault();
                                                            goToModify(bondsOrderDetailIPO.ipo_ncd_series);
                                                        }}><span><b><i className="fa fa-edit"></i></b></span>&nbsp;&nbsp;Modify Order</button>

                                                    </div>
                                                    <div className="col-lg-6">
                                                        <button className="order-confirm-page-cancel-btn"><span className="order-cancel-btn-symbol"><b>X</b></span>&nbsp;&nbsp;Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    } */}

                                    <br />
                                </div>
                                <br />

                                <div className="text-start container col-lg-10 col-md-10 col-sm-12 background-light border-radius-4">
                                    <div className="row py-3 px-3">
                                        <p className="sourceSansPro-semibold m-0 p-0 fs-18"> <b>Please note</b></p> <br />
                                        <div>


                                            <ul className="ul-style">
                                                <li className="">
                                                    <p className="sourceSansPro-regular text-light-grey fs-14">Your bid will be forwarded to the Stock Exchange. RBI and Stock Exchange shall approve your bid.</p></li>
                                                <li >
                                                    <p className="sourceSansPro-regular text-light-grey fs-14">On approval, the funds are transferred to Stock Exchange.</p></li>

                                                <li >
                                                    <p className="sourceSansPro-regular text-light-grey fs-14">Allotted Bonds shall be credited by RBI In your Demat account within 5 days from the Allotment Date.</p></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <br /> <br />

                                <div className="d-flex mb-4 btn-box justify-content-center">
                                    <button onClick={(e: any) => {
                                        e.preventDefault();
                                        goToBOND()
                                    }} className="explore_btn_bond bonds-detail-btn mx-2" style={{ padding: "3px 20px" }}>Explore Other Bonds</button>
                                    <button onClick={(e: any) => {
                                        e.preventDefault();
                                        goToBONDLIST()

                                    }} className="view_btn_bond bonds-detail-btn btn-1 mx-2 mt-1" style={{ padding: "3px 20px" }}>View My Orders</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
            <ConfirmationWithdrow showModal={confirmationModel} closeModal={() => { setConfirmationModel(false) }} />
            {/* <ModifyPopup showModal={modify} closeModal={() => { setModifyModel(false) }} isin={isin} qty={Bonddetail?.data?.rfq_quantity} orderID={bondsOrderDetailIPO?.order_id}/>
            <CanclePopup showModal={cancel} closeModal={() => { setCancel(false) }} /> */}

            <ModifyPopup showModal={modify} closeModal={() => { setModifyModel(false) }} bondType={bondType} isin={isin} qty={parseInt(bondType) == bondTypes.ADDEDBOND || parseInt(bondType) == bondTypes.EXISTINGBOND ? bdDetail?.order_details?.bond_order_quantity : ""}
                orderID={
                    (parseInt(bondType) == bondTypes.IPO ? orderID : 0) ||
                    (parseInt(bondType) == bondTypes.ADDEDBOND ? bdDetail?.order_id : 0)
                } orderNo={(parseInt(bondType) == bondTypes.ADDEDBOND ? bondsOrderDetailIPO?.bse_rfq_internal_order_number : 0) ||
                    (parseInt(bondType) == bondTypes.IPO ? bondsOrderDetailIPO?.ipo_order_form_number : 0)
                } />
            <CanclePopup
                showModal={cancel}
                closeModal={() => { setCancel(false) }}
                id={
                    (parseInt(bondType) == bondTypes.IPO ? orderID : 0) ||
                    (parseInt(bondType) == bondTypes.SGB ? orderID : 0) ||
                    (parseInt(bondType) == bondTypes.ADDEDBOND ? bdDetail?.order_id : 0)
                }
                orderNO={bondsOrderDetailIPO?.bse_rfq_internal_order_number}
                orderQty={bdDetail?.order_details?.bond_order_quantity}
                setCancledata={setCancledata}
                bondType={bondType} />
        </div>


    );
}

export default BondConfirmedOrder;
