import { url } from "inspector";
import { Fragment, useEffect, useRef, useState } from "react";
import DownloadIssueDocuments from "../components/DownloadIssueDocuments";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { interestPaymentFrequency, IssuerType, NatureofInstruments, listing, PageLinks, bondTypes } from "../common/Constants";
import Footer from "../components/template/Footer";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import InvestSteps from "../components/InvestSteps";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import moment from "moment";
import { numberWithCommas } from "../common/Utilities";
import Copy from "../components/Svg/Copy";
import Alert from "../components/Modals/Alert";
import Download from "../components/Svg/Download";
import copy from 'copy-to-clipboard';
import { toast } from "react-hot-toast";
import { Accordion } from "react-accessible-accordion";


const DetailViewOne: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [openEarnDropDown, setOpenEarnDropDown] = useState(true);
    const toggleEarnDropdown = () => setOpenEarnDropDown(!openEarnDropDown);
    const [subTable, setSubTable] = useState(false);
    const toggleEarnSubTableDropdown = () => setSubTable(!subTable);
    const [isin, setIsin] = useState('');
    const [qty, setQty] = useState(1);
    const location = useLocation();
    const [bondsDetail, setBondDetail] = useState<APIData.BondlistData>();
    const [investmentValues, setInvestmentValues] = useState<APIData.InvestmentCalculator>();
    const [bondsDetailCashflow, setBondDetailCashflow] = useState<APIData.BondCashFlow[]>([]);
    const [investmentToday, setInvestmentToday] = useState('');
    const [alertPopup, setAlertPopup] = useState(false);
    const [highlighterClass, setHighlighterClass] = useState('');
    let InterstEarned = 0;
    let principalAmount = 0;
    const scollToRef = useRef();
    let nextprincipleAmount = 0;
    let totalreturnAmount = 0;



    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleIndex = (index: any) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const goToBuy = (isin: string) => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            navigate({
                pathname: PageLinks.APPLYBONDNONGOLD,
                search: "?isin=" + isin + "&qty=" + qty,
            });
        } else {
            localStorage.setItem('redirection', 'SGB');
            localStorage.setItem('isin', bondsDetail?.bond_isin_number!);
            navigate(PageLinks.LOGIN);
        }
    }

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("isin")) {
                const id = query.get("isin");
                setIsin((id!));
            }
        }
    }, [location]);

    useEffect(() => {
        principalAmount = parseInt(investmentToday);
    }, [investmentToday])

    useEffect(() => {
        if (isin != '') {
            setShowPreloader(true);
            ServerAPI.BondDetail(isin).then((response: any) => {
                if (response != undefined) {
                    setBondDetail(response);
                    setInvestmentToday(' ₹ ' + numberWithCommas(parseInt(response?.bond_minimum_application!) * qty))
                }
            }).finally(() => {
                setShowPreloader(false);
            })

            setShowPreloader(true);
            ServerAPI.Cashflow(isin).then((response: any) => {
                if (response != undefined) {
                    setBondDetailCashflow(response);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [isin])
    // useEffect(() => {
    //     if (isin != '') {
    //         setShowPreloader(true);
    //         ServerAPI.BondInvestmentCalculator(isin, qty).then((response: any) => {
    //             if (response != undefined) {
    //                 setInvestmentValues(response);
    //                 setInvestmentToday(response['total_investment']);
    //             }
    //         }).finally(() => {
    //             setShowPreloader(false);
    //         })
    //     }
    // }, [isin, qty])


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const gotoHighlight = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        }
    };

    return (
        <div className="main-index-wrapper">
            <Header />

            <main>
                <section className="mt-0 pt-110 pb-30 pb-md-10 pt-md-120 pt-sm-100 pt-xs-80 pb-xs-10 ">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-12 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li>Bonds</li>
                                            <li>Explore Bonds</li>
                                            <li className="active">{bondsDetail?.bond_name}</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid d_highlight_none">
                            <div className="ipo-nav-top">
                                <table className="ipo-nav-table lg-w-60">
                                    <tr>
                                        <td className={highlighterClass == 'Highlights' ? "highlighter-active cursor" : "cursor font-weight-600"} onClick={(e: any) => {
                                            e.preventDefault();
                                            gotoHighlight('Highlights');
                                            setHighlighterClass('Highlights');
                                        }}>Key Highlights</td>
                                        {/* <td className={highlighterClass == 'Series' ? "highlighter-active cursor" : "cursor"} onClick={(e: any) => {
                                            e.preventDefault();
                                            gotoHighlight('Series');
                                            setHighlighterClass('Series');
                                        }}>Series</td> */}
                                        <td className={highlighterClass == 'InvestmentCalculator' ? "highlighter-active cursor" : "cursor"} onClick={(e: any) => {
                                            e.preventDefault();
                                            gotoHighlight('InvestmentCalculator');
                                            setHighlighterClass('InvestmentCalculator');
                                        }}>Investment Calculator</td>
                                        <td className={highlighterClass == 'AboutBond' ? "highlighter-active cursor" : "cursor"} onClick={(e: any) => {
                                            e.preventDefault();
                                            gotoHighlight('AboutBond');
                                            setHighlighterClass('AboutBond');
                                        }}>About Bond</td>
                                        <td className={highlighterClass == 'IssuerDetails' ? "highlighter-active cursor" : "cursor"} onClick={(e: any) => {
                                            e.preventDefault();
                                            gotoHighlight('IssuerDetails');
                                            setHighlighterClass('IssuerDetails');
                                        }}>Issuer Details</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sovereign_gold_bonds_detail_desc mt-0 pt-0 pb-0 pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                    <div className="container-fluid">
                        <div className="row align-items-center pt-200 detail-view-description box-sm-shadow">
                            <div className="d-flex flex_sm_column p-0">
                                <div className="col-lg-8 col-md-8 col-sm-8 col-12 pr_sm_0 ">
                                    <div className="d-flex  pb-3 align-items-center">
                                        <div className="image-logo-ipo mr-3">
                                            <img className="bond-title-img" src={bondsDetail?.bond_logo} alt="" />
                                        </div>
                                        <div className="w-auto ml-2 title-bond-detail mt-3">
                                            <h4>{bondsDetail?.bond_name}</h4>
                                            <div className="mt-1">
                                                <button className="return-btn">RETURNS Based On Market</button>
                                                {bondsDetail?.bond_isin_number != '' &&
                                                    <button className="isin-btn"><span>ISIN:&nbsp;</span>{bondsDetail?.bond_isin_number}&nbsp;
                                                        <span className="cursor" onClick={(e: any) => {
                                                            e.preventDefault();
                                                            copy(bondsDetail?.bond_isin_number!, {
                                                                debug: true,
                                                            });
                                                            toast.success('ISIN Number Copied To Clipboard');
                                                        }}>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <Copy />
                                                        </span>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 p-0" data-wow-delay="0.1s">
                                        <div className="d-flex flex-row bd-highlight mb-3">
                                            {bondsDetail != undefined && bondsDetail?.bond_eligible_investors.length > 0 &&
                                                <Fragment>
                                                    <div className="bd-highlight eligible-head">Eligible Investors*</div>
                                                    <div className="triangle-right"></div>
                                                </Fragment>
                                            }
                                            {bondsDetail != undefined &&  bondsDetail?.bond_eligible_investors.length > 0 && bondsDetail?.bond_eligible_investors.map((investor: APIData.BondEligibleInvestors) => {
                                                return (
                                                    <>
                                                    
                                                    {investor?.eligible_investors_available == 1 &&
                                                    <div className="bd-highlight eligible-sub-head">{investor.eligible_investor_name}</div>}
                                                    </>
                                                )
                                            })}
                                        </div>
                                        <div>
                                            <h5 className="table-title">Key Points To Consider</h5>
                                            <p className="table-desc">Face Value (In INR) and Issue Size (In INR Crores)</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-4 col-12 d-none d-sm-block">
                                    {bondsDetail != undefined && bondsDetail.bond_rating_details.length > 0 &&
                                        <h4 className="font-weight-700">Bond’s Rating</h4>
                                    }
                                    <div className="detail-view-meter" style={{ marginTop: "20px" }}>
                                        <div className="mb-2">
                                            <div className="row">
                                                {bondsDetail != undefined && bondsDetail.bond_rating_details.length > 0 && bondsDetail.bond_rating_details.map((rating: APIData.BondRatingDetails) => {
                                                    return (
                                                        <Fragment>
                                                            {
                                                                rating.bond_rating_image != null && rating.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=" &&

                                                                <div className="col-lg-6 col-md-12 col-sm-12 col-6">
                                                                    <img className="bond-ipo-detail-view-meter w-100" src={rating.bond_rating_image} alt="" />
                                                                    {rating?.bond_rating_agency !== "" || rating.bond_rating_name !== "" || rating.bond_rating_certificate !== "" &&
                                                                    <div>
                                                                        <table className="ipo-bond-download-table mt-1 w-100">
                                                                            <tr>
                                                                                <td className="sourceSansPro-bold">{rating?.bond_rating_agency?.substring(0,4)}</td>
                                                                                <td className="sourceSansPro-bold">{rating.bond_rating_name}</td>
                                                                                {rating.bond_rating_certificate != "" &&
                                                                                    <td className="sourceSansPro-bold cursor" onClick={(e: any) => {
                                                                                        e.preventDefault();
                                                                                        window.open(rating.bond_rating_certificate);
                                                                                    }}><Download /></td>
                                                                                }
                                                                            </tr>
                                                                        </table>
                                                                    </div>}
                                                                </div>


                                                            }
                                                        </Fragment>

                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className="row section-table table-font-p mt-2 ml-0">
                                <div className="col-lg-4 col-md-4 pr_sm_0">
                                    <div className="row row-first mr-lg-1">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <p>Rate of Int (Returns)</p>
                                            <h5>{bondsDetail?.bond_coupon_rate != null && bondsDetail?.bond_coupon_rate != '' ? bondsDetail?.bond_coupon_rate + "% p.a" : "N/A"}</h5>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <p>Yield</p>
                                            <h5>{bondsDetail?.bonds_yeild != null && bondsDetail?.bonds_yeild != '' ? bondsDetail?.bonds_yeild + "%" : "N/A"}</h5>
                                        </div>
                                    </div>
                                    <div className="row row-sec mr-lg-1 pb-1">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <p>Min. Investment</p>
                                            <h5>{bondsDetail?.bond_minimum_application != null && bondsDetail?.bond_minimum_application != '' ? "₹" + numberWithCommas(bondsDetail?.bond_minimum_application) : "N/A"}</h5>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <p>Face Value</p>
                                            <h5>{bondsDetail?.bond_face_value != null && bondsDetail?.bond_face_value != '' ? "₹" + numberWithCommas(bondsDetail?.bond_face_value) : "N/A"}</h5>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 pr_sm_0">
                                    <div className="row row-first mr-lg-1 ">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <p>Interest Payment Frequency</p>
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_ANNUAL &&
                                                <h5>Annual</h5>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                <h5>Monthly</h5>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                <h5>Quarterly</h5>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                <h5>Semi-Annual</h5>
                                            }
                                            {parseInt(bondsDetail?.bond_interest_frequency!) == 0 &&
                                                <h5>N/A</h5>
                                            }
                                            {bondsDetail?.bond_interest_frequency == null || bondsDetail?.frequency_of_interest_name == '' &&
                                                <h5>N/A</h5>
                                            }
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <p>Maturity Date</p>
                                            <h5>{bondsDetail?.bond_maturity_date != null && bondsDetail?.bond_maturity_date != '' ? moment(bondsDetail?.bond_maturity_date).format("MMM Do YYYY") : "N/A"}</h5>
                                        </div>
                                    </div>
                                    <div className="row row-sec mr-lg-1 pb-1">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <p>Instrument Type</p>
                                            <h5> {bondsDetail?.bond_nature_of_instrument !== null && bondsDetail?.bond_nature_of_instrument !== 0 ? (bondsDetail?.bond_nature_of_instrument == NatureofInstruments.TAXABLE ? "Taxable" : "Tax Free") : 'N/A'}</h5>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <p>Date Of Issue</p>
                                            <h5>{bondsDetail?.bond_issue_date != null && bondsDetail?.bond_issue_date != '' ? moment(bondsDetail?.bond_issue_date).format("MMM Do YYYY") : "N/A"}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 pr_sm_0">
                                    <div className="row row-first mr-lg-1">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <p>Call Option</p>
                                            {bondsDetail != undefined && bondsDetail.bond_call_details != undefined && bondsDetail.bond_call_details.length > 0 ?
                                                <Fragment>
                                                    <h5>Yes
                                                        {/* <span className="call-option" id="call" onClick={handleClick} >{moment(bondsDetail?.bond_call_details[0].bond_call_detail_datetime).format("MMM Do YYYY")} <img src="assets/img/call-option-info.svg" /></span> */}
                                                        </h5>
                                                </Fragment>
                                                :
                                                <h5>No</h5>
                                            }
                                        </div>
                                        {/* <Popover
                                            id="call"
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Typography sx={{ p: 2 }}>
                                                <div className="call-option-content">
                                                    <h5>Dates for Call Option</h5>
                                                    <h6>02 Oct 2022, 10.00am to 11.00am</h6>
                                                    <h6>02 Oct 2022, 10.00am to 11.00am</h6>
                                                    <h6 className="selected">02 Oct 2022, 10.00am to 11.00am</h6>
                                                    <div className="center-btn">
                                                        <button className="alert-button" onClick={(e: any) => {
                                                            e.preventDefault();
                                                            setAlertPopup(true);
                                                            handleClose();
                                                        }}>Set Alert<span><i className="fa fa-arrow-right" aria-hidden="true"></i></span></button>
                                                    </div>
                                                </div>
                                            </Typography>
                                        </Popover> */}
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <p>Put Option</p>
                                            {bondsDetail != undefined && bondsDetail.bond_put_details != undefined && bondsDetail.bond_put_details.length > 0 ?
                                                <Fragment>
                                                    <h5>Yes
                                                        {/* <span className="call-option" id="call" onClick={handleClick} >{moment(bondsDetail?.bond_put_details[0].bond_put_detail_datetime).format("MMM Do YYYY")} <img src="assets/img/call-option-info.svg" /></span> */}
                                                        </h5>
                                                </Fragment>
                                                :
                                                <h5>No</h5>
                                            }
                                        </div>
                                    </div>
                                    <div className="row row-sec pb-1 mr-lg-1">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <p>Mode Of Issue</p>

                                            {bondsDetail?.bond_issuer_details[0]?.bond_type_of_issuer == IssuerType.CENTRAL_GOVERNMENT &&
                                                <h6>Central Government</h6>
                                            }
                                            {bondsDetail?.bond_issuer_details[0]?.bond_type_of_issuer == IssuerType.PRIVATE_BANK &&
                                                <h6>Private Bank</h6>
                                            }
                                            {bondsDetail?.bond_issuer_details[0]?.bond_type_of_issuer == IssuerType.PRIVATE_SECTOR_CORPORATE &&
                                                <h6>Private Sector Corporate</h6>
                                            }
                                            {bondsDetail?.bond_issuer_details[0]?.bond_type_of_issuer == IssuerType.PUBLIC_SECTOR_BANK_STATE_GOVERNMENT &&
                                                <h6>Public Sector Bank State Government</h6>
                                            }
                                            {bondsDetail?.bond_issuer_details[0]?.bond_type_of_issuer == IssuerType.PUBLIC_SECTOR_UNDERTAKING &&
                                                <h6>Public Sector Undertaking</h6>
                                            }
                                            {bondsDetail?.bond_issuer_details[0]?.bond_type_of_issuer == null &&
                                                <h6>N/A</h6>
                                            }

                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <p>Listed</p>
                                            <h5>{bondsDetail?.bond_listing != null && bondsDetail?.bond_listing != undefined ? (bondsDetail?.bond_listing == listing.BSE ? 'BSE' : bondsDetail?.bond_listing == listing.NSE ? 'NSE': bondsDetail?.bond_listing == listing.BSE_NSE ? 'BSE & NSE' : "") : 'N/A'}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-12 d-block d-sm-none">
                                    {bondsDetail != undefined && bondsDetail.bond_rating_details.length > 0 &&
                                        <h4 className="font-weight-700">Bond’s Rating</h4>
                                    }
                                    <div className="detail-view-meter" style={{ marginTop: "20px" }}>
                                        <div className="mb-2">

                                            {bondsDetail != undefined && bondsDetail.bond_rating_details.length > 0 && bondsDetail.bond_rating_details.map((rating: APIData.BondRatingDetails) => {
                                                return (
                                                    <Fragment>
                                                        {
                                                            rating.bond_rating_image != null && rating.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=" &&
                                                            <>
                                                                <div className="row rating_responsive">
                                                                    <div className="col-lg-6 col-md-12 col-sm-6 col-5 meter_image">
                                                                        <img className="bond-ipo-detail-view-meter w-100" src={rating.bond_rating_image} alt="" />

                                                                    </div>
                                                                    <div className="col-lg-6 col-md-12 col-sm-6 col-7 bond_table">
                                                                        <table className="ipo-bond-download-table mt-1 w-100">
                                                                            <tr>
                                                                                <th>Ratting Agency</th>
                                                                                <th>Rating</th>
                                                                                <th>Rationale</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="sourceSansPro-bold">{rating?.bond_rating_agency?.substring(0,4)}</td>
                                                                                <td className="sourceSansPro-bold">{rating.bond_rating_name}</td>
                                                                                {rating.bond_rating_certificate != "" &&
                                                                                    <td className="sourceSansPro-bold cursor" onClick={(e: any) => {
                                                                                        e.preventDefault();
                                                                                        window.open(rating.bond_rating_certificate);
                                                                                    }}><Download /></td>
                                                                                }
                                                                            </tr>
                                                                        </table>
                                                                    </div>


                                                                </div>
                                                                <div className="d-flex justify-content-center p-0">
                                                                    <button className="detail-one-buy-button" onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        goToBuy(bondsDetail?.bond_isin_number!);
                                                                    }}><p className="click-me mt-5">Buy this BOND now&nbsp;&nbsp;&nbsp;&nbsp;<img width="12px" height="12px" src="assets/img/icon/rightarrow.png"></img></p></button>
                                                                </div>
                                                            </>

                                                        }
                                                    </Fragment>
                                                )
                                            })
                                            }

                                        </div>
                                    </div>

                                </div>
                                <div className="float-lg-end p-0 d-none d-sm-block">
                                    {(bondsDetail?.buy_sell_id == 1) &&
                                        <button className="detail-one-buy-button" onClick={(e: any) => {
                                            e.preventDefault();
                                            goToBuy(bondsDetail?.bond_isin_number!);
                                        }}><p className="click-me mt-5">Buy this BOND now&nbsp;&nbsp;&nbsp;&nbsp;<img width="12px" height="12px" src="assets/img/icon/rightarrow.png"></img></p></button>}
                                    {(bondsDetail?.buy_sell_id == 2) &&
                                        <button className="detail-one-buy-button " onClick={(e: any) => {
                                            e.preventDefault();
                                            goToBuy(bondsDetail?.bond_isin_number!);
                                        }}><p className="click-me mt-5 sell_btn_bond">Sell this BOND now&nbsp;&nbsp;&nbsp;&nbsp;<img width="12px" height="12px" src="assets/img/icon/rightarrow.png"></img></p></button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sovereign_gold_bonds_detail_desc mt-0 pt-0 pb-0 pb-md-20 pt-md-60 pt-xs-60 pb-xs-20" id="Highlights">
                    <div className="container-fluid">
                        <div className="row detail-view-calculation">
                            {bondsDetail != undefined && bondsDetail.bond_key_highlights != '' && bondsDetail.bond_key_highlights != '\n' &&
                                <div className="col-lg-6">
                                    <h3>Key Highlights</h3>
                                </div>
                            }
                            <div className="col-lg-8">
                                <div>
                                    <ul className="ipo-bond-list">
                                        {bondsDetail != undefined && bondsDetail.bond_key_highlights &&
                                            bondsDetail.bond_key_highlights.split('\n').map((str: any) => {
                                                {
                                                    if (str != '') {
                                                        return (
                                                            <li className="mb-4">{str}</li>
                                                        )
                                                    }
                                                }
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {bondsDetail != undefined && bondsDetail?.bond_ncd_series.length > 0 && bondsDetail?.bond_ncd_status !== 0 &&
                    <section className="sovereign_gold_bonds_detail_desc mt-0 pt-0 pb-0 pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                        <div className="" id="Series">
                            <div className="row detail-view-calculation">
                                <div className="col-lg-12 section-left mt-5">
                                    <h3 className="ml-2">Upcoming Terms for Each Series of NCDs</h3>
                                    <div className="table-responsive">
                                        <table className="w-100 bond-series-ncd mb-3 text-center">
                                            <thead>
                                                <tr>
                                                    <th className="right-br-ipo-specific-terms  br-top-12">Series</th>
                                                    <th className="right-br-ipo-specific-terms">Frequency of Interest Payment</th>
                                                    <th className="right-br-ipo-specific-terms">Tenor</th>
                                                    <th className="right-br-ipo-specific-terms">Coupon (% per annum)</th>
                                                    <th className="right-br-ipo-specific-terms">Effective Yield (% per annum)</th>
                                                    <th>Redemption Amount (₹ / NCD) on Maturity</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bondsDetail != undefined && bondsDetail?.bond_ncd_series.length > 0 && bondsDetail.bond_ncd_series.map((series: APIData.BondNcdSeries, index: number) => {
                                                    return (
                                                        <tr>
                                                            <td className="right-br-ipo-specific-terms ">{index + 1}</td>
                                                            <td className="right-br-ipo-specific-terms ">{series.bond_ncd_frequency_of_interest_payment}</td>
                                                            <td className="right-br-ipo-specific-terms ">{series.bond_ncd_tenor}</td>
                                                            <td className="right-br-ipo-specific-terms ">{series.bond_ncd_coupon + "%"}</td>
                                                            <td className="right-br-ipo-specific-terms ">{series.bond_ncd_effective_yield}</td>
                                                            <td className="right-br-ipo-specific-terms ">{numberWithCommas(series.bond_ncd_redemption_amount)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="mb-2 ipo-specific-terms-footer">*Company shall allocate and allot Series IV NCDs wherein the Applicants have not indicated the choice of the relevant NCD Series.</div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <section className="sovereign_gold_bonds_invest_calculation pb-md-0 pt-md-60 pt-sm-0 pt-xs-0 pb-xs-0">
                    <div className="container-fluid" id="InvestmentCalculator">
                        <div className="row detail-view-calculation">
                            <div className="col-lg-6 pl-30 mrd-none">
                                <h3 className="mt-5">Your Investment Calculator</h3>
                                <div className="center">
                                    <img className="detail-one-investment-cal-img" src="assets/img/calculator.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 section bg-light1">
                                <h3 className="mt-3 d-block d-sm-none">Your Investment Calculator</h3>

                                <div className="p-20">
                                    <div className="row d-flex section-head align-items-center">
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 p-0">
                                            <p className="title text-quick font-weight-700">Bond Value Details</p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 p-sm-none">
                                            <p className="sub-head d-flex justify-content-around">Face Value (A)</p>
                                            <h5 className="sub-value d-flex justify-content-around">₹ {numberWithCommas(bondsDetail?.bond_face_value)}</h5>
                                        </div>
                                        {/* <div className="col-lg-3 col-md-3 col-sm-3 col-3 p-sm-none">
                                            <p className="sub-head d-flex justify-content-around">Market Value (B)</p>
                                            <h5 className="sub-value d-flex justify-content-around">₹ {numberWithCommas(bondsDetail?.bond_face_value)}</h5>
                                        </div> */}
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 p-sm-none">
                                            <p className="sub-head d-flex justify-content-around">Yield (YTM)</p>
                                            <h5 className="sub-value d-flex justify-content-around">{bondsDetail?.bonds_yeild}%</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3 col-3 p-sm-none">
                                            <p className="sub-head d-flex justify-content-around">Min. Investment (B)</p>
                                            <h5 className="sub-value d-flex justify-content-around">₹ {numberWithCommas(bondsDetail?.bond_minimum_application)}</h5>
                                        </div>
                                    </div>
                                    <div className="section-form align-items-center form-sm-p pt-20">
                                        <div className="row row-form-res">
                                            <div className="col-lg-7 col-md-6 col-sm-6 col-6 w-xs-100 pt-10 pb-35">
                                                <div>
                                                    <h6>Number of bonds</h6>
                                                    <p>No. of Bonds you want to Purchase (B)</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 col-6 pt-10 pb-35 pl-0 pr-3 pr_sm_0 w-xs-100 p_sm_0">
                                                <div className="d-flex justify-content-between jcb-mobile flex-row">
                                                    <div className="">
                                                        <button onClick={(e: any) => {
                                                            e.preventDefault();
                                                            if (qty != 1) {
                                                                setQty(qty - 1);
                                                            }
                                                        }} className="detail-view-calculator-button">-</button>
                                                    </div>
                                                    <div className="">
                                                        <input value={qty} 
                                                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            e.preventDefault();
                                                            const inputValue = e.target.value;
                                                            const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove leading zeros
                                                            const parsedValue = parseInt(sanitizedValue, 10);
                                                            if (isNaN(parsedValue)) {
                                                              setQty(0);
                                                            } else {
                                                              setQty(parsedValue);
                                                            }
                                                          }}
                                                        className="input-value1 box-shadow sgb-calculator-input-1" />
                                                    </div>
                                                    <div className="">
                                                        <button onClick={(e: any) => {
                                                            e.preventDefault();
                                                            setQty(qty + 1);
                                                        }} className="detail-view-calculator-button">+</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row mb-20 row-form-res">
                                            <div className="col-lg-7 col-md-6 col-sm-6 col-6 pt-10 pb-10 w-xs-100">
                                                <div>
                                                    <h6>Principal Amount (E)</h6>
                                                    <p>Market value (B) x no. of bonds (C)</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 col-6 pt-10 pb-10 pr-4 w-xs-100 p_sm_0">
                                                <div className="row">
                                                    <input disabled={true} value={' ₹ ' + numberWithCommas(parseInt(bondsDetail?.bond_face_value!) * qty)} className="input-valu2 box-shadow" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-20 row-form-res">
                                            <div className="col-lg-7 col-md-6 col-sm-6 col-6 pt-10 pb-10 w-xs-100">
                                                <div>
                                                    <h6>Accrued Interest</h6>
                                                    <p>(Interest earned on the bond this year) (D)</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 col-6 pt-10 pb-10 pr-4 w-xs-100 p_sm_0">
                                                <div className="row">
                                                    <input disabled={true} value={' ₹ ' + numberWithCommas(parseInt(bondsDetail?.bond_face_value!) * qty * 5 / 100)} className="input-valu2 box-shadow" />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row mb-20 row-form-res">
                                            <div className="col-lg-7 col-md-6 col-sm-6 col-6 pt-10 pb-10 w-xs-100">
                                                <div>
                                                    <h6> {bondsDetail?.buy_sell_id == 1 && 'Total Investment'}{bondsDetail?.buy_sell_id == 2 && 'Total Return'}</h6>
                                                    <p>Face Value (A) x no. of bonds (B) </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-6 col-sm-6 col-6 pt-10 pb-10 pr-4 w-xs-100 p_sm_0">
                                                <div className="row">
                                                    <input disabled={true} value={' ₹ ' + numberWithCommas(parseInt(bondsDetail?.bond_minimum_application!) * qty)} className="input-valu2 box-shadow" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="mt-3 ml-3 text-center detail-one-buy-button">
                                                {(bondsDetail?.buy_sell_id == 1) &&
                                                    <button onClick={(e: any) => {
                                                        e.preventDefault();
                                                        goToBuy(bondsDetail?.bond_isin_number!);

                                                    }} className="bond-buy">Buy this BOND now&nbsp;&nbsp;&nbsp;</button>}
                                                {(bondsDetail?.buy_sell_id == 2) &&
                                                    <button onClick={(e: any) => {
                                                        e.preventDefault();
                                                        goToBuy(bondsDetail?.bond_isin_number!);

                                                    }} className="bond-buy sell_btn_bond">Sell this BOND now&nbsp;&nbsp;&nbsp;</button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {bondsDetailCashflow != undefined && bondsDetailCashflow.length > 0 &&
                        <div className="container-fluid">
                            <div className="row">
                                <p className="detail-view-calculation-dec-below" onClick={() => { toggleEarnDropdown() }}>WHAT WILL I EARN
                                    {openEarnDropDown === true ?
                                        <i className="fa fa-angle-up" aria-hidden="true"></i>
                                        :
                                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    }
                                </p>
                            </div>
                        </div>
                    }
                    {
                        openEarnDropDown === true && bondsDetailCashflow != undefined && bondsDetailCashflow.length > 0 &&
                        <section className="pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                            <div className="container-fluid">
                                <div className="row detail-view-Earning pt-5">
                                    <div className="w-40 m-auto m-20 section-left">
                                        <table className="table  table-bordered table1">
                                            <thead>
                                                <tr className="border-left">
                                                    <th scope="row" className="white-bg">
                                                        <h5>{moment().format("DD/MM/YYYY")}</h5>
                                                    </th>
                                                    <th colSpan={3} className="light-red-bg border-radius-right">
                                                        {/* <p className="text-end cursor" style={{ color: "#ed4f66" }} onClick={(e: any) => {
                                                            e.preventDefault();
                                                            window.open("https://trust-api.trustmony.com/api/v1/investment_rate?isin=" + isin)
                                                        }}><u>View Raw Data</u></p> */}
                                                        <p className="text-start">Your investment as of today</p>
                                                        <h5>{investmentToday}</h5>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bondsDetailCashflow != undefined && bondsDetailCashflow.length > 0 && bondsDetailCashflow.map((cashflow: APIData.BondCashFlow, index) => {
                                                    principalAmount = parseInt(cashflow.bond_cashflow_amount + investmentToday);
                                                    nextprincipleAmount += principalAmount;
                                                    InterstEarned += parseFloat(cashflow.bond_cashflow_amount);
                                                    totalreturnAmount += nextprincipleAmount;
                                                    const HalfYear = [];

                                                    for (let i = 1; i <= 2; i++) {
                                                        var eNow = new Date(cashflow.bond_cashflow_date);
                                                        var eMoment = moment(eNow);
                                                        var date = eMoment.subtract(i * 6, 'months').format("DD/MM/YYYY").toString()

                                                        HalfYear.push(
                                                            <tr className="sub-table-row">
                                                                <th scope="row">
                                                                    <h5>{date}</h5>
                                                                </th>
                                                                <td colSpan={1}>
                                                                    <h5>₹{numberWithCommas(cashflow.bond_cashflow_amount)}</h5>
                                                                </td>
                                                                <td colSpan={2}>
                                                                    <h5>₹{numberWithCommas(nextprincipleAmount)}</h5>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                    const Quarterly = [];

                                                    for (let i = 1; i <= 4; i++) {
                                                        var eNow = new Date(cashflow.bond_cashflow_date);
                                                        var eMoment = moment(eNow);
                                                        var date = eMoment.subtract(i * 4, 'months').format("DD/MM/YYYY").toString()

                                                        Quarterly.push(
                                                            <tr className="sub-table-row">
                                                                <th scope="row">
                                                                    <h5>{date}</h5>
                                                                </th>
                                                                <td colSpan={1}>
                                                                    <h5>₹{numberWithCommas(cashflow.bond_cashflow_amount)}</h5>
                                                                </td>
                                                                <td colSpan={2}>
                                                                    <h5>₹{numberWithCommas(nextprincipleAmount)}</h5>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                    const items = [];

                                                    for (let i = 1; i <= 12; i++) {
                                                        var eNow = new Date(cashflow.bond_cashflow_date);
                                                        var eMoment = moment(eNow);
                                                        var date = eMoment.subtract(i, 'months').format("DD/MM/YYYY").toString()

                                                        items.push(
                                                            <tr className="sub-table-row">
                                                                <th scope="row">
                                                                    <h5>{date}</h5>
                                                                </th>
                                                                <td colSpan={1}>
                                                                    <h5>₹{numberWithCommas(cashflow.bond_cashflow_amount)}</h5>
                                                                </td>
                                                                <td colSpan={2}>
                                                                    <h5>₹{numberWithCommas(nextprincipleAmount)}</h5>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                    return (
                                                        <>
                                                            <tr key={index} className="border-left">
                                                                <th scope="row" className="white-bg w-30">
                                                                    <h5>{(moment(cashflow.bond_cashflow_date).format('DD/MM/YYYY').toString())}</h5>
                                                                </th>
                                                                <td className="light-blue-bg">
                                                                    <p>Interest</p>
                                                                    <h5>₹ {numberWithCommas(cashflow.bond_cashflow_amount)}</h5>
                                                                </td>
                                                                {parseInt(bondsDetail?.bond_interest_frequency!) !== interestPaymentFrequency.FOR_ANNUAL &&
                                                                <td colSpan={bondsDetail?.bond_interest_frequency != 'NONE' ? 1 : 2} className="light-red-bg">
                                                                    <p>Returns</p>
                                                                    <h5>₹{numberWithCommas(nextprincipleAmount)}</h5>
                                                                </td>}
                                                                {bondsDetail?.bond_interest_frequency != 'NONE' && parseInt(bondsDetail?.bond_interest_frequency!) !== interestPaymentFrequency.FOR_ANNUAL &&
                                                                    <>
                                                                        <td className="light-blue-bg align-middle">
                                                                            <i aria-hidden="true" onClick={() => handleIndex(index)}
                                                                                className={`faq-question fa fa-chevron-down ${activeIndex === index && parseInt(bondsDetail?.bond_interest_frequency!) !== interestPaymentFrequency.FOR_ANNUAL ? 'active' : ''}`}
                                                                            ></i>
                                                                        </td>
                                                                    </>
                                                                }

                                                            </tr>
                                                            {activeIndex === index && (
                                                                <>
                                                                    {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                                        <>
                                                                            {items}
                                                                        </>
                                                                    }
                                                                    {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                                       <>{Quarterly}</>
                                                                    }
                                                                    {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                                        <>{HalfYear}</>
                                                                    }
                                                                    {parseInt(bondsDetail?.bond_interest_frequency!) == 0 &&
                                                                        <h5>N/A</h5>
                                                                    }
                                                                    {bondsDetail?.bond_interest_frequency == null || bondsDetail?.frequency_of_interest_name == '' &&
                                                                        <h5>N/A</h5>
                                                                    }


                                                                </>
                                                            )}
                                                        </>
                                                    )
                                                })}


                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-lg-6 m-20 section-right">
                                        <img className="detail-view-Earning-flow-img" src="assets/img/earn_flow.svg" alt="" />
                                        {/* <div className="row m-2 d-flex">
                                            <div className="col-lg-6 p-0">
                                                <div className="p-2 m-2 interest-earned">
                                                    <p>Interest Earned</p>
                                                    <h4>₹ {numberWithCommas(InterstEarned + "")}</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 p-0">
                                                <div className="p-2 m-2 total-return">
                                                    <p>Total Returns</p>
                                                    <h4>₹{numberWithCommas(totalreturnAmount)}</h4>
                                                </div>
                                            </div>
                                        </div> */}
                                        <p className="desc"><i>Disclaimer: The actual number can vary slightly based on actual Interest Payment date.</i></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                </section>

                {bondsDetail?.bond_about_this_ipo != null && bondsDetail?.bond_about_this_ipo !== "" &&
                    <div className="sovereign_gold section-left mt-5 mb-3" id="AboutBond">
                        <h3 className="font-36">About this Bond</h3>
                        <p className="fs-18 mt-2 text-quick p-0 font-weight-600">{bondsDetail?.bond_about_this_ipo}</p>
                    </div>
                }
                {bondsDetail != undefined && bondsDetail.bond_issuer_details != undefined && bondsDetail.bond_issuer_details.length > 0 &&
                    <section className="sovereign_gold pb-30 pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                        <div id="IssuerDetails">
                            <div className="row detail-view-financial">
                                <div className="d-flex">
                                    <h6 className="ipo-flow-issuer-title">Issuer</h6>
                                    <span className="triangle-right-ipo"></span>
                                </div>
                                <div className="col-lg-6 section-left">
                                    <h3>{bondsDetail?.bond_issuer_name}</h3>
                                    <p className="underline"></p>
                                    <h6 className="fs-18 text-quick p-sm-none">Issuer's Type:{bondsDetail?.bond_issuer_details[0].bond_type_of_issuer}</h6>
                                    <p className="font-bold">{bondsDetail?.bond_issuer_details[0].bond_issuer_about}</p>
                                </div>
                                <div className="col-lg-6">
                                    <p className="section-right-border-length"></p>
                                    <div className="section-right">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-4 pt-10 pb-10 p-sm-none">
                                                <div>
                                                    <p className="fs-12 p-0 text-quick">Total Annual Revenue</p>
                                                    <h6>{bondsDetail?.bond_issuer_details[0].bond_issuer_total_annual_revenue != null && bondsDetail?.bond_issuer_details[0].bond_issuer_total_annual_revenue != '' ? "₹" + numberWithCommas(bondsDetail?.bond_issuer_details[0].bond_issuer_total_annual_revenue) : "N/A"}</h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-4 pt-10 pb-10 p-sm-none">
                                                <div>
                                                    <p className="fs-12 p-0 text-quick">Year of Inception</p>
                                                    <h6>{bondsDetail?.bond_issuer_details[0].bond_issuer_year_of_inception != null ? bondsDetail?.bond_issuer_details[0].bond_issuer_year_of_inception : "N/A"}</h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-3 pt-10 pb-10 p-sm-none">
                                                <div>
                                                    <p className="fs-12 p-0 text-quick">Industry</p>
                                                    <h6>{bondsDetail?.bond_issuer_details[0].bond_issuer_industry != null && bondsDetail?.bond_issuer_details[0].bond_issuer_industry != '' ? bondsDetail?.bond_issuer_details[0].bond_issuer_industry : "N/A"}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-4 pt-10 pb-10 p-sm-none">
                                                <div>
                                                    <p className="fs-12 p-0 text-quick">Head Office</p>
                                                    <h6>{bondsDetail?.bond_issuer_details[0].bond_issuer_head_office != null && bondsDetail?.bond_issuer_details[0].bond_issuer_head_office != '' ? bondsDetail?.bond_issuer_details[0].bond_issuer_head_office : "N/A"}</h6>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-4 pt-10 pb-10 p-sm-none">
                                                <div>
                                                    <p className="fs-12 p-0 text-quick">Type of Issuer</p>
                                                    {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.CENTRAL_GOVERNMENT &&
                                                        <h6>Central Government</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PRIVATE_BANK &&
                                                        <h6>Private Bank</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PRIVATE_SECTOR_CORPORATE &&
                                                        <h6>Private Sector Corporate</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PUBLIC_SECTOR_BANK_STATE_GOVERNMENT &&
                                                        <h6>Public Sector Bank State Government</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PUBLIC_SECTOR_UNDERTAKING &&
                                                        <h6>Public Sector Undertaking</h6>
                                                    }
                                                    {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == null &&
                                                        <h6>N/A</h6>
                                                    }
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-4 pt-10 pb-10">
                                                <div>
                                                    <p>Current MD/CEO</p>
                                                    <h6>{bondsDetail?.bond_issuer_details[0].bond_type_of_issuer}</h6>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6 col-4 pt-10 pb-10 cursor p-sm-none" onClick={(e: any) => {
                                                e.preventDefault();
                                                window.open(bondsDetail?.bond_issuer_details[0].bond_issuer_profile_link);
                                            }}>
                                                <a><u className="text-quick">Issuers Profile</u> <i className="fa fa-external-link" aria-hidden="true"></i></a>
                                            </div>
                                            <div className="col-lg-7 col-md-7 col-sm-6 col-8 pt-10 pb-10" onClick={(e: any) => {
                                                e.preventDefault();
                                                window.open(bondsDetail?.bond_issuer_details[0].bond_issuer_finance_details)
                                            }}>
                                                <a><u className="text-quick">Issuers Finance Details</u> <i className="fa fa-external-link" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                {bondsDetail?.bond_issuer_details[0]?.bond_issuer_documents != '' && bondsDetail?.bond_issuer_details[0]?.bond_issuer_documents != null &&
                    <DownloadIssueDocuments document={bondsDetail?.bond_issuer_documents} />
                }
                <InvestSteps />
                <Subscribe />
                <NeedHelp />
                {/* <NeedHelp /> */}
                <Alert showModal={alertPopup} closeModal={() => { setAlertPopup(false) }} />
            </main>
            <Footer />
        </div >
    )
}

export default DetailViewOne;