import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { interestPaymentFrequency, IssuerType, listing, NatureofInstruments, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { numberWithCommas } from "../common/Utilities";
import DownloadIssueDocuments from "../components/DownloadIssueDocuments";
import InvestSteps from "../components/InvestSteps";
import NeedHelp from "../components/NeedHelp";
import Subscribe from "../components/Subscribe";
import Copy from "../components/Svg/Copy";
import Download from "../components/Svg/Download"
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import copy from 'copy-to-clipboard';
import { toast } from "react-hot-toast";
import MetaDecorator from "../components/MetaDecorator";

const BondIPOFlow = () => {
    const location = useLocation();
    const [id, setId] = useState(0);
    const [bondsDetail, setBondDetail] = useState<APIData.IPODetail>();
    const [investmentValues, setInvestmentValues] = useState<APIData.InvestmentCalculator>();
    const [bondsDetailCashflow, setBondDetailCashflow] = useState<APIData.BondCashFlow[]>([]);
    const { setShowPreloader } = useAppStateAPI();
    const [highlighterClass, setHighlighterClass] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("id")) {
                const id = query.get("id");
                setId(parseInt(id!));
            }
        }
    }, [location]);

    const goToItemPurchase = (id: number) => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            navigate({
                pathname: PageLinks.BOND_IPO_PURCHASE,
                search: "?id=" + id,
            });
        } else {
            localStorage.setItem('redirection', 'IPO');
            localStorage.setItem('ipoid', id + "");
            navigate(PageLinks.LOGIN);
        }
    }

    useEffect(() => {
        if (id != 0) {
            setShowPreloader(true);
            ServerAPI.GetIPODetail(id).then((response: any) => {
                if (response != undefined) {
                    setBondDetail(response);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [id])

    const gotoHighlight = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        }
    };
    return (
        <div className="main-index-wrapper">
            <Header />
            {/* <MetaDecorator title={bondsDetail?.bond_name!} description={"High Interest Rate Bonds"} image={bondsDetail?.bond_logo ? bondsDetail?.bond_logo : "assets/img/img_placeholder.png"} content={"https://trustmony.com"} /> */}
            <div className="body-overlay"></div>
            <main>
                <section className="sovereign_gold_bonds pt-100 pt-md-100 pb-md-0 pt-sm-100 pt-xs-45 ">
                    <div className="container-fluid ">
                        <div className="">
                            <div className="row pt-3">
                                <div className="col-lg-12">
                                    <div className="row align-items-left">
                                        <div className="col-lg-6 breadcums pt-4 ">
                                            <nav>
                                                <ul>
                                                    <li>Home</li>
                                                    <li>Bonds</li>
                                                    <li>Explore Bonds</li>
                                                    <li className="active">{bondsDetail?.bond_name}</li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid d_highlight_none">
                                <div className="ipo-nav-top ml-1">
                                    <table className="ipo-nav-table">
                                        <tr>
                                            {bondsDetail != undefined && bondsDetail.bond_key_highlights != '' &&
                                                <td className={highlighterClass == 'Highlights' ? "highlighter-active cursor" : "cursor"} onClick={(e: any) => {
                                                    e.preventDefault();
                                                    gotoHighlight('Highlights');
                                                    setHighlighterClass('Highlights');
                                                }}>Key Highlights</td>
                                            }
                                            {bondsDetail != undefined && bondsDetail?.bond_ncd_series.length > 0 &&
                                                <td className={highlighterClass == 'Series' ? "highlighter-active cursor" : "cursor"} onClick={(e: any) => {
                                                    e.preventDefault();
                                                    gotoHighlight('Series');
                                                    setHighlighterClass('Series');
                                                }}>Series</td>
                                            }
                                            {bondsDetail != undefined && bondsDetail?.bond_about_this_ipo != '' &&
                                                <td className={highlighterClass == 'AboutIPO' ? "highlighter-active cursor" : "cursor"} onClick={(e: any) => {
                                                    e.preventDefault();
                                                    gotoHighlight('AboutIPO');
                                                    setHighlighterClass('AboutIPO');
                                                }}>About IPO</td>
                                            }
                                            {bondsDetail != undefined && bondsDetail?.bond_issuer_details.length > 0 &&
                                                <td className={highlighterClass == 'IssuerDetails' ? "highlighter-active cursor" : "cursor"} onClick={(e: any) => {
                                                    e.preventDefault();
                                                    gotoHighlight('IssuerDetails');
                                                    setHighlighterClass('IssuerDetails');
                                                }}>Issuer Details</td>
                                            }
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row py-3 mx-4 px-lg-4 detail-view-calculation card-box">

                            <div className="col-lg-6 col-md-12 col-sm-12 col-12 box-sm-shadow">
                                <div className="d-flex align-items-center">
                                    <div className="image-logo-ipo mr-3">
                                        <img src={bondsDetail?.bond_logo != '' ? bondsDetail?.bond_logo : "assets/img/img_placeholder.png"} alt="ipo" className="bond-title-img" />
                                    </div>
                                    <div className="title-bond-detail">
                                        <div className="d-flex my-2">
                                            <p className="title"> {bondsDetail?.bond_name}</p>
                                        </div>
                                        {bondsDetail?.bond_isin_number != null &&
                                            <div className="d-flex my-2">
                                                <span className="background-grey title-background-style d-flex">
                                                    <p className="fs-12 px-2 py-1"> <span>ISIN:&nbsp;</span>{bondsDetail?.bond_isin_number != null && bondsDetail?.bond_isin_number != '' ? bondsDetail?.bond_isin_number : "N/A"}&nbsp;
                                                        <span className="cursor" onClick={(e: any) => {
                                                            e.preventDefault();
                                                            copy(bondsDetail?.bond_isin_number!, {
                                                                debug: true,
                                                            });
                                                            toast.success('ISIN Number Copied To Clipboard');
                                                        }}>
                                                            <Copy />
                                                        </span> </p>
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 mt-3 p-0" data-wow-delay="0.1s">
                                    {bondsDetail != undefined && bondsDetail.bond_eligible_investors.length > 0 &&
                                        <div className="d-flex flex_wrap align-items-center">
                                            <a className="background-lightblack text-white sourceSansPro-regular px-2 bond-investor-tag" >
                                                Eligible Investors*
                                            </a> <span className="triangle-right-ipo-tag"></span>
                                            {bondsDetail.bond_eligible_investors.map((investor: APIData.BondEligibleInvestors) => {
                                                return (
                                                    <>
                                                    {investor?.eligible_investors_available !== null &&
                                                    <div className="background-grey px-2 mx-2 sourceSansPro-regular bond-investor-sub-tags">
                                                        {investor.eligible_investor_name}
                                                    </div>}
                                                    </>
                                                )
                                            })}
                                        </div>
                                    }

                                    <div className=" mt-3 mb-2">
                                        <p className="title"> Key Points To Consider</p>
                                        <span className="sourceSansPro-regular key-to-consider">Face Value (In INR) and Issue Size (In INR Crores)</span>
                                    </div>
                                </div>

                                <div className="mb-2">
                                    <table className="w-100 bond-table">
                                        <tr>
                                            <td className="table-td-bond">
                                                <p className="table-text-light sourceSansPro-regular">Issue on</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bond_opening_date != null && bondsDetail?.bond_opening_date != '' ? moment(bondsDetail?.bond_opening_date).format("MMM Do YYYY") : "N/A"}</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-regular">Issue Closes on</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bond_closing_date != null && bondsDetail?.bond_closing_date != '' ? moment(bondsDetail?.bond_closing_date).format("MMM Do YYYY") : "N/A"}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td >
                                                <p className="table-text-light sourceSansPro-regular">Rate of Int (Returns)</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bond_coupon_rate != null && bondsDetail?.bond_coupon_rate != '' ? bondsDetail?.bond_coupon_rate + "% p.a" : "N/A"}</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-regular">Issue Size</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bond_issue_size != null && bondsDetail?.bond_issue_size != '' ? bondsDetail?.bond_issue_size : "N/A"}</p>
                                            </td>
                                            {/* <td>
                                                <p className="table-text-light sourceSansPro-regular">Yield</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bonds_yeild != null && bondsDetail?.bonds_yeild != '' ? bondsDetail?.bonds_yeild + "%" : "N/A"}</p>
                                            </td> */}
                                        </tr>
                                    </table>
                                </div>

                                <div className="mb-2">
                                    <table className="w-100 bond-table">
                                        <tr>
                                            <td className="table-td-bond">
                                                <p className="table-text-light sourceSansPro-regular">Face Value</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bond_face_value != null && bondsDetail?.bond_face_value != '' ? "₹" + numberWithCommas(bondsDetail?.bond_face_value) : "N/A"}</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-regular">MIN. INVESTMENT</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bond_minimum_application != null && bondsDetail?.bond_minimum_application != '' ? "₹" + numberWithCommas(bondsDetail?.bond_minimum_application) : "N/A"}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="table-text-light sourceSansPro-regular">Interest Payment Freq.</p>
                                                <p className="sourceSansPro-bold">
                                                    {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_ANNUAL &&
                                                        'Annual'
                                                    }
                                                    {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_MONTHLY &&
                                                        'Monthly'
                                                    }
                                                    {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_QUARTERLY &&
                                                        'Quarterly'
                                                    }
                                                    {parseInt(bondsDetail?.bond_interest_frequency!) == interestPaymentFrequency.FOR_SEMI_ANNUAL &&
                                                        'Semi - Annual'
                                                    }
                                                    {parseInt(bondsDetail?.bond_interest_frequency!) == 0 &&
                                                        'N / A'
                                                    }
                                                    {bondsDetail?.bond_interest_frequency == null || bondsDetail.bond_interest_frequency == '' &&
                                                        ' N / A'
                                                    }</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-regular">Maturity Date</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bond_maturity_date != null && bondsDetail?.bond_maturity_date != '' ? moment(bondsDetail?.bond_maturity_date).format("MMM Do YYYY") : "N/A"}</p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="mb-2">
                                    <table className="w-100 bond-table">
                                        <tr>
                                            <td className="table-td-bond">
                                                <p className="table-text-light sourceSansPro-regular">Tax Free</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bond_nature_of_instrument == NatureofInstruments.TAXABLE ? "Taxable" : "Tax Free"}</p>
                                            </td>
                                            <td>
                                                <p className="table-text-light sourceSansPro-regular">SERIES</p>
                                                <p className="sourceSansPro-bold">{bondsDetail?.bond_issuer_details[0].bond_issuer_series != null && bondsDetail?.bond_issuer_details[0].bond_issuer_series != '' ? bondsDetail?.bond_issuer_details[0].bond_issuer_series : "N/A"}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="table-text-light sourceSansPro-regular">Listing</p>
                                                {bondsDetail?.bond_listing == listing.BSE &&
                                                    <p className="sourceSansPro-bold">BSE</p>
                                                }
                                                {bondsDetail?.bond_listing == listing.NSE &&
                                                    <p className="sourceSansPro-bold">NSE</p>
                                                }
                                                {bondsDetail?.bond_listing == listing.BSE_NSE &&
                                                    <p className="sourceSansPro-bold">BSE & NSE</p>
                                                }
                                            </td>
                                            
                                        </tr>
                                    </table>
                                </div>
                                <div className="mb-2 ipo-category-table table-responsive">
                                    <table className="w-100  bond-padding">
                                        <tr>
                                            <td colSpan={2} className="p-2 p-sm-0">
                                                <table className="w-100 bond-ipo-sub-table ">
                                                    <thead>
                                                        <tr>
                                                            <th className="sourceSansPro-regular ">Category</th>
                                                            <th className="sourceSansPro-regular">I - Institutional</th>
                                                            <th className="sourceSansPro-regular">II - Non-Institutiona1</th>
                                                            <th className="sourceSansPro-regular">III - HNI</th>
                                                            <th className="sourceSansPro-regular">IV - Retail</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="sourceSansPro-semibold">Category Allocation</td>
                                                            <td className="sourceSansPro-semibold">{bondsDetail?.bond_category_institutional != null && bondsDetail?.bond_category_institutional != '' ? bondsDetail?.bond_category_institutional + "%" : "N/A"}</td>
                                                            <td className="sourceSansPro-semibold">{bondsDetail?.bond_category_non_institutional != null && bondsDetail?.bond_category_non_institutional != '' ? bondsDetail?.bond_category_non_institutional + "%" : "N/A"}</td>
                                                            <td className="sourceSansPro-semibold">{bondsDetail?.bond_category_hni != null && bondsDetail?.bond_category_hni != '' ? bondsDetail?.bond_category_hni + "%" : "N/A"}</td>
                                                            <td className="sourceSansPro-semibold">{bondsDetail?.bond_category_retail != null && bondsDetail?.bond_category_retail != '' ? bondsDetail?.bond_category_retail + "%" : "N/A"}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt_sm_10 d-block d-sm-none">
                                    {bondsDetail != undefined && bondsDetail != null && bondsDetail.bond_rating_details.length > 0 &&
                                        <h4 className="rating-text mt-2">IPO’s Rating</h4>
                                    }
                                    <div className="detail-view-meter" style={{ marginTop: "10px" }}>
                                        <div className="row mb-2">

                                            {bondsDetail != undefined && bondsDetail.bond_rating_details.length > 0 && bondsDetail.bond_rating_details.map((rating: APIData.BondRatingDetails) => {
                                                return (
                                                    <Fragment>
                                                        <div className="col-lg-4 col-md-4 col-sm-4 col-6 px-xs-0 d-flex">
                                                            <div className="mx-2">
                                                                <img className="bond-ipo-detail-view-meter w-100" src={rating.bond_rating_image} alt="" />

                                                                <table className="ipo-bond-download-table mt-2 w-100">
                                                                    <tr>
                                                                        <td className="sourceSansPro-bold">{rating.bond_rating_agency != null && rating.bond_rating_agency != '' ? rating.bond_rating_agency : "N/A"}</td>
                                                                        <td className="sourceSansPro-bold">{rating.bond_rating_agency != null && rating.bond_rating_agency != '' ? rating.bond_rating_agency : "N/A"}</td>
                                                                        {/* <td className="sourceSansPro-bold">{rating.bond_crisil_long_rating}</td> */}
                                                                        {rating.bond_rating_certificate != "" &&
                                                                            <td className="sourceSansPro-bold cursor" onClick={(e: any) => {
                                                                                e.preventDefault();
                                                                                window.open(rating.bond_rating_certificate);
                                                                            }}><Download /></td>
                                                                        }
                                                                    </tr>
                                                                </table>

                                                            </div>

                                                        </div>
                                                    </Fragment>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 p-0 detail-view-description d-flex justify-content-center">
                                            <button className="detail-one-buy-button" onClick={(e: any) => {
                                                e.preventDefault();
                                                goToItemPurchase(bondsDetail?.bond_id!);
                                            }}><p className="click-me mx-2">Subscribe This IPO now&nbsp;&nbsp;&nbsp;&nbsp;</p></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-2 d-block d-sm-none padding-responsive" id="Highlights">
                                {bondsDetail != undefined && bondsDetail.bond_key_highlights != '' &&
                                    <div className=" mt-4 mb-lg-5 mb-sm-2">
                                        <p className="ipo-bond-right-title">Key Highlights</p>
                                    </div>
                                }
                                <div>
                                    <ul className="ipo-bond-list">
                                        {bondsDetail != undefined && bondsDetail.bond_key_highlights &&
                                            bondsDetail.bond_key_highlights.split('\n').map((str: any) => {
                                                {
                                                    if (str != '') {
                                                        return (
                                                            <li className="mb-3">{str}</li>
                                                        )
                                                    }
                                                }
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12 box-sm-shadow mt_sm_10 d-none d-sm-block padding-responsive">
                                {bondsDetail != undefined && bondsDetail != null && bondsDetail.bond_rating_details.length > 0 &&
                                    <h4 className="rating-text mt-2">IPO’s Rating</h4>
                                }
                                <div className="detail-view-meter" style={{ marginTop: "10px" }}>
                                    <div className="row mb-2">

                                        {bondsDetail != undefined && bondsDetail.bond_rating_details.length > 0 && bondsDetail.bond_rating_details.map((rating: APIData.BondRatingDetails) => {
                                            return (
                                                <Fragment>
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-6 px-xs-0 d-flex">
                                                        <div className="mx-2">
                                                            <img className="bond-ipo-detail-view-meter w-100" src={rating.bond_rating_image} alt="" />

                                                            <table className="ipo-bond-download-table mt-2 w-100">
                                                                <tr>
                                                                    <td className="sourceSansPro-bold">{rating.bond_rating_agency != null && rating.bond_rating_agency != '' ? rating.bond_rating_agency : "N/A"}</td>
                                                                    <td className="sourceSansPro-bold">{rating.bond_rating_agency != null && rating.bond_rating_agency != '' ? rating.bond_rating_agency : "N/A"}</td>
                                                                    {/* <td className="sourceSansPro-bold">{rating.bond_crisil_long_rating}</td> */}
                                                                    {rating.bond_rating_certificate != "" &&
                                                                        <td className="sourceSansPro-bold cursor" onClick={(e: any) => {
                                                                            e.preventDefault();
                                                                            window.open(rating.bond_rating_certificate);
                                                                        }}><Download /></td>
                                                                    }
                                                                </tr>
                                                            </table>

                                                        </div>

                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                        }


                                        <div className="col-lg-12 mt-2 padding-responsive" id="Highlights">
                                            {bondsDetail != undefined && bondsDetail.bond_key_highlights != '' &&
                                                <div className=" mt-4 mb-5">
                                                    <p className="ipo-bond-right-title">Key Highlights</p>
                                                </div>
                                            }
                                            <div>
                                                <ul className="ipo-bond-list">
                                                    {bondsDetail != undefined && bondsDetail.bond_key_highlights &&
                                                        bondsDetail.bond_key_highlights.split('\n').map((str: any) => {
                                                            {
                                                                if (str != '') {
                                                                    return (
                                                                        <li className="mb-3">{str}</li>
                                                                    )
                                                                }
                                                            }
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div className="row">
                                                <div className="float-lg-end p-0 detail-view-description">
                                                    <button className="detail-one-buy-button" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        goToItemPurchase(bondsDetail?.bond_id!);
                                                    }}><p className="click-me mx-2">Subscribe This IPO now&nbsp;&nbsp;&nbsp;&nbsp;<img width="10px" height="10px" src="assets/img/icon/rightarrow.png"></img></p></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 section-left mt-lg-5 mt-2 padding-responsive" id="Series">
                                {bondsDetail != undefined && bondsDetail?.bond_ncd_series.length > 0 &&
                                    <Fragment>
                                        <h3 className="pb-2">Specific Terms for Each Series of NCDs</h3>
                                        <div className="table-responsive">

                                            <table className={bondsDetail?.bond_ncd_series.length == 6 ? "w-100 mt-2 ipo-specific-terms mb-2 text-center  bond-padding-2" : "mt-2 ipo-specific-terms bond-padding-2 mb-2 text-center w-100"}>
                                                <thead>
                                                    <tr>
                                                        <th className="br-top-12 pl-3">Series</th>

                                                        <th className="right-br-ipo-specific-terms br-bt mt-1">I</th>
                                                        <th className="right-br-ipo-specific-terms br-bt mt-1">II</th>
                                                        <th className="right-br-ipo-specific-terms br-bt mt-1">III</th>
                                                        <th className="right-br-ipo-specific-terms br-bt mt-1">IV</th>
                                                        <th className="right-br-ipo-specific-terms br-bt mt-1">V</th>
                                                        <th className=" br-bt mt-1">VI</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="table-td-ipo">Frequency of Interest Payment</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            if (ncdseries.bond_ncd_frequency_of_interest_payment == interestPaymentFrequency.FOR_ANNUAL) {
                                                                return (
                                                                    <td className="right-br-ipo-specific-terms br-bt">Annual</td>
                                                                )
                                                            }
                                                            if (ncdseries.bond_ncd_frequency_of_interest_payment == interestPaymentFrequency.FOR_MONTHLY) {
                                                                return (
                                                                    <td className="right-br-ipo-specific-terms br-bt">Monthly</td>
                                                                )
                                                            }
                                                            if (ncdseries.bond_ncd_frequency_of_interest_payment == interestPaymentFrequency.FOR_QUARTERLY) {
                                                                return (
                                                                    <td className="right-br-ipo-specific-terms br-bt">Quarterly</td>
                                                                )
                                                            }
                                                            if (ncdseries.bond_ncd_frequency_of_interest_payment == interestPaymentFrequency.FOR_SEMI_ANNUAL) {
                                                                return (
                                                                    <td className="right-br-ipo-specific-terms br-bt">Semi-Annual</td>
                                                                )
                                                            }
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Minimum Application</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt">{numberWithCommas(ncdseries.bond_ncd_minimum_application)}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>In Multiples of thereafter (₹)</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt">{ncdseries.bond_ncd_in_multiple_terms_of_thereafter}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Face Value/ Issue Price of NCDs (`/ NCD)</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt">{numberWithCommas(ncdseries.bond_ncd_issue_price_of_ncd)}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Tenor</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt">{ncdseries.bond_ncd_tenor + " months "}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Coupon (% per annum)</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg">{ncdseries.bond_ncd_coupon}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Effective Yield (% per annum)</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg">{ncdseries.bond_ncd_effective_yield}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Mode of Interest Payment</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg">{ncdseries.bond_ncd_mode_of_interest_payment}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Redemption Amount (₹ / NCD) on Maturity</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms br-bt-bg">{numberWithCommas(ncdseries.bond_ncd_redemption_amount)}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td className="br-bottom-12">Put and Call Option</td>
                                                        {bondsDetail.bond_ncd_series.map((ncdseries: APIData.BondNcdSeries) => {
                                                            return (
                                                                <td className="right-br-ipo-specific-terms">{ncdseries.bond_ncd_put_and_option}</td>
                                                            )
                                                        })
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </Fragment>
                                }
                                <div className="mb-2 ipo-specific-terms-footer mt-2">*Company shall allocate and allot Series IV NCDs wherein the Applicants have not indicated the choice of the relevant NCD Series.</div>
                                <div>
                                    <div className="text-center p-0 detail-view-description mt-4 ">
                                        <button className="detail-one-buy-button p-0" onClick={(e: any) => {
                                            e.preventDefault();
                                            goToItemPurchase(bondsDetail?.bond_id!);
                                        }}><p className="click-me terms">Subscribe This IPO now&nbsp;<img width="10px" height="10px" src="assets/img/icon/rightarrow.png" className="mx-3"></img></p></button>
                                    </div>
                                </div>
                            </div>


                            {bondsDetail?.bond_about_this_ipo != '' &&
                                <div className="col-lg-12 section-left mt-5" id="AboutIPO">
                                    <h3>About this IPO</h3>
                                    <p>{bondsDetail?.bond_about_this_ipo}</p>
                                </div>
                            }
                            {bondsDetail != undefined && bondsDetail?.bond_issuer_details.length > 0 && bondsDetail?.bond_issuer_details[0] != undefined &&
                                <section className="pt-30 pb-30 pb-md-20 pt-md-60 pt-xs-60 pb-xs-20 pt-sm-0">
                                    <div id="IssuerDetails">
                                        <div className="row detail-view-financial">
                                            <div className="d-flex pb-10">
                                                <h6 className="ipo-flow-issuer-title text-issuer">Issuer</h6>
                                                <span className="triangle-right-ipo"></span>
                                            </div>
                                            <div className="col-lg-6 section-left text-name">
                                                <h3>{bondsDetail?.bond_issuer_name}</h3>
                                                <p className="underline"></p>
                                                {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.CENTRAL_GOVERNMENT &&
                                                    <h6>Issuer's Type: <span className="span_text mx-2"> Central Government</span></h6>
                                                }
                                                {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PRIVATE_BANK &&
                                                    <h6>Issuer's Type:<span className="span_text mx-2">Private Bank</span></h6>
                                                }
                                                {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PRIVATE_SECTOR_CORPORATE &&
                                                    <h6>Issuer's Type:<span className="span_text mx-2">Private Sector Corporate</span></h6>
                                                }
                                                {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PUBLIC_SECTOR_BANK_STATE_GOVERNMENT &&
                                                    <h6>Issuer's Type:<span className="span_text mx-2">Public Sector Bank State Government</span></h6>
                                                }
                                                {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PUBLIC_SECTOR_UNDERTAKING &&
                                                    <h6>Issuer's Type:<span className="span_text mx-2">Public Sector Undertaking</span></h6>
                                                }
                                                {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == null &&
                                                    <h6>Issuer's Type:<span className="span_text mx-2">N/A</span></h6>
                                                }
                                                <p className="font-bold">{bondsDetail?.bond_issuer_details[0].bond_issuer_about}</p>
                                            </div>
                                            <div className="col-lg-6">
                                                <p className="section-right-border-length"></p>
                                                <div className="section-right">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-4 pt-10 pb-10">
                                                            <div>
                                                                <p>Total Annual Revenue</p>
                                                                <h6>{bondsDetail?.bond_issuer_details[0].bond_issuer_total_annual_revenue != null && bondsDetail?.bond_issuer_details[0].bond_issuer_total_annual_revenue != '' ? "₹" + numberWithCommas(bondsDetail?.bond_issuer_details[0].bond_issuer_total_annual_revenue) : "N/A"}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-4 pt-10 pb-10">
                                                            <div>
                                                                <p>Year of Inception</p>
                                                                <h6>{bondsDetail?.bond_issuer_details[0].bond_issuer_year_of_inception != null ? bondsDetail?.bond_issuer_details[0].bond_issuer_year_of_inception : "N/A"}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4  col-sm-6 col-4 pt-10 pb-10">
                                                            <div>
                                                                <p>Industry</p>
                                                                <h6>{bondsDetail?.bond_issuer_details[0].bond_issuer_industry != null && bondsDetail?.bond_issuer_details[0].bond_issuer_industry != '' ? bondsDetail?.bond_issuer_details[0].bond_issuer_industry : "N/A"}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-4 pt-10 pb-10">
                                                            <div>
                                                                <p>Head Office</p>
                                                                <h6>{bondsDetail?.bond_issuer_details[0].bond_issuer_head_office != null && bondsDetail?.bond_issuer_details[0].bond_issuer_head_office != '' ? bondsDetail?.bond_issuer_details[0].bond_issuer_head_office : "N/A"}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-6 pt-10 pb-10">
                                                            <div>
                                                                <p>Type of Issuer</p>
                                                                {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.CENTRAL_GOVERNMENT &&
                                                                    <h6>Central Government</h6>
                                                                }
                                                                {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PRIVATE_BANK &&
                                                                    <h6>Private Bank</h6>
                                                                }
                                                                {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PRIVATE_SECTOR_CORPORATE &&
                                                                    <h6>Private Sector Corporate</h6>
                                                                }
                                                                {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PUBLIC_SECTOR_BANK_STATE_GOVERNMENT &&
                                                                    <h6>Public Sector Bank State Government</h6>
                                                                }
                                                                {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == IssuerType.PUBLIC_SECTOR_UNDERTAKING &&
                                                                    <h6>Public Sector Undertaking</h6>
                                                                }
                                                                {bondsDetail?.bond_issuer_details[0].bond_type_of_issuer == null &&
                                                                    <h6>N/A</h6>
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-lg-4 pt-10 pb-10">
                                                            <div>
                                                                <p>Current MD/CEO</p>
                                                                <h6>{bondsDetail?.bond_issuer_details[0].bond_type_of_issuer}</h6>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className="row">
                                                        {bondsDetail?.bond_issuer_details[0].bond_issuer_profile_link != '' && bondsDetail?.bond_issuer_details[0].bond_issuer_profile_link != null &&
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-4 pt-10 pb-10 cursor" onClick={(e: any) => {
                                                                e.preventDefault();
                                                                window.open(bondsDetail?.bond_issuer_details[0].bond_issuer_profile_link);
                                                            }}>
                                                                <a><u>Issuers Profile</u> <i className="fa fa-external-link" aria-hidden="true"></i></a>
                                                            </div>
                                                        }
                                                        {bondsDetail?.bond_issuer_details[0].bond_issuer_finance_details != '' && bondsDetail?.bond_issuer_details[0].bond_issuer_finance_details != null &&
                                                            <div className="col-lg-7 col-md-7 col-sm-6 col-7 d-flex pt-10 pb-10 cursor" onClick={(e: any) => {
                                                                e.preventDefault();
                                                                window.open(bondsDetail?.bond_issuer_details[0].bond_issuer_finance_details)
                                                            }}>
                                                                <a><u>Issuers Finance Details</u> <i className="fa fa-external-link" aria-hidden="true"></i></a>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            }
                        </div>
                    </div>
                </section>
            </main>
            <div className="ml-lg-4 mr-lg-4 m-md-0 m-sm-0 ">
                {bondsDetail?.bond_issuer_details != undefined && bondsDetail?.bond_issuer_details.length > 0 && bondsDetail?.bond_issuer_details[0].bond_issuer_documents != null &&
                    <DownloadIssueDocuments document={bondsDetail?.bond_issuer_details[0].bond_issuer_documents!} />
                }
                <div className="d-none">
                    <div className="text-center p-0 detail-view-description">
                        <button className="ipo-buy-button" onClick={(e: any) => {
                            e.preventDefault();
                            goToItemPurchase(bondsDetail?.bond_id!);
                        }}><p className="click-me ipo-detail-buy-button">Subscribe This IPO now&nbsp;&nbsp;&nbsp;<img className="mx-1" width="11px" height="11px" src="assets/img/icon/rightarrow.png"></img></p></button>
                    </div>
                </div>
            </div>

            <div className="mb-5">
                <InvestSteps />
            </div>
            <Subscribe />
            <NeedHelp />
            <Footer />
        </div >
    );
}

export default BondIPOFlow;