import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import InvestSteps from "../components/InvestSteps";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import Footer from "../components/template/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { interestPaymentFrequency, bondTypes, NatureofInstruments, bondPurchaseTypes, PageLinks } from "../common/Constants";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React, { Fragment, useEffect, useRef, useState } from "react";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import ReactPaginate from 'react-paginate';
import { Button, Menu } from "@mui/material";
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Avatar, ListItemDecorator } from "@mui/joy";
import { List } from "@mui/material";
import SortBy from "../components/template/SortBy";
import { numberWithCommas } from "../common/Utilities";
import { ControlledMenu } from "@szhsin/react-menu";
import ShareOptions from "../components/ShareOptions";
import { InitialData } from "../components/Form/InitialData";
import MetaDecorator from "../components/MetaDecorator";
import SocialMediaShare from "../components/SocialMediaShare";
import Listing from "../components/Svg/Listing";
import Download from "../components/Svg/Download";
import MoreInfo from "../components/Modals/MoreInfo";
import Copy from "../components/Svg/Copy";
import Alert from "../components/Modals/Alert";
import AliceCarousel from 'react-alice-carousel';
import moment from "moment";
import copy from 'copy-to-clipboard';
import { toast } from "react-hot-toast";

type LocationState = { data: any };

const DidNotFind: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [bondsList, setBondsList] = useState<APIData.BondlistData[]>([]);
    const [bondsDetail, setBondDetail] = useState<any>();
    const [bondsFilters, setBondsFilters] = useState<APIData.BondFilters>();
    const [issearch, setisSearch] = useState(false)
    const [pageno, setPageno] = useState(1);
    const [bondtype, setBondType] = useState(0);
    const [All, setAll] = useState(0);
    const [isin, setIsin] = useState('');
    const [moreinfo, setMoreInfo] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [searchText, setSeatchText] = useState('');
    const [show, setShow] = useState(false);
    const [searchBarText, setSeatchBarText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const navigate = useNavigate();
    const socialShare = useRef(null);
    const [alertPopup, setAlertPopup] = useState(false);
    const [Amount, setAmount] = useState('');
    const [socialLinkState, setSocialLinkState] = useState<any>("closed");
    const [selectedFilters, setSelectedFilters] = useState<APIData.Filters>({ ...InitialData.FilterData });
    const sites = ['facebook', 'twitter', 'whatsapp', 'telegram', 'linkedin', 'mail'];
    const location = useLocation();
    // const { data } = location.state as LocationState;


    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("isin")) {
                const id = query.get("isin");
                setIsin((id!));
            }

        }
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("bond_type_ipo")) {
                const IPO = query.get("bond_type_ipo");
                setBondType(parseInt(IPO!));
            }

        }
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("bond_type_bond")) {
                const ADDEDBOND = query.get("bond_type_bond");
                setBondType(parseInt(ADDEDBOND!));
            }

        }
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("bond_All")) {
                const BOND = query.get("bond_All");
                setBondType(parseInt(BOND!));
            }

        }
    }, [location]);


    const responsive = {
        0: { items: 1 },
        568: { items: 1 },
        1024: { items: 1 },
    };
    const handleSelectedFilter = (type: string, updateValue: number, checked: boolean) => {
        if (checked === true) {
            if (type === 'brand_tag') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, brand_tag_id: [...selectedFilters.brand_tag_id, updateValue + ""] }))
            } else if (type === 'yield_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_from: updateValue }))
            } else if (type === 'yield_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_to: updateValue }))
            } else if (type === 'tenure_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_from: updateValue }))
            } else if (type === 'tenure_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_to: updateValue }))
            } else if (type === 'rating') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, bond_rating_list_id: [...selFilters.bond_rating_list_id, updateValue + ""] }))
            } else if (type === 'frequency') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, payment_frequency: [...selFilters.payment_frequency, updateValue + ""] }))
            }
        } else {
            if (type === 'brand_tag') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, brand_tag_id: selFilters.brand_tag_id.filter((val: any) => val != updateValue) }));
            } else if (type === 'yield_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_from: 0 }))
            } else if (type === 'yield_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, yield_to: 0 }))
            } else if (type === 'tenure_from') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_from: 0 }))
            } else if (type === 'tenure_to') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, tenure_to: 0 }))
            } else if (type === 'frequency') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, payment_frequency: selFilters.payment_frequency.filter((val: any) => val != updateValue) }));
            } else if (type === 'rating') {
                setSelectedFilters((selFilters: any) => ({ ...selFilters, bond_rating_list_id: selFilters.bond_rating_list_id.filter((val: any) => val != updateValue) }));
            }
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        console.log('clicked');
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    useEffect(() => {
        if (isin != '') {
            setShowPreloader(true);
            ServerAPI.SGBBondDetail(isin).then((response: any) => {
                if (response != undefined) {
                    setBondsList(response);
                    setBondDetail(response)
                    setAmount(response['bonds_price_per_gram'] + "");
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [isin])

    const goToBuy = (isin: string) => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {

            navigate({
                pathname: PageLinks.BOND_CALCULATION,
                search: "?isin=" + isin + "&bond_id=" + bondsDetail[0]?.bond_id,
            });
        } else {
            localStorage.setItem('redirection', 'SGB');
            localStorage.setItem('isin', bondsList[0]?.bond_isin_number!);
            navigate(PageLinks.LOGIN);
        }
    }

    const bondlist = (selectedFilters: APIData.Filters) => {
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        ServerAPI.BondsList(pageno, '', selectedFilters).then((response: any) => {
            if (response != undefined) {
                setBondsList(response['data']);
                setBondDetail(response['data']);
                setTotalCount(response['total'])
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const bondlistWithSearch = (text: string) => {
        console.log(text);
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        ServerAPI.EnquireBondsListe(pageno, text).then((response: any) => {
            if (response != undefined) {
                setBondsList(response['data']);
                setBondDetail(response['data']);
                setTotalCount(response['total'])
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }


    // const bondFilters = () => {
    //     setShowPreloader(true);
    //     ServerAPI.GetBondFilter().then((response: any) => {
    //         if (response != undefined) {
    //             setBondsFilters(response);
    //         }
    //     }).finally(() => {
    //         setShowPreloader(false);
    //     })
    // }
    useEffect(() => {
        // bondlist(selectedFilters);
        // bondFilters();
    }, [])

    useEffect(() => {
        // bondlist(selectedFilters);
    }, [pageno])

    useEffect(() => {
        if (searchBarText == '') {
            setisSearch(false)
            // bondlistWithSearch(searchBarText);
        }
    }, [searchBarText])


    const filterApply = () => {
        bondlist(selectedFilters);
    }

    return (
        <div className="main-index-wrapper">
            <Header />

            <div className="body-overlay"></div>
            <main>
                <section className="page-title-area listing-search fix pb-20 pt-170 pt-md-100 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="widget-search-content faq-que-search">
                                    <form className="subscribe-form" action="">
                                        <i className="far fa-search"></i>
                                        <input type="text" placeholder="Search by Name or ISIN..." className="input-box-bond-slider" onChange={(e: any) => {
                                            e.preventDefault();
                                            setSeatchBarText(e.target.value);
                                        }} />
                                        <button className="trustBlackbtn" onClick={(e: any) => {
                                            e.preventDefault();
                                            if (issearch) {
                                                setisSearch(false)
                                            }
                                            setisSearch(true)
                                            bondlistWithSearch(searchBarText);
                                        }}>Search</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="profile_listing pt-30 pb-110 pt-md-45 pb-md-40 pt-xs-0 pb-xs-40">
                    <div className="container-fluid ">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row align-items-left">
                                    {issearch ?
                                        <div className="col-lg-6 breadcums pb-xs-0 pb-60">
                                            <nav>
                                                <ul>
                                                    <li>Home</li>
                                                    <li>Bond Directory</li>
                                                    <li className="active">Results</li>
                                                </ul>
                                            </nav>
                                        </div> : ""}
                                </div>
                                {issearch ?
                                    <p className="length-font p-1 ">Found {(bondsList.filter(bondsList => bondsList.bond_type !== bondTypes.IPO && bondsList.bond_type !== bondTypes.SGB).length)} bonds for your search!</p> : ""}
                                {bondsList != undefined && bondsList.length > 0 && issearch ?
                                    <div className="row align-items-center">
                                        {(bondsList.filter(bondsList => bondsList.bond_type !== bondTypes.IPO && bondsList.bond_type !== bondTypes.SGB)).map((bond: APIData.BondlistData) => {
                                            return (

                                                <div className="col-lg-12" data-wow-delay="0.1s">

                                                    <div className="do-box do-box-2 mb-30">
                                                        <div className="icon-box d-flex pl-0">
                                                            <div className="icon-img2">
                                                                <img src={bond.bond_logo} alt="" />
                                                            </div>
                                                            <div className="icon-head mt-1">

                                                                <div className="col-lg-12 p-0 bond-details1">
                                                                    <p className="p1">{bond.bond_name}</p>
                                                                    <div className="d-flex my-2">
                                                                        <a className="background-green title-background-style ">
                                                                            <p className="fs-15-bond text-white">RETURNS Based On Market</p>
                                                                        </a>
                                                                        {bond?.bond_isin_number != '' &&
                                                                            <a className="background-grey title-background-style mx-2"><p className="fs-15-bond"> <span>ISIN:&nbsp;</span>{bond?.bond_isin_number}&nbsp;
                                                                                <span className="cursor" onClick={(e: any) => {
                                                                                    e.preventDefault();
                                                                                    copy(bond?.bond_isin_number!, {
                                                                                        debug: true,
                                                                                    });
                                                                                    toast.success('ISIN Number Copied To Clipboard');
                                                                                }}>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                    <Copy />
                                                                                </span></p>
                                                                            </a>
                                                                        }
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <SocialMediaShare url={bond.share_link} />
                                                            {/* <ControlledMenu state={socialLinkState} anchorRef={socialShare} onMouseEnter={() => setSocialLinkState("open")} onMouseLeave={() => setSocialLinkState("closed")} onClose={() => setSocialLinkState("closed")} className="pro_details_share" direction="bottom">
                                                            <ShareOptions baseURL={"test"} id={bond.bond_id} name={bond.bond_name} />
                                                        </ControlledMenu> */}
                                                        </div>
                                                        <div className="col-lg-12 p-0  d-flex box-reverse main-boxbond mb-2">
                                                            <div className="col-lg-2 p-0 box-bond-max1">
                                                                <div className="spotlight_box_one1">
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Rate of Int (Returns)</div>
                                                                        <div className="spotlight_box_inner_text2">{bond.bond_coupon_rate != null && bond.bond_coupon_rate != '' ? bond.bond_coupon_rate + "% p.a" : "N/A"}</div>
                                                                    </div>
                                                                    {bond?.bond_type !== 1 &&
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Yield %</div>
                                                                        <div className="spotlight_box_inner_text2">{bond.bonds_yeild != null && bond.bonds_yeild != '' ? bond.bonds_yeild + "%" : "N/A"}</div>
                                                                    </div>}
                                                                </div>
                                                                <div className="spotlight_box_two2 bg-gray-table">
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Min. Investmentt</div>
                                                                        <div className="spotlight_box_inner_text2">
                                                                            {bond.bond_minimum_application != null && bond.bond_minimum_application != '' ? "₹" + numberWithCommas(bond.bond_minimum_application) : "N/A"}</div>
                                                                    </div>
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Face Value</div>
                                                                        <div className="spotlight_box_inner_text2">{bond.bond_face_value != null && bond.bond_face_value != '' ? "₹" + numberWithCommas(bond.bond_face_value) : "N/A"}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 mx-lg-2 p-0 box-bond-max1 box-bond-2 d-box-none">
                                                                <div className="spotlight_box_one1">
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Interest Payment Freq.</div>
                                                                        <div className="spotlight_box_inner_text2">
                                                                            {bond?.bond_interest_frequency != null ? bond?.bond_interest_frequency : 'N/A'}</div>
                                                                    </div>
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Maturity Date</div>
                                                                        <div className="spotlight_box_inner_text2">{bond.bond_maturity_date != null && bond.bond_maturity_date != '' ? moment(bond.bond_maturity_date).format("DD, MMM YYYY") : "N/A"}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="spotlight_box_two2 bg-gray-table">
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Instrument Type</div>
                                                                        <div className="spotlight_box_inner_text2">
                                                                            {bond.bond_nature_of_instrument == NatureofInstruments.TAXABLE ? "Taxable" : "Tax Free"}</div>
                                                                    </div>
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Date of Issue</div>
                                                                        <div className="spotlight_box_inner_text2">{bond.bond_issue_date != null && bond.bond_issue_date != '' ? moment(bond.bond_issue_date).format("DD, MMM YYYY") : "N/A"}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 p-0 box-bond-max1 box-bond-3 d-box-none">
                                                                <div className="spotlight_box_one1">
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Call Option</div>
                                                                        <div className="spotlight_box_inner_text2">{bond?.call_option != null ? bond?.call_option : 'N/A'}</div>
                                                                        <Popover
                                                                            id="call"
                                                                            open={open}
                                                                            anchorEl={anchorEl}
                                                                            onClose={handleClose}
                                                                            anchorOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'right',
                                                                            }}
                                                                            transformOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'left',
                                                                            }}
                                                                        >
                                                                            <Typography sx={{ p: 2 }}>
                                                                                <div className="call-option-content">
                                                                                    <h5>Dates for Call Option</h5>
                                                                                    <h6>02 Oct 2022, 10.00am to 11.00am</h6>
                                                                                    <h6>02 Oct 2022, 10.00am to 11.00am</h6>
                                                                                    <h6 className="selected">02 Oct 2022, 10.00am to 11.00am</h6>
                                                                                    <div className="center-btn">
                                                                                        <button className="alert-button" onClick={(e: any) => {
                                                                                            e.preventDefault();
                                                                                            setAlertPopup(true);
                                                                                            handleClose();
                                                                                        }}>Set Alert<span><i className="fa fa-arrow-right" aria-hidden="true"></i></span></button>
                                                                                    </div>
                                                                                </div>
                                                                            </Typography>
                                                                        </Popover>
                                                                    </div>
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Put Option</div>
                                                                        <div className="spotlight_box_inner_text2"> {bond?.put_option != null ? bond?.put_option : 'N/A'}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="spotlight_box_two2 bg-gray-table">
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Mode of Issue</div>
                                                                        <div className="spotlight_box_inner_text2">
                                                                            {bond?.mode_of_issuer_name != null ? bond?.mode_of_issuer_name : 'N/A'}</div>
                                                                    </div>
                                                                    <div className="spotlight_box_inner">
                                                                        <div className="spotlight_box_inner_text1">Listed</div>
                                                                        <div className="spotlight_box_inner_text2">{bond?.listing_name != null ? bond?.listing_name : "N/A"}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 m-0 box-img1 box-bond-image p-0 d-flex">
                                                                <p className="pra-1">Bond’s Rating</p>


                                                                {bondsDetail != undefined && bondsDetail?.bond_rating_details?.length > 0 && bondsDetail?.bond_rating_details.map((rating: APIData.BondRatingDetails) => {
                                                                    return (
                                                                        <Fragment>
                                                                            {
                                                                                rating.bond_rating_image != null && rating.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=" &&
                                                                                <div className="row">
                                                                                    <div className="col-lg-6 col-md-12 col-sm-12 col-6">
                                                                                        <img className="bond-ipo-detail-view-meter w-100" src={rating.bond_rating_image} alt="" />
                                                                                        <div>
                                                                                            <table className="ipo-bond-download-table mt-1 w-100">
                                                                                                <tr>
                                                                                                    <td className="sourceSansPro-bold">{rating?.bond_rating_agency?.substring(0,4)}</td>
                                                                                                    <td className="sourceSansPro-bold">{rating.bond_rating_name}</td>
                                                                                                    {rating.bond_rating_certificate != "" &&
                                                                                                        <td className="sourceSansPro-bold cursor" onClick={(e: any) => {
                                                                                                            e.preventDefault();
                                                                                                            window.open(rating.bond_rating_certificate);
                                                                                                        }}><Download /></td>
                                                                                                    }
                                                                                                </tr>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            }
                                                                        </Fragment>
                                                                    )
                                                                })
                                                                }




                                                                {/* <AliceCarousel responsive={responsive}>
                                                                <div className="speed-img2 mx-2">
                                                                    <img src={bond.bond_rating_image != null && bond.bond_rating_image != "https://trust-api.trustmony.com/api/v1/image_download?name=None" && bond.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=None" ? bond.bond_rating_image : "../assets/img/no_rating.png"} alt="" className="w-100" />
                                                                    <table className="ipo-bond-download-table mt-1 w-100">
                                                                        <tr>
                                                                            <td className="sourceSansPro-bold p-1">aa</td>
                                                                            <td className="sourceSansPro-bold p-1">bb</td>
                                                                            <td className="sourceSansPro-bold cursor p-1"><Download /></td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                <div className="speed-img2">
                                                                    <img src={bond.bond_rating_image != null && bond.bond_rating_image != "https://trust-api.trustmony.com/api/v1/image_download?name=None" && bond.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=None" ? bond.bond_rating_image : "../assets/img/no_rating.png"} alt="" className="w-100" />
                                                                    <table className="ipo-bond-download-table mt-1 w-100">
                                                                        <tr>
                                                                            <td className="sourceSansPro-bold p-1">aa</td>
                                                                            <td className="sourceSansPro-bold p-1">bb</td>
                                                                            <td className="sourceSansPro-bold cursor p-1"><Download /></td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </AliceCarousel> */}
                                                            </div>

                                                        </div>
                                                        <div className="row mt-0">
                                                            <div className="col-lg-12 mb-2">
                                                                <div className="ipo_text">
                                                                    {bond.bond_type == bondTypes.IPO &&
                                                                        "IPO"
                                                                    }
                                                                </div>
                                                                <div className="d-flex mb-4 btn-box justify-content-lg-end justify-content-center justify-content-center">



                                                                    <button onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        goToBuy(bond?.bond_isin_number!);
                                                                    }} className="spotlight_readmore_sell bonds-detail-btn btn-1 mx-2 mt-1" style={{ padding: "3px 20px" }}>
                                                                        Continue to Buy or Sell</button>

                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>
                                            )
                                        })}
                                        <div className="">
                                            <ReactPaginate
                                                onPageChange={(e: any) => {
                                                    setPageno(e.selected + 1);
                                                }}
                                                breakLabel="..."
                                                nextLabel=">"
                                                pageRangeDisplayed={5}
                                                pageCount={totalCount / 50}
                                                previousLabel="<"
                                                containerClassName="pagination"
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                activeClassName="active"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className="text-center mt-5 pt-100">
                                        <img src="assets/img/no_result.png" />
                                        <p className="source sourceSansPro-bold no-result-main">Search To Find The Specific Bond...</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>

                {/* <NeedHelp /> */}
            </main>
            <Footer />
            <MoreInfo showModal={moreinfo} closeModal={() => { setMoreInfo(false) }} />
            <Alert showModal={alertPopup} closeModal={() => { setAlertPopup(false) }} />
        </div>
    )
}

export default DidNotFind;
