interface FormParameters {
    step: number,
    status?: string
}
const OrderStepsComponent: React.FC<FormParameters> = ({ step }) => {
    return (
        <div className="order-step-flow">
            <div className="progress-indicator-element">
                <div className='line-container'>
                    <div className='progress-line'>
                        {step == 1 &&
                            <div className='progress'></div>
                        }
                        {step == 2 &&
                            <div className='progress w-50'></div>
                        }
                        {step == 2.5 &&
                            <div className='progress w-75'></div>
                        }
                        {step == 3 &&
                            <div className='progress w-100'></div>
                        }
                        {step == 4 &&
                            <div className='progress cancel w-100'></div>
                        }

                        {step == 4 ?
                            <>
                                <div className='status'>
                                    <div className='dot completed cancel'></div>
                                </div>
                                <div className='status'>
                                    <div className='dot completed cancel'></div>
                                </div>
                                <div className='status'>
                                    <div className='dot completed cancel'><i className="fal fa-times"></i></div>
                                </div></>
                            : <>
                                <div className='status'>
                                    <div className='dot completed'>{step == 1 ||  step >= 2  && <i className="fal fa-check"></i>}</div>
                                </div>
                                <div className='status'>
                                    <div className={step >= 2 ? 'dot completed' : 'dot'}>{ step >= 2 && <i className="fal fa-check"></i>}</div>
                                </div>
                                <div className='status'>
                                    <div className={step == 3 ? 'dot completed' : 'dot'}>{step == 3 && <i className="fal fa-check"></i>}</div>
                                </div>
                            </>}

                    </div>
                </div>
                <ul className="progress-indicator">
                    <li className="completed">
                        <div className="stacked-text step-component-text">
                            <span className={step >= 1 ? "step-component-active subdued position-step" : "subdued position-step"}>Bid  Requested Placed</span>
                        </div>
                    </li>
                    <li className="completed msg_contain">
                        <div className="stacked-text step-component-text">
                            <span className={step >= 2 ? "step-component-active subdued" : " step-component-inactive subdued"}>
                            {step == 4 ? (
                                <span className="green_colored_text text-dark">Bid Processing</span>): step == 3 ? (
                                    <span className="green_colored_text text-dark">Bid Processing</span>   
                                ):
                                 
                                <span className="green_colored_text">Bid Processing</span> }<br></br> 
                                <span className="text-gray">Bid forwarded to RBI and Stock Exchange approval.</span>
                                </span>
                        </div>
                        <div className="bse-order-no">
                        </div>
                        <div className="bse-order-msg msg-success">
                        </div>
                    </li>
                    <li className="completed">
                        <div className="stacked-text step-component-text">
                            <span className={step == 3 ? "step-component-active subdued position-step1" : "step-component-inactive subdued position-step1"}>Bonds Credited to your Account</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        // <div className="order-step-flow">
        //     <ul className="list-unstyled multi-steps">
        //         <li className="sourceSansPro-regular">
        //             <span >Money Receive Bid </span>
        //             <span className="d-block"> Requested Placed
        //             </span>
        //         </li>
        //         <li className="is-active">
        //             <p className="sourceSansPro-regular fs-16 text-green">
        //                 Bid Processing</p>
        //             <p className="text-small text-center sourceSansPro-regular" >Bid forwarded to RBI and Stock <br></br> Exchange approval.</p>
        //         </li>
        //         <li className="not-complet" >
        //             <p className="text-small text-center sourceSansPro-regular" >Bonds Credited to</p>
        //             <p className="d-block m-0 p-0 text-small text-center sourceSansPro-regular"> your Account</p>
        //         </li>
        //     </ul>
        // </div>
    )
}

export default OrderStepsComponent;


