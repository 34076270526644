import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import ChequeUpload from "./ChequeUpload";
import { useNavigate, useLocation } from "react-router-dom";
import { PageLinks, bondTypes } from "../../common/Constants";
import { APIData } from "../../common/DataTypes";
import { ServerAPI } from "../../common/ServerAPI";
import toast from "react-hot-toast";
import { useAppStateAPI } from "../../common/AppStateAPI";

interface FormParameters {
    showModal: boolean,
    closeModal: any
    isin: any,
    qty:any,
    orderID?:any
    bondType?: any
    orderNo: any
}

const ModifyPopup : React.FC<FormParameters> = ({ showModal, closeModal, isin, qty,orderID, bondType, orderNo }) => {

    const navigate = useNavigate();
    const { setShowPreloader } = useAppStateAPI();
    const location = useLocation();
    const [bondsDetail, setBondDetail] = useState<APIData.BondlistData>();

    useEffect(() => {
        if (isin != '' && bondType == '2' ) {
            setShowPreloader(true);
            ServerAPI.BondDetail(isin).then((response: any) => {
                if (response != undefined) {
                    setBondDetail(response);
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [isin])
    
    const goToItemPurchase = (bond_type: any, id: any, isin: any) => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null && (bond_type == bondTypes.SGB)) {
            navigate({
                pathname: PageLinks.APPLYBONDPURCHASE,
                search: "?isin=" + isin,
            });
        } else if (bond_type == bondTypes.IPO || bondType == bondTypes.IPO) {
            navigate({
                pathname: PageLinks.MODIFY_IPO_BOND,
                search: "?orderid=" + orderID + "&orderno=" + orderNo + "&modify=" + 1,
            });
        } else {
            navigate({
                pathname: PageLinks.APPLYBONDNONGOLD,
                search: "?isin=" + isin + "&qty=" + qty + "&modify=" + 1 + "&orderid=" + orderID + "&orderno=" + orderNo,
            });
        }
    }
    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog className="dialog-background"
                    open={showModal}
                    keepMounted
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            closeModal();
                        }
                    }}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    PaperProps={{
                        style: {
                            background: 'none',
                            boxShadow: 'none',
                        },
                    }}
                    maxWidth="md"
                >
                    <DialogContent style={{ overflow: "hidden" }}>
                        <DialogContentText id="alert-dialog-slide-description" className="profile-update-bg">
                            <div className="do-box mb-30 confirmation-model-bg1  text-center">
                                <img src="assets/img/icon/warning.png" className="user_icon" />
                                <p className="mb-10  identity_verify_success1" data-wow-delay="0.1s">Are you sure?</p>
                                <p className="mb-10  confirmation-model-sub-head1" data-wow-delay="0.1s">Do you want to Modify this Order?</p>
                                <div className="mt-3 col-md-12 col-xl-12 row col-12 m-auto">
                                    <div className="col-md-6 col-xl-6 col-6 p-sm-none">
                                        <button onClick={(e: any) => {
                                            e.preventDefault();
                                            closeModal();
                                        }} type="submit" className="theme_btn email_verify_btn primary_btn_1 sub-btn " data-wow-delay="0.5s">Cancel</button>
                                    </div>
                                    <div className="col-md-6 col-xl-6 col-6 p-sm-none">
                                        <button onClick={(e: any) => {
                                            e.preventDefault();
                                            // goToBuy(bondsDetail?.bond_isin_number!);
                                            goToItemPurchase(bondsDetail?.bond_type, bondsDetail?.bond_id, bondsDetail?.bond_isin_number);
                                        }} type="submit" className="theme_btn email_verify_btn primary_btn_1 sub-btn confirm-btn mx-1" data-wow-delay="0.5s">Yes</button>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

            </main>
        </div>
    )
}

export default ModifyPopup;