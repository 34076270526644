import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Footer from "../components/template/Footer";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import InvestSteps from "../components/InvestSteps";
import YourStory from "../components/YourStory";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import { bondTypes, interestPaymentFrequency, kycStatus, PageLinks } from "../common/Constants";
import { useEffect, useState } from "react";
import { ServerAPI } from "../common/ServerAPI";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData } from "../common/DataTypes";
import { InitialData } from "../components/Form/InitialData";
import { numberWithCommas } from "../common/Utilities";
import SocialMediaShare from "../components/SocialMediaShare";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import GoldBond1 from "../components/GoldBond1";
import { toast } from "react-hot-toast";

const Profile: React.FC = () => {
    const [customerFirstName, setCustomerFirstName] = useState('');
    const [customerLastName, setCustomerLastName] = useState('');
    const [url, setUrl] = useState('');
    const [pageno, setPageno] = useState(1);
    const [isin, setIsin] = useState('');
    const [bondsDetail, setBondDetail] = useState<APIData.SGBDetail>();
    const [progressStatus, setProgressStatus] = useState(0);
    const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
    const { setShowPreloader } = useAppStateAPI();
    const [isinNumber, setisinNumber] = useState('');
    const [bondsList, setBondsList] = useState<APIData.BondlistData[]>([]);
    const navigate = useNavigate();
    const sites = ['facebook', 'twitter', 'whatsapp', 'telegram', 'linkedin', 'mail'];
    const [openShareModal, setOpenShareModal] = useState(false);
    const location = useLocation();
    const [Amount, setAmount] = useState('');

    useEffect(() => {
        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("isin")) {
                const id = query.get("isin");
                setIsin((id!));
            }
        }
    }, [location]);

    const getdata = () =>
    navigate({
        pathname: PageLinks.SEARCH_BOND,
        search: "isinNumber=" + isinNumber,
    });
    // const bondlistWithSearch = (text: string) => {
    //     console.log(text);
    //     localStorage.setItem('animation', 'default');
    //     setShowPreloader(true);

    //     if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
    //         ServerAPI.BondsList(pageno, text).then((response: any) => {
    //             if (response != undefined) {
    //                 let params: any = {};
    //                 params.data = response['data'];
    //                 params.isinNumber = isinNumber
    //                 // items.push(params);
    //                 navigate(PageLinks.SEARCH_BOND, { state: { data: params } });
    //             }
    //         }).finally(() => {
    //             setShowPreloader(false);
    //         })
    //     } else {
    //         ServerAPI.BondsListHome(pageno, text).then((response: any) => {
    //             if (response != undefined) {
    //                 let params: any = {};
    //                 params.data = response['data'];
    //                 params.isinNumber = isinNumber
    //                 // items.push(params);
    //                 navigate(PageLinks.SEARCH_BOND, { state: { data: params } });
    //             }
    //         }).finally(() => {
    //             setShowPreloader(false);
    //         })
    //     }
    // }
    console.log('set', isin);
    useEffect(() => {
        if (isin != '') {
            setShowPreloader(true);
            ServerAPI.SGBBondDetail(isin).then((response: any) => {
                if (response != undefined) {
                    setBondsList(response);
                    setAmount(response['bonds_price_per_gram'] + "");
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [isin])
    const goToItemPurchase = (bond_type: number, id: number, isin: string) => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null && (bond_type == bondTypes.SGB)) {
            navigate({
                pathname: PageLinks.APPLYBONDPURCHASE,
                search: "?isin=" + isin,
            });
        } else if (bond_type == bondTypes.IPO) {
            navigate({
                pathname: PageLinks.BOND_IPO_PURCHASE,
                // pathname: PageLinks.BOND_IPO_FLOW1,
                search: "?id=" + id,
            });
        } else if (bond_type == bondTypes.ADDEDBOND || bond_type == bondTypes.EXISTINGBOND) {
            navigate({
                pathname: PageLinks.APPLYBONDNONGOLD,
                search: "?isin=" + isin,
            });
        } else {
            localStorage.setItem('redirection', 'SGB');
            localStorage.setItem('isin', bondsList[0]?.bond_isin_number!);
            navigate(PageLinks.LOGIN);
        }
    }

    const goToItemDetail = (bond_type: number, id: number, isin: string) => {

        if (bond_type == bondTypes.ADDEDBOND || bond_type == bondTypes.EXISTINGBOND) {
            navigate({
                pathname: PageLinks.DETAIL_VIEW_ONE,
                search: "?isin=" + isin,
            });
        } else if (bond_type == bondTypes.IPO) {
            navigate({
                pathname: PageLinks.BOND_IPO_FLOW,
                search: "?id=" + id,
            });
        } else if (bond_type == bondTypes.SGB) {
            navigate({
                pathname: PageLinks.SOVEREIGN_BONDS_LANDING_PAGE,
                search: "?isin=" + isin,
            });
        }
    }
    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        768: { items: 2, itemsFit: 'contain' },
        1024: { items: 2.5, itemsFit: 'contain' },
        1300: { items: 4.5, itemsFit: 'contain' },
        1600: { items: 4.5, itemsFit: 'contain' },
    };
    const responsive1 = {
        0: { items: 1 },
        568: { items: 1 },
        768: { items: 1 },
        1024: { items: 1 },
    };

    useEffect(() => {
        let firstName: any = localStorage.getItem("customerFirstName");
        let lastName: any = localStorage.getItem("customerLastName");
        setCustomerFirstName(firstName);
        setCustomerLastName(lastName);

        if (firstName != undefined && firstName != null) {
            setShowPreloader(true);
            ServerAPI.ProgressBarStatusGet().then((response) => {
                setProgressStatus(response['message']);
            }).finally(() => {
                setShowPreloader(false);
            })
            setShowPreloader(true);
            ServerAPI.userDetailData().then((res: APIData.userDetail) => {
                setUserDetails(res);
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [])
    const goToAll = () =>
        navigate({
            pathname: PageLinks.PROFILE_LISTING,
            search: "&bond_All=" + bondTypes.BOND,
        });
    const goToSVG = (isin: string) =>
        navigate({
            pathname: PageLinks.SOVEREIGN_BONDS_LANDING_PAGE,
            search: "?isin=" + isin,
        });
    const bondlist = () => {
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        ServerAPI.BondsList(1).then((response: any) => {
            if (response != undefined) {
                setBondsList(response['data']);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const bondlistHome = () => {
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        ServerAPI.BondsListHome(1).then((response: any) => {
            if (response != undefined) {
                setBondsList(response['data']);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    useEffect(() => {
        if (localStorage.getItem('login') != '0' && localStorage.getItem('login') != undefined && localStorage.getItem('login') != null) {
            bondlist();
        } else {
            bondlistHome();
        }

    }, [])

    return (
        <div className="main-index-wrapper">
            {userDetail.ekyc_application_status == '0' || userDetail.ekyc_application_status == '' || userDetail.ekyc_application_status == null &&
                <div className="top_kyc_status_bar cursor" onClick={(e: any) => {
                    e.preventDefault();
                    navigate(PageLinks.INSTRUCTION_SCREEN);
                }}><i className="fal fa-exclamation-circle"></i>Complete Your KYC</div>
            }
            <Header />

            <div className="body-overlay"></div>
            <main>
                <section className="slider-area slider-bg pos-rel">
                    <div className="slider-active">
                        <div className="single-slider slider-height-2 pos-rel pt-200 pb-190 pt-md-200 pt-xs-50 pb-md-50 pb-xs-50">
                            <div className="slider__img-two d-none d-lg-block">
                                <div className="slider-card">
                                    <div className="icon">
                                        <img src="assets/img/icon/icon16.svg" alt="" />
                                    </div>
                                    <p>Quick, easy and hassle free</p>
                                </div>
                                <div className="slider-card card-2">
                                    <div className="icon">
                                        <img src="assets/img/icon/icon16.svg" alt="" />
                                    </div>
                                    <div className="slider-text">
                                        <h5>Regular Income Stream on Trust Money.</h5>
                                        <span>Learn More</span>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col-xl-6 col-lg-8 col-md-12 col-12">
                                        <div className="slider__content slider__content-2 text-left p-40">
                                            <h6>From the makers of the most Trusted Bonds <br></br>Investment House.</h6>
                                            <h1 className="main-title mb-35" data-animation="fadeInUp2" data-delay=".2s">
                                                <h1 className="text-head-h1">Hello I am</h1> <br />
                                                <span> Bond, Trust Bond</span>  <span className="banner_man_icon"><img src="assets/img/icon/man_icon.png" /></span><br></br>
                                                <span className="small_text">Licensed to make <br /> money for you.</span>
                                                <span className="lets_find_box">Let’s find the right BOND for you</span>
                                            </h1>
                                            <div className="col-md-12 col-12 d-lg-none">
                                                <div className="imges mt-3 mb-5">
                                                    <img src="../assets/img/Home Banner.png" alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                            <ul className="input-box mt-10">
                                                <li>
                                                    <i className="far fa-search"></i> <input className="input-box-bond-slider font-size-serch" onChange={(e: any) => {
                                                        setisinNumber(e.target.value)
                                                    }} value={isinNumber} type="text" name="bond" placeholder="Bond name, issuer or bond type" />
                                                </li>
                                                <li>
                                                    <button className="trustBlackbtn-home" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        if(isinNumber == ""){
                                                            toast.error("Please enter search text");
                                                        }else{

                                                            getdata()
                                                        }
                                                        // bondlistWithSearch(isinNumber);
                                                    }} tabIndex={0}> Search</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="what-we-do-area pt-10 pb-40 pb-md-20 pt-md-60 pt-xs-60 pb-xs-20">
                    <div className="container-fluid">
                        <div className="view_all mobile_hide">
                            <a style={{ color: "#fff" }} onClick={(e: any) => {
                                e.preventDefault();
                                goToAll()
                            }} className="explore_btn">Explore All Bonds <i className="fas fa-arrow-right"></i></a>
                            {/* <Link to={PageLinks.PROFILE_LISTING}   className="explore_btn">Explore All Bonds <i className="fas fa-arrow-right"></i></Link> */}
                        </div>
                        <Tabs>
                            <TabList>
                                <Tab>Current IPOs</Tab>
                                <Tab>Bonds in Spotlight</Tab>
                            </TabList>
                            <TabPanel>

                                <AliceCarousel disableDotsControls mouseTracking responsive={responsive}>

                                    {bondsList != undefined && bondsList.length > 0 &&
                                        (bondsList.filter(bondsList => bondsList.bond_type == bondTypes.IPO)).map((bond: APIData.BondlistData) => {
                                            return (
                                                <div className="mx-2" data-wow-delay="0.1s">
                                                    <div className="do-box do-box-2 mb-30 home_profile col-lg-12">
                                                        <div className="icon-box d-flex icon-h">
                                                            <div className="icon-img">
                                                                <img src={bond.bond_logo ? bond.bond_logo : "assets/img/img_placeholder.png"} alt="" />
                                                            </div>
                                                            <div className="icon-head text-width">
                                                                {bond.bond_name}
                                                            </div>
                                                            <SocialMediaShare url={bond.share_link} />
                                                        </div>
                                                        <div className="spotlight_box_one">
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Rate of Int</div>
                                                                <div className="spotlight_box_inner_text2">{bond.bond_coupon_rate != null && bond.bond_coupon_rate != '' ? bond.bond_coupon_rate + "%" : "N/A"}</div>
                                                            </div>
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Face Value</div>
                                                                <div className="spotlight_box_inner_text2">
                                                                    {bond.bond_face_value != null && bond.bond_face_value != '' ? "₹" + numberWithCommas(bond.bond_face_value) : "N/A"}
                                                                </div>
                                                            </div>
                                                            {/* <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Yield%</div>
                                                                <div className="spotlight_box_inner_text2">{bond.bonds_yeild != null && bond.bonds_yeild != '' ? bond.bonds_yeild + "%" : "N/A"}</div>
                                                            </div> */}
                                                        </div>
                                                        <div className="spotlight_box_two">
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Issue Size (In crores)</div>
                                                                <div className="spotlight_box_inner_text2">{bond.bond_issue_price != null && bond.bond_issue_price != '' ? "₹" + numberWithCommas(bond.bond_issue_price) : "N/A"}</div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4 box-h">
                                                            {bond != undefined && bond?.bond_rating_list.length > 0 ?

                                                                <AliceCarousel responsive={responsive}>
                                                                    {bond?.bond_rating_list?.map((rating) => (

                                                                        <div className="m-auto speed-img1 ">
                                                                            <img src={rating?.bond_rating_image != null && rating?.bond_rating_image != "https://trust-api.trustmony.com/api/v1/image_download?name=None" && rating?.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=None" ? rating.bond_rating_image : "N/A"} alt="" className="w-100" />
                                                                        </div>
                                                                    ))}
                                                                </AliceCarousel>
                                                                : "N/A"
                                                            }
                                                            {/* <AliceCarousel responsive={responsive1}>
                                                                <div className="m-auto speed-img1">
                                                                    <img src={bond.bond_rating_image != null && bond.bond_rating_image != "https://trust-api.trustmony.com/api/v1/image_download?name=None" && bond.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=None" ? bond.bond_rating_image : "../assets/img/no_rating.png"} alt="" className="w-100" />
                                                                </div>
                                                                <div className="m-auto speed-img1">
                                                                    <img src={bond.bond_rating_image != null && bond.bond_rating_image != "https://trust-api.trustmony.com/api/v1/image_download?name=None" && bond.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=None" ? bond.bond_rating_image : "../assets/img/no_rating.png"} alt="" className="w-100" />
                                                                </div>
                                                            </AliceCarousel> */}
                                                        </div>
                                                        <div className="spotlight_readmore_box">
                                                            <div className="ipo_text">{bond.bond_type == bondTypes.IPO &&
                                                                "IPO"
                                                            }</div>

                                                            {bond.bond_type == bondTypes.IPO &&
                                                                <button onClick={(e: any) => {
                                                                    goToItemPurchase(bond.bond_type, bond.bond_type != bondTypes.IPO ? 0 : bond.bond_id, bond.bond_type == bondTypes.IPO ? '' : bond.bond_isin_number);
                                                                }} className="spotlight_readmore-ipo bonds-detail-btn btn-1 mx-2 mt-1" style={{ padding: "3px 20px" }}>
                                                                    Subscribe This IPO now</button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </AliceCarousel>
                                <div className="note_text">
                                    Note: The listing of products above should not be considered an endorsement or recommendation to invest. Please use your own discretion before you transact. The listed products and their price or yield are subject to availability and market cutoff times.
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <AliceCarousel disableDotsControls mouseTracking responsive={responsive}>
                                    {bondsList != undefined && bondsList.length > 0 &&
                                        (bondsList.filter(bondsList => bondsList.bond_type == bondTypes.ADDEDBOND || bondsList.bond_type == bondTypes.EXISTINGBOND)).map((bond: APIData.BondlistData) => {
                                            return (
                                                <div className="mx-2" data-wow-delay="0.1s">
                                                    <div className="do-box do-box-2 mb-30 home_profile">
                                                        <div className="icon-box d-flex icon-h">
                                                            <div className="icon-img">
                                                                <img src={bond.bond_logo ? bond.bond_logo : "assets/img/img_placeholder.png"} alt="" />
                                                            </div>
                                                            <div className="icon-head text-width">
                                                                {bond.bond_name}
                                                            </div>
                                                            <SocialMediaShare url={bond.share_link} />
                                                        </div>
                                                        <div className="spotlight_box_one">
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Rate of Int</div>
                                                                <div className="spotlight_box_inner_text2">{bond.bond_coupon_rate != null && bond.bond_coupon_rate != '' ? bond.bond_coupon_rate + "%" : "N/A"}</div>
                                                            </div>
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Yield%</div>
                                                                <div className="spotlight_box_inner_text2">{bond.bonds_yeild != null && bond.bonds_yeild != '' ? bond.bonds_yeild + "%" : "N/A"}</div>
                                                            </div>
                                                        </div>
                                                        <div className="spotlight_box_two">
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Face Value</div>
                                                                <div className="spotlight_box_inner_text2">
                                                                {bond.bond_face_value != null && bond.bond_face_value != '' ? "₹" + numberWithCommas(bond.bond_face_value) : "N/A"}

                                                                </div>
                                                            </div>
                                                            <div className="spotlight_box_inner">
                                                                <div className="spotlight_box_inner_text1">Issue Size (In crores)</div>
                                                                <div className="spotlight_box_inner_text2">{bond.bond_issue_price != null && bond.bond_issue_price != '' ? "₹" + numberWithCommas(bond.bond_issue_price) : "N/A"}</div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-3 box-h ">
                                                            {bond != undefined && bond?.bond_rating_list.length > 0 ?

                                                                <AliceCarousel responsive={responsive}>
                                                                    {bond?.bond_rating_list?.map((rating) => (

                                                                        <div className="m-auto speed-img1">
                                                                            <img src={rating?.bond_rating_image != null && rating?.bond_rating_image != "https://trust-api.trustmony.com/api/v1/image_download?name=None" && rating?.bond_rating_image != "https://api.trustmony.com/api/v1/image_download?name=None" ? rating.bond_rating_image : "N/A"} alt="" className="w-100" />
                                                                        </div>
                                                                    ))}
                                                                </AliceCarousel>
                                                                : "N/A"
                                                            }
                                                        </div>
                                                        <div className="spotlight_readmore_box  mb-4">

                                                            <button onClick={(e: any) => {
                                                                e.preventDefault();
                                                                goToItemDetail(bond.bond_type, bond.bond_type != bondTypes.IPO ? 0 : bond.bond_id, bond.bond_type == bondTypes.IPO ? '' : bond.bond_isin_number)
                                                            }} className="readmore-btn-bond bonds-detail-btn"><span><svg xmlns="http://www.w3.org/2000/svg" className="mb-1" width="14" height="14" viewBox="0 0 88.716 88.716">
                                                                <path id="Path_26371" data-name="Path 26371" d="M-710.221,975.631a44.355,44.355,0,0,1-44.357,44.357,44.356,44.356,0,0,1-44.359-44.357,44.365,44.365,0,0,1,44.359-44.359A44.364,44.364,0,0,1-710.221,975.631Zm-34.341,16.455a2.146,2.146,0,0,0-2.146-2.146h-2.146V972.054A2.146,2.146,0,0,0-751,969.907h-11.447a2.146,2.146,0,0,0-2.146,2.146v4.293a2.146,2.146,0,0,0,2.146,2.146h2.146v11.447h-2.146a2.146,2.146,0,0,0-2.146,2.146v4.293a2.146,2.146,0,0,0,2.146,2.146h15.74a2.146,2.146,0,0,0,2.146-2.146ZM-762.09,958.46a7.511,7.511,0,0,0,7.512,7.51,7.511,7.511,0,0,0,7.512-7.51,7.512,7.512,0,0,0-7.512-7.512A7.512,7.512,0,0,0-762.09,958.46Z" transform="translate(798.937 -931.272)" fill="#fff" />
                                                            </svg></span> Read More</button>
                                                            {(bond.buy_sell_id == 1) &&
                                                                <button onClick={(e: any) => {
                                                                    goToItemPurchase(bond.bond_type, bond.bond_type != bondTypes.IPO ? 0 : bond.bond_id, bond.bond_type == bondTypes.IPO ? '' : bond.bond_isin_number);
                                                                }} className="spotlight_readmore-bond bonds-detail-btn btn-1 mx-2 mt-1" style={{ padding: "3px 20px" }}>
                                                                    Buy this BOND now</button>}
                                                            {(bond.bond_type !== bondTypes.IPO && bond.buy_sell_id == 2) &&
                                                                <button onClick={(e: any) => {
                                                                    goToItemPurchase(bond.bond_type, bond.bond_type != bondTypes.IPO ? 0 : bond.bond_id, bond.bond_type == bondTypes.IPO ? '' : bond.bond_isin_number);
                                                                }} className="bond-btn bonds-detail-btn sell_btn_responsive btn-1 mx-2 mt-1" style={{ padding: "3px 20px" }}>
                                                                    Sell this BOND now</button>}
                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }

                                </AliceCarousel>
                                <div className="note_text">
                                    Note: The listing of products above should not be considered an endorsement or recommendation to invest. Please use your own discretion before you transact. The listed products and their price or yield are subject to availability and market cutoff times.
                                </div>
                            </TabPanel>
                        </Tabs>

                    </div>
                </section>

                {/* goldbond */}
                {bondsList.map((bond: APIData.BondlistData, index) => {
                    return (
                        <>
                            {bond.bond_type == bondTypes.SGB &&
                                <section className="bond-gold mb-none-mobile pt-20 pb-20 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40 mt-1">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-7 pt-10">
                                                <div className="section-title section-title-3 text-left text-md-left mb-30 pr-80 pr-xs-0">
                                                    <h2>Invest In <span className="text-red">Sovereign Gold Bond</span></h2>
                                                    <span className="small_text text-shadow font-w-500">Apply Online Through Your Demat Account And Save ₹50 Per Gram</span>

                                                    <div className="text-bond mt-4">
                                                        <div className="d-flex my-2">
                                                            <span className="box-yellow  d-flex">
                                                                <p className="fs-18"> <span>Listed on BSE / NSE &nbsp;</span> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 103 103" className="text-right svg-gold">
                                                                    <g id="Group_91" data-name="Group 91" transform="translate(0 0.004)">
                                                                        <circle id="Ellipse_4" data-name="Ellipse 4" cx="51.5" cy="51.5" r="51.5" transform="translate(0 -0.004)" fill="#9F0704" />
                                                                        <g id="Group_85" data-name="Group 85" transform="translate(20.425 17.118)">
                                                                            <rect id="Rectangle_460" data-name="Rectangle 460" width="63.374" height="63.374" transform="translate(0)" fill="none" />
                                                                            <path id="Checkbox" d="M25.131,43.706,0,18.575l5.1-5.1L25.131,33.144,58.275,0l5.1,5.1Z" transform="translate(0 11.883)" fill="#fff" />
                                                                        </g>
                                                                    </g>
                                                                </svg> &nbsp;
                                                                </p>
                                                            </span>
                                                        </div>

                                                    </div>
                                                    <div className="text-bond">
                                                        <div className="d-flex my-2">
                                                            <span className="box-yellow  d-flex">
                                                                <p className="fs-18"> <span>+ 2.5% Interest Extra&nbsp;</span> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 103 103" className="text-right svg-gold">
                                                                    <g id="Group_91" data-name="Group 91" transform="translate(0 0.004)">
                                                                        <circle id="Ellipse_4" data-name="Ellipse 4" cx="51.5" cy="51.5" r="51.5" transform="translate(0 -0.004)" fill="#9F0704" />
                                                                        <g id="Group_85" data-name="Group 85" transform="translate(20.425 17.118)">
                                                                            <rect id="Rectangle_460" data-name="Rectangle 460" width="63.374" height="63.374" transform="translate(0)" fill="none" />
                                                                            <path id="Checkbox" d="M25.131,43.706,0,18.575l5.1-5.1L25.131,33.144,58.275,0l5.1,5.1Z" transform="translate(0 11.883)" fill="#fff" />
                                                                        </g>
                                                                    </g>
                                                                </svg> &nbsp;
                                                                </p>
                                                            </span>
                                                        </div>

                                                    </div>
                                                    <div className="text-bond">
                                                        <div className="d-flex my-2">
                                                            <span className="box-yellow  d-flex">
                                                                <p className="fs-18"> <span>+ Capital Gains Tax Benefit &nbsp;</span> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 103 103" className="text-right svg-gold">
                                                                    <g id="Group_91" data-name="Group 91" transform="translate(0 0.004)">
                                                                        <circle id="Ellipse_4" data-name="Ellipse 4" cx="51.5" cy="51.5" r="51.5" transform="translate(0 -0.004)" fill="#9F0704" />
                                                                        <g id="Group_85" data-name="Group 85" transform="translate(20.425 17.118)">
                                                                            <rect id="Rectangle_460" data-name="Rectangle 460" width="63.374" height="63.374" transform="translate(0)" fill="none" />
                                                                            <path id="Checkbox" d="M25.131,43.706,0,18.575l5.1-5.1L25.131,33.144,58.275,0l5.1,5.1Z" transform="translate(0 11.883)" fill="#fff" />
                                                                        </g>
                                                                    </g>
                                                                </svg> &nbsp;
                                                                </p>
                                                            </span>
                                                        </div>

                                                    </div>
                                                    <div className="text-bond">
                                                        <div className="d-flex my-2">
                                                            <span className="box-yellow  d-flex">
                                                                <p className="fs-18"> <span>36,741.27 Crore already Invested! &nbsp;</span> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 103 103" className="text-right svg-gold">
                                                                    <g id="Group_91" data-name="Group 91" transform="translate(0 0.004)">
                                                                        <circle id="Ellipse_4" data-name="Ellipse 4" cx="51.5" cy="51.5" r="51.5" transform="translate(0 -0.004)" fill="#9F0704" />
                                                                        <g id="Group_85" data-name="Group 85" transform="translate(20.425 17.118)">
                                                                            <rect id="Rectangle_460" data-name="Rectangle 460" width="63.374" height="63.374" transform="translate(0)" fill="none" />
                                                                            <path id="Checkbox" d="M25.131,43.706,0,18.575l5.1-5.1L25.131,33.144,58.275,0l5.1,5.1Z" transform="translate(0 11.883)" fill="#fff" />
                                                                        </g>
                                                                    </g>
                                                                </svg> &nbsp;
                                                                </p>
                                                            </span>
                                                        </div>

                                                    </div>
                                                    <div className="text-bond">
                                                        <div className="d-flex my-2">
                                                            <span className="box-yellow  d-flex">
                                                                <p className="fs-18"> <span>Issued by the Government of India &nbsp;</span> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 103 103" className="text-right svg-gold">
                                                                    <g id="Group_91" data-name="Group 91" transform="translate(0 0.004)">
                                                                        <circle id="Ellipse_4" data-name="Ellipse 4" cx="51.5" cy="51.5" r="51.5" transform="translate(0 -0.004)" fill="#9F0704" />
                                                                        <g id="Group_85" data-name="Group 85" transform="translate(20.425 17.118)">
                                                                            <rect id="Rectangle_460" data-name="Rectangle 460" width="63.374" height="63.374" transform="translate(0)" fill="none" />
                                                                            <path id="Checkbox" d="M25.131,43.706,0,18.575l5.1-5.1L25.131,33.144,58.275,0l5.1,5.1Z" transform="translate(0 11.883)" fill="#fff" />
                                                                        </g>
                                                                    </g>
                                                                </svg> &nbsp;
                                                                </p>
                                                            </span>
                                                        </div>

                                                    </div>
                                                    <button onClick={(e: any) => {
                                                        e.preventDefault();
                                                        goToSVG(bond?.bond_isin_number!);
                                                    }} className="gold-btn bonds-detail-btn1 mt-3" style={{ padding: "3px 20px", fontSize: "20px" }}> Buy this BOND now!</button>
                                                </div>
                                            </div>
                                            <div className="col-md-5 d-flex m-auto p-0">
                                                <div className="img-gold w-100">
                                                    <img src="assets/img/SGB Bond banner image.png" className="w-100" />
                                                </div>
                                            </div>


                                        </div>
                                    </div></section>}
                        </>

                    )
                })
                }
                <section className="why-chose-us-area m-mobile pt-100 pb-110 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="section-title section-title-3 text-left text-md-left mb-30 pr-80 pr-xs-0 pr-md-0">
                                    <h3 className="mb-30">Why Invest In Bonds?</h3>
                                    <h4 className="sub-title mb-40">Trust Money provides steady interest income to investors <br /> throughout the life of the bond and also reduce the overall risk in an investment portfolio</h4>
                                    <a style={{ color: "#fff" }} onClick={(e: any) => {
                                        e.preventDefault();
                                        navigate(PageLinks.PROFILE_LISTING);
                                    }} className="explore_btn mrd-none">Explore More  <i className="fas fa-arrow-right mx-1"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <ul className="chose-list chose-list-w">
                                    <li>
                                        <div className="chose-box " data-wow-delay="0.1s">
                                            <div className="chose-box-icon">
                                                <img src="assets/img/icon/regular_income.svg" alt="" />
                                            </div>
                                            <div className="chose-box-text">
                                                <h6>Regular Income Stream</h6>
                                                <h3>Fixed-income securities provide investors with a a steady stream of income.</h3>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="chose-box " data-wow-delay="0.3s">
                                            <div className="chose-box-icon">
                                                <img src="assets/img/icon/safety_principle.svg" alt="" />
                                            </div>
                                            <div className="chose-box-text">
                                                <h6>Safety - Principal Protection</h6>
                                                <h3>Investors benefit by preserving and increasing their invested capital.</h3>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="chose-box " data-wow-delay="0.5s">
                                            <div className="chose-box-icon">
                                                <img src="assets/img/icon/zero_credit.svg" alt="" />
                                            </div>
                                            <div className="chose-box-text">
                                                <h6>Zero Credit Risk</h6>
                                                <h3>No default risk while investing in Govt Bonds.</h3>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <InvestSteps />
                {/* <YourStory /> */}
                <div className="blog-test pt-100 pb-110 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="slider__content slider__content-2 text-left text-blog p-0">
                                    <h6 className="text-dark">Seekho toh sirf<br></br><span className="text-red">Bond, Trust Bond </span>se</h6>
                                    <span>Learn your smart investment moves from the best so far…</span>
                                </div>
                                <div className="blog-box d-flex mt-4 row col-lg-10 blog2">
                                    <div className="d-flex">
                                        <div className="img-box">
                                            <img src="../assets/img/icon/story_left_img.png" alt="" className="w-100" />
                                        </div>
                                        <div className="content-blog">
                                            <p>What are 54EC Bonds or Capital Gain <br></br> Bonds</p>
                                            <div className="story_box_right_date">Jan 24, 2022</div>
                                            <div className="triangle-left"></div>
                                            <div className="story_box_right_mins">5 min read</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="blog-box d-flex mt-4 row col-lg-10">
                                    <div className="d-flex">
                                        <div className="img-box">
                                            <img src="../assets/img/icon/story_left_img.png" alt="" className="w-100" />
                                        </div>
                                        <div className="content-blog">
                                            <p>What are 54EC Bonds or Capital Gain <br></br> Bonds</p>
                                            <div className="story_box_right_date">Jan 24, 2022</div>
                                            <div className="triangle-left"></div>
                                            <div className="story_box_right_mins">5 min read</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="blog-box d-flex mt-4 row col-lg-10">
                                    <div className="d-flex">
                                        <div className="img-box">
                                            <img src="../assets/img/icon/story_left_img.png" alt="" className="w-100" />
                                        </div>
                                        <div className="content-blog">
                                            <p>What are 54EC Bonds or Capital Gain <br></br> Bonds</p>
                                            <div className="story_box_right_date">Jan 24, 2022</div>
                                            <div className="triangle-left"></div>
                                            <div className="story_box_right_mins">5 min read</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="blog-box d-flex mt-4 row col-lg-10">
                                    <div className="d-flex">
                                        <div className="img-box">
                                            <img src="../assets/img/icon/story_left_img.png" alt="" className="w-100" />
                                        </div>
                                        <div className="content-blog">
                                            <p>What are 54EC Bonds or Capital Gain <br></br> Bonds</p>
                                            <div className="story_box_right_date">Jan 24, 2022</div>
                                            <div className="triangle-left"></div>
                                            <div className="story_box_right_mins">5 min read</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-10">
                                    <div className="view_all mobile_hide">
                                        <Link to={PageLinks.PROFILE_LISTING} className="explore_btn">View All <i className="fas fa-arrow-right mx-2"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="slider__content slider__content-2 text-blog text-left pl-0">
                                    <h6 className="text-dark">From around the market<br></br><span className="text-red">Bond ki khabren </span></h6>
                                    <span></span>
                                </div>
                                <div className="blog-box d-flex row mt-57 col-lg-12 blog1">
                                    <div className="d-flex blog-col pb-30">
                                        <div className="img-box1">
                                            <img src="../assets/img/icon/story_right_img.png" alt="" className="w-100" />
                                        </div>
                                        <div className="content-blog mt-3">
                                            <p>Rainmatter invests Rs 3.5 Cr in Trust Money</p>
                                            <div className="story_box_right_date mt-1">Jan 24, 2022</div>
                                            <div className="triangle-left mt-1"></div>
                                            <div className="story_box_right_mins mt-1">5 min read</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mt-lg-8">
                                    <div className="col-lg-6 col-md-6 mt-2 mt-md-0">
                                        <div className="blog-box d-flex row col-lg-12">
                                            <div className="d-flex blog-col">
                                                <div className="img-box1">
                                                    <img src="../assets/img/icon/story_right_img_1.png" alt="" className="w-100" />
                                                </div>
                                                <div className="content-blog mt-3">
                                                    <p>Rainmatter invests Rs 3.5 Cr in Trust Money</p>
                                                    <div className="story_box_right_date mt-1">Jan 24, 2022</div>
                                                    <div className="triangle-left mt-1"></div>
                                                    <div className="story_box_right_mins mt-1">5 min read</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 mt-2 mt-md-0">
                                        <div className="blog-box d-flex row col-lg-12 mt-md-0">
                                            <div className="d-flex blog-col">
                                                <div className="img-box1">
                                                    <img src="../assets/img/icon/story_right_img_2.png" alt="" className="w-100" />
                                                </div>
                                                <div className="content-blog mt-3">
                                                    <p>Rainmatter invests Rs 3.5 Cr in Trust Money</p>
                                                    <div className="story_box_right_date mt-1">Jan 24, 2022</div>
                                                    <div className="triangle-left mt-1"></div>
                                                    <div className="story_box_right_mins mt-1">5 min read</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="view_all mobile_hide">
                                        <Link to={PageLinks.PROFILE_LISTING} className="explore_btn">View All <i className="fas fa-arrow-right mx-2"></i></Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Subscribe />
                <NeedHelp />
                {/* <NeedHelp /> */}
            </main>
            <Footer />
        </div>
    )
}

export default Profile;

