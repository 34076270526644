import { Field, Formik } from "formik";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";
import StepsComponent from "../components/StepsComponent";
import "react-accessible-accordion/dist/fancy-example.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditAddNominee: React.FC = () => {
    const navigate = useNavigate();
    const [nomineeAddress, setNomineeAddress] = useState(false);
    const [nomineeDetail, setNomineeDetails] = useState<APIData.NomineeDataUpdate>(InitialData.NomineeDataUpdate);
    const [relationshipDropdown, setRelationshipDropdown] = useState<APIData.Relationship[]>([]);
    const [nomineeDropdown, setNomineeDropdown] = useState<APIData.NomineeProof[]>([]);
    const [nomineeTitles, setNomineeTitles] = useState<APIData.Titles[]>([]);
    const [proofType, setProofType] = useState('Proof');
    const [minor1, setMinor1] = useState(true);
    const [gproofType1, setgProofType1] = useState("Proof");
    const [panerror1, setPanerror1] = useState("");
    const maxDate = moment(new Date()).format('YYYY-MM-DD');
    const [dob, setdob] = useState('');
    const [startDate1, setStartDate1] = useState<Date>();
    const [cityValue, setCityValue] = useState('');
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [id, setID] = useState(0);
    const location = useLocation();


    const validate3 = (value: any) => {

        let errors;
        if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/i.test(value) && gproofType1 == "PAN") {
            errors = "Invalid PAN number";
            setPanerror1(errors);
        } else if (!/^[A-PR-WYa-pr-wy][1-9]\\d?\\d{4}[1-9]$/i.test(value) && gproofType1 == "Passport") {
            errors = "Invalid Passport number";
            setPanerror1(errors);
        } else if (!/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/i.test(value) && gproofType1 == "Aadhar") {
            errors = "Invalid Aadhar number";
            setPanerror1(errors);
        } else {
            setPanerror1("");
        }
        // return errors;

    };
    useEffect(() => {
        if (location.pathname) {
            const id = location.pathname.split("/").pop();
            setID(parseInt(id!));
        }
    }, [location]);

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.Relationship().then((res: any) => {
            setRelationshipDropdown(res?.message)
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
        ServerAPI.NomineeProof().then((res: any) => {
            setNomineeDropdown(res?.message)
        }).finally(() => {
            setShowPreloader(false);
        })

        setShowPreloader(true);
        ServerAPI.userDetailData().then((res: APIData.userDetail) => {
            setUserDetails(res)
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
        ServerAPI.Titles()
            .then((res: any) => {
                setNomineeTitles(res?.message);
            })
            .finally(() => {
                setShowPreloader(false);
            });
        if (id != 0) {
            setShowPreloader(true);
            ServerAPI.GetNomineeDetails(id).then((res: any) => {
                setNomineeDetails(res);
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [])
    useEffect(() => {
        let nominee1details: any = { ...nomineeDetail };
        if (moment().diff(moment(startDate1), "years") < 18 && startDate1) {
            nominee1details["nominee_dob"] = moment(startDate1).format("YYYY-MM-DD");
            nominee1details["nominee_is_minor"] = 1;
            setMinor1(true);
        } else {
            nominee1details["nominee_dob"] = moment(startDate1).format("YYYY-MM-DD");
            nominee1details["nominee_is_minor"] = 0;
            setMinor1(false);
        }
        setNomineeDetails(nominee1details);
    }, [startDate1]);
    useEffect(() => {
        if (id != 0) {
            setShowPreloader(true);
            ServerAPI.GetNomineeDetails(id).then((res: any) => {
                console.log(res);
                setNomineeDetails(res);
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }, [id])

    useEffect(() => {
        setShowPreloader(true);
        if (pincode != '') {
            ServerAPI.pincodeSearch(pincode).then((response: any) => {
                setState(response[0]['pincode_state_name']);
                setCityValue(response[0]['pincode_district']);
            }).finally(() => {
                setShowPreloader(false);
            })
        }

    }, [pincode])

    const validate = (values: APIData.NomineeData) => {
        let errors: any = {};
        if (nomineeAddress == true) {
            if (values.nominee_address_line_1 == "") {
                errors.nominee_address_line_1 = " Address line 1 is required";
            }
            if (values.nominee_address_line_2 == "") {
                errors.nominee_address_line_2 = " Address line 2 is required";
            }
            // if (values.address_zip == "") {
            //     errors.address_zip = "Pincode is required";
            // }
            if (values.nominee_address_state == "") {
                errors.nominee_address_state = "State is required";
            }
            if (values.nominee_address_city == "") {
                errors.nominee_address_city = "City is required";
            }
        } else {
            if (values.nominee_fullname == '') {
                errors.nominee_fullname = "First Name is Required";
            }
            if (values.relationship_with_applicant_id == 0) {
                errors.relationship_with_applicant_id = "Please Select Relationship With The Nominee";
            }
            // if (values.nominee_details_identification == 0) {
            //     errors.nominee_details_identification = "Please Select Nominee's Identification";
            // }
            if (values.nominee_identification_number == '') {
                errors.nominee_identification_number = "Please Enter Nominee's Identification Number";
            }
        }
        return errors;

    }

    return (

        <div className="main-index-wrapper">
            <main>
                {/* Profile-details-edit-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src={window.location.origin + "/assets/img/Intersection_4.svg"} alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                            <img className="header-logo mb-5" src={window.location.origin + "/assets/img/logo/header_logo_one.png"} alt="logo" />
                                        </div>
                                    </div>
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow page-main-heading" data-wow-delay="0.1s">Nominee Details<span className="page-main-heading-steps"></span></h6>
                                                            <hr></hr>
                                                            <Formik validate={validate} initialValues={nomineeDetail!} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                                let data: APIData.NomineeDataUpdate = { ...values };
                                                                let valuedata: APIData.NomineeDataUpdate = { ...values };
                                                                data.nominee_dob = values.nominee_dob + "";
                                                                valuedata.nominee_dob = values.nominee_dob + "";
                                                                if (values.nominee_mobile_number.length < 10) {
                                                                    toast.error("Please Enter Valid Mobile Number");
                                                                } else {
                                                                    if (nomineeAddress == true) {
                                                                        setShowPreloader(true);
                                                                        ServerAPI.UpdateNomineeDetails(id, data).then((response: any) => {
                                                                           
                                                                            if (response['message'] != undefined) {
                                                                                toast.success(response['message']);
                                                                                navigate(PageLinks.MY_ACCOUNT_PROFILE);
                                                                            }
                                                                        }).finally(() => {
                                                                            setShowPreloader(false);
                                                                        })
                                                                    } else {
                                                                        valuedata.nominee_address_line_1 = userDetail?.address_line_1!;
                                                                        valuedata.nominee_address_line_2 = userDetail?.address_line_2!;
                                                                        valuedata.nominee_address_city = userDetail?.address_city!;
                                                                        valuedata.nominee_address_state = userDetail?.address_state!;
                                                                        setShowPreloader(true);
                                                                        ServerAPI.UpdateNomineeDetails(id, valuedata).then((response: any) => {
                                                                           
                                                                            if (response['message'] != undefined) {
                                                                                toast.success(response['message']);
                                                                                navigate(PageLinks.MY_ACCOUNT_PROFILE);
                                                                            }
                                                                        }).finally(() => {
                                                                            setShowPreloader(false);
                                                                        })
                                                                    }
                                                                }

                                                            }}>
                                                                {({
                                                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                                }) => (
                                                                    <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                                                        <div className="col-xl-12 pl-0 float-left">
                                                                            <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">Please Fill In Nominee Details</h6>
                                                                        </div>
                                                                        <div className="col-xl-10 p-0 float-left">
                                                                            <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">Title</label>
                                                                                    <Field as="select" name="name_title_id" onChange={handleChange} onBlur={handleBlur}>
                                                                                        <option value="0">select</option>
                                                                                        {nomineeTitles != undefined &&
                                                                                            nomineeTitles.length > 0 &&
                                                                                            nomineeTitles.map((title: APIData.Titles) => {
                                                                                                return <option value={title.name_title_id}>{title.name_title}</option>;
                                                                                            })}
                                                                                    </Field>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className={errors.nominee_fullname && touched.nominee_fullname ? "error" : "input-title"}>Full Name <span className="error-area">*</span></label>
                                                                                    <Field as="input" onChange={handleChange} onBlur={handleBlur} placeholder="Enter First Name" name="nominee_fullname" />
                                                                                </div>
                                                                                {errors.nominee_fullname && touched.nominee_fullname && (
                                                                                    <span className="error">{errors.nominee_fullname}</span>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">Relationship with Application</label>
                                                                                    <Field className="" name="relationship_with_applicant_id" as="select" onChange={handleChange} onBlur={handleBlur}>
                                                                                        <option value="0">select</option>
                                                                                        {relationshipDropdown != undefined && relationshipDropdown.length > 0 && relationshipDropdown.map((relation: APIData.Relationship, index) => {
                                                                                            return (
                                                                                                <option key={index} value={relation.relationship_with_applicant_id} >{relation.relationship_with_applicant}</option>
                                                                                            )
                                                                                        })

                                                                                        }
                                                                                    </Field>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">Nominee Identification</label>
                                                                                    <Field as="select" className="" name="nominee_identification_id" onChange={(e: any) => {
                                                                                        if (e.target.value == 0) {
                                                                                            e.preventDefault();
                                                                                            handleChange(e);
                                                                                            handleBlur(e);
                                                                                            setProofType('Proof');
                                                                                        } else {
                                                                                            e.preventDefault();
                                                                                            handleChange(e);
                                                                                            handleBlur(e);
                                                                                            setProofType(e.nativeEvent.target[e.target.selectedIndex].text);
                                                                                        };
                                                                                    }
                                                                                    }>
                                                                                        <option>select</option>
                                                                                        {nomineeDropdown != undefined &&
                                                                                            nomineeDropdown.length > 0 &&
                                                                                            nomineeDropdown.map((proof: APIData.NomineeProof, index) => {
                                                                                                return (
                                                                                                    <option key={index} value={proof.nominee_identification_id}>
                                                                                                        {proof.nominee_identification}
                                                                                                    </option>
                                                                                                );
                                                                                            })}
                                                                                    </Field>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">{proofType} Number</label>
                                                                                    <Field as="input" placeholder={"Enter" + " " + proofType + " " + "Number"} onChange={handleChange} onBlur={handleBlur} name="nominee_identification_number" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className="input-title">Mobile Number</label>
                                                                                    <Field as="input" maxLength={10} placeholder="Enter Mobile Number" onChange={handleChange} onBlur={handleBlur} name="nominee_mobile_number" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                <div className="email-input input_field pl-0">
                                                                                    <label className={errors.nominee_dob ? "error" : "input-title"}>Date of Birth</label>

                                                                                    <DatePicker peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" dateFormat="dd-MM-yyyy" placeholderText="DD/MM/YYYY" maxDate={new Date(maxDate)} minDate={new Date("1900-01-01")} className="date-picker-input profile-calendar mobile_number" name="nominee_dob" selected={startDate1} onChange={(date: any) => setStartDate1(date)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                       
                                                                        {minor1 == true && values.nominee_guardian_records != undefined && (
                                                                            <Fragment>
                                                                                <div className="col-xl-12 p-0 float-left guardian-details">
                                                                                    <h3>
                                                                                        <label className="input-title nominee-guardian-title">Guardian Details</label>
                                                                                    </h3>
                                                                                    <div className="email-input input_field pl-0">
                                                                                        <div className="col-xl-8 p-0 float-left">
                                                                                            <div className="col-xl-6 pl-0 float-left  ">
                                                                                                <div className="email-input input_field pl-0">
                                                                                                    <label className="input-title">Title</label>
                                                                                                    <Field
                                                                                                        as="select"
                                                                                                        name="nominee_name_title_id"
                                                                                                        onChange={handleChange} onBlur={handleBlur}
                                                                                                    >
                                                                                                        <option value={""}>select</option>
                                                                                                        {nomineeTitles != undefined &&
                                                                                                            nomineeTitles.length > 0 &&
                                                                                                            nomineeTitles.map((title: APIData.Titles) => {
                                                                                                                return <option value={title.name_title_id}>{title.name_title}</option>;
                                                                                                            })}
                                                                                                    </Field>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-6 pl-0 float-left  ">
                                                                                                <div className="email-input input_field pl-0">
                                                                                                    <label className={errors.nominee_fullname && touched.nominee_fullname ? "error" : "input-title"}>
                                                                                                        Full Name <span className="error-area">*</span>
                                                                                                    </label>
                                                                                                    <Field
                                                                                                        as="input"
                                                                                                        onChange={handleChange} onBlur={handleBlur}
                                                                                                        placeholder="Enter First Name"
                                                                                                        name="nominee_guardian_fullname"
                                                                                                    />
                                                                                                </div>
                                                                                                {errors.nominee_fullname && touched.nominee_fullname && <span className="error">{errors.nominee_fullname}</span>}
                                                                                            </div>
                                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                                <div className="email-input input_field pl-0">
                                                                                                    <label className={errors.nominee_guardian_records?.nominee_relationship_with_applicant_id && touched.nominee_guardian_records?.nominee_relationship_with_applicant_id ? "error" : "input-title"}>Relationship with Application</label>
                                                                                                    <Field
                                                                                                        className=""
                                                                                                        name="nominee_relationship_with_applicant_id"
                                                                                                        as="select"
                                                                                                        onChange={handleChange} onBlur={handleBlur}
                                                                                                    >
                                                                                                        <option value="0">select</option>
                                                                                                        {relationshipDropdown != undefined &&
                                                                                                            relationshipDropdown.length > 0 &&
                                                                                                            relationshipDropdown.map((relation: APIData.Relationship, index) => {
                                                                                                                return (
                                                                                                                    <option key={index} value={relation.relationship_with_applicant_id}>
                                                                                                                        {relation.relationship_with_applicant}
                                                                                                                    </option>
                                                                                                                );
                                                                                                            })}
                                                                                                    </Field>
                                                                                                </div>
                                                                                                {errors.nominee_guardian_records?.nominee_relationship_with_applicant_id && touched.nominee_guardian_records?.nominee_relationship_with_applicant_id && <span className="error">{errors.nominee_guardian_records?.nominee_relationship_with_applicant_id}</span>}
                                                                                            </div>
                                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                                <div className="email-input input_field pl-0">
                                                                                                    <label className="input-title">Guardian Identification</label>
                                                                                                    <Field
                                                                                                        as="select"
                                                                                                        className=""
                                                                                                        name="nominee_guardian_identification_id"
                                                                                                        onChange={(e: any) => {
                                                                                                            if (e.target.value == 0) {
                                                                                                                e.preventDefault();
                                                                                                                handleChange(e);
                                                                                                                handleBlur(e);
                                                                                                                setgProofType1("Proof");
                                                                                                            } else {
                                                                                                                e.preventDefault();
                                                                                                                handleChange(e);
                                                                                                                handleBlur(e);
                                                                                                                setgProofType1(e.nativeEvent.target[e.target.selectedIndex].text);
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <option>select</option>
                                                                                                        {nomineeDropdown != undefined &&
                                                                                                            nomineeDropdown.length > 0 &&
                                                                                                            nomineeDropdown.map((proof: APIData.NomineeProof, index) => {
                                                                                                                return (
                                                                                                                    <option key={index} value={proof.nominee_identification_id}>
                                                                                                                        {proof.nominee_identification}
                                                                                                                    </option>
                                                                                                                );
                                                                                                            })}
                                                                                                    </Field>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                                <div className="email-input input_field pl-0">
                                                                                                    <label className="input-title">{gproofType1} Number</label>
                                                                                                    <Field
                                                                                                        as="input"
                                                                                                        style={{ textTransform: 'uppercase' }}
                                                                                                        placeholder={"Enter" + " " + gproofType1 + " " + "Number"}
                                                                                                        maxLength={gproofType1 == "PAN" ? "10" : gproofType1 == "Passport" ? "8" : gproofType1 == "Aadhar" ? "12" : null}
                                                                                                        name="nominee_guardian_identification_number"
                                                                                                        onChange={handleChange} onBlur={handleBlur}
                                                                                                    />
                                                                                                    {panerror1 != null && panerror1 != undefined ? <span className="error">{panerror1}</span> : null}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-6 pl-0 float-left">
                                                                                                <div className="email-input input_field pl-0">
                                                                                                    <label className="input-title">Mobile Number</label>
                                                                                                    <Field
                                                                                                        as="input"
                                                                                                        maxLength={10}
                                                                                                        placeholder="Enter Mobile Number"
                                                                                                        name="nominee_guardian_mobile_number"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Fragment>
                                                                        )}
                                                                        <div className="col-xl-8 p-0 float-left">
                                                                            <div className="email-input input_field pl-0">
                                                                                <label className="input-title">Address (Same as the Applicant)</label>
                                                                                <textarea rows={4} disabled className="text-field" value={(userDetail?.address_line_1) + "," + (userDetail?.address_line_2) + "," + "\n" + (userDetail?.address_city) + " - " + (userDetail?.address_zip) + "," + "\n" + (userDetail?.address_state) + "."
                                                                                }></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-4 p-0 float-left">

                                                                        </div>
                                                                        <div className="col-xl-12 p-0 mt-10 mb-10 float-left"><input type="checkbox" id="terms" onChange={(e: any) => {
                                                                            if (e.target.checked) {
                                                                                setNomineeAddress(true);
                                                                            } else {
                                                                                setNomineeAddress(false);
                                                                            }

                                                                        }} /> Got a different Address</div>
                                                                        {nomineeAddress == true &&
                                                                            <Fragment>
                                                                                <div className="col-xl-12 pl-0 float-left">
                                                                                    <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">Enter Address Detail</h6>
                                                                                </div>
                                                                                <div className="col-xl-8 p-0 float-left">
                                                                                    <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                        <div className="email-input input_field pl-0">
                                                                                            <label className={errors.nominee_address_line_1 && touched.nominee_address_line_1 ? "error" : "input-title"}>Address line 1 <span className="error-area">*</span></label>
                                                                                            <Field as="input" className={errors.nominee_address_line_1 && touched.nominee_address_line_1 ? "input-error" : ""} type="text" placeholder="Enter Address line 1" name="nominee_address_line_1" onChange={handleChange} />
                                                                                            {errors.nominee_address_line_1 && touched.nominee_address_line_1 && (
                                                                                                <span className="error">{errors.nominee_address_line_1}</span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xl-6 pl-0 float-left mt-20">
                                                                                        <div className="email-input input_field pl-0">
                                                                                            <label className={errors.nominee_address_line_2 && touched.nominee_address_line_2 ? "error" : "input-title"}>Address line 2 <span className="error-area">*</span></label>
                                                                                            <Field as="input" className={errors.nominee_address_line_2 && touched.nominee_address_line_2 ? "input-error" : ""} type="text" placeholder="Enter Address line 2" name="nominee_address_line_2" onChange={handleChange} />
                                                                                            {errors.nominee_address_line_2 && touched.nominee_address_line_2 && (
                                                                                                <span className="error">{errors.nominee_address_line_2}</span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className="col-xl-6 pl-0 float-left">
                                                                                        <div className="email-input input_field pl-0">
                                                                                            <label className={errors.address_zip && touched.address_zip ? "error" : "input-title"}>Pincode <span className="error-area">*</span></label>
                                                                                            <Field as="input" className={errors.address_zip && touched.address_zip ? "input-error" : ""} type="text" placeholder="Enter Pincode" name="address_zip" maxLength={6} onChange={(e: any) => {
                                                                                                handleChange(e);
                                                                                            }}

                                                                                                onBlur={(e: any) => {
                                                                                                    setPincode(e.target.value);
                                                                                                }} />
                                                                                            {errors.address_zip && touched.address_zip && (
                                                                                                <span className="error">{errors.address_zip}</span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div> */}
                                                                                    <div className="col-xl-6 pl-0 float-left">
                                                                                        <div className="email-input input_field pl-0">
                                                                                            <label className={errors.nominee_address_state && touched.nominee_address_state ? "error" : "input-title"}>State <span className="error-area">*</span></label>
                                                                                            <Field as="input" className={errors.nominee_address_state && touched.nominee_address_state ? "input-error" : ""} type="text" placeholder="Enter State" name="nominee_address_state" onChange={handleChange} />
                                                                                            {errors.nominee_address_state && touched.nominee_address_state && (
                                                                                                <span className="error">{errors.nominee_address_state}</span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xl-6 pl-0 float-left">
                                                                                        <div className="email-input input_field pl-0">
                                                                                            <label className={errors.nominee_address_city && touched.nominee_address_city ? "error" : "input-title"}>City <span className="error-area">*</span></label>
                                                                                            <Field as="input" className={errors.nominee_address_city && touched.nominee_address_city ? "input-error" : ""} type="text" placeholder="Enter City" name="nominee_address_city" onChange={handleChange} />
                                                                                            {errors.nominee_address_city && touched.nominee_address_city && (
                                                                                                <span className="error">{errors.nominee_address_city}</span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Fragment>
                                                                        }
                                                                        <div className="col-xl-4 p-0 float-left">

                                                                        </div>
                                                                        <div className="col-xl-12 p-0 float-left text-center mt-100">
                                                                            <button type="submit" className="theme_btn primary_btn_one sub-btn wow w-auto" data-wow-delay="0.5s">Continue</button>
                                                                        </div>
                                                                    </form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Profile-details-edit-area end */}
            </main>
        </div >
    )
}

export default EditAddNominee;
