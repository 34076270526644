import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";



const KYCSuccess: React.FC = () => {
    return (
        <div className="main-index-wrapper">
            <main>
                {/* subscribe-letter-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>
                </section>
                    <section className="otp_popup">
                        <div className="blur_back"></div>
                        <div className="container custom-container-subs leaf_bg">
                            <div className="sub-bg pt-85 pb-75 pr-100 pl-100 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-md-6 wow  text-center" data-wow-delay="0.3s">
                                        <Link to={PageLinks.KYC_SUCCESS}>
                                            <div className="do-box do-box-2 mb-30">
                                                <div className="icon mb-25 icon_img">
                                                    <img src="assets/img/icon/Thumbs_up.png" alt="" />
                                                </div>
                                                <h5 className="otp_popup_text">Your form has been received successfully.</h5>
                                                <Link to={PageLinks.KYC_SUCCESS} className="theme_btn btn-change  esign_btn sub-btn wow " data-wow-delay="0.5s">Alright, Continue</Link>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                {/* subscribe-letter-area end */}
            </main>
        </div>
    )
}

export default KYCSuccess;
