import { useAppState, StateActionTypes } from "./AppState";

export const useAppStateAPI = () => {
    const { state, dispatch } = useAppState();

    return {
        showPreloader: state.showPreloader,
        setShowPreloader: (showPreloader) => dispatch({ type: StateActionTypes.SET_SHOW_PRELOADER, showPreloader: showPreloader }),
        setShowKycStatus: (kycStatus) => dispatch({ type: StateActionTypes.SET_KYC_STATUS, kycStatus: kycStatus }),
        SGBBond: state.SGBBond,
        setSGBData: (sgb_order_quantity, sgb_order_amount,bond_id,sgb_order_demant_account_id,quantity_type) => dispatch({ type: StateActionTypes.SET_SGB_BOND, SGBBond: { sgb_order_quantity: sgb_order_quantity, sgb_order_amount: sgb_order_amount,bond_id:bond_id,sgb_order_demant_account_id:sgb_order_demant_account_id,quantity_type:quantity_type} }),
        IPOBond: state.IPOBond,
        setIPOData: (ipo_order_quantity, ipo_order_amount,bond_id) => dispatch({ type: StateActionTypes.SET_IPO_BOND, IPOBond: { ipo_order_quantity: ipo_order_quantity, ipo_order_amount: ipo_order_amount,bond_id:bond_id} }),
    }
}

