import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Footer from "../components/template/Footer";
import Subscribe from "../components/Subscribe";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "../common/Constants";

const ContactUs: React.FC = () => {
const navigate = useNavigate();
    return (
        <div className="main-index-wrapper">
            <Header />
            
            <div className="body-overlay"></div>
            <main>
                <section className="contact_us page-title-area pb-30 pt-110 pt-md-110 pt-xs-110">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li className="active">Contact us</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center pt-30">
                            <div className="col-lg-5 offset-lg-1">
                                <div className="page-title-wrapper page-title-blog">
                                    <img src="assets/img/icon/contact.png" />
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="page-title-wrapper pl-45 pl-md-0 pl-xs-0">
                                    <h1 className="contact_head">CONTACT US</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="address-details-area pb-30 pt-30 pb-md-20 pb-xs-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="address-box text-center mb-50 pl-45 pr-45">
                                    <img className="loc-icon mb-25" src="assets/img/icon/contact_location.png" alt="" />
                                    <h4 className="sub-title mb-25">Our Location</h4>
                                    <p className="contact-us-address-content">Mumbai (Corporate Office)<br/>
                                        1202, Naman Centre, Bandra Kurla Complex, Bandra (East), Mumbai -400 051 </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="address-box text-center mb-50 pl-45 pr-45">
                                    <img className="loc-icon mb-25" src="assets/img/icon/contact_email.png" alt="" />
                                    <h4 className="sub-title mb-25">Email &amp; Phone</h4>
                                    <p className="contact-us-address-content">Support@trustmony.com<br /> 022 40845045<br />022 40845070 <br/>022 42245246</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="address-box text-center mb-50 pl-45 pr-45">
                                    <img className="loc-icon mb-25" src="assets/img/icon/chain.svg" alt="" />
                                    <h4 className="sub-title mb-25">Get In Touch</h4>
                                    <p className="contact-us-address-content">Also find us social media below</p>
                                    <div className="footer-social">
                                        <a href="#"><i className="fab fa-facebook-f contact-us-social-icons"></i></a>
                                        <a href="#"><i className="fab fa-twitter contact-us-social-icons"></i></a>
                                        <a href="#"><i className="fab fa-linkedin-in contact-us-social-icons"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="address-details-area pb-30 pb-md-20 pb-xs-20">
                    <img src="../assets/img/under_construction.jpg" className="w-100" />
                </section> */}
                <section className="contact-form-area pt-30 pb-60 pb-xs-0">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="map-area">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1885.5121006086306!2d72.86023795799152!3d19.06267353189735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8e70d20a9f3%3A0x16b9b71f98ccac60!2sBandra%20Kurla%20Complex%20Rd%2C%20Mumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1675325950380!5m2!1sen!2sin" width="800" height="600"  loading="lazy"></iframe>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="contact-form pl-40 pl-lg-0 pl-md-0 pl-xs-0">
                                    <h3 className="blog-details-title mb-45">Don’t hesitate to drop a <br></br> line to us.</h3>
                                    <form className="quote-form" action="#">
                                        <div className="email-input">
                                            <label className="input-title contact-us-title">Your Name</label>
                                            <input type="text" className="contact-us-content" />
                                        </div>
                                        <div className="email-input">
                                            <label className="input-title contact-us-title">Email</label>
                                            <input type="text" className="contact-us-content" />
                                        </div>
                                        <div className="email-input">
                                            <label className="input-title contact-us-title">Your Message</label>
                                            <textarea name="message" className="contact-us-content" id="message"></textarea>
                                        </div>
                                        <button onClick={(e:any)=>{
                                            e.preventDefault();
                                            navigate(PageLinks.ENQUIRY_ADDED_SUCCESS);
                                        }} className="explore_btn mt-5">Mail to <i className="fas fa-arrow-right"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Subscribe />
            </main>
            <Footer />
        </div>
    )
}

export default ContactUs;
