import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { LoginDetails } from "../common/Utilities";
import { InitialData } from "../components/Form/InitialData";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Countdown from 'react-countdown';



const VerifyOTPLogin: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const prevData: any = location.state;
    const [otp, setOtp] = useState(InitialData.verify_otp);
    const [otpSuccess, setOTPSuccess] = useState(false);
    const [resendOtp, setResendOtp] = useState(false);
    const { setShowPreloader } = useAppStateAPI();
    const [otpResend, setOTPResend] = useState(Date.now() + 30000);
    let logintype = localStorage.getItem('loginType');
    let redirection = localStorage.getItem('redirection');
    let isin = localStorage.getItem('isin');
    let id = localStorage.getItem('ipoid');

    const reSend_otp = () => {
        if (logintype == 'login') {
            localStorage.setItem('animation', 'default');
            setShowPreloader(true);
            ServerAPI.Login_resend_otp().then((res) => {
                setResendOtp(true);
                toast.success(res['message'])
            }).finally(() => {
                disableButton();
                setShowPreloader(false);
            })
        } else {
            localStorage.setItem('animation', 'default');
            setShowPreloader(true);
            ServerAPI.resend_otp().then((res) => {
                setResendOtp(true);
                toast.success(res['message'])
            }).finally(() => {
                disableButton();
                setShowPreloader(false);
            })
        }

    }

    const disableButton = () => {
        setTimeout(function () {
            setResendOtp(false);
        }, 30000);
    }

    useEffect(() => {
        if (otpSuccess == true) {
            if (redirection == 'SGB') {
                navigate({
                    pathname: PageLinks.APPLYBONDPURCHASE,
                    search: "?isin=" + isin,
                });
            } else if (redirection == 'IPO') {
                navigate({
                    pathname: PageLinks.BOND_IPO_PURCHASE,
                    search: "?id=" + id,
                });
            } else {
                setShowPreloader(true);
                ServerAPI.userDetailData().then((res: APIData.userDetail) => {
                    if(res!=undefined){
                        if(res['ekyc_application_status']=='1'){
                            navigate(PageLinks.PROFILE);
                        }else{
                            navigate(PageLinks.INSTRUCTION_SCREEN);
                        }
                    }
                }).finally(() => {
                    setShowPreloader(false);
                })
            }
            localStorage.removeItem('redirection');
        }

    }, [otpSuccess])


    return (
        <div className="main-index-wrapper">
            <main>
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg pr-150 pl-150 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-11">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                            <img src="assets/img/logo/header_logo_one.png" className="main_logo" alt="logo" />
                                            <h4 className="sub-title-login wow " data-wow-delay="0.3s">OTP Verification</h4>
                                            <p className="review-text text-center">Enter the 6 digits, One Time Password sent on your number <br></br>
                                                {logintype == 'signup' ?
                                                    <Link to={PageLinks.SIGNUP} className="mobile_number">+91 {localStorage.getItem('mobile')} <i className="far fa-edit"></i></Link>
                                                    :
                                                    <Link to={PageLinks.LOGIN} className="mobile_number">+91 {localStorage.getItem('mobile')} <i className="far fa-edit"></i></Link>
                                                }
                                            </p>
                                            <Formik initialValues={otp} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                if (logintype != undefined) {
                                                    if (logintype == 'signup') {
                                                        setShowPreloader(true);
                                                        ServerAPI.verifyOtp(values.otp).then((res: any) => {
                                                            if (res != undefined) {
                                                                const userDetail = res;
                                                                const currentTime = new Date().getTime();
                                                                LoginDetails(userDetail["customer_id"], userDetail["first_name"], userDetail["last_name"], userDetail["refresh_token"], userDetail["token"], userDetail['mobile_number'], userDetail["last_login"], currentTime);
                                                                setOTPSuccess(true);
                                                            } else {
                                                                setOTPSuccess(false);

                                                            }
                                                        }).finally(() => {
                                                            setShowPreloader(false);
                                                        })

                                                    } else {
                                                        setShowPreloader(true);
                                                        ServerAPI.verify_Login_Otp(values.otp).then((res: any) => {
                                                            if (res != undefined) {
                                                                const userDetail = res;
                                                                const currentTime = new Date().getTime();
                                                                LoginDetails(userDetail["customer_id"], userDetail["first_name"], userDetail["last_name"], userDetail["refresh_token"], userDetail["token"], userDetail['mobile_number'], userDetail["last_login"],currentTime);
                                                                setOTPSuccess(true);
                                                            } else {
                                                                setOTPSuccess(false);

                                                            }
                                                        }).finally(() => {
                                                            setShowPreloader(false);
                                                        })
                                                    }
                                                }

                                            }}>
                                                {({
                                                    values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                }) => (
                                                    <form className="login-form quote-form mb-20" onSubmit={handleSubmit}>
                                                        <div className="email-input input_field">
                                                            <input type="password" placeholder="Enter OTP"
                                                                onChange={handleChange}
                                                                onBlur={(e: any) => {
                                                                    handleBlur(e);
                                                                    disableButton();
                                                                }}
                                                                name="otp"
                                                                value={values.otp}
                                                                maxLength={6}
                                                                className="mobile_number" />
                                                            <label className="input-title text-color">Didn’t get OTP?</label>
                                                            <div className="text-end">
                                                                {resendOtp == true ?
                                                                    <Countdown
                                                                        date={otpResend}
                                                                        intervalDelay={0}
                                                                        renderer={(props) => <p className="review-text otp-counter">Get in&nbsp;{props.seconds}&nbsp;Seconds</p>}
                                                                    />
                                                                    :
                                                                    <button type="button" className={"theme_btn possion-releactive resend_otp_btn_1_enabled sub-btn wow"} data-wow-delay="0.5s" onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        reSend_otp();
                                                                        setOTPResend(Date.now() + 30000);
                                                                    }}>Resend OTP</button>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="divider_150"></div>
                                                        <div className="email-input mobile-input input_field pl-0 login-btn">
                                                            <button type="submit" className={values.otp.length == 6 ? "theme_btn btn-change primary_btn_1 sub-btn wow otp-verify" : "theme_btn primary_btn sub-btn wow w-100 otp-verify"} data-wow-delay="0.5s" >Verify</button>
                                                        </div>
                                                    </form>

                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default VerifyOTPLogin;
