
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { Annual_income_status, Gender, PageLinks, Selected_marital_status, TradingExperience } from "../../common/Constants";
import { APIData } from "../../common/DataTypes";
import { ServerAPI } from "../../common/ServerAPI";
import ProfileUpdateSuccess from "./ProfileUpdateSuccess";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
    updateKey: string,
    reloadList: any,
    addressProofID:number
}


const ProfileUpdate: React.FC<FormParameters> = ({ showModal, closeModal, updateKey, reloadList,  addressProofID }) => {
    const [gender, setGender] = useState(Gender.NotSelected);
    const [maritalStatus, setMaritalStatus] = useState(Selected_marital_status.NotSelected);
    const [annualIncome, setAnnualIncome] = useState(Annual_income_status.NotSelected);
    const [tradingExperience, setTradingExperience] = useState(TradingExperience.NotSelected);
    const [mothersMaidenName, setMothersMaidenName] = useState('');
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [userDetail, setUserDetails] = useState<any>();
    const [addressProof, setAddressProof] = useState<APIData.AddressProof[]>([]);
    const [stateValue, setStateValue] = useState<any[]>([]);
    const [cityValue, setCityValue] = useState<any[]>([]);
    const [state, getState] = useState();
    const [addressProofType, setAddressProofType] = useState('Address Proof');
    const [imageFront, setImageFront] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [pincode, setPincode] = useState('');
    const [imageRear, setImageRear] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const { setShowPreloader } = useAppStateAPI();

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res) => {
            setUserDetails(res)
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
        ServerAPI.AddressProof().then((response: APIData.AddressProof[]) => {
            setAddressProof(response);
        }).finally(() => {
            setShowPreloader(false);
        })
        
        setShowPreloader(true);
        ServerAPI.State().then((res: any) => {
            setStateValue(res["data"])
        }).finally(() => {
            setShowPreloader(false);
        })
    }, [])

    useEffect(() => {
        setShowPreloader(true);
        if (pincode != '') {
            ServerAPI.pincodeSearch(pincode).then((response: any) => {
                getState(response[0]['pincode_state_name']);
                setCityValue(response[0]['pincode_district']);
            }).finally(() => {
                setShowPreloader(false);
            })
        }

    }, [pincode])

    const updateProfileDetails = () => {
        let values: APIData.userDetail = { ...userDetail };
        if (updateKey == 'address') {
            values.address_line_1 = addressLine1;
            values.address_proof_id = addressProofID;
            values.address_line_2 = addressLine2
            values.proof_type = addressProofType;
            values.proof_front_image = imageFront;
            values.proof_back_image = imageRear;
            values.address_zip = pincode;
            values.address_state = selectedState;
            values.address_city = selectedCity;
        } else if (updateKey == 'gender') {
            values.gender = gender;
        } else if (updateKey == 'marital_status') {
            values.married_status = maritalStatus;

        } else if (updateKey == 'annual_income') {
            values.annual_income = annualIncome;

        } else if (updateKey == 'trading_experience') {
            values.trading_experience = tradingExperience;

        } else if (updateKey == 'mothers_maiden_name') {
            values.mothers_maiden_name = mothersMaidenName;
        }
        setShowPreloader(true);
        ServerAPI.add_profile_details(values).then((res) => {
            if (res['message'] != undefined) {
                toast.success(res['message']);
                closeModal();
                setUpdateSuccess(true);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const getCity = (e: any) => {
        e.preventDefault();
        let id = Number(e.target.value)
        getState(e.target.value);
        setShowPreloader(true);
        ServerAPI.City(id).then((res: APIData.cityList[]) => {
            setCityValue(res)
        }).finally(() => {
            setShowPreloader(false);
        })

    }

    const loadUploadedfile = (fileInput: any, imageSide: string) => {
        let filetype = fileInput.files[0].type;
        filetype = filetype.split("/")[0];
        if (filetype == 'image') {
            setShowPreloader(true);
            if (imageSide == 'front') {
                ServerAPI.proofImageUpload(addressProofID, fileInput.files[0]).then(response => {
                    if (response['front-image'] != undefined && response['front-image'] != "") {
                        setImageFront(response['front-image']);
                        toast.success('Image Uploaded Successfully');
                    }
                }).finally(() => {
                    setShowPreloader(false);
                });
            } else if (imageSide == 'rear') {
                ServerAPI.proofImageUpload(addressProofID, '', fileInput.files[0]).then(response => {
                    if (response['back-image'] != undefined && response['back-image'] != "") {
                        setImageRear(response['back-image']);
                        toast.success('Image Uploaded Successfully');
                    }
                }).finally(() => {
                    setShowPreloader(false);
                });
            } else {
                toast.error('unable to upload the image please try again!');
            }
        } else {
            toast.error("Uploaded file is not an Image!");
        }
    }


    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog
                    open={showModal}
                    keepMounted
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    className="empty-bg"
                    scroll="body"
                    
                >
                    <DialogContent className="profile-update-bg empty-bg" style={{ overflow: "hidden" }}>
                        {updateKey != 'address' ?
                            <p className="email_update_text_p text-start mb-3">Update Your Info !</p>
                            :
                            <Fragment>
                                <p className="email_update_text text-start mb-3">Update Address Details</p>
                                <hr />
                            </Fragment>
                        }
                        <div>
                            <div className="choice-list">
                                {updateKey == 'gender' &&
                                    <Fragment>
                                        <span className="email_update_text">What is your gender?</span>
                                        <span className="input-title-filled gender_col mb-3" role={"group"}>
                                            <div className={gender == Gender.Male ? "gender_btn selected" : "gender_btn"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setGender(Gender.Male);
                                                    }
                                                }} type="radio" value={1} checked={gender == Gender.Male ? true : false} className="d-none" name="gender" id="gender_option_1" />
                                                <label
                                                    htmlFor="gender_option_1"
                                                    className={gender == Gender.Male ? "gender-btn-label-selected" : "gender_btn-label-profile-profile"}
                                                >
                                                    Male
                                                </label>
                                            </div>
                                            <div className={gender == Gender.Female ? "gender_btn selected" : "gender_btn"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setGender(Gender.Female);
                                                    }
                                                }} type="radio" value={2} checked={gender == Gender.Female ? true : false} className="d-none" name="gender" id="gender_option_2" />
                                                <label
                                                    htmlFor="gender_option_2"
                                                    className={gender == Gender.Female ? "gender-btn-label-selected" : "gender_btn-label-profile-profile"}
                                                >
                                                    Female
                                                </label>
                                            </div>
                                            <div className={gender == Gender.Trans ? "gender_btn selected" : "gender_btn"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setGender(Gender.Trans);
                                                    }
                                                }} type="radio" value={3} checked={gender == Gender.Trans ? true : false} className="d-none" name="gender" id="gender_option_3" />
                                                <label
                                                    htmlFor="gender_option_3"
                                                    className={gender == Gender.Trans ? "gender-btn-label-selected" : "gender_btn-label-profile-profile"}
                                                >
                                                    Trans
                                                </label>
                                            </div>
                                        </span>
                                    </Fragment>
                                }
                                {updateKey == 'marital_status' &&
                                    <Fragment>
                                        <span className="email_update_text">What is your marital status?</span>
                                        <span className="input-title-filled gender_col" role={"group"}>
                                            <div className={maritalStatus == Selected_marital_status.Single ? "gender_btn selected" : "gender_btn"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setMaritalStatus(Selected_marital_status.Single);
                                                    }

                                                }} type="radio" value={1} checked={maritalStatus == Selected_marital_status.Single ? true : false} className="d-none" name="married_status" id="marital_status_option_1" />
                                                <label
                                                    htmlFor="marital_status_option_1"
                                                    className={maritalStatus == Selected_marital_status.Single ? "gender-btn-label-selected" : "gender_btn-label-profile"}
                                                >
                                                    Single
                                                </label>
                                            </div>
                                            <div className={maritalStatus == Selected_marital_status.Married ? "gender_btn selected" : "gender_btn"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setMaritalStatus(Selected_marital_status.Married);
                                                    }

                                                }} type="radio" value={2} checked={maritalStatus == Selected_marital_status.Married ? true : false} className="d-none" name="married_status" id="marital_status_option_2" />
                                                <label
                                                    htmlFor="marital_status_option_2"
                                                    className={maritalStatus == Selected_marital_status.Married ? "gender-btn-label-selected" : "gender_btn-label-profile"}
                                                >
                                                    Married
                                                </label>
                                            </div>
                                            <div className={maritalStatus == Selected_marital_status.Others ? "gender_btn selected" : "gender_btn"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setMaritalStatus(Selected_marital_status.Others);
                                                    }

                                                }} type="radio" value={3} checked={maritalStatus == Selected_marital_status.Others ? true : false} className="d-none" name="married_status" id="marital_status_option_3" />
                                                <label
                                                    htmlFor="marital_status_option_3"
                                                    className={maritalStatus == Selected_marital_status.Others ? "gender-btn-label-selected" : "gender_btn-label-profile"}
                                                >
                                                    Other
                                                </label>
                                            </div>
                                        </span>
                                    </Fragment>
                                }
                                {updateKey == 'annual_income' &&
                                    <Fragment>
                                        <span className="email_update_text">What is your annual income?</span>
                                        <span className="input-title-filled gender_col" role={"group"}>
                                            <div className={annualIncome == Annual_income_status.below_one ? " gender_btn_selected selected" : " gender_btn_selected"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setAnnualIncome(Annual_income_status.below_one);
                                                    }

                                                }} type="radio" value={1} checked={annualIncome == Annual_income_status.below_one ? true : false} className="d-none" name="annual_income" id="income_option_1" />
                                                <label
                                                    htmlFor="income_option_1"
                                                    className={annualIncome == Annual_income_status.below_one ? "gender-btn-label-selected1" : "gender_btn-label-profile"}
                                                >
                                                    Below 1 Lakh
                                                </label>
                                            </div>
                                            <div className={annualIncome == Annual_income_status.one_to_five ? " gender_btn_selected selected" : " gender_btn_selected"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setAnnualIncome(Annual_income_status.one_to_five);
                                                    }

                                                }} type="radio" value={2} checked={annualIncome == Annual_income_status.one_to_five ? true : false} className="d-none" name="annual_income" id="income_option_2" />
                                                <label
                                                    htmlFor="income_option_2"
                                                    className={annualIncome == Annual_income_status.one_to_five ? "gender-btn-label-selected1" : "gender_btn-label-profile"}
                                                >
                                                    1 - 5 Lakhs
                                                </label>
                                            </div>
                                            <div className={annualIncome == Annual_income_status.five_to_ten ? "  gender_btn_selected selected" : " gender_btn_selected"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setAnnualIncome(Annual_income_status.five_to_ten);
                                                    }

                                                }} type="radio" value={3} checked={annualIncome == Annual_income_status.five_to_ten ? true : false} className="d-none" name="annual_income" id="income_option_3" />
                                                <label
                                                    htmlFor="income_option_3"
                                                    className={annualIncome == Annual_income_status.five_to_ten ? "gender-btn-label-selected1" : "gender_btn-label-profile"}
                                                >
                                                    5 - 10 Lakhs
                                                </label>
                                            </div>
                                            <div className={annualIncome == Annual_income_status.ten_to_twentyfive ? " gender_btn_selected selected" : " gender_btn_selected"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setAnnualIncome(Annual_income_status.ten_to_twentyfive);
                                                    }

                                                }} type="radio" value={4} checked={annualIncome == Annual_income_status.ten_to_twentyfive ? true : false} className="d-none" name="annual_income" id="income_option_4" />
                                                <label
                                                    htmlFor="income_option_4"
                                                    className={annualIncome == Annual_income_status.ten_to_twentyfive ? "gender-btn-label-selected1" : "gender_btn-label-profile"}
                                                >
                                                    10 - 25 Lakhs
                                                </label>
                                            </div>
                                            <div className={annualIncome == Annual_income_status.above_twntyfive ? " gender_btn_selected selected" : " gender_btn_selected"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setAnnualIncome(Annual_income_status.above_twntyfive);
                                                    }

                                                }} type="radio" value={5} checked={annualIncome == Annual_income_status.above_twntyfive ? true : false} className="d-none" name="annual_income" id="income_option_5" />
                                                <label
                                                    htmlFor="income_option_5"
                                                    className={annualIncome == Annual_income_status.above_twntyfive ? "gender-btn-label-selected1" : "gender_btn-label-profile"}
                                                >
                                                    Above 25 Lakhs
                                                </label>
                                            </div>
                                        </span>
                                    </Fragment>
                                }
                                {updateKey == 'trading_experience' &&
                                    <Fragment>
                                        <span className="email_update_text">What is your trading experience?</span>
                                        <span className="input-title-filled gender_col" role={"group"}>
                                            <div className={tradingExperience == TradingExperience.less_than_one ? "gender_btn selected" : "gender_btn"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setTradingExperience(TradingExperience.less_than_one);
                                                    }

                                                }} type="radio" value={1} checked={tradingExperience == TradingExperience.less_than_one ? true : false} className="d-none" name="trading_experience" id="trading_option_1" />
                                                <label
                                                    htmlFor="trading_option_1"
                                                    className={tradingExperience == TradingExperience.less_than_one ? "gender-btn-label-selected" : "gender_btn-label-profile"}
                                                >
                                                    Less than 1 year 
                                                </label>
                                            </div>
                                            <div className={tradingExperience == TradingExperience.one_to_two ? "gender_btn selected" : "gender_btn"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setTradingExperience(TradingExperience.one_to_two);
                                                    }

                                                }} type="radio" value={2} checked={tradingExperience == TradingExperience.one_to_two ? true : false} className="d-none" name="trading_experience" id="trading_option_2" />
                                                <label
                                                    htmlFor="trading_option_2"
                                                    className={tradingExperience == TradingExperience.one_to_two ? "gender-btn-label-selected" : "gender_btn-label-profile"}
                                                >
                                                    1 - 2 Years
                                                </label>
                                            </div>
                                            <div className={tradingExperience == TradingExperience.two_to_five ? "gender_btn selected" : "gender_btn"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setTradingExperience(TradingExperience.two_to_five);
                                                    }

                                                }} type="radio" value={3} checked={tradingExperience == TradingExperience.two_to_five ? true : false} className="d-none" name="trading_experience" id="trading_option_3" />
                                                <label
                                                    htmlFor="trading_option_3"
                                                    className={tradingExperience == TradingExperience.two_to_five ? "gender-btn-label-selected" : "gender_btn-label-profile"}
                                                >
                                                    2 - 5 Years
                                                </label>
                                            </div>
                                            <div className={tradingExperience == TradingExperience.five_to_ten ? "gender_btn selected" : "gender_btn"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setTradingExperience(Annual_income_status.ten_to_twentyfive);
                                                    }

                                                }} type="radio" value={4} checked={tradingExperience == TradingExperience.five_to_ten ? true : false} className="d-none" name="trading_experience" id="trading_option_4" />
                                                <label
                                                    htmlFor="trading_option_4"
                                                    className={tradingExperience == TradingExperience.five_to_ten ? "gender-btn-label-selected" : "gender_btn-label-profile"}
                                                >
                                                    5 - 10 Years
                                                </label>
                                            </div>
                                            <div className={tradingExperience == TradingExperience.ten_to_twenty ? "gender_btn selected" : "gender_btn"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setTradingExperience(TradingExperience.ten_to_twenty);
                                                    }

                                                }} type="radio" value={5} checked={tradingExperience == TradingExperience.ten_to_twenty ? true : false} className="d-none" name="trading_experience" id="trading_option_5" />
                                                <label
                                                    htmlFor="trading_option_5"
                                                    className={tradingExperience == TradingExperience.ten_to_twenty ? "gender-btn-label-selected" : "gender_btn-label-profile"}
                                                >
                                                    10 - 20 Years
                                                </label>
                                            </div>
                                            <div className={tradingExperience == TradingExperience.twenty_to_twenty_five ? "gender_btn selected" : "gender_btn"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setTradingExperience(TradingExperience.twenty_to_twenty_five);
                                                    }

                                                }} type="radio" value={6} checked={tradingExperience == TradingExperience.twenty_to_twenty_five ? true : false} className="d-none" name="trading_experience" id="trading_option_6" />
                                                <label
                                                    htmlFor="trading_option_6"
                                                    className={tradingExperience == TradingExperience.twenty_to_twenty_five ? "gender-btn-label-selected" : "gender_btn-label-profile"}
                                                >
                                                    20 - 25 Years
                                                </label>
                                            </div>
                                            <div className={tradingExperience == TradingExperience.above_twenty_five ? "gender_btn selected" : "gender_btn"}>
                                                <input onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        setTradingExperience(TradingExperience.above_twenty_five);
                                                    }

                                                }} type="radio" value={7} checked={tradingExperience == TradingExperience.above_twenty_five ? true : false} className="d-none" name="trading_experience" id="trading_option_7" />
                                                <label
                                                    htmlFor="trading_option_7"
                                                    className={tradingExperience == TradingExperience.above_twenty_five ? "gender-btn-label-selected" : "gender_btn-label-profile"}
                                                >
                                                    Above 25 Years    
                                                </label>
                                            </div>
                                        </span>
                                    </Fragment>
                                }
                                {updateKey == 'mothers_maiden_name' &&
                                    <Fragment>
                                        <label className="email_update_text">Mother’s Maiden Name</label><br />
                                        <input type="text" name="mothers_maiden_name" placeholder="Enter Mother's Maiden Name" className="mb-3 profile-update-input" onChange={(e: any) => {
                                            setMothersMaidenName(e.target.value);
                                        }} /><br />
                                    </Fragment>
                                }
                                {updateKey == 'address' &&
                                    <Fragment>
                                        <form className="quote-form mb-10" >
                                            <Fragment>
                                                <div className="col-xl-6 pl-0 ">
                                                    <div className="email-input input_field pl-0">
                                                        <label className="email_update_text">Address Proof</label>
                                                        <select className="" placeholder="Select Address Proof" onChange={(e: any) => {
                                                            if (e.target.value == 0) {
                                                                setAddressProofType('Address Proof');
                                                            } else {
                                                                setAddressProofType(e.nativeEvent.target[e.target.selectedIndex].text);
                                                            };
                                                        }
                                                        }>
                                                            <option value={0}>Select</option>
                                                            {addressProof != undefined && addressProof.length > 0 && addressProof.map((proof: APIData.AddressProof, index) => {
                                                                return (
                                                                    <option key={index} value={proof.address_proof_id}>{proof.address_proof}</option>
                                                                )
                                                            })

                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-xl-12 pl-0 ">
                                                    <p className="error update-profile-address-helptext">*Enter your address details exactly as per your document otherwise your application will get rejected.</p>
                                                </div>
                                                <div className="col-xl-4 p-0 "></div>
                                                <div className="col-xl-12 pl-0 ">
                                                    <h6 className="pt-20 mb--10  pb-10 email_update_text text-start" data-wow-delay="0.1s">Enter Address Detail</h6>
                                                </div>
                                                <div className="col-xl-12 p-0 row mx-0">
                                                    <div className="col-xl-6 pl-0  mt-20">
                                                        <div className="email-input input_field pl-0">
                                                            <label className="email_update_text">Address line 1 <span className="error-area">*</span></label>
                                                            <input type="text" onChange={(e: any) => {
                                                                setAddressLine1(e.target.value);
                                                            }} placeholder="Enter Address line 1" name="Address_line_1" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 pl-0  mt-20">
                                                        <div className="email-input input_field pl-0">
                                                            <label className="email_update_text">Address line 2</label>
                                                            <input onChange={(e: any) => {
                                                                setAddressLine2(e.target.value);
                                                            }} type="text" placeholder="Enter Address line 2" name="Address_line_2" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 pl-0 ">
                                                        <div className="email-input input_field pl-0">
                                                            <label className="email_update_text">Pincode</label>
                                                            <input onBlur={(e: any) => {
                                                                setPincode(e.target.value);
                                                            }} type="text" placeholder="Enter Pincode" name="pincode" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 pl-0 ">
                                                        <div className="email-input input_field pl-0">
                                                            <label className="email_update_text">State</label>
                                                            <input value={state} type="text" placeholder="Enter State" name="state" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 pl-0 ">
                                                        <div className="email-input input_field pl-0">
                                                            <label className="email_update_text">City</label>
                                                            <input value={cityValue} type="text" placeholder="Enter City" name="city" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 pl-0"></div>
                                                    <div className="col-xl-6 pl-0 mt-3">
                                                        <div className="email-input input_field pl-0">
                                                            <div className="signature_preview_box_head">{addressProofType} Front Side View</div>
                                                            <div className="signature_preview_box_content bg-white">
                                                                <p className="signature_preview_box_content_text"> <Link to={"#"}>.</Link></p>
                                                                <div className="upload_img cursor">
                                                                    <label htmlFor="front">
                                                                        <img className="cursor"
                                                                            onClick={(e: any) => {
                                                                                if (imageFront != "") {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                            src={imageFront != undefined && imageFront != undefined && imageFront != "" ? imageFront : "assets/img/icon/Upload.png"} />
                                                                    </label>

                                                                    <input
                                                                        type="file"
                                                                        id="front"
                                                                        hidden
                                                                        name="demat_signature_image_2"
                                                                        onChange={(e: any) => {
                                                                            loadUploadedfile(e.target, 'front');
                                                                        }}
                                                                    />
                                                                </div>
                                                                {imageFront == "" &&
                                                                    <p className="upload_img_text">Upload your Image</p>
                                                                }
                                                                {imageFront != "" &&
                                                                    <Fragment>
                                                                        <div className="replace-btn-address-proof" >
                                                                            <label htmlFor="front">Replace</label></div>
                                                                    </Fragment>
                                                                }
                                                                <br />
                                                                <p className="signature_preview_box_content_text_one">upload your{' '}<Link to={'#'}>JPG</Link>, <Link to={'#'}>JPEG</Link> or PNG in less than <Link to={'#'}>10 MB</Link></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 pl-0  mt-3">
                                                        <div className="email-input input_field pl-0">
                                                            <div className="signature_preview_box_head">{addressProofType} Backside View</div>
                                                            <div className="signature_preview_box_content bg-white">
                                                                <p className="signature_preview_box_content_text"> <Link to={"#"}>.</Link></p>
                                                                <div className="upload_img cursor">
                                                                    <label htmlFor="back">
                                                                        <img className="cursor"
                                                                            onClick={(e: any) => {
                                                                                if (imageRear != "") {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                            src={imageRear != undefined && imageRear != undefined && imageRear != "" ? imageRear : "assets/img/icon/Upload.png"} />
                                                                    </label>

                                                                    <input
                                                                        type="file"
                                                                        id="back"
                                                                        hidden
                                                                        name="demat_signature_image_!"
                                                                        onChange={(e: any) => {
                                                                            loadUploadedfile(e.target, 'rear');
                                                                        }}
                                                                    />
                                                                </div>
                                                                {imageRear == "" &&
                                                                    <p className="upload_img_text">Upload your Image</p>
                                                                }
                                                                {imageRear != "" &&
                                                                    <div className="replace-btn-address-proof" >
                                                                        <label htmlFor="back">Replace</label></div>
                                                                }
                                                                <br />
                                                                <p className="signature_preview_box_content_text_one">upload your{' '} <Link to={'#'}>JPG</Link>, <Link to={'#'}>JPEG</Link> or PNG in less than <Link to={'#'}>10 MB</Link></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        </form>
                                    </Fragment>
                                }
                                <div className={updateKey == 'address' ? "text-middle" : ""}>
                                    <button className="profile-update-cancel-button" onClick={(e: any) => {
                                        e.preventDefault();
                                        closeModal();
                                    }}>Cancel</button>
                                    <button onClick={(e: any) => {
                                        e.preventDefault();
                                        updateProfileDetails();
                                    }} className="profile-update-update-button">Update</button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </main>
            <ProfileUpdateSuccess showModal={updateSuccess} closeModal={() => { setUpdateSuccess(false) }} reloadlist={reloadList} />
        </div>
    )
}

export default ProfileUpdate;