import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import InvestSteps from "../components/InvestSteps";
import Subscribe from "../components/Subscribe";
import NeedHelp from "../components/NeedHelp";
import Footer from "../components/template/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { bondListTypes, OrderStatus1, bondPurchaseTypes, PaymentStatus1, PageLinks } from "../common/Constants";
import { Fragment, useEffect, useState } from "react";
import OrderStepsComponent from "../components/OrderStepsComponent";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import moment from "moment";
import { numberWithCommas } from "../common/Utilities";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ReactPaginate from "react-paginate";
import SortBy from "../components/template/SortBy";
import SortByOrder from "../components/template/SortByOrder";
import { InitialData } from "../components/Form/InitialData";
import ModifyPopup from "../components/Modals/ModifyPopup";
import CanclePopup from "../components/Modals/CanclePopup";
import Copy from "../components/Svg/Copy";
import copy from 'copy-to-clipboard';
import { toast } from "react-hot-toast";

const OrderListing: React.FC = () => {
    const [orderOpenIndex, setorderOpenIndex] = useState('');
    const [ordersList, setOrdersList] = useState<APIData.order[]>([]);
    const [modify, setModifyModel] = useState(false);
    const [cancel, setCancel] = useState(false);
    const navigate = useNavigate();
    const [isin, setIsin] = useState('');
    const [orderID, setOrderID] = useState('0');
    const [pageno, setPageno] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [OrderNo, setOrderNo] = useState(0);
    const [OrderQty, setOrderQty] = useState(0);
    const [id, setId] = useState(0)
    const [bondtype, setBondType] = useState(0);
    const [cancledata, setCancledata] = useState<any>();
    const location = useLocation();
    // const [bondType, setBondType] = useState(0);
    const [selectedFilters, setSelectedFilters] = useState<APIData.OrderFilters>({ ...InitialData.OrderFilterData });

    useEffect(() => {

        if (location.search) {
            const query = new URLSearchParams(location.search);
            if (query.has("id")) {
                const orderID = query.get("id");
                setOrderID(orderID!);
            }
            // if (query.has("bond")) {
            //     const bondtype = query.get("bond");
            //     setBondType((bondtype!));
            // }
            // if (query.has("isin")) {
            //     const isin = query.get("isin");
            //     setIsin((isin!));
            // }
        }
    }, [location]);
    const orderslist = (pageno: number, selectedFilters: any) => {
        ServerAPI.GetOrders(pageno, selectedFilters).then((response: any) => {
            if (response != undefined) {
                setOrdersList(response['data']);
                setTotalCount(response['count']);
            }
        })
    }
    useEffect(() => {

        if (cancledata == bondListTypes.IPO || cancledata == bondListTypes.SGB || cancledata == bondListTypes.ADDEDBOND) {

            orderslist(pageno, selectedFilters)
        }
    }, [cancledata])

    const [openDropDown, setOpenDropDown] = useState(true);
    const toggleDropdown = () => setOpenDropDown(!openDropDown);

    useEffect(() => {
        console.log(pageno);
        console.log(selectedFilters);
        orderslist(pageno, selectedFilters);
    }, [pageno, selectedFilters])

    return (
        <div className="main-index-wrapper">
            <Header />

            <div className="body-overlay"></div>
            <main>
                <section className="page-title-area listing-search fix pt-xs-70">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="widget-search-content faq-que-search">
                                    <form className="subscribe-form">
                                        <input type="text" placeholder="Bond name, issuer or bond type" />
                                        <button className="trustBlackbtn" onClick={(e: any) => {
                                            e.preventDefault();
                                        }}>Search</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="order_listing pt-30 pb-50 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid confirm-res">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-12 breadcums">
                                    <nav>
                                        <ul>
                                            <li className="cursor" onClick={(e: any) => {
                                                e.preventDefault();
                                                navigate(PageLinks.PROFILE);
                                            }}>Home</li>
                                            <li>My Account</li>
                                            <li className="active">Order</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            {
                                ordersList != undefined && ordersList.length > 0 ?
                                    ordersList.map((order: APIData.order, index: number) => {
                                        return (

                                            <div className="text-center container-fluid card-box-2 pb-18 pt-20 mb-5">
                                                <div className="d-flex justify-content-between align-items-end row-responsive" key={index}>
                                                    <div className="mb-0 icon-logo ml-2 w_62">
                                                        <img className="img-small img-70" src={order.bond_logo != undefined || order.bond_logo != "" ? order.bond_logo : "assets/img/Trust Mony_Logo_New.svg"} alt="" />

                                                        <div className="d-flex flex-column align-items-start ml-3">
                                                            <p className="text-start quicksand-bold sgborder-bond-name fs-18 p-0 fw-500 p-0">{order.bond_name != undefined ? order.bond_name : ""} </p>
                                                            <div className="">
                                                                <div className="my-1">
                                                                    <span className="background-grey title-background-style d-flex">
                                                                        <p className="fs-15 px-res-0"> <span>ISIN: &nbsp;</span>{order.bond_isin_number} &nbsp;
                                                                            <span className="cursor" onClick={(e: any) => {
                                                                                e.preventDefault();
                                                                                copy(order.bond_isin_number!, {
                                                                                    debug: true,
                                                                                });
                                                                                toast.success('ISIN Number Copied To Clipboard');
                                                                            }}>
                                                                                <Copy />
                                                                            </span>
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btn-box d-lg-box">

                                                        {order.bond_type == bondListTypes.IPO && order.is_modifiable == 1 &&
                                                            <button onClick={(e: any) => {
                                                                e.preventDefault();
                                                                window.location.replace(order?.order_pdf);
                                                            }} className="btn-edit btn-1 mb-3 font-bold-600" style={{ padding: "3px 20px" }}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 83.283 83.283" className="mt-2 mr-1">
                                                                    <path id="Path_22591" data-name="Path 22591" d="M-1692.082,782.512a3.9,3.9,0,0,1-3.9,3.9h-75.476a3.9,3.9,0,0,1-3.9-3.9V764.294a3.894,3.894,0,0,1,3.9-3.9h23.863l7.971,7.97a8.333,8.333,0,0,0,11.808,0l7.971-7.97h23.862a3.894,3.894,0,0,1,3.9,3.9Zm-35.135-79.379a3.894,3.894,0,0,1,3.9,3.9v27.327h14.266a3.247,3.247,0,0,1,2.293,5.546l-24.74,24.758a3.151,3.151,0,0,1-4.441,0l-24.774-24.758a3.246,3.246,0,0,1,2.293-5.546h14.283V707.037a3.894,3.894,0,0,1,3.9-3.9Zm11.712,72.222a3.262,3.262,0,0,0-3.253,3.253,3.263,3.263,0,0,0,3.253,3.253,3.263,3.263,0,0,0,3.253-3.253A3.262,3.262,0,0,0-1715.5,775.355Zm10.41,0a3.262,3.262,0,0,0-3.253,3.253,3.263,3.263,0,0,0,3.253,3.253,3.263,3.263,0,0,0,3.253-3.253A3.262,3.262,0,0,0-1705.095,775.355Z" transform="translate(1775.365 -703.133)" />
                                                                </svg>Download Application</button>
                                                        }

                                                        {order?.is_modifiable == 1 &&

                                                            <button onClick={(e: any) => {
                                                                e.preventDefault();
                                                                setBondType(order?.bond_type)
                                                                setIsin(order?.bond_isin_number)
                                                                if (order.bond_type == bondListTypes.IPO) {
                                                                    setId(order?.order_id)
                                                                } else {
                                                                    setId(order?.order_id)
                                                                }
                                                                setOrderNo(order?.order_number)
                                                                setOrderQty(order?.order_quantity)
                                                                setModifyModel(true);
                                                            }} className="btn-modify font-bold-600 btn-1 m-1" style={{ padding: "3px 20px" }}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 93.694 83.266" className="mr-1 mb-1">
                                                                    <path id="Path_22587" data-name="Path 22587" d="M-1436.527,909.412l6.506-6.506a1.955,1.955,0,0,1,3.334,1.384v25.668a7.81,7.81,0,0,1-7.808,7.808h-57.258a7.81,7.81,0,0,1-7.808-7.808V872.7a7.811,7.811,0,0,1,7.808-7.809h46.49a1.952,1.952,0,0,1,1.382,3.336l-6.506,6.507a2,2,0,0,1-1.382.57h-37.379v52.051h52.051V910.8A2,2,0,0,1-1436.527,909.412Zm17.128-26.725a1.591,1.591,0,0,1,0,2.245l-35.525,35.526-15.1,1.673a3.163,3.163,0,0,1-3.5-3.5l1.676-15.094,35.525-35.526a1.591,1.591,0,0,1,2.245,0Zm11.679-9.419-5.758,5.759a1.59,1.59,0,0,1-2.244,0l-14.674-14.672a1.592,1.592,0,0,1,0-2.246l5.759-5.758a6.362,6.362,0,0,1,8.978,0l7.938,7.938A6.36,6.36,0,0,1-1407.72,873.268Z" transform="translate(1499.559 -854.498)" />

                                                                </svg>Modify Order</button>}

                                                        {
                                                            order.is_cancellable == 1 &&
                                                            <button onClick={(e: any) => {
                                                                e.preventDefault();
                                                                setBondType(order?.bond_type)
                                                                if (order.bond_type == bondListTypes.IPO || order.bond_type == bondListTypes.SGB) {
                                                                    setId(order?.order_id)
                                                                } else {
                                                                    setId(order?.order_id)
                                                                }
                                                                setOrderNo(order?.order_id)
                                                                setOrderQty(order?.order_quantity)
                                                                setCancel(true);
                                                            }} className="btn-withdrow font-bold-600 btn-1 m-1"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 103 103" className="bg-light mr-1">
                                                                    <g id="Group_177" data-name="Group 177" transform="translate(0.219 0.004)">
                                                                        <circle id="Ellipse_4" data-name="Ellipse 4" transform="translate(-0.219 -0.004)" fill="#fff" />
                                                                        <g id="Group_160" data-name="Group 160" transform="translate(25.941 25.94)">
                                                                            <line id="Line_14" data-name="Line 14" y1="51.132" x2="51.132" fill="none" stroke="#FF405A" stroke-width="7" />
                                                                            <line id="Line_15" data-name="Line 15" x1="51.132" y1="51.132" fill="none" stroke="#FF405A" stroke-width="7" />
                                                                        </g>
                                                                    </g>
                                                                </svg>Cancel</button>
                                                        }


                                                    </div>
                                                </div>
                                                <div className="col-lg-12 bg-2 mt-2 p-10 mt-10">
                                                    <div className="row m-auto justify-space-between">
                                                        <div className="res-width p-sm-none mb-3">
                                                            <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Order No</p>
                                                            <p className="text-small1 text-start sourceSansPro-regular fs-18 p-0 font-bold-600">{order.order_number}</p>

                                                        </div>
                                                        <div className="res-width p-sm-none mb-3">
                                                            <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Date</p>
                                                            <p className="text-small1 text-start sourceSansPro-regular fs-18 p-0 font-bold-600">{moment(order.order_datetime).format("MMM Do YYYY")}</p>
                                                        </div>
                                                        <div className="res-width p-sm-none mb-3">
                                                            <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Order Status</p>
                                                            <div className="col-lg-12 p-0">
                                                                {order.order_status == OrderStatus1.PLACED &&
                                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-yellow">Under Processing</p>
                                                                }
                                                                {order.order_status == OrderStatus1.MODIFIED &&
                                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0 text-yellow">Order Modified</p>
                                                                }
                                                                {order.order_status == OrderStatus1.CANCLE &&
                                                                    <p className="text-small1 text-start sourceSansPro-regular  font-bold-600 fs-18 p-0 text-red-cancle">Order Cancelled</p>

                                                                }
                                                                {order.order_status == OrderStatus1.ACCEPTED &&
                                                                    <p className="text-small1 text-start sourceSansPro-regular  font-bold-600 fs-18 p-0 text-green">Order Accepted</p>

                                                                }
                                                                {order.order_status == OrderStatus1.REJECTED &&
                                                                    <p className="text-small1 text-start sourceSansPro-regular  font-bold-600 fs-18 p-0 text-yellow">Order Rejected</p>

                                                                }
                                                            </div>

                                                        </div>
                                                        <div className="res-width p-sm-none mb-3">
                                                            <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Payment Status</p>
                                                            {order.order_payment_status == PaymentStatus1.PENDING &&
                                                                <p className="text-start quicksand-bold text-blue fs-18 p-0 text-blue">Processing</p>
                                                            }
                                                            {order.order_payment_status == PaymentStatus1.SUCCESS &&

                                                                <p className="text-start quicksand-bold text-green fs-18 p-0 text-green" >Successful</p>

                                                            }
                                                            {order.order_payment_status == PaymentStatus1.FAILURE &&
                                                                <p className="text-start quicksand-bold text-red-cancle fs-18 p-0 text-red-cancle">Failed</p>
                                                            }
                                                            {order.order_payment_status == PaymentStatus1.REFUNDED &&
                                                                <p className="text-start quicksand-bold text-red-cancle fs-18 p-0">Refunded</p>
                                                            }
                                                        </div>

                                                        {(order.bond_type == bondListTypes.ADDEDBOND || order.bond_type == bondListTypes.EXISTINGBOND) &&
                                                            <div className="res-width  bg-res mb-3">
                                                                <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Price</p>

                                                                <p className="text-small1 text-start sourceSansPro-regular font-bold-600">₹ {numberWithCommas(order?.bond_price)}</p>

                                                            </div>}
                                                        {(order.bond_type == bondListTypes.ADDEDBOND || order.bond_type == bondListTypes.EXISTINGBOND) &&
                                                            <>

                                                                <div className="res-width  bg-res mb-3">
                                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Yield %</p>
                                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">{numberWithCommas(order.bond_yeild)}</p>
                                                                </div>
                                                                <div className="res-width  bg-res mb-3">
                                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">No. of Quantity</p>
                                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">{order.order_quantity}</p>

                                                                </div>
                                                                <div className="res-width  bg-res mb-3">
                                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Total Amount</p>
                                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">₹ {numberWithCommas(order.order_amount)}</p>
                                                                </div>
                                                                <div className="res-width  bg-res mb-3">
                                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Order Type</p>

                                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">{order?.buy_sell_id == 1 ? "BUY" : "SELL"}</p>

                                                                </div>
                                                                <div className="res-width  bg-res mb-3">
                                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Exchange</p>

                                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">{order?.listing_name}</p>

                                                                </div>
                                                            </>}
                                                        {order.bond_type == bondListTypes.SGB &&
                                                            <div className="row m-auto justify-space-between p-0">
                                                                <div className="res-width  bg-res">
                                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Price of 1 gram</p>

                                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600">₹ {numberWithCommas(order?.bond_price)}</p>

                                                                </div>
                                                                <div className="res-width  bg-res mb-3">
                                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Yield %</p>
                                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">{numberWithCommas(order?.bond_yeild)}</p>
                                                                </div>
                                                                <div className="res-width  bg-res mb-3">
                                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">No. of Quantity</p>
                                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">{order.order_quantity}</p>

                                                                </div>
                                                                <div className="res-width  bg-res mb-3">
                                                                    <p className="text-small1 text-start sourceSansPro-regular text-light-grey">Total Investment</p>
                                                                    <p className="text-small1 text-start sourceSansPro-regular font-bold-600 fs-18 p-0">₹ {numberWithCommas(order.order_amount)}</p>
                                                                </div>

                                                            </div>}
                                                        {(order.bond_type == bondListTypes.IPO) &&

                                                            <div className="col-lg-12 m-res-0">
                                                                <h5 className="text-left text-title mb-2">Selected Series Of Ncds</h5>
                                                                <div className="bg-wight">
                                                                    <table className="w-100 justify-space-between">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="th-header w-20">Series</th>
                                                                                <th className="th-header w-20">Price</th>
                                                                                {/* <th className="th-header w-20">Yield</th> */}
                                                                                <th className="th-header w-20">No. of Quantity</th>
                                                                                <th className="th-header w-20">Total Amount</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="td-body">IV</td>
                                                                                <td className="td-body">₹ {numberWithCommas(order?.bond_price)}</td>
                                                                                {/* <td className="td-body">{numberWithCommas(order.bond_yeild)}</td> */}
                                                                                <td className="td-body">{order.order_quantity}</td>
                                                                                <td className="td-body">₹ {numberWithCommas(order.order_amount)}</td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>

                                                        }
                                                    </div>
                                                    {
                                                        orderOpenIndex != '' && orderOpenIndex == index + "" ?
                                                            <div className="row d-flex pt-10 pb-10">
                                                                <div className="row dropdown-sub-content p-10 align-items-center justify-content-center">
                                                                    <div className="col-lg-3">
                                                                        <p>Amount Invested</p>
                                                                        <h5>₹ {numberWithCommas(order.order_amount)}</h5>
                                                                    </div>
                                                                    <div className="col-lg-3">
                                                                        {order.bond_type != undefined && order.bond_type == bondListTypes.SGB ?
                                                                            <Fragment>
                                                                                <p>Gold Quantity</p>
                                                                                <h5>{order.order_quantity}Gram</h5>
                                                                            </Fragment>
                                                                            :
                                                                            <Fragment>
                                                                                <p>Quantity</p>
                                                                                <h5>{order.order_quantity}</h5>
                                                                            </Fragment>
                                                                        }
                                                                    </div>
                                                                    <div className="col-lg-3">
                                                                        <p>Payment status</p>
                                                                        <h5>Successful</h5>
                                                                    </div>
                                                                </div>
                                                                <OrderStepsComponent step={2} />
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                                <div className="btn-box d-sm-box">

                                                    {order.bond_type == bondListTypes.IPO && order.is_modifiable == 1 &&
                                                        <button onClick={(e: any) => {
                                                            e.preventDefault();
                                                        }} className="btn-edit btn-1 m-1 font-bold-600" style={{ padding: "3px 20px" }}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 83.283 83.283" className="mt-2 mr-1">
                                                                <path id="Path_22591" data-name="Path 22591" d="M-1692.082,782.512a3.9,3.9,0,0,1-3.9,3.9h-75.476a3.9,3.9,0,0,1-3.9-3.9V764.294a3.894,3.894,0,0,1,3.9-3.9h23.863l7.971,7.97a8.333,8.333,0,0,0,11.808,0l7.971-7.97h23.862a3.894,3.894,0,0,1,3.9,3.9Zm-35.135-79.379a3.894,3.894,0,0,1,3.9,3.9v27.327h14.266a3.247,3.247,0,0,1,2.293,5.546l-24.74,24.758a3.151,3.151,0,0,1-4.441,0l-24.774-24.758a3.246,3.246,0,0,1,2.293-5.546h14.283V707.037a3.894,3.894,0,0,1,3.9-3.9Zm11.712,72.222a3.262,3.262,0,0,0-3.253,3.253,3.263,3.263,0,0,0,3.253,3.253,3.263,3.263,0,0,0,3.253-3.253A3.262,3.262,0,0,0-1715.5,775.355Zm10.41,0a3.262,3.262,0,0,0-3.253,3.253,3.263,3.263,0,0,0,3.253,3.253,3.263,3.263,0,0,0,3.253-3.253A3.262,3.262,0,0,0-1705.095,775.355Z" transform="translate(1775.365 -703.133)" />
                                                            </svg>Download Application</button>
                                                    }

                                                    {order?.is_modifiable == 1 &&

                                                        <button onClick={(e: any) => {
                                                            e.preventDefault();
                                                            setBondType(order?.bond_type)
                                                            setIsin(order?.bond_isin_number)
                                                            if (order.bond_type == bondListTypes.IPO) {
                                                                setId(order?.order_id)
                                                            } else {
                                                                setId(order?.order_id)
                                                            }
                                                            setOrderNo(order?.order_number)
                                                            setOrderQty(order?.order_quantity)
                                                            setModifyModel(true);
                                                        }} className="btn-modify font-bold-600 btn-1 m-1" style={{ padding: "3px 20px" }}><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 93.694 83.266" className="mr-1 mb-1">
                                                                <path id="Path_22587" data-name="Path 22587" d="M-1436.527,909.412l6.506-6.506a1.955,1.955,0,0,1,3.334,1.384v25.668a7.81,7.81,0,0,1-7.808,7.808h-57.258a7.81,7.81,0,0,1-7.808-7.808V872.7a7.811,7.811,0,0,1,7.808-7.809h46.49a1.952,1.952,0,0,1,1.382,3.336l-6.506,6.507a2,2,0,0,1-1.382.57h-37.379v52.051h52.051V910.8A2,2,0,0,1-1436.527,909.412Zm17.128-26.725a1.591,1.591,0,0,1,0,2.245l-35.525,35.526-15.1,1.673a3.163,3.163,0,0,1-3.5-3.5l1.676-15.094,35.525-35.526a1.591,1.591,0,0,1,2.245,0Zm11.679-9.419-5.758,5.759a1.59,1.59,0,0,1-2.244,0l-14.674-14.672a1.592,1.592,0,0,1,0-2.246l5.759-5.758a6.362,6.362,0,0,1,8.978,0l7.938,7.938A6.36,6.36,0,0,1-1407.72,873.268Z" transform="translate(1499.559 -854.498)" />

                                                            </svg>Modify Order</button>}

                                                    {
                                                        order.is_cancellable == 1 &&
                                                        <button onClick={(e: any) => {
                                                            e.preventDefault();
                                                            setBondType(order?.bond_type)
                                                            if (order.bond_type == bondListTypes.IPO || order.bond_type == bondListTypes.SGB) {
                                                                setId(order?.order_id)
                                                            } else {
                                                                setId(order?.order_id)
                                                            }
                                                            setOrderNo(order?.order_id)
                                                            setOrderQty(order?.order_quantity)
                                                            setCancel(true);
                                                        }} className="btn-withdrow font-bold-600 btn-1 m-1"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 103 103" className="bg-light mr-1">
                                                                <g id="Group_177" data-name="Group 177" transform="translate(0.219 0.004)">
                                                                    <circle id="Ellipse_4" data-name="Ellipse 4" transform="translate(-0.219 -0.004)" fill="#fff" />
                                                                    <g id="Group_160" data-name="Group 160" transform="translate(25.941 25.94)">
                                                                        <line id="Line_14" data-name="Line 14" y1="51.132" x2="51.132" fill="none" stroke="#FF405A" stroke-width="7" />
                                                                        <line id="Line_15" data-name="Line 15" x1="51.132" y1="51.132" fill="none" stroke="#FF405A" stroke-width="7" />
                                                                    </g>
                                                                </g>
                                                            </svg>Cancel</button>
                                                    }


                                                </div>
                                                <Fragment>

                                                    <div className="row">
                                                        <div className="col-lg-12 p-0">
                                                            {(order.order_status == OrderStatus1.CANCLE) &&
                                                                <OrderStepsComponent step={4} />
                                                            }
                                                            {(order.order_status == OrderStatus1.REJECTED) &&
                                                                <OrderStepsComponent step={4} />
                                                            }
                                                            {(order.order_status == OrderStatus1.PLACED) &&
                                                                <OrderStepsComponent step={2.5} />
                                                            }
                                                            {(order.order_status == OrderStatus1.ACCEPTED) &&
                                                                <OrderStepsComponent step={3} />
                                                            }
                                                            {(order.order_status == OrderStatus1.MODIFIED) &&
                                                                <OrderStepsComponent step={2.5} />
                                                            }
                                                        </div>
                                                    </div>
                                                </Fragment>

                                            </div>
                                        )
                                    })
                                    :
                                    <Fragment>
                                        <div className="text-center mt-5">
                                            <img src="assets/img/no_orders.png" />
                                            <p className="source sourceSansPro-bold no-result-main">No Orders Found</p>
                                            <p className="source sourceSansPro-regular no-result-sub">Tap the button to explore Bonds.</p>
                                        </div>
                                        <div className="text-center mt-3">
                                            <Link to={PageLinks.PROFILE_LISTING} className="apply-gold-btn  text-white">Explore All Bonds</Link>
                                        </div>
                                    </Fragment>
                            }
                            < ReactPaginate
                                onPageChange={(e: any) => {
                                    setPageno(e.selected + 1);
                                }}
                                breakLabel="..."
                                nextLabel=">"
                                pageRangeDisplayed={3}
                                pageCount={totalCount / 20}
                                previousLabel="<"
                                containerClassName="pagination mb-5"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                activeClassName="active"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                            />

                        </div>
                    </div>
                </section>
            </main>
            <Footer />


            <ModifyPopup showModal={modify} closeModal={() => { setModifyModel(false) }} orderNo={OrderNo} bondType={bondtype} isin={isin} qty={OrderQty} orderID={id} />
            <CanclePopup
                showModal={cancel}
                closeModal={() => { setCancel(false) }}
                id={id}
                orderNO={OrderNo}
                orderQty={OrderQty}
                setOrdersList={orderslist}
                selectedFilters={selectedFilters}
                pageno={pageno}
                setCancledata={setCancledata}
                bondType={bondtype} />

        </div>
    )
}

export default OrderListing;
