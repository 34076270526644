import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Footer from "../components/template/Footer";

const Disclaimer: React.FC = () => {

    return (
        <div className="main-index-wrapper">
            <Header />
            
            <div className="body-overlay"></div>
            <main>
            <section className="what_are_advantages pt-110 pb-50 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li className="active">Disclaimer</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 what_are_advantages_full">
                            <div className="col-lg-12 what_are_advantages_full_left p-0">
                                <div className="what_are_advantages_full_left_text">
                                    <p className="text_bold">
                                        Important Legal Information And Disclaimer
                                    </p>
                                    <p className="text_light">
                                        No part of this material may be copied or duplicated in any form by any means, or redistributed. Nothing in these materials is intended by Trust Group to be construed as legal, accounting or tax advice. Past performance is no guide to the future. Investments inherently involve risks. 
                                    </p>                                    
                                    <p className="text_light">
                                        The information, software, products, and services included in or available through the Trust Group and its Associates Companies sites / services may include inaccuracies or typographical errors. Changes are periodically made to the Trust Group and its Associates Companies sites/ services and to the information therein. Trust Group and its Associates Companies and/or its respective suppliers may make improvements and/or changes in the Trust Group and its Associates Companies sites/ services at any time. Advice received via the Trust Group and its Associates Companies sites/ services should not be relied upon for personal, medical, legal or financial decisions and you should consult an appropriate professional for specific advice tailored to your situation. 
                                    </p>
                                    <p className="text_light">
                                        Trust Group and its Associates Companies and/or its respective suppliers makes no representations about the suitability, reliability, availability, timeliness, lack of viruses or other harmful components and accuracy of the information, software, products, services and related graphics contained within the Trust Group and its Associates Companies sites/ services for any purpose. All such information, software, products, services and related graphics are provided “as is” without warranty of any kind. Trust Group and its Associates Companies and/or its respective suppliers hereby disclaim all warranties and conditions with regard to this information, software, products, services and related graphics, including all implied warranties and conditions of merchantability, fitness for a particular purpose, workmanlike effort, title and non-infringement. 
                                    </p>
                                    <p className="text_light">
                                        This site may contain links to other websites operated by third parties (“linked sites”). You acknowledge that, when you click on a link to visit a linked site, a frame may appear that contains the Trust Group and its Associates Companies logo, advertisements and/or other content selected by Trust Group and its Associates Companies. You acknowledge that Trust Group and its Associates Companies and its sponsors neither endorse nor are affiliated with the linked site and are not responsible for any content of any linked site or any link contained in a link site, or any changes or updates to such sites. You also acknowledge that Trust Group and its Associates Companies are providing these links to you only as a convenience. 
                                    </p>
                                    <p className="text_light">
                                        You specifically agree that Trust Group and its Associates Companies shall not be responsible for unauthorised access to or alteration of your transmissions or data, any material or data sent or received or not sent or received, or any transactions entered into through an Trust Group and its Associates Companies site/ service. You specifically agree that Trust Group and its Associates Companies is not responsible or liable for any threatening, defamatory, obscene, offensive or illegal content or conduct of any other party or any infringement of another’s rights, including intellectual property rights. You specifically agree that Trust Group and its Associates Companies is not responsible for any content sent using and/or included in Trust Group and its Associates Companies site/service by any third party. 
                                    </p>
                                    <p className="text_light">
                                        In no event shall Trust Group and its Associates Companies and/or its suppliers be liable for any direct, indirect, punitive, incidental, special, consequential damages or any damages whatsoever including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of the Trust Group and its Associates Companies sites/ services, with the delay or inability to use the Trust Group and its Associates Companies sites/ services or related services, the provision of or failure to provide services, or for any information, software, products, services and related graphics obtained through the Trust Group and its Associates Companies sites/ services, or otherwise arising out of the use of the Trust Group and its Associates Companies sites/ services, whether based on contract, tort, negligence, strict liability or otherwise, even if Trust Group and its Associates Companies or any of its suppliers has been advised of the possibility of damages. Trust Group and its Associates Companies do not endorse in anyway any advertisers/ contents of advertisers on its webpages. Please therefore verify the veracity of all information on your own before undertaking reliance and actioning thereupon. Trust Group and its Associates Companies shall not be responsible nor liable for any consequential damages arising on account of your relying on the contents of the website. 
                                    </p>
                                    <p className="text_light">
                                        The Trust Group and its Associates Companies is not in any manner answerable, responsible or liable to any person or persons for any acts of omission or commission, errors, mistakes and/or violation, actual or perceived, by us or our partners, agents, associates etc. 
                                    </p>
                                    <p className="text_light">
                                        The Trust Group and its Associates Companies is not answerable, responsible or liable for any information on this website or for any services rendered by us, our employees, and our servants.
                                    </p>
                                    <p className="text_bold">Governing Law</p>
                                    <p className="text_light">
                                        This website, legal information and disclaimer shall be exclusively governed by the laws in India. You hereby irrevocably consent to the exclusive jurisdiction and venue of courts in Mumbai, Maharashtra, India in all disputes arising out of or relating to the use of the Trust Group and its Associates Companies sites/ services. If any provision of this legal information or disclaimer contained herein is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the other provisions of this terms of use of this website.
                                    </p>
                                    <p className="text_bold">Disclaimer For Research Reports</p>
                                    <p className="text_light">
                                        The information contained in the Research Reports uploaded herein has been obtained from various sources; we do not guarantee its authenticity or validity or completeness. Neither any information nor any opinions expressed constitute an offer, or an invitation to make an offer, to buy or sell any securities or any derivative instruments related to such securities. Investors should take financial advice with respect to the suitability of investing their monies in any securities discussed or recommended in on this website and should understand that statements regarding future prospects may not materialize. Investors should note that each security’s price or value may rise or fall and accordingly, investors may even receive the amounts, which are less than originally invested. Past performance is not necessarily a guide to future performance. Please carefully read the detailed disclosures given at the end of every research report. 
                                    </p>
                                    <p className="text_light">                                        
                                        Trading and investments in Equities and other securities are subject to market risk, there is no assurance or guarantee of the returns. Please read the Risk Disclosure Document and Do’s & Dont’s prescribed by the Exchanges carefully before investing.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default Disclaimer;
