
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageLinks } from "../common/Constants";


const EmailVerified: React.FC = () => {

    return (
        <div className="main-index-wrapper">
            <main>
                <section className="otp_popup">
                    <div className="blur_back"></div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg pt-150 pb-100 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 col-md-6  text-left" data-wow-delay="0.3s">
                                    <div className="do-box do-box-2 mb-30 email_verify_bg">
                                        <Link to={PageLinks.MY_ACCOUNT_PROFILE} data-wow-delay="0.5s">
                                            <div>
                                                <div className="icon mb-25 icon_img">
                                                    <img src="assets/animations/icon_animations/email_tick.gif" alt="" />
                                                </div>
                                                <h5 className="email_update_text">Your Email ID was updated successfully.</h5>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default EmailVerified;
