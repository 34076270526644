import { url } from "inspector";
import Header from "../components/template/Header";
import SlideBar from "../components/template/SlideBar";
import Footer from "../components/template/Footer";

const TermsAndConditions: React.FC = () => {

    return (
        <div className="main-index-wrapper">
            <Header />
            
            <div className="body-overlay"></div>
            <main>
                <section className="what_are_advantages terms pt-110 pb-50 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <div className="row align-items-left">
                                <div className="col-lg-6 breadcums">
                                    <nav>
                                        <ul>
                                            <li>Home</li>
                                            <li className="active">Terms And Conditions</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 what_are_advantages_full">
                            <div className="col-lg-12 what_are_advantages_full_left">
                                <div className="what_are_advantages_full_left_text">
                                    <p>
                                        Trust Securities Services is the Non-Institutional Clientele vertical of one of India's leading full-service financial houses, TRUST Group.
                                    </p>
                                    <p className="text_light_bold">IMPORTANT</p>
                                    <p className="text_light_privacy">
                                        By accessing this website and any of its pages or sections, you are agreeing to the terms set out herein. Since there may be specific additional disclaimers contained at other pages or sections of this website, you agree to abide by all of such disclaimers in addition to the terms contained herein. If you do not agree to these terms, do not access this website. By accessing this website or any section of pages thereof, you agree that the exclusions and limitations of liability set out in this website terms of use and disclaimer are reasonable. If you do not think they are reasonable, you must not access this website. 
                                    </p>
                                    <p className="text_light_privacy">
                                        The information provided on this website is not intended or directed to or for distribution to, or use by any U.S. Person.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default TermsAndConditions;
