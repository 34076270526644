import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { bondPurchaseTypes, interestPaymentFrequency, listing, NatureofInstruments, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { getDisplaySelectOption, numberWithCommas } from "../common/Utilities";
import DownloadIssueDocuments from "../components/DownloadIssueDocuments";
import { InitialData } from "../components/Form/InitialData";
import InvestSteps from "../components/InvestSteps";
import NeedHelp from "../components/NeedHelp";
import Subscribe from "../components/Subscribe";
import MoreInfo from "../components/Modals/MoreInfo";
import Copy from "../components/Svg/Copy";
import Download from "../components/Svg/Download";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import Category from "../components/Modals/Category";
import SubCategory from "../components/Modals/SubCategory";

const ModifyIpoBond = () => {
  const location = useLocation();
  const [OrderID, setOrderID] = useState('');
  const [checked, setChecked] = useState<Boolean[]>([]);
  // const [qty, setQty] = useState<number[]>([]);
  const [moreinfo, setMoreInfo] = useState(false);
  const [subcat, setSubcat] = useState(false);
  const [individualTotal, setIndividualTotal] = useState<number[]>([]);
  const [ncds, setNCDs] = useState<APIData.IPONCDOrderSeires[]>([]);
  const [investmentCategory, setInvestmentCategory] = useState<APIData.InvestorCategory[]>([]);
  const [investmentTypes, setInvestmentTypes] = useState<APIData.InvestorTypes[]>([]);
  const [bondsDetail, setBondDetail] = useState<any>();
  const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
  const { setShowPreloader, setIPOData } = useAppStateAPI();
  const [orderTotal, setOrderTotal] = useState(0);
  const [validationFailed, setValidationFailed] = useState(false);
  const [secondPanName, setSecondPanName] = useState("");
  const [secondPanNo, setSecondPanNo] = useState("");
  const [thirdPanName, setThirdPanName] = useState("");
  const [thirdPanNo, setThirdPanNo] = useState("");
  const [categoryid, setCategoryID] = useState(0);
  const [investorID, setInvestorID] = useState(0);
  const [DemantID, setDemantID] = useState(0);
  const [qty, setQty] = useState(0);
  const [isin, setIsin] = useState('');
  const [OrderNo, setOrderNO] = useState('');
  const [modify, setModify] = useState(0);
  const [subCategoryCode, setSubCategoryCode] = useState(0);
  const navigate = useNavigate();
  const alphabets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"];
  var data: any = {};
  const [bondsOrderDetailIPO, setBondOrderDetailIPO] = useState<any>();
  const [agree, setagree] = useState(false);

  useEffect(() => {
    console.log(bondsOrderDetailIPO?.ipo_ncd_series)
  }, [bondsOrderDetailIPO])
  


  useEffect(() => {
    if (location.search) {
        const query = new URLSearchParams(location.search);
        if (query.has("isin")) {
            const id = query.get("isin");
            setIsin((id!));
        } if (query.has("qty")) {
            const qty = query.get("qty");
            setQty(parseInt(qty!))
        } if (query.has("modify")) {
            const modify = query.get("modify");
            setModify(parseInt(modify!))
        }
        if (query.has("orderid")) {
          const orderID = query.get("orderid");
          setOrderID(orderID!)
      } if (query.has("orderno")) {
            const orderNo = query.get("orderno");
            setOrderNO(orderNo!)
        }
    }
}, [location]);

  const goToBuy = (id: number) => {
    let totalQty = 0;
    ncds.map((value: any) => {
      totalQty += parseInt(value.ipo_order_ncd_series_quantity);
    });
    if (userDetail.ekyc_application_status == "1") {
      let items: any[] = [];
      let params: any = {};
      params.ipo_order_quantity = bondsOrderDetailIPO?.ipo_ncd_series.reduce((acc : any, curr: any) => acc + curr.ipo_order_ncd_series_quantity, 0);
      params.ipo_order_amount = bondsOrderDetailIPO?.ipo_ncd_series.reduce((acc : any, curr: any) => acc + parseInt(curr.ipo_order_ncd_series_amount) * curr.ipo_order_ncd_series_quantity, 0);
      params.bond_id = id;
      params.bond_type = bondPurchaseTypes.IPO;
      params.bond_ncd_series = bondsOrderDetailIPO?.ipo_ncd_series;
      params.ipo_order_second_appl_name = secondPanName;
      params.ipo_order_second_pan = secondPanNo;
      params.ipo_order_third_appl_name = thirdPanName;
      params.ipo_order_third_pan = thirdPanNo;
      params.ipo_order_sub_category_code = subCategoryCode;
      params.ipo_order_investor_category_id = bondsOrderDetailIPO?.ipo_order_investor_category_id;
      params.ipo_order_investor_type_id = bondsOrderDetailIPO?.ipo_order_investor_type_id;
      params.ipo_order_demant_account_type = bondsOrderDetailIPO?.ipo_order_demant_account_type;
      params.ipo_order_payment_upi = bondsOrderDetailIPO?.order_detail?.payment_status?.ipo_order_payment_upi;
      params.ipo_order_payment_ref_city_id = bondsOrderDetailIPO?.order_detail?.payment_status?.ipo_order_payment_ref_city_id;
      params.ipo_order_payment_ref_bank_id = bondsOrderDetailIPO?.order_detail?.payment_status?.ipo_order_payment_ref_bank_id;
      params.payment_mode_id = bondsOrderDetailIPO?.order_detail?.payment_status?.payment_mode_id;
      params.ipo_order_second_pan = bondsOrderDetailIPO?.ipo_order_second_pan;
      params.ipo_order_third_appl_name = bondsOrderDetailIPO?.ipo_order_third_appl_name;
      params.ipo_order_third_pan = bondsOrderDetailIPO?.ipo_order_third_pan;
      params.ipo_order_demant_account_id = DemantID
      params.modify = modify;
      params.Order_No = OrderNo;
      params.Order_ID = OrderID;
      items.push(params);
      navigate(PageLinks.PAYMENTBOND, { state: { data: items } });
    }
     else {
      toast.error("Please Complete Your KYC Before Proceeding To Buy This Bond");
    }
  };

  // const dataVerify = () => {
  //     let ncddata: any = [];
  //     data = location.state;
  //     if(data!=null){
  //         console.log(data['data'][0].bond_ncd_series);
  //     }
  //     if (data != null && data['data'][0] != undefined && data['data'][0].bond_ncd_series != null) {
  //     //         if (data['data'][0] != undefined && data['data'][0].bond_ncd_series != undefined) {
  //                 if (data['data'][0].bond_ncd_series.length > 0) {
  //                     let newqty = [...qty];
  //                     let newchecked = [...checked];
  //                     data['data'][0].bond_ncd_series.map((series:any,index:number)=>{
  //                         newqty[index] = series.ipo_order_ncd_series_quantity;
  //                         setQty(newqty);
  //                         newchecked[index] = true;
  //                         setChecked(newchecked);
  //                     })
  //                 }
  //     //         }
  //     //         setSecondPanName(data['data'][0].ipo_order_second_appl_name);
  //     //         setSecondPanNo(data['data'][0].ipo_order_second_pan);
  //     //         setThirdPanName(data['data'][0].ipo_order_third_appl_name);
  //     //         setThirdPanNo(data['data'][0].ipo_order_third_pan);
  //     //         setCategoryID(data['data'][0].ipo_order_investor_category_id);
  //     //         setSubCategoryCode(data['data'][0].ipo_order_sub_category_code);
  //     //     } else if (data['data'][0].bond_type == bondTypes.SGB) {
  //     //         setBondType(data['data'][0].bond_type);
  //     //         setBondID(data['data'][0].bond_id);
  //     //     }
  //     }
  // }
  // useEffect(() => {
  //     dataVerify();
  // }, [location]);

  useEffect(() => {
    if (OrderID != "") {
      setShowPreloader(true);
      ServerAPI.GetOrderIPOBond(parseInt(OrderID))
        .then((response: any) => {
          if (response != undefined) {
            // setBondDetail(response);
            setBondOrderDetailIPO(response);
            setSubCategoryCode(response.ipo_order_sub_category_code);
            setDemantID(response.ipo_order_demant_account_id)
            setQty(response.ipo_order_quantity);
            setShowPreloader(true);
            ServerAPI.GetIPODetail(response?.bond_id!)
              .then((response: any) => {
                if (response != undefined) {
                  setBondDetail(response);
                }
              })
              .finally(() => {
                setShowPreloader(false);
              });
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  }, [OrderID]);

  useEffect(() => {
    console.log(individualTotal);
    let totalOrder = 0;
    individualTotal.map((value: number) => {
      if (value != undefined) {
        totalOrder += value;
      }
    });
    setOrderTotal(totalOrder);
  }, [qty]);

  useEffect(() => {
    if (localStorage.getItem("login") != "0" && localStorage.getItem("login") != undefined && localStorage.getItem("login") != null) {
      setShowPreloader(true);
      ServerAPI.userDetailData()
        .then((res: APIData.userDetail) => {
          setUserDetails(res);
        })
        .finally(() => {
          setShowPreloader(false);
        });
      setShowPreloader(true);
      ServerAPI.GetInvestorCategories()
        .then((res: any) => {
          setInvestmentCategory(res["data"]);
        })
        .finally(() => {
          setShowPreloader(false);
        });
      setShowPreloader(true);
      ServerAPI.GetInvestorTypes()
        .then((res: any) => {
          setInvestmentTypes(res["data"]);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  }, []);

  return (
    <div className="main-index-wrapper">
      <Header />
      <div className="body-overlay"></div>
      <main>
        <section className="sovereign_gold_bonds pt-100 pt-md-45 pb-md-40 pt-xs-45 pb-xs-40">
          <div className="container-fluid ">
            <div className="row pt-3">
              <div className="col-lg-12">
                <div className="row align-items-left">
                  <div className="col-lg-6 breadcums pt-4 ">
                    <nav>
                      <ul>
                        <li>Home</li>
                        <li>Orders</li>
                        <li>{bondsDetail?.bond_name}</li>
                        <li className="active">Modify</li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="row py-3 mx-4 px-4 detail-view-calculation">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="row  pb-3">
                  <div className="col-lg-2 col-md-3 col-sm-6 pr-0">
                    <img src={bondsDetail?.bond_logo != "" ? bondsDetail?.bond_logo : "assets/img/img_placeholder.png"} alt="ipo" className="bond-img-logo" />
                  </div>
                  <div className="col-lg-10 col-md-9 col-sm-6 pl-0">
                    <div className="d-flex my-2">
                      <p className="title fs-24 mt-3">{bondsDetail?.bond_name}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 ipo-purchase-right-section1">
                <div className="container-fluid p-sm-none">
                  <div className="row detail-view-calculation">
                    <div className="">
                      <div className="row d-flex section-head1 px-3 py-3 mb-5">
                        <p className="ipo-purchase-p1">*Company shall allocate and allot Series {bondsDetail?.bond_issuer_details[0].bond_issuer_series} NCDs wherein the Applicants have not indicated the choice of the relevant NCD Series.</p>
                      </div>
                      <div className="row section-form p-sm-none">
                        <div className="row mb-3">
                          <div className="col-lg-3 pt-10">
                            <p className="mx-2">Investor Type</p>
                            <div className="row mx-1">
                              <input disabled={true} className="ipo-purchase-subcategory" value={investmentTypes.filter((e: APIData.InvestorTypes) => e.investor_type_id === bondsOrderDetailIPO?.ipo_order_investor_type_id)[0]?.investor_type_name} />
                            </div>
                          </div>

                          <div className="col-lg-3 pt-10">
                            <p className="mx-2">Investor Category</p>
                            <div className="row mx-1">
                              <input disabled={true} className="ipo-purchase-subcategory" value={investmentCategory.filter((e: APIData.InvestorCategory) => e.investor_category_id === bondsOrderDetailIPO?.ipo_order_investor_category_id)[0]?.investor_category_name} />
                              <p className="text-source p-0">
                                For the Complete List of categories{" "}
                                <a
                                  href="/"
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    setMoreInfo(true);
                                  }}
                                  className="text-blue link-1"
                                >
                                  Click Here
                                </a>
                              </p>
                            </div>
                          </div>

                          <div className="col-lg-3 pt-10">
                            <p className="mx-2">Subcategory</p>
                            <div className="row mx-1">
                              <input disabled={true} value={subCategoryCode} className="ipo-purchase-subcategory" />
                              <p className="text-source p-0">
                                For the Complete List of sub categories{" "}
                                <a
                                  href="/"
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    setSubcat(true);
                                  }}
                                  className="text-blue link-1"
                                >
                                  {" "}
                                  Click Here
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-lg-3 pb-4">
                            <p className="mx-2">Second Applicant</p>
                            <div className="row mx-1">
                              <input
                                placeholder="Enter Full Name"
                                type="text"
                                onChange={(e: any) => {
                                  setSecondPanName(e.target.value);
                                }}
                                className="input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 pb-4">
                            <p className="mx-2">Second Applicant PAN</p>
                            <div className="row mx-1">
                              <input
                                type="text"
                                maxLength={10}
                                onChange={(e: any) => {
                                  setSecondPanNo(e.target.value);
                                }}
                                placeholder="Enter 10 Digit PAN Number"
                                className="input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input"
                              />
                            </div>
                          </div>

                          <div className="col-lg-3 pb-4">
                            <p className="mx-2">Third Applicant</p>
                            <div className="row mx-1">
                              <input
                                placeholder="Enter Full Name"
                                type="text"
                                onChange={(e: any) => {
                                  setThirdPanName(e.target.value);
                                }}
                                className="input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input"
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 pb-4">
                            <p className="mx-2">Third Applicant PAN</p>
                            <div className="row mx-1">
                              <input
                                type="text"
                                maxLength={10}
                                onChange={(e: any) => {
                                  setThirdPanNo(e.target.value);
                                }}
                                placeholder="Enter 10 Digit PAN Number"
                                className="input-valu2 box-shadow text-start px-2 py-2 ipo-purchase-pan-input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 section-left table-shadow mt-5 p-2">
                <Fragment>
                  <h3 className="ml-2 font-24 text-left mt-2">Select Series of NCDs</h3>
                  {bondsOrderDetailIPO?.ipo_ncd_series != undefined ? (
                    <table className={bondsOrderDetailIPO?.ipo_ncd_series != undefined && bondsOrderDetailIPO?.ipo_ncd_series.length == 6 ? "w-100 mt-2 ipo-specific-terms1 mb-3 text-center table_th table-responsive p-2" : "mt-2 table_th ipo-specific-terms1 mb-3 text-center w-100 table-responsive p-2"}>
                      <thead className="city-table">
                        <tr>
                          <th>Series</th>
                          <th>Frequency of Int Payment</th>
                          <th>Minimum Application (₹)</th>
                          <th>In Multiples of thereafter (₹)</th>
                          <th>Issue Price (₹)</th>
                          <th>Tenor in Months</th>
                          <th>Coupon (% per annum)</th>
                          <th>Effective Yield (% per annum)</th>
                          <th>Redemption Amt (₹/NCD) on Maturity</th>
                          <th>Put and Call Option</th>
                          <th>No. of NCD’s</th>
                          <th>Total Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bondsOrderDetailIPO?.ipo_ncd_series != undefined && bondsOrderDetailIPO?.ipo_ncd_series.length >0
                          ? bondsOrderDetailIPO?.ipo_ncd_series.map((series: APIData.IPOorderNCDSeries, inx: number) => {
                              return (
                                <tr key={inx}>
                                  <td>{inx + 1}</td>
                                  <td>{getDisplaySelectOption(series.bond_ncd_frequency_of_interest_payment, "bond_ncd_frequency_of_interest_payment")}</td>
                                  <td>{getDisplaySelectOption(series.bond_ncd_frequency_of_interest_payment, "bond_ncd_frequency_of_interest_payment")}</td>
                                  <td>{series.bond_ncd_in_multiple_terms_of_thereafter}</td>
                                  <td>{series.bond_ncd_issue_price_of_ncd}</td>
                                  <td>{series.bond_ncd_tenor}</td>
                                  <td>{series.bond_ncd_coupon}</td>
                                  <td>{series.bond_ncd_effective_yield}</td>
                                  <td>{series.bond_ncd_redemption_amount}</td>
                                  <td>{getDisplaySelectOption(series.bond_ncd_put_and_option, "bond_ncd_put_and_option")}</td>
                                  <td>
                                    <input
                                      style={{ width: "50px", textAlign: "center" }}
                                      type="number"
                                      value={series.ipo_order_ncd_series_quantity}
                                      onChange={(e) => {
                                        setQty(parseInt(e.target.value));
                                        series.ipo_order_ncd_series_quantity = parseInt(e.target.value);
                                        series.ipo_order_ncd_series_total_amount = (parseInt(series.ipo_order_ncd_series_amount) * series.ipo_order_ncd_series_quantity)+"";
                                        if (parseInt(bondsDetail.bond_ncd_series[inx].bond_ncd_issue_price_of_ncd) * parseInt(e.target.value) < series.bond_ncd_minimum_application) {
                                          setValidationFailed(true);
                                        } else {
                                          setValidationFailed(false);
                                        }
                                        setBondOrderDetailIPO({ ...bondsOrderDetailIPO });
                                      }}
                                    ></input>
                                  </td>
                                  <td>{parseInt(series.ipo_order_ncd_series_amount) * series.ipo_order_ncd_series_quantity}</td>
                                </tr>
                              );
                            })
                          : ""}
                        {/* <tr>
                          {bondsOrderDetailIPO?.ipo_ncd_series.map((feq: APIData.IPOorderNCDSeries, inx: number) => {
                            return <td>{inx + 1}</td>;
                          })}
                          {bondsOrderDetailIPO?.ipo_ncd_series.map((feq: APIData.IPOorderNCDSeries, inx: number) => {
                            return <td>{getDisplaySelectOption(feq.bond_ncd_frequency_of_interest_payment, "bond_ncd_frequency_of_interest_payment")}</td>;
                          })}
                          {bondsOrderDetailIPO?.ipo_ncd_series.map((feq: APIData.IPOorderNCDSeries, inx: number) => {
                            return <td>{getDisplaySelectOption(feq.bond_ncd_frequency_of_interest_payment, "bond_ncd_frequency_of_interest_payment")}</td>;
                          })}
                          {bondsOrderDetailIPO?.ipo_ncd_series.map((feq: APIData.IPOorderNCDSeries, inx: number) => {
                            return <td>{feq.bond_ncd_in_multiple_terms_of_thereafter}</td>;
                          })}
                          {bondsOrderDetailIPO?.ipo_ncd_series.map((feq: APIData.IPOorderNCDSeries, inx: number) => {
                            return <td>{feq.bond_ncd_issue_price_of_ncd}</td>;
                          })}
                          {bondsOrderDetailIPO?.ipo_ncd_series.map((feq: APIData.IPOorderNCDSeries, inx: number) => {
                            return <td>{feq.bond_ncd_tenor}</td>;
                          })}
                          {bondsOrderDetailIPO?.ipo_ncd_series.map((feq: APIData.IPOorderNCDSeries, inx: number) => {
                            return <td>{feq.bond_ncd_coupon}</td>;
                          })}
                          {bondsOrderDetailIPO?.ipo_ncd_series.map((feq: APIData.IPOorderNCDSeries, inx: number) => {
                            return <td>{feq.bond_ncd_effective_yield}</td>;
                          })}
                          {bondsOrderDetailIPO?.ipo_ncd_series.map((feq: APIData.IPOorderNCDSeries, inx: number) => {
                            return <td>{feq.bond_ncd_redemption_amount}</td>;
                          })}
                          {bondsOrderDetailIPO?.ipo_ncd_series.map((feq: APIData.IPOorderNCDSeries, inx: number) => {
                            return <td>{getDisplaySelectOption(feq.bond_ncd_put_and_option, "bond_ncd_put_and_option")}</td>;
                          })}
                          {bondsOrderDetailIPO?.ipo_ncd_series.map((feq: APIData.IPOorderNCDSeries, inx: number) => {
                            return (
                              <td>
                                <input
                                  style={{ width: "50px", textAlign: "center" }}
                                  type="number"
                                  value={qty+""}
                                  onChange={(e) => {
                                    setQty(parseInt(e.target.value));
                                    feq.ipo_order_ncd_series_quantity = parseInt(e.target.value);
                                    if (parseInt(bondsDetail.bond_ncd_series[inx].bond_ncd_issue_price_of_ncd) * parseInt(e.target.value) < feq.bond_ncd_minimum_application) {
                                        setValidationFailed(true);
                                    } else {
                                        setValidationFailed(false);
                                    }
                                    setBondOrderDetailIPO({ ...bondsOrderDetailIPO });
                                  }}
                                ></input>
                              </td>
                            );
                          })}
                          {bondsOrderDetailIPO?.ipo_ncd_series.map((feq: APIData.IPOorderNCDSeries, inx: number) => {
                            return <td>{parseInt(feq.ipo_order_ncd_series_amount) * qty}</td>;
                          })}
                        </tr> */}
                        <tr>
                          <td colSpan={10} style={{ textAlign: "end" }}>
                            Total
                          </td>
                          <td>{bondsOrderDetailIPO?.ipo_ncd_series.reduce((acc: any, curr: any) => acc + curr.ipo_order_ncd_series_quantity, 0)}</td>
                          <td>₹{numberWithCommas(bondsOrderDetailIPO?.ipo_ncd_series.reduce((acc : any, curr : any) => acc + parseInt(curr.ipo_order_ncd_series_amount) * curr.ipo_order_ncd_series_quantity, 0))}</td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    ""
                  )}
                </Fragment>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div className="ml-4 mr-4">
        <div className="text-center">
          <label htmlFor="signup" className="review-text mx-1 mb-0 p-0">
            <p className="review-text text-left fs-12 text-source">
              <input type="checkbox" id="signup" className="signup mx-1" name="check_box_share_data_with_govt" checked={agree} onChange={(e) => setagree(e.target.checked)} /> I have read and agreed to the <u className="signup-terms_conditions">disclaimer</u> and <u className="signup-terms_conditions">terms & conditions</u>
            </p>
          </label>
          <div className="text-center p-0 detail-view-description mt-3">
            {/* <button className="ipo-buy-button" onClick={(e: any) => {
                            e.preventDefault();
                            if (validationFailed == true) {
                                toast.error("Please Fill in Minimum Applications Before Proceeding");
                            } else {
                                goToBuy(bondsDetail?.bond_id!);
                            }
                        }}><p className="click-me ipo-detail-buy-button">Subscribe to this IPO Now&nbsp;<img width="12px" height="12px" src="assets/img/icon/rightarrow.png"></img></p></button> */}
            {/* <button
              className="apply-gold-btn  text-white"
              onClick={(e: any) => {
                e.preventDefault();
                if (agree == true) {
                  navigate(PageLinks.PAYMENTBOND);
                } else {
                  toast.error("Please select Terms & Conditions");
                }
              }}
            > */}{" "}
            <button
              className="view_btn_bond1 bonds-detail-btn btn-1 mx-2 mt-1"
              onClick={(e: any) => {
                e.preventDefault();
                if (validationFailed == true) {
                  toast.error("Please Fill in Minimum Applications Before Proceeding");
                } else if (agree == false) {
                  toast.error("Please checked terms & conditions ");
                } else {
                  goToBuy(bondsDetail?.bond_id!);
                }
              }}
            >
              Continue to Buy
            </button>
          </div>
        </div>
      </div>
      <Footer />
      <Category
        showModal={moreinfo}
        closeModal={() => {
          setMoreInfo(false);
        }}
      />
      <SubCategory
        showModal={subcat}
        closeModal={() => {
          setSubcat(false);
        }}
      />
    </div>
  );
};

export default ModifyIpoBond;
