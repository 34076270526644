import React from "react";
import { Helmet } from "react-helmet";

interface FormParameters {
    title: string,
    description: string,
    image?: string,
    content: string,
    keyword?: string
}


const MetaDecorator: React.FC<FormParameters> = ({ title, description, image, content, keyword }) => {
    return (

        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:image:secure_url" content={image} />
            {/* <meta property="og:image:type" content="image/jpeg" /> */}
            <meta property="og:image:width" content="400" />
            <meta property="og:image:height" content="300" />
            <meta property="og:url" content={content} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image:secure_url" content={image} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    )
};


export default MetaDecorator;