import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";


const EmailVerification: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const prevData: any = location.state;
    const [userDetails, setUserDetails] = useState<APIData.userDetail>()
    const [email_id, setMailId] = useState<APIData.userDetail>(InitialData.UserDetails);
    const { setShowPreloader } = useAppStateAPI();

    const validate = (values: APIData.userDetail) => {
        let errors: any = {};
        if (values.email_id == "") {
            errors.email_id = " Email Id is required";
        }
        return errors;
    }

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData().then((res) => {
            setUserDetails(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }, [])



    return (
        <div className="main-index-wrapper">
            <main>
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg pr-150 pl-150 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-11">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                           <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                            <h4 className="sub-title wow " data-wow-delay="0.3s">Mobile Verification</h4>
                                            <p className="review-text text-center">Enter the 6 digits, One Time Password sent on your number <br></br>
                                                <a href="#" className="mobile_number">+91 9820098200 <i className="far fa-edit"></i></a>
                                            </p>
                                            <form className="login-form quote-form mb-20" action="#">
                                                <div className="email-input input_field">
                                                    <input type="password" placeholder="Enter OTP" />
                                                    <label className="input-title"><a href="#">Didn’t get OTP?</a></label>
                                                </div>
                                                <a href="contact.html" className="theme_btn resend_otp_btn sub-btn wow " data-wow-delay="0.5s">Resend OTP</a>
                                            </form>
                                            <div className="divider_150"></div>
                                            <a href="contact.html" className="theme_btn primary_btn sub-btn wow " data-wow-delay="0.5s">Verify</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="otp_popup">
                    <div className="blur_back"></div>
                    <div className="container custom-container-subs">
                        <div className="sub-bg pt-150 pb-100 pr-md-50 pl-md-50 pr-xs-0 pl-xs-0">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 col-md-6  text-left" data-wow-delay="0.3s">
                                    <div className="do-box do-box-2 mb-30 email_verify_bg">
                                        <h6 className="mb--10  email_verify_head" data-wow-delay="0.1s">Email ID Verification</h6>
                                        <Formik initialValues={email_id} validate={validate} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                            let passData: APIData.userDetail;
                                            setShowPreloader(true);
                                            ServerAPI.EmailSendOtp(userDetails, values.email_id).then((res: any) => {
                                                if (res != undefined) {
                                                    toast.success(res['message']);
                                                    navigate(PageLinks.EMAIL_VERIFICATION_OTP)
                                                }
                                            }).finally(() => {
                                                setShowPreloader(false);
                                            })
                                        }}>
                                            {({
                                                values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,                                /* and other goodies */
                                            }) => (

                                                <form className="email_verify_form quote-form mb-20" onSubmit={handleSubmit}>
                                                    <div className="email-input input_field pl-0 pr-0">
                                                        <label className={errors.email_id && touched.email_id ? "error" : "input-title"}>Please provide your Email ID <span className="error-area">*</span></label>
                                                        <input type="text" placeholder="Enter Email ID"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="email_id"
                                                            value={values.email_id}
                                                            className={errors.email_id && touched.email_id ? "input-error" : ""} />
                                                        {errors.email_id && touched.email_id && (
                                                            <span className="error">{errors.email_id}</span>
                                                        )}
                                                    </div>
                                                    <button type="submit" className="theme_btn email_verify_btn primary_btn_1 sub-btn " data-wow-delay="0.5s" >Submit</button>

                                                    {/* <Link to={PageLinks.EMAIL_VERIFICATION_OTP} className="theme_btn email_verify_btn sub-btn " data-wow-delay="0.5s">Submit</Link> */}
                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default EmailVerification;
