import React from 'react'
interface FormParameters {
    document: string
}
const DownloadIssueDocuments: React.FC<FormParameters> = ({ document }) => {
    return (
        <section className="pt-30 pb-30 pb-md-20 pt-md-0 pt-xs-60 pb-xs-20">
            <div className="container-fluid">
                <div className="row  align-items-center detail-view-one-download-documents">
                    <div className="col-lg-1 col-md-2 col-sm-3 col-3 col-sm-1">
                        <img className="img" src="assets/img/Report.png" alt="" />
                    </div>
                    <div className="col-lg-7 col-md-9 col-sm-8 col-8 mt-3 mx-5 ml_sm_left">
                        <h4>DOWNLOAD ISSUE DOCUMENTS</h4>
                        <p>The document contains high-quality data which is fetched from SEBI and Exchange websites to help you make an informed decision.</p>
                    </div>
                    <div className="col-lg-3 cursor d-btn-none" onClick={(e: any) => {
                        e.preventDefault();
                        window.open(document);
                    }}>
                        <button className="download-btn mx-2">Download Now <svg xmlns="http://www.w3.org/2000/svg" className='ml-2' id="Group_13135" data-name="Group 13135" width="14" height="14" viewBox="0 0 84.894 84.841">
                            <path id="Path_23030" data-name="Path 23030" d="M-1060.766,973.612c.09-.191.19-.381.261-.578a4.679,4.679,0,0,1,5.155-3.109,4.673,4.673,0,0,1,4.01,4.572c.037,1.518,0,3.04,0,4.557a9.363,9.363,0,0,0,9.074,9.643c.18.006.358.006.537,0q23.34.03,46.721,0a9.368,9.368,0,0,0,9.651-9.077c.006-.2.006-.409,0-.614v-4.393a4.774,4.774,0,0,1,4.721-4.774,4.7,4.7,0,0,1,4.7,4.7v.075a40.759,40.759,0,0,1-.467,8.906,18.893,18.893,0,0,1-16.05,14.46,3.693,3.693,0,0,0-.531.153h-50.71a3.639,3.639,0,0,0-.53-.149,18.849,18.849,0,0,1-15.849-13.7c-.281-1.061-.456-2.121-.679-3.219v-7.453" transform="translate(1060.766 -913.292)" fill="#fff" />
                            <path id="Path_23031" data-name="Path 23031" d="M-1029.841,983.294V938.916a4.968,4.968,0,0,1,1.623-4.079,4.472,4.472,0,0,1,4.864-.811,4.464,4.464,0,0,1,2.9,3.914q.052.661.038,1.326V983.31l.386.286a2.979,2.979,0,0,1,.409-.8q4.96-5,9.951-9.966a4.556,4.556,0,0,1,4.716-1.246,4.432,4.432,0,0,1,3.279,3.5,4.727,4.727,0,0,1-1.492,4.583q-5.26,5.225-10.491,10.482-3.869,3.866-7.734,7.734a4.775,4.775,0,0,1-6.709.759,4.73,4.73,0,0,1-.759-.759q-9.112-9.108-18.22-18.22a4.718,4.718,0,0,1-.409-6.658,4.717,4.717,0,0,1,6.656-.408c.089.078.173.159.256.244,3.319,3.283,6.609,6.594,9.9,9.9a5.835,5.835,0,0,1,.531.733l.308-.17" transform="translate(1067.561 -933.64)" fill="#fff" />
                        </svg></button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DownloadIssueDocuments