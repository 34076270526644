import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { kycStatus, PageLinks } from "../common/Constants";
import { Field, Formik } from "formik";
import { APIData } from "../common/DataTypes";
import { InitialData } from "../components/Form/InitialData";
import { ServerAPI } from "../common/ServerAPI";
import toast from "react-hot-toast";
import StepsComponent from "../components/StepsComponent";
import { kycStatusUpdate } from "../common/Utilities";
import SignatureExample from "../components/Modals/SignatureExample";
import ConfirmationKYCModel from "../components/Modals/ConfirmationKYCModel";
import * as yup from "yup";
import moment from "moment";
import AppologiesPopup from "./AppologiesPopup";
import AppologiesPopupModel from "../components/Modals/AppologiesPopupModel";
import AppologiesPopupModel1 from "../components/Modals/AppologiesPopupModel1";

const DematDetails: React.FC = () => {
    const [exisitingDematDetails, setExisitingDematDetails] = useState<APIData.ExistingDematAccount>(InitialData.ExistingDematAccount);
    const [depositories, setDepositories] = useState<APIData.Depositories[]>([]);
    const [segmentID, setSegmentID] = useState<APIData.VariousSegment[]>([]);
    const [dematPage, setDematPage] = useState(1);
    const [dematSignUpload, setDematSignUpload] = useState(false);
    const [depositoryName, setDepositoryName] = useState("");
    const [confirmationModel, setConfirmationModel] = useState(false);
    const [dpconfirm, setDPConfirm] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const [wealthSource, setWealthSource] = useState<APIData.WealthSource[]>([]);
    const [segmentsDropDown, setSegmentsDropDown] = useState<APIData.Segments[]>([]);
    const [bornCity, setBornCity] = useState<APIData.WhereYouBorn[]>([]);
    const [newDematDetails, setNewDematDetails] = useState<APIData.NewDematAccount>(InitialData.newDematAccount);
    const [brokerageDropDown, setBrokerageDropDown] = useState<APIData.BrokerageScheme[]>([]);
    const prevData: any = location.state;
    const [signUrl, setSignUrl] = useState<string>('');
    let AccountRedirect = localStorage.getItem('demat');
    const [userDetail, setUserDetails] = useState<APIData.userDetail>();
    const [residentusa, setResidentusa] = useState(false);
    const [showSample, setShowSample] = useState(false);
    const { setShowPreloader } = useAppStateAPI();
    const [KycExit, setKycExit] = useState(false);


    const validationSchema = yup.object({
        depository_id: yup.number().min(1).required("Please select Depository Name"),
        dp_id: yup.string().when("depository_id", {
            is: 1,
            then: (d) => d.max(8, "Must start with IN").min(8, "Must start with IN followed by 8 digits").matches(/^([IN])+/, ' Must start with IN').required(),
            otherwise: (d) => d.max(16).min(16).required(),
        }),
        client_id: yup.string().when("depository_id", {
            is: 1,
            then: (d) => d.max(8, "Must start with IN").min(8, "Must start with IN followed by 8 digits").required(),
            otherwise: (d) => d.notRequired(),
        }),
        brokerage_scheme_id: yup.number().min(1).required("Please select Brokerage Scheme"),
        reenterclientid: yup
            .string().when("depository_id", {
                is: 1,
                then: (d) => d.oneOf([yup.ref("client_id")], "Client IDs don't match!")
                    .required("Required")
            }),
        dpidconfirm: yup
            .string()
            .oneOf([yup.ref("dp_id")], "Dp IDs don't match!")
            .required("Required"),
    });
    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.Depository().then((response: any) => {
            setDepositories(response?.message);
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
        ServerAPI.WealthSource().then((response) => {
            setWealthSource(response?.message);
        }).finally(() => {
            setShowPreloader(false);
        })
        ServerAPI.Segments()
            .then((response: any) => {
                setSegmentsDropDown(response?.message);
            })
            .finally(() => {
                setShowPreloader(false);
            });
        setShowPreloader(true);
        setShowPreloader(true);
        ServerAPI.BornCity().then((response) => {
            setBornCity(response?.message);
        }).finally(() => {
            setShowPreloader(false);
        })
        ServerAPI.BrokerageScheme()
            .then((response: any) => {
                setBrokerageDropDown(response?.message);
            })
            .finally(() => {
                setShowPreloader(false);
            });
    }, [])
    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.userDetailData()
            .then((res: any) => {
                setUserDetails(res["data"]);
            })
            .finally(() => {
                setShowPreloader(false);
            });
    }, []);
    const addNewDematDetails = (values: APIData.NewDematAccount) => {
        let dematvalues = { ...values };
        dematvalues.demat_signature_image = signUrl;
        localStorage.setItem('animation', 'default');
        setShowPreloader(true);
        ServerAPI.newDematAdd(dematvalues).then((response: any) => {
            if (response['message'] != undefined) {
                toast.success(response['message']);
                navigate(PageLinks.SIGNATURE_SUCCESS);
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const loadUploadedfile = (fileInput: any) => {
        let filetype = fileInput.files[0].type;
        filetype = filetype.split("/")[0];
        if (filetype == 'image') {
            setShowPreloader(true);
            ServerAPI.UploadSignature(fileInput.files[0]).then(response => {
                if (response.message != undefined && response.message != "") {
                    setSignUrl(response.message);
                    toast.success('Image Uploaded Successfully');
                } else {
                    toast.error('unable to upload the image please try again!');
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        } else {
            toast.error("Uploaded file is not an Image!");
        }
    }
    const handleSegments = (id: number, status: number, checked: boolean) => {
        let newsegments = [...segmentID];
        if (checked == true) {
            newsegments.push({
                various_segment_id: id,
                various_segment_selected_status: status,
            });
            setSegmentID(newsegments);
        } else {
            let removedSegements = newsegments.filter((segment: APIData.VariousSegment) => segment.various_segment_id != id);
            setSegmentID(removedSegements);
        }
    };

    return (
        <div className="main-index-wrapper">
            <main>
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className={AccountRedirect != "true" ? "section-title section-title-3 text-center" : "section-title section-title-3 text-center mb-5"}>
                                            <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                        </div>
                                    </div>
                                    {AccountRedirect != "true" && <StepsComponent step={3} />}
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow page-main-heading" data-wow-delay="0.1s">

                                                            </h6>
                                                            <h6 className="wow page-main-heading" data-wow-delay="0.1s">{dematPage == 1 ? "Existing Demat Details" : "Open Demat Account"}{dematPage == 2 && dematSignUpload == false && <span className="page-main-heading-steps">STEPS - 1 of 4</span>}{dematPage == 2 && dematSignUpload == true && <span className="page-main-heading-steps">STEPS - 2 of 4</span>}</h6>
                                                            <hr></hr>
                                                            <h6 className="pt-20 mb-10 " data-wow-delay="0.1s">
                                                                Hey {userDetail?.pan_name}, Enter Your Exisiting Demat Account Information
                                                            </h6>
                                                            <div className="col-xl-12 p-0">
                                                                <div className="choice-list">
                                                                    {dematPage == 2 && dematSignUpload == true ?
                                                                        <div className="col-xl-8 pl-0 float-left">
                                                                            <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">Signature Upload</h6>
                                                                            <p className="personal_sub_text">Let’s Upload your Signature</p>
                                                                        </div>
                                                                        :
                                                                        <span className="input-title-filled gender_col">
                                                                            <div className={dematPage == 1 ? "gender_btn selected" : "gender_btn"} >
                                                                                <input onClick={(e: any) => {
                                                                                    if (userDetail?.existing_demat_account_count == 2) {
                                                                                        e.preventDefault();
                                                                                        toast.error("Exisiting Demat Account Limit Reached!")
                                                                                    } else {
                                                                                        e.preventDefault();
                                                                                        setDematPage(1);
                                                                                    }
                                                                                }}
                                                                                    type="radio" value={1} className="d-none" name="gender" id="gender_option_1" />
                                                                                <label
                                                                                    htmlFor="gender_option_1"
                                                                                    className={dematPage == 1 ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                >
                                                                                    Existing Demat
                                                                                </label>
                                                                            </div>
                                                                            <div className={dematPage == 2 ? "gender_btn selected" : "gender_btn"}>
                                                                                <input onClick={(e: any) => {
                                                                                    if (userDetail?.new_demat_account_count == 1) {
                                                                                        e.preventDefault();
                                                                                        toast.error("New Demat Account Limit Reached!")
                                                                                    } else {
                                                                                        e.preventDefault();
                                                                                        setDematPage(2);
                                                                                    }

                                                                                }} type="radio" value={2} className="d-none" name="gender" id="gender_option_2" />
                                                                                <label
                                                                                    htmlFor="gender_option_2"
                                                                                    className={dematPage == 2 ? "gender-btn-label-selected" : "gender_btn-label"}
                                                                                >
                                                                                    Open New Demat
                                                                                </label>
                                                                            </div>
                                                                        </span>
                                                                    }

                                                                </div>
                                                            </div>

                                                            {dematPage == 1 &&

                                                                <Formik
                                                                    initialValues={exisitingDematDetails}
                                                                    // validate={validate} 
                                                                    enableReinitialize
                                                                    onSubmit={(values, { setSubmitting }) => {
                                                                      
                                                                        localStorage.setItem("animation", "exisitingdemat");
                                                                        let newValues = { ...values };
                                                                        console.log("🚀 ~ newValues:", newValues)
                                                                        newValues.various_segment = segmentID;
                                                                        // if (values.depository_id == 1) {
                                                                        //     newValues.dp_id = newValues.dp_id + newValues.client_id!;
                                                                        // } else {
                                                                        //     newValues.dp_id = newValues.dp_id;
                                                                        // }
                                                                        // delete newValues.client_id;
                                                                        // delete newValues.dpidconfirm;
                                                                        var test = false;
                                                                       if(newValues?.auth_accept_collateral_segments == 0 || newValues?.auth_fund_securities == 0 || newValues?.auth_receive_info_stock_broker == 0 || newValues?.auth_transfer_fund_securities == 0){
                                                                         toast.error("Please accept all the terms and conditions to proceed.");
                                                                         test = true;
                                                                       }
                                                                        if (userDetail?.existing_demat_account_count == 2) {
                                                                            toast.error("Exisiting Demat Account Limit Reached!"); 
                                                                            test = true;
                                                                        } 
                                                                        if(!test) {
                                                                            setShowPreloader(true);
                                                                            ServerAPI.exisitingDematAccount(newValues)
                                                                                .then((res: any) => {
                                                                                    if (res != undefined) {
                                                                                        toast.success(res['message']);
                                                                                        navigate(PageLinks.VERIFY_DEMAT_DETAILS_SUCCESS);

                                                                                    }
                                                                                })
                                                                                .finally(() => {
                                                                                    setShowPreloader(false);
                                                                                    localStorage.setItem("animation", "default");
                                                                                });
                                                                        }
                                                                    }}
                                                                    validationSchema={validationSchema}
                                                                >
                                                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                                                        <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                                                            <div className="col-xl-12 p-0">
                                                                                <div className="choice-list">
                                                                                    <h6 className="pt-20 mb-10 " data-wow-delay="0.1s">
                                                                                        Segment Type
                                                                                    </h6>
                                                                                    <span className="input-title-filled gender_col">
                                                                                        {segmentsDropDown != undefined &&
                                                                                            segmentsDropDown.length > 0 &&
                                                                                            segmentsDropDown.map((element: APIData.Segments) => {
                                                                                                return (
                                                                                                    <div className={segmentID.filter((item: APIData.VariousSegment) => item.various_segment_id == element.various_segment_id).length > 0 ? "gender_btn selected" : "gender_btn"}>
                                                                                                        <input
                                                                                                            onClick={(e: any) => {
                                                                                                                e.preventDefault();
                                                                                                                handleSegments(element.various_segment_id, element.various_segment_status, e.target.checked);
                                                                                                            }}
                                                                                                            type="checkbox"
                                                                                                            checked={segmentID.filter((item: APIData.VariousSegment) => item.various_segment_id == element.various_segment_id).length > 0 ? true : false}
                                                                                                            value={element.various_segment_id}
                                                                                                            className="d-none"
                                                                                                            name={"segment" + element.various_segment_id}
                                                                                                            id={"segment" + element.various_segment_id}
                                                                                                        />
                                                                                                        <label htmlFor={"segment" + element.various_segment_id} className={segmentID.filter((item: APIData.VariousSegment) => item.various_segment_id == element.various_segment_id).length > 0 ? "gender-btn-label-selected" : "gender_btn-label"}>
                                                                                                            {element.various_segment}
                                                                                                        </label>
                                                                                                    </div>
                                                                                                );
                                                                                            })}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-12 pl-0 float-left">
                                                                                <h6 className="mt-3 mb-0">Existing DP Details</h6>
                                                                                <div className="col-xl-4 pl-0 float-left mt-10">
                                                                                    <div className="email-input input_field pl-0">
                                                                                        <label className={errors.depository_id && touched.depository_id ? "error" : "input-title"}>Depository *</label>
                                                                                        <select
                                                                                            placeholder="Enter Depository"
                                                                                            onChange={(e: any) => {
                                                                                                handleChange(e);
                                                                                                setDepositoryName(e.nativeEvent.target[e.target.selectedIndex].text);
                                                                                            }}
                                                                                            onBlur={handleBlur}
                                                                                            className={errors.depository_id && touched.depository_id ? "input-error" : ""}
                                                                                            name="depository_id"
                                                                                            value={values.depository_id}
                                                                                        >
                                                                                            <option value={0}>Select</option>
                                                                                            {depositories != undefined &&
                                                                                                depositories.length > 0 &&
                                                                                                depositories.map((depository: APIData.Depositories, index) => {
                                                                                                    return <option value={depository.depository_id}>{depository.depository}</option>;
                                                                                                })}
                                                                                        </select>
                                                                                        {errors.depository_id && touched.depository_id && <span className="error">{errors.depository_id}</span>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {depositoryName == "Central Depository Services (India) Limited - CDSL" ? (
                                                                                <Fragment>
                                                                                    <div className="col-xl-12 pl-0 float-left">
                                                                                        <div className="col-xl-4 pl-0 float-left mt-10">
                                                                                            <div className="email-input input_field pl-0">
                                                                                                <label className={errors.dp_id && touched.dp_id ? "error" : "input-title"}>DP & Client (16 Digit Client Account No.) - ID*</label>
                                                                                                <input type="text" placeholder="Enter DP - ID" name="dp_id" value={values.dp_id} onChange={handleChange} onBlur={handleBlur} maxLength={16} className={errors.dp_id && touched.dp_id ? "input-error" : ""} />
                                                                                                {errors.dp_id && touched.dp_id && <span className="error">{errors.dp_id}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-1 pl-0 float-left demat-dash  text-center">-</div>
                                                                                        <div className="col-xl-4 pl-0 float-left mt-10">
                                                                                            <div className="email-input input_field pl-0">
                                                                                                <label className={errors.dpidconfirm && touched.dpidconfirm ? "error" : "input-title"}>DP & Client (16 Digit Client Account No.) - ID*</label>
                                                                                                <input type="text" placeholder="Enter DP - ID" name="dpidconfirm" value={values.dpidconfirm} onChange={handleChange} onBlur={handleBlur} maxLength={16} className={errors.dpidconfirm && touched.dpidconfirm ? "input-error" : ""} />
                                                                                                {errors.dpidconfirm && touched.dpidconfirm && <span className="error">{errors.dpidconfirm}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Fragment>
                                                                            ) : (
                                                                                <Fragment>
                                                                                    <div className="col-xl-12 pl-0 float-left">
                                                                                        <div className="col-xl-4 pl-0 float-left mt-10">
                                                                                            <div className="email-input input_field pl-0">
                                                                                                <label className={errors.dp_id && touched.dp_id ? "error" : "input-title"}>DP - ID *</label>
                                                                                                <input type="password" placeholder="Enter DP Name" name="dp_id" value={values.dp_id} onChange={handleChange} onBlur={handleBlur} maxLength={8} className={errors.dp_id && touched.dp_id ? "input-error" : ""} />
                                                                                                {errors.dp_id && touched.dp_id && <span className="error">{errors.dp_id}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-1 pl-0 float-left demat-dash  text-center">-</div>
                                                                                        <div className="col-xl-4 pl-0 float-left mt-10">
                                                                                            <div className="email-input input_field pl-0">
                                                                                                <label className={errors.client_id && touched.client_id ? "error" : "input-title"}>Client - ID *</label>
                                                                                                <input type="password" placeholder="Enter Client - ID" name="client_id" value={values.client_id} onChange={handleChange} onBlur={handleBlur} maxLength={8} className={errors.client_id && touched.client_id ? "input-error" : ""} />
                                                                                                {errors.client_id && touched.client_id && <span className="error">{errors.client_id}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xl-12 pl-0 float-left">
                                                                                        <div className="col-xl-4 pl-0 float-left mt-10">
                                                                                            <div className="email-input input_field pl-0">
                                                                                                <label className={errors.dpidconfirm && touched.dpidconfirm ? "error" : "input-title"}>Re-enter DP - ID *</label>
                                                                                                <input type="text" placeholder="Enter DP Name" name="dpidconfirm" value={values.dpidconfirm} onChange={handleChange} onBlur={handleBlur} maxLength={8} className={errors.dpidconfirm && touched.dpidconfirm ? "input-error" : ""} />
                                                                                                {errors.dpidconfirm && touched.dpidconfirm && <span className="error">{errors.dpidconfirm}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xl-1 pl-0 float-left demat-dash  text-center">-</div>
                                                                                        <div className="col-xl-4 pl-0 float-left mt-10">
                                                                                            <div className="email-input input_field pl-0">
                                                                                                <label className={errors.reenterclientid && touched.reenterclientid ? "error" : "input-title"}>Re-enter Client - ID *</label>
                                                                                                <input type="text" placeholder="Enter DP - ID" name="reenterclientid" value={values.reenterclientid} onChange={handleChange} onBlur={handleBlur} maxLength={8} className={errors.reenterclientid && touched.reenterclientid ? "input-error" : ""} />
                                                                                                {errors.reenterclientid && touched.reenterclientid && <span className="error">{errors.reenterclientid}</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Fragment>
                                                                            )}
                                                                            <div className="col-xl-12 pl-0 float-left">
                                                                                <div className="col-xl-9 pl-0 float-left mt-10">
                                                                                    <div className="email-input input_field pl-0">
                                                                                        <label className={errors.brokerage_scheme_id && touched.brokerage_scheme_id ? "error" : "input-title"}>Brokerage Scheme *</label>
                                                                                        <select placeholder="Enter Depository" onChange={handleChange} onBlur={handleBlur} className={errors.brokerage_scheme_id && touched.brokerage_scheme_id ? "input-error" : ""} name="brokerage_scheme_id" value={values.brokerage_scheme_id}>
                                                                                            <option value={0}>Select</option>
                                                                                            {brokerageDropDown != undefined &&
                                                                                                brokerageDropDown.length > 0 &&
                                                                                                brokerageDropDown.map((broker: APIData.BrokerageScheme, index) => {
                                                                                                    return <option value={broker.brokerage_scheme_id}>{broker.brokerage_scheme}</option>;
                                                                                                })}
                                                                                        </select>
                                                                                        {errors.brokerage_scheme_id && touched.brokerage_scheme_id && <span className="error">{errors.brokerage_scheme_id}</span>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-12 pl-0 float-left mt-4">
                                                                                <label htmlFor="terms" className="review-text mx-1 mb-0 p-0">
                                                                                    <p className="review-text text-left mt-1">
                                                                                        <input
                                                                                            className="signup"
                                                                                            type="checkbox"
                                                                                            id="terms"
                                                                                            name="auth_fund_securities"
                                                                                            onChange={(e: any) => {
                                                                                                if (e.target.checked == true) {
                                                                                                    values.auth_fund_securities = 1;
                                                                                                } else {
                                                                                                    values.auth_fund_securities = 0;
                                                                                                }
                                                                                            }}
                                                                                        />{" "}
                                                                                        I hereby authorise for TFCSPL Periodic Settlement of Funds and Securities:
                                                                                    </p>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-xl-12 pl-0 float-left mt-1">
                                                                                <label htmlFor="terms1" className="review-text mx-1 mb-0 p-0">
                                                                                    <p className="review-text text-left mt-1">
                                                                                        <input
                                                                                            className="signup"
                                                                                            type="checkbox"
                                                                                            id="terms1"
                                                                                            name="auth_receive_info_stock_broker"
                                                                                            onChange={(e: any) => {
                                                                                                if (e.target.checked == true) {
                                                                                                    values.auth_receive_info_stock_broker = 1;
                                                                                                } else {
                                                                                                    values.auth_receive_info_stock_broker = 0;
                                                                                                }
                                                                                            }}
                                                                                        />{" "}
                                                                                        I hereby authorise TFCSPL for receiving any documents/communications in electronic form by E-mail from the Stock Broker
                                                                                    </p>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-xl-12 pl-0 float-left mt-1">
                                                                                <label htmlFor="terms2" className="review-text mx-1 mb-0 p-0">
                                                                                    <p className="review-text text-left mt-1">
                                                                                        <input
                                                                                            className="signup"
                                                                                            type="checkbox"
                                                                                            id="terms2"
                                                                                            name="auth_transfer_fund_securities"
                                                                                            onChange={(e: any) => {
                                                                                                if (e.target.checked == true) {
                                                                                                    values.auth_transfer_fund_securities = 1;
                                                                                                } else {
                                                                                                    values.auth_transfer_fund_securities = 0;
                                                                                                }
                                                                                            }}
                                                                                        />{" "}
                                                                                        I hereby authorise TFCSPL for the Transfer of Funds and/or Securities, Inter Segment and Inter Exchange
                                                                                    </p>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-xl-12 pl-0 float-left mt-1">
                                                                                <label htmlFor="terms3" className="review-text mx-1 mb-0 p-0">
                                                                                    <p className="review-text text-left mt-1">
                                                                                        <input
                                                                                            className="signup"
                                                                                            type="checkbox"
                                                                                            id="terms3"
                                                                                            name="auth_accept_collateral_segments"
                                                                                            onChange={(e: any) => {
                                                                                                if (e.target.checked == true) {
                                                                                                    values.auth_accept_collateral_segments = 1;
                                                                                                } else {
                                                                                                    values.auth_accept_collateral_segments = 0;
                                                                                                }
                                                                                            }}
                                                                                        />{" "}
                                                                                        I hereby authorise TFCSPL for Acceptance of securities as collateral for any or all segment(s)
                                                                                    </p>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-xl-12 continue_btn_whole mb-3 mt-5">
                                                                                <button onClick={(e: any) => {
                                                                                    kycStatusUpdate(kycStatus.ESIGN);
                                                                                }} type="submit" className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s">
                                                                                    Add Demat Account
                                                                                </button>
                                                                            </div>
                                                                            <div
                                                                                onClick={(e: any) => {
                                                                                    e.preventDefault();
                                                                                    setKycExit(true);
                                                                                }}
                                                                                className="save_continue_btn text-center cursor"
                                                                            >
                                                                                <u>Save & Complete Later</u>
                                                                            </div>
                                                                        </form>
                                                                    )}
                                                                </Formik>

                                                            }
                                                            {dematPage == 2 && dematSignUpload == false &&
                                                                <Formik initialValues={newDematDetails} enableReinitialize onSubmit={(values, { setSubmitting }) => {
                                                                    setNewDematDetails(values);
                                                                    setDematSignUpload(true);
                                                                }}>
                                                                    {({
                                                                        values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
                                                                    }) => (
                                                                        <form className="quote-form mb-10" onSubmit={handleSubmit}>
                                                                            <div className="col-xl-8 pl-0 float-left">
                                                                                <h6 className="pt-20 mb--10  pb-10" data-wow-delay="0.1s">Mandatory Questions</h6>
                                                                                <p className="personal_sub_text">Sebi Requires the following details to open your Demat account</p>
                                                                                <div className="col-xl-6 pl-0 float-left">
                                                                                    <div className="email-input input_field pl-0">
                                                                                        <label className="input-title">Where were you born?</label>
                                                                                        <select name="born_place" className="" placeholder="Select Your Country" onChange={handleChange} onBlur={handleBlur} >
                                                                                            <option value={""}>Select</option>
                                                                                            {bornCity != undefined && bornCity.length > 0 && bornCity.map((country: APIData.WhereYouBorn, index) => {
                                                                                                return (

                                                                                                    <option key={index} value={country.where_you_born_id} selected>{country.country_name}</option>
                                                                                                )
                                                                                            })

                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                    {errors.born_place && touched.born_place && (
                                                                                        <span className="error">{errors.born_place}</span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-4 p-0 float-left">
                                                                            </div>
                                                                            <div className="col-xl-8 p-0 float-left">
                                                                                <div className="col-xl-6 pl-0 float-left">
                                                                                    <div className="choice-list political_switch">
                                                                                        <span className="input-title">Are you a citizen of the U.S.A?</span>
                                                                                        <div className="input-title-filled">
                                                                                            <div>
                                                                                                <div className="custom-switch custom-switch-label-yesno pl-0">
                                                                                                    <input className="custom-switch-input" id="citizen" name="dp_resident_usa" type="checkbox" checked={values.dp_resident_usa == 1 ? true : false}
                                                                                                        onChange={(e: any) => {
                                                                                                            if (e.target.checked) {
                                                                                                                setConfirmationModel(true);
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                    <label className="custom-switch-btn" htmlFor="citizen"></label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="choice-list future_switch">
                                                                                        <span className="input-title">Is Your Country Of Tax Residency, India?</span>
                                                                                        <div className="input-title-filled">
                                                                                            <div className="custom-switch custom-switch-label-yesno pl-0">
                                                                                                <input className="custom-switch-input" id="Residency" type="checkbox" onChange={(e: any) => {
                                                                                                    if (e.target.checked) {
                                                                                                        values.dp_resident_india = 1;
                                                                                                    } else {
                                                                                                        values.dp_resident_india = 0;
                                                                                                    }
                                                                                                }} name="dp_resident_india" />
                                                                                                <label className="custom-switch-btn" htmlFor="Residency"></label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {errors.dp_resident_india && touched.dp_resident_india && (
                                                                                        <span className="error">{errors.dp_resident_india}</span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-4 p-0 float-left">

                                                                            </div>
                                                                            <div className="col-xl-8 p-0 float-left">
                                                                                <div className="col-xl-6 pl-0 float-left">
                                                                                    <div className="email-input input_field pl-0">
                                                                                        <label className="input-title">Your primary source of wealth?</label>
                                                                                        <select className="" name="primary_source" placeholder="Select Your Primary Source" onChange={handleChange} onBlur={handleBlur} >
                                                                                            <option value={""}>Select</option>
                                                                                            {wealthSource != undefined && wealthSource.length > 0 && wealthSource.map((source: APIData.WealthSource, index) => {
                                                                                                return (
                                                                                                    <option key={index} value={source.primary_source_of_wealth_id} selected>{source.primary_source_of_wealth_name}</option>
                                                                                                )
                                                                                            })

                                                                                            }
                                                                                        </select>
                                                                                        {errors.primary_source && touched.primary_source && (
                                                                                            <span className="error">{errors.primary_source}</span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-8 p-0 float-left">
                                                                                <label htmlFor="statement" className="review-text mx-1 mb-0 p-0">
                                                                                    <p className="agree-text text-left mt-0"><input type="checkbox" className="signup" id="statement" name="account_statement_in_electronics signup" onChange={(e: any) => {
                                                                                        if (e.target.checked) {
                                                                                            values.account_statement_in_electronics = 1;
                                                                                        } else {
                                                                                            values.account_statement_in_electronics = 0;
                                                                                        }
                                                                                    }} /> I am aware & agree that <Link to={"#"}>MY ACCOUNT STATEMENTS</Link> will be in electronic & I do not require a <Link to={"#"}>DIS</Link> slip.</p>
                                                                                </label>
                                                                                {errors.account_statement_in_electronics && touched.account_statement_in_electronics && (
                                                                                    <span className="error">{errors.account_statement_in_electronics}</span>
                                                                                )}
                                                                                <label htmlFor="signup" className="review-text mx-1 mb-0 p-0">
                                                                                    <p className="review-text text-left pb-0"><input type="checkbox" className="signup" id="signup" name="demat_terms_and_conditions"
                                                                                        onChange={(e: any) => {
                                                                                            if (e.target.checked) {
                                                                                                values.demat_terms_and_conditions = 1;
                                                                                            } else {
                                                                                                values.demat_terms_and_conditions = 0;
                                                                                            }
                                                                                        }}
                                                                                    /> I accept the <Link to={"#"}>Terms & Conditions</Link></p>
                                                                                </label>
                                                                                {errors.demat_terms_and_conditions && touched.demat_terms_and_conditions && (
                                                                                    <span className="error">{errors.demat_terms_and_conditions}</span>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-xl-4 p-0 float-left">

                                                                            </div>
                                                                            <div className="divider_15"></div>
                                                                            <div className="col-xl-12 continue_btn_whole mb-3" style={{ display: "inline-block" }}>
                                                                                <button className="theme_btn primary_btn_one sub-btn wow" data-wow-delay="0.5s">Continue</button>
                                                                            </div>
                                                                            <div onClick={(e: any) => {
                                                                                e.preventDefault();
                                                                                setKycExit(true);
                                                                            }} className="save_continue_btn text-center cursor"><u>Save & Complete Later</u></div>
                                                                        </form>
                                                                    )}


                                                                </Formik>
                                                            }
                                                            {dematPage == 2 && dematSignUpload == true &&

                                                                <div className="step_box_content">
                                                                    <div>
                                                                        <div className="">
                                                                            <div className="col-xl-12">
                                                                                <div className="subscribe-wrapper">
                                                                                    <div className="section-title section-title-3">
                                                                                        <form className="quote-form mb-10" action="#">
                                                                                            <div className="col-xl-12 p-0 float-left">
                                                                                                <div className="signature_preview_box">
                                                                                                    <div className="signature_preview_box_head">Signature Preview</div>
                                                                                                    <div className="signature_preview_box_content">
                                                                                                        <p className="signature_preview_box_content_text">Ensure that you sign on a blank paper in a clearly &nbsp;<u className="signup-terms_conditions"><span className="instruc_img_colored_text text-style cursor" onClick={(e: any) => {
                                                                                                            e.preventDefault();
                                                                                                            setShowSample(true);
                                                                                                        }}>See Example</span></u></p>
                                                                                                        <div className="upload_img cursor">
                                                                                                            <label htmlFor="back">
                                                                                                                <img className="cursor"
                                                                                                                    onClick={(e: any) => {
                                                                                                                        if (signUrl != "") {
                                                                                                                            e.preventDefault();
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    src={signUrl != undefined && signUrl != undefined && signUrl != "" ? signUrl : "assets/img/icon/Upload.png"} />
                                                                                                            </label>

                                                                                                            <input
                                                                                                                type="file"
                                                                                                                id="back"
                                                                                                                hidden
                                                                                                                name="demat_signature_image"
                                                                                                                onChange={(e: any) => {
                                                                                                                    loadUploadedfile(e.target);
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <p className="upload_img_text">Upload your Signature</p>
                                                                                                        {signUrl != "" &&
                                                                                                            <div className="replace-btn-address-proof" >
                                                                                                                <label htmlFor="back">Replace</label></div>
                                                                                                        }
                                                                                                        <br />
                                                                                                        <p className="signature_preview_box_content_text_one">upload your Signature <Link to={'#'}>JPG</Link>, <Link to={'#'}>JPEG</Link> or PNG in less than <Link to={'#'}>10 MB</Link></p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-12 p-0 float-left text-center">
                                                                                                <button type="button" className="theme_btn primary_btn_one sub-btn wow w-auto" data-wow-delay="0.5s" onClick={(e: any) => {
                                                                                                    e.preventDefault();
                                                                                                    addNewDematDetails(newDematDetails);
                                                                                                    kycStatusUpdate(kycStatus.ESIGN);
                                                                                                }}>Continue</button>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ConfirmationKYCModel
                    showModal={KycExit}
                    closeModal={() => {
                        setKycExit(false);
                    }}
                    confirmtype={"demat"}
                />
                {/* Profile-details-edit-area end */}
                <AppologiesPopupModel1 showModal={confirmationModel} closeModal={() => { setConfirmationModel(false) }} />
            </main>
        </div>
    );
};

export default DematDetails;
