import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import ChequeUpload from "./ChequeUpload";
import { useNavigate, useLocation } from "react-router-dom";
import { bondPurchaseTypes, bondListTypes, PageLinks } from "../../common/Constants";
import { APIData } from "../../common/DataTypes";
import { ServerAPI } from "../../common/ServerAPI";
import toast from "react-hot-toast";
import { useAppStateAPI } from "../../common/AppStateAPI";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
    id: any,
    orderQty: any,
    orderNO: any,
    setCancledata: any,
    bondType: any,
    setOrdersList?: any,
    selectedFilters?: any,
    pageno?: any
}

const CanclePopup: React.FC<FormParameters> = ({ showModal, closeModal, id, orderQty,setOrdersList,selectedFilters,pageno, orderNO, setCancledata ,bondType}) => {
    const navigate = useNavigate();
    const { setShowPreloader } = useAppStateAPI();
    const location = useLocation();
    const actionflag = 2;

    const CancleOrder = () => {
        if (showModal == true) {
            if(parseInt(bondType) === bondListTypes.ADDEDBOND && id != 0){
                setShowPreloader(true);
                ServerAPI.Cancle(id, orderQty, actionflag).then((response: any) => {
                    if (response != undefined) {
                        toast.success(response['message']);
                        let data: any = {};
                        data = response['message'];
                        setCancledata(parseInt(bondType));
                        closeModal();
                        setOrdersList(pageno, selectedFilters);
                    }
                }).finally(() => {
                    setShowPreloader(false);
                })
            } else if (parseInt(bondType) === bondListTypes.SGB && id != 0) {
                setShowPreloader(true);
                ServerAPI.SGBCancle(id, actionflag).then((response: any) => {
                    if (response != undefined) {
                        toast.success(response['message']);
                        let data: any = {};
                        data = response['message'];
                        // setCancledata({ data });
                        setCancledata(parseInt(bondType));
                        closeModal();
                        setOrdersList(pageno, selectedFilters);
                    }
                }).finally(() => {
                        setShowPreloader(false);
                })
            }  else if (parseInt(bondType) === bondListTypes.IPO  && id != 0) {
                setShowPreloader(true);
                ServerAPI.IPOCancle(parseInt(id), actionflag).then((response: any) => {
                    if (response != undefined) {
                        toast.success(response['message']);
                        let data: any = {};
                        data = response['message'];
                        // setCancledata({ data });
                        setCancledata(parseInt(bondType));
                        closeModal();
                        setOrdersList(pageno, selectedFilters);
                    }
                }).finally(() => {
                        setShowPreloader(false);
                })
            } else {
                toast.error('Order ID is not valid');
            }
        } 
    }
    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog className="dialog-background"
                    open={showModal}
                    keepMounted
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            closeModal();
                        }
                    }}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    PaperProps={{
                        style: {
                            background: 'none',
                            boxShadow: 'none',
                        },
                    }}
                    maxWidth="md"
                >
                    <DialogContent style={{ overflow: "hidden" }} className="p-sm-none">
                        <DialogContentText id="alert-dialog-slide-description" className="profile-update-bg">
                            <div className="do-box mb-30 confirmation-model-bg1 h-auto w-auto  text-center">
                                <img src="assets/img/icon/warning.png" className="user_icon" />
                                <p className="mb-10  identity_verify_success1" data-wow-delay="0.1s">Are you sure?</p>
                                <p className="mb-10  confirmation-model-sub-head1" data-wow-delay="0.1s">Do you want to cancel this Order.</p>
                                <h4 className="mb-10  text-white fs-18 p-0 text-source font-weight-500 text-left" data-wow-delay="0.1s">Remark</h4>
                                <p className="bg-white-p ">Sorry, I've miss clicked while placing the order. Cancel this one if possible.</p>
                                <div className="mt-3 col-md-12 col-xl-12 row col-12 m-auto">
                                    <div className="col-md-6 col-xl-6 col-6 p-sm-none">
                                        <button onClick={(e: any) => {
                                            e.preventDefault();
                                            closeModal();
                                        }} type="submit" className="theme_btn email_verify_btn primary_btn_1 sub-btn " data-wow-delay="0.5s">Cancel</button>
                                    </div>
                                    <div className="col-md-6 col-xl-6 col-6 p-sm-none">
                                        <button onClick={(e: any) => {
                                            e.preventDefault();
                                            CancleOrder();
                                        }} type="submit" className="theme_btn email_verify_btn primary_btn_1 sub-btn confirm-btn mx-1" data-wow-delay="0.5s">Cancel Order</button>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

            </main>
        </div>
    )
}

export default CanclePopup;