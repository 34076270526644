import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { AccountTypeValues1, bondPurchaseTypes, bondTypes, PageLinks, RazorPayKey, exchange } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import { ServerAPI } from "../common/ServerAPI";
import { InitialData } from "../components/Form/InitialData";
import BankAccounts from "../components/Modals/BankAccounts";
import DematAccounts from "../components/Modals/DematAccounts";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import useRazorpay, { RazorpayOptions } from "react-razorpay";

const PayBondPayment: React.FC = () => {
  const [paymentMode, setPaymentMode] = useState<any>(null);
  const location = useLocation();
  const [Exchange, setExchange] = useState(exchange.NotSelected);
  const [isin, setIsin] = useState("");
  const [id, setID] = useState("");
  const [qty, setQty] = useState(0);
  const [Quty, setQuty] = useState(0);
  const [IPOQty, setIPOQty] = useState(0);
  const [paymode, setPaymode] = useState(0);
  const [paymodes, setPaymodes] = useState(0);
  const [dematId, setDematId] = useState(0);
  const [bondID, setBondID] = useState(0);
  const [bankId, setBankId] = useState(0);
  const [cityID, setCityId] = useState(1);
  const [dematType, setDematType] = useState(0);
  const [bondType, setBondType] = useState(0);
  const { setShowPreloader } = useAppStateAPI();
  const [userDetail, setUserDetails] = useState<APIData.userDetail>(InitialData.UserDetails);
  const [exisitingDematDetails, setExisitingDematBankDetails] = useState<APIData.ExisitingDematAccountDetails[]>([]);
  const [newDematDetails, setNewDematDetails] = useState<APIData.NewDematDetails[]>([]);
  const [bankDetails, setBankDetails] = useState<APIData.BankAccountDetails[]>([]);
  const [ncdSeriesID, setNCDSeriesID] = useState<APIData.NCDSeriesOrder[]>([]);
  const [asbaDetails, setASBADetails] = useState<APIData.ASBADetails[]>([]);
  const [paymentModes, setPaymentModes] = useState<APIData.PaymentModes[]>([]);
  const [dematSelect, setDematSelect] = useState(false);
  const [bankSelect, setBankSelect] = useState(false);
  const [bankSelected, setBankSelected] = useState(false);

  const [selectedDemat, setSelectedDemat] = useState<any[]>([]);

  const [selectedBank, setSelectedBank] = useState<any>({});

  const [ipoData, setIpoData] = useState<any>();
  const [sgbData, setSGBData] = useState<any>();
  const [upiID, setUPIID] = useState("");
  const [secondPanName, setSecondPanName] = useState("");
  const [secondPanNo, setSecondPanNo] = useState("");
  const [thirdPanName, setThirdPanName] = useState("");
  const [thirdPanNo, setThirdPanNo] = useState("");
  const [categoryid, setCategoryID] = useState(0);
  const [subCategoryCode, setSubCategoryCode] = useState("");
  const [OID, setOID] = useState('');
  const [Modify, setModify] = useState(0);
  const [ONO, setNO] = useState(0);
  const [Action, setAction] = useState(0);
  const [UPIvalue, setUPIvalue] = useState('@okicici');
  const [dataIpo, setDataIpo] = useState<any>();
  const [List, setList] = useState('');
  const navigate = useNavigate();
  var data: any = {};
  data = location.state;
  const Razorpay = useRazorpay();



  useEffect(() => {
    data = location.state;
    if (data != null && data["data"][0] != undefined && data["data"][0] != null) {
      setModify(data.data[0]?.modify)
      setQuty(data.data[0]?.qty)
      setOID(data.data[0]?.Order_ID)
      setNO(data.data[0]?.Order_No)
      setDataIpo(data["data"][0])
      setPaymodes(data["data"][0].payment_mode_id)
      setNCDSeriesID(data["data"][0].bond_ncd_series)
      setList(data["data"][0].List)
    }

  }, [location]);

  // useEffect(() => {

  //     if (location.search !== "") {
  //         const query = new URLSearchParams(location.search);
  //         if (query.has("bond")) {
  //             const id = query.get("bond");

  //         }
  //     }
  //     if (location.search !== "") {
  //         const query = new URLSearchParams(location.search);
  //         if (query.has("qty")) {
  //             const qty = query.get("qty");
  //             setQty(parseInt(qty!));

  //         }
  //     }
  //     if (location.search !== "") {
  //         const query = new URLSearchParams(location.search);
  //         if (query.has("bond_id")) {
  //             const bond_id = query.get("bond_id");
  //             setBondID(parseInt(bond_id!));

  //         }
  //     }
  // }, [location]);

  const dataVerify = () => {
    let ncddata: any = [];
    data = location.state;
    if (data != null && data["data"][0] != undefined && data["data"][0] != null) {
      if (data["data"][0].bond_type == bondTypes.SGB) {
        setBondType(data["data"][0].bond_type);
        setBondID(data["data"][0].bond_id);
      } else if (data["data"][0].bond_type == bondTypes.IPO) {
        setBondType(data["data"][0].bond_type);
        setBondID(data["data"][0].bond_id);
        if (data["data"][0] != undefined && data["data"][0].bond_ncd_series != undefined) {
          if (data["data"][0].bond_ncd_series.length > 0) {
            setNCDSeriesID(data["data"][0].bond_ncd_series);
          }

        }
        setSecondPanName(data["data"][0].ipo_order_second_appl_name);
        setSecondPanNo(data["data"][0].ipo_order_second_pan);
        setThirdPanName(data["data"][0].ipo_order_third_appl_name);
        setThirdPanNo(data["data"][0].ipo_order_third_pan);
        setCategoryID(data["data"][0].ipo_order_investor_category_id);
        setSubCategoryCode(data["data"][0].ipo_order_sub_category_code);
      } else if (data["data"][0].bond_type == bondTypes.ADDEDBOND) {
        setBondType(data["data"][0].bond_type);
        setQty(data["data"][0].qty);
        setBondID(data["data"][0].bond_id);
      }
    }
  };
  useEffect(() => {
    dataVerify();
  }, [location]);

  useEffect(() => {
    if (data != null && data["data"] != undefined) {
      if (data["data"][0].bond_type == bondTypes.IPO) {
        setIpoData(data["data"][0]);
      } else if (data["data"][0].bond_type == bondTypes.SGB) {
        setSGBData(data["data"][0]);
      }
    }
  }, [data]);

  const getExisitingDematAccounts = () => {
    setShowPreloader(true);
    ServerAPI.ExisitingDematAccountList()
      .then((response: any) => {
        setExisitingDematBankDetails(response.message);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const getASBACities = () => {
    if (data["data"] != undefined && data["data"][0] != undefined && data["data"][0].bond_type !== bondTypes.IPO) {
      setShowPreloader(true);
      ServerAPI.ASBALists(data["data"][0].bond_id, selectedBank.id)
        .then((response: any) => {
          if (response != undefined && response["message"] != undefined) {
            setASBADetails(response["message"]);
            setCityId(response["message"][0]?.asba_bank_location_id)
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  };
  const getNewDematAccounts = () => {
    setShowPreloader(true);
    ServerAPI.NewDematAccountList()
      .then((response: any) => {
        setNewDematDetails(response.message);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const getbankAccounts = () => {
    if (data != null && data["data"] != undefined && data["data"][0] != undefined) {
      if (data["data"][0].bond_type == bondTypes.IPO && paymentMode !== null && paymentMode == 'ASBA') {
        setShowPreloader(true);
        ServerAPI.OrderBankList(data["data"][0].bond_id)
          .then((response: any) => {
            if (response.length > 0) {
              let params: any = {};
              params.id = response[0].bank_id;
              params.number = response[0].account_number;
              params.name = response[0].bank_name;
              params.account_type = response[0].account_type_1;
              params.primary = response[0].is_primary;
              params.is_asba = response[0].is_asba;
              setShowPreloader(true);

              ServerAPI.ASBALists(data["data"][0].bond_id, response[0].bank_id)
                .then((response: any) => {
                  if (response != undefined && response["message"] != undefined) {
                    setASBADetails(response["message"]);
                    setCityId(response["message"][0]?.asba_bank_location_id)

                  }
                })
                .finally(() => {
                  setShowPreloader(false);
                });
              setSelectedBank(params);
              setBankDetails(response);
              setBankId(response[0].bank_id);
            }
          })
          .finally(() => {
            setShowPreloader(false);
          });
      } else if (data["data"][0].bond_type == bondTypes.SGB) {
        setShowPreloader(true);
        ServerAPI.OrderBankList(data["data"][0].bond_id)
          .then((response: any) => {
            if (response.length > 0) {
              let params: any = {};
              params.id = response[0].bank_id;
              params.number = response[0].account_number;
              params.name = response[0].bank_name;
              params.account_type = response[0].account_type_1;
              params.primary = 1;
              setShowPreloader(true);

              setSelectedBank(params);
              setBankDetails(response);
              setBankId(response[0].bank_id);
            }
          })
          .finally(() => {
            setShowPreloader(false);
          });
      } else if (data["data"][0]?.bond_type == bondTypes.ADDEDBOND || data["data"][0]?.bond_type == bondTypes.EXISTINGBOND) {
        setShowPreloader(true);
        ServerAPI.OrderBankList(data["data"][0].bond_id)
          .then((response: any) => {
            if (response.length > 0) {
              let params: any = {};
              params.id = response[0].bank_id;
              params.number = response[0].account_number;
              params.name = response[0].bank_name;
              params.account_type = response[0].account_type_1;
              params.primary = 1;
              setShowPreloader(true);

              setSelectedBank(params);
              setBankDetails(response);
              setBankId(response[0].bank_id);
            }
          })
          .finally(() => {
            setShowPreloader(false);
          });
      }
    }
  };

  const PaymentModes = () => {
    if (bondType == bondTypes.SGB) {
      setShowPreloader(true);
      ServerAPI.GetSGBPaymentModes()
        .then((response: any) => {
          if (response != undefined) {
            setPaymentModes(response["payment_modes"]);
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
    if (bondType == bondTypes.ADDEDBOND || bondType == bondTypes.EXISTINGBOND || bondType == bondTypes.IPO) {
      setShowPreloader(true);
      ServerAPI.GetBondPaymentModes()
        .then((response: any) => {
          if (response != undefined) {
            setPaymentModes(response["payment_modes"]);
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  };

  useEffect(() => {
    PaymentModes();
  }, [bondType]);

  useEffect(() => {
    if (bankId != 0 && paymentMode == "ASBA" && paymentMode != null) {
      getASBACities();
    }
  }, [bankId, paymentMode]);

  useEffect(() => {
    getExisitingDematAccounts();
    getNewDematAccounts();
    // if ((bondType) != bondTypes.SGB) {
    getbankAccounts();
    // }
    getDematAccounts();
  }, [location, bondType, paymentMode]);

  useEffect(() => {
    if (dematId != 0) {
      let params: any = {};
      let demat: any = {};
      // if (dematType == 1) {
      //     demat = newDematDetails.filter(account => account.demat_id == dematId);
      //     params.id = demat[0].demat_id;
      //     params.name = demat[0].fname;
      //     params.number = demat[0].dp_account_number;
      //     params.depository = demat[0].depository;
      //     params.primary = demat[0].demat_account_primary_account;
      //     params.demat_type = demat[0].demat_account_type;
      //     params.demat_id = demat[0].demat_id
      // } else {
      //     demat = exisitingDematDetails.filter(account => account.demat_id == dematId);
      //     params.id = demat[0].id;
      //     params.name = demat[0].dp_name;
      //     params.number = demat[0].dp_id;
      //     params.depository = demat[0].depository;
      //     params.primary = demat[0].exist_demat_account_primary_account;
      //     params.demat_type = demat[0].demat_account_type;
      //     params.demat_id = demat[0].demat_id
      // }
      demat = newDematDetails.filter((account) => account.demat_id == dematId);
      params.id = demat[0].demat_id;
      params.name = demat[0].fname;
      params.number = demat[0].dp_account_number;
      params.depository = demat[0].depository;
      params.primary = demat[0].demat_account_primary_account;
      params.demat_type = demat[0].demat_account_type;
      params.demat_id = demat[0].demat_id;
      setSelectedDemat(demat);
    }
  }, [dematId, dematType]);

  useEffect(() => {
    if (bankId != 0) {
      let params: any = {};
      let bank: any = {};
      bank = bankDetails.filter((account) => account.bank_id == bankId);
      params.bank_id = bank[0]?.bank_id;
      params.number = bank[0]?.account_number;
      params.name = bank[0]?.bank_name;
      params.primary = bank[0]?.is_primary;
      params.is_asba = bank[0]?.is_asba;
      setSelectedBank(params);
    }
  }, [bankId]);

  const getDematAccounts = () => {
    setShowPreloader(true);
    ServerAPI.GetAllDematAccounts()
      .then((response: any) => {
        let demat: any = {};
        // if (response['exist_demat'] != undefined && response['exist_demat'].length > 0) {
        //     let params: any = {};
        //     params.id = response['exist_demat'][0].exist_demat_id;
        //     params.name = response['exist_demat'][0].dp_name;
        //     params.number = response['exist_demat'][0].dp_id;
        //     params.depository = response['exist_demat'][0].depository;
        //     params.primary = 1;
        //     params.demat_type = response['exist_demat'][0].demat_type;
        //     setSelectedDemat(params);
        // } else if (response['new_demat'] != undefined && response['new_demat'].length > 0) {
        //     let params: any = {};
        //     params.id = response['new_demat'][0].new_demat_id;
        //     params.name = response['new_demat'][0].fname;
        //     params.number = response['new_demat'][0].dp_account_number;
        //     params.depository = response['new_demat'][0].depository;
        //     params.primary = 1;
        //     params.demat_type = response['new_demat'][0].demat_type;
        //     setSelectedDemat(params);
        // }
        setSelectedDemat(response.message);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };
  useEffect(() => {
    if (List == '1') {
      setExchange(exchange.BSC);
    } else if (List == '2') {
      setExchange(exchange.NSC);
    }
}, [List]);

  useEffect(() => {
    if (Modify == 1) {
      setPaymode(dataIpo?.payment_mode_id);
      if (dataIpo?.payment_mode_id == 1) {
        setPaymentMode("ASBA")
      } else if (dataIpo?.payment_mode_id == 2) {
        setPaymentMode("UPI")
      }
      setUPIID(dataIpo?.ipo_order_payment_upi)
    }
  }, [Modify]);

  useEffect(() => {
    if (paymentMode == "UPI") {
      setPaymode(2);
    } else if (paymentMode == "ASBA")
      setPaymode(1);
  }, [paymentMode]);

  useEffect(() => {
    PaymentModes();
  }, []);

  useEffect(() => {
    console.log(sgbData);
  }, [sgbData])


  const SGBOrderPayment = (razorPayorderId: string, orderId: number, sgb_order_payment_id: number) => {
    const options: RazorpayOptions = {
      key: RazorPayKey,
      amount: sgbData.sgb_order_amount * 100 + "",
      currency: "INR",
      name: "Trustmony",
      description: "SGB Order",
      image: "../assets/img/logo/header_logo_dark.svg",
      order_id: razorPayorderId,
      handler: function (response: any) {
        PaymentVerify(1, sgb_order_payment_id, response["razorpay_order_id"], response["razorpay_signature"], response["razorpay_payment_id"]);
        navigator(orderId);
      },
      prefill: {
        name: userDetail.firstname,
        email: userDetail.email_id,
        contact: userDetail.mobile_number,
        // "method": paymentMode == 'UPI' ? 'upi' : 'netbanking'
      },
      theme: {
        color: "#ff405a",
      },
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      PaymentVerify(2, sgb_order_payment_id, response.error.metadata.order_id);
    });
    rzp1.open();
  };

  const navigator = (orderId: any) => {
    navigate({
      pathname: PageLinks.PAYMENTBOND_SUCCESS,
      search: "?id=" + orderId + "&&bond=" + bondType,
    });
  };

  const PaymentVerify = (paymentStatus: number, order_payment_id: number, razorpay_order_id: number, signature?: string, payment_id?: number) => {
    setShowPreloader(true);
    ServerAPI.SGBPaymentVerify(paymentStatus, order_payment_id, razorpay_order_id, signature, payment_id)
      .then((response: any) => {
        if (response != undefined) {
          if (paymentStatus == 1) {
            toast.success(response["message"]);
          } else {
            toast.error(response["message"]);
          }
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const SGBorder = () => {
    if (Object.keys(selectedDemat).length === 0) {
      toast.error("Please Choose Your Demat Account To Proceed!");
    } else if (Object.keys(selectedBank).length === 0 && paymentMode == "ASBA") {
      toast.error("Please Choose Your Bank Account To Proceed!");
    } else {
      setShowPreloader(true);
      ServerAPI.OrderSGBBond(selectedDemat[0].demat_id, selectedDemat[0].demat_type, sgbData.sgb_order_quantity, sgbData.sgb_order_amount, sgbData.bond_id, sgbData.price_per_gram)
        .then((response: any) => {
          if (response != undefined) {
            SGBOrderPayment(response["razorpay_order_id"], response["order_id"], response["sgb_order_payment_id"]);
          }
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  };
  //   const IPOOrder1 = () => {
  //     if (Object.keys(selectedDemat).length === 0) {
  //       toast.error("Please Choose Your Demat Account To Proceed!");
  //     } else if (Object.keys(selectedBank).length === 0 && paymentMode == "ASBA") {
  //       toast.error("Please Choose Your Bank Account To Proceed!");
  //     } else if (cityID == 0 && asbaDetails.length > 0 && paymentMode == "ASBA") {
  //       toast.error("Please Choose City For ASBA  To Proceed!");
  //     } else if (cityID == 0 && asbaDetails.length == 0 && paymentMode == "ASBA") {
  //       toast.error("ASBA Cannot Be Processed Try Another Payment Mode");
  //     } else {
  //       setShowPreloader(true);

  //       ServerAPI.OrderIPOBond1(bondID, selectedDemat[0].demat_id, bankId, qty)
  //         .then((response: any) => {
  //           if (response != undefined) {
  //             toast.success(response["message"]["message"]);
  //             let params: any = {};
  //             params.bond_isin_number = response["message"]["bond_isin_number"];
  //             params.bond_logo = response["message"]["bond_logo"];
  //             params.bond_name = response["message"]["bond_name"];
  //             params.bond_type = bondPurchaseTypes.BONDS;
  //             params.rfq_bank_id = response["message"]["rfq_bank_id"];
  //             params.rfq_deal_datetime = response["message"]["rfq_deal_datetime"];
  //             params.rfq_demat_id = response["message"]["rfq_demat_id"];
  //             params.rfq_id = response["message"]["rfq_id"];
  //             params.rfq_order_number = response["message"]["rfq_order_number"];
  //             params.rfq_price = response["message"]["rfq_price"];
  //             params.rfq_qoute_status = response["message"]["rfq_qoute_status"];
  //             params.rfq_qoute_status_name = response["message"]["rfq_qoute_status_name"];
  //             params.rfq_quantity = response["message"]["rfq_quantity"];
  //             params.rfq_value = response["message"]["rfq_value"];
  //             params.rfq_yield = response["message"]["rfq_yield"];
  //             // items.push(params);
  //             navigate(PageLinks.BOND_CONFIRMED_ORDER, { state: { data: params } });
  //           }
  //         })
  //         .finally(() => {
  //           setShowPreloader(false);
  //         });
  //     }
  //   };
  useEffect(() => {
    if (Modify == 1) {
      setAction(1);
    } else {
      setAction(2);
    }

  }, [Modify])


  const IPOOrder1 = () => {
    if (Modify == 1) {
      if (OID != '') {
        setShowPreloader(true);
        ServerAPI.Cancle(OID, Quty, Action).then((response: any) => {
          if (response != undefined) {
            toast.success(response['message']);
            let params: any = {};
            params.order_id = response['order_id'];
            params.bond_type = bondPurchaseTypes.BONDS;
            // items.push(params);
            navigate(PageLinks.PAYMENTBOND_SUCCESS, { state: { data: params } });
          }
        }).finally(() => {
          setShowPreloader(false);
        })
      }
    } else {
      if (Object.keys(selectedDemat).length === 0) {
        toast.error("Please Choose Your Demat Account To Proceed!");
      } else if (Object.keys(selectedBank).length === 0 && paymentMode == 'ASBA') {
        toast.error("Please Choose Your Bank Account To Proceed!");
      } else if (cityID == 0 && asbaDetails.length > 0 && paymentMode == 'ASBA') {
        toast.error("Please Choose City For ASBA  To Proceed!");
      } else if (cityID == 0 && asbaDetails.length == 0 && paymentMode == 'ASBA') {
        toast.error("ASBA Cannot Be Processed Try Another Payment Mode");
      } else {
        setShowPreloader(true);

        ServerAPI.OrderIPOBond1(bondID, selectedDemat[0].demat_id, bankId, qty, data?.data[0]?.Order_SELL_ID, Exchange).then((response: any) => {
          if (response != undefined) {
            toast.success(response['message']);
            // navigator(response['message']['rfq_order_number']);
            // window.location.hraf=PageLinks.BOND_CONFIRMED_ORDER+
            // navigate({
            //     pathname: PageLinks.PAYMENTBOND_SUCCESS,
            //     search: "?bondID=" + bondID + "&&selectedDemat=" + dematId + "&bankId=" + bankId + "&qty=" + qty,
            // });
            // let items: any[] = [];
            let params: any = {};
            params.order_id = response['order_id'];
            params.bond_type = bondPurchaseTypes.BONDS;
            // items.push(params);
            navigate(PageLinks.PAYMENTBOND_SUCCESS, { state: { data: params } });
          }
        }).finally(() => {
          setShowPreloader(false);
        })

      }
    }
  }

  const IPOOrder = () => {
    if (Modify == 1) {
      if (OID != '') {
        setShowPreloader(true);
        ServerAPI.CancleIPO(
          dataIpo?.ipo_order_demant_account_id,
          dataIpo?.ipo_order_demant_account_type,
          dataIpo?.ipo_order_quantity, Action,
          dataIpo?.ipo_order_amount,
          dataIpo.bond_id, dataIpo.Order_ID,
          paymentMode == "UPI" ? upiID + UPIvalue : "",
          dataIpo.ipo_order_payment_ref_city_id,
          dataIpo.ipo_order_investor_category_id,
          dataIpo.ipo_order_investor_type_id,
          dataIpo.ipo_order_payment_ref_bank_id,
          paymode,
          dataIpo.ipo_order_second_appl_name,
          dataIpo.ipo_order_second_pan,
          dataIpo.ipo_order_third_appl_name,
          dataIpo.ipo_order_third_appl_name, ncdSeriesID).then((response: any) => {
            if (response != undefined) {
              toast.success(response['message']);
              navigate({
                pathname: PageLinks.BOND_CONFIRMED_ORDER,
                search: "?id=" + response['order_id'] + "&&bond=" + bondType,
              });
            }
          }).finally(() => {
            setShowPreloader(false);
          })
      }
    } else {
      if (Object.keys(selectedDemat).length === 0) {
        toast.error("Please Choose Your Demat Account To Proceed!");
      } else if (Object.keys(selectedBank).length === 0 && paymentMode == 'ASBA') {
        toast.error("Please Choose Your Bank Account To Proceed!");
      } else if (cityID == 0 && asbaDetails.length > 0 && paymentMode == 'ASBA') {
        toast.error("Please Choose City For ASBA  To Proceed!");
      } else if (cityID == 0 && asbaDetails.length == 0 && paymentMode == 'ASBA') {
        toast.error("ASBA Cannot Be Processed Try Another Payment Mode");
      } else {
        if (ipoData != undefined) {
          setShowPreloader(true);
          ServerAPI.OrderIPOBond(selectedDemat[0].demat_id, ipoData.ipo_order_quantity, ipoData.ipo_order_amount, ipoData.bond_id, selectedDemat[0].demat_type, paymentMode == "UPI" ? upiID + UPIvalue : "", cityID, bankId, paymode, ncdSeriesID, categoryid, subCategoryCode, secondPanName, secondPanNo, thirdPanName, thirdPanNo, ipoData.ipo_order_investor_type_id).then((response: any) => {
            if (response != undefined) {
              toast.success(response['message']["bid_details"][0]['message']);

              navigate({
                pathname: PageLinks.BOND_CONFIRMED_ORDER,
                search: "?id=" + response['message']['order_id'] + "&&bond=" + bondType,
              });

            }
          }).finally(() => {
            setShowPreloader(false);
          })
        }
      }
    }
  }

  useEffect(() => {
    setShowPreloader(true);
    ServerAPI.userDetailData().then((res: APIData.userDetail) => {
      setUserDetails(res);
    }).finally(() => {
      setShowPreloader(false);
    })
  }, []);
  useEffect(() => {
    setShowPreloader(true);
    ServerAPI.OrderBankList(bondID).then((response: any) => {
      setBankDetails(response);
    }).finally(() => {
      setShowPreloader(false);
    })
  }, []);
  const maskingFunction = (acno: string) => {
    let subNum = acno.replace(/\d(?=\d{3})/g, "x");
    return (subNum);
  };



  return (
    <div className="main-index-wrapper">
      <Header />
      <div className="body-overlay"></div>
      <main>
        <section className="sovereign_gold_bonds payment_bond pt-100 pt-md-100 pb-md-40 pb-sm-40 pt-xs-55 pb-xs-40 mb-5">
          <div className="container-fluid mt-4">
            <div className="row py-4 mx-4 px-4">
              <div className="col-lg-8 col-md-8 col-sm-12 p-0 d-lg-block d-none">
                <div className=" pt-3 ">
                  <p className="text-quick fs-28 font-weight-600 mb-2">
                    Payment
                  </p>
                  <span className="text-light-grey quicksand-midium fs-20 p-0 pt-3">Choose your payment method</span>
                </div>
                <br />
                <div className="payment-img-style text-center mrd-none">
                  <img src="/assets/img/Payment_img.png" alt="Payment_img" className="payment-img" />
                </div>

              </div>

              <div className="col-lg-4 col-md-12 col-sm-12  pl-0 p-0">
                <div className="card-bg w-100 padding-20 position-reltive">
                  <p className="quicksand-bold fs-20">Select Demat Account</p> <br />

                  <div className="demat_card_full mb-5 w-100">
                    <div className="demat_card_left">
                      <div className="demat_card_icon">
                        <i className="fal fa-exchange"></i>
                      </div>

                      <div className="demat_card_text">
                        {Object.keys(selectedDemat).length === 0 ?
                          <div className="demat_card_text_head_one mt-4" style={{ fontSize: "16px" }}>Choose Your Demat Account</div>
                          :
                          <Fragment>
                            <div className="d-flex flex-column mt-2 pl-sm-20">
                              <div className="bank_card_text_head d-flex">
                                <p className="bank_p fs-10">TRUST MONEY</p>
                                <p className="demat_saving_btn">NSDL</p>
                              </div>
                              <div className="demat_card_text_head_one mt-0 payment_name">{`${selectedDemat[0]?.firstname}${selectedDemat[0]?.lastname}`}</div>
                              <div className="demat_card_text_num">{`${selectedDemat[0]?.dp_id}-${selectedDemat[0]?.client_id}`}</div>
                            </div>
                          </Fragment>
                        }
                      </div>

                    </div>
                    <div className="demat_card_right h-100">
                      <button onClick={(e: any) => {
                        e.preventDefault();
                        if (data.data[0]?.modify == 1) {
                          setDematSelect(false);
                        } else {
                          setDematSelect(true);
                        }

                      }} className="demat_card_right_icon mt-4"><i className="fas font-weight-500 font-15">&#xf107;</i></button>
                    </div>
                    <div onClick={(e: any) => {
                      e.preventDefault();
                    }} className={`${selectedDemat[0]?.demat_is_primary !== 1}` ? "demat_primarybtn" : "bank-disabled-primary bank_primarybtn cursor"}>{`${selectedDemat[0]?.demat_is_primary !== 1}` ? "PRIMARY" : "Set as Primary"}</div>
                  </div>
                  {bondType == bondTypes.EXISTINGBOND || bondType == bondTypes.ADDEDBOND ?
                    <Fragment>
                      <p className="quicksand-bold">Select Bank Account</p><br />
                      <div className="choice-list" onClick={(e: any) => {
                        e.preventDefault();
                        if (data.data[0]?.modify == 1) {
                          setBankSelected(false);
                        } else {
                          setBankSelected(true);
                        }

                      }}>
                        <span className="input-title-filled gender_col" role={"group"}>
                          <div className="bank_card_full mb-3 w-100">
                            <div className="demat_card_left">
                              <div className="bank_card_icon mt-2">
                                <i className="fal fa-piggy-bank"></i>
                              </div>
                              <div className="demat_card_text mt-2">
                                {Object.keys(selectedBank).length === 0 ?
                                  <div className="demat_card_text_head_one" style={{ fontSize: "16px" }}>Choose Your Bank Account</div>
                                  :
                                  <Fragment>
                                    <div className="bank_card_text_head d-flex justify-content-around">
                                      <p className="bank_p">
                                        {selectedBank.name}</p>
                                      <p className="bank_saving_btn">{selectedBank.account_type == AccountTypeValues1.savings ? "Savings" : "Current"} A/C</p>
                                    </div>

                                    <div className="bank_card_text_num">{maskingFunction(selectedBank.number + "")}</div>
                                  </Fragment>
                                }
                                {/* <Fragment>
                                                                    <div className="demat_card_text_head_one mt-1">HDFC BANK</div> <p className="demat_saving_btn">Savings A/C</p>
                                                                    <div className="bank_card_text_num">XXXXXXX645</div>
                                                                </Fragment> */}
                              </div>

                            </div>
                            <div className="bank_card_right  h-100">
                              <button className="demat_card_right_icon mt-4"><i className="fas font-weight-500 font-15 p-0">&#xf107;</i></button>
                            </div>
                            <div onClick={(e: any) => {
                              e.preventDefault();
                            }} className={selectedBank?.primary == 1 ? "demat_primarybtn" : "bank-disabled-primary bank_primarybtn cursor"}>{selectedBank?.primary == 1 ? "PRIMARY" : "Set as Primary"}</div>
                          </div>
                        </span>
                      </div>
                    </Fragment> : bondType == bondTypes.SGB ? "" :
                      <Fragment>
                        <p className="quicksand-bold">Select Payment Mode</p>
                        <div className="choice-list">
                          <span className="input-title-filled gender_col" role={"group"}>
                            {paymentModes != undefined && paymentModes.length > 0 && paymentModes.map((mode: APIData.PaymentModes) => {
                              return (
                                <div className={paymentMode == mode.payment_mode_name || dataIpo?.payment_mode_id == mode.payment_mode_id ? "gender_btn selected" : "gender_btn"}>
                                  <input type="radio" value={mode.payment_mode_id} onClick={(e: any) => {
                                    if (data.data[0]?.modify == 1) {

                                    } else {
                                      setPaymentMode(mode.payment_mode_name);
                                    }

                                  }} className="d-none" name={mode.payment_mode_name} id={mode.payment_mode_id + ""} />
                                  <label
                                    htmlFor={mode.payment_mode_id + ""}
                                    className={paymentMode == mode.payment_mode_name || dataIpo?.payment_mode_id == mode.payment_mode_id ? "gender-btn-label-selected" : "gender_btn-label"}
                                  >
                                    {mode.payment_mode_name}
                                  </label>
                                </div>
                              )
                            })}
                          </span>
                        </div>
                      </Fragment>

                  }
                  {bondType == bondPurchaseTypes.BONDS &&
                  <Fragment>
                  <p className="quicksand-bold mt-2 mb-2">Select Exchange</p>
                    <span className="input-title-filled" role={"group"}>
                      {List == '1' ?
                      <div className={Exchange == exchange.BSC || List == '1' ? "gender_btn selected" : "gender_btn"}>
                        <input onChange={(e: any) => {
                          if (e.target.checked) {
                            setExchange(exchange.BSC);
                          }
                        }} type="radio" value={1} checked={Exchange == exchange.BSC ? true : false} className="d-none" name="gender" id="gender_option_1" />
                        <label
                          htmlFor="gender_option_1"
                          className={Exchange == exchange.BSC || List == '1' ? "gender-btn-label-selected" : "gender_btn-label"}
                        >
                          BSE
                        </label>
                      </div>: List == '2'  ?
                     
                      <div className={Exchange == exchange.NSC || List == '2' ? "gender_btn selected" : "gender_btn"}>
                        <input onChange={(e: any) => {
                          if (e.target.checked) {
                            setExchange(exchange.NSC);
                          }
                        }} type="radio" value={2} checked={Exchange == exchange.NSC ? true : false} className="d-none" name="gender" id="gender_option_2" />
                        <label
                          htmlFor="gender_option_2"
                          className={Exchange == exchange.NSC || List == '2' ? "gender-btn-label-selected" : "gender_btn-label"}
                        >
                          NSE
                        </label>
                      </div> :
                      <>
                      <div className={Exchange == exchange.BSC || List == '1' ? "gender_btn selected" : "gender_btn"}>
                      <input onChange={(e: any) => {
                        if (e.target.checked) {
                          setExchange(exchange.BSC);
                        }
                      }} type="radio" value={1} checked={Exchange == exchange.BSC ? true : false} className="d-none" name="gender" id="gender_option_1" />
                      <label
                        htmlFor="gender_option_1"
                        className={Exchange == exchange.BSC || List == '1' ? "gender-btn-label-selected" : "gender_btn-label"}
                      >
                        BSE
                      </label>
                    </div>
                      <div className={Exchange == exchange.NSC || List == '2' ? "gender_btn selected" : "gender_btn"}>
                        <input onChange={(e: any) => {
                          if (e.target.checked) {
                            setExchange(exchange.NSC);
                          }
                        }} type="radio" value={2} checked={Exchange == exchange.NSC ? true : false} className="d-none" name="gender" id="gender_option_2" />
                        <label
                          htmlFor="gender_option_2"
                          className={Exchange == exchange.NSC || List == '2' ? "gender-btn-label-selected" : "gender_btn-label"}
                        >
                          NSE
                        </label>
                      </div> </>}
                    </span>
                  </Fragment>}
                  {data.data[0]?.modify == 1 && paymentMode == 'ASBA' ? <>
                    {
                      bankDetails != undefined &&
                      bankDetails.length > 0 &&
                      bankDetails.map((detail: any) => (
                        <Fragment>
                          {/* <input type="radio" style={{ position: "initial" }}
                                                        // disabled={detail.is_asba!=1?true:false}
                                                        name="demat_account" onChange={(e: any) => {
                                                            setBankId(detail?.bank_id);
                                                        }} /> */}
                          {detail?.bank_id == dataIpo?.ipo_order_payment_ref_bank_id &&
                            <div
                              className="choice-list"
                              onClick={(e: any) => {
                                e.preventDefault();
                                setBankId(detail?.bank_id);
                                setBankSelected(false);
                              }}
                            >
                              <span className="input-title-filled gender_col" role={"group"}>
                                <div className="bank_card_full mb-2 w-100">
                                  <div className="demat_card_left">
                                    <div className="bank_card_icon mt-2">
                                      <i className="fal fa-piggy-bank"></i>
                                    </div>
                                    <div className="demat_card_text">
                                      <Fragment>
                                        <div className="demat_card_text_head_one mt-1">{detail.bank_name}</div> <p className="demat_saving_btn">Savings A/C</p>
                                        <div className="bank_card_text_num">{maskingFunction(detail.account_number + "")}</div>
                                      </Fragment>
                                    </div>
                                  </div>
                                  <div onClick={(e: any) => {
                                    e.preventDefault();
                                  }} className={selectedBank?.primary == 1 ? "demat_primarybtn" : "bank-disabled-primary bank_primarybtn cursor"}>{selectedBank?.primary == 1 ? "PRIMARY" : "Set as Primary"}</div>
                                </div>
                              </span>
                            </div>}
                        </Fragment>
                      ))}</> : ""}
                  {paymentMode == 'ASBA' && data.data[0]?.modify !== 1 &&

                    <div className={selectedBank.is_asba == 1 ? "bank_card_full mb-3 w-100 mt-3" : "bank_card_full-disabled mb-3 w-100 mt-3"}>
                      <div className="demat_card_left" onClick={(e: any) => {
                        e.preventDefault();
                        if (data.data[0]?.modify == 1) {
                          setBankSelected(false);
                        } else {
                          setBankSelected(true);
                        }

                      }}>

                        <div className="demat_card_icon">
                          <i className="fal fa-exchange"></i>
                        </div>

                        <div className="demat_card_text">
                          {Object.keys(selectedBank).length === 0 ?
                            <div className="demat_card_text_head_one mt-4" style={{ fontSize: "16px" }}>Choose Your Bank Account</div>
                            :
                            <div className="mt-2 pl-sm-20">
                              <div className="bank_card_text_head">
                                <p className="bank_p m_sm_none fs-15 p-0 text-source font-weight-500">{selectedBank.name}</p>
                              </div>
                              <p className="bank_saving_btn">{selectedBank.account_type == AccountTypeValues1.savings ? "Savings" : "Current"} A/C</p>
                              <div className="bank_card_text_num">{maskingFunction(selectedBank.number + "")}</div>
                            </div>
                          }
                        </div>

                      </div>
                      <div className={selectedBank.is_asba != 1 ? "bank_card_right-disabled h-100" : "bank_card_right h-100"}>
                        <button className="demat_card_right_icon mt-4"><i className="fas font-weight-500 font-15 p-0">&#xf107;</i></button>
                      </div>
                      <div onClick={(e: any) => {
                        e.preventDefault();
                      }} className={selectedBank?.primary == 1 ? "demat_primarybtn" : "bank-disabled-primary bank_primarybtn cursor"}>{selectedBank?.primary == 1 ? "PRIMARY" : "Set as Primary"}</div>
                    </div>
                  }

                  {paymentMode == 'NETBANKING' &&
                    <div className="col-lg-12 row">
                      <p className="fs-12 text-upi p-0 mt-2">Choose Bank Account</p>
                      <select placeholder="Chosse City" className="input-valu2 upi-select  px-2 py-3  w-100">
                        {bankDetails != undefined && bankDetails.length > 0 && bankDetails.map((Net: any) => {
                          return (
                            <option value={Net.bank_name + ""}>{Net.bank_name}</option>
                          )
                        })
                        }
                      </select>
                    </div>}
                  {paymentMode == 'UPI' && bondType == bondTypes.IPO &&
                    <Fragment>

                      <p className="fs-16 p-0 text-quick font-weight-600 mt-2 mb-2">Pay by UPI</p>
                      <p className="fs-12 text-upi p-0">Enter Your UPI ID</p>
                      <div className="d-flex mb-5">
                        <input placeholder="UPI ID" onChange={(e: any) => {
                          setUPIID(e.target.value);
                        }} defaultValue={data.data[0]?.modify == 1 ? dataIpo?.ipo_order_payment_upi.split('@')[0] : ""} disabled={data.data[0]?.modify == 1} className="input-valu2 text-start px-2 py-2 ipo-purchase-pan-input w-100" />
                        <select className="ml-3 upi-select" onChange={(e) => setUPIvalue(e.target.value)} defaultValue={data.data[0]?.modify == 1 ? `@${dataIpo?.ipo_order_payment_upi.split('@')[1]}` : ""} disabled={data.data[0]?.modify == 1}>
                          <option value="@okicici">@okicici</option>
                          <option value="@upi">@upi</option>
                          <option value="@ybl">@ybl</option>
                          <option value="@paytm">@paytm</option>
                        </select>

                      </div>

                    </Fragment>

                  }
                  {paymentMode == 'UPI' && bondType == bondTypes.SGB &&
                    <Fragment>
                      <p className="fs-12 text-upi p-0">Enter Your UPI ID</p>
                      <div className="d-flex">
                        <input placeholder="UPI ID" onChange={(e: any) => {
                          setUPIID(e.target.value);
                        }} className="input-valu2 text-start px-2 py-2 ipo-purchase-pan-input w-100" />
                        <select className="mx-2 upi-select">
                          <option value="@okicici">@okicici</option>
                          <option value="@upi">@upi</option>
                          <option value="@ybl">@ybl</option>
                          <option value="@paytm">@paytm</option>
                        </select>

                      </div>
                    </Fragment>

                  }


                  {paymentMode == 'ASBA' && asbaDetails != undefined && asbaDetails.length > 0 &&

                    <div className="mb-5">
                      <p className="fs-12 text-upi p-0">Select Form Submission City</p>
                      <select placeholder="Chosse City" disabled={data.data[0]?.modify == 1} onChange={(e: any) => {
                        e.preventDefault();
                        setCityId(e.target.value);
                      }} className={data.data[0]?.modify == 1 ? "disabled_dropdwon input-valu2 upi-select select_asba px-2 py-3  w-100" : "input-valu2 upi-select select_asba px-2 py-3  w-100"}>
                        {asbaDetails != undefined && asbaDetails.length > 0 && asbaDetails.map((asba: APIData.ASBADetails) => {
                          return (
                            <>
                              {asba?.asba_bank_location_status == 1 &&

                                <option value={asba.asba_bank_location_id + ""} selected>{asba.asba_bank_location_fullname}</option>}
                            </>
                          )
                        })
                        }
                      </select>
                    </div>}



                  <div className="text-center pb-3 mt-auto">
                    <button onClick={(e: any) => {
                      e.preventDefault();

                      if (userDetail.ekyc_application_status == '1') {
                        if ((bondType) == bondTypes.SGB) {
                          SGBorder();
                        } else if ((bondType) == bondTypes.IPO) {
                          if (paymentMode == 'UPI' && upiID == '') {
                            toast.error("Please Enter Your UPI Id to Proceed");
                          } else {
                            IPOOrder();
                          }
                        }
                        else if ((bondType) == bondTypes.ADDEDBOND) {
                          IPOOrder1();
                        }
                      } else {
                        toast.error("Please Complete Your KYC Before Proceeding To Buy This Bond")
                      }

                    }} className="apply-gold-btn  text-white">
                      {bondType === bondTypes.SGB ?
                        <>Confirm Order and Pay</> : <>Confirm Order</>}
                    </button>
                  </div>
                </div>

                {bondType != bondTypes.SGB ? (
                  <Fragment>
                    <></>
                    {/* {paymentMode == "ASBA" && (
                        <div className={selectedBank.is_asba == 1 ? "bank_card_full mb-3 w-100" : "bank_card_full-disabled mb-3 w-100"}>
                          <div className="demat_card_left">
                            <div className="demat_card_icon">
                              <i className="fal fa-exchange"></i>
                            </div>

                            <div className="demat_card_text">
                              {Object.keys(selectedBank).length === 0 ? (
                                <div className="demat_card_text_head_one mt-4" style={{ fontSize: "16px" }}>
                                  Choose Your Bank Account
                                </div>
                              ) : (
                                <Fragment>
                                  <div className="bank_card_text_head">{selectedBank.name}</div>
                                  <p className="bank_saving_btn">{selectedBank.account_type == AccountTypeValues1.savings ? "Savings" : "Current"} A/C</p>
                                  <div className="bank_card_text_num">{selectedBank.number}</div>
                                </Fragment>
                              )}
                            </div>
                          </div>
                          
                          <div className={selectedBank.is_asba != 1 ? "bank_card_right-disabled h-100" : "bank_card_right h-100"}>
                            <button
                              onClick={(e: any) => {
                                e.preventDefault();
                                setBankSelect(true);
                              }}
                              className="demat_card_right_icon mt-4"
                            >
                              <i className="fas">&#xf107;</i>
                            </button>
                          </div>
                          {selectedDemat[0]?.demat_is_primary != undefined && selectedDemat[0]?.demat_is_primary == 1 && <div className="demat_primarybtn">PRIMARY</div>}
                            {bankSelected == true &&
                                (
                                    <div className="col-lg-4 col-md-6 col-sm-12  py-3 bank-select-box">
                                        <div className="bank-select w-100 padding-20" >
                                            {bankDetails != undefined && bankDetails.length > 0 && bankDetails.map((detail: any) => (
                                                <Fragment>
                                                   
                                                    <div className="choice-list" onClick={(e: any) => {
                                                    e.preventDefault();
                                                    setBankId(detail?.bank_id);
                                                    setBankSelected(false);
                                                }}>
                                                        <span className="input-title-filled gender_col" role={"group"}>
                                                            <div className="bank_card_full mb-2 w-100">
                                                                <div className="demat_card_left">
                                                                    <div className="bank_card_icon mt-2">
                                                                        <i className="fal fa-piggy-bank"></i>
                                                                    </div>
                                                                    <div className="demat_card_text mt-2">
                                                                        <Fragment>
                                                                            <div className="demat_card_text_head_one mt-1">{detail.bank_name}</div> <p className="demat_saving_btn">Savings A/C</p>
                                                                            <div className="bank_card_text_num">{maskingFunction(detail.account_number + "")}</div>
                                                                        </Fragment>
                                                                    </div>

                                                                </div>
                                                                {selectedDemat[0]?.demat_is_primary != undefined && selectedDemat[0]?.demat_is_primary == 1 &&
                                                                    <div className="demat_primarybtn">PRIMARY</div>
                                                                }
                                                            </div>
                                                        </span>
                                                    </div>

                                                </Fragment>
                                            ))}
                                        </div>

                                    </div>
                                )
                            }
                        </div>
                      )} */}

                    {paymentMode == "NETBANKING" && (
                      <div className="col-lg-12 row">
                        <p className="fs-12 text-upi p-0 mt-2">Choose Bank Account</p>
                        <select placeholder="Chosse City" className="input-valu2 upi-select  px-2 py-3  w-100">
                          {bankDetails != undefined &&
                            bankDetails.length > 0 &&
                            bankDetails.map((Net: any) => {
                              return <option value={Net.bank_name + ""}>{Net.bank_name}</option>;
                            })}
                        </select>
                      </div>
                    )}
                    {/* {paymentMode == "UPI" && bondType == bondTypes.IPO && (
                        <Fragment>
                          <p className="quicksand-bold mt-2 mb-2">Pay by UPI</p>

                          <p className="fs-12 text-upi p-0">Enter Your UPI ID</p>
                          <div className="d-flex">
                            <input
                              placeholder="UPI ID"
                              onChange={(e: any) => {
                                setUPIID(e.target.value);
                              }}
                              className="input-valu2 text-start px-2 py-2 ipo-purchase-pan-input w-100"
                            />
                            <select className="mx-2 upi-select">
                              <option value="@okicici">@okicici</option>
                              <option value="@upi">@upi</option>
                              <option value="@ybl">@ybl</option>
                              <option value="@paytm">@paytm</option>
                            </select>
                          </div>
                        </Fragment>
                      )} */}
                    {paymentMode == "UPI" && bondType == bondTypes.SGB && (
                      <Fragment>
                        <p className="fs-12 text-upi p-0">Enter Your UPI ID</p>
                        <div className="d-flex">
                          <input
                            placeholder="UPI ID"
                            onChange={(e: any) => {
                              setUPIID(e.target.value);
                            }}
                            className="input-valu2 text-start px-2 py-2 ipo-purchase-pan-input w-100"
                          />
                          <select className="mx-2 upi-select">
                            <option value="@okicici">@okicici</option>
                            <option value="@upi">@upi</option>
                            <option value="@ybl">@ybl</option>
                            <option value="@paytm">@paytm</option>
                          </select>
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                ) : (
                  ""
                )}
                {/* </div> */}

                {bankSelected == true && (
                  <div className="bank-select-box">
                    <div className="bank-select  w-100 padding-20">
                      {bankDetails != undefined &&
                        bankDetails.length > 0 &&
                        bankDetails.map((detail: any) => (
                          <Fragment>
                            {/* <input type="radio" style={{ position: "initial" }}
                                                        // disabled={detail.is_asba!=1?true:false}
                                                        name="demat_account" onChange={(e: any) => {
                                                            setBankId(detail?.bank_id);
                                                        }} /> */}
                            <div
                              className="choice-list"
                              onClick={(e: any) => {
                                e.preventDefault();
                                setBankId(detail?.bank_id);
                                setBankSelected(false);
                              }}
                            >
                              <span className="input-title-filled gender_col" role={"group"}>
                                <div className="bank_card_full mb-2 w-100">
                                  <div className="demat_card_left">
                                    <div className="bank_card_icon mt-2">
                                      <i className="fal fa-piggy-bank"></i>
                                    </div>
                                    <div className="demat_card_text">
                                      <Fragment>
                                        <div className="demat_card_text_head_one mt-1">{detail.bank_name}</div> <p className="demat_saving_btn">Savings A/C</p>
                                        <div className="bank_card_text_num">{maskingFunction(detail.account_number + "")}</div>
                                      </Fragment>
                                    </div>
                                  </div>
                                  <div onClick={(e: any) => {
                                    e.preventDefault();
                                  }} className={detail?.is_primary == 1 ? "demat_primarybtn" : "bank-disabled-primary bank_primarybtn cursor"}>{detail?.is_primary == 1 ? "PRIMARY" : "Set as Primary"}</div>
                                </div>
                              </span>
                            </div>
                          </Fragment>
                        ))}
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>
        </section>
      </main>
      <Footer />
      <DematAccounts
        showModal={dematSelect}
        closeModal={() => {
          setDematSelect(false);
        }}
        dematType={setDematType}
        dematDetailsId={setDematId}
      />
      <BankAccounts
        showModal={bankSelect}
        closeModal={() => {
          setBankSelect(false);
        }}
        bankDetailsId={setBankId}
        bondID={bondID}
      />
    </div>
  );
};

export default PayBondPayment;
