import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import ChequeUpload from "./ChequeUpload";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
    setChequeUrl?:any
}

const BankDetailsError: React.FC<FormParameters> = ({ showModal, closeModal,setChequeUrl }) => {
    const [openChequePopup, setOpenChequePopup] = useState(false);

    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog className="dialog-background"
                    open={showModal}
                    keepMounted
                    onClose={closeModal}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    PaperProps={{
                        style: {
                          background: 'none',
                          boxShadow: 'none',
                        },
                      }}
                      maxWidth="sm"
                >
                    <DialogContent style={{overflow:"hidden"}}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div className="do-box do-box-2 mb-30 email_verify_bg text-center">
                                <img src="assets/img/icon/account_error.png" className="user_icon" />
                                <h6 className="mb--10  identity_verify_success" data-wow-delay="0.1s">Your Bank A/C number does not match with PAN name. Please upload the cancelled signed cheque of your bank A/C</h6>
                                <div className="mt-2">
                                <button onClick={(e: any) => {
                                    e.preventDefault();
                                    setOpenChequePopup(true);
                                    closeModal();
                                }} type="submit" className="theme_btn email_verify_btn primary_btn_1 sub-btn " data-wow-delay="0.5s">Proceed to upload Signed Cheque</button>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

            </main>
            <ChequeUpload showModal={openChequePopup} closeModal={()=>{setOpenChequePopup(false)}} setShowModel={()=>{setOpenChequePopup(true)}} setChequeUrl={setChequeUrl} />
        </div>
    )
}

export default BankDetailsError;