import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import ChequeUpload from "./ChequeUpload";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageLinks } from "../../common/Constants";
import { DialogTitle } from "@mui/material";
import { Formik } from "formik";
import { APIData } from "../../common/DataTypes";
import { InitialData } from "../Form/InitialData";
import { useAppStateAPI } from "../../common/AppStateAPI";
import { ServerAPI } from "../../common/ServerAPI";
import { toast } from "react-hot-toast";

interface FormParameters {
    showModal: boolean,
    closeModal: any,
}

const AppologiesPopupModel: React.FC<FormParameters> = ({ showModal, closeModal }) => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <div className="main-index-wrapper">
            <main>
                <Dialog className="dialog-background"
                    open={showModal}
                    keepMounted
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            closeModal();
                        }
                    }}
                    aria-describedby="alert-dialog-slide-description"
                    scroll="body"
                    PaperProps={{
                        style: {
                            background: 'none',
                            boxShadow: 'none',
                        },
                    }}
                    maxWidth="sm"
                >
                    <div className="do-box do-box-2 mb-30 email_verify_bg">
                        <DialogContent style={{ overflow: "hidden" }}>
                            <DialogContentText id="alert-dialog-slide-description">
                                <div className="text-center">
                                    <img src="assets/img/icon/User_3.png" className="user_icon" />
                                    <h6 className="mb-10  identity_verify_success" data-wow-delay="0.1s">We apologise for the inconvenience. As per our policy, we are unable to proceed further, kindly call our customer service for further details.</h6>
                                    <div className="mt-3 col-md-12 col-xl-12 row col-12">
                                        <div className="col-md-6 col-xl-6 col-12">
                                            <button onClick={(e: any) => {
                                                e.preventDefault();
                                                navigate(PageLinks.INSTRUCTION_SCREEN);
                                            }} type="submit" className="theme_btn email_verify_btn primary_btn_1 sub-btn " data-wow-delay="0.5s">Exit</button>
                                        </div>
                                        <div className="col-md-6 col-xl-6 col-12">
                                            <button onClick={(e: any) => {
                                                e.preventDefault();
                                                closeModal();
                                            }} type="submit" className="theme_btn email_verify_btn primary_btn_1 sub-btn " data-wow-delay="0.5s">Change Option</button>
                                        </div>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </div>
                </Dialog>

            </main>
        </div>
    )
}

export default AppologiesPopupModel;