import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { PageLinks } from "../common/Constants";
import { ServerAPI } from "../common/ServerAPI";

const VerifyYourIdentity: React.FC = () => {
    const location = useLocation();
    const preData: any = location.state;
    const navigate = useNavigate();
    const { setShowPreloader } = useAppStateAPI();

    useEffect(() => {
        console.log(preData);

    })

    const add_profile_Details = () => {
        setShowPreloader(true);
        ServerAPI.add_profile_details(preData).then((res) => {
            navigate(PageLinks.VERIFY_YOUR_IDENTITY_SUCCESS, { state: preData })
            localStorage.setItem("update_datas", JSON.stringify(preData));
        }).catch((err) => {

        }).finally(() => {
            setShowPreloader(false);
        })
    }


    return (
        <div className="main-index-wrapper">
            <main>
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="subscribe-wrapper">
                                        <div className="section-title section-title-3 text-center">
                                           <img className="header-logo" src="assets/img/logo/header_logo_one.png" alt="logo" />
                                        </div>
                                    </div>
                                    <div className="progress-indicator-element">
                                        <ul className="progress-indicator">
                                            <li className="">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 01</span> <br></br><p> Personal Details </p></span>
                                                </span>
                                                <span className="bubble"></span>
                                            </li>
                                            <li className="msg_contain">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 02</span> <br></br><p> Bank Details</p></span>
                                                </span>
                                                <span className="bubble"></span>
                                                <div className="bse-order-no">
                                                </div>
                                                <div className="bse-order-msg msg-success">
                                                </div>
                                            </li>
                                            <li className="">
                                                <span className="stacked-text">
                                                    <span className="subdued"><span className="small">Step 03</span> <br></br><p> Demat Details</p></span>
                                                </span>
                                                <span className="bubble"></span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3 identity_profile">
                                                            <h6 className="mb--10 wow " data-wow-delay="0.1s">Personal Details <span className="step_count">STEPS - 2 of 2</span></h6>
                                                            <Link to={PageLinks.PROFILE_PERSONAL_DETAILS} className="theme_btn profile_close_btn sub-btn wow " data-wow-delay="0.5s"><i className="far fa-times-circle"></i> Close</Link>
                                                            <hr></hr>
                                                            <form className="quote-form mb-10" action="#">
                                                                <div className="col-xl-12 pl-0 float-left">
                                                                    <h6 className="pt-20 mb--10  personal_sub_head" data-wow-delay="0.1s">Verify Your Identity</h6>
                                                                    <p className="personal_sub_text">We will now verify your identity via photo verification as required by SEBI.</p>
                                                                </div>
                                                                <Link to={PageLinks.VERIFY_YOUR_IDENTITY_PHOTO_POPUP} state={preData}>
                                                                    <div className="identity_photo_whole">
                                                                        <div className="identity_photo_single_selected">
                                                                            {/* <img src="assets/img/icon/user_icon_fill.png" /> */}
                                                                            <video
                                                                                src={URL.createObjectURL(preData.mediaBlob)}
                                                                                // width={260}
                                                                                // height={480}
                                                                                controls
                                                                                className="identity_photo_img"
                                                                                autoPlay
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Link>

                                                                <p className="instrction_text_one">Tips for taking a good Video / Photograph / Mug shot</p>
                                                                <div className="instruc_img_whole">
                                                                    <div className="instruc_img_box">
                                                                        <div className="instruc_img_icon">
                                                                            <img src="assets/img/icon/Light.png" />
                                                                        </div>
                                                                        <div className="instruc_img_text">Good Light</div>
                                                                    </div>
                                                                    <div className="instruc_img_box">
                                                                        <div className="instruc_img_icon">
                                                                            <img src="assets/img/icon/Cam.png" />
                                                                        </div>
                                                                        <div className="instruc_img_text">Look Into Webcam</div>
                                                                    </div>
                                                                    <div className="instruc_img_box">
                                                                        <div className="instruc_img_icon">
                                                                            <img src="assets/img/icon/Sunglases.png" />
                                                                        </div>
                                                                        <div className="instruc_img_text instruc_img_colored_text">No Specs Or Sunglases</div>
                                                                    </div>
                                                                    <div className="instruc_img_box">
                                                                        <div className="instruc_img_icon">
                                                                            <img src="assets/img/icon/Hats.png" />
                                                                        </div>
                                                                        <div className="instruc_img_text instruc_img_colored_text">No Caps / Hats</div>
                                                                    </div>
                                                                </div>
                                                                <div className="instruc_note instruc_img_colored_text">Note: Keep your face within this circle only. if the face is not visible properly then your application might get rejected.</div>
                                                                <div className="col-xl-12 p-0 float-left text-center mt-20">
                                                                    <a className="theme_btn primary_btn_one sub-btn wow " data-wow-delay="0.5s" onClick={() => add_profile_Details()}>Continue</a>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default VerifyYourIdentity;
