import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PageLinks } from "../common/Constants";

const SetAlert: React.FC = () => {
    return (
        <div className="main-index-wrapper">
            <main>
                {/* Profile-details-edit-area start */}
                <section className="subscribe-letter-area pt-20 pb-20">
                    <div className="line-shape d-none d-lg-inline-block">
                        <img className="img-fluid" src="assets/img/Intersection_4.svg" alt="" />
                    </div>

                    <div className="container custom-container-subs">
                        <div className="sub-bg">
                            <div className="row justify-content-center">
                                <div className="col-xl-12">
                                    <div className="step_box_content">
                                        <div className="sub-bg">
                                            <div className="row justify-content-center">
                                                <div className="col-xl-12">
                                                    <div className="subscribe-wrapper">
                                                        <div className="section-title section-title-3">
                                                            <h6 className="wow page-main-heading" data-wow-delay="0.1s">Set alerts for the upcoming issues of Gold Bond</h6>
                                                            <Link to={PageLinks.PROFILE_PERSONAL_DETAILS_EDIT} state={{ from: "occupation" }} className="theme_btn profile_close_btn sub-btn  edit-personal-detail" data-wow-delay="0.5s"><i className="far fa-edit"></i> Edit</Link>
                                                            <hr></hr>
                                                        </div>
                                                    </div>
                                                    <form className="quote-form mb-10">
                                                        <div className="col-xl-12 pl-0 float-left">
                                                            <div className="col-xl-6 pl-0 float-left">
                                                                <div className="email-input input_field pl-0"><label className="input-title">First Name *</label><input type="text" placeholder="Enter First Name" name="firstname" className="" value="Senthilmurugan" /></div>
                                                            </div>
                                                            <div className="col-xl-6 pl-0 float-left">
                                                                <div className="email-input input_field pl-0"><label className="input-title">Last Name <span className="error-area">* &nbsp;</span></label><input type="text" className="" placeholder="Enter Last Name" name="lastname" value="V" /></div>
                                                            </div>
                                                            <div className="col-xl-6 pl-0 float-left">
                                                                <div className="email-input input_field pl-0">
                                                                    <label className="input-title">Mobile Number <span className="error-area">*</span></label><input type="text" className="email_input mobile_number pl-50" placeholder="Enter Mobile Number" name="mobile_number" value="8056684835" /><span className="verified"><i className="far fa-check-circle"></i></span>
                                                                    <div className="country_box_personal_details"><img src="assets/img/icon/flag.png" />&nbsp;&nbsp;&nbsp;</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 pl-0 float-left">
                                                                <div className="email-input input_field pl-0"><label className="input-title">Email ID *</label><input type="text" placeholder="Enter Email ID" name="email" className="" value="Seveenajairaj@googleone.com" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-12 p-0 float-left">
                                                            <div className="choice-list">
                                                                <span className="input-title">Choose Months To Issue Alerts</span>
                                                                <span className="input-title-filled gender_col" role="group">
                                                                    <div className="gender_btn"><input type="radio" className="d-none" name="gender" id="gender_option_3" value="3" /><label htmlFor="gender_option_3" className="gender_btn-label">May</label></div>
                                                                    <div className="gender_btn"><input type="radio" className="d-none" name="gender" id="gender_option_3" value="3" /><label htmlFor="gender_option_3" className="gender_btn-label">June</label></div>
                                                                    <div className="gender_btn"><input type="radio" className="d-none" name="gender" id="gender_option_3" value="3" /><label htmlFor="gender_option_3" className="gender_btn-label">July</label></div>
                                                                    <div className="gender_btn"><input type="radio" className="d-none" name="gender" id="gender_option_3" value="3" /><label htmlFor="gender_option_3" className="gender_btn-label">August</label></div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-8 p-0 float-left">
                                                            <p className="review-text text-left mt-5"><input type="checkbox" id="signup" name="check_box_share_data_with_company" /> Allow Trust Money to send me promotional mails and WhatsApp messages</p>
                                                            <p className="review-text text-left pb-0"><input type="checkbox" id="signup" name="check_box_share_data_with_govt" /> I agree to <Link to={PageLinks.TERMS_AND_CONDITIONS}>Terms & Conditions</Link> and <Link to={PageLinks.PRIVACY_POLICY}>Privacy Policy</Link></p>
                                                        </div>
                                                        <div className="col-xl-4 p-0 float-left"></div>
                                                        <div className="col-xl-12 p-0 float-left text-center m-24"><button className="theme_btn primary_btn sub-btn wow" data-wow-delay="0.5s" type="submit">Continue</button></div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div >
    )
}

export default SetAlert;
